import axios from "axios";

const createCoupon = async(formdata) =>{
    const response = await axios.post('/api/coupon/create-coupon',formdata);
    return response.data;
}
const getCoupons = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/coupon/get-coupons',formdata);
    return response.data;
}
const suspendCoupon = async(couponId) =>{
    const formdata = {
        couponId : couponId
    }
    const response = await axios.post('/api/coupon/suspend-coupon',formdata);
    return response.data;
}

const restoreCoupon = async(couponId) =>{
    const formdata = {
        couponId : couponId
    }
    const response = await axios.post('/api/coupon/restore-coupon',formdata);
    return response.data;
}
const getListOfActiveCoupons = async(projectId) => {
    const formdata = {
        projectId
    }
    const response =  await axios.post('/api/coupon/get-active-coupons-list',formdata);
    return response.data;
}
const getCouponDetails = async (couponId) => {
    const formdata = {
        couponId
    }
    const response =  await axios.post('/api/coupon/get-coupon-details',formdata);
    return response.data;  
}
const editCoupon = async (formdata) => {
    const response =  await axios.post('/api/coupon/edit-coupon',formdata);
    return response.data;  
}



export  default {
    createCoupon,
    getCoupons,
    restoreCoupon,
    suspendCoupon,
    getListOfActiveCoupons,
    getCouponDetails,
    editCoupon
}