import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import ctvAppService from "../../../services/api/ctv-app-service";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UserContext from "../../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const AppTemplate = (props) => {
    const [templates, setTemplates] = useState([]);
    const [componentRendered, setComponentRendered] = useState(false);

    const [reloadComponent, setReloadComponent] = useState(0);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const history = new useHistory();
    const context = useContext(UserContext);


    useEffect(() => {
        (async () => {
            const response = await ctvAppService.getAppTemplates();
            // console.log(response.data);
            if(!response.status && response.error.errorCode === 403){
                console.log("context will be set");
                //context.setIsAuthorized(context);
            }
            else if (response.status && response.data) {

                setTemplates(response.data);
            }
             setComponentRendered(true);
        })();
    }, [reloadComponent]);
   
    const deactivateTemplate =async (templateId)=>{
        const response = await ctvAppService.deactivateAppTemplate(templateId);
        // console.log(response.data);
        if (!response.status && response.error.errorCode === 403) {
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context);
        }
        else if (response.status && response.data) {

            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Deactivate!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    showConfirm
                    btnSize=""
                  >
                    Template was deactivated successfully.
                  </ReactBSAlert>
                ),
              });
               
            }else{
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Please try again!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    Template could not be deactivate. Please try again.
                  </ReactBSAlert>
                ),
              });
            }
              setReloadComponent(Math.random());
              setTimeout(hideAlert,3000);
        }

    const deactivateTemplatePopup = (templateId) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => deactivateTemplate(templateId)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Deactivate it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to deactivate this template!
            </ReactBSAlert>
          ),
        });
      }
  






    const handlePublishTemplateSwitch = async (templateId, enabled) => {

        if (enabled) {
            const response = await ctvAppService.publishAppTemplate(templateId);
            if (!response.status && response.error.errorCode === 403) {
                setShowPopup({
                    alert:null
                });
                context.setIsAuthorized(context);
            }
            else if (response.status && response.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Template Published!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Template Published successfully.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);


                setReloadComponent(Math.random());
            }else
            {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Template could not Publish!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Template could not Publish.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);
                setReloadComponent(Math.random());
            }
        }else{
            setReloadComponent(Math.random());

        }
    };
    const confirmationPopup = (templateId, enabled) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to publish this template ?"
                    onConfirm={() => {
                        handlePublishTemplateSwitch(templateId, enabled);
                        hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Publish it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to Publish Template!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
    

    const getTemplateTable = () => {

        let templateElements = [];
        if (!templates || !templates.length) {
            if (!componentRendered) {
                templateElements.push(
                    <tr>
                        <td colSpan="7" className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </td>
                    </tr>
                );
            } else {
                templateElements.push(
                    <tr>
                        <td colSpan="7" className="text-center">
                            <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                    </tr>
                );
            }
        } else {
            let i = 1;
            templates.forEach(function (template) {
                let className = "";

                if (!template.status) {
                    className = "table-danger";
                }
                templateElements.push(
                    <tr className={className}>
                        <td className="text-left">{i}</td>
                        <td className="text-left">{template.name?template.name:""}</td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {template.createdAt?template.createdAt:""}
                            </Moment>
                        </td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {template.updatedAt?template.updatedAt:""}
                            </Moment>
                        </td>
                        
                        <td className="text-left">{template.last_modified_by?template.last_modified_by:"System"}</td>
                        {/* <td className="text-left">{template.status?"True":"False"}</td> */}
                        
                        <td className="text-left">
                            <Switch
                                name="is_default"
                                offColor="success"
                                offText={
                                    <i className="nc-icon nc-simple-remove" />
                                }
                                onColor="success"
                                disabled={template.is_published?template.is_published:0}
                                onText={<i className="nc-icon nc-check-2" />}
                                value={template.is_published?template.is_published:0}
                                onChange={(value, enabled) =>
                                    confirmationPopup(template._id, enabled)
                                }
                            />
                        </td>



                       
                        <td className="text-left">
                            {!template.is_published?
                            <Button
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={(event) =>
                                    history.push(
                                        `/ctv/template/edit?template_id=${template._id}`
                                    )
                                }>
                                Edit
                            </Button>:
                            <>
                            
                            {
                                template.status?
                            <Button
                            className="btn-round"
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={()=>{
                                deactivateTemplatePopup(template._id);
                            }}
                            >
                            Deactivate
                        </Button>:""
                            
                            }
                            </>
                            }
                        </td>
                    </tr>
                );

                i++;

            });
        }
        return templateElements;
    };



    return (
        <div className="content">
            {showPopup.alert}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <Row>
                                    <Col>
                                    <div style={{'float':'right'}}>
                                    <Button
                                    className="btn-round"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event) =>
                                        history.push("/ctv/template/create")
                                    }>
                                    Create Template
                                </Button>
                                    </div>
                                    </Col>
                                </Row>
                                <CardTitle tag="h4">App Templates</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">
                                                Template Name
                                            </th>
                                            <th className="text-left">
                                                Created At
                                            </th>
                                            <th className="text-left">
                                                Updated At
                                            </th>
                                            <th className="text-left">
                                                Last Modified By
                                            </th>
                                            <th className="text-left">
                                                is_published
                                            </th>                                          
                                            <th className="text-left">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!templates || !templates.length ? (
                                            !componentRendered ? (
                                                <tr>
                                                    <td
                                                        colSpan="7"
                                                        className="text-center">
                                                        <Spinner
                                                            animation="border"
                                                            role="status">
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan="7"
                                                        className="text-center">
                                                        <CardTitle tag="h4">
                                                            No Records Found
                                                        </CardTitle>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            getTemplateTable()
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AppTemplate;
