import React, { useEffect, useState, useContext } from "react";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import UserContext from "../../../UserContext";
import moment from "moment";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const PlanData = (props) => {
    const [planDetails, setPlanDetails] = useState({});
    const [groupDetails, setGroupDetails] = useState({});

    useEffect(() => {
        setPlanDetails(props.planData);
        setGroupDetails(props.planGroup);
    }, []);

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Plan Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="6">Plan Name</Col>
                                    <Col sm="6">{planDetails.name?planDetails.name:"-"}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">Group Name</Col>
                                    <Col sm="6">{groupDetails.name}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">Type</Col>
                                    <Col sm="6">{planDetails.type}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">Start Date</Col>
                                    <Col sm="6">
                                        {moment(planDetails.start_date).format("DD/MM/yyyy    hh:mm A")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">End Date</Col>
                                    <Col sm="6">
                                        {moment(planDetails.end_date).format("DD/MM/yyyy    hh:mm A")}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PlanData;
