import React,{ useEffect, useState, useContext } from 'react';
import AndroidAppBuild from './AndroidAppBuild';
import projectsService from "../../services/api/projects-service";
import IOSAppBuild from './IOSAppBuild';
import UserContext from "../../UserContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
const  AppPlatformTab = (props) => {

 const project_id = new URLSearchParams(props.location.search).get('projectid');
 const context = useContext(UserContext);
  const [activeTab,setActiveTab] = useState("android");
  const [showPlatforms , setShowPlatforms] =  useState(true);
  const [androidEnabled , setAndroidEnabled] = useState(false);
  const [iosEnabled , setIOSEnabled] = useState(false);
  const toogleTab= (tab)=>{
    setActiveTab(tab);
  }
   useEffect( () => {
    (async () =>{
        let getAppsGeneralSettingsDetails = await projectsService.getProjectAppsGeneralSettings(project_id);
        // if(!getAppsGeneralSettingsDetails.status && getAppsGeneralSettingsDetails.error.errorCode === 403){
        //   context.setIsAuthorized(context);
        // }
        if(getAppsGeneralSettingsDetails.status && getAppsGeneralSettingsDetails.data){
                if(getAppsGeneralSettingsDetails.data.android_app_enabled) {
                  setAndroidEnabled(true);
                }
                if(getAppsGeneralSettingsDetails.data.ios_app_enabled) {
                  setIOSEnabled(true);
                }
        }

    })();
  }, []);
    return (
      <div className='content' >
        {showPlatforms?
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a  class={activeTab==="android"?"nav-link active" :"nav-link"}  onClick={()=>toogleTab("android")}>
              Android
            </a>
          </li>
          <li class="nav-item">
            <a  class={activeTab==="ios"?"nav-link active" :"nav-link"}   onClick={()=>toogleTab("ios")}>
              IOS
            </a>
          </li>
        </ul>:""}


          {activeTab==="android"  ?
          (androidEnabled?
            // <div class={activeTab==="android" ?"tab-pane fade show active":"tab-pane fade"}>
              <AndroidAppBuild projectId={project_id} setShowPlatforms = {setShowPlatforms}/> 
            // </div>
            :<div align="center">Please Enable Android App</div>)
          :
          (iosEnabled?
          <div class={activeTab==="ios" ? "tab-pane fade show active":"tab-pane fade"} >
          <IOSAppBuild projectId={project_id} setShowPlatforms = {setShowPlatforms} />  
          </div>
          :<div align="center">Please Enable IOS App</div>)
          }
      </div>
    );
}
export default AppPlatformTab;