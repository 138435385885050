import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import CreatePlanPrice from "./CreatePlanPrice";
import AddPlanFeature from "./AddPlanFeatures";
import EditPlan from "./EditPlan";
import PlanData from "./PlanData";
import PlanPriceDetails from "./PlanPriceDetail";
import PlanFeatureDetails from "./PlanFeatureDetails";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UserContext from "../../../UserContext";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import EditPlanPrice from "./EditPlanPrice";
import moment from "moment";

const PreviewPlan = (props) => {
    const [planDetails, setPlanDetails] = useState("");
    const [editPlan, setEditPlan] = useState("");
    const context = useContext(UserContext);
    const [componentRendered, setComponentRendered] = useState(false);
    const [groupDetails, setGroupDetails] = useState("");
    const history = new useHistory();
    const [priceDetails, setPriceDetails] = useState();
    const [planFeatures, setPlanFeatures] = useState([]);
    const [isPlanPublished, setIsPlanPublished] = useState(false);
    const [modified, setModified] = useState(false);
    const [showPopup, setShowPopup] = useState({ alert: null });

    const [planPrice, setPlanPrice] = useState({});
    //  const [planFeatures, setPlanFeatures] = useState([]);

    useEffect(() => {
        (async () => {
            // const response = await saasService.getPlanDetails(props.match.params["planId"]);
            // console.log("Response Received:");
            // console.log("response--------", response);
            // setPlanDetails(response.data.details);
            // setEditPlan(response.data.details);
            // setGroupDetails(response.data.planGroup);
            // setPriceDetails(response.data.planPrice);
            // setPlanFeatures(response.data.planFeatures)

            // if(response.data.details.is_published ===1){
            //     setIsPlanPublished(true)
            //     console.log(response.data.details.is_published)
            // }

            console.log("plan related data", props.planData);
            setPlanDetails(props.planData);
            setGroupDetails(props.planGroup);
            console.log("Plan price details ", props.planPriceData);
            setPlanPrice(props.planPriceData);
            console.log("PlanFeatures", props.planFeatures);

            let tempFeatures = [];

            if (props.planFeatures && props.planFeatures.length) {
                props.planFeatures.forEach((feature) => {
                    tempFeatures.push({
                        name: feature.feature_id.name,
                        quota: feature.feature_quota || "NA",
                    });
                });
                setPlanFeatures(tempFeatures);
            }
        })();
    }, [modified]);

    // const activatePlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.activatePlanGroup(planGroupId);
    //     if(response.status){
    //         setChangeStatus(true);
    //     }
    // const tempPlanGroups = [];

    // planGroups.forEach(planGroup=>{
    //     if(planGroup.id == planGroupId){
    //         planGroup.status = 1;

    //         tempPlanGroups.push(planGroup)
    //     }else{
    //         tempPlanGroups.push(planGroup)
    //     }
    // })

    //     console.log(response)
    // }

    // const deactivatePlanPlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.deactivatePlanGroup(planGroupId);

    //     if(response.status){
    //         setChangeStatus(true)
    //     }
    //     console.log(response)

    // }

    // const getPlanGroupsTable = () =>{

    //     let groupElements = [];
    //     if(!planGroups || !planGroups.length){
    //       if(!componentRendered){
    //           groupElements.push(
    //          <tr>
    //           <td colSpan="4" className="text-center">
    //               <Spinner animation="border" role="status">
    //                       <span className="sr-only">Loading...</span>
    //                     </Spinner>
    //           </td>
    //           </tr>
    //         );
    //       }else{
    //           groupElements.push(
    //             <tr>
    //           <td colSpan="4" className="text-center">
    //               <CardTitle tag="h4">No Records Found</CardTitle>
    //           </td>
    //           </tr>
    //       );
    //       }
    //     }else{
    //        let i = 1;
    //        planGroups.forEach(function(groupDetails) {
    //             let className = '';
    //             if(!groupDetails.status){
    //               className = 'table-warning';
    //             }
    //             groupElements.push(
    //               <tr className={className}>
    //                   <td className="text-left">
    //                      {i}
    //                  </td>
    //                  <td className="text-left">
    //                      {groupDetails.name}
    //                  </td>
    //                  <td className="text-left">
    //                      {groupDetails.status?'Active':'Inactive'}
    //                  </td>
    //                  <td className="text-left">
    //                     {groupDetails.status? <Button
    //                             style={{float: 'right'}}
    //                             className="btn-round"
    //                             color="danger"
    //                             size="sm"
    //                             type="button"
    //                             onClick={(event) =>deactivatePlanPlanGroup(groupDetails.id)}>
    //                             Deactivate
    //                         </Button>:<Button
    //                             style={{float: 'right'}}
    //                             className="btn-round"
    //                             color="danger"
    //                             size="sm"
    //                             type="button"
    //                             onClick={(event) => activatePlanGroup(groupDetails.id)}>
    //                             Activate
    //                         </Button>}
    //                  </td>
    //               </tr>
    //           )
    //           i++;
    //       })
    //     }
    //     return groupElements;
    // }

    const publishPlan = async () => {
        console.log("Publishing Plan");
        const response = await saasService.publishPlan(props.planId);
        console.log(response);
        if(!response.status && response.error.errorCode === 403) {
            setShowPopup({alert:null});
            context.setIsAuthorized(context);
        }
        else if (response.data === true) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Plan Published!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                        btnSize=""
                    >
                        Plan Published successfully.
                    </ReactBSAlert>
                ),
            });

            setTimeout(() => {
                hideAlert();
                props.setPlanPublished(true);
            }, 3000);
        }
    };
    const getFeaturesTable = () => {
        let i = 1;
        // setComponentRendered(true)

        const table = [];
        planFeatures.forEach((feature) => {
            table.push(
                <tr>
                    <td className="text-left">{i++}</td>
                    <td className="text-left">{feature.name}</td>
                    <td className="text-left">{feature.quota}</td>
                </tr>
            );
        });
        return table;
    };

    const confirmationPopup = () => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to publish  ?"
                    onConfirm={() => {
                        publishPlan();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Publish it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to Publish Plan!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };

    return (
        <div className="content">
            {showPopup.alert}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Plan Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <PlanData  planData = {props.planData} planGroup = {props.planGroup}/>
                <PlanPriceDetails planPriceData={props.planPriceData}/>
                <PlanFeatureDetails planFeatures={props.planFeatures}/> */}

                                <Row>
                                    <Col sm="6">Group Name</Col>
                                    <Col sm="6">{groupDetails.name}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">Type</Col>
                                    <Col sm="6">{planDetails.type}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">Start Date</Col>
                                    <Col sm="6">
                                        {moment(planDetails.start_date).format(
                                            "DD/MM/yyyy    hh:mm A"
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">End Date</Col>
                                    <Col sm="6">
                                        {moment(planDetails.end_date).format(
                                            "DD/MM/yyyy    hh:mm A"
                                        )}
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="6">Base Price (INR)</Col>
                                    <Col sm="6">{planPrice.base_price}</Col>
                                </Row>

                                <Row>
                                    <Col sm="6">Discount (INR)</Col>
                                    <Col sm="6">{planPrice.discount}</Col>
                                </Row>

                                <Row>
                                    <Col sm="6">Duration</Col>
                                    <Col sm="6">
                                        {planPrice.duration}{" "}
                                        {planPrice.duration_unit}
                                    </Col>
                                </Row>
                                <hr />
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">
                                                Feature Name
                                            </th>
                                            <th className="text-left">Quota</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!planFeatures ||
                                        !planFeatures.length ? (
                                            !componentRendered ? (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="text-center"
                                                    >
                                                        <Spinner
                                                            animation="border"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="text-center"
                                                    >
                                                        <CardTitle tag="h4">
                                                            No Records Found
                                                        </CardTitle>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            getFeaturesTable()
                                        )}
                                        {/* {getFeaturesTable()} */}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    {props.planPriceData && props.planFeatures.length ? (
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            //onClick={publishPlan}
                            onClick={confirmationPopup}
                        >
                            Publish Plan
                        </Button>
                    ) : (
                        ""
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default PreviewPlan;
