import React,{useEffect,useState,useContext} from "react";
import { useHistory } from "react-router-dom";
import paymentLinksService from "../../services/api/payment-links-service";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Container,
    Table
} from "reactstrap";
import moment from "moment";

const ViewPaymentLink = (props) => {
    const history = new useHistory();
    const paymentLinkId = new URLSearchParams(props.location.search).get("payment-link-id");
    if(!paymentLinkId){
        history.push("/contract/payment-links");
    }
    const [paymentLink, setPaymentLink] = useState(true);
    const [temp,setTemp] = useState(false);

    useEffect(()=>{
        (async()=>{
            let paymentLink = await paymentLinksService.viewPaymentLink(paymentLinkId);
            if(paymentLink.status && paymentLink.data){
                setPaymentLink(paymentLink.data);
                setTemp(true);
            }
        })();
    },[]);

    return(
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Details of Payment Link</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left"></th>
                                        </tr>
                                    </thead>
                                    {temp ? 
                                    
                                        <tbody>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Project Name:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.project_id.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Publisher Name:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.publisher_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Publisher Email:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.publisher_email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Publisher Contact Number:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.publisher_contact}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Razorpay Payment Link:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.payment_link}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Currency:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.currency}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Amount:</b>
                                                </td>
                                                <td className="text-left">
                                                    {(paymentLink.amount)/100}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Razopay Payment Link Id:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.razorpay_payment_link_id}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Status:</b>
                                                </td>
                                                <td className="text-left">
                                                    {paymentLink.status}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <b>Link Expire Date:</b>
                                                </td>
                                                <td className="text-left">
                                                    {moment.unix(paymentLink.expire_date).format("DD-MM-YYYY")}
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <>
                                        
                                        </>}
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}   

export default ViewPaymentLink;