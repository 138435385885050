import axios from "axios";

const createPaymentLinks = async(formdata)=>{
    const response = await axios.post("/api/payment-links/create-payment-link",formdata);
    return response.data;
}

const getPaymentLinks = async(formdata) => {
    const response = await axios.post("/api/payment-links/get-payment-links",formdata);
    return response.data;
}

const viewPaymentLink = async(paymentLinkId) => {
    const formdata = {
        paymentLinkId: paymentLinkId
    }
    const response = await axios.post("/api/payment-links/view-payment-link",formdata);
    return response.data;
}

export default {
    createPaymentLinks,
    getPaymentLinks,
    viewPaymentLink
}