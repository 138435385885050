/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createRoot} from "react-dom/client"
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const hist = createBrowserHistory();

const root=document.getElementById("root");
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/project" render={(props) => <AdminLayout {...props} />} />
      <Route path="/template" render={(props) => <AdminLayout {...props} />} />
      <Route path="/transaction" render={(props) => <AdminLayout {...props} />} />
      <Route path="/subscription" render={(props) => <AdminLayout {...props} />} />
      <Route path="/report" render={(props) => <AdminLayout {...props} />} />
      
      <Route path="/dashboard" render={(props) => <AdminLayout {...props} />} />
      <Route path="/user" render={(props) => <AdminLayout {...props} />} />
      <Route path="/contentsettings" render={(props) => <AdminLayout {...props} />} />
      <Route path="/config" render={(props) => <AdminLayout {...props} />} />
      <Route path="/notifications" render={(props) => <AdminLayout {...props} />} />
      <Route path="/jobs" render={(props) => <AdminLayout {...props} />} />
      <Route path="/saas" render={(props) => <AdminLayout {...props} />} />
      <Route path="/contract" render={(props) => <AdminLayout {...props} />} />
      <Route path="/plugin" render={(props)=><AdminLayout {...props} />} />
      <Route path="/faq" render={(props)=><AdminLayout {...props} />} />
      <Route path="/cache" render ={(props)=><AdminLayout {...props} />} />
      <Route path="/appbuild" render ={(props)=><AdminLayout {...props} />} />
      <Route path="/service-stack" render ={(props)=><AdminLayout {...props} />} />
      <Route path="/whatsapp" render ={(props)=> <AdminLayout {...props} />} />
      <Route path="/coupon" render = {(props) => <AdminLayout {...props} />} />
      <Route path="/zohodesk" render ={(props)=> <AdminLayout {...props} />} />
      <Route path="/users" render={(props) => <AdminLayout {...props}/>}/>
      <Route path="/permissions" render={(props) => <AdminLayout {...props}/>}/>
      <Route path="/ctv" render={(props)=> <AdminLayout {...props} />} />
      <Route path="/genai" render={(props)=> <AdminLayout {...props} />} />
      <Route path="/genai-credits" render={(props)=> <AdminLayout {...props} />} />

      <Redirect to="/auth/login" />
    </Switch>
  </Router>,
  document.getElementById("root")
  
);
