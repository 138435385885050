import React, { useContext, useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import templateService from "../../services/api/template-service";
import TimezoneSelect, { i18nTimezones } from "react-timezone-select";
import { useHistory } from "react-router-dom";
import UserContext from "../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from "react-select";

const CreateTemplate = (props) => {
    const history = new useHistory();
    const [formdetails, setFormDetails] = useState({
        name: "",
        description: "",
        template_id: "",
        platform: "",
    });
    const context = useContext(UserContext);
    const [existingTemplates, setExistingTemplates] = useState(false);
    const [templateNameState, setTemplateNameState] = useState(false);
    const [templateNameError, setTemplateNameError] = useState(false);
    const [description, setDescription] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });

    const [template, setTemplate] = useState(false);
    const [platform, setPlatform] = useState(false);
    const [templateOptions, setTemplateOptions] = useState(false);
    const [platformOptions, setPlatformOptions] = useState([{
        value: "non-headless",
        label : "Non-Headless",
    },{
        value: "headless",
        label: "Headless",
    }])

    useEffect(() => {
        (async () => {
                let templates = await templateService.getTemplates();
                if (!templates.status && template.error.errorCode === 403) {
                    console.log("set Context");
                    // context.setIsAuthorized(context);
                }
                else if (templates.status) {
                    let allTemplates = templates.data;
                    let templateArray = [];
                    let defaultTemplate = '';
                    if(allTemplates.length){
                      allTemplates.forEach(function(templateDetails) {
                        
                        templateArray.push({value:templateDetails._id,label:templateDetails.name});

                      })
                      setTemplateOptions(templateArray);
                      setTemplate(templateArray[0]);
                      setFormDetails({...formdetails,template_id:templateArray[0].value});
                    }
                    setExistingTemplates(templates.data);
                
            }
        })();
    }, []);


    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value.trim(),
        });
    };

    const changeTemplate = (event) => {
        setFormDetails({
            ...formdetails,
            template_id: event.value,
        });
         setTemplate(event);
    };

    const changePlatfrom = (event) => {
        setFormDetails({
            ...formdetails,
            platform: event.value,
        });
        setPlatform(event);
    }


    const validateTemplateName = () => {
        if (formdetails.name === "") {
            setTemplateNameState("has-danger");
            setTemplateNameError("Name is required");
            return false;
        }

        if (existingTemplates) {
            let duplicateName = false;
            Object.keys(existingTemplates).forEach(function (key) {
                let existingTemplateName = existingTemplates[key].name;
                if (formdetails.name === existingTemplateName) {
                    duplicateName = true;
                }
            });
            if (duplicateName) {
                setTemplateNameState("has-danger");
                setTemplateNameError("You have already created template with this name.");
                return false;
            }
        }

        setTemplateNameState("has-success");
        return true;
    };


    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        let namevalidation = validateTemplateName();
        if(namevalidation){
          setShowLoader(true);
          setShowSaveButton(false);
          let createTemplate = await templateService.createTemplate(formdetails);
          if(!createTemplate.status && createTemplate.error.errorCode === 403){
                context.setIsAuthorized(context);
          }
          else if(createTemplate.status){
            setTimeout(()=>{
              setShowLoader(false)
              setAlert({show:true,type:'success',message:'Template was created Successfully'});
            },1000);
            setTimeout(()=>{
              setAlert({show:false,type:'',message:''});
            },3000);
            setTimeout(()=>{
              history.push("/template/templates");
            },2000);
          }else{
            setTimeout(()=>{
              setShowLoader(false)
              setShowSaveButton(true)
              setAlert({show:true,type:'danger',message:'Template could not be created, Please try again.'});
            },1000);
            setTimeout(()=>{
              setAlert({show:false,type:'',message:''});
            },3000);
          }
        }
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Create Template</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol form"
                                    action=""
                                    id="createTemplate"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Label sm="2">Name <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={templateNameState}
                                            >
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                   
                                                />
                                                {templateNameState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {templateNameError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Description</Label>
                                        <Col sm="7">
                                            <FormGroup className={description}>
                                                <Input
                                                    name="description"
                                                    id="description"
                                                    type="textarea"
                                                    onChange={handleChange}
                                                />
                                                {description ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {descriptionError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">
                                            Select Platfrom<span class="text-danger"> </span>
                                        </Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="select-platform"
                                                    value={platform}
                                                    onChange={changePlatfrom}
                                                    options={platformOptions}
                                                />

                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">
                                            Copy From Templates <span class="text-danger"> * </span>
                                        </Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="select-template"
                                                    value={template}
                                                    onChange={changeTemplate}
                                                    options={templateOptions}
                                                />

                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {showSaveButton ? (
                                        <Button
                                            className="btn-round"
                                            color="info"
                                            type="button"
                                            onClick={validateForm}
                                        >
                                            Create
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default CreateTemplate;
