import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select';
import cacheCleanJobsService from "../../services/api/clean-cache-job-service";
import projectsService from "../../services/api/projects-service";
import contentService from "../../services/api/content-service";
import { useHistory } from 'react-router-dom'
import UserContext from "../../UserContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";



const ScheduleCacheCleanJob = (props) => {
  const history = new useHistory();
  const [eventType, setEventType] = useState(false);
  const [publisherId, setPublisherId] = useState('');
  const [publisherNameArray, setPublisherNames] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projectNameArray, setProjectNames] = useState([]);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [publisher, setPublisher] = useState('');
  const [categories, setCategories] = useState(false);
  const [articles, setArticles] = useState(false);
  const [formdetails, setFormDetails] = useState({ publisher_id: '', project_id: '', event_type: "", group_name: "", status: "", category_id: '', article_id: '', article_guid: '',page_slug:"" });
  const [projectstate, setProjectState] = useState("");
  const [projecterror, setProjectError] = useState("");
  const [publisherstate, setPublisherState] = useState("");
  const [publishererror, setPublisherError] = useState("");
  const [eventstate, setEventState] = useState("");
  const [eventerror, setEventError] = useState("");
  const [categorystate, setCategoryState] = useState("");
  const [categoryerror, setCategoryError] = useState("");
  const [articlestate, setArticleState] = useState("");
  const [articleerror, setArticleError] = useState("");
  const [groupNameState, setGroupNameState] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [routeslugstate, setRouteSlugState] = useState("");
  const [routeslugerror, setRouteSlugError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: ""
  });

  const context = useContext(UserContext);
  const [formData, setFormData] = useState({
    jobData: "",
    jobName: "",
    scheduleTime: "",
    endDate: "",
    frequency: "",
    jobType: ""
  })

  const jobType = [
    { value: "instant", label: "Instant" },
    { value: "schedule", label: "Schedule" },
  ]

  useEffect(() => {
    (async () => {
      let publishers = await projectsService.getAllPublishers();
      if (publishers.status) {
        let getPublishers = [];
        let publisherData = publishers.data;
        if (publisherData.length) {
          publisherData.forEach(function (publisherDetails) {
            let publisherValue = { value: publisherDetails.id, label: publisherDetails.name + "(" + publisherDetails.email + ")", email: publisherDetails.email };
            getPublishers.push(publisherValue)
          })
        }
        setPublisherNames(getPublishers);
      }
    })();
  }, [reloadComponent]);

  useEffect(() => {
    (async () => {
      if (publisher) {
        let projects = await projectsService.getPublisherProjects(publisher);
        let publisherProjects = [];
        if (projects.status) {
          let projectData = projects.data;
          if (projectData.length) {
            projectData.forEach(function (projectDetails) {
              let projectValue = { value: projectDetails.id, label: projectDetails.name };
              publisherProjects.push(projectValue)
            })
          }
          setProjectNames(publisherProjects);
        }
      }
    })();
  }, [publisher]);

  const cacheNameOptions = [
    { value: "home", label: "Home" },
    { value: "category", label: "Category" },
    { value: "article", label: "Article" },
    { value: "config", label: "Config" },
    { value: "routeslug", label: "Route Slug" },
    // { value: "archiveArticle", label: "Archive Articles"},
    { value: "all", label: "All Cache Clean" },

  ]

  const groupNameOptions = [
    { value: "home", label: "Home" },
    { value: "category", label: "Category" },
    { value: "article", label: "Article" },
    { value: "widgets", label: "Widgets" },

  ]

  useEffect(() => {
    (async () => {
      if (projectId) {
        let categoryData = await contentService.getCategories(projectId.value);
        if (categoryData.status) {
          let allCategories = categoryData.data;
          let categoryArray = [];
          if (allCategories.length) {
            allCategories.forEach(function (categoryDetails) {
              categoryArray.push({
                value: categoryDetails._id,
                label: categoryDetails.name,
              });
              if (
                categoryDetails.sub_categories &&
                categoryDetails.sub_categories.length
              ) {
                let subCategories = categoryDetails.sub_categories;
                subCategories.forEach(function (subCategoryDetails) {
                  categoryArray.push({
                    value: subCategoryDetails._id,
                    label: subCategoryDetails.name,
                  });
                });
              }
            });
            setCategories(categoryArray);
          }
        }
      }
    })();
  }, [projectId]);

  // useEffect(() => {
  //   (async () => {
  //     if ( projectId && eventType ==="article") {
  //       let articleData = await contentService.getArticles(projectId.value,{title:''});
  //       if (articleData.status) {
  //         let allArticles = articleData.data;
  //         let articleArray = [];
  //         if (allArticles.length) {
  //           allArticles.forEach(function (articleDetails) {
  //             articleArray.push({
  //               value: articleDetails._id,
  //               label: articleDetails.title,
  //             });

  //           });
  //           setArticles(articleArray);
  //         }
  //       }
  //     }
  //   })();
  // }, [eventType]);

  const validateFields = () => {
    let validations = true;
    if (formdetails.publisher_id === '') {
      setPublisherState("has-danger");
      setPublisherError("This field is required");
      validations = false;
    } else {
      setPublisherState("has-success");
      setPublisherError("");
    }

    if (formdetails.project_id === '') {
      setProjectState("has-danger");
      setProjectError("This field is required");
      validations = false;
    } else {
      setProjectState("has-success");
      setProjectError("");
    }

    if (formdetails.event_type === '') {
      setEventState("has-danger");
      setEventError("This field is required");
      validations = false;
    } else {
      setEventState("has-success");
      setEventError("");
      if (formdetails.event_type === "category" && formdetails.category_id === "") {
        setCategoryState("has-danger");
        setCategoryError("This field is required");
        validations = false;
      } else {
        setCategoryState("has-success");
        setCategoryError("");
      }
      if (formdetails.event_type === "article" && formdetails.article_guid === "") {

        setArticleState("has-danger");
        setArticleError("Enter Article GUID");
        validations = false;
      } else {
        setArticleState("has-success");
        setArticleError("");

      }
      if (formdetails.event_type === "routeslug" && formdetails.page_slug === "") {

        setRouteSlugState("has-danger");
        setRouteSlugError("Enter Page Slug");
        validations = false;
      } else {
        setRouteSlugState("has-success");
        setRouteSlugError("");

      }
      // if(formdetails.event_type ==="config"){
      //     setGroupNameState("has-danger");
      //     setGroupNameError("This field is required");
      //     validations = false;
      // }else{

      // }
    }
    return validations;
  }
  const validateForm = async () => {
    let validated = validateFields();
    // return; 

    if (validated) {
      setShowLoader(true);
      setShowButton(false);
      const response = await cacheCleanJobsService.scheduleJob(formdetails);
      if (!response.status && response.error.errorCode === 403) {
        context.setIsAuthorized(context);
      }
      else if (response.status && response.data) {
        setTimeout(() => {
          setShowLoader(false)
          setAlert({ show: true, type: 'success', message: 'Cache Clean job scheduled successfully' });
        }, 1000);

        setTimeout(() => {
          setAlert({ show: false, type: '', message: '' });
        }, 3000);

        setTimeout(() => {
          history.push("/cache/dashboard");
        }, 2000);
      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowButton(true)
          setAlert({ show: true, type: 'danger', message: 'Unable to clean cache, Please try again.' });
        }, 1000);

        setTimeout(() => {
          setAlert({ show: false, type: '', message: '' });
        }, 3000);
      }

    }
    // setShowLoader(false);
    // setShowButton(true);
  }
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }


  const handleChange = async (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.name]: event.target.value
    })
  }


  const handleCacheTypeChange = (event) => {
    setEventType(event.value);
    setFormDetails({
      ...formdetails,
      event_type: event.value
    })
  }

  const handleGroupNameChange = (event) => {
    setEventType(event.value);
    setFormDetails({
      ...formdetails,
      group_name: event.value
    })
  }
  const handleArticleChange = (event) => {
    // setArticle(event.value);
    setFormDetails({
      ...formdetails,
      article_id: event.value
    })
  }



  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id: ''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }


  const handleCategoryChange = (event) => {
    console.log("Value:", event.value);
    // let categoryIds = [];
    // if (value && value.length > 0) {
    //   value.forEach(function (categoryIdDetails) {
    //     categoryIds.push(categoryIdDetails.value);
    //   });
    // }
    setFormDetails({
      ...formdetails,
      category_id: event.value,
    });
  };

  // const disablePastDates = (current) =>{
  //     const previousDate =  moment().subtract(1, 'day');
  //     return current.isAfter(previousDate);
  // }
  const redirectUrl = (event) => {
    event.preventDefault();
    history.push("/cache/dashboard")
  }

  return (
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Create Cache Clean Job</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Label className="bold" sm="2">Select Publisher <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup className={publisherstate}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="publisherid"
                          value={publisherId}
                          onChange={changePublisherId}
                          options={publisherNameArray}
                        />

                        {publisherstate === "has-danger" ? (
                          <label className="error">
                            {publishererror}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label className="bold" sm="2">Select Project <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup className={projectstate}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="projectid"
                          value={projectId}
                          onChange={changeProjectId}
                          options={projectNameArray}
                        />

                        {projectstate === "has-danger" ? (
                          <label className="error">
                            {projecterror}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label className="bold" sm="2">Select Event <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup className={eventstate}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="event"
                          onChange={handleCacheTypeChange}
                          options={cacheNameOptions}
                        />

                        {eventstate === "has-danger" ? (
                          <label className="error">
                            {eventerror}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  {eventType === "article" ? <>
                    <Row>
                      <Label className="bold" sm="2">Article guid <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup >
                          <Input
                            name="article_guid"
                            type="text"
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {articlestate === "has-danger" ? (
                          <label style={{ color: "red" }} className="error">
                            {articleerror}
                          </label>
                        ) : null}
                      </Col>
                    </Row>
                  </> : ""}
                  {eventType === "category" ?
                    <Row>
                      <Label className="bold" sm="2">Select Category <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={categorystate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="select category"
                            name="categoryids"
                            id="categoryids"
                            // isMulti="true"
                            //value={currentMultiEnumValue()}
                            options={categories}
                            onChange={(value) => handleCategoryChange(value)}
                          />
                          {categorystate === "has-danger" ? (
                            <label className="error">
                              {categoryerror}
                            </label>
                          ) : null}

                        </FormGroup>
                      </Col>
                    </Row>
                    : ""}
                  {eventType === "config" ?
                    <Row>
                      <Label className="bold" sm="2">Select Group Name</Label>
                      <Col sm="7">
                        <FormGroup className={groupNameState}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="event"
                            onChange={handleGroupNameChange}
                            options={groupNameOptions}
                          />

                          {groupNameState === "has-danger" ? (
                            <label className="error">
                              {groupNameError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    : ""}
                  {eventType === "routeslug" ? <>
                    <Row>
                      <Label className="bold" sm="2">Page Slug <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup >
                          <Input
                            name="page_slug"
                            type="text"
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {routeslugstate === "has-danger" ? (
                          <label style={{ color: "red" }} className="error">
                            {routeslugerror}
                          </label>
                        ) : null}
                      </Col>
                    </Row>
                  </> : ""}

                  {showButton ?
                    <>
                      <Button type="submit">Clear Cache</Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={redirectUrl}
                      >
                        Cancel
                      </Button>
                    </>
                    : ""}
                  {showLoader ?
                    <Spinner
                      animation="border"
                      role="status"
                    >
                      <span className="sr-only">
                        Loading...
                      </span>
                    </Spinner>
                    : ""}
                </Form>
                {alert.show ?
                  <Alert color={alert.type}>
                    {alert.message}
                  </Alert>
                  : ''}
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );
}

export default ScheduleCacheCleanJob;