import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const Users = (props)=> {
  const projectId = new URLSearchParams(props.location.search).get('projectid');
 
  const history = new useHistory();
  if(!projectId){
    history.push("/project/projects");
  }
  const context = useContext(UserContext);
  const [users, setUsers] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  let ownerEmail = "";
  useEffect( () => {
    (async () =>{
        let projectData = await projectService.getProjectDetails(projectId);
            if(projectData.status && projectData.data){
                setProjectDetails(projectData.data);
            }
        })();
    }, []);
  
  useEffect( () => {
    (async () =>{
        let usersData = await projectService.getProjectUsers(projectId);
            if(usersData.status && usersData.data){
                setUsers(usersData.data);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    const suspendUserPopup = (userId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => suspendUser(userId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Suspend/Remove it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to suspend/remove this user!
          </ReactBSAlert>
        ),
      });
    }

    const restoreUserPopup = (userId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreUser(userId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to provide access to this user again!
          </ReactBSAlert>
        ),
      });
    }

    const suspendUser = async (userId) => {
      let susUser = await projectService.suspendUserFromProject(projectId,userId);
      if(!susUser.status && susUser.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context)
      }
      else if(susUser.status && susUser.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Suspended/Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User was suspended/removed successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreUser = async (userId) => {
      let resUser = await projectService.restoreUserToProject(projectId,userId);
      if(!resUser.status && resUser.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(resUser.status && resUser.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User was restored to the project successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }
    const changeOwnerPopup = (userInfo) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => updateOwner(userInfo)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Update it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            Do you want to change ownership from {ownerEmail} to {userInfo.email} ? 
          </ReactBSAlert>
        ),
      });
    }
    const updateOwner = async (userInfo) => {
      let response = await projectService.updateProjectOwner(projectId,userInfo);
      if(!response.status && response.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(response.status && response.data){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Updated!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Owner was updated successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Unable to change ownership . Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }
    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  
 

  let userElements = [];
  if(!users || !users.length){
    if(!renderComponent){
        userElements.push(
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        userElements.push(
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No user found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      users.forEach(function(userDetails) {
        let className = '';
        if(userDetails.status==0){
            className = 'table-danger';
        }
        let displayRoles = [];
        userDetails.roles.forEach(function(roledisplay){
          console.log("roledisplay: ",roledisplay)
          displayRoles.push(
            <p>{roledisplay.toUpperCase() }</p>
            );
        })
        let actions = true;
        if(userDetails.roles.includes('owner')){
          actions = false;
          ownerEmail = userDetails.email;
        }
        userElements.push(
            <tr className={className}>
               <td className="text-left">{i}</td>
               <td className="text-left">
                   {userDetails.name}
               </td>
               <td className="text-left">
                   {userDetails.email}
               </td>
               <td className="text-left">
                {console.log("in project users: ",displayRoles)}
               {displayRoles}
               </td>
               <td className="text-left">
                   {userDetails.status==1?'Active':'Suspended'}
               </td>
               {actions?<>
               <td className="text-left">
               {userDetails.status==1?
                <a title="Suspend/Remove" onClick={(event)=>  suspendUserPopup(userDetails.publisher_id) } href="javascript:void(0)" class="nav-link">
                  <FontAwesomeIcon icon={faTrash} />
                </a>
                :
                <a title="Restore" onClick={(event)=>  restoreUserPopup(userDetails.publisher_id) } href="javascript:void(0)" class="nav-link">
                    <FontAwesomeIcon icon={faTrashRestoreAlt} />
                </a> 
               }
               </td>
               </>
               :
               <td className="text-left">-</td>
              }
              {
              userDetails.status==1 && actions ? 
                <td>
                <Button onClick = {() => { changeOwnerPopup(userDetails)}} className="btn-smb">Mark as Owner</Button>
               </td>:<td>-</td>
              }
               
            </tr>
        )
        i++;
    })
  }

    return (
      <div className="content">
         {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    Users associated with {projectDetails.name}
                </CardTitle>

                  <Button
                      style={{float: 'right'}}
                      className="btn-round"
                      color="info"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/project/invitedusers?projectid="+projectId) }
                  >View Invited Users</Button>
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th>   
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Roles</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Action</th>
                        <th className="text-left">Change Ownership</th>
                      </tr>
                    </thead>
                    <tbody>{userElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  

export default Users;
