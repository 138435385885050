import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select'; 
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import jobsService from "../../services/api/jobs-service";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
  } from "reactstrap";
import UserContext from "../../UserContext";
const RescheduleJob = (props)=>{
    const context = useContext(UserContext);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        type:""
    });

    const [formData, setFormData] = useState({
        scheduleTime:"",
        endDate:"",
        jobId: props.match.params["jobId"]
    })

    const handleSubmit = async(event)=>{
        event.preventDefault();
        console.log(formData)
        const response = await jobsService.rescheduleJob(formData);
        console.log(response);
        if(!response.status && response.error.errorCode === 403){
            setAlert({show:false});
            context.setIsAuthorized(context);
        }
        else if(response.data.isRescheduled){
            setAlert({
                show: true,
                type:"success",
                message:"Job Scheduled successfully"
            })
        }else{
            setAlert({
                show: true,
                type:"danger",
                message:"Unable to Schedule job"
            })
        }
    }

    const handleScheduleTimeChange = async(event)=>{
        setFormData({
            ...formData,
            scheduleTime: moment(event._d).utc().toString()
        })
    }

    const handleEndTimeChange = async(event)=>{
        setFormData({
            ...formData,
            endDate: moment(event._d).utc().toString()
        })
    }

    return (
        <>
         <div className="content">
            <Container>
                <Form onSubmit={handleSubmit}>
                    
                    <Row>
                        <Label sm="2">Schedule Time <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                            <ReactDatetime
                                autoComplete = 'off'
                                name="scheduleTime"
                                id="scheduleTime"
                                onChange={handleScheduleTimeChange}
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                            />
                        </Col>
                        </FormGroup>
                    </Row>

                    <Row>
                        <Label sm="2">End Date <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                            <ReactDatetime
                                autoComplete = 'off'
                                name="endDate"
                                id="endDate"
                                onChange={handleEndTimeChange}
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                            />
                        </Col>
                        </FormGroup>
                    </Row>

                    <Button type="submit">Reschedule Job</Button>
                </Form>
                {
                    alert.show? <Alert color={alert.type}>
                        {alert.message}
                    </Alert> :''
                }
            </Container>
            </div>
        </>
    )
}

export default RescheduleJob;