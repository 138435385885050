import React, { useEffect, useState, useContext } from "react";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UserContext from "../../../UserContext";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const AddPlanFeature = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const context = useContext(UserContext);
    const [quotaState, setQuotaState] = useState(false);
    const [quotaError, setQuotaError] = useState(false);

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const history = new useHistory();
    const [componentRendered, setComponentRendered] = useState(true);
    const [planFeatures, setPlanFeatures] = useState([]);
    const [featureOptions, setFeatureOptions] = useState([]);
    const [currentFeature, setCurrentFeature] = useState({
        plan_id: props.planId,
        feature_id: "",
        feature_quota: 0,
        quota_duration: ""
    });
    const [modified, setModified] = useState(false);
    const [quotaType, setQuotaType] = useState(false);
    const [showForm, setShowFrom] = useState(false);
    const [enumOptions, setEnumOptions] = useState([]);
    const [quotaRange, setQuotaRange] = useState({
        min: 0,
        max: 0,
    });
    const [featureLabel, setFeatureLabel] = useState("");

    const [showPopup,setShowPopup] = useState({alert:null});

    
    useEffect(() => {
        (async () => {
            const response = await saasService.getPlanFeatures(props.planId);

            let tempResData = [];

            console.log("Response", response.data);
            response.data.forEach((element) => {
                tempResData.push({
                    id: element.id,
                    feature: element.feature_id.name,
                    quota: element.feature_quota,
                    quota_duration: element.quota_duration,
                    feature_id: element.feature_id._id,
                });
            });

            setPlanFeatures(tempResData);

            const features = await saasService.getFeatures();

            console.log("Feature responses ", features);

            let tempFeatureOptions = [];
            let formattedFeature = {};
            features.data.forEach((feature) => {
                formattedFeature = {};
                if (feature.status === 1) {
                    formattedFeature["label"] = feature.name;
                    formattedFeature["value"] = feature.id;
                    formattedFeature["type"] = feature.type;
                    formattedFeature["description"]= feature.description;
                    if (feature.type === "quota") {
                        formattedFeature["limit_type"] = feature.limit_type;
                        formattedFeature["quota_duration"]=feature.quota_duration;
                        if (feature.limit_type === "range") {
                            formattedFeature["min"] = feature.limit_min;
                            formattedFeature["max"] = feature.limit_max;
                        } else if (feature.limit_type === "enum") {
                            formattedFeature["enum_array"] =
                                feature.limit_enum_array;
                        }
                    }

                    //code to check element in planfeatures state
                    // console.log("formated:",formattedFeature);
                    if (tempResData.find((element) =>element.feature.toLowerCase() === feature.name.toLowerCase())) {
                        //console.log(feature.name);
                    } else {
                        // console.log(feature.name);
                        tempFeatureOptions.push(formattedFeature);
                    }
                }
            });
            setFeatureOptions(tempFeatureOptions);
        })();
    }, [modified]);

    // const validateName = () =>{

    //     if(formdetails.name===''){
    //         setNameState("has-danger");
    //         setNameError("This field is required");
    //         return false;
    //       }

    //       if(planGroups && planGroups.length > 0){
    //         let duplicateName = false;
    //         planGroups.forEach(function(details) {
    //             if(formdetails.name===details.name){
    //                 duplicateName = true;
    //             }
    //         })
    //         if(duplicateName){
    //             setNameState("has-danger");
    //             setNameError("You have already created the plan group with this name.");
    //             return false;
    //         }
    //       }
    //       setNameState("has-success");
    //       return true;

    // }

    const validateQuota = () => {
        if (quotaType === "range") {
            if (
                currentFeature.feature_quota >= quotaRange.min &&
                currentFeature.feature_quota <= quotaRange.max
            ) {
                setQuotaState("");
                setQuotaError("");
                return true;
            }
            setQuotaState("has-danger");
            setQuotaError(
                <div className="text-danger">Quota should be in the range. Min : {quotaRange.min} Max : {quotaRange.max}
            </div>);
            return false;
        } else {
            if (currentFeature.feature_quota) {
                setQuotaState("");
                setQuotaError("");
                return true;
            }
            setQuotaState("has-danger");
            setQuotaError(
                <div className="text-danger">Select Quota from the list</div>
            );
            return false;
            }

        return true;
    };

    // const featureValidation = () =>{

    //     console.log(currentFeature)

    // }

    const validateForm = async () => {
        // console.log(currentFeature);

       let quotaValidation = validateQuota();
        console.log(currentFeature.feature_quota);
        if ((quotaType?quotaValidation:true)) {
            const response = await saasService.addPlanFeature(currentFeature);
            if(!response.status && response.error.errorCode === 403){
                context.setIsAuthorized(context);
            }
            setQuotaType(false);

            setFeatureLabel("");
            
            console.log("Plan feature added",response);
            // setShowQuotaForm(false);
            if (modified) {
                
                props.setModified(!props.state);
                setModified(false);
            } else {
                
                props.setModified(!props.state);
                setModified(true);
            }
         setCurrentFeature({
            plan_id: props.planId,
            feature_id: "",
            feature_quota: 0,
            quota_duration : "",
        });

        }

        // let nameValidation = validateName();
        // if(nameValidation){
        //     setShowLoader(true);

        //     setShowSaveButton(false);

        //     let newPlanGroup = await saasService.createPlanGroup(formdetails);

        //     console.log(newPlanGroup);

        // if(response.status){
        //     setTimeout(()=>{
        //       setShowLoader(false)
        //       setAlert({show:true,type:'success',message:'Plan price created Successfully'});
        //     },1000);

        //     setTimeout(()=>{
        //       setAlert({show:false,type:'',message:''});
        //     },3000);

        //     setTimeout(()=>{
        //       history.push("/saas/plan-groups");
        //     },2000);

        //   }else{
        //     setTimeout(()=>{
        //       setShowLoader(false)
        //       setShowSaveButton(true)
        //       setAlert({show:true,type:'danger',message:'Plan Price could not be created, Please try again.'});
        //     },1000);

        //     setTimeout(()=>{
        //       setAlert({show:false,type:'',message:''});
        //     },3000);
        //   }
        //     }
    };

    const deletePlanFeature = async (featureId) => {
        const response = await saasService.deletePlanFeature(featureId);
        if(!response.status && response.error.errorCode === 403){
            context.setIsAuthorized(context);
        }
        console.log(response);
        hideAlert();
        if (modified) {
            setModified(false);
            props.setModified(false);
        } else {
            setModified(true);
            props.setModified(true);
        }
        setFeatureLabel("");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleFeatureChange = (event) => {
        setFeatureLabel(event.name);
        setQuotaState("");
        setQuotaError("");
        if (event.type === "quota") {

            if (event.limit_type === "range") {
                setQuotaRange({
                    min: Number(event.min),
                    max: Number(event.max),
                });
                console.log({
                    min: Number(event.min),
                    max: Number(event.max),
                });
                setQuotaType("range");
            } else if (event.limit_type === "enum") {
                let tempEnumArray = [];
                event.enum_array.forEach((element) => {
                    tempEnumArray.push({
                        label: element,
                        value: element,
                    });
                });
                console.log(tempEnumArray);
                setEnumOptions(tempEnumArray);
                setQuotaType("enum");
            }
        } else {
            setQuotaType(false);
        }
        setCurrentFeature({
            ...currentFeature,
            feature_id: event.value,
            feature_quota: null,
            quota_duration : event.quota_duration,
        });
    };

    const handleChange = (event) => {
        setCurrentFeature({
            ...currentFeature,
            [event.target.name]: event.target.value,

        });
        setQuotaState("");
        setQuotaError("");
    };
    const toggleForm = (event) => {
        if (showForm) {
            setShowFrom(false);
        } else {
            setShowFrom(true);
        }
    };

    const handleEnumChange = (event) => {
        console.log(event);
        setCurrentFeature({
            ...currentFeature,
            feature_quota: event.value,
        });
        setQuotaState("");
        setQuotaError("");
    };



    const confirmationPopup = (feature_id) => {
       
        setShowPopup({
          alert: (
            <ReactBSAlert
              info
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure want to Delete ?"
              onConfirm={() =>{ deletePlanFeature(feature_id)}}
              onCancel={() =>{ hideAlert()}}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="primary"
              confirmBtnText="Yes, Delete it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to delete this feature!
            </ReactBSAlert>
          ),
        });
      }
      const hideAlert = () => {
        setShowPopup({
          alert: null,
        });
      };  
  


    const getTableData = () => {
        const table = [];
        let i = 1;

        planFeatures.forEach((feature) => {
            table.push(
                <tr>
                    <td className="text-left">{i}</td>
                    <td className="text-left">{feature.feature}</td>
                    <td className="text-left">{feature.quota}</td>
                    <td className="text-left">
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            //onClick={(event) => deletePlanFeature(feature.id)}
                            onClick={(event) => {confirmationPopup(feature.id)}}
                        >
                            Delete
                        </Button>
                    </td>
                </tr>
            );
            i++;
        });
        return table;
    };

    return (
        <div className="content">
            {showPopup.alert}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle
                                    tag="h4"
                                    onClick={toggleForm}
                                    style={{ cursor: "pointer" }}
                                >
                                    Add Features To Plan
                                    <b className="caret"></b>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {showForm ? (
                                    <>

                                            <Form
                                            className="form-horizontol"
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Label sm="2">Feature <span class="text-danger"> * </span></Label>
                                                <Col sm="7">
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Feature"
                                                        name="feature"
                                                        id="feature"
                                                        value={featureLabel}
                                                        options={featureOptions}
                                                        onChange={(value) =>
                                                            handleFeatureChange(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            {quotaType &&
                                            quotaType === "range" ? (
                                                <Row className={quotaState}>
                                                    <Label sm="2">Quota <span class="text-danger"> * </span></Label>
                                                    <Col sm="7">
                                                        <FormGroup>
                                                            <Input
                                                                name="feature_quota"
                                                                id="feature_quota"
                                                                type="number"
                                                                min={
                                                                    quotaRange.min
                                                                }
                                                                max={
                                                                    quotaRange.max
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            <div><label>Min : {quotaRange.min} Max : {quotaRange.max}</label></div>
                                                            {quotaState ===
                                                            "has-danger" ? (
                                                                <label className="error">
                                                                    {quotaError}
                                                                </label>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ""
                                            )}

                                            {quotaType &&
                                            quotaType === "enum" ? (
                                                <Row>
                                                    <Label sm="2">Quota <span class="text-danger"> * </span></Label>
                                                    <Col sm="7">
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Feature"
                                                            name="feature"
                                                            id="feature"
                                                            options={
                                                                enumOptions
                                                            }
                                                            onChange={(value) =>
                                                                handleEnumChange(
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        {quotaState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {quotaError}
                                                            </label>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ""
                                            )}
                                            {showSaveButton ? (
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    onClick={validateForm}
                                                >
                                                    Add
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                            {alert.show ? (
                                                <Alert color={alert.type}>
                                                    {alert.message}
                                                </Alert>
                                            ) : (
                                                ""
                                            )}

                                            {showLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                ""
                                            )}
                                        </Form><hr/>
                                        <CardTitle
                                    tag="h4"
                                   // onClick={toggleForm}
                                    //style={{ cursor: "pointer" }}
                                >
                                    Plan Features
                                   
                                </CardTitle>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th className="text-left">
                                                        #
                                                    </th>
                                                    <th className="text-left">
                                                        Name
                                                    </th>
                                                    <th className="text-left">
                                                        Quota
                                                    </th>
                                                    <th className="text-left">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!planFeatures ||
                                                !planFeatures.length ? (
                                                    !componentRendered ? (
                                                        <tr>
                                                            <td
                                                                colSpan="4"
                                                                className="text-center"
                                                            >
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                >
                                                                    <span className="sr-only">
                                                                        Loading...
                                                                    </span>
                                                                </Spinner>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td
                                                                colSpan="4"
                                                                className="text-center"
                                                            >
                                                                <CardTitle tag="h4">
                                                                    No Records
                                                                    Found
                                                                </CardTitle>
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : (
                                                    getTableData()
                                                )}
                                            </tbody>
                                        </Table>

                                    </>
                                ) : (
                                    ""
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddPlanFeature;
