import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import serviceStackService from "../../services/api/service-stack-service";
import ReactDatetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from "moment";
import {
  faLock,
  faExclamationCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import ReactBSAlert from 'react-bootstrap-sweetalert'
import UserContext from "../../UserContext";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table
} from "reactstrap";

const EditServiceStack = (props) => {
  const statusOptions = [
    { value: "", label: "All" },
    { value: "inactive", label: "Inactive" },
    { value: "available", label: "Available" },
    { value: "locked", label: "Locked" },
  ];
  const context = useContext(UserContext);
  const [showPopup, setShowPopup] = useState({
    alert: null
  })

  const classificationOptions = ["Basic", "Professional", "Business", "Enterprise"];
  const [serviceStackData, setServiceStackData] = useState(null)
  const [stackId, setStackId] = useState();

  useEffect(()=>{
      (async()=>{
            const serviceStackId = props.match.params["id"];
            setStackId(serviceStackId)
            const response = await serviceStackService.getServiceStackDetails(serviceStackId);

            console.log("service stack details ", response)

            if(response && response.status && response.data.serviceStackDetail){
                const responseData = response.data.serviceStackDetail;
                setServiceStackData({
                    name: responseData.name,
                    region: responseData.region,
                    status: responseData.status,
                    capacity: responseData.capacity,
                    classification: responseData.classification,
                    api_redis: responseData.api_redis,
                    turbo_redis: responseData.turbo_redis,
                    es_articles: responseData.es_articles,
                    cf_logs_bucket: responseData.cf_logs_bucket,
                    es_turbo_cflogs: responseData.es_turbo_cflogs,
                    route_cache_redis: responseData.route_cache_redis,
                })
            }
      })()
  },[])

  const hideAlert = () =>{
    setShowPopup({
      alert: null
    })
  }

  const updateServiceStackStatus = async(status) =>{
    const response =  await serviceStackService.updateServiceStackStatus({
      serviceStackId: stackId,
      status
    })
    console.log("response", response)
    if(!response.status && response.error.errorCode === 403){
      setShowPopup({
        alert:null
      });
      context.setIsAuthorized(context);
    }
    else if(!response.data.err){
      setShowPopup({
        alert: (
          <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Status Updated"
              onConfirm={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            />
        )
      })
      setServiceStackData({
        ...serviceStackData,
        status
      })
      setTimeout(()=>{
        hideAlert()
      },3000)
    }else{

      
      const {
        apiRedisConnection,
        esArticleConnection,
        esTurboConnection,
        turboRedisConnection
      } = response.data.service;
      
      setShowPopup({
        alert: (
          <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Unable to update the status"
              onConfirm={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              <p>
                Api Redis : { apiRedisConnection ? <FontAwesomeIcon style={{color:"green"}} icon={faCheckCircle}/>
                :<FontAwesomeIcon style ={{color:"red"}} icon={faExclamationCircle}/> }
              </p>
              <p>
                Turbo Redis : { turboRedisConnection ? <FontAwesomeIcon style={{color:"green"}} icon={faCheckCircle}/>
                :<FontAwesomeIcon style ={{color:"red"}} icon={faExclamationCircle}/> }
              </p>
              <p>
                Elasticsearch Article : { esArticleConnection ? <FontAwesomeIcon style={{color:"green"}} icon={faCheckCircle}/>
                :<FontAwesomeIcon style ={{color:"red"}} icon={faExclamationCircle}/> }
              </p>
              <p>
                Elasticsearch CF logs : { esTurboConnection ? <FontAwesomeIcon style={{color:"green"}} icon={faCheckCircle}/>
                :<FontAwesomeIcon style ={{color:"red"}} icon={faExclamationCircle}/> }  
              </p>
            </ReactBSAlert>
        )
      })
      setTimeout(()=>{
        hideAlert()
      },3000)
    }
    
  }

  const handleUpdateServiceStateStatus = () =>{
    const currentStatus = serviceStackData.status;

    if(currentStatus == "inactive"){
      setShowPopup({
        alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure to Activate the service stack"
              onConfirm={() => updateServiceStackStatus("available")}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              showCancel
              cancelBtnBsStyle="danger"
              btnSize=""
              confirmBtnText="Yes"
            />
        )
      })

    }

    if(currentStatus == "available"){
      setShowPopup({
        alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure to Lock the service stack"
              onConfirm={() =>updateServiceStackStatus("locked")}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
              showCancel
              confirmBtnText="Yes"
              cancelBtnBsStyle="danger"
            >
            </ReactBSAlert>

        )
      })

    }
  }

  return (
    <>
      <div className="content">
        {showPopup.alert ? showPopup.alert :null}
        <Container>
        {serviceStackData? 
          <Card className="card-signup text-center">
            <CardHeader>
              <CardTitle tag="h4">Service Stack details for - {serviceStackData.name}</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                  <Col md="6">
                    <Row>
                      <Col sm="6">
                        Status : {serviceStackData.status}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        Classification : {classificationOptions[serviceStackData.classification]}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        Region : {serviceStackData.region ? serviceStackData.region : "All"}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                  <Table responsive>
                    <thead className="text-primary">
                      <th className="text-left">Service Name</th>
                      <th className="text-left">Service Connection Details</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan="3">API Redis</td>
                      </tr>
                      <tr>
                        <td>Host : {serviceStackData.api_redis.host}</td>
                      </tr>
                      <tr>
                        <td>Port : {serviceStackData.api_redis.port} </td>
                      </tr>

                      <tr>
                        <td rowSpan="3">Turbo Redis</td>
                      </tr>
                      <tr>
                        <td>Host : {serviceStackData.turbo_redis.host}</td>
                      </tr>
                      <tr>
                        <td>Port : {serviceStackData.turbo_redis.port} </td>
                      </tr>

                      <tr>
                        <td rowSpan="3">Route Cache Redis</td>
                      </tr>
                      <tr>
                        <td>Host : {serviceStackData.route_cache_redis?.host}</td>
                      </tr>
                      <tr>
                        <td>Port : {serviceStackData.route_cache_redis?.port} </td>
                      </tr>

                      <tr>
                        <td rowSpan="3"> Articles Elasticsearch </td>
                      </tr>
                      <tr>
                        <td>Host : {serviceStackData.es_articles.host}</td>
                      </tr>
                      <tr>
                        <td>Port : {serviceStackData.es_articles.port} </td>
                      </tr>

                      <tr>
                        <td rowSpan="3">CF Logs Elasticsearch</td>
                      </tr>
                      <tr>
                        <td>Host : {serviceStackData.es_turbo_cflogs.host}</td>
                      </tr>
                      <tr>
                        <td>Port : {serviceStackData.es_turbo_cflogs.port} </td>
                      </tr>
                      <tr>
                        <td> CF-Logs Path Prefix </td>
                        <td>{serviceStackData.cf_logs_bucket.path_prefix} </td>
                      </tr>
                    </tbody>
                  </Table>
                  </Col>
                </Row>

                {
                  serviceStackData.status ==="inactive"? 
                    <Button className="btn btn-primary" onClick={handleUpdateServiceStateStatus}>
                        Activate
                    </Button>
                    :serviceStackData.status ==="available"?
                    <Button className="btn btn-danger" onClick={handleUpdateServiceStateStatus}>
                       <FontAwesomeIcon icon={faLock}/>  Lock
                    </Button>
                    :""
                }

            </CardBody>

          </Card>:""}
        
        </Container>
      </div>
    </>
  );
};

export default EditServiceStack;
