import React, { useEffect, useState, useContext } from "react";
import { useHistory,Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import permissionsService from "../../services/api/permission-group-service.js";
import usersService from "../../services/api/user-service.js";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faTrashRestoreAlt } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import UserContext from "../../UserContext";
const PermissionDetails = (props) => {

    const history = new useHistory();
    let permissionGroupId = '';
    const context = useContext(UserContext);
    const [permission, setPermission] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const [permissions, setPermissions] = useState('');
    const [permissionGroup, setPermissionGroup] = useState([]);
    const [formdetails, setFormDetails] = useState({ permission_Group: permissionGroupId,status:1 });
    const [search, setSearchDetails] = useState({ permission_Group: permissionGroupId, status:1 });
    // const [permissionGroupArray, setPermissionGroupArray] = useState([]);
    // const [permissionsArray, setPermissionsArray] = useState([]);
    const [permissionGroupOptions, setPermissionGroupOptions] = useState([])
    const [activePage,setActivePage] = useState(1);
    const [totalPermissionGroup, setTotalPermissionGroup] = useState(0);
    const records = 10;

    let statusArray = [
        {
            value: 1,
            label: "Active",
        },
        {
            value:0,
            label:"Deleted",
        },
        {
            value:"all",
            label:"All",
        },
    ];



    // useEffect(() => {
    //     (
    //         async () => {
    //             let permissionData = await permissionsService.getPermissions();
    //             let permissionGroupData = await usersService.getPermissionGroup();

    //             // Code for showing permission group names in dropdown starts
    //             // console.log("permissionGroupData",permissionGroupData)
    //             if(!permissionGroupData.status && permissionGroupData.error.errorCode === 403){
    //                 history.push("/unauth/unauthorized")
    //             }
    //             else if (permissionData != "" && permissionGroupData != "") {
    //                 let tempPermissionGroupOptions = [];

    //                 if (permissionGroupData && permissionGroupData.data.length) {

    //                     for (const data of permissionGroupData.data) {
    //                         tempPermissionGroupOptions.push({
    //                             value: data._id,
    //                             label: data.name
    //                         })
    //                     }

    //                     setPermissionGroupOptions([
    //                         ...tempPermissionGroupOptions
    //                     ])
    //                 }
    //                 // Code for showing permission group names in dropdown ends

    //                 // if(permissionData && permissionData.data){
    //                 //     allPermissions = permissionData.data;
    //                 // }
    //                 // if(permissionGroupData && permissionGroupData.data){
    //                 //     allPermissionGroup = permissionGroupData.data;
    //                 // }


    //                 // Code for showing permissions names in dropdown starts
    //                 // console.log("in view: ", permissionData)
    //                 // if(permissionData && permissionData.data.length){

    //                 //     for(const data of permissionData.data){
    //                 //         console.log(data)
    //                 //         permissionsArray.push({
    //                 //             value: data.permissions,
    //                 //             label: data.permissions
    //                 //         })
    //                 //     }

    //                 //     setPermissionsArray([
    //                 //         ...permissionsArray
    //                 //     ])
    //                 // }
    //                 // let allPermissions = [];
    //                 // let permissionValue = [];
    //                 // let defaultPermission = '';

    //                 // if(allpermissionsService.length ){
    //                 //     allpermissionsService.forEach(function(PermissionDetails){
    //                 //         permissionValue.push({value:PermissionDetails._id,label:PermissionDetails.permission});
    //                 //         if(permissions!='' && permissions === PermissionDetails.id){
    //                 //             defaultPermission = {value:PermissionDetails.id, label:permission.permission}
    //                 //         }
    //                 //     })
    //                 // }
    //                 // // console.log("permissionGroupValue",permissionGroupValue);
    //                 // setPermissionsArray(permissionValue);
    //                 // setPermissions(defaultPermission);
    //             }
    //         }
    //     )();
    // }, []);

    useEffect(() => {
        (
            async () => {
                // console.log("in search user")
                let permissiongroupData = await permissionsService.getPermissionGroupList({filters:formdetails,activePage,records});
                // console.log(permissiongroupData);
                if (permissiongroupData.status) {
                    setPermission(permissiongroupData.data.permissionGroup);
                    setTotalPermissionGroup(permissiongroupData.data.count);

                    let permissionGroupList = permissiongroupData.data.permissionGroup;
                    let permissionGroupOptionsList = [];

                    for(let i in permissionGroupList){
                        permissionGroupOptionsList.push({
                            value:permissionGroupList[i].permissionGroupId,
                            label: permissionGroupList[i].name,
                        })
                    }

                    setPermissionGroupOptions([
                        ...permissionGroupOptionsList
                    ])

                }
                setRenderComponent(true);
            }
        )();
    }, [reloadComponent, search,activePage]);

    const suspendPermissionGroupPopUp = (permissionGroupId) => {
        // console.log(permissionGroupId)
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => suspendPermissionGroup(permissionGroupId)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle='info'
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Remove it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to remove this Permission Group!
                </ReactBSAlert>
            )
        });
    }

    const restorePermissionGroupPopup = (permissionGroupId) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display:"block", marginTop:"-100px"}}
                    title="Are you sure?"
                    onConfirm={() => restorePermissionGroup(permissionGroupId)}
                    onCancel = {() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="daner"
                    confirmBtnText="Yes, Restore it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to restore this Permission Group Again!
                </ReactBSAlert>
            ),
        });
    }

    const restorePermissionGroup = async (PermissionGroupId) => {
        let restore = await permissionsService.restorePermissionGroup(PermissionGroupId);
        // console.log("PermissionGroupId: ", suspend.status)
        if(!restore.status && restore.error.errorCode === 403){
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context)
        }
        if (restore.status && restore.data.restored) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Restore!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle='info'
                        btnSize=""
                    >
                        Permission Group Restored Successfully.
                    </ReactBSAlert>
                )
            });
            setReloadComponent(Math.random());
        }
        else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnStyle='info'
                        btnSize=""
                    >
                        Permission Group could not be restored. Please try again!
                    </ReactBSAlert>
                ),
            });
        }
    }

    const suspendPermissionGroup = async (PermissionGroupId) => {
        let suspend = await permissionsService.suspendPermissionGroup(PermissionGroupId);
        // console.log("PermissionGroupId: ", suspend.status)
        if(!suspend.status && suspend.error.errorCode === 403){
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context);
        }
        if (suspend.status && suspend.data.deleted) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Removed!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle='info'
                        btnSize=""
                    >
                        Role Removed Successfully.
                    </ReactBSAlert>
                )
            });
            setReloadComponent(Math.random());
        }
        else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle='info'
                        btnSize=""
                    >
                        Role could not be removed. Please check Role is not assigned to any user!
                    </ReactBSAlert>
                ),
            });
        }
    }

    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };

    const redirectUrl = (e,permissionGroupId) => {
        // console.log(permissionGroupId)
        e.preventDefault()
        history.push("/permissions/editpermissiongroup?permissionGroupId="+permissionGroupId)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    let permissionElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!permission || !permission.length) {
        if (!renderComponent) {
            permissionElements.push(
                <tr>
                    <td colSpan='5' className='text-center'>
                        <Spinner animation="border" role='status'>
                            <span className='sr-only'>Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            );
        }
        else {
            permissionElements.push(
                <tr>
                    <td colSpan='5' className='text-center'>
                        <CardTitle tag='h4'>No records Found</CardTitle>
                    </td>
                </tr>
            );
        }
    }
    else {
        let i = 1;
        permission.forEach(function (permissionDetails) {
            let className = '';
            if (!permissionDetails.status) {
                className = 'table-warning';
            }
            if(i == 1){
                firstRecord = (activePage-1)*records+i;
            }
            permissionElements.push(
                <tr className={className}>
                    <td className='text-left'>
                        {(records*(activePage-1))+i}
                    </td>
                    <td className='text-left'>
                        {permissionDetails.name}
                    </td>
                    <td className='text-left'>
                        {permissionDetails.permissionGroupCode}
                    </td>
                    <td className='text-left'>
                        {permissionDetails.TotalPermission}
                    </td>
                    <td className="text-left">
                        {permissionDetails.status === 1 ?
                            <span>
                                <a title="Edit" onClick={(event) => redirectUrl(event, permissionDetails.permissionGroupId)} href="javascript:void(0)" className="nav-link">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </a>
                                <a title="Remove" onClick={(event) => suspendPermissionGroupPopUp(permissionDetails.permissionGroupId)} href="javascript:void(0)" className="nav-link">
                                    <FontAwesomeIcon icon={faTrash} />
                                </a>
                            </span>
                            :
                            <a title="Restore" onClick={(event)=>  restorePermissionGroupPopup(permissionDetails.permissionGroupId) } href="javascript:void(0)" class="nav-link">
                                {/* <i class="nc-icon nc-refresh-69"></i> */}
                                <FontAwesomeIcon icon={faTrashRestoreAlt} />
                            </a> 
                            // <td className="text-left">
                            //     Removed
                            // </td>
                        }
                    </td>
                </tr>
            )
            if(i == permission.length){
                lastRecord = (activePage-1) * records +i;
            }
            i++;
            if(i == permission.length){
                lastRecord = (activePage-1) * records + i;
            }
        })
    }

    const handleSubmit = function (e) {
        // console.log("in sub")
        searchPermissionGroup();
        e.preventDefault()
    }

    const changePermissionGroup = (event) => {
        // console.log("in view", event)
        setFormDetails({
            ...formdetails,
            permission_Group: { value: event.value, label: event.label },
        });
        setPermissionGroup({ value: event.value, label: event.label })
    }

    // const changePermissions = (event) => {
    //     setFormDetails({
    //         ...formdetails,
    //         permissions: event.value,
    //     });
    //     setPermissions(event)
    // }

    const changeStatus = (event)=>{
        setFormDetails({
            ...formdetails,
            status: event.value,
        });
    };
    const searchPermissionGroup = () => {
        // console.log("in serch: ",formdetails);
        setSearchDetails(formdetails);
    };

    const resetForm = () => {
        setPermissions('')
        setPermissionGroup('')
        setFormDetails({
            permission_Group: '',
            permissions: '',
        });
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
    }

    return (
        <>
            <div className="content">
                {showPopup.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <Row>
                                        <Col>
                                        <div style={{'float':'right'}}>
                                        <Button
                                        className='btn-round'
                                        color='danger'
                                        size='sm'
                                        type='button'
                                        onClick={(event) => {
                                            history.push("/permissions/create-permission-group")
                                        }}
                                    >
                                        Create Role
                                    </Button>
                                        </div>
                                        </Col>
                                    </Row>
                                    <CardTitle tag="h3">
                                        Permission Management
                                    </CardTitle>
                                    <Form action='' method='POST' className='permForm' onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md='4'>
                                                <Select
                                                    multiple="true"
                                                    className="react-select primary"
                                                    classNamePrefic="react-select"
                                                    placeholder="Select Role"
                                                    name="permissionGroup"
                                                    id="permissionGroup"
                                                    options={permissionGroupOptions}
                                                    value={permissionGroup}
                                                    onChange={changePermissionGroup}
                                                />

                                            </Col>
                                            <Col md="4">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder ="Select Status.."
                                                name="status"
                                                id="status"
                                                options={statusArray}
                                                value={formdetails.status !== ""?statusArray.filter(option=>option.value == formdetails.status):""}
                                                onChange={changeStatus}
                                            />

                                            </Col>
                                            {/* <Col md='4'>
                                                <Select
                                                    multiple='true'
                                                    className='react-select primary'
                                                    classNamePrefic = 'react-select'
                                                    placeholder='Select Permissions'
                                                    name='permissions'
                                                    id='permissions'
                                                    options = {permissionsArray}
                                                    value = {permissions}
                                                    onChange = {changePermissions}
                                                />
                                            </Col> */}
                                            <Col md="4">
                                                <div style={{'float':'left'}}>
                                                <Button
                                                    className='btn-round'
                                                    color='info'
                                                    type='submit'
                                                // onClick={searchPermissionGroup}
                                                >
                                                    Search
                                                </Button>
                                                <Button
                                                    className='btn-round'
                                                    color='warning'
                                                    type='button'
                                                    onClick={resetForm}
                                                >
                                                    Reset
                                                </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Form>
                                    <hr></hr>
                                    {totalPermissionGroup > 0 ? 
                                        <Row>

                                                <Col md="6">
                                                    <div style={{float:'right',margin:'5px'}}>
                                                        <b>{firstRecord} - {lastRecord} of {totalPermissionGroup}</b>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div style={{float:'left'}}>
                                                        <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={records}
                                                        totalItemsCount={totalPermissionGroup}
                                                        pageRangeDisplayed={3}
                                                        onChange={handlePageChange.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        />
                                                    </div>
                                                </Col>

                                        </Row>
                                    : null}
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th className="text-left">#</th>
                                                <th className="text-left">Role</th>
                                                <th className="text-left">Role Code</th>
                                                <th className="text-left">Total Permissions</th>
                                                <th className="text-left">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {permissionElements}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );

}

export default PermissionDetails;



