import axios from 'axios';

const getSystemDetails=async() =>{
    const response=await axios.get("/api/system/get-system-details");
    return response.data;
    }

const getProjectStats =async(unit) =>{
    const formdata={
    unit:unit
    };
    const response=await axios.post("/api/system/get-project-stats",formdata);
    return response.data;
    }

const getPublisherStats =async(unit) =>{
        const formdata={
        unit:unit
        };
        const response=await axios.post("/api/system/get-publisher-stats",formdata);
        return response.data;
        }
        
const getTransactionStats =async(unit) =>{
    const formdata={
    unit:unit
    };
    const response=await axios.post("/api/system/get-transaction-stats",formdata);
    return response.data;
    }

const getContractStats =async(unit) =>{
    const formdata={
    unit:unit
    };
    const response=await axios.post("/api/system/get-contract-stats",formdata);
    return response.data;
    }
    

export  default {
    getSystemDetails,
    getProjectStats,
    getPublisherStats,
    getTransactionStats,
    getContractStats
}