import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ticketsService from "../../services/api/zoho-desk-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const ZohoDeskCreateTicket = (props) => {

  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({ subject: '', departmentId: '', publisher: '', project_id: '', project_name: '', productId: '', contact: { name: '', email: '' }, priority: '', description: '' });
  const [subjectState, setSubjectState] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [departmentState, setDepartmentState] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [publisherState, setPublisherState] = useState("");
  const [publisherError, setPublisherError] = useState("");
  // const [firstNameState, setFirstNameState] = useState("");
  // const [firstNameError, setFirstNameError] = useState("");
  // const [lastNameState, setLastNameState] = useState("");
  // const [lastNameError, setLastNameError] = useState("");
  // const [emailState, setEmailState] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [priorityState, setPriorityState] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  // const [statusState, setStatusState] = useState("");
  // const [statusError, setStatusError] = useState("");
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [products, setProducts] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [publisher, setPublisher] = useState('');
  const [projectNameArray, setProjectNames] = useState([]);
  const [projectId, setProjectId] = useState('');

  useEffect(() => {
    (async () => {
      let departmentsData = await ticketsService.getDepartments();
      if (departmentsData.status && departmentsData.data) {
        let departmentNames = departmentsData.data;
        let departmentValues = [];
        let i = 1;
        Object.keys(departmentNames).forEach(function (key) {
          departmentValues.push({
            value: departmentNames[key].id,
            label: departmentNames[key].name,
          });
          i++;
        });
        setDepartments(departmentValues);
      }

    })();
  }, []);

  useEffect(() => {
    (async () => {
      let publishersData = await ticketsService.getAllPublishers();
      if (publishersData.status && publishersData.data) {
        console.log("DETAILS", publishersData.data)
        let publishers = publishersData.data;
        let publisherValues = [];
        let i = 1;
        Object.keys(publishers).forEach(function (key) {
          publisherValues.push({
            value: publishers[key].id,
            label: publishers[key].name,
            email: publishers[key].email
          });
          i++;
        });
        setPublishers(publisherValues);
      }

    })();
  }, []);

  useEffect(() => {
    (async () => {
      let projects = await projectsService.getPublisherProjects(publisher);
      let publisherProjects = [];
      if (projects.status) {
        let projectData = projects.data;
        if (projectData.length) {
          projectData.forEach(function (projectDetails) {
            let projectValue = { value: projectDetails.id, label: projectDetails.name, name: projectDetails.name };
            publisherProjects.push(projectValue)
          })
        }
        setProjectNames(publisherProjects);
      }
      // }
    })();
  }, [publisher]);


  useEffect(() => {
    (async () => {
      const department = formdetails.departmentId;
      let ProductsData = await ticketsService.getProducts(department);
      if (ProductsData.status && ProductsData.data) {
        if (ProductsData.data.length === 0) {
          console.log("FIRST EXECUTED")
          let productName = formdetails.project_name;
          let departmentid = formdetails.departmentId;
          let response = await ticketsService.createProduct(productName, departmentid);
          if (response.status && response.data) {
            setFormDetails({
              ...formdetails,
              productId: response.data.id
            });
          }
        }
        else if(ProductsData.data.length > 0){
          let count = 0;
          console.log("SECOND EXECUTED")
          for(let i=0;i<ProductsData.data.length;i++){
            if(ProductsData.data[i].productName === formdetails.project_name){
            setFormDetails({
              ...formdetails,
              productId : ProductsData.data[i].id
          });
          count = count + 1;
            }
          }

          if(count === 0){
            console.log("THIRD EXECUTED")
            let productName = formdetails.project_name;
            let departmentid = formdetails.departmentId;
            let response = await ticketsService.createProduct(productName, departmentid);
            if (response.status && response.data) {
              setFormDetails({
                ...formdetails,
                productId: response.data.id
              });
            }
          }
        }
      }
    })();
  }, []);


  const priorityArray = [
    {
      value: "High",
      label: "High",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Low",
      label: "Low",
    }
  ];
  const statusArray = [
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Closed",
      label: "Closed",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Escalated",
      label: "Escalated",
    }
  ]

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value,
    });
    console.log(event.target.value);
    if (event.target.id === "subject") {
      setSubjectState("");
      setSubjectError("");
    }
    if (event.target.id === "departmentId") {
      setDepartmentState("");
      setDepartmentError("");
    }

    if (event.target.id === "publisher") {
      setPublisherState("");
      setPublisherError("");
    }
    if (event.target.id === "priority") {
      setPriorityState("");
      setPriorityError("");
    }
    if (event.target.id === "description") {
      setDescriptionState("");
      setDescriptionError("");
    }
    //   if(event.target.id==="status"){
    //     setStatusState("");
    //     setStatusError("");
    // }

  }

  const changeDepartment = (event) => {
    setFormDetails({
      ...formdetails,
      departmentId: event.value,
    });
    setDepartmentState("has-sucess");
    setDepartmentError("");
  };


  const changePublisher = (event) => {
    setFormDetails({
      ...formdetails,
      publisher: event.value,
      name: event.label,
      email: event.email
    })
    setProjectId('');
    setPublisherState("has-sucess");
    setPublisherError("");
    setPublisher(event.value);
  };

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
      project_name: event.name
    });
    setProjectId(event)
  }


  const changePriority = (event) => {
    setFormDetails({
      ...formdetails,
      priority: event.value,
    });
    setPriorityState("has-sucess");
    setPriorityError("");
  };

  //   const changeStatus = (event) => {
  //     setFormDetails({
  //         ...formdetails,
  //         status: event.value,
  //     });
  //     setStatusState("has-sucess");
  //     setStatusError("");
  // };

  const validateSubject = () => {
    if (formdetails.subject.trim() === '') {
      setSubjectState("has-danger");
      setSubjectError("This field is required");
      return false;
    }
    setSubjectState("has-success");
    return true;
  }
  const validateDepartment = () => {
    if (formdetails.departmentId === '') {
      setDepartmentState("has-danger");
      setDepartmentError("This field is required");
      return false;
    }
    setDepartmentState("has-success");
    return true;
  }

  console.log("DEPARTMENT ID", formdetails.departmentId);
  console.log("PROJECT NAME", formdetails.project_name)
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }


  const validateForm = async () => {

    let subjectValidation = validateSubject();
    let departmentValidation = validateDepartment();
    //let emailValidation = validateEmail();
    //let priorityValidation = validatePriority();
    //let descriptionValidation = validateDescription();
    //let statusValidation = validateStatus();

    if (subjectValidation && departmentValidation) {
      setShowLoader(true);
      setShowSaveButton(false);
      let response = await ticketsService.createTicket(formdetails);
      console.log(response.data);
      if (response.status && response.data && response.data.code != 'ERR_BAD_REQUEST') {
        setTimeout(() => {
          setShowLoader(false)
          setAlert({ show: true, type: 'success', message: 'Ticket was created Successfully' });
        }, 1000);

        setTimeout(() => {
          setAlert({ show: false, type: '', message: '' });
        }, 3000);

        setTimeout(() => {
          history.push("/zohodesk/dashboard");
        }, 3000);

      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Ticket could not be created, Please try again.' });
        }, 1000);

        setTimeout(() => {
          setAlert({ show: false, type: '', message: '' });
        }, 3000);
      }
    }

    // let response = await ticketsService.createTicket(formdetails);
    // if(response.status){
    //     setTimeout(()=>{
    //     setShowLoader(false)
    //     setAlert({show:true,type:'success',message:'Ticket was created Successfully'});
    //     },1000);

    //     setTimeout(()=>{
    //     setAlert({show:false,type:'',message:''});
    //     },3000);

    //     setTimeout(()=>{
    //     history.push("/zohodesk/dashboard");
    //     },4000);

    // }
  }

  const getDepartmentValue = () => {
    let departmentValue = "";
    if (formdetails.departmentId) {
      departmentValue = departments.length ? departments[0] : "";

      departments.forEach(function (deptdetails) {
        if (deptdetails.value == formdetails.departmentId) {
          departmentValue = deptdetails;
        }
      });
    }
    return departmentValue;
  };

  const getPublisherValue = () => {
    let publisherValue = "";
    if (formdetails.publisher) {
      publisherValue = publishers.length ? publishers[0] : "";

      publishers.forEach(function (pubdetails) {
        if (pubdetails.value == formdetails.publisher) {
          publisherValue = pubdetails;
        }
      });
    }
    return publisherValue;
  };

  const getPriorityValue = () => {
    let priorityValue = "";
    if (formdetails.priority) {
      priorityValue = priorityArray.length ? priorityArray[0] : "";

      priorityArray.forEach(function (prioritydetails) {
        if (prioritydetails.value == formdetails.priority) {
          priorityValue = prioritydetails;
        }
      });
    }
    return priorityValue;
  };

  //   const getStatusValue = () => {
  //     let statusValue="";
  //    if (formdetails.status) {
  //        statusValue = statusArray.length ? statusArray[0] : "";

  //        statusArray.forEach(function (statusdetails) {
  //            if (statusdetails.value == formdetails.status) {
  //                statusValue = statusdetails;
  //            }
  //        });
  //    }
  //    return statusValue;
  // };


  console.log("FORM-DETAILS", formdetails)

  return <>
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Create a Ticket</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontol" action="" id="createTicket" method="POST" onSubmit={handleSubmit} autocomplete="off">

                  <Row>
                  <Label sm="2">Subject: <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup className={subjectState}>
                        <Input
                          name="subject"
                          id="subject"
                          type="text"
                          onChange={handleChange}
                        />
                        {subjectState === "has-danger" ? (
                          <label className="error">
                            {subjectError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Label sm="2">Department: <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="department"
                          value={getDepartmentValue()}
                          onChange={changeDepartment}
                          options={departments}
                        />
                        {departmentState === "has-danger" ? (
                          <label className="error">
                            {departmentError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Label sm="2">Publisher: <span class="text-danger"> * </span></Label>
                    <Col sm="7">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="publisher"
                          value={getPublisherValue()}
                          onChange={changePublisher}
                          options={publishers}
                        />
                        {publisherState === "has-danger" ? (
                          <label className="error">
                            {publisherError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Project:</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="project"
                          value={projectId}
                          onChange={changeProjectId}
                          options={projectNameArray}
                        />
                        {publisherState === "has-danger" ? (
                          <label className="error">
                            {publisherError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Priority:</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="priority"
                          value={getPriorityValue()}
                          onChange={changePriority}
                          options={priorityArray}
                        />
                        {priorityState === "has-danger" ? (
                          <label className="error">
                            {priorityError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Descriptiom:</Label>
                    <Col sm="7">
                      <FormGroup className={descriptionState}>
                        <Input
                          name="description"
                          id="description"
                          type="text"
                          onChange={handleChange}
                        />
                        {descriptionState === "has-danger" ? (
                          <label className="error">
                            {descriptionError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showSaveButton ?
                    <>
                      <Button
                        className="btn-round"
                        color="info"
                        type="button"
                        onClick={validateForm}
                      >
                        Create
                      </Button>
                      <Button
                        className="btn-round"
                        color="danger"
                        type="button"
                        onClick={(event) => { history.push(`/zohodesk/dashboard`) }}
                      >
                        Cancel
                      </Button>
                    </>
                    : ''}
                  {alert.show ? <Alert color={alert.type}>
                    {alert.message}
                  </Alert> : ''}

                  {showLoader ? <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner> : ''}

                </Form>
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>


}
export default ZohoDeskCreateTicket;
