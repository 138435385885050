/**
 * @module PermissionService
 * @requires axios
 */
 import axios from "axios";

 /**
  * This service is used to get permission list
  * @returns return permission list
  */
 const getPermissions = async () => {
     // console.log("in api of get permission");
     const response = await axios.post("/api/permissions/get-permissions");
     return response.data;
 }
 
 /**
  * This service is used to create permission group
  * @param {JSON} formdata formdata
  * @returns return true if permission is creatred successfully otherwise return error
  */
 const createPermissionGroup = async(formdata) => {
    //  console.log("in api: ",formdata)
     const response = await axios.post("/api/permissions/create-permission-group",formdata);
     return response.data;
 }
 
 /**
  * This service is used to get permission group list
  * @param {JSON} formdata formdata
  * @returns return permission group list
  */
 const getPermissionGroupList = async(formdata) =>{
     // console.log("in api : ", formdata)
     const response = await axios.post("/api/permissions/permission-group-list",formdata);
     return response.data;
 }
 
 // const editPermissionGroup = async() =>{
 //     const response = await axios.get("/api/permissions/edit-permission-group");
 //     return response.data;
 // }
 
 /**
  * This service is used to update the status of permission group as false
  * @param {Objectid} PermissionGroupId permission group id
  * @returns return suspend status of permission group
  */
 const suspendPermissionGroup = async(PermissionGroupId) => {
     const formdata = {
         permissionGroupId: PermissionGroupId
     }
     // console.log("in api: ",formdata);
     const response = await axios.post('/api/permissions/suspend-permission-group',formdata);
     return response.data;
 }
 
 /**
  * This service is used to update the status of permission group as true again
  * @param {Objectid} PermissionGroupId permission group Id
  * @returns return restore staus of permission group
  */
 const restorePermissionGroup = async(PermissionGroupId) => {
     const formdata = {
         permissionGroupId: PermissionGroupId
     }
    //  console.log("in api: ",formdata);
     const response = await axios.post('/api/permissions/restore-permission-group',formdata);
     return response.data;
 }
 
 /**
  * This service is used to update the details of permission group
  * @param {JSON} formdata formdata
  * @returns return true if permission group is updated successfully otherwise return error
  */
 const savePermissionGroup = async(formdata) =>{
    //  console.log(formdata);
     const response = await axios.post('/api/permissions/save-permission-group',formdata);
     return response.data;
 }
 
 /**
  * This service is used to get pemrission group details based on permission group id
  * @param {ObjectId} permissionGroupId permission Group id
  * @returns return permission group details
  */
 const getPermissionGroupDetails = async(permissionGroupId) => {
     const formdata = {
         permissionGroupId : permissionGroupId
     }
     const response = await axios.post('/api/permissions/get-permission-group-details',formdata);
     return response.data;
 }
 
 const getSelectedPermission = async(permissionGroupList) => {
     const formdata = {
         permissionGroupList: permissionGroupList
     }
    //  console.log("in api: ",formdata)
 
     const response = await axios.post('/api/permissions/get-selected-permission',formdata);
     return response.data
 }
 const getSelectedPermissionList = async(permissionGroupId)=>{
     const formdata = {
         permissionGroupId : permissionGroupId,
     }
     const response = await axios.post('/api/permissions/get-selected-permission-list',formdata);
     return response.data;
 }
 
 export default{
     getPermissions,
     createPermissionGroup,
     getPermissionGroupList,
     // editPermissionGroup,
     suspendPermissionGroup,
     savePermissionGroup,
     getPermissionGroupDetails,
     restorePermissionGroup,
     getSelectedPermission,
     getSelectedPermissionList
 }