import axios from "axios";

const createTemplate=async(formdata) =>{
    const response=await axios.post('/api/template/create-template',formdata);
    return response.data;
}
const getTemplates = async() =>{
    const response = await axios.get('/api/template/get-templates');
    return response.data;
}

const getTemplateDetails=async(formdata) =>{
    const response=await axios.post('/api/template/get-template-data',formdata);
    return response.data;
}

const editTemplate = async(formdata) => {
    const response=await axios.post('/api/template/edit-template-details',formdata);
    return response.data;
}

const getConfigSchema = async() =>{
    const response = await axios.get('/api/template/config-schema');
    return response.data;
}

const getSavedConfig = async(templateId) =>{
    let data = {templateId:templateId}
    const response = await axios.post('/api/template/saved-config',data);
    return response.data;
}

const storeConfig = async(templateId,config_type,config) =>{
    let data = {templateId:templateId,groupName:config_type,formdata:config}
    const response = await axios.post('/api/template/store-config',data);
    return response.data;
}

const uploadImage = async(templateId,fieldId,imageData) =>{
    let data = {templateId:templateId,fieldId:fieldId,imageData:imageData}
    const response = await axios.post('/api/template/upload-image',data);
    return response.data;
}
const publishTemplate = async(templateId) =>{
    const response = await axios.post('/api/template/publish-template',{templateId})
    return response.data;
}
const deactivateTemplate = async(templateId) =>{
    const response = await axios.post('/api/template/deactivate-template',{templateId})
    return response.data;
}

const addPlanGroupTemplate = async(formdata) =>{

    console.log("FormData",formdata);
    const response = await axios.post('/api/template/add-plan-group-template',formdata)
    return response.data;
}

const removePlanGroupTemplate = async(plan_group_id,template_id) =>{

    const response = await axios.post('/api/template/remove-plan-group-template',{plan_group_id,template_id})
    return response.data;
}
const getPlanGroupTemplates = async(plan_group_id) =>{
    const response = await axios.post('/api/template/get-plan-group-templates',{plan_group_id})
    return response.data;
}

const getAppTemplates = async() =>{
    const response = await axios.get('/api/template/get-app-templates');
    return response.data;
}

const publishAppTemplate = async(templateId) =>{
    const response = await axios.post('/api/template/publish-app-template',{templateId})
    return response.data;
}
const deactivateAppTemplate = async(templateId) =>{
    const response = await axios.post('/api/template/deactivate-app-template',{templateId})
    return response.data;
}

const createAppTemplate = async(formdata) =>{
    const response=await axios.post('/api/template/create-app-template',formdata);
    return response.data;
}

const getAppSavedConfig = async(templateId) =>{
    let data = {templateId:templateId}
    const response = await axios.post('/api/template/app-saved-config',data);
    return response.data;
}

const storeAppConfig = async(templateId,config_type,config) =>{
    let data = {templateId:templateId,groupName:config_type,formdata:config}
    const response = await axios.post('/api/template/store-app-config',data);
    return response.data;
}

const uploadAppTemplateImage = async(templateId,fieldId,imageData) =>{
    let data = {templateId:templateId,fieldId:fieldId,imageData:imageData}
    const response = await axios.post('/api/template/upload-app-image',data);
    return response.data;
}

const getAppConfigSchema = async() =>{
    const response = await axios.get('/api/template/app-config-schema');
    return response.data;
}

const getSortdAppTemplates = async()=> {
    const response = await axios.post('/api/template/get-sortd-app-templates');
    return response.data;
}

const getCtvAppTemplates = async() =>{
    const response = await axios.get('/api/template/get-ctv-app-templates');
    return response.data;
}

export default {
    createTemplate,
    getTemplates,
    getAppTemplates,
    getConfigSchema,
    getSavedConfig,
    storeConfig,
    uploadImage,
    publishTemplate,
    deactivateTemplate,
    addPlanGroupTemplate,
    removePlanGroupTemplate,
    getPlanGroupTemplates,
    publishAppTemplate,
    deactivateAppTemplate,
    createAppTemplate,
    getAppSavedConfig,
    storeAppConfig,
    uploadAppTemplateImage,
    getAppConfigSchema,
    getSortdAppTemplates,
    getCtvAppTemplates,
    getTemplateDetails,
    editTemplate,
}