import React, { useEffect, useState, useContext } from "react";
import projectsService from "../../services/api/projects-service";
import couponService from "../../services/api/coupon-service";
import saasService from '../../services/api/saas-service'
import {useHistory} from "react-router-dom";
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import UserContext from "../../UserContext"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const CreateCoupon = (props)=> {
  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({
        publisher_ids : [],
        project_ids : [],
        plan_ids : [],
        coupon_code : '',
        description : '',
        discount_type : 'percentage',
        discount_percentage : '1',
        discount_flat : 0,
        limit_amount : null,
        max_usage : '1',
        start_date : '',
        expires_at : '',
        platform_type : 'pwa'
  });
  const context = useContext(UserContext);
  const [publisher, setPublisher] = useState('');
  const [publisherFieldValue, setPublisherFieldValue] = useState('');
  const [projectFieldValue, setProjectFieldValue] = useState('');
  const [allProjects, setAllProjects] = useState([]);
  const [allPublishers, setAllPublishers] = useState([]);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [fieldStates,setFieldStates] = useState({});
  const [activeCoupons, setActiveCoupons] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [plans,setAllPlans] = useState([]);
  useEffect( () => {
    (async () =>{
        if(formdetails.project_id){
            let coupons = await couponService.getListOfActiveCoupons();
            if(coupons.status && coupons.data){
                setActiveCoupons(coupons.data);
            }
        }
    })();
  }, []);
  useEffect( () => {
    (async () =>{
        // if(publisher){
            let projects = await projectsService.getPublisherProjects();
            let publisherProjects = [];
            if(projects.status){
                let projectData = projects.data;
                if(projectData.length){
                    projectData.forEach(function(projectDetails) {
                        let projectValue = {value:projectDetails.id,label:projectDetails.name};
                        publisherProjects.push(projectValue)
                    })
                }
                setAllProjects(publisherProjects);
            }
        // }
    })();
  }, []);

  useEffect( () => {
    (async () =>{
        let publishers = await projectsService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
        }
    })();
  }, []);
    useEffect( () => {
    (async () =>{
        let plans = await saasService.getPlans();
        if(plans.status && plans.data){
            let getPlans = [];
            let planData = plans.data.planList;
            if(planData.length){
                planData.forEach(function(planDetails) {
                  if(planDetails.is_published === 1) {
                    let publisherValue = {value: planDetails.id, label:planDetails.name?planDetails.name:''+"--"+planDetails.type};
                    getPlans.push(publisherValue)
                  }
                    
                })
            }
            setAllPlans(getPlans);
        }
    })();
  }, []);
  

  const changePublisher = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    fieldStates["error_publisher_id"] = "has-success";
    fieldStates["error_message_publisher_id"] = "";
    
    setFieldStates({
      ...fieldStates,
      fieldStates
    });
    setProjectFieldValue('');
    setPublisher(event.value);
    setPublisherFieldValue(event);
  }

  const changeProject = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    fieldStates["error_project_id"] = "has-success";
    fieldStates["error_message_project_id"] = "";
    
    setFieldStates({
      ...fieldStates,
      fieldStates
    });
    setProjectFieldValue(event);
  }


const handleChange = (event) => {
    setFormDetails({
        ...formdetails,
        [event.target.id]: event.target.value,
    });
    fieldStates["error_"+event.target.id] = "has-success";
    fieldStates["error_message"+event.target.id] = "";
    
    setFieldStates({
      ...fieldStates,
      fieldStates
    });
};

  const validateDuplicateCoupon = ()=>{
        let isDuplicate = false;
        activeCoupons.filter(function(coupon_code) {
            if(formdetails.coupon_code === coupon_code){
              isDuplicate = true;
            }
        });
        return isDuplicate;
  }
  const validateFields = () => {
        let validations = true;
        // if(formdetails.publisher_id===''){
        //     fieldStates['error_publisher_id'] = 'has-danger';
        //     fieldStates['error_message_publisher_id'] = 'This field is required.';
        //     validations = false;
        // }
        // if(formdetails.project_id===''){
        //     fieldStates['error_project_id'] = 'has-danger';
        //     fieldStates['error_message_project_id'] = 'This field is required.';
        //     validations = false;
        // }
        if(formdetails.coupon_code === '') {
            fieldStates['error_coupon_code'] = 'has-danger';
            fieldStates['error_message_coupon_code'] = 'This field is required.';
            validations = false;
        }else{
          if(validateDuplicateCoupon()) {
            fieldStates['error_coupon_code'] = 'has-danger';
            fieldStates['error_message_coupon_code'] = 'Coupon already exists.';
            validations = false;
          }else if(formdetails.coupon_code.length<5 || formdetails.coupon_code.length>15){
            fieldStates['error_coupon_code'] = 'has-danger';
            fieldStates['error_message_coupon_code'] = 'Invalid Coupon code length. Length should be in between 5 to 15';
            validations = false;
          }
        }
        if(formdetails.discount_type ==='percentage') {
          if(formdetails.discount_percentage === '') {
              fieldStates['error_discount_percentage'] = 'has-danger';
              fieldStates['error_message_discount_percentage'] = 'This field is required.';
              validations = false;
          }
        }else if(formdetails.discount_type === 'flat'){
          if(formdetails.discount_flat === '') {
              fieldStates['error_discount_flat'] = 'has-danger';
              fieldStates['error_message_discount_flat'] = 'This field is required.';
              validations = false;
          }else if(formdetails.discount_flat<=0) {
              fieldStates['error_discount_flat'] = 'has-danger';
              fieldStates['error_message_discount_flat'] = 'Enter valid amount.';
              validations = false;
          }
        }

        // if(formdetails.limit_amount === '') {
        //     fieldStates['error_limit_amount'] = 'has-danger';
        //     fieldStates['error_message_limit_amount'] = 'This field is required.';
        //     validations = false;
        // }else if(formdetails.limit_amount<=0){
        //     fieldStates['error_limit_amount'] = 'has-danger';
        //     fieldStates['error_message_limit_amount'] = 'Invalid max discount amount';
        //     validations = false;
        // }
        if(formdetails.max_usage === '') {
            fieldStates['error_max_usage'] = 'has-danger';
            fieldStates['error_message_max_usage'] = 'This field is required.';
            validations = false;
        }else if(formdetails.max_usage<=0){
            fieldStates['error_max_usage'] = 'has-danger';
            fieldStates['error_message_max_usage'] = 'Invalid value';
            validations = false;
        }

        if(formdetails.start_date===''){
            fieldStates['error_start_date'] = 'has-danger';
            fieldStates['error_message_start_date'] = 'This field is required.';
            validations = false;
        }

        if(formdetails.expires_at===''){
            fieldStates['error_expires_at'] = 'has-danger';
            fieldStates['error_message_expires_at'] = 'This field is required.';
            validations = false;
        }
        if(formdetails.start_date && formdetails.expires_at && moment(formdetails.expires_at).isBefore(formdetails.start_date)){
            fieldStates['error_start_date'] = 'has-danger';
            fieldStates['error_message_start_date'] = 'Start date should be less than expire date.';
            validations = false;
        }

        setFieldStates({
            ...fieldStates,
            fieldStates
        });
    return validations;
  }


  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 
  const validateForm = async () => {
    let validated = validateFields();
    if(validated){
      setShowLoader(true);
      setShowSaveButton(false);
      let createCoupon = await couponService.createCoupon(formdetails);
      if(createCoupon.status && createCoupon.data){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Coupon was created Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/coupon/dashboard");
        },2000);
        
      }
      else if(!createCoupon.staus && createCoupon.error.errorCode === 403){
        context.setIsAuthorized(context);
        setShowLoader(false)
        setShowSaveButton(true)
      }
      else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Coupon could not be created, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }

  const handlePublisherIdsChange = (value) => {
    let publisherIds = [];
    if(value && value.length > 0){
      value.forEach(function(publisherDetails){
        publisherIds.push(publisherDetails.value);
      })
    }
    setFormDetails({
      ...formdetails,
      publisher_ids: publisherIds,
    });
    setSelectedPublishers(value);
  }
  const handleProjectIdsChange = (value) => {
    let projectIds = [];
    if(value && value.length > 0){
      value.forEach(function(projectDetails){
        projectIds.push(projectDetails.value);
      })
    }
    setFormDetails({
      ...formdetails,
      project_ids: projectIds,
    });
    setSelectedProjects(value);
  }
  const handlePlanIdsChange = (value) => {
    let planIds = [];
    if(value && value.length > 0){
      value.forEach(function(planDetails){
        planIds.push(planDetails.value);
      })
    }
    setFormDetails({
      ...formdetails,
      plan_ids: planIds,
    });
    setSelectedPlans(value);
  }
  const redirectUrl = (event) =>{
      event.preventDefault();
    history.push("/coupon/dashboard")
  }

  const disablePastDates = (current) =>{
    const previousDate =  moment().subtract(1, 'day');
    return current.isAfter(previousDate);
  }
  const disablePastDatesForEndDate = (current) => {
    const previousDate = moment(formdetails.start_date).subtract(1, "day");
    return current.isAfter(previousDate);
};
     const disablePastAndPreviousDatesForStartDate = (current) => {
    const previousDate = moment(formdetails.expires_at)
    return current.isBetween(moment(),previousDate);

    };




    const handleStartDateChange = (event) =>{
        setFormDetails({
            ...formdetails,
            start_date: moment(event._d)
        });
        fieldStates["error_start_date"] = "has-success";
        fieldStates["error_message_start_date"] = "";
        
        setFieldStates({
          ...fieldStates,
          fieldStates
        });
    }

    const handleEndDateChange = (event) =>{
        setFormDetails({
            ...formdetails,
            expires_at: moment(event._d)
        });
        fieldStates["error_expires_at"] = "has-success";
        fieldStates["error_message_expires_at"] = "";
        
        setFieldStates({
          ...fieldStates,
          fieldStates
        });
    }
    const handleDiscountType = (value) => {
      setFormDetails({
            ...formdetails,
            discount_type: value
        });
    }
    const handlePlatformType = (value) => {
      setFormDetails({
            ...formdetails,
            platform_type: value
        });
    }
    console.log("Formdetails:",formdetails);
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Create Coupon</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createCoupon" method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label className="bold" sm="3">Select Publisher</Label>
                      <Col sm="5">
                        <FormGroup className={fieldStates["error_publisher_id"]}>
                          <Select
                            className="react-select primary "
                            classNamePrefix="react-select"
                            name="publisher_ids"
                            ids="publisher_ids"
                            value={selectedPublishers}
                            options={allPublishers}
                            placeholder="Select Publishers"
                            styles={{
                              option: provided => ({
                                ...provided,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }),
                              multiValueLabel: provided => ({ ...provided, width: '200px' })
                            }}
                            isMulti = "true"
                            onChange={(value)=>  handlePublisherIdsChange(value) }
                          />
                          {fieldStates["error_publisher_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_publisher_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label className="bold" sm="3">Select Project</Label>
                      <Col sm="5">
                        <FormGroup className={fieldStates["error_project_id"]}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={selectedProjects}
                            name="project_ids"
                            ids="project_ids"
                            options={allProjects}
                            placeholder="Select Projects"
                            isMulti = "true"
                            onChange={(value)=>  handleProjectIdsChange(value) }
                          
                          />
                          
                          {fieldStates["error_project_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_project_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label className="bold" sm="3">Select Plans</Label>
                      <Col sm="5">
                        <FormGroup className={fieldStates["error_plan_id"]}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={selectedPlans}
                            name="plan_ids"
                            ids="plan_ids"
                            options={plans}
                            placeholder="Select Plans"
                            isMulti = "true"
                            onChange={(value)=>  handlePlanIdsChange(value) }
                          
                          />
                          
                          {fieldStates["error_plan_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_plan_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Discount Type</Label>
                    <Col sm="1">
                        <Input
                            value={formdetails.discount_type}
                            id="flat"
                            name="discount_type"
                            type="radio"
                            checked = {formdetails.discount_type==='flat'? true: false}
                            onChange={(value) => {
                                handleDiscountType("flat");
                            }}
                        />
                        Flat
                    </Col>
                    <Col sm='2'>
                        <Input
                            value={formdetails.discount_type}
                              id="percentage"
                            name="discount_type"
                            type="radio"
                            checked = {formdetails.discount_type==='percentage'? true: false}
                            onChange={(value) => {
                                handleDiscountType("percentage");
                            }}
                        />
                        Percentage
                    </Col>
                  </Row>
                  {formdetails.discount_type==='percentage'?
                  
                    <Row>
                      <Label sm="3">Discount %<span class="text-danger"> * </span></Label>
                        <Col sm="5">
                            <FormGroup className={fieldStates["error_discount_percentage"]}>
                                <Input
                                    name="discount_percentage"
                                    id="discount_percentage"
                                    type="range"
                                    min="1"  max="80" step="1"
                                    tooltip={formdetails.discount_percentage}
                                    onChange={handleChange}
                                />
                                {fieldStates["error_discount_percentage"] ===
                              "has-danger" ? (
                                  <label className="error">
                                      {
                                          fieldStates[
                                              "error_message_discount_percentage"
                                          ]
                                      }
                                  </label>
                              ) : null}
                            </FormGroup>
                        </Col>
                        <Col sm='1'>{formdetails.discount_percentage}</Col>
                    </Row>
                  :
                    <Row>
                      <Label sm="3">Amount (INR)<span class="text-danger"> * </span></Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_discount_flat"]}>
                              <Input
                                  name="discount_flat"
                                  id="discount_flat"
                                  type="text"
                                  value = {formdetails.discount_flat}
                                  onChange={handleChange}
                              />
                              {fieldStates["error_discount_flat"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_discount_flat"
                                        ]
                                    }
                                </label>
                            ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  
                    }
                  <Row>
                      <Label sm="3">Coupon Code <span class="text-danger"> * </span></Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_coupon_code"]}>
                              <Input
                                  name="coupon_code"
                                  id="coupon_code"
                                  type="text"
                                  onChange={handleChange}
                              />
                              {fieldStates["error_coupon_code"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_coupon_code"
                                        ]
                                    }
                                </label>
                            ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label sm="3">Description</Label>
                      <Col sm="5">
                          <FormGroup>
                              <Input
                                  name="description"
                                  id="description"
                                  type="textarea"
                                  value = {formdetails.description}
                                  onChange={handleChange}
                              />
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Limit Amount (INR)</Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_limit_amount"]}>
                              <Input
                                  name="limit_amount"
                                  id="limit_amount"
                                  type="number"
                                  min="0"
                                  onChange={handleChange}
                              />
                              {fieldStates["error_limit_amount"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_limit_amount"
                                        ]
                                    }
                                </label>
                            ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Max usage</Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_max_usage"]}>
                              <Input
                                  name="max_usage"
                                  id="max_usage"
                                  type="number"
                                  min="1"
                                  value = {formdetails.max_usage}
                                  onChange={handleChange}
                              />
                              {fieldStates["error_max_usage"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_max_usage"
                                        ]
                                    }
                                </label>
                            ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label className="bold" sm="3">Start Date <span class="text-danger"> * </span></Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_start_date"]}>
                              
                                  <ReactDatetime
                                      value={formdetails.start_date}
                                      name="start_date"
                                      isValidDate={ formdetails.expires_at?disablePastAndPreviousDatesForStartDate:disablePastDates}
                                      onChange={handleStartDateChange}
                                      dateFormat ={"DD/MM/YYYY"}
                                      closeOnSelect
                                  />
                                  {fieldStates["error_start_date"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_start_date"
                                        ]
                                    }
                                </label>
                            ) : null}
                          
                          </FormGroup>
                          </Col>
                  </Row>
                  <Row>
                      <Label className="bold" sm="3">End Date <span class="text-danger"> * </span></Label>
                      <Col sm="5">
                          <FormGroup className={fieldStates["error_expires_at"]}>
                              <ReactDatetime
                                  value={formdetails.expires_at}
                                  name="end_date"
                                  isValidDate={ formdetails.start_date?disablePastDatesForEndDate:disablePastDates}
                                  onChange={handleEndDateChange}
                                  dateFormat ={"DD/MM/YYYY"}
                                  closeOnSelect
                              />
                              {fieldStates["error_expires_at"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_expires_at"
                                        ]
                                    }
                                </label>
                            ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Platform Type</Label>
                    
                    <Col sm="1">
                        <Input
                            value={formdetails.platform_type}
                            id="pwa"
                            name="platform_type"
                            type="radio"
                            checked = {formdetails.platform_type==='pwa'? true: false}
                            onChange={(value) => {
                                handlePlatformType("pwa");
                            }}
                        />
                        PWA
                    </Col>
                    <Col sm='1'>
                        <Input
                            value={formdetails.platform_type}
                              id="app"
                            name="platform_type"
                            type="radio"
                            checked = {formdetails.platform_type==='app'? true: false}
                            onChange={(value) => {
                                handlePlatformType("app");
                            }}
                        />
                        App
                    </Col>
                    <Col sm="1">
                        <Input
                            value={formdetails.platform_type}
                            id="both"
                            name="platform_type"
                            type="radio"
                            checked = {formdetails.platform_type==='both'? true: false}
                            onChange={(value) => {
                                handlePlatformType("both");
                            }}
                        />
                        Both
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="10">
                     {showSaveButton?<span>
                      <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Create Coupon
                  </Button>

                  <Button
                    className="btn-round"
                    color="warning"
                    type="button"
                    onClick={redirectUrl}
                  >
                    Cancel
                  </Button>
                  
                  </span>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                  </Col>
                  </Row>
                  
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default CreateCoupon;
