import React, { useEffect, useState, useContext } from "react";
// import versionService from "../../services/api/versions-service";
import { useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import customizationsService from "../../services/api/customizations-service";
import projectsService from "../../services/api/projects-service";
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserContext from "../../UserContext";
const ConfigVersions = (props) => {
    const projectId = new URLSearchParams(props.location.search).get('projectid');
    const history = new useHistory();
    const context = useContext(UserContext);
    const [versions, setVersions] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [showPopup, setShowPopup] = useState({alert: null});
    const [reloadComponent,setReloadComponent] = useState(0);
    const [projectDetails, setProjectDetails] = useState(false);
    useEffect(()=>{
      (async () => {
          if (projectId) {
            let getProjectDetails = await projectsService.getProjectDetails(
              projectId
            );
            if (getProjectDetails.status) {
              setProjectDetails(getProjectDetails.data);
              setRenderComponent(true);
            }
          }
      })();
    },[])
    useEffect(() => {
        (async () => {
        let configVersions = await customizationsService.getConfigVersions(projectId);
        if(!configVersions.status && configVersions.error.errorCode === 403){
            // context.setIsAuthorized(context);
        }
        else if (configVersions.status && configVersions.data) {
            setVersions(configVersions.data.versions);
            setRenderComponent(true);
        }
        })();
    }, [reloadComponent]);
    const restoreConfigPopup = (configBackupId,projectId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreConfig(configBackupId, projectId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this config
          </ReactBSAlert>
        ),
      });
    }


    const restoreConfig = async (configBackupId,projectId) => {
      let resConfig = await customizationsService.restoreConfig(configBackupId,projectId);
      if(!resConfig.status && resConfig.error.errorCode === 403){
          setShowPopup({alert:null});
          context.setIsAuthorized(context);
      }
      else if(resConfig.status && resConfig.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Config was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Config could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
        setShowPopup({
        alert: null,
        });
    };
    return (
      <div className="content mt-30">
        {showPopup.alert}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">
                    Config Versions of {projectDetails.name ? projectDetails.name : "-"}
                  </CardTitle>
                  <div align="right">
                    <a href ={"/config/customizations?projectid="+projectId} className="btn btn-primary btn-smb " align="right">Go Back</a>
                  </div>
                </CardHeader>
                <CardBody>
                  {!renderComponent ? <td colSpan="4" className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                  </td> :
                  ((versions && versions.length) ?
                  <Table responsive>
                    <thead className="text-primary">
                      <th className="text-left">#</th>
                      <th className="text-left">Config Created At</th>
                      <th className="text-left">Updated By</th>
                      <th className="text-left">Platform</th>
                      <th className="text-left">Updated group</th>
                      <th className="text-left">Action</th>
                    </thead>
                    <tbody>
                        {versions.map((versionDetails,index) => {
                          return <tr id={"row_" + versionDetails._id}>
                              <td className="text-left">{index+1}</td>
                              <td className="text-left">
                                {versionDetails.createdAt ? moment(versionDetails.createdAt).format("MMMM Do YYYY, h:mm:ss a") : ""}
                              </td>
                              <td className="text-left">
                                {versionDetails.updated_by ? versionDetails.updated_by: ""}
                              </td>
                              <td className="text-left">
                                {versionDetails.platform ? versionDetails.platform: ""}
                              </td>
                              <td className="text-left">
                                {versionDetails.updated_group ? versionDetails.updated_group: ""}
                              </td>
                              <td className="text-left">
                                <Button onClick={()=>restoreConfigPopup(versionDetails._id, projectId)} className="btn btn-smb">Restore Config</Button>
                              </td>
                              </tr>
                        })}

                    </tbody>
                  </Table>: 
                  // <td colSpan="4" className="text-center">
                    <CardTitle align="center" tag="h4">
                        No Backup version found
                    </CardTitle>
                  // </td>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
};

export default ConfigVersions;
