import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import formValidations from "../../variables/form-validations";
import permissionService from "../../services/api/permission-group-service.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLessThanEqual, faPencilAlt, faToggleOff, faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext";
import Scrollspy from 'react-scrollspy'


// import Tab from 'react-bootstrap/Tab'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
    FormGroup,
    UncontrolledCollapse,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";



const CreatePermissionGroup = (props) => {
    const history = new useHistory();
    const context = useContext(UserContext);
    const [disabled, setDisabled] = useState(false);
    const [permissionGroupNameState, setPermissionGroupNameState] = useState("");
    const [permissionGroupDescriptionState, setPermissionGroupDescriptionState] = useState("");
    const [permisisonGroupDescriptionError, setPermisisonGroupDescriptionError] = useState("");
    const [permisisonGroupNameError, setPermisisonGroupNameError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [permissionsState, setPermissionsState] = useState("");
    const [permissionsError, setPermissionsError] = useState("");
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [formdetails, setFormdetails] = useState({
        permissionGroupName: "",
        permissionGroupDescription: "",
        permissions: "",
    });
    // let tab = false;
    var activeTab = false;
    const [verticalTab, setVerticalTab] = useState(activeTab);
    const [checkedPermissions, setcheckedPermissions] = useState([]);
    // const [codeList, setCodeList] = useState([]);
    const [tabElement, setTabPaneElement] = useState([]);
    const [permissionLookUp, setPermissionLookUp] = useState([]);
    // const [permissionGroupCode, setPermissionGroupCode] = useState("");
    useEffect(
        () => {
            (async () => {
                let response = await permissionService.getPermissions();
                // console.log("In View");
                // console.log(permissionsValues);
                if (response && response.data) {
                    // console.log("In use Effect...")
                    // console.log(response.data);
                    setPermissionLookUp(response.data);
                }
            })();
        }, []);
    
    const validatePemrissionGroupName = () => {
        if (formdetails.permissionGroupName.trim() === "") {
            setPermissionGroupNameState("has-danger");
            setPermisisonGroupNameError("Permission Group Name is required!");
            return false;
        }
        setPermissionGroupNameState("has-success");
        return true;
    };

    const validatePermissionGroupDescription = () => {
        if (formdetails.permissionGroupDescription === "") {
            setPermissionGroupDescriptionState("has-danger");
            setPermisisonGroupDescriptionError("Permission Group Description is required!");
            return false;
        }
        setPermissionGroupNameState("has-success");
        return true;
    };


    const handleSubmit = (e) => {
        validateForm();
        e.preventDefault();
    }

    const handlePermissionGroupNameChange = (event) => {
        // console.log("in name: ", event.target.value)
        setFormdetails({
            ...formdetails,
            permissionGroupName: event.target.value,
        })
        setPermissionGroupNameState("has-success");
        setPermisisonGroupNameError("");
    }

    const handlePermissionGroupDescriptionChange = (event) => {
        // console.log("in name: ", event.target.value)
        setFormdetails({
            ...formdetails,
            permissionGroupDescription: event.target.value,
        })
        setPermissionGroupDescriptionState("has-success");
        setPermisisonGroupDescriptionError("");
    }

    const   handlePermissionsChange = (event) =>{
        // console.log(code)
        console.log(event.target.value)
        console.log("checkedpermission: ",checkedPermissions)
        // handlePermissionsCode(code);
        let updateList = [...checkedPermissions];
        // console.log("list: ",updateList)
        if (event.target.checked) {
            updateList = [...checkedPermissions, event.target.value];
            // updateList.push(event.target.value);
            // console.log("in if")
        }
        else {
            updateList.splice(checkedPermissions.indexOf(event.target.value), 1);
        }
        console.log("list:",updateList);
        setcheckedPermissions(updateList);
        setFormdetails({
            ...formdetails,
            permissions: updateList
        })

        setPermissionsState("has-success");
        setPermissionsError("");

        // make new state for permisisons array and push every permission in permission array 
    }

    // const handlePermissionsCode = (code)=> {
    //     // update codes array using push function in formdetails
    //     let updateList = [...codeList];
    //     if(code != ""){
    //         updateList = [...codeList, code];
    //     }
    //     else{
    //         updateList = [...codeList]
    //     }
    //     console.log(updateList)
    //     setCodeList(updateList);
    // }
    console.log("chekedpermission",checkedPermissions)
    const validatePermissions = () => {

        if (formdetails.permissions === "") {
            setPermissionsState("has-danger");
            setPermissionsError("At least select one permission");
            return false;
        }
        setPermissionsState("has-success");
        return true;
    }

    const validateForm = async () => {
        setFormdetails({ ...formdetails });
        console.log(formdetails);
        let permissionGroupNameValidation = validatePemrissionGroupName();
        // let permissionGroupDescriptionValidation = validatePermissionGroupDescription();
        let permissionsValidation = validatePermissions();
        // console.log("in form", formdetails)
        if (permissionGroupNameValidation && permissionsValidation  && !disabled) {
            let permissionGroupDetails = await permissionService.createPermissionGroup({
                ...formdetails
            });
            setDisabled(true);
            // console.log(permissionGroupDetails.status)
            // if(!permissionGroupDetails.status && permissionGroupDetails.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            // }
            if (permissionGroupDetails.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Role created successfully!"
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({
                        show: false,
                        type: "",
                        message: ""
                    });
                }, 3000);

                setTimeout(() => {
                    history.push("/permissions/permission-details");
                }, 2000);
            }
            else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message: "Role could not be created, Please try again."
                    });
                }, 1000);
                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
                setTimeout(() => {
                    setDisabled(false);
                }, 2000);
            }
        }
    };

    var tabPanes = [];
    
    var navItems = [];
    var navItemsArray = [];
    var i = 0;
    Object.keys(permissionLookUp).forEach((key) => {
        navItemsArray.push(key);
        navItems.push(<NavItem>
            
            <NavLink
                data-toggle="tab"
                href={"#" + key}
                role="tab"
                className={
                    (verticalTab === key) ? "active" : ""
                }
                onClick={() => {
                    setVerticalTab(key);
                    // tabPaneElement(key,verticalTab);
                }}
                >
                {permissionLookUp[key].classifierName}
            </NavLink>
        </NavItem>);
        let permission = permissionLookUp[key].permissions;
        Object.keys(permission).forEach((data)=>{

            tabPanes.push(<TabPane id={key} tabId={verticalTab} role="tabpanel">
                <Label>{permission[data].label}</Label>{' '}
                <Input type="checkbox" name={permission[data].value} id={permission[data].value} value={permission[data].value} onChange={handlePermissionsChange} style={{'marginTop':'10px','width':'15px','height':'15px'}}></Input>
            </TabPane>)
            });
            tabPanes.push(<hr></hr>)
            // setTabPaneElement(tabPanes)
        });
        // const tabPaneElement = (data) => {
        //     let permission = data.permissions;
        //     setVerticalTab(data.code)
        //     setTabPaneElement(permission);
        // }
        // i++; 
        // const tabPaneElement = (key,verticalTab)=> {
        //     console.log("value: ",key,verticalTab);
        //     console.log("tabpane element funtion",permissionLookUp[key].permissions)
        //     let permission = permissionLookUp[key].permissions;
        //     Object.keys(permission).forEach((data)=>{
        //         // console.log("in loop",permission[data])
        //         tabPanes.push(<TabPane id={key} tabId={verticalTab} role="tabpanel">
        //             <Label>{permission[data].label}</Label>
        //             <Input type="checkbox" name={permission[data].value} id={permission[data].value} value={permission[data].value} onChange={handlePermissionsChange}></Input>
        //         </TabPane>
        //     )
        //     // console.log(tabPanes)
        // });
        // setTabPaneElement(tabPanes);
        // };
        const scrollUpdated = (event) => {
            if (event) {
                setVerticalTab(event.id);
            }
        };


    // Object.keys(permissionLookUp).forEach(function (key) {
    //     // console.log("permissionLookup:",permissionLookUp)    
    //     // console.log("key",key);
    //     navItemsArray.push(key);
    //     navItems.push(<NavItem>
    //         <NavLink
    //             data-toggle="tab"
    //             href={'#' + key}
    //             role="tab"
    //             className={
    //                 (verticalTab === key)
    //                     ? "active"
    //                     : ""
    //             }
    //             onClick={() =>
    //                 setVerticalTab(key)
    //             }
    //         >
    //             {permissionLookUp[key].classifierName}
    //         </NavLink>
    //     </NavItem>);

    //     tabPanes.push(
    //         <TabPane id={key} tabId={verticalTab} role="tabPanel">
    //             <CardTitle>{permissionLookUp[key].classifierName}</CardTitle>
    //             {console.log(permissionLookUp[key].permissions)}
    //             <Form >

    //                 {permissionLookUp[key].permissions.map(permission => (
    //                     // <Input type="checkbox" label={data[0].permissions.label} value={data[0].permissions.value}>{data[0].permissions.label}</Input>
    //                     <FormGroup>
    //                         {/* {console.log(key2)}
    //                                                                                 {console.log(data2.label)} */}
    //                         <Label >{permission.label}</Label>
    //                         <Input type="checkbox" value={permission.value} onChange={handlePermissionsChange}></Input>

    //                     </FormGroup>

    //                 )

    //                 )}
    //             </Form>
    //         </TabPane>
    //     );
    //     i++;
    // });

    return (
        <>
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Create Role
                                    </CardTitle>


                                    <CardBody>
                                        <Form
                                            className="form-horizontal"
                                            id=""
                                            method="Post"
                                            action=""
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Label sm="2">Role Name<span className="text-danger">*</span></Label>
                                                <Col sm="7">
                                                    <FormGroup className={permissionGroupNameState}>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            onChange={handlePermissionGroupNameChange}
                                                        ></Input>
                                                        {permissionGroupNameState === "has-danger" ?
                                                            (
                                                                <label className="error">
                                                                    {permisisonGroupNameError}
                                                                </label>
                                                            ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Role Description</Label>
                                                <Col sm="7">
                                                    <FormGroup >
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            onChange={handlePermissionGroupDescriptionChange}
                                                        ></Input>
                                                    
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <h5>Permissions</h5>
                                            </Row>
                                            
                                            {/* <Card>
                                                <CardBody>
                                                    <Col md="3">
                                                        <div className="nav-tabs-navigation verical-navs p-0">
                                                            <div className="nav-tabs-wrapper">
                                                                
                                                                <Scrollspy className="flex-column nav-stacked scroll nav nav-tabs" items={navItemsArray} currentClassName="bg-light" onUpdate={(event) => scrollUpdated(event)} rootEl="#vertical-tab-content">
                                                                    {

                                                                        Object.keys(permissionLookUp).forEach((key) => {

                                                                            navItemsArray.push(key);
                                                                            navItems.push(<NavItem>
                                                                                <NavLink
                                                                                    data-toggle="tab"
                                                                                    href={"#" + key}
                                                                                    role="tab"
                                                                                    className={
                                                                                        (verticalTab === key) ? "active" : ""
                                                                                    }
                                                                                    onClick={() => {
                                                                                        scrollUpdated(key)
                                                                                    }}
                                                                                >
                                                                                    {console.log("permission lookup: ", permissionLookUp[key].classifierName)}
                                                                                    {permissionLookUp[key].classifierName}
                                                                                </NavLink>
                                                                            </NavItem>);

                                                                        })
                                                                    }
                                                                </Scrollspy>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card> */}
                                            <Card>
                                                <CardBody>

                                                    <Row className={permissionsState}>
                                                    <Col md="3">
                                                        <div className="nav-tabs-navigation verical-navs p-0">
                                                            <div className="nav-tabs-wrapper">
                                                            
                                                            <Scrollspy className="flex-column nav-stacked scroll nav nav-tabs" items={navItemsArray} currentClassName="bg-light"  onUpdate={(event) =>scrollUpdated(event)} rootEl="#vertical-tab-content">
                                                                {navItems}
                                                            </Scrollspy>
                                                            </div>
                                                        </div>
                                                        </Col>

                                                        <Col md="9">
                                                            <TabContent id="vertical-tab-content" style={{'height':'300px','overflow-y':'scroll','fontSize':"20px"}} activeTab={verticalTab}>
                                                            {tabPanes}
                                                            </TabContent>
                                                        </Col>
                                                    </Row>
                                                    {permissionsState === "has-danger" ? (<label className="error" style={{ color: "red" }}>
                                                        {permissionsError}
                                                    </label>
                                                    ) : null}
                                                </CardBody>
                                            </Card>
                                            {/* <Card> */}
                                            {/* <CardBody> */}

                                            {/* <Tab.Container id="left-tabs-example">
                                                        {permissionLookUp.map((data) => (
                                                            <Row>
                                                                <>
                                                                    <Col sm={3}>
                                                                        <Nav variant="pills" className="flex-column">
                                                                            <NavItem>
                                                                                <NavLink eventKey={data.code} onClick={()=>{toggle(data.code)}} className={classNames({activeTab:activeTab === data.code})}>
                                                                                    {data.classifierName}
                                                                                </NavLink>
                                                                            </NavItem>
                                                                        </Nav>
                                                                    </Col>
                                                                    <Col sm={9}></Col>
                                                                    {data.permissions.map((permission) => (
                                                                        <>
                                                                        {console.log(data.code)}
                                                                            <Col sm={3}></Col>
                                                                            <Col sm={9}>
                                                                                <Tab.Content>
                                                                                    <Tab.Pane eventkey={data.code}>
                                                                                        <FormGroup>
                                                                                           
                                                                                            <Label>{permission.label}</Label>
                                                                                            <Input
                                                                                                type="checkbox"
                                                                                                value={permission.value}
                                                                                                onChange={handlePermissionsChange}
                                                                                            ></Input>
                                                                                        </FormGroup>
                                                                                    </Tab.Pane>
                                                                                </Tab.Content>
                                                                            </Col>
                                                                        </>
                                                                    ))}
                                                                </>
                                                            </Row>
                                                        ))}
                                                    </Tab.Container> */}

                                            {/* </CardBody> */}
                                            {/* </Card> */}
                                            {/* <Row>
                                                <Label sm="2">Role Code<span className="text-danger">*</span></Label>
                                                <Col sm="7">
                                                    <FormGroup className={permissionGroupCodeState}>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            onChange={handlePermissionGroupCodeChange}
                                                        ></Input>
                                                        {permissionGroupCodeState === "has-danger" ?
                                                            (
                                                                <label className="error">
                                                                    {permisisonGroupCodeError}
                                                                </label>
                                                            ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                                            {/* <Row className={permissionsState}>
                                                <div>
                                                    {permissionLookUp.map(data => <>

                                                        <Button
                                                            color="primary"
                                                            id="toggler1"
                                                            style={{ marginBottom: '1rem' }}
                                                        // onClick={getPermissions}
                                                        >
                                                            {data.classifierName}

                                                        </Button>

                                                        <UncontrolledCollapse toggler="#toggler1">
                                                            <Card>
                                                                <CardBody>
                                                                    <Form >

                                                                        {data.permissions.map(permission => (
                                                                            // <Input type="checkbox" label={data[0].permissions.label} value={data[0].permissions.value}>{data[0].permissions.label}</Input>
                                                                            <FormGroup>
                                                                                {/* {console.log(key2)}
                                                                                    {console.log(data2.label)} */}
                                            {/* <Label >{permission.label}</Label>
                                                                                <Input type="checkbox" value={permission.value} onChange={handlePermissionsChange}></Input>

                                                                            </FormGroup>

                                                                        )

                                                                        )}
                                                                    </Form>


                                                                </CardBody>
                                                            </Card>
                                                        </UncontrolledCollapse>

                                                    </>)}
                                                </div>

                                                {permissionsState === "has-danger" ? (<label className="error">
                                                    {permissionsError}
                                                </label>
                                                ) : null}
                                            </Row> */}



                                            <Row>
                                                {showSaveButton ? (
                                                    <div>
                                                        <Button
                                                            className="btn-round"
                                                            color="info"
                                                            type="button"
                                                            onClick={validateForm}
                                                            disabled={disabled}
                                                        >
                                                            Create
                                                        </Button>
                                                        <Button
                                                            className="btn-round"
                                                            color="danger"
                                                            type="button"
                                                            onClick={(event) => {
                                                                history.push(`/permissions/permission-details`)
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                ) : ("")}
                                                {
                                                    alert.show ? (
                                                        <Alert color={alert.type}>
                                                            {alert.message}
                                                        </Alert>
                                                    ) : ("")
                                                }
                                                {showLoader ? (
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="sr-only"></span>
                                                    </Spinner>
                                                ) : ("")}
                                            </Row>

                                        </Form>
                                    </CardBody>


                                    <CardFooter>

                                    </CardFooter>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

}


// const CreatePermissionGroup = (props) => {

//     const history = new useHistory();
//     const [permissionGroupState, setPermissionGroupState] = useState("");
//     const [permissionGroupError, setPemrissionGroupError] = useState("");
//     const [showLoader, setShowLoader] = useState(false);
//     const [showSaveButton, setShowSaveButton] = useState(true);
//     const [permissionsState, setPermissionState] = useState("");
//     const [permisisonserror, setPemrissionError] = useState("");
//     const [alert, setAlert] = useState({ show: false, message: "", type: "" });
//     const [formdetails, setFormdetails] = useState({
//         permissionGroup: "",
//         permissions: "",
//         status: "",
//     });

//     const permissionOptions = [
//         { value: "list project", label: "List Project" },
//         { value: "schedule a job", label: "Schedule a Job" },
//         { value: "reschedule a job", label: "Reschedule A Job" }
//     ]

//     const [permissionLookup, setPermissionLookup] = useState([]);

//     useEffect(() => {
//         (async () => {
//             let response = await permissionService.getPermissions();

//             console.log("in view..", permissionData);
//             if (response && response.data) {
//                 setPermissionLookup(response.data);
//             }
//         })();
//     }, []);



//     const validatePermissionGroup = () => {
//         if (formdetails.permissionGroup === "") {
//             setPermissionGroupState("has-danger");
//             setPemrissionGroupError("Permission Group is required!!");
//             return false;
//         }
//         setPemrissionGroupError("has-success");
//         return true;
//     };

//     const validatePemrissions = () => {
//         if (formdetails.permissions === "") {
//             setPermissionState("has-danger");
//             setPemrissionError("Please select atleast one permission!!");
//             return false;
//         }
//         setPermissionGroupState("has-success");
//         return true;
//     };

//     const handleSubmit = function (e) {
//         validateForm();
//         e.preventDefault();
//     };

//     const validateForm = async () => {
//         setFormdetails({ ...formdetails });
//         let permissionsValidation = validatePemrissions();
//         let permissionGroupValidation = validatePermissionGroup();

//         if (permissionsValidation && permissionGroupValidation) {
//             let permissionGroupDetails = await Permissions.createPermissionGroup({
//                 ...formdetails
//             });

//             if (permissionGroupDetails.status) {
//                 setTimeout(() => {
//                     setShowLoader(false);
//                     setAlert({
//                         show: true,
//                         type: "success",
//                         message: "Permission group created Successfully",
//                     });
//                 }, 1000);
//                 setTimeout(() => {
//                     setAlert({
//                         show: false, type: "", message: ""
//                     });
//                 }, 3000);
//                 setTimeout(() => {
//                     history.push("/permission-group/permission-details");
//                 }, 2000);
//             }
//             else {
//                 setTimeout(() => {
//                     setShowLoader(false);
//                     setShowSaveButton(true);
//                     setAlert({
//                         show: true,
//                         type: "danger",
//                         message: "Permission Group could not be created, Please Try Again!!"
//                     });
//                 }, 1000);
//                 setTimeout(() => {
//                     setAlert({ show: false, type: "", message: "" });
//                 }, 3000);
//             }
//         }
//     };

//     const handlePermissionGroupChange = (event) => {
//         setFormdetails({
//             ...formdetails,
//             permissionGroup: event.value,
//         });
//         setPermissionGroupState("has-success");
//         setPemrissionGroupError("");
//     }

//     const handlePermissionChange = (event) => {
//         setFormdetails({
//             ...formdetails,
//             permissions: event.value,
//         });
//         setPermissionState("has-success");
//         setPemrissionError("");
//     }

//     const getPermissionValue = () => {
//         let permissionValue = Permissions.getPermissions();
//         if (formdetails.permissions) {
//             permissionOptions.forEach(function (ldetails) {
//                 if (ldetails.value === formdetails.permissions) {
//                     permissionValue = ldetails;
//                 }
//             });
//         }
//         return permissionValue;
//     }

//     return (
//         <>
//             <div className="content">
//                 <Container>
//                     <Row>
//                         <Col className="ml-auto mr-auto" md="12">
//                             <Card className="card-sgnup text-center">
//                                 <CardHeader>
//                                     <CardTitle tag="h4">
//                                         Create New Permission Group
//                                     </CardTitle>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <Form
//                                         className="form-horizontal"
//                                         action=''
//                                         id=''
//                                         method="POST"
//                                         onSubmit={handleSubmit}
//                                     >
//                                         <Row>
//                                             <Label sm="2">Permission Group Name<span className="text-danger">*</span></Label>
//                                             <Col sm="7">
//                                                 <FormGroup className={permissionGroupState}>
//                                                     <Input
//                                                         name="permissiongroup"
//                                                         id="permissiongroup"
//                                                         type="text"
//                                                         onChange={handlePermissionGroupChange}
//                                                     />
//                                                     {
//                                                         permissionGroupState === "has-danger" ? (
//                                                             <label className="error">
//                                                                 {permissionGroupError}
//                                                             </label>
//                                                         ) : null
//                                                     }
//                                                 </FormGroup>
//                                             </Col>
//                                         </Row>
//                                         <Row>
//                                             {/* <Label sm="2">Permissions<span className="text-danger">*</span></Label>
//                                         <Col sm="7">
//                                             <FormGroup className={permissionsState}>
//                                                 <Select
//                                                     multiple='true'
//                                                     className='react-select primary'
//                                                     classNamePrefix='react-select'
//                                                     name='permissions'
//                                                     value={getPermissionValue()}
//                                                     onChange={handlePermissionChange}
//                                                     options={permissions}
//                                                 />
//                                                 {
//                                                     permissionsState === "has-danger"?(
//                                                         <label className="error">
//                                                             {permisisonserror}
//                                                         </label>
//                                                     ):null
//                                                 }
//                                             </FormGroup>
//                                         </Col> */}
//                                         </Row>
//                                         <Row>
//                                             {showSaveButton ? (
//                                                 <div>
//                                                     <Button
//                                                         className='btn-round'
//                                                         color='info'
//                                                         type='button'
//                                                         onClick={validateForm}
//                                                     >
//                                                         Create
//                                                     </Button>
//                                                     <Button
//                                                         className='btn-round'
//                                                         color='danger'
//                                                         type='button'
//                                                         onClick={
//                                                             (event) => {
//                                                                 history.push(`/permissions/permission-details`);
//                                                             }
//                                                         }
//                                                     >
//                                                         Cancel
//                                                     </Button>
//                                                 </div>
//                                             ) : (
//                                                 ""
//                                             )}
//                                             {
//                                                 alert.show ? (
//                                                     <Alert color={alert.type}>
//                                                         {alert.message}
//                                                     </Alert>
//                                                 ) : (
//                                                     ""
//                                                 )
//                                             }
//                                             {showLoader ? (
//                                                 <Spinner
//                                                     animation="border"
//                                                     role="status"
//                                                 >
//                                                     <span className="sr-only">
//                                                         Loading...
//                                                     </span>
//                                                 </Spinner>
//                                             ) : (
//                                                 ""
//                                             )}
//                                         </Row>
//                                     </Form>

//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </>
//     )
// }

export default CreatePermissionGroup;