import axios from "axios";

const getFAQGroups = async() =>{
    const response = await axios.get('/api/faq/get-faq-groups');
    return response.data;
}

const createFAQGroup = async(formdata) =>{
    const response = await axios.post('/api/faq/create-faq-group',formdata);
    return response.data;
}

const removeFAQGroup = async(groupId) =>{
    const formdata = {
        groupId : groupId
    }
    const response = await axios.post('/api/faq/remove-faq-group',formdata);
    return response.data;
}

const restoreFAQGroup = async(groupId) =>{
    const formdata = {
        groupId : groupId
    }
    const response = await axios.post('/api/faq/restore-faq-group',formdata);
    return response.data;
}
const getAllFaqs= async (formdetails,page,records)=>{
     const formdata = {
            filters : formdetails,
            page : page,
            records : records
        }
    const response = await axios.post('/api/faq/get-all',formdata);
    return response.data;
}
const getFAQ = async (faq_id)=>{
    const formdata={
        faq_id:faq_id
    }
    const response = await axios.post('/api/faq/get-faq',formdata);
    return response.data;   
}
const addFAQ = async (formdetails) => {
    const response = await axios.post('/api/faq/add',formdetails);
    return response.data;  
}
const editFAQ = async (formdetails) => {
    const response = await axios.post('/api/faq/edit',formdetails);
    return response.data;  
}
const deleteFAQ = async (faq_id) => {
    const formdata={
        faq_id:faq_id
    }
    const response = await axios.post('/api/faq/delete',formdata);
    return response.data;  
}
export default {
    getFAQGroups,
    createFAQGroup,
    removeFAQGroup,
    restoreFAQGroup,
    getAllFaqs,
    getFAQ,
    addFAQ,
    editFAQ,
    deleteFAQ,
}