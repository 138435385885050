import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import UserContext from "../../../UserContext"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const Plan = (props) => {
    const [plans, setPlans] = useState([]);
    const context = useContext(UserContext);
    const [componentRendered, setComponentRendered] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [planName,setPlanName] =useState("");
    const [groupName, setGroupName] = useState("");
    const [isPublished, setIsPublished] = useState("");
    const [type, setType] = useState("");
    const [formdetails, setFormDetails] = useState({
        name:"",
        plan_group_id: "",
        is_published: "",
        type: "",
    });
    const [search, setSearchDetails] = useState({
        name:"",
        plan_group_id: "",
        is_published: "",
        type: "",
    });
    const [reloadComponent, setReloadComponent] = useState(0);
    const [planGroupOptions, setPlanGroupOption] = useState();
    const history = new useHistory();
    const [totalPlans,setTotalPlans] = useState(0);
    const [activePage,setActivePage] = useState(1);
    const [firstRecord,setFirstRecord]=useState(1);
    const [lastRecord,setLastRecord]=useState(10);
    const limit = 10;
    const planTypeArray = [
        {
            value: "trial",
            label: "Trial",
        },
        {
            value: "monthly",
            label: "Monthly",
        },
        {
            value: "yearly",
            label: "Yearly",
        },
    ];

    useEffect(() => {
        (async () => {
            let tempOptions = [];
            let groupsData = await saasService.getPlanGroups();
            if (groupsData.status && groupsData.data) {
                groupsData.data.forEach((data) => {
                    tempOptions.push({
                        value: data.id,
                        label: data.name,
                    });
                });
            }
            setPlanGroupOption(tempOptions);
        })();
    }, []);
    useEffect(() => {
        (async () => {
            const response = await saasService.getPlans({...formdetails,activePage,limit});
            if (response.status) {
                let tempPlan = [];
                response.data.planList.forEach((plan) => {
                    console.log(plan.plan_group_id);
                    tempPlan.push({
                        id: plan.id,
                        groupName: plan.plan_group_id.name || "",
                        name:plan.name || "",
                        type: plan.type,
                        start_date: plan.start_date,
                        end_date: plan.end_date,
                        status: plan.status,
                        created_by: plan.created_by,
                        isPrimary: plan.is_primary,
                        is_published:plan.is_published,
                        last_modified_by: plan.last_modified_by,
                    });
                });
                setPlans(tempPlan);
                setTotalPlans(response.data.totalCount);
                setFirstRecord((activePage-1)*limit + 1)
                setLastRecord(response.data.totalCount > (activePage)*limit ? activePage*limit : response.data.totalCount)
            }
             setComponentRendered(true);
        })();
    }, [changeStatus,activePage, reloadComponent, search]);

    const publishPlan = async () => {};

    // const activatePlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.activatePlanGroup(planGroupId);
    //     if(response.status){
    //         setChangeStatus(true);
    //     }
    // const tempPlanGroups = [];

    // planGroups.forEach(planGroup=>{
    //     if(planGroup.id == planGroupId){
    //         planGroup.status = 1;

    //         tempPlanGroups.push(planGroup)
    //     }else{
    //         tempPlanGroups.push(planGroup)
    //     }
    // })

    //     console.log(response)
    // }

    // const deactivatePlanPlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.deactivatePlanGroup(planGroupId);

    //     if(response.status){
    //         setChangeStatus(true)
    //     }
    //     console.log(response)

    // }

    const handleIsPrimarySwitch = async (planId, enabled) => {
        if (enabled) {
            const response = await saasService.changePlanPrimaryStatus(
                planId,
                enabled
            );
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setReloadComponent(Math.random());
            }
        }else{
            setReloadComponent(Math.random());
        }
    };

    const getPlanTable = () => {

        let planElements = [];
        if (!plans || !plans.length) {
            if (!componentRendered) {
                planElements.push(
                    <tr>
                        <td colSpan="10" className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </td>
                    </tr>
                );
            } else {
                planElements.push(
                    <tr>
                        <td colSpan="10" className="text-center">
                            <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                    </tr>
                );
            }
        } else {
            let i = 1;
            plans.forEach(function (plan) {
                let className = "";
                if(i==1){
            }
                if (!plan.status) {
                    className = "table-danger";
                }
                if (moment().diff(plan.end_date) > 0) {
                    className = "table-active";
                }
                planElements.push(
                    <tr className={className}>
                        <td className="text-left">{(limit*(activePage-1))+i}</td>
                        <td className="text-left">{plan.name}</td>
                        <td className="text-left">{plan.groupName}</td>
                        <td className="text-left">{plan.type}</td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {plan.start_date}
                            </Moment>
                        </td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {plan.end_date}
                            </Moment>
                        </td>
                        {/* <td className="text-left">
                         {plan.status?'Active':'Inactive'}
                     </td> */}
                        <td className="text-left">{plan.last_modified_by}</td>
                        <td className="text-left">{plan.is_published?"Yes":"No"}</td>
                        <td className="text-left">
                            <Switch
                                name="is_default"
                                offColor="success"
                                offText={
                                    <i className="nc-icon nc-simple-remove" />
                                }
                                onColor="success"
                                disabled={plan.isPrimary || moment().diff(plan.end_date) > 0}
                                onText={<i className="nc-icon nc-check-2" />}
                                value={plan.isPrimary}
                                onChange={(value, enabled) =>
                                    handleIsPrimarySwitch(plan.id, enabled)
                                }
                            />
                        </td>
                        <td className="text-left">
                            <Button
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={(event) =>
                                    history.push(
                                        `/saas/plan-details/${plan.id}`
                                    )
                                }>
                                Details
                            </Button>
                        </td>
                    </tr>
                );
                if(i==plans.length){
                //   lastRecord = (activePage-1)*limit+i;
                  //setLastRecord((activePage-1)*limit+i);
                  console.log("lastRecord",lastRecord);
                }
                i++;
                if(i==plans.length){
                //   lastRecord = (activePage-1)*limit+i;
                  //setLastRecord((activePage-1)*limit+i);
                  console.log("lastRecord",lastRecord);
                }
            });
        }
        return planElements;
    };
    const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
 
//       setLastRecord((pageNumber)*limit)
   }
   

    const handleSubmit = function (e) {
        searchFeatures();
        e.preventDefault();
    };

    const changeGroupName = (event) => {
        setFormDetails({
            ...formdetails,
            plan_group_id: event.value,
        });
        setGroupName(event);
    };
    const changePlanName=(event) =>{
        console.log("Event.target",event.target.value.trim());

        setFormDetails({
            ...formdetails,
            name: event.target.value.trim(),
            
        });
        setPlanName(event);
    }
    const changeType = (event) => {
        setFormDetails({
            ...formdetails,
            type: event.value,
        });
        setType(event);
    };
    const changeIsPublished = (event) => {
        setFormDetails({
            ...formdetails,
            is_published: event.value,
        });
        setIsPublished(event);
    };

    const searchFeatures = () => {
        setSearchDetails(formdetails);
    };

    const resetForm = () => {
        setGroupName("");
        setIsPublished("");
        setType("");
        setFormDetails({
            ...formdetails,
            name:"",
            plan_group_id: "",
            is_published: "",
            type: "",
        });
        document.getElementById("name").value="";
        setActivePage(1);
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <Row>
                                    <Col>
                                    <div style={{'float':'right'}}>
                                    <Button
                                    className="btn-round"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event) =>
                                        history.push("/saas/create-plan")
                                    }>
                                    Create New Plan
                                </Button>
                                </div>
                                    </Col>
                                </Row>
                                <CardTitle tag="h4">Plans Dashboard</CardTitle>
                                <Form
                                    id="featurefilters"
                                    action=""
                                    className="form"
                                    method="POST"
                                    onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="3">
                                             <Input type="text" name="name" id="name"  style={{height:"40px"}} onChange={changePlanName} placeholder="Plan Name"/>
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Group Name.."
                                                name="plan_group_id"
                                                id="plan_group_id"
                                                options={planGroupOptions}
                                                value={groupName}
                                                onChange={changeGroupName}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Type .."
                                                name="type"
                                                id="type"
                                                options={planTypeArray}
                                                value={type}
                                                onChange={changeType}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Status.."
                                                name="isPublished"
                                                id="isPublished"
                                                options={[
                                                    {
                                                        value: "1",
                                                        label: "Published",
                                                    },
                                                    {
                                                        value: "0",
                                                        label: "UnPublished",
                                                    },
                                                ]}
                                                value={isPublished}
                                                onChange={changeIsPublished}
                                            />
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md="4">
                                            <div style={{'float':'left'}}>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="submit">
                                                Search
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="warning"
                                                type="button"
                                                onClick={resetForm}>
                                                Reset
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <hr></hr>
                                {totalPlans > 0 ? 
                                    <Row>
                                        <Col md="6">
                                            <div style={{float:'right',margin:'5px'}}>
                                            <b>{firstRecord} - {lastRecord} of {totalPlans}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{float:'left'}}>
                                                <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={limit}
                                                totalItemsCount={totalPlans}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange.bind(this)}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                : null}
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">
                                                Plan  Name
                                            </th>
                                            <th className="text-left">
                                                Plan Group Name
                                            </th>
                                            <th className="text-left">
                                                Plan type
                                            </th>
                                            <th className="text-left">
                                                Start Date
                                            </th>
                                            <th className="text-left">
                                                End Date
                                            </th>
                                            {/* <th className="text-left">Status</th> */}
                                            <th className="text-left">
                                                Last Modified By
                                            </th>
                                            <th className="text-left">
                                                Is Published
                                            </th>
                                            <th className="text-left">
                                                Is Primary
                                            </th>
                                            <th className="text-left">
                                                Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!plans || !plans.length ? (
                                            !componentRendered ? (
                                                <tr>
                                                    <td
                                                        colSpan="10"
                                                        className="text-center">
                                                        <Spinner
                                                            animation="border"
                                                            role="status">
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan="10"
                                                        className="text-center">
                                                        <CardTitle tag="h4">
                                                            No Records Found
                                                        </CardTitle>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            getPlanTable()
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Plan;
