/**
 * @module usersService
 * @requires axios
 */

// import React from "react"
import axios from "axios";
// import userContext from '../../UserContext.js';
// import {useHistory} from 'react-router-dom';

// const history = new useHistory();

/**
 * This service is used to get logged in user details
 * @returns return loggedin users details
 */
const getLoggedInUser = async() =>{
    const response = await axios.get('/api/users/get-loggedin-user');
    return response.data;
}

/**
 * This service is used to get falsh message
 * @returns return flash message 
 */
const getFlashMessage = async() =>{
    const response = await axios.get('/api/users/get-flash-message');
    return response.data;
}

/**
 * This service is used to get permission group list
 * @returns return permission group list
 */
const getPermissionGroup = async() =>{
    const response = await axios.post('/api/users/get-permission-group');
    return response.data;
}

/**
 * This service is used to user list
 * @param {JSON} formdata formdata
 * @returns return user list
 */
const getUsersList = async(formdata) => {
    const response = await axios.post('/api/users/get-users-list',formdata);
    return response.data;
}

/**
 * This service is used to get user details
 * @param {ObjectId} userId user Id
 * @returns return user details
 */
const getUsersDetails = async(userId) => {
    const formdata = {
        userId : userId
    }
    const response = await axios.post('/api/users/get-user-details',formdata);
    return response.data;
}
/**
 * this service is used to create user
 * @param {JSON} formdata formdata
 * @returns return true if user is created successfully otherwise return error
 */
const createUser = async(formdata) =>{
    // console.log("in api: ",formdata)
    const response = await axios.post('/api/users/create-user',formdata);
    return response.data;
}
/**
 * This service is used to update the details of user
 * @param {JSON} formdata formdata
 * @returns return true if user deatils is updated successfully otherwise return error
 */
const saveUser = async(formdata) =>{
    // console.log(formdata);
    const response = await axios.post('/api/users/edit-user',formdata);
    // console.log(response)
    return response.data;
}

/**
 * This service is used to update the status of user as restored
 * @param {ObjectId} userId user is
 * @returns return restore status
 */
const restoreUser = async(userId) => {
    // console.log("in api ",userId)
    const formdata = {
        userId : userId
    }
    const response = await axios.post('/api/users/restore-user',formdata);
    return response.data;
}

/**
 * This service is used to update the status of user as suspend
 * @param {ObjectId} userId user is
 * @returns return suspend status of user
 */
const suspendUser = async(userId)=>{
    
    const formdata ={
        userId : userId
    }
    const response = await axios.post('/api/users/suspend-user',formdata);
    return response.data;
}

const getUserPermissionGroupList = async(userId) => {
    const formdata = {
        userId: userId
    }
    const response = await axios.post('/api/users/get-user-permission-group-list',formdata);
    return response.data;
}

const getUserPermissionList = async(email) => {
    // console.log("email: ",email)
    const formdata = {
        user_email: email
    }
    const response = await axios.post("/api/users/get-user-permission-list",formdata);
    return response.data;
}

const getUserFrontendPermissionLookup = async()=> {
    const response = await axios.post("/api/users/get-user-frontend-permission-lookup");
    return response.data;
}

export  default {
    getLoggedInUser,
    getFlashMessage,
    getPermissionGroup,
    getUsersList,
    createUser,
    saveUser,
    suspendUser,
    getUsersDetails,
    restoreUser,
    getUserPermissionGroupList,
    getUserPermissionList,
    getUserFrontendPermissionLookup
}