import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";
import UserContext from "../../UserContext";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import jwplayerService from "../../services/api/jwplayer-service";

const Jwplayer = ({ props }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modelOpen, setModelOpen] = useState(false);
  const [isFetched,setIsFetched] = useState(false);
  const [projectList,setprojectList] = useState([]);
  const [allProjectModel,setAllProjectModel] = useState(false);
  const projectId = new URLSearchParams(props.location.search).get("projectid");
  const history = new useHistory();
  if (!projectId) {
    history.push("/project/projects");
  }
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({
    projectid: projectId,
    enablejwplayer: false,
    jwkey: "",
    siteid: "",
  });

let count=0;
  const handleChange = (fieldname, fieldValue) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: fieldValue,
    });
  };


    const fetchFormDetails = async()=>{
    console.log("project id is :",projectId)
    
      let getJwplayerDetails = await jwplayerService.getJwplayerDetails(
        projectId
      );
      console.log("jwplayer data is:",getJwplayerDetails);
     
      if (getJwplayerDetails.data) {
        const data = {
          
        }
        setFormDetails({
          ...formdetails,
          enablejwplayer: getJwplayerDetails.data.enablejwplayer,
          jwkey: getJwplayerDetails.data.jwplayer_key,
          siteid: getJwplayerDetails.data.site_id,
        });
        
      
   
        setIsFetched(true);
      }
    }
  
useEffect(()=>{
  
  fetchFormDetails();

},[])

  const handleSubmit = function (e) {
    validateForm();
    e.preventDefault();
  };


  const validateForm = async () => {
    let validations = true;

    if (validations) {
      if (formdetails.enablejwplayer === false) {
        setShowLoader(true);
        setShowSaveButton(false);
        const saveProjectJwplayerData =
          await jwplayerService.saveProjectJwplayer(formdetails);

        if (
          !saveProjectJwplayerData.status &&
          saveProjectJwplayerData.error.errorCode === 403
        ) {
          context.setIsAuthorized(context);
        } else if (saveProjectJwplayerData.status) {
          setTimeout(() => {
            setShowLoader(false);
            setShowSaveButton(true);
            setAlert({
              show: true,
              type: "success",
              message: "Project settings were saved successfully",
            });
          }, 1000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
            setShowSaveButton(true);
            setAlert({
              show: true,
              type: "danger",
              message: "Project settings could not be saved, Please try again.",
            });
          }, 1000);
        }

        setTimeout(() => {
          setAlert({ show: false, type: "", message: "" });
        }, 3000);
      }

      if (formdetails.enablejwplayer === true) {
        if (formdetails.jwkey === "" || formdetails.siteid === "") {
          return;
        }
        setShowLoader(true);
        setShowSaveButton(false);

        const jwplayerStatus = await jwplayerService.getJwplayerStatus(
          formdetails.jwkey.trim(),
          formdetails.siteid.trim()
        );
        console.log("jwplayerStatus is :".jwplayerStatus);
      
        if (jwplayerStatus.data.status) {
          const gettingTotalOccurence = await jwplayerService.getJwplayerKeys(
            formdetails.jwkey.trim(),
            formdetails.siteid.trim()
          );
         

          if (gettingTotalOccurence.data.data.length > 0) {
           
            if(gettingTotalOccurence.data.data.length===1 && gettingTotalOccurence.data.data[0].project_id._id===projectId){
              
            }
            else{
            
            setprojectList(gettingTotalOccurence.data.data);
            setModelOpen(!modelOpen);
            setShowLoader(false);
            setShowSaveButton(true);
            return;
            }
            
          }
          const saveProjectJwplayerData =
            await jwplayerService.saveProjectJwplayer(formdetails);

          if (
            !saveProjectJwplayerData.status &&
            saveProjectJwplayerData.error.errorCode === 403
          ) {
            context.setIsAuthorized(context);
          } else if (saveProjectJwplayerData.status) {
            setTimeout(() => {
              setShowLoader(false);
              setShowSaveButton(true);
              setAlert({
                show: true,
                type: "success",
                message: "Project settings were saved successfully",
              });
            }, 1000);
          } else {
            setTimeout(() => {
              setShowLoader(false);
              setShowSaveButton(true);
              setAlert({
                show: true,
                type: "danger",
                message:
                  "Project settings could not be saved, Please try again.",
              });
            }, 1000);
          }

          setTimeout(() => {
            setAlert({ show: false, type: "", message: "" });
          }, 3000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
            setShowSaveButton(true);
            setAlert({
              show: true,
              type: "danger",
              message: "Invalid jwplayer Credentials",
            });
          }, 1000);
          setTimeout(() => {
            setAlert({ show: false, type: "", message: "" });
          }, 4000);
        }
      }
    }
  };

  const handleToggle = () => {
    setModelOpen(!modelOpen);
  };

  const handleYes = async () => {
    const saveProjectJwplayerData = await jwplayerService.saveProjectJwplayer(
      formdetails
    );

    if (
      !saveProjectJwplayerData.status &&
      saveProjectJwplayerData.error.errorCode === 403
    ) {
      context.setIsAuthorized(context);
    } else if (saveProjectJwplayerData.status) {
      setTimeout(() => {
        setShowLoader(false);
        setShowSaveButton(true);
        setModelOpen(!modelOpen);
        setAlert({
          show: true,
          type: "success",
          message: "Project settings were saved successfully",
        });
      }, 1000);
    } else {
      setTimeout(() => {
        setShowLoader(false);
        setShowSaveButton(true);
        setModelOpen(!modelOpen);
        setAlert({
          show: true,
          type: "danger",
          message: "Project settings could not be saved, Please try again.",
        });
      }, 1000);
    }

    setTimeout(() => {
      setAlert({ show: false, type: "", message: "" });
    }, 3000);
  };

  const handleNo = async () => {
    setFormDetails({
      ...formdetails,
      jwkey:"",
      siteid:"",
    });
    formdetails.jwkey ="";
    formdetails.siteid ="";
    const saveProjectJwplayerData = await jwplayerService.saveProjectJwplayer(
      formdetails
    );
    setModelOpen(!modelOpen);
  };


  return (
    <div className="content">
      <Container>
        <Modal isOpen={modelOpen} toggle={handleToggle}>
          <ModalBody>
            <div className="d-flex justify-content-between">
              <h4 style={{ color: "rgba(227, 167, 71, 0.8)" }}>Warning</h4>
              <div style={{ cursor: "pointer" }} onClick={handleToggle}>
                X
              </div>
            </div>
            <h6>These  jwplayer  credentials  already  used  in  below  projects.</h6>
            {projectList.length >=1 &&
            <Card style={{
              maxHeight: "30vh",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "0px",
            }}>
              <CardBody>
              <Table>
              <thead>
                <tr>
                  <th>s.no</th>
                  <th >project name</th>
                  <th style={{textAlign:'center'}}>publisher email</th>
                </tr>
              </thead>
              <tbody>
                {projectList.map((e,index)=>{
                 
                  if(e.project_id){
                    count++;
                    return <tr key={index}>
                    <td style={{maxWidth:'3px'}}>{count}</td>
                    <td style={{maxWidth:'3px',maxLines:'3',maxHeight:'15px'}}>{e.project_id.name}</td>
                    <td style={{maxWidth:'13px',maxLines:'3',maxHeight:'15px'}}>{e.project_id.publishers_id.email}</td>
                  </tr>
                  }
                  
                })}
                <tr></tr>
              </tbody>
            </Table>
              </CardBody>
            </Card>
}
            <p>Are you sure want to use the same?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleToggle}
            >
              No
            </Button>
            <Button onClick={handleYes} className="btn btn-outline-warning">
              Yes
            </Button>
          </ModalFooter>
        </Modal>
        
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">JW Player</CardTitle>
              </CardHeader>
              <CardBody>
                
                <Form
                className="form-horizontol"
                action=""
                id="projectSetting"
                method="POST"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Label sm="2">Enable JwPlayer</Label>
                  <Col sm="7">
                    <FormGroup>
                      <Switch
                        name="enable_jwplayer"
                        //defaultValue = {false}
                        offColor="success"
                        offText={<i className="nc-icon nc-simple-remove" />}
                        onColor="success"
                        onText={<i className="nc-icon nc-check-2" />}
                        value={formdetails.enablejwplayer}
                        onChange={(value, enabled) =>
                          handleChange("enablejwplayer", enabled)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {formdetails.enablejwplayer ? (
                  <>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={2}>
                        Jwplayer key <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name="jwplayer_key"
                          id="exampleEmail"
                          value={formdetails.jwkey}
                          onChange={(e) =>
                            handleChange("jwkey", e.target.value)
                          }
                          placeholder="Enter Jw Player Key"
                          required
                        />
                        {formdetails.jwkey === "" && (
                          <p style={{ color: "red" }}>
                            This field is required
                          </p>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="exampleEmail" sm={2}>
                        Site Id <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name="site_id"
                          id="exampleEmail"
                          value={formdetails.siteid}
                          onChange={(e) =>
                            handleChange("siteid", e.target.value)
                          }
                          placeholder="Enter Site Id"
                          required
                        />
                        {formdetails.siteid === "" && (
                          <p style={{ color: "red" }}>
                            This field is required
                          </p>
                        )}
                      </Col>
                    </FormGroup>
                  </>
                ) : (
                  ""
                )}

                {showSaveButton ? (
                  <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
                {alert.show ? (
                  <Alert color={alert.type}>{alert.message}</Alert>
                ) : (
                  ""
                )}

                {showLoader ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  ""
                )}
              </Form>
                
                
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Jwplayer;
