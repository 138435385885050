import React, { useState, useEffect, useHistory } from 'react';
import userService from "../services/api/user-service.js";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
// import alertService from '../services/api/alert-service';
// import './FlashAlert.css'
const FlashAlert = (props) => {
    // let cookieName = 'showFlashAlert:'+props.project.slug;
    // const [cookies, setCookie] = useCookies([cookieName])
    const [showAlert, setShowAlert] = useState(true);
    const [alert, setAlert] = useState(false);
    const [userPermissionList,setuserPermissionList] = useState([]);
    const [frontendPermissionLookUp, setFrontendPermissionLookup] = useState([]);
    const [path, setPath] = useState()
    let pathname = window.location.pathname;
    // console.log("in flash alert: ",window.location.pathname)
    useEffect(() => {
        (async () => {
            console.log("path",pathname)
            // console.log("props",props);
            setPath(pathname);
            // console.log(props.user.user.email);
            let userPermissionList = await userService.getUserPermissionList(props.user.user.email);
            setuserPermissionList(userPermissionList);
            // console.log(userPermissionList);
            // if(!userPermissionList.data){
            //     props.setState();
            //     return;
            // }
            let frontendPermissionLookUp = await userService.getUserFrontendPermissionLookup();
            setFrontendPermissionLookup(frontendPermissionLookUp.data)
            pathname = pathname.split("/").splice(1);
            // console.log("After split: ", pathname);
            let flag = 1;
            console.log("flag",flag)
            frontendPermissionLookUp = frontendPermissionLookUp.data;
            for (var i in frontendPermissionLookUp) {
                // console.log("frontentpermissionlookup", frontendPermissionLookUp[0]);
                if(!userPermissionList.data){
                    flag = 0;
                }
                else if (frontendPermissionLookUp[i].code === pathname[0]) {
                    userPermissionList = userPermissionList.data;
                    let permission = frontendPermissionLookUp[i].permission;
                    // console.log("in if",)
                    for (var j in permission) {
                        // console.log("permission: ",permission[j]);
                        // console.log("list:",userPermissionList);
                        // console.log("value: ",permission[j].value);
                        // console.log("user list ",userPermissionList.includes(permission[j].label));
                        // console.log("permission: ",permission[j].label);
                        if ((!userPermissionList)||(permission[j].value === pathname[1] && !userPermissionList.includes(permission[j].label))) {
                            console.log("not permitted",props);
                            props.setState();
                            flag = 0;    
                            // console.log(props.user.user.isAuthorized)
                            break;
                        }
                    }
                }
                if(flag === 0){
                    break;
                }
            }

        })();
    }, [pathname]);
    // const onDismiss = () =>{ 
    //     //cookies set for 30 days
    //     setCookie(cookieName,true,{maxAge : 30*24*60*60});
    //     setShowAlert(false);
    // }
    // const hideAlert = () =>{
    //     if(cookies[cookieName]) {
    //         setShowAlert(false)
    //     }else{ 
    //         setShowAlert(true);
    //     }
    // }

    // useEffect(()=>{
    //     hideAlert();
    // },[])

    return alert ?
        <div
            style={{
                position: "relative",
                marginLeft: "40px",
                marginRight: "40px",
                marginTop: "5px",
                marginBottom: "-1px",
            }}
        >
            {/* <Alert className="flashAlert alertTxt" color={alert.type} isOpen={showAlert} toggle={onDismiss}>
                <div className="anchorLinkCss" dangerouslySetInnerHTML={{ __html: alert.message }} />
            </Alert> */}
        </div> : null
}
export default FlashAlert;