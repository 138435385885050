import axios from "axios";

const getPluginUsers= async (formdetails,page,records)=>{
     const formdata = {
            filters : formdetails,
            page : page,
            records : records
        }
    const response = await axios.post('/api/plugin/get-plugin-users',formdata);
    return response.data;
}
const getTotalPluginUsersCount = async(formdata) =>{
    const response = await axios.post('/api/plugin/get-total-plugin-users',formdata);
    return response.data;
}
const getProjectsPluginInfo = async (filters,page,records) => {
    let formdata = {
        filters,
        page,
        records
    }
    const response = await axios.post('/api/plugin/get-projects-plugin-details',formdata);
    return response.data;
}
const getPluginsList = async () => {
    const response = await axios.post('/api/plugin/get-plugins-list');
    return response.data;
}
export default {
    getPluginUsers,
    getTotalPluginUsersCount,
    getProjectsPluginInfo,
    getPluginsList
}