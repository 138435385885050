import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
// import ProjectContext from '../../ProjectContext';
import { Line, Bar, Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";


const ArticlesFrequencyChart = (props)=> {

  const [labels,setLabels] = useState(false);
  const [data,setData] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let articlesData = await contentService.getDailyInsertedArticles(props.projectId);
            if(articlesData.status && articlesData.data){
                let articles = articlesData.data;
                let chartLabels  = [];
                let chartDataValues = [];
                if(articles.length){
                    articles.forEach(function(articleDetails) {
                        chartLabels.push(articleDetails._id);
                        chartDataValues.push(articleDetails.count);
                    })
                }
                setLabels(chartLabels)
                setData(chartDataValues)
            }
            setRenderComponent(true);
        })();
    }, []);  
    console.log(labels)
    console.log(data)

    const chartData = {
        data: {
          labels: labels,
          datasets: [
            {
              label: "Data",
              borderColor: "#fcc468",
              fill: true,
              backgroundColor: "#fcc468",
              hoverBorderColor: "#fcc468",
              borderWidth: 8,
              barPercentage: 0.4,
              data: data,
            }
            
          ],
        },
        options: {
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily:
              "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontColor: "#9f9f9f",
                  fontStyle: "bold",
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 20,
                },
                gridLines: {
                  zeroLineColor: "transparent",
                  display: true,
                  drawBorder: false,
                  color: "#9f9f9f",
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  zeroLineColor: "white",
                  display: false,
      
                  drawBorder: false,
                  color: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9f9f9f",
                  fontStyle: "bold",
                },
              },
            ],
          },
        },
      };
 

    return (
            <Card>
                <CardHeader>
                  <CardTitle tag="h4">Daily Ingested Articles</CardTitle>
                </CardHeader>
                <CardBody>
                  <Bar
                    data={chartData.data}
                    options={chartData.options}
                  />
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
    );
  
  
}

export default ArticlesFrequencyChart;
