import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
// import Select from 'react-select';
// import ReactDatetime from 'react-datetime';
// import moment from 'moment';
import UserContext from "../../../UserContext";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const CreatePlanGroup = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [currencyState, setCurrencyState] = useState("");
    const [currencyError, setCurrencyError] = useState("");
    const context = useContext(UserContext);
    const [basePriceState, setBasePriceState] = useState("");
    const [basePriceError, setBasePriceError] = useState("");
    const [discountState, setDiscountState] = useState("");
    const [discountError, setDiscountError] = useState("");

    const [durationState, setDurationState] = useState("");
    const [durationError, setDurationError] = useState("");

    const [durationUnitState, setDurationUnitState] = useState("");
    const [durationUnitError, setDurationUnitError] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const history = new useHistory();
    const [modified, setModified] = useState(false);
    const [showForm, setShowFrom] = useState(false);

    const durationUnitOptions = [
        {
            value: "days",
            label: "Days",
        },
        {
            value: "months",
            label: "Months",
        },
    ];

    // const nameOptions = [
    //     {
    //         value: "trial",
    //         label: "Trial",
    //     },
    //     {
    //         value: "monthly",
    //         label: "Monthly",
    //     },
    //     {
    //         value: "quarterly",
    //         label: "Quarterly",
    //     },
    //     {
    //         value: "yearly",
    //         label: "Yearly",
    //     },
    // ];

    const currencyOptions = [
        {
            value: "inr",
            label: "INR",
        },
        {
            value: "usd",
            label: "USD",
        },
    ];

    const [planPrice, setPlanPrice] = useState({
        plan_id: props.planId,
        // name: "",
        base_price: "",
        discount: "",
        duration: "",
        duration_unit: "",
        // currency: "",
    });

    useEffect(() => {
        (async () => {})();
    }, [modified]);

    const validateForm = async () => {
        console.log(planPrice);

        // let planTypeValidation = validatePlanType();
        // let currencyValidation = validateCurrency();

        let basePriceValidation = validateBasePrice(planPrice.base_price);
        let discountValidation = validateDiscount(planPrice.discount);

        let durationValidation = validateDuration(planPrice.duration);
        let durationUnitValidation = validateDurationUnit();

        if (
            // planTypeValidation &&
            // currencyValidation &&
            basePriceValidation &&
            discountValidation &&
            durationValidation &&
            durationUnitValidation
        ) {
            setShowLoader(true);

            setShowSaveButton(true);

            const response = await saasService.createPlanPrice(planPrice);
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Plan Price was created Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    //history.push(`/saas/plan-details/${props.planId}`)
                    props.setModified(true);
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "Plan Price could not be created, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    const validatePlanType = () => {
        if (planPrice.name === "") {
            setNameState("has-danger");
            setNameError(
                <div className="text-danger">Plan type is required</div>
            );
            return false;
        }

        setNameState("has-success");
        return true;
    };
    const validateCurrency = () => {
        if (planPrice.currency === "") {
            setCurrencyState("has-danger");
            setCurrencyError(
                <div className="text-danger">Currency is required</div>
            );
            return false;
        }

        setCurrencyState("has-success");
        return true;
    };
    const validateBasePrice = (base_price) => {
        if (base_price === "") {
            setBasePriceState("has-danger");
            setBasePriceError("Base Price is required");
            return false;
        }

        if (Number(base_price) < 0) {
            setBasePriceState("has-danger");
            setBasePriceError("Base Price will never be negative");
            return false;
        }
        if (planPrice.discount) {
            if (Number(base_price) < Number(planPrice.discount)) {
                setBasePriceState("has-danger");
                setBasePriceError("Base Price should be greater than Discount");
                return false;
            }
        }
        setBasePriceState("has-success");
        return true;
    };
    const validateDiscount = (discount) => {
        if (discount === "") {
            setDiscountState("has-danger");
            setDiscountError("Discount is required");
            return false;
        }
        if (planPrice.base_price) {
            if (Number(planPrice.base_price) < Number(discount)) {
                setDiscountState("has-danger");
                setDiscountError(
                    "Discount will never be greater than Base Price"
                );
                return false;
            }
        }
        if (Number(discount) < 0) {
            setDiscountState("has-danger");
            setDiscountError("Discount will never be negative");
            return false;
        }
        setDiscountState("has-success");
        return true;
    };
    const validateDuration = (duration) => {
        if (duration === "") {
            setDurationState("has-danger");
            setDurationError("Duration is required");
            return false;
        }
        if (Number(duration) < 1) {
            setDurationState("has-danger");
            setDurationError("Duration Should be positive number");
            return false;
        }

        setDurationState("has-success");
        return true;
    };
    const validateDurationUnit = () => {
        if (planPrice.duration_unit === "") {
            setDurationUnitState("has-danger");
            setDurationUnitError(
                <div className="text-danger">Duration unit is required</div>
            );
            return false;
        }
        setDurationUnitState("has-success");
        return true;
    };

    const handleNameChange = (value) => {
        setPlanPrice({
            ...planPrice,
            name: value,
        });
        setNameError("");
        setNameState("");
    };
    const handleCurrencyChange = (value) => {
        setPlanPrice({
            ...planPrice,
            currency: value,
        });
        setCurrencyError("");
        setCurrencyState("");
    };

    const handleDurationUnitChange = (value) => {
        setPlanPrice({
            ...planPrice,
            duration_unit: value,
        });
        setDurationUnitError("");
        setDurationUnitState("");
    };

    const handleChange = (event) => {
        setPlanPrice({
            ...planPrice,
            [event.target.name]: event.target.value,

        });
        if (event.target.name === "discount") {
            setDiscountState("");
            setDiscountError("");
            validateDiscount(event.target.value);
        }
        if (event.target.name === "base_price") {
            setBasePriceError("");
            setBasePriceState("");
            validateBasePrice(event.target.value);
        }
        if (event.target.name === "duration") {
            setDurationState("");
            setDurationError("");
            validateDuration(event.target.value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const toggleForm = (event) =>{
        if(showForm){
            setShowFrom(false);
        }else{
            setShowFrom(true);
        }
    }
    let currencyElements = [];
    if (currencyOptions) {
        currencyOptions.forEach(function (currencyDetails) {
            currencyElements.push(
                <div>
                    <Label check>
                        <Input
                            value={currencyDetails.value}
                            name="currencyId"
                            type="radio"
                            onChange={(value) => {
                                handleCurrencyChange(currencyDetails.value);
                            }}
                        />
                        {currencyDetails.label}{" "}
                        <span className="form-check-sign" />
                    </Label>
                </div>
            );
        });
    }

    let durationUnitElements = [];
    if (durationUnitOptions) {
        durationUnitOptions.forEach(function (durationUnitDetails) {
            durationUnitElements.push(
                <div>
                    <Label check>
                        <Input
                            value={durationUnitDetails.value}
                            //   id="durationUnitId"
                            name="durationUnitId"
                            type="radio"
                            onChange={() => {
                                handleDurationUnitChange(
                                    durationUnitDetails.value
                                );
                            }}
                        />
                        {durationUnitDetails.label}{" "}
                        <span className="form-check-sign" />
                    </Label>
                </div>
            );
        });
    }
    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4" onClick={toggleForm} style={{cursor:"pointer"}}>
                                    Create Plan Price
                                    <b className="caret"></b>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {showForm? <Form
                                    className="form-horizontol"
                                    onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Base Price (INR) <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={basePriceState}
                                            >
                                                <Input
                                                    name="base_price"
                                                    id="base_price"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {basePriceState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {basePriceError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Discount (INR) <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={discountState}
                                            >
                                                <Input
                                                    name="discount"
                                                    id="discount"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {discountState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {discountError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Duration Unit <span class="text-danger"> * </span></Label>
                                        <Col sm="1">
                                            {durationUnitElements}
                                            {durationUnitState ===
                                            "has-danger" ? (
                                                <label className="error">
                                                    {durationUnitError}
                                                </label>
                                            ) : null}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Duration <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={durationState}
                                            >
                                                <Input
                                                    name="duration"
                                                    id="duration"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {durationState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {durationError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {showSaveButton ? (
                                        <Button
                                            className="btn-round"
                                            color="info"
                                            type="button"
                                            onClick={validateForm}
                                        >
                                            Create
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>:""}
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CreatePlanGroup;
