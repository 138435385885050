import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import formValidations from "../../variables/form-validations";
import usersService from '../../services/api/user-service.js';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp,faChevronDown } from '@fortawesome/free-solid-svg-icons'
import permissionService from "../../services/api/permission-group-service.js";
import UserContext from "../../UserContext";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
    FormGroup,
    UncontrolledCollapse
} from "reactstrap";




import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";

const CreateUser = (props) => {
    const history = new useHistory();
    const context = useContext(UserContext);
    const [disabled, setDisabled] = useState(false);
    const [usernamestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [useremailstate, setEmailState] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [permissionGroupState, setPermissionGroupState] = useState("");
    const [permissionGroupError, setPermissionGroupError] = useState("");
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [formdetails, setFormDetails] = useState({
        userName: "",
        userEmail: "",
        permission_Group: "",
    });
    const [temp_list,setTempList] = useState([]);
    const [checkedPermissionGroup, setcheckedPermissionGroup] = useState([]);
    const [permissionGroup, setPermissionGroup] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    // handler functions code starts

    useEffect(() => {
        (async () => {
            let permissionGroupdata = await usersService.getPermissionGroup();
            // if(!permissionGroupdata.status && permissionGroupdata.error.errorCode === 403){
            //     history.push("/unauth/unauthorized");
            // }
            if (permissionGroupdata.status && permissionGroupdata.data) {
                let permissionGroup = permissionGroupdata.data;
                let permissionGroupValues = [];
                let i = 1;
                Object.keys(permissionGroup).forEach(function (key) {
                    if(permissionGroup[key].status != 0 ){
                        permissionGroupValues.push({
                            value: permissionGroup[key]._id,
                            label: permissionGroup[key].name,
                        });
                    }
                    i++;
                });
                console.log(permissionGroupValues)
                setPermissionGroup(permissionGroupValues);
            }

        })();
    }, []);

    const validateName = () => {
        if (formdetails.userName.trim() === "") {
            setNameState("has-danger");
            setNameError("User Name is required");
            return false;
        }
        setNameState("has-success");
        return true;
    };

    const validateEmail = () => {
        if (formdetails.userEmail.trim() == "") {
            setEmailState("has-danger");
            setEmailError("User Email is required");
            return false;
        }

        if (!formValidations.verifyEmail(formdetails.userEmail)) {
            setEmailState("has-danger");
            setEmailError("Please enter valid email address");
            return false;
        }
        setEmailState("has-success");
        return true;
    };

    const validatePermissionGroup = () => {
        if (formdetails.permission_Group === "") {
            setPermissionGroupState("has-danger");
            setPermissionGroupError("Please choose atleast one role");
            return false;
        }
        setPermissionGroupState("has-success");
        return true;
    };

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        setFormDetails({ ...formdetails });
        let userNameValidation = validateName();
        let userEmailValidation = validateEmail();
        let permissionGroupValidation = validatePermissionGroup();

        if (userNameValidation && userEmailValidation && permissionGroupValidation && !disabled) {
            let userDetails = await usersService.createUser({
                ...formdetails
            });
            setDisabled(true);
            // if(!userDetails.status && userDetails.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            //     history.push("/unauth/unauthorized");
            // }
            if (userDetails.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "User was Created Successfully",
                    });
                }, 1000);
                setTimeout(() => {
                    setAlert({
                        show: false, type: "", message: ""
                    });
                }, 3000);
                setTimeout(() => {
                    history.push("/users/user-details");
                }, 2000);
            }
            else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "User could not be created or already existed, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
                setTimeout(() => {
                    history.push("/users/user-details");
                }, 2000);
            }


        }
    };

    const handleNameChange = (event) => {
        // console.log(event.target);
        setFormDetails({
            ...formdetails,
            userName: event.target.value,
        });
        setNameState("has-success");
        setNameError("");
    }

    const handleEmailChange = (event) => {
        setFormDetails({
            ...formdetails,
            userEmail: event.target.value.trim(),
        });
        setEmailState("has-success");
        setEmailError("");
    }

    const handlePermissionGroupChange = async (event) => {
        // console.log(label)
        let updateList = [...checkedPermissionGroup];
        let permissionList;
        if(event.target.checked){
            updateList = [...checkedPermissionGroup, event.target.value];
            permissionList = await permissionService.getSelectedPermission(updateList);
            // permissionList = [...permissionArray,...list.data];
        }
        else{
            updateList.splice(checkedPermissionGroup.indexOf(event.target.value),1);
            permissionList = await permissionService.getSelectedPermission(updateList);
            // permissionList = [...permissionArray,...list.data];
        }
        // setPermissionArray(permissionList.data)
        // console.log("permission list: ",permissionList.data);
        // console.log(permissionArray)
        let permList = permissionList.data;
        let list = [];
        for(var index in permList){
            let permissionGroup = permList[index];
            for(var perm in permissionGroup){
                // console.log(perm)
                // console.log(permissionGroup[perm].permission)
                if(!list.includes(permissionGroup[perm].permission)){
                    list.push(permissionGroup[perm].permission)
                }
            }
        }
        setTempList(list);
        // console.log("in list: ",temp_list)
        // listPermission();
        // console.log(updateList);
        setcheckedPermissionGroup(updateList);
        setFormDetails({
            ...formdetails,
            permission_Group: updateList
        })
        setPermissionGroupState("has-success");
        setPermissionGroupError("");
    }


    // const getPermissionGroupValue = () => {
    //     let permissionGroupvalue = permissionGroupOptions[0];
    //     if (formdetails.permission_Group) {
    //         permissionGroupOptions.forEach(function (ldetails) {
    //             if (ldetails.value === formdetails.permission_Group) {
    //                 permissionGroupvalue = ldetails;
    //             }
    //         });
    //     }
    //     return permissionGroupvalue;
    // }

    // let userData = [];
    const handleDropDown = ()=>{
        setIsOpen(!isOpen)
    }

    return (
        <>
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Create New User
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        className="form-horizontol form"
                                        action=""
                                        id=""
                                        method="POST"
                                        onSubmit={handleSubmit}
                                    >

                                        <Row>
                                            <Label sm="2">User Name<span className="text-danger"> * </span> </Label>
                                            <Col sm="7">
                                                <FormGroup className={usernamestate}>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        onChange={handleNameChange}
                                                    />
                                                    {usernamestate === "has-danger" ? (
                                                        <label className="error">
                                                            {nameerror}
                                                        </label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">User Email<span className="text-danger"> * </span> </Label>
                                            <Col sm="7">
                                                <FormGroup className={useremailstate}>
                                                    <Input
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        onChange={handleEmailChange}
                                                    />
                                                    {useremailstate === "has-danger" ? (
                                                        <label className="error">
                                                            {emailerror}
                                                        </label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {/* <Label sm="2">Select Permission Group<span className="text-danger"> * </span> </Label> */}
                                            <Col sm="2"></Col>
                                            <Col sm="8">
                                                <FormGroup className={permissionGroupState}>
                                                    {/* <Select
                                                        multiple='true'
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        value={getPermissionGroupValue()}//getRoleValue function called
                                                        onChange={handlePermissionGroupChange}//changeRole function called 
                                                        options={permissionGroup}// role state                            
                                                    /> */}

                                                    <Row>
                                                        <h5>Role</h5>
                                                    </Row>
                                                    <Row>       
                                                        <Button 
                                                            color="primary" 
                                                            id="toggler1" 
                                                            style={{ marginBottom: '1rem' }} 
                                                            onClick={handleDropDown}
                                                        >
                                                            Select At Least One Role   
                                                            {' '}
                                                            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown}/>             
                                                        </Button>
                                                        <UncontrolledCollapse toggler="#toggler1">
                                                            <Card>
                                                                <CardBody>
                                                                    <FormGroup>      
                                                                        {permissionGroup.map(data=><>
                                                                            {/* {console.log(data.value)} */}
                                                                            {}
                                                                            <Label style={{float:"row",marginTop:"5px",marginBottom:"5px",marginLeft:"5px", textSize:"10px"}}>
                                                                                {data.label}
                                                                            </Label>
                                                                            <Input type="checkbox" value={data.value} onChange={handlePermissionGroupChange}
                                                                                style={{float:"row",marginTop:"5px",marginBottom:"5px",marginLeft:"5px"}}   
                                                                            ></Input>
                                                                        </>)}
                                                                    </FormGroup>
                                                                </CardBody>
                                                            </Card>
                                                        </UncontrolledCollapse>
                                                        <Row>
                                                            <Card>
                                                                <CardBody>
                                                                    {temp_list.map((data)=>{
                                                                        // console.log("in item: ",temp_list)
                                                                            return (
                                                                                <ul type="disc">
                                                                                    <li style={{color:"blue"}}>
                                                                                        {data}
                                                                                    </li>
                                                                                </ul>
                                                                            )
                                                                    })}
                                                                        
                                                                </CardBody>
                                                            </Card>
                                                        </Row>

                                                                {/* <UncontrolledCollapse toggler="#toggler1">
                                                                <Card>
                                                                    <CardBody>
                                                                        <Form>
                                                                        {permissionGroup.map(data=><> */}
                                                                            
                                                                            {/* // <Input type="checkbox" label={data[0].permissions.label} value={data[0].permissions.value}>{data[0].permissions.label}</Input> */}
                                                                
                                                                            {/* {console.log(key2)}
                                                                            {console.log(data2.label)} */}
                                                                            {/* <Label >{data.label}</Label>
                                                                            <Input type="checkbox" value={data.value} onChange={handlePermissionGroupChange}></Input>
                                                                            </>)}
                                                                        </Form>
                                                                    </CardBody>
                                                                </Card>
                                                                </UncontrolledCollapse> */}                                                   
                                                                {/* {
                                                                    data.permissions.map(permission=><>{permission.label}</>)
                                                                } */}
                                                    </Row>
                                                    {
                                                        permissionGroupState === "has-danger" ? (
                                                            <label className="error">
                                                                {permissionGroupError}
                                                            </label>
                                                        ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* button for creating user */}
                                        <Row>
                                            {showSaveButton ? (

                                                <div>
                                                    <Button
                                                        className="btn-round"
                                                        color="info"
                                                        type="button"
                                                        onClick={validateForm}
                                                        disabled={disabled}
                                                        
                                                    >
                                                        Create
                                                    </Button>
                                                    <Button
                                                        className="btn-round"
                                                        color="danger"
                                                        type="button"
                                                        onClick={
                                                            (event) => {
                                                                history.push(
                                                                    `/users/user-details`
                                                                );
                                                            }
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {
                                                alert.show ? (
                                                    <Alert color={alert.type}>
                                                        {alert.message}
                                                        {/* {setDisabled(false)} */}
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )
                                            }
                                            {showLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                ""
                                            )}
                                        </Row>

                                    </Form>
                                </CardBody>
                                <CardFooter></CardFooter>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CreateUser;