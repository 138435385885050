import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import faqService from "../../services/api/faq-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const FAQGroups = (props)=> {
    const history = new useHistory();
    const context = useContext(UserContext);
  const [groups, setGroups] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);

  useEffect( () => {
    (async () =>{
      
        let groupsData = await faqService.getFAQGroups();
          // if(!groupsData.status && groupsData.error.errorCode === 403){
          //   context.setIsAuthorized(context);
          // }
          if(groupsData.status){
                setGroups(groupsData.data);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);


    const deactivateGroupPopup = (groupId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deactivateGroup(groupId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Remove it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to remove this group!
          </ReactBSAlert>
        ),
      });
    }

    const restoreGroupPopup = (groupId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreGroup(groupId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this group again!
          </ReactBSAlert>
        ),
      });
    }

    const deactivateGroup = async (groupId) => {
      let susGroup = await faqService.removeFAQGroup(groupId);
      if(!susGroup.status && susGroup.error.errorCode === 403){
        setShowPopup({alert:null})
        context.setIsAuthorized(context);
      }
      else if(susGroup.status && susGroup.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Group was removed successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Group could not be removed. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreGroup = async (groupId) => {
      let resGroup = await faqService.restoreFAQGroup(groupId);
      if(!resGroup.status && resGroup.error.errorCode === 403){
        setShowPopup({alert:null})
        context.setIsAuthorized(context);
      }
      else if(resGroup.status && resGroup.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Group was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Group could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  

    

  let groupElements = [];
  if(!groups || !groups.length){
    if(!renderComponent){
        groupElements.push(
       <tr>
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        groupElements.push(
          <tr>
        <td colSpan="4" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      groups.forEach(function(groupDetails) {
          let className = '';
          if(!groupDetails.status){
            className = 'table-warning';
          }
          groupElements.push(
            <tr className={className}>
                <td className="text-left">
                   {i}
               </td>
               <td className="text-left">
               <Link 
                to={{
                  pathname: "/faq/list",
                  state: {
                    group_id: groupDetails.id
                  },
                }}
                >
                {groupDetails.name}
                </Link>
                   
               </td>
               <td className="text-left">
                   {groupDetails.status?'Active':'Inactive'}
               </td>
               <td className="text-left">
               {groupDetails.status==1?
               <a title="Remove" onClick={(event)=>  deactivateGroupPopup(groupDetails.id) } href="javascript:void(0)" class="nav-link">
               {/* <i class="nc-icon nc-simple-remove"></i> */}
               <FontAwesomeIcon icon={faTrash} />
             </a>
             :
             <a title="Restore" onClick={(event)=>  restoreGroupPopup(groupDetails.id) } href="javascript:void(0)" class="nav-link">
                 {/* <i class="nc-icon nc-refresh-69"></i> */}
                 <FontAwesomeIcon icon={faTrashRestoreAlt} />
               </a> 
               }
               </td>
            </tr>
        )
        i++;
    })
  }


    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <Row>
                    <Col>
                    <div style={{'float':'right'}}>
                    <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/faq/createfaqgroup") }
                  >Create FAQ Group</Button>
                    </div>
                    </Col>
                  </Row>
                <CardTitle tag="h4">
                   FAQ Group Dashboard
                </CardTitle>
                <hr />
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Name</th> 
                      <th className="text-left">Status</th>  
                      <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{groupElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default FAQGroups;
