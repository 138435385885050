
import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import couponService from "../../services/api/coupon-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from "../../services/api/saas-service";
import UserContext from "../../UserContext";
import './pagination.css'
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
const CouponCodeList = (props)=> {
    const history = new useHistory();
    const context = useContext(UserContext);
  const [coupons, setCoupons] = useState(false);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  
  const [formdetails, setFormDetails] = useState({publisher_ids:'',plan_ids:'',project_ids:'',status:""});
  const [search, setSearchDetails] = useState({publisher_ids:'',plan_ids:'',project_ids:'',status:""});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const [planType,setPlanType] = useState('');
  const records = 10;
  const [planGroupOptions, setPlanGroupOption] = useState();
  const [groupName,setGroupName] = useState('');
  const [status,setStatus]=useState('');



  useEffect( () => {
    (async () =>{
        let couponsData = await couponService.getCoupons(formdetails,activePage,records);

        if(couponsData.status && couponsData.data){
                setCoupons(couponsData.data.coupons);
                setTotalCoupons(couponsData.data.count);
            }
          
            setRenderComponent(true);
      })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

  const suspendCouponPopup = (couponId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => suspendCoupon(couponId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Suspend it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to suspend this coupon!
          </ReactBSAlert>
        ),
      });
    }

    const restoreCouponPopup = (couponId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreCoupon(couponId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this coupon again!
          </ReactBSAlert>
        ),
      });
    }

    const suspendCoupon = async (couponId) => {
      let susCoupon = await couponService.suspendCoupon(couponId);
      if(!susCoupon.status && susCoupon.error.errorCode === 403){
        setShowPopup({alert:null});
        context.setIsAuthorized(context)
      }
      else if(susCoupon.status && susCoupon.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Coupon was suspended successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Coupon could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreCoupon = async (couponId) => {
      let resCoupon = await couponService.restoreCoupon(couponId);
      if(!resCoupon.status && resCoupon.error.errorCode === 403){
        setShowPopup({alert:null});
        context.setIsAuthorized(context);
      }
      else if(resCoupon.status && resCoupon.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Coupon was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Coupon could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  
    const isCouponActive = (couponInfo) => {
      if(couponInfo.status === 1  && moment(couponInfo.expires_at)>moment()){
        return true;
      }
      return false;            
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }
    const reviewTransactionPopup = (details) => {
      setShowPopup({
        alert: (
                <ReactBSAlert
                    Success
                    onCancel={() => hideAlert()}
                    style ={{"display":"block","position":"absolute","height" : "auto","width": "fit-content"}}
                    closeOnClickOutside={true}
                    showCancel={false}
                    showConfirm={false}
                    btnSize=""
                >
                    <Row>
                        <Col>
                          <CardTitle tag="h4" align="center">
                              Coupon Details
                          </CardTitle>
                        </Col>
                        <Col align="right">
                            <Button onClick = {() => hideAlert()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
                    <Table size="lg" bordered responsive="sm" className="detailsTable">
                        <tr>
                            <td >
                                Coupon Code
                            </td>
                            <td>
                              {/* {
                                (moment(details.expires_at)<moment()) ? 
                                    <>
                                    {details.times_used ? <Badge color="success" pill>Claimed &nbsp; {details.times_used} / {details.max_usage}</Badge> : ""}
                                    <Badge color="danger" pill> Expired</Badge>
                                    </>
                                :""} */}
                          
                                {details.coupon_code} &nbsp;&nbsp;&nbsp;
                                
                                {details.status === 1  && moment(details.expires_at)>moment()? <Badge color="success" pill> Active</Badge> : ""}
                                {details.status !=1 || moment(details.expires_at)<moment() ? <Badge color="danger" pill> Expired</Badge> : ""}
                                
                            </td>
                        </tr>
                        <tr>
                            <td >
                                Description
                            </td>
                            <td>
                              {details.description?details.description:'-'}
                            </td>
                        </tr>
                       {details.conditions && details.conditions.includes('publisher')? <tr>
                            <td>
                                Publisher Name
                            </td>
                            <td>
                                {details.publisher_ids.map((publisher)=>  <li>{publisher.name}</li>)}
                            </td>
                        </tr>
                        :""}
                        {details.conditions && details.conditions.includes('project')?<tr>
                            <td>
                                Project Name
                            </td>
                            <td>
                                {details.project_ids.map((project)=>  <li>{project.name}</li>)}
                            </td>
                        </tr>
                        :""}
                        {details.conditions && details.conditions.includes('plan')?
                        <tr>
                            <td>
                                Plans
                            </td>
                            <td>
                                {details.plan_ids.map((plan)=>  <li>{plan.name}</li>)}
                            </td>
                        </tr>
                        :""}
                        <tr>
                            <td>
                                Discount Type
                            </td>
                            <td>
                                {details.discount_type}&nbsp;
                            </td>
                        </tr>
                        {details.discount_type ==='percentage' ?
                        <tr>
                            <td>
                                Discount %
                            </td>
                            <td>
                                {details.discount_percentage}&nbsp;%
                            </td>
                        </tr>
                        :""}
                        {details.discount_type ==='flat' ? <tr>
                            <td>
                                Flat discount  Amount
                            </td>
                            <td>
                                {details.discount_flat}&nbsp;
                            </td>
                        </tr>:""
                      }
                        
                        <tr>
                            <td>
                                Limit amount
                            </td>
                            <td>
                              {details.limit_discount?<>
                                <i className="fa fa-inr" style={{"font-size":"15px"}}></i>
                                {details.limit_discount}
                                </>
                                :"No limit"}
                            </td>
                        </tr>
                        {/* <tr>
                            <td>
                                Minimum Order Amount
                            </td>
                            <td>
                                <i className="fa fa-inr" style={{"font-size":"15px"}}></i>{details.min_order_value}
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                Start Date
                            </td>
                            <td className="text-left">
                              <Moment format="DD MMM YYYY hh:mm:ss a">{details.start_date}</Moment>
                          </td>
                        </tr>
                        <tr>
                          
               
                            <td>
                                Expire On
                            </td>
                            <td className="text-left">
                                <Moment format="DD MMM YYYY hh:mm:ss a">{details.expires_at}</Moment>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 Created by
                            </td>
                            <td>
                                {details.created_by}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Created At
                            </td>
                            <td>
                                {moment(details.created_at).local().format('DD MMM YYYY hh:mm:ss a')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Modified At
                            </td>
                            <td>
                                {moment(details.modified_at).local().format('DD MMM YYYY hh:mm:ss a')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Modified By
                            </td>
                            <td>
                                {details.modified_by}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Platform Type
                            </td>
                            <td>
                                {details.platform_type}&nbsp;
                            </td>
                        </tr>
                    </Table>
            </ReactBSAlert> 
        )
      });
    }

  let couponElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!coupons || !coupons.length){
    if(!renderComponent){
        couponElements.push(
       <tr>
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        couponElements.push(
          <tr>
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      coupons.forEach(function(couponDetails) {
        console.log("Coupon Details",couponDetails);
          let className = '';
          if(couponDetails.status==0){
            className = 'table-danger';
          }
          if(moment(couponDetails.expires_at)<moment()) {
            className = 'table-danger';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          couponElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               {/* <td className="text-left">
                   {couponDetails.publisher_id?couponDetails.publisher_id.name:''} <br/>
               </td>
               <td className="text-left">
                   {couponDetails.project_id?couponDetails.project_id.name:''}
               </td> */}
               <td className="text-left">
                   {couponDetails.coupon_code? couponDetails.coupon_code : "" }
               </td>
               <td className="text-left">
                   {couponDetails.description? couponDetails.description : "" }
               </td>
               <td className="text-left">
                   {couponDetails.discount_type? couponDetails.discount_type : "" }
               </td>
               <td className="text-left">
               {couponDetails.discount_type==='percentage'?<td className="text-left">
                   {couponDetails.discount_percentage? couponDetails.discount_percentage+" %" : "" }
               </td>:''}
               {couponDetails.discount_type==='flat'?<td className="text-left">
                   {couponDetails.discount_flat? <><i className="fa fa-inr" style={{"font-size":"12px"}}></i> &nbsp;{couponDetails.discount_flat}</> : "" }
               </td>:""}
               </td>
               
               
               
               {/* <td className="text-left">
                   {couponDetails.status==1?'Active':
                   'Expired'}
               </td> */}
               {/* <td className="text-left">
                   {couponDetails.created_by}
               </td> */}
               {/* <td className="text-left">
                   {couponDetails.id?
                   
                   <Link
                   to={"/transaction/transaction-details?coupon_id="+couponDetails.id}
                   >
                    view
                   </Link>
                   :""}
               </td> */}
               <td className="text-center">
                  <Button className="btn-smb" onClick={(event)=>reviewTransactionPopup(couponDetails)}> Review</Button>
                </td>
                <td >
               {couponDetails.status==1?
               <span>
               
               <a title="Suspend" onClick={(event)=>  suspendCouponPopup(couponDetails.id) } href="javascript:void(0)" class="nav-link">
               <FontAwesomeIcon icon={faTrash} />

             </a>
             </span>
             :''}
             {couponDetails.status==0?
             <a title="Restore" onClick={(event)=>  restoreCouponPopup(couponDetails.id) } href="javascript:void(0)" class="nav-link">
                 <FontAwesomeIcon icon={faTrashRestoreAlt} />
               </a> 
              :'' }
               {/* </td>
               <td className="text-left"> */}
                   {couponDetails.id && isCouponActive(couponDetails)?
                   
                   <Link
                   title="Edit Coupon"
                   to={"/coupon/edit?couponid="+couponDetails.id}
                   className="nav-link"
                   >
                    <FontAwesomeIcon icon={faPencilAlt} />
                   </Link>
                   :""}
               </td>
               
            </tr>
        )
        if(i==coupons.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==coupons.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchCoupons();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }

    const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
  }

  const searchCoupons = () => {
    setActivePage(1);
    // console.log("Search Coupon",formdetails);

    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    
    setPublisherId('');
    setProjectId('');
    setGroupName('');
    setStatus('');
    setPlanType('');
    setPublisher('');
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      plan_id:'',
      project_id:'',
      plan_group_id: '',
      status:'',
      plan_type:'',
    });
    // setProjectNames([]);
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <Row>
                  <Col>
                  <Button
                       style={{float: 'right'}}
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/coupon/create") }
                  >Create Coupon
                </Button>
                  </Col>
                </Row>
                <CardTitle tag="h4">
                   Discount Coupon Dashboard
                </CardTitle>
              <Form id="couponfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={[{value:"1",label:"Active"},{value:"0",label:"Expired"}]}
                    value={[{value:"1",label:"Active"},{value:"0",label:"Expired"}].filter(option => option.value==formdetails.status)}
                    onChange={changeStatus}
                  />
                  </Col>
                  <Col md="3">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>     
                <hr></hr>
                {totalCoupons > 0 ? 
                  <Row>
                        <Col md="6">
                          <div style={{'float':'right'}}>
                            <b>{firstRecord} - {lastRecord} of {totalCoupons}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{'float':'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalCoupons}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                      </Row>
                
                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      {/* <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th>  */}
                      <th className="text-left">Coupon Code</th> 
                      <th className="text-left">Description</th>
                      <th className="text-left">Discount Type</th>
                      <th className="text-left">Discount</th> 
                      <th className="text-left">View More</th>
                      <th className="text-left">Action</th>  
                      </tr>
                    </thead>
                    <tbody>{couponElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default CouponCodeList;
