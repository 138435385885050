
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../UserContext";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
    Collapse
  } from "reactstrap";
  import "./faqs.scss";
import faqService from '../../services/api/faq-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactHtmlParser from 'react-html-parser';

class Tab extends React.Component{
    render(){

        return(
        <div className="tab">
            <div className="title" onClick={()=>{this.props.handleclick(this.props.id)}}>
                <i className={this.props.active?"nc-icon nc-simple-delete" : "nc-icon nc-simple-add"}></i>
                &nbsp;&nbsp;&nbsp;
                <span >{ReactHtmlParser(this.props.title)}</span>
                <div className="spRt">
                    <a title="Edit"  href="javascript:void(0)" className="icn"  onClick={(event)=>  this.props.redirectUrl(event,this.props.id) }>
                    <FontAwesomeIcon icon={faPencilAlt} />
                    </a>
                    <a title="Remove"  href="javascript:void(0)" className="icn" onClick={(event)=> this.props.removeFaq(event,this.props.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                    </a>
                </div>
            </div>
            <div className={this.props.active ? "content show" : "content hide"}>
                  {ReactHtmlParser(this.props.content)}
            </div>
        </div>
        )
        }
   }

const FAQs=(props)=>{
    const history=new useHistory();
    const context = useContext(UserContext);
    const [toggleQuestion, setTogglequestion] = useState(false);
    const [faqs,setFaqs] = useState([]);
    const [activeTab,setActiveTab]  = useState(false);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [reloadComponent,setReloadComponent] = useState(0);
    const [componentRendered, setComponentRendered] = useState(false);
    useEffect(()=>{
        (async ()=>{
            let response=await faqService.getAllFaqs({});
            // if(!response.status && response.error.errorCode === 403){
            //   context.setIsAuthorized(context);
            // }
            if(response.status && response.data){
                console.log(response);
                setFaqs(response.data);
                
            }
            setComponentRendered(true);
        })()
    },[reloadComponent]);


    const handleTabClick=(id)=>{
        if(id===activeTab){
          setActiveTab(false);
        }else{
          setActiveTab(id);
        }

        }
    const redirectUrl = (e,faq_id) =>{
            e.preventDefault()
            history.push("/faq/edit?faqId="+faq_id);
        }
         
    const deleteFAQ = async (faq_id) => {
            let response = await faqService.deleteFAQ(faq_id);
            if(!response.status && response.error.errorCode === 403){
              setShowPopup({alert:null})
              context.setIsAuthorized(context);
            }
            else if(response.status && response.data){
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Removed!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    FAQ was removed successfully.
                  </ReactBSAlert>
                ),
              });
                
            }else{
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Please try again!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    FAQ could not be removed. Please try again.
                  </ReactBSAlert>
                ),
              });
            }
            setReloadComponent(Math.random());
          }

          const confirmationPopup = (event,faq_id) => {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Are you sure want to delete this question?"
                        onConfirm={() => {
                            deleteFAQ(faq_id);
                        }}
                        onCancel={() => {
                            hideAlert();
                        }}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Yes, delete it!"
                        cancelBtnText="Cancel"
                        showCancel
                        btnSize=""
                    >
                        You want to delete this Question!
                    </ReactBSAlert>
                ),
            });
        };

    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };  

    return (
        <div className="content">
            {showPopup.alert}
            <h3>Frequently Asked Questions</h3>
            <Row>
                    <Col md="12">
                    <Button
                        style={{float: 'right','margin-top':'-50px'}}
                        className="btn-round"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={(event)=>  history.push("/faq/create") }
                    >Add FAQ</Button>
                    </Col>
            </Row>
            {!faqs || !Object.keys(faqs).length ? (
                  !componentRendered ? (
                        <Row>
                            <td colSpan="4" className="text-center">
                                <Spinner
                                    animation="border"
                                    role="status"
                                >
                                  <span className="sr-only">
                                      Loading...
                                  </span>
                                </Spinner>
                            </td>
                        </Row>
                      ) : (
                        <Row>
                              <td colSpan="4" className="text-center">
                                    <CardTitle tag="h4">
                                           No Faq found     
                                    </CardTitle>
                                        </td>
                        </Row>
                                )
                            ) : (
                              <div id="accordion">
                                  {
                                  Object.keys(faqs).map((tab,index)=>{
                                   return <>
                                          <div className="faqGroupLabel">
                                            {faqs[tab].name}
                                          </div>

                                            {faqs[tab].questions.map((tab,index)=>{
                                            return <Tab key={index} id={tab.id} handleclick={handleTabClick} redirectUrl={redirectUrl} removeFaq={confirmationPopup} title={tab.question} content={tab.answer} active={tab.id===activeTab?true:false} />
                                            })}
                                            <br/>
                                  </>
                                  })
                                  }
                            </div>

                            )}


        </div>
    );
}



export default FAQs;