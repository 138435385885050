import React, { useEffect, useState,useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";

const AwsResources = (props)=> {
  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({projectslug:''});
  const [resources, setResources] = useState(false);
  const [totalResources,setTotalResources] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({projectslug:'',resource_type:''});
  const records = 10;
  const resourceTypeArray = [
        {
            value: "s3",
            label: "S3",
        },
        {
            value: "acm",
            label: "ACM",
        },
        {
            value: "cdn",
            label: "CDN",
        },
    ];
  const context = useContext(UserContext);
  useEffect( () => {
    (async () =>{
            let resourcesData = await projectService.getAwsResources(formdetails,activePage,records);
            if(resourcesData.status){
                setResources(resourcesData.data);
            }

            let getTotalResources = await projectService.getTotalAwsResources(formdetails);
            if(getTotalResources.status){
                setTotalResources(getTotalResources.data.count);
            }
            setRenderComponent(true);
        })();
    }, [activePage,search]);


    const resetElements = () => {
      document.getElementById('searchproject').value = '';
      setFormDetails({projectslug:'',resource_type:""});
      setActivePage(1);
      setSearchDetails({projectslug:'',resource_type:""});
    }

  const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
  }

  const handleProjectChange = (event) => {
    setFormDetails({
      ...formdetails,
      projectslug: event.target.value,
    });
  }
  const handleResourceTypeChange = (event) => {
    setFormDetails({
        ...formdetails,
        resource_type : event.value,
      });  
  }

  const handleSubmit = function(e){  searchResources();e.preventDefault() }

  const searchResources = () => {
      setActivePage(1);
      setSearchDetails(formdetails);
  }

  
  let resourcesElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!resources || !resources.length){
    if(!renderComponent){
        resourcesElements.push(
        <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        resourcesElements.push(
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4">No Resources found.</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      resources.forEach(function(resourceDetails) {
        if(i==1){
          firstRecord = (activePage-1)*records+i;
        }
        
        resourcesElements.push(
            <tr>
               <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left">
                   {resourceDetails.project_id?resourceDetails.project_id.name:''}
               </td>
               <td className="text-left">
                   {resourceDetails.project_id?resourceDetails.project_id.slug:''}
               </td>
               <td className="text-left">
                   {resourceDetails.resource_type} 
               </td>
               <td className="text-left">
                    {resourceDetails.arn} 
               </td>
               <td className="text-left">
                    {resourceDetails.resource_meta} 
               </td>
            </tr>
        )
        if(i==resources.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==resources.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    Aws Resources List 
                </CardTitle>

                <Form id="awsfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Input type="text" name="searchproject" style ={{height:"40px"}} id="searchproject" onChange={handleProjectChange} placeholder="Project Slug .."/>
                  </Col>
                  <Col md="4">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Resource .."
                      name="resource_type"
                      id="resource_type"
                      options={resourceTypeArray}
                      value={resourceTypeArray.filter(option => option.value===formdetails.resource_type)}
                      onChange={handleResourceTypeChange}
                    />                    
                  </Col>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                      //onClick={searchResources}
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetElements}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                </Row>
                </Form>
                <hr></hr>
                {totalResources > 0 ? 
                  <Row>
                    
                    <Col md="6">
                      <div style={{float:'right'}}>
                        <b>{firstRecord} - {lastRecord} of {totalResources}</b>
                      </div>
                    </Col>
                    <Col md="6">
                    <div style={{float:'left'}}>
                        <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalResources}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        />
                    </div>
                  </Col>
                  </Row>
                : null}
                 
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th>   
                        <th className="text-left">Project Name</th>
                        <th className="text-left">Project Slug</th>
                        <th className="text-left">Resource Type</th>
                        <th className="text-left">ARN</th>
                        <th className="text-left">Resource Meta</th>
                      </tr>
                    </thead>
                    <tbody>{resourcesElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>
                
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default AwsResources;
