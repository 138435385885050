import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import ReorderCategories from "./ReorderCategories";
import CreateCategory from './CreateCategory'
import Projects from "../projects/Projects";
import {useHistory} from "react-router-dom";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from 'react-bootstrap-sweetalert'
import UploadImage from "../customizations/UploadImage";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const Categories = (props)=> {
  const history = useHistory();
  const context = useContext(UserContext);
  const val=new URLSearchParams(props.location.search).get('categoryid');
  const projectId = new URLSearchParams(props.location.search).get('projectid');
  const categoryId = new URLSearchParams(props.location.search).get('categoryid')
  const [formdetails, setFormDetails] = useState({projectid:projectId,categoryid:"",categoryname:"",categoryalias:"",categorydesc : "",categorycattitle : ''});
  const [categories, setCategories] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [reorderCategories, setReorderCategories] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const [showIconUploader , setShowIconUploader] = useState(false);
  const [categoriesIcon , setCategoriesIcon ] = useState([]);
  const [selectedCategoryId ,setSelectedCategoryId] = useState("");
  const [showIconSpinner, setShowIconSpinner] = useState(false);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [reorderCats,setReorderCats] = useState([]);

  // const [showQRPopup, setShowQRPopup] = useState(false);
  useEffect( () => {
    (async () =>{
        let getProjectDetails = await projectsService.getProjectDetails(projectId);
        if(getProjectDetails.status){
          setProjectDetails(getProjectDetails.data);
        }

        let categoryData = await contentService.getCategories(projectId);
        if(!categoryData.status && categoryData.error.errorCode === 403){
          let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
              window.history.replaceState({},'',newurl)
          // context.setIsAuthorized(context);
        }
         if(categoryData.status){
                setCategories(categoryData.data);
                for(const categoryDetails of categoryData.data) { 
                  categoriesIcon[categoryDetails._id] = categoryDetails;
                }
                setCategoriesIcon({...categoriesIcon ,categoriesIcon});
            }
            setRenderComponent(true);
        })();
    }, [formdetails,reorderCategories,createCategory]);

    useEffect(()=>{
      (async()=>{
        const catsHierarchy = await contentService.getCategoriesHierarchy(projectId);
        if(catsHierarchy.status && catsHierarchy.data){
          setReorderCats(catsHierarchy.data);
        }
      })();
    },[formdetails,reorderCategories,createCategory])

    const handleCategoryNameChange = (event) => {
        
    }

    const handleAliasChange = (event) => {
       
    }  
    const handleChange = (event) => {
       
    }  

  const updateCategoryIcon = async (categoryId,iconURL) => {
    try {
      let formdata = {
        project_id : projectId,
        category_id :categoryId,
        icon_url : iconURL,
      }
      const response  = await contentService.updateCategoryIcon(formdata);
      if(!response.status && response.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(response.status && response.data) {
        console.log("Response");

      }

    }catch(error) {
      console.log(error);
    }
  }
  const onSave = async (categoryId) => {
    setShowIconSpinner(true);
    // const result = await updateCategoryIcon(categoryId ,categoriesIcon[categoryId]);
    try {
      let formdata = {
        project_id : projectId,
        category_id :categoryId,
        icon_url : categoriesIcon[categoryId].icon_url,
      }
      const response  = await contentService.updateCategoryIcon(formdata);
      if(!response.status && response.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(response.status && response.data) {
        console.log("Response");
        setShowIconSpinner(false);
        setTimeout(()=>{
            setShowIconSpinner(false)
            setAlert({show:true,type:'success',message:'Icon has uploaded Successfully'});
            },1000);
            
            setTimeout(()=>{
              setAlert({show:false,type:'',message:''});
            },3000);
        
        
        setShowIconUploader(false);
      }

    }catch(error) {
      console.log(error);
    }
  }
  const handleEnumChange = async (categoryId ,imageData) => {
    if(imageData.value) {
      categoriesIcon[categoryId].icon_url = imageData.value;
    }else {
      categoriesIcon[categoryId].icon_url = false;
    }
    
    setCategoriesIcon({...categoriesIcon,categoriesIcon});
  }

  const showCategoryIconUploadPopup = async (categoryDetails) =>{
    setSelectedCategoryId(categoryDetails._id);
    setShowIconUploader(true);
  }
  const saveCategoryData = async (categoryId,event) => {
      let validations = true;
      if(document.getElementById('name_'+categoryId).value.trim() == ''){
        validations = false;
        document.getElementById('error_name_label_'+categoryId).style.display = '';
        setTimeout(()=>{
          document.getElementById('error_name_label_'+categoryId).style.display = 'none';
        },2000);
      }

      if(document.getElementById('alias_'+categoryId).value.trim() == ''){
        validations = false;
        document.getElementById('error_alias_label_'+categoryId).style.display = '';
        setTimeout(()=>{
          document.getElementById('error_alias_label_'+categoryId).style.display = 'none';
        },2000);
      }

      if(!validations){
        return false;
      }
      document.getElementById('spinner_'+categoryId).style.display = '';
      document.getElementById('save_'+categoryId).style.display = 'none';
      document.getElementById('cancel_'+categoryId).style.display = 'none';
      formdetails.categoryid = categoryId;  
      let formdata = {
          projectid:projectId,
          categoryid:categoryId,
          categoryname:document.getElementById('name_'+categoryId).value,
          categoryalias:document.getElementById('alias_'+categoryId).value,
          categorydesc:document.getElementById('desc_'+categoryId).value,
          categorycattitle:document.getElementById('cat_title_'+categoryId).value,

      }
      let saveCategory = await contentService.updateCategory(formdata);
      if(!saveCategory.status && saveCategory.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(saveCategory.status){
        setTimeout(()=>{
            document.getElementById('edit_'+categoryId).style.display = '';
            document.getElementById('spinner_'+categoryId).style.display = 'none';
            document.getElementById('success_alert_'+categoryId).style.display = '';
            document.getElementById('span_name_label_'+categoryId).style.display = 'block';
            document.getElementById('span_alias_label_'+categoryId).style.display = 'block';
            document.getElementById('span_desc_label_'+categoryId).style.display = 'block';
            document.getElementById('span_cat_title_label_'+categoryId).style.display = 'block';
            document.getElementById('span_name_form_'+categoryId).style.display = 'none';
            document.getElementById('span_alias_form_'+categoryId).style.display = 'none';
            document.getElementById('span_desc_form_'+categoryId).style.display = 'none';
            document.getElementById('span_cat_title_form_'+categoryId).style.display = 'none';
            setFormDetails({
                ...formdetails,
                categoryid: '',
                categoryname: '',
                categoryalias : '',
                categorydesc : '',
                categorycattitle : ''
            });
        },1000);
      }else{
        document.getElementById('spinner_'+categoryId).style.display = 'none';
        document.getElementById('failure_alert_'+categoryId).style.display = '';
        document.getElementById('save_'+categoryId).style.display = '';
        document.getElementById('cancel_'+categoryId).style.display = '';
      }

      setTimeout(()=>{
        document.getElementById('success_alert_'+categoryId).style.display = 'none';
        document.getElementById('failure_alert_'+categoryId).style.display = 'none';
      },2000);

      console.log(categoryId);
  }

  const showSaveOptions = async (categoryId, categoryName, categoryAlias, categoryDesc,categoryCatTitle, event) => {
      
    document.getElementById('edit_'+categoryId).style.display = 'none';
    document.getElementById('save_'+categoryId).style.display = '';
    document.getElementById('cancel_'+categoryId).style.display = '';
    document.getElementById('span_name_label_'+categoryId).style.display = 'none';
    document.getElementById('span_alias_label_'+categoryId).style.display = 'none';
    document.getElementById('span_desc_label_'+categoryId).style.display = 'none';
    document.getElementById('span_cat_title_label_'+categoryId).style.display = 'none';
    document.getElementById('span_name_form_'+categoryId).style.display = 'block';
    document.getElementById('span_alias_form_'+categoryId).style.display = 'block';
    document.getElementById('span_desc_form_'+categoryId).style.display = 'block';
    document.getElementById('span_cat_title_form_'+categoryId).style.display = 'block';
    document.getElementById('name_'+categoryId).value = categoryName;
    document.getElementById('alias_'+categoryId).value = categoryAlias;
    document.getElementById('desc_'+categoryId).value = categoryDesc;
    document.getElementById('cat_title_'+categoryId).value = categoryCatTitle;

    console.log(categoryId);
}

const hideSaveOptions = async (categoryId,event) => {
    document.getElementById('edit_'+categoryId).style.display = 'block';
    document.getElementById('save_'+categoryId).style.display = 'none';
    document.getElementById('cancel_'+categoryId).style.display = 'none';
    document.getElementById('span_name_label_'+categoryId).style.display = 'block';
    document.getElementById('span_alias_label_'+categoryId).style.display = 'block';
    document.getElementById('span_name_form_'+categoryId).style.display = 'none';
    document.getElementById('span_alias_form_'+categoryId).style.display = 'none';
    document.getElementById('span_desc_label_'+categoryId).style.display = 'block';
    document.getElementById('span_cat_title_label_'+categoryId).style.display = 'block';
    document.getElementById('span_desc_form_'+categoryId).style.display = 'none';
    document.getElementById('span_cat_title_form_'+categoryId).style.display = 'none';

    console.log(categoryId);
}

const redirectUrl = (e,categoryId) =>{
    e.preventDefault();
    history.push("/contentsettings/articles?projectid="+projectId+"&categoryid="+categoryId);
}

  let categoryElements = [];
  if(!categories || !categories.length){
    if(!renderComponent){
      categoryElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    categoryElements.push(
        <td colSpan="5" className="text-center">
            <CardTitle tag="h4">No Category Found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
    categories.forEach(function(categoryDetails) {
        categoryElements.push(<>
            <tr id={"row_"+categoryDetails._id}>
               <td className="text-left">{i}</td>
               <td className="text-left">
                   <span id={"span_name_label_"+categoryDetails._id}>
                      <a title="View Articles" onClick={(event)=>  redirectUrl(event,categoryDetails._id) } href="javascript:void(0)" class="nav-link">
                           {categoryDetails.name}
                      </a>
                   
                   </span>
                   <span style={{display: 'none'}} className="display-none" id={"span_name_form_"+categoryDetails._id}>
                   <Input
                        name={"name_"+categoryDetails._id}
                        id={"name_"+categoryDetails._id}
                        type="text"
                        onChange={handleCategoryNameChange}
                        //value={categoryDetails.name}
                    />
                    <label id={"error_name_label_"+categoryDetails._id} className="error" style={{display:'none',color:'red'}}>
                              Category name can not be empty.
                     </label>
                    </span>
               </td>
               <td className="text-left">{categoryDetails.cat_guid}</td>
               <td className="text-left">
                    <span  id={"span_alias_label_"+categoryDetails._id}>
                        {categoryDetails.alias}
                        </span>
                        <span className="has-error" style={{display: 'none'}} id={"span_alias_form_"+categoryDetails._id}>
                        <Input
                            name={"alias_"+categoryDetails._id}
                            id={"alias_"+categoryDetails._id}
                            type="text"
                            onChange={handleAliasChange}
                            //value={categoryDetails.alias}
                        />
                        <label id={"error_alias_label_"+categoryDetails._id}  className="error" style={{display:'none',color:'red'}}>
                              Category alias can not be empty.
                        </label>
                    </span>
                    
               </td>
               <td className="text-left">
                    <span  id={"span_desc_label_"+categoryDetails._id}>
                        {categoryDetails.desc}
                        </span>
                        <span className="has-error" style={{display: 'none'}} id={"span_desc_form_"+categoryDetails._id}>
                        <Input
                            name={"desc_"+categoryDetails._id}
                            id={"desc_"+categoryDetails._id}
                            type="text"
                            onChange={handleChange}
                            //value={categoryDetails.desc}
                        />
                    </span>
                    
               </td>
               <td className="text-left">
                    <span  id={"span_cat_title_label_"+categoryDetails._id}>
                        {categoryDetails.cat_title}
                        </span>
                        <span className="has-error" style={{display: 'none'}} id={"span_cat_title_form_"+categoryDetails._id}>
                        <Input
                            name={"cat_title_"+categoryDetails._id}
                            id={"cat_title_"+categoryDetails._id}
                            type="text"
                            onChange={handleChange}
                            //value={categoryDetails.desc}
                        />
                    </span>
                    
               </td>
               <td className="text-left">
               <Button
                    className="btn-round"
                    color="info"
                    id={"edit_"+categoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  showSaveOptions(categoryDetails._id,categoryDetails.name,categoryDetails.alias,categoryDetails.desc,categoryDetails.cat_title, event) }
                >
                    Edit
                </Button>

                <Button
                    style={{display: 'none'}}
                    className="btn-round"
                    color="primary"
                    id={"save_"+categoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  saveCategoryData(categoryDetails._id,event) }
                >
                    Save
                </Button>

                <Button
                    style={{display: 'none'}}
                    className="btn-round"
                    color="danger"
                    id={"cancel_"+categoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  hideSaveOptions(categoryDetails._id,event) }
                >
                    Cancel
                </Button>
                </td>
                <td className="text-left">
                 <Spinner id={"spinner_"+categoryDetails._id} style={{display: 'none'}}  animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>

                  <Alert id={"success_alert_"+categoryDetails._id} style={{display: 'none',width:'100px'}} color="success">
                    Saved
                  </Alert>

                  <Alert id={"failure_alert_"+categoryDetails._id} style={{display: 'none',width:'100px'}} color="danger">
                    Not Saved. Try Again.
                  </Alert>

               </td>
               <td>
                 <i class="fa fa-upload" aria-hidden="true" onClick={(event)=>  showCategoryIconUploadPopup(categoryDetails,event) }
                ></i>
                 {/* <Button
                    className="btn-round"
                    color="success"
                    // id={"cancel_"+categoryDetails._id}
                    size="sm"
                    type="button"
                    >
                    Upload Icon
                </Button> */}
               </td>
            </tr>

            </>
        )
        
        if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
            let subCategories = categoryDetails.sub_categories;
            subCategories.forEach(function(subCategoryDetails) {
              categoryElements.push(
                <tr id={"row_"+subCategoryDetails._id}>
               <td className="text-left"></td>
               <td className="text-left">
                   <span style={{'margin-left':"20px"}} id={"span_name_label_"+subCategoryDetails._id}>
                   {subCategoryDetails.name}
                   </span>
                   <span style={{display: 'none'}} className="display-none" id={"span_name_form_"+subCategoryDetails._id}>
                   <Input
                        name={"name_"+subCategoryDetails._id}
                        id={"name_"+subCategoryDetails._id}
                        type="text"
                        onChange={handleCategoryNameChange}
                        //value={subCategoryDetails.name}
                    />
                    </span>
               </td>
               <td className="text-left">{subCategoryDetails.cat_guid}</td>
               <td className="text-left">
                    <span  id={"span_alias_label_"+subCategoryDetails._id}>
                        {subCategoryDetails.alias}
                        </span>
                        <span style={{display: 'none'}} id={"span_alias_form_"+subCategoryDetails._id}>
                        <Input
                            name={"alias_"+subCategoryDetails._id}
                            id={"alias_"+subCategoryDetails._id}
                            type="text"
                            onChange={handleAliasChange}
                            //value={subCategoryDetails.alias}
                        />
                    </span>
               </td>
               <td className="text-left">
                    <span  id={"span_desc_label_"+subCategoryDetails._id}>
                        {subCategoryDetails.desc}
                        </span>
                        <span style={{display: 'none'}} id={"span_desc_form_"+subCategoryDetails._id}>
                        <Input
                            name={"desc_"+subCategoryDetails._id}
                            id={"desc_"+subCategoryDetails._id}
                            type="text"
                            onChange={handleChange}
                            //value={subCategoryDetails.alias}
                        />
                    </span>
               </td>
               <td className="text-left">
                    <span  id={"span_cat_title_label_"+subCategoryDetails._id}>
                        {subCategoryDetails.cat_title}
                        </span>
                        <span style={{display: 'none'}} id={"span_cat_title_form_"+subCategoryDetails._id}>
                        <Input
                            name={"cat_title_"+subCategoryDetails._id}
                            id={"cat_title_"+subCategoryDetails._id}
                            type="text"
                            onChange={handleChange}
                            //value={subCategoryDetails.alias}
                        />
                    </span>
               </td>
               <td className="text-left">
               <Button
                    className="btn-round"
                    color="info"
                    id={"edit_"+subCategoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  showSaveOptions(subCategoryDetails._id,subCategoryDetails.name,subCategoryDetails.alias,subCategoryDetails.desc,subCategoryDetails.cat_title, event) }
                >
                    Edit
                </Button>

                <Button
                    style={{display: 'none'}}
                    className="btn-round"
                    color="primary"
                    id={"save_"+subCategoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  saveCategoryData(subCategoryDetails._id,event) }
                >
                    Save
                </Button>

                <Button
                    style={{display: 'none'}}
                    className="btn-round"
                    color="danger"
                    id={"cancel_"+subCategoryDetails._id}
                    size="sm"
                    type="button"
                    onClick={(event)=>  hideSaveOptions(subCategoryDetails._id,event) }
                >
                    Cancel
                </Button>
                </td>
                <td className="text-left">
                 <Spinner id={"spinner_"+subCategoryDetails._id} style={{display: 'none'}}  animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>

                  <Alert id={"success_alert_"+subCategoryDetails._id} style={{display: 'none',width:'100px'}} color="success">
                    Saved
                  </Alert>

                  <Alert id={"failure_alert_"+subCategoryDetails._id} style={{display: 'none',width:'100px'}} color="danger">
                    Not Saved. Try Again.
                  </Alert>

               </td>
            </tr>
              )
            })
        }

        i++;
    })
  }

  if(!projectId){
    return (
    <Projects navigate="contentsettings/categories"></Projects>
    );
  }else{
      if(createCategory){
          return (
            <CreateCategory setCreateCategory={setCreateCategory} projectId ={projectId}/>
          );
      }else if(reorderCategories){
        return (
          <ReorderCategories setReorderCategories={setReorderCategories} projectId ={projectId} categories={reorderCats}/>
        );
      }else{
        return (
          <div className="content">
            {showIconUploader?
            <ReactBSAlert
                            title={`Icon for category ${categoriesIcon[selectedCategoryId].name}`} 
                            onCancel={()=>{setShowIconUploader(false)}}
                            openAnim = {{ name: 'showSweetAlert', duration: 700 }}
                            showConfirm={false}
                            closeOnClickOutside
                        >
                          {selectedCategoryId?
                            <UploadImage projectId={projectId} imageDetails ={
                                        {
                                          "default":"",
                                        // "size" : "64x64",
                                        "file_type": "image/png",
                                      }
                                    }
                                  id ={selectedCategoryId}

                                elementValue={categoriesIcon[selectedCategoryId].icon_url}
                                handleEnumChange={handleEnumChange}
                            />:""}
                            {/* { categoriesIcon[categoryDetails._id]!=""? */}
                            <Button
                            type ="button"
                            onClick = { async ()=>{ onSave(selectedCategoryId)}}
                            > Save</Button>

                            <Button
                            type ="button"
                            onClick = { async ()=>{ setSelectedCategoryId("");setShowIconUploader(false)}}
                            > Cancel</Button>
                            {/* :""} */}
                            {showIconSpinner?
                              <Spinner animation="border" role="status">

                              <span className="sr-only"> Loading... </span>
                            </Spinner>:""}
                            {alert.show?
                              <Alert color={alert.type}>
                                {alert.message}
                              </Alert>
                            :''}
                        </ReactBSAlert>:""}
            <Container>
              <Row>
              <Col className="ml-auto mr-auto"  md="12">
                  <Card className="card-signup text-center">
                    <CardHeader>
                    <CardTitle tag="h4">
                    {projectDetails.name?projectDetails.name+' - ':''}Category Manager
                        
                    </CardTitle>
                    <div align="right">
                    <Button
                      className="btn-smb inUsr"
                      type="button"
                      onClick={(event)=>  setCreateCategory(true) }
                      >
                        Create Category
                    </Button>
                    {categories.length?<Button
                          className="btn-smb"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={(event)=>  setReorderCategories(true) }
                      >Reorder Categories</Button>:''}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CardTitle align="left">
                        {categories?"Total Category : "+categories.length:""}   
                    </CardTitle>
                    <Table responsive>
                        <thead className="text-primary">
                          <tr>
                          <th className="text-left">#</th>   
                            <th className="text-left">Name</th>
                            <th className="text-left">GUID</th>
                            <th className="text-left">Alias</th>
                            <th className="text-left">Desc</th>
                            <th className="text-left">Title</th>
                            <th className="text-left">Action</th>
                            <th className="text-left"></th>
                            <th className="text-left">Icon</th>
                          </tr>
                        </thead>
                        <tbody>{categoryElements}</tbody>
                      
                      </Table>
                    </CardBody>
                    <CardFooter>
                      
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
              }}
            />
          </div>
        );
      }
  }
  
}

export default Categories;
