import React, { useEffect, useState ,useContext} from "react";
import projectService from "../../services/api/projects-service";
import manageProjectService from "../../services/api/manage-project-service";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import Moment from "react-moment";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Spinner,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const Projects = (props)=> {
  const history = new useHistory(); 
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisherids:[],publishername:'',publisheremail:'',projectname:'', status: 0});
  const [projects, setProjects] = useState(false);
  const [totalProjects,setTotalProjects] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({publisherids:[],publishername:'',publisheremail:'',projectname:'',status: 0});
  const [allPublishers, setAllPublishers] = useState([]);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [projectsInQueue, setProjectsInQueue] = useState([]);
  const statusFilters = [{
                          value: 1, 
                          label: "Active"
                        },{
                          value: -2,
                          label: "Deleted"
                        }]

  const records = 10;

  useEffect( () => {
    (async () =>{
      let publishers = await projectService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")"};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
          }
        })();
    }, []);


  useEffect( () => {
    (async () =>{
            let projectsData = await manageProjectService.getAllProjects(formdetails,activePage,records);
            if(projectsData.status && projectsData.data){
                // console.log("ProjectData",projectsData);
                setProjects(projectsData.data);
            }

            let getTotalProjects = await projectService.getTotalProjects(formdetails);
            if(getTotalProjects.status){
                setTotalProjects(getTotalProjects.data.count);
            }
              setRenderComponent(true);
        })();
    }, [activePage,search,reloadComponent]);

  const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
  }

  const handleProjectChange = (event) => {
    setFormDetails({
      ...formdetails,
      projectname: event.target.value.trim(),
    });
  }

  const handlePublisherNameChange = (event) => {
    setFormDetails({
      ...formdetails,
      publishername: event.target.value.trim(),
    });
  }

  const handlePublisherEmailChange = (event) => {
    setFormDetails({
      ...formdetails,
      publisheremail: event.target.value.trim(),
    });
  }

  const handleStatusFilterChange = (option) =>{
    setFormDetails({
      ...formdetails,
      status: option.value
    })
  }

  const handleSubmit = function(e){  searchProjects();e.preventDefault() }

  const searchProjects = () => {
      setActivePage(1);
      setSearchDetails(formdetails);
  }

  

  const resetElements = () => {
    document.getElementById('searchproject').value = '';
    document.getElementById('publishername').value = '';
    document.getElementById('publisheremail').value = '';
    setFormDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'', status: 0});
    setSearchDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'', status: 0});
  }

  const addProjectToDeleteQueue = async(projectId)=>{
    const response = await manageProjectService.addProjectToDeleteQueue(projectId)
    if(!response.status && response.error.errorCode === 403){
      context.setIsAuthorized(context);
    }
    setReloadComponent(Math.random())
    console.log(response)
  }

  const removeProjectFromQueue = async(elementId)=>{
    const response = await manageProjectService.removeElementFromQueue(elementId);
    if(!response.status && response.error.errorCode === 403){
      context.setIsAuthorized(context);
    }
    setReloadComponent(Math.random())
    console.log(response)
  }

  let projectElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!projects || !projects.length){
    if(!renderComponent){
      projectElements.push(
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    projectElements.push(
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No Projects found.</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      projects.forEach(function(projectDetails) {
        let className = '';
        if(i==1){
          firstRecord = (activePage-1)*records+i;
        }

          // if(projectDetails.deletionStatus==-1){
          //   className = 'table-warning';
          // }
        
        projectElements.push(
            <tr id={"row_"+projectDetails._id} className={className}>
               <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left">

                   {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.name}
                 </a>
                   :
                   <a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.name}
                 </a>}
               </td>
               <td className="text-left">
                 
                  {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.slug}
                 </a>
                   :<a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.slug}
                 </a>} 
               </td>
               <td className="text-left">
                   {projectDetails.publisherName}
               </td>
               <td className="text-left">
                   {projectDetails.publisherEmail}
               </td>
               <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {projectDetails.created_at}
                            </Moment>
               </td>
               
               <td className="text-left">
                 {
                  projectDetails.deletionStatus === null || projectDetails.deletionStatus === -1? 
                  
                  <Button
                      style={{float: 'right','width':'145px'}}
                      className="btn-round"
                      color="info"
                      size="sm"
                      type="button"
                      onClick= {()=>{addProjectToDeleteQueue(projectDetails.id)}}
                      >
                          Add to queue
                    </Button> : 
                     projectDetails.deletionStatus === 0? 

                    <Button
                    style={{float: 'right','width':'145px'}}
                    className="btn-round"
                    color="warning"
                    size="sm"
                    type="button"
                    onClick= {()=>{removeProjectFromQueue(projectDetails.queueId)}}
                    >
                        Remove From Queue
                    </Button> : "Deleted"

                   
                
                }
                    
               </td>
            </tr>
        )
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  
    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    Manage Projects 
                </CardTitle>
                
                <Form id="projectfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  {/* <Col md="4">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="select publishers"
                    name = "publisherids"
                    id="publisherids"
                    isMulti = "true"
                    options={allPublishers}
                    onChange={(value)=>  handlePublisherChange(value) }
                  />
                  </Col> */}
                  <Col md="3">
                  <Input type="text" name="publishername" id="publishername" onChange={handlePublisherNameChange} placeholder="Publisher Name .."/>
                  </Col>
                  <Col md="3">
                  <Input type="text" name="publisheremail" id="publisheremail" onChange={handlePublisherEmailChange} placeholder="Publisher Email .."/>
                  </Col>
                  <Col md="3">
                  <Input type="text" name="searchproject" id="searchproject" onChange={handleProjectChange} placeholder="Project Name .."/>
                  </Col>

                  <Col md="3">
                    <Select
                          className="react-select primary"
                          id="status-filter roles"
                          classNamePrefix="react-select"
                          placeholder="Select Status"
                          name = "roles"
                          options={statusFilters}
                          onChange={(option)=>{
                          handleStatusFilterChange(option)
                          }}
                          // value={formdetails.status}
                    />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                      //onClick={searchProjects}
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetElements}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                </Row>
                </Form>
                <hr></hr>
                {totalProjects > 0 ? 
                  <Row>
                    <Col md="6">
                      <div style={{float:'right',margin:'5px'}}>
                        <b>{firstRecord} - {lastRecord} of {totalProjects}</b>
                      </div>
                    </Col>
                    <Col md="6">
                    <div style={{float:'left'}}>
                        <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalProjects}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        />
                    </div>
                  </Col>
                  </Row>
                : null}
                 
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th>   
                        <th className="text-left">Project Name</th>
                        <th className="text-left">Project Slug</th>
                        <th className="text-left">Publisher Name</th>
                        <th className="text-left">Publisher Email</th>
                        <th className="text-left">Created At</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{projectElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>
                
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default Projects;
