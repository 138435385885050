import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ticketsService from "../../services/api/zoho-desk-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import Select from "react-select";
import Scrollspy from 'react-scrollspy'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  Table,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { indexOf, size } from "lodash";


const ZohoDeskViewTicket = (props) => {

  const history = new useHistory();
  const ticketId = new URLSearchParams(props.location.search).get(
    "ticketId"
  );
  if (!ticketId) {
    history.push("/zohodesk/dashboard");
  }

  const [ticketIdState, setTicketIdState] = useState("");
  const [ticketIdError, setTicketIdError] = useState("");
  const [ticketCommentState, setTicketCommentState] = useState("");
  const [ticketCommentError, setTicketCommentError] = useState("");
  const [ticket, setTicketData] = useState([]);
  const [conversations, setConversationsData] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [thread, setThread] = useState(false);
  const [conversationListState,setConversationListState] = useState([])
  console.log("TICKET-ID", ticketId);

  const [formdetails, setFormDetails] = useState({ ticket_id: ticketId, status: '' });
  const [statusState, setStatusState] = useState("");
  const [statusError, setStatusError] = useState("");
  const [showUpdateButton, setShowUpdateButton] = useState(true);
  const [statusUpdateAlert, setStatusUpdateAlert] = useState({show:false,message:"",type:""});
  const [statusUpdateLoader, setStatusUpdateLoader] = useState(false);
  const statusArray = [
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Closed",
      label: "Closed",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Escalated",
      label: "Escalated",
    }
  ]
  useEffect(() => {
    (async () => {
      let ticketData = await ticketsService.getTicket(ticketId);
      console.log("TicketData:", ticketData);
      if (ticketData.status && ticketData.data) {
        setTicketData(ticketData.data);
      }
      setRenderComponent(true);
    })()
  }, [reloadComponent]);

  useEffect(() => {
    (async () => {
      let conversationsData = await ticketsService.getConversations(ticketId);
      console.log("ConversationsData:", conversationsData);
      if (conversationsData.status && conversationsData.data) {
        let data = conversationsData.data
        const convs_desc = []
          const sno = []
          const timeCreation = []
          const roles = []
          const convs_user_names = [];
          const conversations_list = [];
        for (let i = 0; i < data.length; i++) {
          {
            if (data[i].summary) {
              if (data[i].summary.indexOf('...') > -1) {
                let threadId = data[i].id;
                let threadData = await ticketsService.getThread(ticketId, threadId);
                if (threadData.status && threadData.data) {
                  console.log("THREAD DATA", threadData.data.plainText)
                  convs_desc.push(threadData.data.plainText);
                }
              }
              else {
                convs_desc.push(data[i].summary);
              }
            }
            if (!data[i].summary) {
              convs_desc.push(data[i].content);
            }
          }
          { sno.push(i + 1); }
          {
            if(data[i].author){
              roles.push(data[i] && data[i].author ? data[i].author.type : '');
            }
            if(!data[i].author) {
              roles.push(data[i] && data[i].commenter ? data[i].commenter.type : '');
            }
          }
          {
            if(data[i].summary){
              timeCreation.push(data[i].createdTime);
            }
            if(!data[i].summary) {
              timeCreation.push(data[i].commentedTime);
            }
          }
          {
            if(data[i].author){
              convs_user_names.push(data[i] && data[i].author ? data[i].author.name : '');
            }
            if(!data[i].author) {
              convs_user_names.push(data[i] && data[i].commenter ? data[i].commenter.name : '');
            }
          }
          {
            conversations_list.push(
              <span>
                <span style={{ fontSize: "17px" }}>{sno[i]}. &nbsp; {convs_user_names[i]}&nbsp;&nbsp;({roles[i]}) &nbsp;- &nbsp;{moment(timeCreation[i]).format("DD MMM hh:mm a").toString()}</span><br></br>
                <span style={{ fontSize: "16px" }}>{<div dangerouslySetInnerHTML={{ __html: convs_desc[i] }} />}</span><br></br>
              </span>)
          }
        }
        setConversationListState(conversations_list)
        setConversationsData(conversationsData.data);
      }
      setRenderComponent(true);
    })();
  }, [reloadComponent]);



  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value,
    });
    if (event.target.id === "ticketid") {
      setTicketIdState("");
      setTicketIdError("");
    }
    if (event.target.id === "content") {
      setTicketCommentState("");
      setTicketCommentError("");
    }
  }



  const handleSubmit = function (e) { validateForm(); e.preventDefault() }

  const validateForm = async () => {


    setShowLoader(true);
    setShowSaveButton(false);
    let response = await ticketsService.createComment(formdetails);
    console.log(response.data);
    if (response.status && response.data && response.data.status != 404) {
      setTimeout(() => {
        setShowLoader(false)
        setShowSaveButton(true)
        setAlert({ show: true, type: 'success', message: 'Comment posted successfully' });
        setReloadComponent(Math.random)
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 3000);


    } else {
      setTimeout(() => {
        setShowLoader(false)
        setShowSaveButton(true)
        setAlert({ show: true, type: 'danger', message: 'Comment could not be posted, Please try again.' });
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 3000);
    }
  }
  const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status:event.value,
    })
    setStatusState("has-success");
    setStatusError("");
  }
  const getStatusValue = ()=>{
    let statusValue = "";
    if(formdetails.status){
      statusValue = statusArray.length ? statusArray[0] : "";
      statusArray.forEach(function(statusdetails){
        if(statusdetails.value == formdetails.status){
          statusValue = statusdetails;
        }
      })
    }
    return statusValue;
  }

  const updateStatus = async()=>{
    if(formdetails.status != ""){
        setShowUpdateButton(false);
        setStatusUpdateLoader(true);
        let statusUpdated = await ticketsService.updateStatus(formdetails);
        if(statusUpdated.status){
          setTimeout(()=>{
            setStatusUpdateLoader(false);
            setStatusUpdateAlert({
              show:true,type:"success",message:"Ticket Status was updated Successfully"
            },2000);
            setTimeout(()=>{
              setStatusUpdateAlert({
                show:false,type:"",message:""
              },3000);
            setShowUpdateButton(true);

            })
            setReloadComponent(Math.random)
          })
        }
      }
      else{
        setStatusState("has-danger");
        setStatusError("Please Select Status")
      }
      
  }

  console.log("FORM DETAILS", formdetails)


  return <>
    <div className="content">
      <Container>
        <Row>
          <Col className="card-signup text-center" md="12">
            <span style={{ fontSize: "26px", fontWeight: "bold", textDecoration: "underline" }}>VIEW TICKET</span>
          </Col>
        </Row>
      </Container>
      {!renderComponent ?
        <div className="text-center">
          <Spinner className="text-center" animation="border" role="status" style={{ marginTop: "100px" }}>
          </Spinner>
        </div>
        :
        <Container><br></br><br></br>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <Button
                        style={{ float: 'right', 'marginTop': '0px' }}
                        className="btn-round"
                        color="success"
                        size="sm"
                        type="button"
                        onClick={(event) => history.push("/zohodesk/dashboard")}
                      >Go Back</Button>
                    </Col>
                  </Row>
                  <CardTitle>
                    <span style={{ fontSize: "22px", fontWeight: "bold" }}>#{ticket ? ticket.ticketNumber : ''} &nbsp;
                      {ticket ? ticket.subject : ''}</span>
                  </CardTitle>
                  <CardTitle>
                    <span style={{ fontSize: "16px" }}>Created On: {moment(ticket.createdTime).format("DD MMM YYYY hh:mm a").toString()}</span>
                  </CardTitle>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col md="6">
                        By: 
                        {!(ticket && ticket.contact ? ticket.contact.firstName : '') ? (ticket && ticket.contact ? ticket.contact.lastName : '') : (ticket && ticket.contact ? ticket.contact.firstName + " " + ticket.contact.lastName : '')}<br />
                        ( {ticket && ticket.contact ? ticket.contact.email : ''} )
                        {/* <span style={{fontSize: "16px"}}>Assigned To: {!ticket.assigneeId ? (<span>Not Assigned</span>) : (!(ticket && ticket.assignee ? ticket.assignee.firstName:'')) ?  (ticket && ticket.assignee ? ticket.assignee.lastName:'') :  (ticket && ticket.assignee ?ticket.assignee.firstName + " "+ticket.assignee.lastName:'')}</span> */}
                    </Col>
                    <Col md="6">
                        Status: {ticket ? ticket.status : ''}<br />
                        Due-date: {!ticket.dueDate ? <span>Not specified</span> : moment(ticket.dueDate).format("DD MMM YYYY hh:mm a").toString()}<br />
                        Priority: {!ticket.priority ? (<span>Not specified</span>) : (ticket ? ticket.priority : '')}
                    </Col>
                    </Row>
                    <Form>
                            <Row>
                              <Col md="2">Update Status:</Col>
                              <Col md="4">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  placeholder="Select Status"
                                  name="type"
                                  value={getStatusValue()}
                                  onChange={changeStatus}
                                  options={statusArray}
                                />
                                {statusState === "has-danger" ? 
                                  <label className="error" style={{color:"red"}}>{statusError}</label>
                                :null}
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <div style={{'float':'left'}}>
                                {showUpdateButton ? 
                              <Button
                                className="btn-round"
                                color="info"
                                type="button"
                                onClick={updateStatus}
                              >Update</Button>
                              : ""
                            }
                            {statusUpdateAlert.show ? <Alert color={statusUpdateAlert.type}>
                              {statusUpdateAlert.message}
                            </Alert> : ""}
                            {statusUpdateLoader ? <Spinner animation="border" role="status">
                            </Spinner> : ""}
                                </div>
                              </Col>
                            </Row>
                    </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <a
                        style={{
                          float: 'right', marginTop: "0px", fontWeight: "bold", textDecoration: "none", fontSize: "16px"
                          , backgroundColor: "#6BD098", color: "white"
                        }}
                        className="btn-round"
                        href="#comments"
                      >COMMENT</a>
                    </Col>
                  </Row>
                  <CardTitle className="card-signup text-center">
                    <span style={{ fontSize: "20px", fontWeight: "bold", textDecoration: "underline" }}>
                      Conversations
                    </span><br></br>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {
                    conversationListState && conversationListState.length > 0 ? 
                    <CardTitle>
                      {conversationListState}
                    </CardTitle>
                  : 
                  <div className="text-center">
                  <Spinner className="text-center" animation="border" role="status" style={{ marginTop: "40px" }}>
                  </Spinner>
                </div>
                  }
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle className="card-signup" id="comments">
                    <span style={{ fontSize: "20px", fontWeight: "bold", textDecoration: "underline", marginLeft: "15px" }}>
                      Create a comment
                    </span><br></br>
                  </CardTitle>
                </CardHeader>
                <span style={{ marginLeft: "15px" }}>
                  <CardBody>
                    <Form className="form-horizontol" action="" id="createComment" method="POST" onSubmit={handleSubmit} autoComplete="off">
                      <Row>
                        <Col md="6">
                          <FormGroup className={ticketCommentState}>
                            <Input
                              type="textarea"
                              name="content"
                              id="content"
                              placeholder="Comments can be entered here"
                              onChange={handleChange}
                            />
                            {ticketCommentState === "has-danger" ? (
                              <label className="error">
                                {ticketCommentError}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      {showSaveButton ?
                        <>
                          <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                          >
                            Comment
                          </Button>
                        </>
                        : ''}
                      {alert.show ? <Alert color={alert.type}>
                        {alert.message}
                      </Alert> : ''}

                      {showLoader ? <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner> : ''}
                    </Form>
                  </CardBody>
                </span>
              </Card>
            </Col>
          </Row>
        </Container>
      }

    </div>
  </>

}

export default ZohoDeskViewTicket;
