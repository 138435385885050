import axios from "axios";

const getNotifications = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/notifications/get-all-notifications',formdata);
    return response.data;
}

const getTotalNotifications = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/notifications/get-total-notifications',formdata);
    return response.data;
}

export  default {
    getNotifications,
    getTotalNotifications
}