import axios from 'axios';

const getCTVAppGeneralSettings = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-general-settings', formData);
    return response.data;
}

const saveCTVAppGeneralSettings = async (formData) => {
    const response = await axios.post('/api/ctv-app/save-general-settings', formData);
    return response.data;
}

const getProjectDistribution = async () => {
    const response = await axios.post('/api/ctv-app/get-ctv-app-distribution');
    return response.data;
}
const getImporterLogsList = async (formdata) => {
    const response = await axios.post('/api/ctv-app/importer-logs', formdata);
    return response.data;
}
const getProjectImporters = async (projectId) => {
    const formdata = {
        project_id: projectId
    }
    const response = await axios.post('/api/ctv-app/get-importer', formdata);
    return response.data;
}

const getAppTemplates = async() =>{
    const response = await axios.get('/api/ctv-app/get-templates');
    return response.data;
}

const deactivateAppTemplate = async(templateId) =>{
    const response = await axios.post('/api/ctv-app/deactivate-app-template',{templateId})
    return response.data;
}

const publishAppTemplate = async(templateId) =>{
    const response = await axios.post('/api/ctv-app/publish-app-template',{templateId})
    return response.data;
}

const createAppTemplate = async(formdata) =>{
    const response=await axios.post('/api/ctv-app/create-app-template',formdata);
    return response.data;
}

const getAppConfigSchema = async() =>{
    const response = await axios.get('/api/ctv-app/app-config-schema');
    return response.data;
}

const getAppSavedConfig = async(templateId) =>{
    let data = {templateId:templateId}
    const response = await axios.post('/api/ctv-app/template-saved-config',data);
    return response.data;
}

const storeAppConfig = async(templateId,config_type,config) =>{
    let data = {templateId:templateId,groupName:config_type,formdata:config}
    const response = await axios.post('/api/ctv-app/store-template-config',data);
    return response.data;
}

const saveNewTemplateConfig = async(config_type,config,formdetails) => {
    let data = {groupName:config_type,formdata:{...config,formdetails}}
    const response = await axios.post('/api/ctv-app/save-template-config',data);
    return response.data;
}

export default {
    getCTVAppGeneralSettings,
    saveCTVAppGeneralSettings,
    getProjectDistribution,
    getImporterLogsList,
    getProjectImporters,
    getAppTemplates,
    deactivateAppTemplate,
    publishAppTemplate,
    createAppTemplate,
    getAppConfigSchema,
    getAppSavedConfig,
    storeAppConfig,
    saveNewTemplateConfig,
}