import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import subscriptionsService from "../../services/api/subscription-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from "../../services/api/saas-service";
import moment from "moment";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import ReactDatetime from "react-datetime";
const Subscriptions = (props)=> {
    const history = new useHistory();
  const [Subscriptions, setSubscriptions] = useState(false);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',subscription_id:'',from_date:'',to_date:'',project_id:'',status:"",verified_by :'', type:""});
  const [search, setSearchDetails] = useState({publisher_id:'',subscription_id:'',from_date:'',to_date:'',project_id:'',status:"",verified_by :'',  type:""});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const [planType,setPlanType] = useState('');
  const records = 10;
  const [planGroupOptions, setPlanGroupOption] = useState();

  const [groupName,setGroupName] = useState('');
  const [status,setStatus]=useState('');
  const [toDate,setToDate] = useState("");
  const [fromDate,setFromDate] = useState("");
  const [subscriptionId, setSubscriptionId] = useState(false);

  const [showPopup, setShowPopup] = useState({
    alert: null
  })

  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };

  const reconcileSubscription = async(subscriptionId) =>{

    hideAlert();
    console.log({
      subscriptionId
    })

    const response = await subscriptionsService.reconcileSubscription(subscriptionId);
    if(!response.status && response.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
    }
    else if(response.data){
      setShowPopup({
        alert: <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Completed"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        Reconcilation Completed
    </ReactBSAlert>,
      });
    }else{
      setShowPopup({
        alert: <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Failed"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        Failed to reconcile, Try again.
    </ReactBSAlert>
      });
    }

    setTimeout(()=>{
      hideAlert();
      setReloadComponent(Math.random());
    },2000)
    console.log({response})
  }


  const handleReconcile = (subscriptionId) => {
    setShowPopup({
      alert:(
        <ReactBSAlert
          warning
          // style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => reconcileSubscription(subscriptionId)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, reconcile it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You want to reconcile this subscription!
        </ReactBSAlert>
      )
    })
  }

  const verifiedByArray = [
        {
            value: "Webhook",
            label: "Webhook",
        },
        {
            value: "frontend",
            label: "Frontend",
        }
    ];

  const typeOptions = [
    {
      value: "1",
      label: "One time",
    },
    {
      value: "2",
      label: "Subscription",
    }
  ]

const SubscriptionstatusValue=[
{
    value: "created",
    label: "Created",
},
{
    value: "authenticated",
    label: "Authenticated",
},
{
  value: "activated",
  label: "Activated",
},
{
  value: "charged",
  label: "Charged",
},
{
  value: "completed",
  label: "Completed",
},
{
  value: "pending",
  label: "Pending",
},
{
  value: "halted",
  label: "Halted",
},
{
  value: "cancelled",
  label: "Cancelled",
},
{
  value: "paused",
  label: "Paused",
},
{
  value: "resumed",
  label: "Resumed",
}
];


  useEffect( () => {
    (async () =>{
        let subscriptionsData = await subscriptionsService.getSubscriptions(formdetails,activePage,records);
        if(subscriptionsData.status && subscriptionsData.data){
                setSubscriptions(subscriptionsData.data.subscriptions);
                setTotalSubscriptions(subscriptionsData.data.count);
        }  
        setRenderComponent(true);
      })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

 
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let subscriptionElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!Subscriptions || !Subscriptions.length){
    if(!renderComponent){
        subscriptionElements.push(
       <tr>
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        subscriptionElements.push(
          <tr>
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
     Subscriptions.forEach(function(subscriptionDetails) {
          let className = '';
          if(subscriptionDetails.status==-2){
            className = 'table-danger';
          }
          if(subscriptionDetails.status==2){
            className = 'table-success';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          subscriptionElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
                   {subscriptionDetails.publisher_id?subscriptionDetails.publisher_id.name:''} <br/>
               </td>
               <td className="text-left">
                   {subscriptionDetails.project_id?subscriptionDetails.project_id.name:''}
               </td>
                <td className="text-left">
                   {subscriptionDetails.pg_subscription_id?subscriptionDetails.pg_subscription_id:''}
               </td>
               <td className="text-left">
                   {subscriptionDetails.paid_count}
               </td>

               <td className="text-left">
                   {subscriptionDetails.status}
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{subscriptionDetails.createdAt}</Moment>
               </td>
            </tr>
        )
        if(i==Subscriptions.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==Subscriptions.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchSubscriptions();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const handleSubscriptionIdChange = (event) => {
    setFormDetails({
      ...formdetails,
      subscription_id: event.target.value,
    });
    setSubscriptionId(event.target.value);
  }
  
  const changePlanStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    setStatus(event)
  }
  const handleVerifiedByChange = (event) => {
    setFormDetails({
      ...formdetails,
      verified_by: event.value,
    });
  }
  

  const handleFromDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      from_date: event._d,
    });
    setFromDate(event._d);
  }

  const handleToDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      to_date: event._d,
    });
    setToDate(event._d);
  }

  const searchSubscriptions = () => {
    setActivePage(1);
    console.log(formdetails)
    setSearchDetails(formdetails);
  }

  const handleTypeChange = (event) =>{
    setFormDetails({
      ...formdetails,
      type: event.value
    });
    console.log(event.value)
  }

  const resetForm = () => {
    setPublisherId('');
    setProjectId('');
    setGroupName('');
    setStatus('');
    setPlanType('');
    setToDate("");
    setFromDate("");
    setSubscriptionId("");
    setPublisher("");
    document.getElementById('subscription_id').value = '';

    setFormDetails({
      ...formdetails,
      publisher_id: '',
      plan_id:'',
      project_id:'',
      plan_group_id: '',
      status:'',
      plan_type:'',
      subscription_id:'',
      from_date:'',
      to_date:'',
      verified_by : '',
      type: ""
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
    
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Subscriptions Dashboard
                </CardTitle>
              <Form id="subscriptionfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Project .."
                      name = "project_id"
                      id="project_id"
                      options={projectNameArray}
                      value={projectId}
                      onChange={changeProjectId}
                    />
                  </Col>

                  <Col md="3">
                  <ReactDatetime
                      value={fromDate}
                      onChange={handleFromDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                        id:"fromDate",
                      }
                    }
                    />
                  </Col>
                  <Col md="3">
                  <ReactDatetime
                      value={toDate}
                      onChange={handleToDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                        id:"toDate",
                      }}
                    />
                  </Col>
                  </Row>
                  <br/>
                  <Row>
                  <Col md="3">
                   <Input type="text" name="subscription_id" id="subscription_id" onChange={handleSubscriptionIdChange} placeholder="Subscription Id"/>
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={SubscriptionstatusValue}
                    value={status}
                    onChange={changePlanStatus}
                  />
                  </Col>
                  {/* <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Verified by .."
                      name = "verified_by"
                      id="verified_by"
                      options={verifiedByArray}
                      value={verifiedByArray.filter(option => option.value==formdetails.verified_by)}
                      onChange={handleVerifiedByChange}
                    />
                  </Col> */}
                  
                  {/* <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Subscriptions Type"
                      name = "type"
                      id="type"
                      options={typeOptions}
                      value={typeOptions.filter(option => option.value==formdetails.type)}
                      onChange={handleTypeChange}
                    />
                  </Col> */}
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form> 
                <hr></hr>
                {totalSubscriptions > 0 ? 
                  <Row>
                        
                        <Col md="6">
                          <div style={{float:'right',margin:'5px'}}>
                            <b>{firstRecord} - {lastRecord} of {totalSubscriptions}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{float:'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalSubscriptions}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                      </Row>
                : null}
                
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th>
                      {/* <th className="text-left">Subscription Amount <small className="text-default">
                                (in Rupees)
                            </small><span class="text-small"></span>
                      </th>  */}
                      <th className="text-left">Subscription Id</th>
                      <th className="text-left">Paid Count</th> 
                      <th className="text-left">Status</th>
                      <th className="text-left">Subscription Date</th> 
                      
                      
                      </tr>
                    </thead>
                    <tbody>{subscriptionElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Subscriptions;
