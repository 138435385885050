import axios from "axios";

const getProjectAlert = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/project-alert/get-project-alerts',formdata);
    return response.data;
}

const completeAlertProject = async(projectAlertId) => {
    const response = await axios.post('/api/project-alert/complete-alert-project', {projectAlertId});
    return response.data;
}

const getLoggedInUser = async() => {
    const response = await axios.post('/api/project-alert/get-user-details');
    return response.data;
}

export  default {
    getProjectAlert,
    completeAlertProject,
    getLoggedInUser
}