import React, { useEffect, useState } from "react";
import ConfigFormComponent from "./ConfigFormComponent";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";



const ConfigForm = (props)=> {

    var formElements = [];
    var formStructure = props.data.items;
    var fieldStatesData = {};
//console.log(props);
    Object.keys(formStructure).forEach(function(key) {
        if(formStructure[key].items){
            formElements.push(
            <>
            <CardTitle tag="h4" className="subHeading_h4">
                {formStructure[key].label}
            </CardTitle>
            {formStructure[key].helptext ? (
                    <label className="text-default"> {formStructure[key].helptext}</label>
                ) : (
                    ""
                )}
            </>
            );
            var subheads = formStructure[key].items;
            Object.keys(subheads).forEach(function(subheadkey) {
                formElements.push(<ConfigFormComponent projectId={props.projectId} fieldStates={props.fieldStates} setFieldStates={props.setFieldStates} validateConfigField={props.validateConfigField} categories={props.categories} setCurrentTabConfig={props.setCurrentTabConfig} currentTabConfig={props.currentTabConfig}  grandparent={props.subparent} subparent={props.parent} parent={key} data={subheads[subheadkey]} elementname={subheadkey}  />);
                let elementId = subheadkey;
                if(props.subparent!=''){
                    elementId = props.parent+":"+key+":"+subheadkey;
                }else if(key != ''){
                    elementId = key+":"+props.elementname;
                }
            })
        }else{
            formElements.push(<ConfigFormComponent projectId={props.projectId} fieldStates={props.fieldStates} setFieldStates={props.setFieldStates} validateConfigField={props.validateConfigField} categories={props.categories} setCurrentTabConfig={props.setCurrentTabConfig} currentTabConfig={props.currentTabConfig}  grandparent='' subparent={props.subparent} parent={props.parent} data={formStructure[key]} elementname={key} />);
            // let elementId = key;
            // if(props.subparent!=''){
            //     elementId = props.parent+":"+key;
            // }
            // let elementErrorClass = "error_"+elementId;
            // let elementErrorMessageClass =  "error_message_"+elementId;
            // fieldStatesData[elementErrorClass] = 'has-success';
            // fieldStatesData[elementErrorMessageClass] = '';
        }
    })

    return (
        <>
            {formElements}
        </>
    );
  
}

export default ConfigForm;
