import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import contractService from "../../services/api/contract-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from "../../services/api/saas-service";
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
const Contracts = (props)=> {
    const history = new useHistory();
  const [contracts, setContracts] = useState(false);
  const [totalContracts, setTotalContracts] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',plan_type:'',project_id:'',plan_group_id:"",status:""});
  const [search, setSearchDetails] = useState({publisher_id:'',plan_type:'',project_id:'',plan_group_id:"",status:"",});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const [planType,setPlanType] = useState('');
  const records = 10;
  const [planGroupOptions, setPlanGroupOption] = useState();

  const [groupName,setGroupName] = useState('');
  const [status,setStatus]=useState('');
  const planTypeArray = [
    {
        value: "trial",
        label: "Trial",
    },
    {
        value: "monthly",
        label: "Monthly",
    },
    {
        value: "yearly",
        label: "Yearly",
    },
];

  useEffect(() => {
    (async() => {
        let tempOptions=[];
        let groupsData = await saasService.getPlanGroups();
        if(!groupsData.status && groupsData.error.errorCode === 403){
         // context.setIsAuthorized(context);
        }
        // console.log("Groups Data",groupsData);
        else if (groupsData.status && groupsData.data) {
            //    setPlanGroups(groupsData.data);
           //console.log(groupsData.data);

            groupsData.data.forEach((data) => {
                tempOptions.push({
                    value: data.id,
                    label: data.name,
                });
            });
        }
        setPlanGroupOption(tempOptions);
    })()
},[]);



  useEffect( () => {
    (async () =>{
        let getTotalContracts = await contractService.getTotalContracts(formdetails);
            if(getTotalContracts.status){
                setTotalContracts(getTotalContracts.data.count);
            }
        })();
    }, [reloadComponent,search]);

  useEffect( () => {
    (async () =>{
        let contractsData = await contractService.getContracts(formdetails,activePage,records);

        if(contractsData.status){
                setContracts(contractsData.data);
            }
          
            setRenderComponent(true);
        })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

  const suspendContractPopup = (contractId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => suspendContract(contractId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Suspend it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to suspend this contract!
          </ReactBSAlert>
        ),
      });
    }

    const restoreContractPopup = (contractId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreContract(contractId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this contract again!
          </ReactBSAlert>
        ),
      });
    }

    const suspendContract = async (contractId) => {
      let susContract = await contractService.suspendContract(contractId);
      if(!susContract.status && susContract.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      }
      else if(susContract.status && susContract.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Contract was suspended successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Contract could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreContract = async (contractId) => {
      let resContract = await contractService.restoreContract(contractId);
      if(!resContract.status && resContract.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      }
      else if(resContract.status && resContract.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Contract was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Contract could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let contractElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!contracts || !contracts.length){
    if(!renderComponent){
        contractElements.push(
       <tr>
        <td colSpan="10" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        contractElements.push(
          <tr>
        <td colSpan="10" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      contracts.forEach(function(contractDetails) {
        console.log("Contract Details",contractDetails);
          let className = '';
          if(!contractDetails.status){
            className = 'table-warning';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          contractElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
                   {contractDetails.publisher_id?contractDetails.publisher_id.name:''} <br/>
               </td>
               <td className="text-left">
                   {contractDetails.project_id?contractDetails.project_id.name:''}
               </td>
               <td className="text-left">
                   {contractDetails.plan_id?contractDetails.plan_id.plan_group_id.name:""} - {contractDetails.plan_id?contractDetails.plan_id.type:""}
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{contractDetails.start_date}</Moment>
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{contractDetails.expire_date}</Moment>
               </td>
               
               <td className="text-left">
                   {contractDetails.status==1?'Active':
                   contractDetails.status==0?'Grace':
                   contractDetails.status==-2?'Expired':
                   'Suspended'}
               </td>
               <td className="text-left">
                   {contractDetails.created_by}
               </td>
               <td className="text-left">
                   {contractDetails.id?
                   
                   <Link
                   to={"/transaction/transaction-details?contract_id="+contractDetails.id}
                   >
                    view
                   </Link>
                   :""}
               </td>
               
               <td className="text-left">
               {contractDetails.status==1?
               <span>
               
               <a title="Suspend" onClick={(event)=>  suspendContractPopup(contractDetails.id) } href="javascript:void(0)" class="nav-link">
               {/* <i class="nc-icon nc-simple-remove"></i> */}
               <FontAwesomeIcon icon={faTrash} />
             </a>
             </span>
             :''}
             {contractDetails.status==-1 || contractDetails.status == -2?
             <a title="Restore" onClick={(event)=>  restoreContractPopup(contractDetails.id) } href="javascript:void(0)" class="nav-link">
                 {/* <i class="nc-icon nc-refresh-69"></i> */}
                 <FontAwesomeIcon icon={faTrashRestoreAlt} />
               </a> 
              :'' }
               </td>
            </tr>
        )
        if(i==contracts.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==contracts.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchContracts();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }

  
  const changePlanStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    setStatus(event)
  }
  const changeGroupName = (event) => {
    setFormDetails({
      ...formdetails,
      plan_group_id: event.value,
      plan_id: event.value,
    });
    setGroupName(event)
  }

  const changePlanType = (event) => {
    setFormDetails({
      ...formdetails,
      plan_type: event.value,
    });
    setPlanType(event)
  } 

  const searchContracts = () => {
    setActivePage(1);
    // console.log("Search Contract",formdetails);

    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    
    setPublisherId('');
    setProjectId('');
    setGroupName('');
    setStatus('');
    setPlanType('');
    setPublisher('');
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      plan_id:'',
      project_id:'',
      plan_group_id: '',
      status:'',
      plan_type:'',
    });
    // setProjectNames([]);
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                   Contracts Dashboard
                </CardTitle>
                <Row>
                  <Col md="6">
                    <div style={{'float':'left'}}>
                <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/contract/invitations") }
                  >Enterprise Plan Invitations
                </Button>
                </div>
                </Col>
                <Col md="6">
                <div style={{'float':'right'}}>
                <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/contract/create") }
                  >Create Complimentry Contract
                </Button>
                </div>
                </Col>
                </Row>
              <Form id="contractfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md="2">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>

                   {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan .."
                    nconst [type,setType]=useState('');ame = "plan_id"
                    id="plan_id"
                    options={planNameArray}
                    value={planId}
                    onChange={changePlanId}
                  />
                  </Col> */}
                  <Col md="2">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Group.."
                    name = "plan_group_id"
                    id="plan_group_id"
                    options={planGroupOptions}
                    value={groupName}
                    onChange={changeGroupName}
                  />

                  </Col>
                  <Col md="2">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Type .."
                    name = "type"
                    id="type"
                    options={planTypeArray}
                    value={planType}
                    onChange={changePlanType}
                  />
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={[{value:"1",label:"Active"},{value:"0",label:"Grace"},{value:"-2",label:"Expired"},{value:"-1",label:"Suspended"}]}
                    value={status}
                    onChange={changePlanStatus}
                  />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>                
                <hr></hr>
                {totalContracts > 0 ? 
                  <Row>
                        <Col md="6">
                          <div style={{'float':'right'}}>
                            <b>{firstRecord} - {lastRecord} of {totalContracts}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{'float':'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalContracts}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                      </Row>

                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th> 
                      <th className="text-left">Plan</th> 
                      <th className="text-left">Start Date</th> 
                      <th className="text-left">Expire Date</th> 
                      <th className="text-left">Status</th>  
                      <th className="text-left">Created By</th>
                      <th className="text-left">Transaction</th>
                      
                      <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{contractElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Contracts;
