import React, { useEffect, useState, useContext } from "react";
import ticketsService from "../../services/api/zoho-desk-service";
import Moment from 'react-moment';
import Pagination from "react-js-pagination";


// reactstrap components
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import ReactDatetime from "react-datetime";
import { filter } from "lodash";
const moment = require('moment');


const ZohoDeskTickets = (props) => {
  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({ priority: '', status_value: 'Open' });
  const [search, setSearchDetails] = useState({ priority: '', status_value: 'Open' });
  const [ticket, setTicketData] = useState([]);
  const [filterticket, setFilterTicketData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [contactState, setContactState] = useState("");
  const [contactError, setContactError] = useState("");
  const [priorityState, setPriorityState] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [statusState, setStatusState] = useState("");
  const [statusError, setStatusError] = useState("");
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const [totalTicket, setTotalTickets] = useState(0);
  const [lowPriorityTicket, setLowPriorityTickets] = useState(0);
  const [mediumPriorityTicket, setMediumPriorityTickets] = useState(0);
  const [highPriorityTicket, setHighPriorityTickets] = useState(0);
  const [openStatusTicket, setOpenStatusTickets] = useState(0);
  const [closedStatusTicket, setClosedStatusTickets] = useState(0);
  const [formSubmit, setFormSubmit] = useState(false);
  const records = 10;

  const priorityArray = [
    {
      value: "High",
      label: "High",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Low",
      label: "Low",
    }
  ];
  const statusArray = [
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Closed",
      label: "Closed",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Escalated",
      label: "Escalated",
    }
  ]

  // useEffect(() => {
  //   (async () => {

  //     let count = await ticketsService.getTicketCount();
  //     if (count.status && count.data) {
  //       console.log("RESPONSE", count.data)
  //       setTotalTickets(count.data)
  //     }

  //     let response = await ticketsService.getTickets(activePage, records);
  //     if (response.status && response.data && response.data.errorCode != '2001') {
  //       setTicketData(response.data);
  //       console.log("LENGTH", response.data.length)
  //       if (response && response.status && count.data < 10) {
  //         console.log("FIRST")
  //         setFirstRecord((activePage - 1) * records + 1);
  //         setLastRecord((activePage - 1) * records + count.data);
  //       } else {
  //         if (response && response.status && response.data.length === records) {
  //           console.log("SECOND")
  //           setFirstRecord((activePage - 1) * records + 1);
  //           setLastRecord((activePage - 1) * records + records);
  //         }
  //         else {
  //           console.log("THIRD")
  //           setFirstRecord((activePage - 1) * records + 1);
  //           setLastRecord((activePage - 1) * records + response.data.length);
  //         }
  //       }
  //     }
  //     else (alert("Invalid OAuth token. Please Reload"));

  //     setRenderComponent(true);

  //   })()
  // }, [reloadComponent,search]);


  useEffect(() => {
    (async () => {

      let filterData = await ticketsService.searchTicket(formdetails, activePage, records);
      console.log("FILTER-TICKET DATA", filterData.data);
      {
        if (filterData.status && filterData.data.data) {
          setTicketData(filterData.data.data);
          setTotalTickets(parseInt(filterData.data.count))
          console.log("FILTER DATA LENGTH", filterData.data.count);
          if (filterData && filterData.status && filterData.data.count < records) {
            console.log("FIRST CONDITION")
            setFirstRecord((activePage - 1) * records + 1);
            setLastRecord((activePage - 1) * records + filterData.data.count);
          } else {
            if (filterData && filterData.status && filterData.data.data.length === records) {
              console.log("SECOND CONDITION")
              setFirstRecord((activePage - 1) * records + 1);
              setLastRecord((activePage - 1) * records + records);
            }
            else {
              console.log("THIRD CONDITION")
              setFirstRecord((activePage - 1) * records + 1);
              setLastRecord((activePage - 1) * records + filterData.data.data.length);
            }
          }
        }
        else {
          console.log("NOT FILTER DATA CONDITION");
          setTicketData([]);
          setTotalTickets(0);
          setFirstRecord(0);
          setLastRecord(0);
          //setReloadComponent(Math.random());

        }
      } 
      setRenderComponent(true);
      setFormSubmit(false);

    })();
  }, [reloadComponent, search, formSubmit, activePage]);


  const handlePageChange = (pageNumber) => {
    console.log("PAGE CHANGE");
    setActivePage(pageNumber);
    //setReloadComponent(Math.random());
  }


  const changePriority = (event) => {
    setFormDetails({
      ...formdetails,
      priority: event.value,
    });
    setPriorityState("has-sucess");
    setPriorityError("");
  };

  const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status_value: event.value,
    });
    setStatusState("has-sucess");
    setStatusError("");
  };


  const handleSubmit = function (e) { FilterForm(); e.preventDefault() }

  const FilterForm = async () => {
    setFormSubmit(true);
  }
  const resetElements = () => {
    //setActivePage(1);
    setFormDetails({
      ...formdetails,
      priority: '',
      status_value: 'Open'
    });
    setSearchDetails({ priority: '', status_value: 'Open' });
    //setReloadComponent(Math.random());
  }




  const getPriorityValue = () => {
    let priorityValue = "";
    if (formdetails.priority) {
      priorityValue = priorityArray.length ? priorityArray[0] : "";

      priorityArray.forEach(function (prioritydetails) {
        if (prioritydetails.value == formdetails.priority) {
          priorityValue = prioritydetails;
        }
      });
    }
    return priorityValue;
  };

  const getStatusValue = () => {
    let statusValue = "";
    if (formdetails.status_value) {
      statusValue = statusArray.length ? statusArray[0] : "";

      statusArray.forEach(function (statusdetails) {
        if (statusdetails.value == formdetails.status_value) {
          statusValue = statusdetails;
        }
      });
    }
    return statusValue;
  };

  console.log("FORM-DETAILS", formdetails)

  console.log("ACTIVE PAGE", activePage);
  console.log("FIRST RECORD", firstRecord)
  console.log("LAST RECORD", lastRecord)
  console.log("TOTAL TICKET", totalTicket)





  return <>
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
              <Row>
                  <Col md="12">
                    <div style={{'float':'right'}}>
                    <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event) => history.push("/zohodesk/createTicket")}
                    >Create Ticket</Button>
                    </div>
                  </Col>
                </Row>
                <CardTitle tag="h4">
                  Zoho Desk Tickets Dashboard
                </CardTitle>
                <Form id="ticketfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                  <Row>
                    {/* <Col>
                  <Select 
                  className="react-select primary"
                  classNamePrefix="react-select"
                  placeholder="Select Publisher .."
                  name="contactId"
                  id="contactId"
                  value={getContactValue()}
                  onChange={changeContact}
                  options={contacts}
                  />
                  {contactState === "has-danger" ? (
                    <label className="error">
                      {contactError}
                    </label>
                    ) : null}
                      </Col> */}
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Status .."
                        name="status"
                        id="status"
                        value={getStatusValue()}
                        onChange={changeStatus}
                        options={statusArray}
                      />
                      {statusState === "has-danger" ? (
                        <label className="error">
                          {statusError}
                        </label>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Priority .."
                        name="priority"
                        id="priority"
                        value={getPriorityValue()}
                        onChange={changePriority}
                        options={priorityArray}
                      />
                      {priorityState === "has-danger" ? (
                        <label className="error">
                          {priorityError}
                        </label>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <div style={{'float':'left'}}>
                      <Button
                        className="btn-round"
                        color="info"
                        type="submit"

                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={resetElements}
                      >
                        Reset
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <hr></hr>
                {totalTicket > 0 ? 
                  <Row>
                    <Col md="6">
                    <div style={{'float':'right'}}>
                    <b>{firstRecord} - {lastRecord} of {totalTicket}</b>
                    </div>
                    </Col>
                    <Col md="6">
                    <div style={{'float':'left'}}>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalTicket}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    </Col>
                  </Row>
                : null}
              </CardHeader>
              <CardBody>
                {<Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">Ticket-Number</th>
                      <th className="text-left">Subject</th>
                      <th className="text-left">User</th>
                      <th className="text-left">Ticket Raising Time</th>
                      <th className="text-left">Ticket Last Modified Time</th>
                      <th className="text-left">Status</th>
                      <th className="text-left">Priority</th>
                      <th className="text-left">Thread Count</th>
                      <th className="text-left">View</th>
                      <th className="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!renderComponent ?
                      <tr>
                        <td colSpan="10">
                          <span style={{ fontSize: "24px" }}>Loading...</span>
                        </td>
                      </tr>
                      : null}

                    {renderComponent && ticket.length === 0 ?
                      <tr>
                        <td colSpan="10">
                          <span style={{ fontSize: "24px" }}>No Ticket Found...</span>
                        </td>
                      </tr>
                      : null}


                    {ticket.map((details) =>
                      <tr>
                        <td>#{details.ticketNumber}</td>
                        <td>{details.subject}</td>
                        <td>
                          {!details.contact.firstName ? (details.contact.lastName) : (details.contact.firstName + " " + details.contact.lastName)}
                        </td>
                        <td>{moment(details.createdTime).format("DD MMM YYYY hh:mm a").toString()}</td>
                        <td>{moment(details.modifiedTime).format("DD MMM YYYY hh:mm a").toString()}</td>

                        <td>{details.status}</td>
                        <td>{details.priority}</td>
                        <td>{details.threadCount}</td>
                        <td>
                          {
                            <Button
                              style={{ float: 'right', 'margin-top': '10px' }}
                              className="btn-round"
                              color="info"
                              size="sm"
                              type="button"
                              onClick={(event) => history.push("/zohodesk/viewTicket?ticketId=" + details.id)}
                            >View</Button>
                          }
                        </td>
                        <td>
                          {
                            <Button
                              style={{ float: 'right', 'margin-top': '10px' }}
                              className="btn-round"
                              color="info"
                              size="sm"
                              type="button"
                              onClick={(event) => history.push("/zohodesk/editTicket?ticketId=" + details.id)}
                            >Edit</Button>
                          }
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>}
              </CardBody>
              <CardFooter>
                <hr></hr>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  </>



}
export default ZohoDeskTickets;

