import React, {useState,useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";
import paymentLinkdService from "../../services/api/payment-links-service";
import projectsService from "../../services/api/projects-service";
import saasService from "../../services/api/saas-service";
import ReactDatetime from "react-datetime";
import formValidations from "../../variables/form-validations";
import{
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    Container,
    Row,
    Col
} from "reactstrap";

import Select from "react-select";
import moment from "react-moment";
import Moment from "react-moment";
const CreatePaymentLinks = (props) => {
    const history = new useHistory();
    const [formdetails, setFormDetails] = useState({ publisherId: "", projectId: "", publisherEmail:"", publisherContactNumber:"",publisherName:"",planId:"", amount:"", expireDate:"" });
    const [publishersArray, setPublisherNames] = useState([]);
    const [publisherId, setPublisherId] = useState('');
    const [alert,setAlert] = useState({ show: false, message: "", type: "" })
    const [publisher, setPublisher] = useState('');
    const [projectsArray, setProjectNames] = useState();
    const [publisherNameState, setPublisherNameState] = useState("");
    const [publisherNameError, setPublisherNameError] = useState("");
    const [projectNameState, setProjectNameState] = useState("");
    const [projectNameError,setProjectNameError] = useState("");
    const [dateState, setDateState] = useState("");
    const [dateError,setDateError] = useState("");
    const [planFieldValue, setPlanFieldValue] = useState('');
    const [allPlans,setAllPlans] = useState([]);
    const [planState, setPlanState] = useState('');
    const [planError, setPlanError] = useState('');
    const [contactNumberState, setContactNumberState] = useState("");
    const [contactNumberError, setContactNumberError] = useState("");
    const [amountState, setAmountState] = useState("");
    const [amountError, setAmountError] = useState("");
    const [projectId, setProjectId] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);

    useEffect(() => {
        (async () => {
            let publishers = await projectsService.getAllPublishers();
            if (publishers.status) {
                let getPublishers = [];
                let publisherDetails = publishers.data;
                if (publisherDetails.length) {
                    publisherDetails.forEach(function (publisherData) {
                        let publisherValue = { value: publisherData.id, label: publisherData.name + "(" + publisherData.email + ")", email: publisherData.email,name:publisherData.name };
                        getPublishers.push(publisherValue);
                    })
                    setPublisherNames(getPublishers);
                }
            }
        })();
    }, []);

    useEffect(()=>{
        (async()=>{
            let plansList = await saasService.getPlans({is_published:1,status:1});
            if(plansList.status && plansList.data){
                let plans = [];
                plansList.data.planList.forEach(planDetails=>{
                    let planValue = {value:planDetails.id,label:planDetails.name?planDetails.name : ""+"-"+planDetails.plan_group_id.name+" - "+planDetails.type};
                    plans.push(planValue);
                });
                setAllPlans(plans);
            }
        })();
    },[])

    useEffect(() => {
        (async () => {
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if (projects.status) {
                let projectDetails = projects.data;
                if (projectDetails.length) {
                    projectDetails.forEach(function (projectData) {
                        let projectValue = { value: projectData.id, label: projectData.name };
                        publisherProjects.push(projectValue);
                    })
                }
                setProjectNames(publisherProjects);
            }
        })();
    }, [publisher]);

    const validatePublisherEmail = ()=>{
        if(formdetails.publisherId === "" && formdetails.publisherEmail === ""){
            setPublisherNameState("has-danger");
            setPublisherNameError("Publisher Name and Email are required")
            return false;
        }
        setPublisherNameState("has-success");
        return true;
    }

    const validateProjectName = ()=>{
        if(formdetails.projectId === ""){
            setProjectNameState("has-danger");
            setProjectNameError("Project Name is required");
            return false;
        }
        setProjectNameState("has-sucsess");
        return true;
    }
    const validatePlanId = ()=>{
        if(formdetails.planId === ""){
            setPlanState("has-danger");
            setPlanError("Plan Name is required");
            return false;
        }
        setPlanState("has-success");
        return true;
    }
    const validateContactNumber = ()=>{
        if(formdetails.publisherContactNumber === "" || !formValidations.verifyPhoneNumber(formdetails.publisherContactNumber)){
            setContactNumberState("has-danger");
            setContactNumberError("Contact Number is required");
            return false;
        }
        setContactNumberState("has-success");
        return true;
    }

    const validateAmount = ()=>{
        if(formdetails.amount === ""){
            setAmountState("has-danger");
            setAmountError("Amount is required");
            return false;
        }
        if(formdetails.amount <= 0){
            setAmountState("has-danger");
            setAmountError("Amount cannot be negative");
            return false;
        }
        setAmountState("has-success");
        return true;
    }
    const validateDate = ()=>{
        if(formdetails.expireDate === ""){
            return true;
        }
        let date = new Date().toLocaleString();
        date = date.split(",")[0];
        console.log("current date: ",date);
        let expireDate = formdetails.expireDate;
        console.log("expire date: ",expireDate.getDate());
        console.log("expireDate month: ",expireDate.getMonth()+1);
        console.log("expireDate year: ",expireDate.getFullYear());
        console.log("expire date time: ",expireDate.getTime());
        let expDate = ('0'+expireDate.getDate()).slice(-2) + "/"+ ('0'+(expireDate.getMonth()+1)).slice(-2) + "/" + expireDate.getFullYear();
        console.log("exp date: ",expDate);
        let expDateTime = expireDate.getTime();
        let currentTime = new Date().getTime();
        console.log("current Time: ",currentTime)
        console.log("expDateTime-currentTime: ",expDateTime-currentTime)
        if(expDate < date && expDate !== ""){
            setDateState("has-danger");
            setDateError("Please Select Appropriate Date of Expiration");
            return false;
        }
        else if((expDateTime-currentTime)<=900000){
            setDateState("has-danger");
            setDateError("Link Expire time should be 15 minutes more than current time");
            return false;
        }
        setDateState("has-success");
        return true;
    }

    const validateForm = async()=>{
        setFormDetails({...formdetails});
        let publisherEmailValidation = validatePublisherEmail();
        let projectNameValidation = validateProjectName();
        let planIdValidation = validatePlanId();
        let contactNumberValidation = validateContactNumber();
        let amountValidation = validateAmount();
        let dateValidation = validateDate();
        if(publisherEmailValidation && projectNameValidation && contactNumberValidation && amountValidation && planIdValidation && dateValidation){
            setShowLoader(true);
            setShowSaveButton(false);
            let paymentLinkDetails= await paymentLinkdService.createPaymentLinks({...formdetails});
            // setDisabled(true);
            if(paymentLinkDetails.status){
                setTimeout(()=>{
                    setShowLoader(false);
                    setAlert({
                        show:true,
                        type:"success",
                        message:"Payment Link is created Successfully",
                    },1000);
                    setTimeout(()=>{
                        setAlert({
                            show:false,type:"",message:""
                        })
                    },3000);
                    setTimeout(()=>{
                        history.push("/contract/payment-links");
                    },2000);
                })
            }
            else{
                setTimeout(()=>{
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show:true,
                        type:"danger",
                        message:"Payment Links could not be created,Please Try again",
                    })
                    // setDisabled(false);
                },1000);
                setTimeout(()=>{
                    setAlert({show:true,type:"",message:""});
                },3000);
            }
        }
    }
    const handleSubmit = function(e){
        validateForm();
        e.preventDefault();
    }

    const changePublisher = (event)=>{
        setFormDetails({
            ...formdetails,
            publisherId:event.value,
            publisherEmail:event.email,
            publisherName : event.name,
            projectId:""
        });
        setProjectId('');
        setPublisherId(event);
        setPublisher(event.value);
    }

    const changePlan = (event) => {
        console.log("event: ",event);
        setFormDetails({
            ...formdetails,
            planId:event.value,
        });
        setPlanFieldValue(event);
        setPlanState("");
        setPlanError("");
    }
    const changeProject = (event)=>{
        setFormDetails({
            ...formdetails,
            projectId: event.value,
        });
        setProjectId(event);
    }

    const changeContactNumber = (event)=>{
        setFormDetails({
            ...formdetails,
            publisherContactNumber: event.target.value
        })
    }
    const changeAmount = (event)=>{
        setFormDetails({
            ...formdetails,
            amount: parseInt(event.target.value),
        })
    }

    const changeDate = (event)=>{
        console.log("event: ",event)
        setFormDetails({
            ...formdetails,
            expireDate: event._d,
        })
    }

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Create New Payment Link
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol"
                                    action=""
                                    id=""
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Label sm="2">Publisher <span className="text-danger">*</span></Label>
                                        <Col sm="7">
                                            <FormGroup className={publisherNameState}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeHolder="Select Publisher"
                                                    name="Publisher"
                                                    id="publisher"
                                                    options={publishersArray}
                                                    value={publisherId}
                                                    onChange={changePublisher}
                                                />
                                                {publisherNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {publisherNameError}
                                                    </label>
                                                ):null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Project<span className="text-danger">*</span></Label>
                                        <Col sm="7">
                                            <FormGroup className={projectNameState}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeHolder="Select Project"
                                                    name="Project"
                                                    id="project"
                                                    style={{'height':"40px"}}
                                                    options={projectsArray}
                                                    value={projectId}
                                                    onChange={changeProject}
                                                />
                                                {projectNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {projectNameError}
                                                    </label>
                                                ):null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Select Plan<span className="text-danger">*</span></Label>
                                        <Col sm="7">
                                            <FormGroup className={planState}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeHolder="Select Plan"
                                                    name="Plan"
                                                    id="plan"
                                                    style={{'height':"40px"}}
                                                    options={allPlans}
                                                    value={planFieldValue}
                                                    onChange={changePlan}
                                                />
                                                {planState === "has-danger" ? (
                                                    <label className="error">
                                                        {planError}
                                                    </label>
                                                ):null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Contact Number<span className="text-danger">*</span></Label>
                                        <Col sm="7">
                                            <FormGroup className={contactNumberState}>
                                                <Input name="contact" type="tel" id="tel" className="tel" onChange={changeContactNumber}/>
                                                {contactNumberState === "has-danger" ? 
                                                    (<label className="error">{contactNumberError}</label>)
                                                :null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Amount<span className="text-danger">*</span></Label>
                                        <Col sm="7">
                                            <FormGroup className={amountState}>
                                                <Input name="amount" id="number" className="number" type="number" onChange={changeAmount}/>
                                                {amountState === "has-danger" ? (
                                                    <label className="error" >{amountError}</label>
                                                ): null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Link Expire Date</Label>
                                        <Col sm="7">
                                            <FormGroup className={dateState}>
                                                <ReactDatetime
                                                    value={formdetails.expireDate}
                                                    onChange={changeDate}
                                                    inputProps={{
                                                        className: "form-control",
                                                        placeholder: "Expire date",
                                                        id: "expireDate",
                                                        input:"false",
                                                        autoComplete:"off",
                                                    }}
                                                    dateFormat={"DD/MM/YYYY"}
                                                    closeOnSelect
                                                />
                                                {/* <Input type="date" name="date" id="date" className="date" onChange={changeDate}/> */}
                                                {dateState === "has-danger" ? (
                                                    <label className="error">{dateError}</label>
                                                ): null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {showSaveButton ? (
                                            <div>
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    onClick={validateForm}
                                                >Create</Button>
                                                <Button
                                                    className="btn-round"
                                                    color="danger"
                                                    type="button"
                                                    onClick={(event) => {
                                                        history.push('/contract/payment-links');
                                                    }}
                                                >Cancel</Button>
                                            </div>
                                        ):("")}
                                        {alert.show ? (
                                            <Alert color={alert.type}>{alert.message}</Alert>
                                        ):("")}
                                        {showLoader ? (
                                            <Spinner animation="border" role="status"></Spinner>
                                        ):("")}
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreatePaymentLinks;