import React, { useEffect, useState , useContext} from "react";
import formValidations from "../../variables/form-validations";
import projectsService from "../../services/api/projects-service";
import TimezoneSelect, { i18nTimezones } from 'react-timezone-select';
import {useHistory} from "react-router-dom";
import UserContext from "../../UserContext";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const CreateProject = (props)=> {
  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({publisherid:'',email:'',projectname:"",slug:"",about:"",projecturl:"",locale:"en",timezone:"Asia/Kolkata"});
  const [projectnamestate, setProjectNameState] = useState("");
  const [projectnameerror, setProjectNameError] = useState("");
  const [publisherstate, setPublisherState] = useState("");
  const [publishererror, setPublisherError] = useState("");
  const [aboutprojectstate, setAboutProjectState] = useState("");
  const [aboutprojecterror, setAboutProjectError] = useState("");
  const [projecturlstate, setProjectUrlState] = useState("");
  const [projecturlerror, setProjectUrlError] = useState("");
  const [publisher, setPublisher] = useState('');
  const [publisherFieldValue, setPublisherFieldValue] = useState('');
  const [projectlocalestate, setProjectLocaleState] = useState("");
  const [projectlocaleerror, setProjetLocaleError] = useState("");
  const [projecttimezonestate, setProjectTimezoneState] = useState("");
  const [projecttimezoneerror, setProjectTimezoneError] = useState("");
  const [existingProjects, setExistingProjects] = useState(false);
  const [allPublishers, setAllPublishers] = useState([]);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [selectedTimezone,setSelectedTimezone] = useState({value: "Asia/Kolkata", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi"});
  const [locale,setLocale] = useState({value: "en", label: "English"});
  const context = useContext(UserContext);
  useEffect( () => {
    (async () =>{
        if(publisher){
            let projects = await projectsService.getPublisherProjects(publisher);
            if(projects.status){
                setExistingProjects(projects.data);
            }
        }
    })();
  }, [publisher]);

  useEffect( () => {
    (async () =>{
        let publishers = await projectsService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
        }
    })();
  }, []);
  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  const changeTimeZone = (event) => {
    setFormDetails({
      ...formdetails,
      timezone: event.value,
    });
    setSelectedTimezone(event)
  }

  const changeLocale = (event) => {
    setFormDetails({
      ...formdetails,
      locale: event.value,
    });
    setLocale(event)
  }

  const changePublisher = (event) => {
    setFormDetails({
      ...formdetails,
      publisherid: event.value,
      email: event.email,
    });
    setPublisher(event.value);
    setPublisherFieldValue(event);
  }

  const handleBlur = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value,
    });

    if(event.target.id=='projectname'){
      validateProjectName();
    }

    if(event.target.id=='projecturl'){
      validateProjectUrl();
    }

  }

  const validateProjectName = () => {
      if(formdetails.projectname===''){
        setProjectNameState("has-danger");
        setProjectNameError("This field is required");
        return false;
      }

      if(formdetails.projectname.length >=50){
        setProjectNameState("has-danger");
        setProjectNameError("Project Name should have max 50 characters");
        return false;
      }
      if(formdetails.projectname.length <=5){
        setProjectNameState("has-danger");
        setProjectNameError("Project Name should have min 5 characters");
        return false;
      }
      
      if(existingProjects){
        let duplicateName = false;
        Object.keys(existingProjects).forEach(function(key) {
            let existingProjectName = existingProjects[key].name;
            if(formdetails.projectname===existingProjectName){
              duplicateName = true;
            }
        })
        if(duplicateName){
          setProjectNameState("has-danger");
          setProjectNameError("You have already created this project.");
          return false;
        }
      }

      setProjectNameState("has-success");
      return true;
  }

  const validatePublisher = () => {
    if(formdetails.publisherid===''){
      setPublisherState("has-danger");
      setPublisherError("This field is required");
      return false;
    }
    
    setPublisherState("has-success");
    return true;
   }


 
  const validateProjectUrl = () => { 
    if (formdetails.projecturl === '') {
      setProjectUrlState("has-danger");
      setProjectUrlError("This field is required");
      return false;
    }

    if(!formValidations.verifyWebsiteUrl(formdetails.projecturl)){
      setProjectUrlState("has-danger");
      setProjectUrlError("Invalid URL (https://example.com)");
      return false;
    }

    if(existingProjects){
      let duplicateUrl = false;
      Object.keys(existingProjects).forEach(function(key) {
          let existingProjectUrl = existingProjects[key].host;
          if(formdetails.projecturl===existingProjectUrl){
            duplicateUrl = true;
          }
      })
      if(duplicateUrl){
        setProjectUrlState("has-danger");
        setProjectUrlError("You have already created a project with this url.");
        return false;
      }
    }

    setProjectUrlState("has-success");
    return true;
  }
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  const generateProjectSlug = (projectSlug) => {
   
    projectSlug = projectSlug.replace(/^\s+|\s+$/g, ''); // trim
    projectSlug = projectSlug.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        projectSlug = projectSlug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    projectSlug = projectSlug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return projectSlug;
  }

  const validateForm = async () => {
    let namevalidation = validateProjectName();
    let urlvalidation =  validateProjectUrl();
    let publishervalidation =  validatePublisher();
    if(namevalidation && urlvalidation && publishervalidation){
      setShowLoader(true);
      setShowSaveButton(false);
      formdetails.slug = generateProjectSlug(formdetails.projectname);
      let createProject = await projectsService.createProject(formdetails);
      if(!createProject.status && createProject.error.errorCode === 403){
        setAlert({show:false});
        context.setIsAuthorized(context);
      }
      else if(createProject.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Project was created Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/project/settings?projectid="+createProject.data);
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Project could not be created, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }
  
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Create Project</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createProject"  method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label sm="2">Publisher <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={publisherstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="publisherid"
                            value={publisherFieldValue}
                            onChange={changePublisher}
                            options={allPublishers}
                          />
                          
                          {publisherstate === "has-danger" ? (
                            <label className="error">
                              {publishererror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Name <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={projectnamestate}>
                          <Input
                            name="projectname"
                            id="projectname"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {projectnamestate === "has-danger" ? (
                            <label className="error">
                              {projectnameerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">About Project</Label>
                      <Col sm="7">
                        <FormGroup className={aboutprojectstate}>
                          <Input
                            name="about"
                            id="about"
                            type="textarea"
                            onChange={handleChange}
                          />
                          {aboutprojectstate === "has-danger" ? (
                            <label className="error">
                              {aboutprojecterror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2" >Website URL <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={projecturlstate}>
                          <Input
                            name="projecturl"
                            id="projecturl"
                            type="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {projecturlstate === "has-danger" ? (
                            <label className="error">
                              {projecturlerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Locale</Label>
                      <Col sm="7">
                        <FormGroup className={projectlocalestate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="locale"
                            value={locale}
                            onChange={changeLocale}
                            options={[
                            { value: "af", label: "Afrikaans" },
                            { value: "sq", label: "Albanian" },
                            { value: "am", label: "Amharic" },
                            { value: "ar", label: "Arabic" },
                            { value: "hy", label: "Armenian" },
                            { value: "az", label: "Azerbaijani" },
                            { value: "eu", label: "Basque" },
                            { value: "be", label: "Belarusian" },
                            { value: "bn", label: "Bengali" },
                            { value: "bs", label: "Bosnian" },
                            { value: "bg", label: "Bulgarian" },
                            { value: "ca", label: "Catalan" },
                            { value: "ceb", label: "Cebuano" },
                            { value: "ny", label: "Chichewa" },
                            { value: "zh", label: "Chinese (Simplified)" },
                            { value: "zh-TW", label: "Chinese (Traditional)" },
                            { value: "co", label: "Corsican" },
                            { value: "hr", label: "Croatian" },
                            { value: "cs", label: "Czech" },
                            { value: "da", label: "Danish" },
                            { value: "nl", label: "Dutch" },
                            { value: "en", label: "English" },
                            { value: "eo", label: "Esperanto" },
                            { value: "et", label: "Estonian" },
                            { value: "tl", label: "Filipino" },
                            { value: "fi", label: "Finnish" },
                            { value: "fr", label: "French" },
                            { value: "fy", label: "Frisian" },
                            { value: "gl", label: "Galician" },
                            { value: "ka", label: "Georgian" },
                            { value: "de", label: "German" },
                            { value: "el", label: "Greek" },
                            { value: "gu", label: "Gujarati" },
                            { value: "ht", label: "Haitian Creole" },
                            { value: "ha", label: "Hausa" },
                            { value: "haw", label: "Hawaiian" },
                            { value: "iw", label: "Hebrew" },
                            { value: "hi", label: "Hindi" },
                            { value: "hmn", label: "Hmong" },
                            { value: "hu", label: "Hungarian" },
                            { value: "is", label: "Icelandic" },
                            { value: "ig", label: "Igbo" },
                            { value: "id", label: "Indonesian" },
                            { value: "ga", label: "Irish" },
                            { value: "it", label: "Italian" },
                            { value: "ja", label: "Japanese" },
                            { value: "jw", label: "Javanese" },
                            { value: "kn", label: "Kannada" },
                            { value: "kk", label: "Kazakh" },
                            { value: "km", label: "Khmer" },
                            { value: "ko", label: "Korean" },
                            { value: "ku", label: "Kurdish (Kurmanji)" },
                            { value: "ky", label: "Kyrgyz" },
                            { value: "lo", label: "Lao" },
                            { value: "la", label: "Latin" },
                            { value: "lv", label: "Latvian" },
                            { value: "lt", label: "Lithuanian" },
                            { value: "lb", label: "Luxembourgish" },
                            { value: "mk", label: "Macedonian" },
                            { value: "mg", label: "Malagasy" },
                            { value: "ms", label: "Malay" },
                            { value: "ml", label: "Malayalam" },
                            { value: "mt", label: "Maltese" },
                            { value: "mi", label: "Maori" },
                            { value: "mr", label: "Marathi" },
                            { value: "mn", label: "Mongolian" },
                            { value: "my", label: "Myanmar (Burmese)" },
                            { value: "ne", label: "Nepali" },
                            { value: "no", label: "Norwegian" },
                            { value: "ps", label: "Pashto" },
                            { value: "fa", label: "Persian" },
                            { value: "pl", label: "Polish" },
                            { value: "pt", label: "Portuguese" },
                            { value: "pa", label: "Punjabi" },
                            { value: "ro", label: "Romanian" },
                            { value: "ru", label: "Russian" },
                            { value: "sm", label: "Samoan" },
                            { value: "gd", label: "Scots Gaelic" },
                            { value: "sr", label: "Serbian" },
                            { value: "st", label: "Sesotho" },
                            { value: "sn", label: "Shona" },
                            { value: "sd", label: "Sindhi" },
                            { value: "si", label: "Sinhala" },
                            { value: "sk", label: "Slovak" },
                            { value: "sl", label: "Slovenian" },
                            { value: "so", label: "Somali" },
                            { value: "es", label: "Spanish" },
                            { value: "su", label: "Sundanese" },
                            { value: "sw", label: "Swahili" },
                            { value: "sv", label: "Swedish" },
                            { value: "tg", label: "Tajik" },
                            { value: "ta", label: "Tamil" },
                            { value: "te", label: "Telugu" },
                            { value: "th", label: "Thai" },
                            { value: "tr", label: "Turkish" },
                            { value: "uk", label: "Ukrainian" },
                            { value: "ur", label: "Urdu" },
                            { value: "uz", label: "Uzbek" },
                            { value: "vi", label: "Vietnamese" },
                            { value: "cy", label: "Welsh" },
                            { value: "xh", label: "Xhosa" },
                            { value: "yi", label: "Yiddish" },
                            { value: "yo", label: "Yoruba" },
                            { value: "zu", label: "Zulu" }
                            ]}
                          />
                          
                          {projectlocalestate === "has-danger" ? (
                            <label className="error">
                              {projectlocaleerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Timezone</Label>
                      <Col sm="7">
                        <FormGroup className={projecttimezonestate}>
                        <TimezoneSelect
                          name = "timezone"
                          value={selectedTimezone}
                          onChange={changeTimeZone}
                        />
                          
                          {projecttimezonestate === "has-danger" ? (
                            <label className="error">
                              {projecttimezoneerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  


                  {showSaveButton?<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Create
                  </Button>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default CreateProject;
