import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';
import creditService from '../../services/api/genai-credits-service';
import projectsService from '../../services/api/projects-service';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from '../../services/api/saas-service';
import UserContext from '../../UserContext';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap';

import Select from 'react-select';
const STATUS = {
  '1' : 'ACTIVE',
  '0' : 'INACTIVE',
  '-1' : 'SUSPENDED'
}
const Credits = (props)=> {
  const history = new useHistory();
  const [credits, setCredits] = useState(false);
  const [totalCredits, setTotalCredits] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:'',status:''});
  const [search, setSearchDetails] = useState({publisher_id:'',project_id:'',status:'',});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const records = 10;
  const [status,setStatus]=useState('');
  



  useEffect( () => {
    (async () =>{
        let getTotalCredits = await creditService.getTotalCredits(formdetails);
            if(getTotalCredits.status){
                setTotalCredits(getTotalCredits.data.count);
            }
        })();
    }, [reloadComponent,search]);

  useEffect( () => {
    (async () =>{
        let creditsData = await creditService.getCredits(formdetails,activePage,records);
        if(creditsData && creditsData.status){
            setCredits(creditsData.data);
        }
        setRenderComponent(true);
      })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+'('+publisherDetails.email+')',email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

  const suspendCreditPopup = (creditId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '-100px' }}
            title='Are you sure?'
            onConfirm={() => suspendCredit(creditId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle='info'
            cancelBtnBsStyle='danger'
            confirmBtnText='Yes, Suspend it!'
            cancelBtnText='Cancel'
            showCancel
            btnSize=''
          >
            You want to suspend this credits!
          </ReactBSAlert>
        ),
      });
    }

    const restoreCreditPopup = (creditId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '-100px' }}
            title='Are you sure?'
            onConfirm={() => restoreCredit(creditId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle='info'
            cancelBtnBsStyle='danger'
            confirmBtnText='Yes, restore it!'
            cancelBtnText='Cancel'
            showCancel
            btnSize=''
          >
            You want to restore this credits again!
          </ReactBSAlert>
        ),
      });
    }

    const suspendCredit = async (creditId) => {
      let susCredit = await creditService.suspendCredits(creditId);
      if(!susCredit.status && susCredit.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      }
      else if(susCredit.status && susCredit.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title='Removed!'
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle='info'
              btnSize=''
            >
              Credit was suspended successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: 'block', marginTop: '-100px' }}
              title='Please try again!'
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle='info'
              btnSize=''
            >
              Credit could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreCredit = async (creditId) => {
      let resCredit = await creditService.restoreCredits(creditId);
      if(!resCredit.status && resCredit.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      }
      else if(resCredit.status && resCredit.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title='Restored!'
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle='info'
              btnSize=''
            >
              Credit was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: 'block', marginTop: '-100px' }}
              title='Please try again!'
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle='info'
              btnSize=''
            >
              Credit could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let creditElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!credits || !credits.length){
    if(!renderComponent){
        creditElements.push(
       <tr>
        <td colSpan='10' className='text-center'>
            <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        creditElements.push(
          <tr>
        <td colSpan='10' className='text-center'>
            <CardTitle tag='h4'>No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      credits.forEach(function(creditDetails) {
        console.log('Credit Details',creditDetails);
          let className = '';
          if(!creditDetails.status){
            className = 'table-warning';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          creditElements.push(
            <tr className={className}>
                <td className='text-left'>
                   {(records*(activePage-1))+i}
               </td>
               <td className='text-left'>
                   {creditDetails.project_id?creditDetails.project_id.name:''}
               </td>
               <td className='text-left'>
                   {creditDetails.total_quota?creditDetails.total_quota:''} <br/>
               </td>
               <td className='text-left'>
                   {creditDetails.quota_used} <br/>
               </td>
               <td className='text-left'>
                   {STATUS[creditDetails.status]}
               </td>
               <td className='text-left'>
                <div>
                       <b>Created At </b> <Moment format='DD MMM YYYY hh:mm:ss a'>{creditDetails.createdAt}</Moment>
                </div>
                <div>
                <b>Modified At </b> <Moment format='DD MMM YYYY hh:mm:ss a'>{creditDetails.updatedAt}</Moment>
                </div>
               </td>
               <td className='text-left'>
                  {creditDetails.created_by ? creditDetails.created_by : 'System'}
               </td>
              
               
               <td className='text-left'>
               {creditDetails.status == 1?
                <span>
                  <a title='Suspend' onClick={(event)=>  suspendCreditPopup(creditDetails._id) } href='javascript:void(0)' class='nav-link'>
                    <FontAwesomeIcon icon={faTrash} />
                  </a>
                </span>
             :''}
             {creditDetails.status == -1 || creditDetails.status == -2?
             <a title='Restore' onClick={(event)=>  restoreCreditPopup(creditDetails._id) } href='javascript:void(0)' class='nav-link'>
                 <FontAwesomeIcon icon={faTrashRestoreAlt} />
               </a> 
              :'' }
               </td>
            </tr>
        )
        if(i==credits.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==credits.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchCredits();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }

  
  const changeCreditStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    setStatus(event)
  }


  const searchCredits = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    
    setPublisherId('');
    setProjectId('');
    setStatus('');
    setPublisher('');
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      project_id:'',
      status:'',
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className='content'>
        {showPopup.alert}
        <Container>
          <Row>
          <Col className='ml-auto mr-auto'  md='12'>
              <Card className='card-signup text-center'>
                <CardHeader>
                <CardTitle tag='h4'>
                   GenAI Credits Dashboard
                </CardTitle>
                <Row>
                <Col>
                <div style={{'float':'right'}}>
                <Button
                      className='btn-round'
                      color='danger'
                      size='sm'
                      type='button'
                      onClick={(event)=>  history.push('/genai/create') }
                  >Assign Credits
                </Button>
                </div>
                </Col>
                </Row>
              <Form id='creditfilters' action='' className='form' method='POST' onSubmit={handleSubmit}>
                <Row>
                  <Col md='3'>
                  <Select
                    className='react-select primary'
                    classNamePrefix='react-select'
                    placeholder='Select Publisher ..'
                    name = 'publisher_id'
                    id='publisher_id'
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md='2'>
                  <Select
                    className='react-select primary'
                    classNamePrefix='react-select'
                    placeholder='Select Project ..'
                    name = 'project_id'
                    id='project_id'
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>

                  
                  <Col md='3'>
                  <Select
                    className='react-select primary'
                    classNamePrefix='react-select'
                    placeholder='Select Status ..'
                    name = 'status'
                    id='status'
                    options={[{value:1,label:'Active'},{value:0,label:'Inactive'},{value:-1,label:'Suspended'}]}
                    value={status}
                    onChange={changeCreditStatus}
                  />
                  </Col>
                  </Row>
                  <Row>
                  <Col md='4'>
                    <div style={{'float':'left'}}>
                    <Button
                      className='btn-round'
                      color='info'
                      type='submit'
                    >
                      Search
                    </Button>                   
                    <Button
                      className='btn-round'
                      color='warning'
                      type='button'
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>                
                <hr></hr>
                {totalCredits > 0 ? 
                  <Row>
                        <Col md='6'>
                          <div style={{'float':'right'}}>
                            <b>{firstRecord} - {lastRecord} of {totalCredits}</b>
                          </div>
                        </Col>
                        <Col md='6'>
                        <div style={{'float':'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalCredits}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass='page-item'
                            linkClass='page-link'
                            />
                        </div>
                      </Col>
                      </Row>

                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className='text-primary'>
                      <tr>
                      <th className='text-left'>#</th> 
                      <th className='text-left'>Project Name</th> 
                      <th className='text-left'>Total Credits</th> 
                      <th className='text-left'>Credit Used</th> 
                      <th className='text-left'>Status</th>
                      <th className='text-left'>Date</th>   
                      <th className='text-left'>Assigned By</th>
                      <th className='text-left'>Action</th>
                      </tr>
                    </thead>
                    <tbody>{creditElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className='full-page-background'
          style={{
            backgroundImage: `url(${require('../../assets/img/bg/jan-sendereks.jpg')})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Credits;
