import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import ReactDatetime from "react-datetime";
const MonthlyStats = (props)=> {
    const project_id = new URLSearchParams(props.location.search).get('projectid')
    const history = new useHistory();
  const [stats, setStats] = useState(false);
  const [totalStats, setTotalStats] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',from_date:'',to_date:'',project_id:'',metric_class:'',metric_name:''});
  const [search, setSearchDetails] = useState({publisher_id:'',from_date:'',to_date:'',project_id:'',metric_class:'',metric_name:''});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const records = 10;

  const [toDate,setToDate] = useState("");
  const [fromDate,setFromDate] = useState("");
  const [metricClass,setMetricClass] = useState(false);
  const [metricName,setMetricName] = useState(false);

  useEffect(() => {
    (async() => {

        let response= await projectsService.getMonthlyStats(formdetails,activePage,records);
        if(response.status && response.data){
            setStats(response.data);
        }
        response= await projectsService.getTotalMonthlyStats(formdetails);
        if(response.status && response.data){
            setTotalStats(response.data.count);
        }

    })()
},[reloadComponent,search]);


  useEffect( () => {
    (async () =>{
        let statsData = await projectsService.getMonthlyStats(formdetails,activePage,records);

        if(statsData.status && statsData.data){
                setStats(statsData.data);
            }
        setRenderComponent(true);
        })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

 
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let statsElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!stats || !stats.length){
    if(!renderComponent){
        statsElements.push(
       <tr>
        <td colSpan="8" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        statsElements.push(
          <tr>
        <td colSpan="8" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
     stats.forEach(function(statsDetails) {
          let className = '';
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          statsElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
               <Moment format="DD MMM YYYY">{statsDetails.from_date}</Moment>
               </td>
               <td className="text-left">
               <Moment format="DD MMM YYYY">{statsDetails.to_date}</Moment>
               </td>
               <td className="text-left">
                   {statsDetails.project_id?statsDetails.project_id.name:""}
               </td>
                <td className="text-left">
                   {statsDetails.contract_id && statsDetails.contract_id.plan_id?statsDetails.contract_id.plan_id.name:""}
               </td>
               <td className="text-left">
                   {statsDetails.metric_class}
               </td>
                <td className="text-left">
                 {statsDetails.metric_name}
               </td>
               <td className="text-left">
                {statsDetails.metric_value} 
               </td>
            </tr>
        )
        if(i==stats.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==stats.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchStats();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const handleMetricClassChange = (event) => {
    setFormDetails({
      ...formdetails,
      "metric_class" : event.target.value,
    });
    setMetricClass(event.target.value);
  }
  const handleMetricNameChange = (event) => {
    setFormDetails({
      ...formdetails,
      "metric_name" : event.target.value,
    });
    setMetricName(event.target.value);
  }
  

  const handleFromDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      from_date: event._d,
    });
    setFromDate(event._d);
  }

  const handleToDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      to_date: event._d,
    });
    setToDate(event._d);
  }

  const searchStats = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherId('');
    setPublisher('');
    setProjectId('');
    setMetricClass('');
    setMetricName('');
    setToDate("");
    setFromDate("");
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      project_id:'',
      metric_class:'',
      metric_name : '',
      from_date:'',
      to_date:''
    });
    document.getElementById("metric_class").value="";
    document.getElementById("metric_name").value="";
    setSearchDetails(formdetails);
    setActivePage(1);
    setReloadComponent(Math.random());
    
  }

  const handleOrderByChange = (orderBy) => {
      setFormDetails({
        ...formdetails,
        orderBy :1
      });
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Monthly Stats Dashboard
                </CardTitle>
              <Form id="transactionfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>
                  <Col md="3">
                  <ReactDatetime
                      value={fromDate}
                      onChange={handleFromDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                        id:"fromDate",
                        autoComplete : "off"
                      }}
                      dateFormat = "DD/MM/YYYY"
                      closeOnSelect
                    />
                  </Col>
                  <Col md="3">
                  <ReactDatetime
                      value={toDate}
                      onChange={handleToDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                        id:"toDate",
                        autoComplete : "off"
                      }}
                      dateFormat = "DD/MM/YYYY"
                      closeOnSelect
                    />
                  </Col>
                  </Row><br />
                  <Row>
                  <Col md="3">
                   <Input type="text" name="metric_class" id="metric_class" onChange={handleMetricClassChange} placeholder="Metric Class"/>
                  </Col>
                  <Col md="3">
                   <Input type="text" name="metric_name" id="metric_name" onChange={handleMetricNameChange} placeholder="Metric Name"/>
                  </Col>


                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>

                </Form>

                
                <hr></hr>
                {totalStats > 0 ? 
                <Row>
                      
                      <Col md="6">
                        <div style={{float:'right'}}>
                          <b>{firstRecord} - {lastRecord} of {totalStats}</b>
                        </div>
                      </Col>
                      <Col md="6">
                      <div style={{float:'left'}}>
                          <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalStats}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          />
                      </div>
                    </Col>
                    </Row>
                
                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">From Date</th>
                      <th className="text-left">To Date</th>
                      <th className="text-left">Project</th> 
                      <th className="text-left">Plan Name</th>
                      <th className="text-left">Metric Class</th> 
                      <th className="text-left">Metric Name</th> 
                      <th className="text-left">Metric Value </th>
                      {/* <i onClick ={()=>{handleOrderByChange('metric_name')}} class="fa fa-sort float-right aria-hidden="true></i> */}
                      </tr>
                    </thead>
                    <tbody>{statsElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default MonthlyStats;
