import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Switch from "react-bootstrap-switch";
import UserContext from "../../../UserContext";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const CreatePlanGroup = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [planGroups, setPlanGroups] = useState([]);
    const [plans,setPlans]=useState([]);
    const [planGroupNameState, setPlanGroupNameState] = useState("");
    const [planGroupNameError, setPlanGroupNameError] = useState("");
    const [planNameState,setPlanNameState]=useState("");
    const [planNameError,setPlanNameError]=useState("");

    const [typeState, setTypeState] = useState("");
    const [typeError, setTypeError] = useState("");
    
    const context = useContext(UserContext);
    const [isDefaultState, setIsDefaultState] = useState("");
    const [isDefaultError, setIsDefaultError] = useState("");

    const [startTimeState, setStartTimeState] = useState("");
    const [startTimeError, setStartTimeError] = useState("");

    const [endTimeState, setEndTimeState] = useState("");
    const [endTimeError, setEndTimeError] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [planGroupOptions, setPlanGroupOption] = useState();
    const [planDuration, setPlanDuration] = useState();
    const [modified, setModified] = useState(false);

    const history = new useHistory();
    const boolOptions = [
        {
            value: true,
            label: "True",
        },
        {
            value: false,
            label: "False",
        },
    ];

    const planTypeOptions = [
        {
            value: "trial",
            label: "Trial",
        },
        {
            value: "monthly",
            label: "Monthly",
        },
        {
            value: "yearly",
            label: "Yearly",
        },
    ];

    const [planData, setPlanData] = useState({
        plan_group_id: "",
        name:"",
        start_date: "",
        end_date: "",
        type: "",
        is_primary: false,
        recurring_enabled: false,
        rzpay_plan_id: ""
    });

    useEffect(() => {
        (async () => {
            let groupsData = await saasService.getPlanGroups({params:{status:1}});
            if (groupsData.status && groupsData.data) {
                //    setPlanGroups(groupsData.data);
                let tempOptions = [];

                groupsData.data.forEach((data) => {
                    tempOptions.push({
                        value: data.id,
                        label: data.name,
                    });
                });
                setPlanGroupOption(tempOptions);
            }
            let plansData = await saasService.getPlans();
           
            if (plansData.status && plansData.data) {
                setPlans(plansData.data.planList);
            }
        })();
    }, [modified]);


    const handlePlanTypeChange = (event) => {
        console.log(event);
        setPlanData({
            ...planData,
            type: event.value,
        });
        setTypeState("has-success");
        setTypeError("");
    };

    const validatePlanGroupName = () => {
        if (planData.plan_group_id === "") {
            setPlanGroupNameState("has-danger");
            setPlanGroupNameError(
                <div className="text-danger">Plan Group Name is required</div>
            );
            return false;
        }

        setPlanGroupNameState("has-success");
        return true;
    };
    const validatePlanName = () => {
        if (planData.name.trim() === "") {
            setPlanNameState("has-danger");
            setPlanNameError("Plan name is required");
            return false;
        }
        if (plans) {
            let duplicateName = false;
            
            plans.forEach(function (details) {
                if (planData.name === details.name) {
                    duplicateName = true;
                }
            });
            if (duplicateName) {
                setPlanNameState("has-danger");
                setPlanNameError(
                    "You have already created the plan  with this name."
                );
                return false;
            }
        }
        setPlanNameState("has-success");
        return true;
    };

    const validatePlanType = () => {
        if (planData.type === "") {
            setTypeState("has-danger");
            setTypeError(
                <div className="text-danger">Plan Type is required</div>
            );
            return false;
        }

        setTypeState("has-success");
        return true;
    };
    // const validateIsDefault = () =>{

    //     if(planData.is_default===''){
    //         setIsDefaultState("has-danger");
    //         setIsDefaultError(<div className='text-danger'>This field is required</div>);
    //         return false;
    //       }
    //       setIsDefaultState("has-success");
    //       return true;

    // }

    let planTypeElements = [];
    if (planTypeOptions) {
        planTypeOptions.forEach((planType) => {
            planTypeElements.push(
                <div>
                    <Label check>
                        <Input
                            value={planType.value}
                            //   id="planGroupId"
                            name="currencyId"
                            type="radio"
                            onChange={(value) => {
                                handlePlanTypeChange(planType);
                            }}
                        />
                        {planType.label} <span className="form-check-sign" />
                    </Label>
                </div>
            );
        });
    }

    const validateStartTime = () => {
        if (planData.start_date === "") {
            setStartTimeState("has-danger");
            setStartTimeError("Start Date-Time is required");
            return false;
        }
        setStartTimeState("has-success");
        return true;
    };
    const validateEndTime = () => {
        if (planData.end_date === "") {
            setEndTimeState("has-danger");
            setEndTimeError("End Date-Time is required");
            return false;
        }
        setEndTimeState("has-success");

        return true;
    };

    const validateForm = async () => {
        console.log("Plan Data:",planData);

        let planGroupNameValidation = validatePlanGroupName();
        let planNameValidation=validatePlanName();
        let planTypeValidation=validatePlanType();
        let startTimeValidation = validateStartTime();

        let endTimeValidation = validateEndTime();

        if (
            planGroupNameValidation && planNameValidation &&planTypeValidation&&
            startTimeValidation &&
            endTimeValidation
        ) {
            setShowLoader(true);

            setShowSaveButton(false);

            const response = await saasService.createPlan(planData);

            console.log(response);
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Plan was created Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    //    history.push("/saas/plan-groups");
                    history.push(`/saas/plan-details/${response.data}`);
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message: "Plan could not be created, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    const handleSubmit = async (event) => {
        // validateForm();
        console.log(planData);
        event.preventDefault();
    };

    const handlePlanGroupChange = async (event) => {
        setPlanData({
            ...planData,
            plan_group_id: event.target.value,
        });
        setPlanGroupNameState("");
        setPlanGroupNameError("");
    };

    const handleChange = (event) => {
        setPlanData({
            ...planData,
            [event.target.id]: event.target.value.trim(),
        });
    };
    const handlePlanNameChange = async (event) => {
        setPlanData({
            ...planData,
            name: event.target.value,
        });
        setPlanNameState("");
        setPlanNameError("");
    };

    const handleRzpPlanId = (event) =>{
        setPlanData({
            ...planData,
            rzpay_plan_id: event.target.value
        })
    }


    const disablePastDates = (current) => {
        const previousDate = moment().subtract(1, "day");
        return current.isAfter(previousDate);

    };
   const disablePastDatesForEndDate = (current) => {
    const previousDate = moment(planData.start_date).subtract(1, "day");
    return current.isAfter(previousDate);
};
     const disablePastAndPreviousDatesForStartDate = (current) => {
    const previousDate = moment(planData.end_date)
    return current.isBetween(moment(),previousDate);

    };


    const handleStartTimeChange = (event) => {
        setPlanData({
            ...planData,
            start_date: moment(event._d).utc().toDate(),
        });

        if (planData.end_date != "") {
            var duration = moment.duration(
                moment(planData.end_date).diff(moment(event._d))
            );
            setDuration(duration);
        } else {
            setPlanDuration("");
        }
        setStartTimeError("");
        setStartTimeState("");
    };
    const handleSwitchChange = (fieldname, fieldvalue) => {
        setPlanData({
            ...planData,
            [fieldname]: fieldvalue,
        });

        setIsDefaultError("");
        setIsDefaultState("");
    };

    const handleIsDefault = (event) => {
        setPlanData({
            ...planData,
            is_default: event.value,
        });
    };

    const handleEndTimeChange = (event) => {
        setPlanData({
            ...planData,
            end_date: moment(event._d).utc().toDate(),
        });
        if (planData.start_date != "") {
            var duration = moment.duration(
                moment(event._d).diff(moment(planData.start_date))
            );
            setDuration(duration);
        } else {
            setPlanDuration("");
        }
        setEndTimeError("");
        setEndTimeState("");
    };

    function setDuration(duration) {
        let data=duration._data;
        var seconds = data.seconds;
        var minutes = data.minutes;
        var hours = data.hours;
        var days = data.days;
        var months = data.months;
        var years = data.years;
        var s = "";
        if (years > 0) {
            s += years + (years === 1 ? "  Year  " : "  Years  ");
        }
        if (months > 0) {
            s += months + (months === 1 ? "  Month  " : "  Months  ");
        }
        if (days > 0) {
            s += days + (days === 1 ? "  Day  " : "  Days  ");
        }

        if (hours > 0) {
            s += hours + (hours === 1 ? "  Hour  " : "  Hours  ");
        }

        if (minutes > 0) {
            s += minutes + (minutes === 1 ? "  Minute  " : "  Minutes  ");
        }
        setPlanDuration(s);
    }

    let planGroupElements = [];
    if (planGroupOptions) {
        let i = 0;
        planGroupOptions.forEach(function (planGroupDetails) {
            planGroupElements.push(
                <div>
                    <Label check>
                        <Input
                            value={planGroupDetails.value}
                            id="planGroupId"
                            name="planGroupId"
                            type="radio"
                            onChange={handlePlanGroupChange}
                        />
                        {planGroupDetails.label}{" "}
                        <span className="form-check-sign" />
                    </Label>
                </div>
            );
        });
    }

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Create Plan</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol"
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Label sm="3">Plan Group Name <span class="text-danger"> * </span></Label>

                                        <Col sm="1">
                                            <Row>
                                                {planGroupNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {planGroupNameError}
                                                    </label>
                                                ) : null}
                                                {planGroupElements}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Label sm="3">Plan Name <span class="text-danger"> * </span></Label>
                                        <Col sm="5">
                                            <FormGroup className={planNameState}>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    onChange={handlePlanNameChange}
                                                />
                                                {planNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {planNameError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Label sm="3">Plan Type <span class="text-danger"> * </span></Label>

                                        <Col sm="1">
                                            <Row>
                                                {typeState === "has-danger" ? (
                                                    <label className="error">
                                                        {typeError}
                                                    </label>
                                                ) : null}
                                                {planTypeElements}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Label sm="3">Is Primary Plan</Label>
                                        <Col sm="1">
                                            <FormGroup>
                                                <Switch
                                                    name="is_default"
                                                    offColor="success"
                                                    offText={
                                                        <i className="nc-icon nc-simple-remove" />
                                                    }
                                                    onColor="success"
                                                    onText={
                                                        <i className="nc-icon nc-check-2" />
                                                    }
                                                    value={planData.is_primary}
                                                    onChange={(
                                                        value,
                                                        enabled
                                                    ) =>
                                                        handleSwitchChange(
                                                            "is_primary",
                                                            enabled
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="8"></Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Label sm="3">Enable Recurring</Label>
                                        <Col sm="1">
                                            <FormGroup>
                                                <Switch
                                                    name="is_default"
                                                    offColor="success"
                                                    offText={
                                                        <i className="nc-icon nc-simple-remove" />
                                                    }
                                                    onColor="success"
                                                    onText={
                                                        <i className="nc-icon nc-check-2" />
                                                    }
                                                    value={planData.recurring_enabled}
                                                    onChange={(
                                                        value,
                                                        enabled
                                                    ) =>
                                                        handleSwitchChange(
                                                            "recurring_enabled",
                                                            enabled
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="8"></Col>
                                    </Row><br />
                                    {
                                        planData.recurring_enabled?  
                                            <Row>
                                                <Label sm="3">Razorpay Plan Id <span class="text-danger"> * </span></Label>
                                                <Col sm="5">
                                                    <FormGroup className={planNameState}>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            onChange={handleRzpPlanId}
                                                        />
                                                        {planNameState === "has-danger" ? (
                                                            <label className="error">
                                                                {planNameError}
                                                            </label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>:""
                                    }
                                    <Row>
                                    <Label sm="3">
                                                    Start Date-Time <span class="text-danger"> * </span>
                                    </Label>
                                    <Col>
                                    <FormGroup
                                                    className={startTimeState}
                                                >
                                                    <Col></Col>

                                                    <Col sm="7">
                                                        <ReactDatetime
                                                            value={
                                                                planData.start_date
                                                            }
                                                            name="scheduleTime"
                                                            // id="scheduleTime"
                                                            isValidDate={
                                                                //disablePastDates
                                                                planData.end_date?disablePastAndPreviousDatesForStartDate:disablePastDates
                                                            }
                                                            onChange={
                                                                handleStartTimeChange
                                                            }
                                                        />
                                                        {startTimeState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {startTimeError}
                                                            </label>
                                                        ) : null}
                                                    </Col>
                                                </FormGroup>
                                    </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                    <Label sm="3">
                                                    End Date-Time <span class="text-danger"> * </span>
                                    </Label>
                                    <Col>
                                    <FormGroup
                                                    className={endTimeState}
                                                >
                                                    <Col sm="7">
                                                        <ReactDatetime
                                                            value={
                                                                planData.end_date
                                                            }
                                                            name="scheduleTime"
                                                            // id="scheduleTime"
                                                            isValidDate={
                                                             planData.start_date?disablePastDatesForEndDate:disablePastDates
                                                            //disablePastDates
                                                        }
                                                            onChange={
                                                                handleEndTimeChange
                                                            }
                                                        />
                                                        {endTimeState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {endTimeError}
                                                            </label>
                                                        ) : null}
                                                    </Col>
                                                </FormGroup>
                                    </Col>
                                    </Row><br />    
                                    <Row>
                                        <Label sm="3">Duration</Label>

                                        <Col sm="1">
                                            <div>{planDuration}</div>
                                        </Col>
                                    </Row>

                                    {showSaveButton ? (
                                        <div>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="button"
                                                onClick={validateForm}
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="danger"
                                                type="button"
                                                onClick={(event) => {
                                                    history.push(
                                                        `/saas/plans`
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CreatePlanGroup;
