import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
// import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const CategoryCountWidget = (props)=> {
  const [totalCategories,setTotalCategories] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let categoryData = await contentService.getCategories(props.projectId);
        if(categoryData.status){
            let categories = categoryData.data;
            let categoryCount=0;
            if(categories.length){
                categories.forEach(function(categoryDetails) {
                    categoryCount++;
                    if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
                        let subCategories = categoryDetails.sub_categories;
                        subCategories.forEach(function(subCategoryDetails) {
                            categoryCount++;
                        })
                    }
                })

            }
            setTotalCategories(categoryCount);
            setRenderComponent(true);
        }
        
        })();
    }, []);  
 

    return (
        <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
              <i className="fa fa-list-alt text-danger" />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category"><a href={"/contentsettings/categories?projectid="+props.projectId}>Categories</a></p>
                <CardTitle tag="p">
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalCategories}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar-o" />
            Till Date
          </div>
        </CardFooter>
      </Card>
    );
  
  
}

export default CategoryCountWidget;
