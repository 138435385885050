import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from 'react-router-dom';
import Pagination from "react-js-pagination";
import Projects from "../projects/Projects";
import moment from 'moment';
import './WPWebstories.css'
import whatsappImage from "../../assets/img/whatsapp.png";
import facebookImage from "../../assets/img/facebook.png";
import twitterImage from "../../assets/img/twitter.png";
import telegramImage from "../../assets/img/telegram.png";
import copyToClipboardImage from "../../assets/img/copy.png";
import UserContext from "../../UserContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import defaultImage from "../../assets/img/no_image.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
const WPWebstories = (props)=> {
  const projectId = new URLSearchParams(props.location.search).get("projectid");
  const [formdetails, setFormDetails] = useState({projectId,title : '', status : 1});
  const context = useContext(UserContext);
  const [webstories, setWebstories] = useState(false);
  const [totalWebstories,setTotalWebstories] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [search,setSearchDetails] = useState({projectId ,title : '', status :1});
  const [reloadComponent,setReloadComponent] = useState(0);
  const records = 8;
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const statusArray = [{"value":"all",label:"All"},{"value": 1,label:"Active"},{"value":0,label:"Deleted"}];
  useEffect(()=>{
        (async ()=>{
            const response = await contentService.getWPWebstories(formdetails,activePage,records);
            if(!response.status && response.error.errorCode === 403){
                let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
              window.history.replaceState({},'',newurl)
            }
            if(response.status && response.data) {
                console.log(response.data);
                let webstories = response.data.webstories;
                let webstoriesArray =[];
                let size = 4;
                for (let i=0; i<webstories.length; i+=size) {
                    webstoriesArray.push(webstories.slice(i,i+size));
                }
                setWebstories(webstoriesArray);
                setTotalWebstories(response.data.count);
                if(webstories && webstories.length) {
                    setFirstRecord((activePage-1)*records+1);
                    setLastRecord((activePage-1)*records+ webstories.length);
                }else{
                    setFirstRecord((activePage-1)*records);
                    setLastRecord((activePage-1)*records+ webstories.length);
                }
                
            }
            setRenderComponent(true);
        })();
    },[reloadComponent]);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const handleCardClick = (details) => {
        console.log(details);
        if(details.share_url) {
            window.open(details.share_url, '_blank');
        }      
    }
    const onImageError = (event) => {
        event.target.src = defaultImage;
    }

    const handleSubmit = function(e){  searchWebstories();e.preventDefault() }

    const searchWebstories = () => {
            setActivePage(1);
            setSearchDetails(formdetails);
            setReloadComponent(Math.random());
    }
  const resetForm = () => {
    setActivePage(1);
    setFormDetails({
      ...formdetails,
      title : '',
      status : 1
    });
    setSearchDetails({
      title : '',
      status : 1
    });
    setReloadComponent(Math.random());
  }

  const handleChange = (event)=>{
      console.log("Event:",event);
    setFormDetails({
      ...formdetails,
      [event.id]: event.value,
    });
  }
 const handleTitleChange = (event) => {
    setFormDetails({
      ...formdetails,
      title: event.target.value,
    });
  };
 const handleStatusChange = (event)=>{
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
  }
  const deleteWebstoryPopup = (webstoryId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deleteWebstory(webstoryId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete this!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to delete this webstory!
          </ReactBSAlert>
        ),
      });
    }

    const restoreWebstoryPopup = (webstoryId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreProject(webstoryId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this webstory again!
          </ReactBSAlert>
        ),
      });
    }

    const deleteWebstory = async (webstoryId) => {
      let susProject = await contentService.deleteWPWebstory(webstoryId, projectId);
      if(!susProject.status && susProject.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(susProject.status && susProject.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Webstory was deleted successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Webstory could not be deleted. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreProject = async (webstoryId) => {
      let resProject = await contentService.restoreWPWebstory(webstoryId, projectId);
      if(!resProject.status && resProject.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(resProject.status && resProject.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Webstory was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Webstory could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    }; 

    if (!projectId) {
      return <Projects navigate="contentsettings/wpwebstories"></Projects>;
    } else {
    return (
        <div className="content">
            {showPopup.alert}
            <Container>
              <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4" className="text-center ">
                        Wordpress Webstories   
                    </CardTitle>
                    {!renderComponent?
                        <div className="text-center">
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :null}
                    <Form
                        id="webstoryFilters"
                        action=""
                        className="form"
                        method="POST"
                        onSubmit={handleSubmit}
                        >
                        <Row>
                          <Col md="4">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Status .."
                                name="status"
                                id="status"
                                options={statusArray}
                                value={statusArray && (formdetails.status!=="")?statusArray.filter(option=> option.value === formdetails.status):""}
                                onChange={handleStatusChange}
                            />
                            </Col>
                            <Col md="4">
                            <Input
                                value={formdetails.title}
                                type="text"
                                name="title"
                                id="title"
                                onChange={handleTitleChange}
                                placeholder="Search by Webstory title"
                            />
                            </Col>
                            <Col md="4">
                              <div style={{'float':'left'}}>
                                <Button className="btn-smb" type="submit">
                                    Search
                                </Button>
                                <Button
                                    className="btn-smb btnRst"
                                    type="button"
                                    onClick={resetForm}
                                    >
                                Reset 
                                </Button>
                                </div>
                                </Col>
                    </Row>
                    </Form>
                    <hr/>
                    {/* {webstories && webstories.length? */}
                    {totalWebstories > 0 ?
                      <Row>
                      <Col md="6">
                        <div style={{'float':'right'}}>
                              {firstRecord} - {lastRecord} of {totalWebstories}
                        </div>
                        </Col>
                        <Col md="6">
                        <div style={{'float':'left'}}>        
                              <Pagination
                              activePage={activePage}
                              itemsCountPerPage={records}
                              totalItemsCount={totalWebstories}
                              pageRangeDisplayed={3}
                              onChange={handlePageChange.bind(this)}
                              itemClass="page-item"
                              linkClass="page-link"
                              />
                        </div>
                        </Col>
                      </Row>
                    
                    : null}
                    </CardHeader>
                    {/* :""} */}
                    {webstories && webstories.length?webstories.map((details, index)=>(
                        <Row className="strRow">
                        {details.map((data,index) => (
                            <Col  sm="3">
                                <Card className={data.status?"stryCard":"stryCard deletedStry"} >
                                <CardBody className="cardBody" >
                                    <CardImg  title="Click here to view" onClick= {()=>{handleCardClick(data)}} onError ={onImageError} variant="top" src={data.image} style={{"height":"250px"}} />
                                    <p  title={data.title} className="wrWord">{data.title}</p>   
                                </CardBody>
                                <CardFooter>
                                    <div>
                                      <div>
                                    <div class="social_icons">
                                        <a id="whatsapp" title ="Share on Whatsapp" target="_blank" data-action="share/whatsapp/share" href={`https://web.whatsapp.com/send?text=${data.share_url}`}> 
                                            <img width="27" height="27" alt="Whatsapp share" src={whatsappImage} />
                                        </a>
                                        <a id="facebook" title ="Share on Facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${data.share_url}`}> 
                                            <img width="24" height="24" alt="facebook share" src={facebookImage} /> 
                                        </a>
                                        <a id="twitter" title ="Share on Twitter" target="_blank" href={`https://twitter.com/intent/tweet?text=${data.title}&url=${data.share_url}`}> 
                                            <img width="24" height="24" alt="tweet button" src={twitterImage} /> 
                                        </a>
                                        <a id="telegram" title ="Share on Telegram" target="_blank" href={`https://t.me/share/url?url=${data.share_url}&text=${data.title}`}>
                                            <img width="24" height="24" alt="telegram share" src={telegramImage} /> 
                                        </a>
                                        {data.status?
                                            <a title="delete" onClick={(event)=>  deleteWebstoryPopup(data.id) } href="javascript:void(0)" class="nav-link">
                                            <Button  className="btnsmiconred"><FontAwesomeIcon icon={faTrash} /></Button> 
                                        </a>
                                        :''}
                                        {data.status== false?
                                        <a title="Restore" onClick={(event)=>  restoreWebstoryPopup(data.id) } href="javascript:void(0)" class="nav-link">
                                            <Button  className="btn-smb btnsmicon"><FontAwesomeIcon icon={faTrashRestoreAlt} /></Button>  
                                            </a> 
                                            :''}
                                    </div>
                                    
                                    </div>
                                    <small className="text-muted">{moment(data.published_on).fromNow()}</small>
                                    </div>
                                </CardFooter>
                            </Card>
                            </Col>
                        ))}
                        </Row>
                    )):
                    <div className="text-center">
                        <br/>
                        <CardTitle tag="h4" className="noRslt">No Webstory Found</CardTitle>
                        <br/><br/>
                    </div>
                    }

                </Card>
                </Col>
                </Row>
            </Container>
            <div
            className="full-page-background"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
            }}
            />
        </div>
        
    );
    }
}
export default WPWebstories;