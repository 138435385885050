import React, { useEffect, useState,useContext } from "react";
import projectService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPencilAlt,
    faTrash,
    faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import Contracts from "./contracts";

const PublisherReport = (props) => {
    const history = new useHistory();
    let publisherEmail = new URLSearchParams(props.location.search).get(
        "publisher_email"
    );
    const [formdetails, setFormDetails] = useState({
        publisherids: [],
        publishername: "",
        publisheremail: publisherEmail,
        projectname: "",
    });
    const [projects, setProjects] = useState(false);
    const [publishersDetail, setPublishersDetail] = useState(false);
   
    const [renderComponent, setRenderComponent] = useState(false);
    const [search, setSearchDetails] = useState({
        publisherids: [],
        publishername: "",
        publisheremail: publisherEmail,
        projectname: "",
    });
    const context = useContext(UserContext);
    const [reloadComponent, setReloadComponent] = useState(0);


    useEffect(() => {
        (async () => {
            let projectsData = await projectService.getPublishersProjectByEmail(
                publisherEmail
            );
            if (projectsData.status && projectsData.data) {
                setProjects(projectsData.data.projects);
                setPublishersDetail({
                    name: projectsData.data.name,
                    email: publisherEmail,
                    id: projectsData.data._id

                });
            }

            setRenderComponent(true);
        })();
    }, []);

  


  
    let projectElements = [];
    if (!projects || !projects.length) {
        if (!renderComponent) {
            projectElements.push(
                <td colSpan="3" className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </td>
            );
        } else {
            projectElements.push(
                <td colSpan="3" className="text-center">
                    <CardTitle tag="h4">No Projects found.</CardTitle>
                </td>
            );
        }
    } else {
        let i = 1;
        projects.forEach(function (projectDetails) {
            let className = "";
            if (projectDetails.status == -1) {
                className = "table-warning";
            }

            projectElements.push(
                <tr id={"row_" + projectDetails._id} className={className}>
                    <td>{i}</td>
                    <td>
                        <a
                            onClick={(event) =>
                                (window.location.href =
                                    "/report/project?project=" +
                                    projectDetails.project_id.slug)
                            }
                            href="javascript:void(0)"
                            class="btn-rotate nav-link"
                        >
                            {projectDetails.project_id?projectDetails.project_id.name:""}
                        </a>
                    </td>
                    <td>
                        <a
                            onClick={(event) =>
                                (window.location.href =
                                    "/report/project?project=" +
                                    projectDetails.project_id.slug)
                            }
                            href="javascript:void(0)"
                            class="btn-rotate nav-link"
                        >
                            {projectDetails.project_id?projectDetails.project_id.slug:""}
                        </a>
                    </td>
                </tr>
            );
            i++;
        });
    }

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-signup text-center">
                            <CardHeader>
                            </CardHeader>
                            <CardBody>
                            <b>
                            <Row>
                                    <Col>
                                    Publisher Name : {publishersDetail
                                                ? publishersDetail.name
                                                : ""}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    Publisher Email : {publishersDetail
                                                ? publishersDetail.email
                                                : ""}                                   
                                    </Col>
                                </Row>
                            </b>
                            </CardBody>
                            <CardHeader></CardHeader>
                    </Card>
                    <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Projects</CardTitle>
                                <hr/>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                Project Name
                                            </th>
                                            <th>
                                                Project Slug
                                            </th>
                                            {/* <th className="text-left">Publisher Name</th>
                        <th className="text-left">Publisher Email</th>
                        <th className="text-left">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>{projectElements}</tbody>
                                </Table>
                            </CardBody>
                            <CardFooter></CardFooter>
                    </Card>
                    <Card className="card-signup text-center">
                        <CardHeader></CardHeader>
                        <CardBody>
                        {publishersDetail.id? <Contracts publisherId={publishersDetail.id}/>:""}
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default PublisherReport;
