import React, { useEffect, useState, useContext } from "react";
import { useHistory,Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import usersService from '../../services/api/user-service.js';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext.js";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from 'react-select';


const UserDetails = (props) => {
    
    let permissionGroupId = '';
    const history = new useHistory();
    const context = useContext(UserContext);
    
    const [users, setUsers] = useState(false);
    const [renderComponent,setRenderComponent] = useState(false); // change true in false
    const [showPopup, setShowPopup] = useState({alert:null});
    const [reloadComponent,setReloadComponent] = useState(0);
    const [permissionGroup, setPermissionGroup] = useState('');
    const [formdetails, setFormDetails] = useState({permission_Group:permissionGroupId,userEmail:'',status:1});
    const [search, setSearchDetails] = useState({permission_Group:permissionGroupId,userEmail:'',status:1});
    const [permissionGroupArray, setpermissionGroupArray] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [activePage,setActivePage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);

    const records = 10;

    let statusArray = [
        {
            value: 1,
            label: "Active",
        },
        {
            value:0,
            label:"Deleted",
        },
        {
            value:"all",
            label:"All",
        },
    ];
    
    // handler functions code starts

    // useEffect ( () => {
    //     (
    //         async () => {
    //             let permissionGroupData = await usersService.getPermissionGroup();
                
    //             if(!permissionGroupData.status && permissionGroupData.error.errorCode === 403){
    //                 history.push("/unauth/unauthorized");
    //             }
    //             else if(permissionGroupData != ""){
    //                 setpermissionGroupArray(permissionGroupData.data);
    //                 let allPermissionGroup = permissionGroupData.data;
    //                 let permissionGroupsArray = [];
    //                 // let defaultPermissionGroup = '';
    //                 if(allPermissionGroup.length){
    //                     allPermissionGroup.forEach(function(permissionGroupDetails){
    //                         permissionGroupsArray.push({value:permissionGroupDetails._id,label:permissionGroupDetails.name});
    //                         //if(permissionGroupId != '' && permissionGroupId==permissionGroupDetails._id){
    //                           //  defaultPermissionGroup = {value:permissionGroupDetails._id,label:permissionGroupDetails.name};
    //                         //}
    //                     })
    //                 }
    //                 console.log(permissionGroupsArray)
    //                 setpermissionGroupArray(permissionGroupsArray);
    //                 console.log(permissionGroupArray)
    //                 // setPermissionGroup(defaultPermissionGroup);
    //             }
    //         }
    //     )();
    // },[]);

    useEffect( () => {
        (
            async () =>{
                // console.log(formdetails)
                let userData = await usersService.getUsersList({filters:formdetails,activePage,records});
                // if(!userData.status && userData.error.errorCode === 403){
                //     history.push("/unauth/unauthorized")
                // }
                if(userData.status){
                    setUsers(userData.data.users);
                    setTotalUsers(userData.data.count);
                    // for(var i in userData.data){
                        
                    //     let userPermissionGroupData = await userService.getUserPermissionGroupList(userData.data[i].user_id);
                    //     console.log("array: ",permissionGroupArray)
                    //     console.log(userData.data[i].user_name,userPermissionGroupData.data)
                    // }

                    
                    console.log("in user details list")
                }
                // console.log("error: ",userData.error.errorCode)
                setRenderComponent(true);
            }
        )();
    },[reloadComponent,search,activePage]);

    const suspendUserPopUp = (userId) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display:"block",marginTop:"-100px"}}
                    title = "Are you sure?"
                    onConfirm={()=> suspendUser(userId)}
                    onCancel = {()=> hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Remove it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to remove this User!
                </ReactBSAlert>
            ),
        });
    }

    const restoreUserPopUp = (userId) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display:"block",marginTop:"-100px"}}
                    title = "Are you sure?"
                    onConfirm={()=>restoreUser(userId)}
                    onCancel= { ()=> hideAlert()}
                    confirmBtnBsStyle = "info"
                    cancelBtnBsStyle= "danger"
                    confirmBtnText = "Yes, restore User!"
                    cancelBtnText = "Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to restore this user again!
                </ReactBSAlert>
            ),
        });
    }

    const restoreUser = async(userId) => {
        // console.log("in res")
        let resUser = await usersService.restoreUser(userId);
        if(!resUser.status && resUser.error.errorCode === 403){
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context);
        }
        if(resUser.status && resUser.data.restored){
            setShowPopup({
                alert:(
                    <ReactBSAlert
                    success
                    style={{display:"block", marginTop:"-100px"}}
                    title = "Restored!"
                    onConfirm={()=> hideAlert()}
                    onCancel = {()=>hideAlert()}
                    confirmBtnBsStyle = "info"
                    btnSize=""
                    >
                        User was restored successfully.
                    </ReactBSAlert>
                ),
            });
            setReloadComponent(Math.random());
        }
        else{
            setShowPopup({
                alert: (
                    <ReactBSAlert
                    danger
                    style={{display:"block", marginTop:"-100px"}}
                    title = "Please try again"
                    onConfirm={()=> hideAlert()}
                    onCancel = {()=> hideAlert()}
                    confirmBtnBsStyle = "info"
                    btnSize=""
                    >
                        User could not be restored. Please try again. 
                    </ReactBSAlert>
                ),
            });
        }
    }

    const suspendUser = async (userId) => {
        // console.log("in suspend user")
        let suspend = await usersService.suspendUser(userId);
        if(!suspend.status && suspend.error.errorCode === 403){
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context);
        }
        if(suspend.status && suspend.data.delete){
            setShowPopup({
                alert: (
                    <ReactBSAlert
                    success
                    style={{display:"block",marginTop:"-100px"}}
                    title="Removed!"
                    onConfirm={()=> hideAlert()}
                    onCancel={()=> hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                    >
                        User was removed successfully.
                    </ReactBSAlert>
                )
            });
            setReloadComponent(Math.random());
        }
        else{
            setShowPopup({
                alert: (
                    <ReactBSAlert
                    danger
                    style={{display:"block",marginTop:"-100px"}}
                    title="Please try Again!"
                    onConfirm={()=> hideAlert()}
                    onCancel={()=> hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                    >
                        User could not be removed. Please try again.
                    </ReactBSAlert>
                ),
            });
        }
    }

    const changeStatus = (event)=>{
        setFormDetails({
            ...formdetails,
            status: event.value,
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };

    const redirectUrl = (e,userId) =>{
        e.preventDefault()
        history.push("/users/edituserdetails?userId="+userId)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    let userElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if(!users || !users.length){
        // console.log(renderComponent)
        if(!renderComponent){
            userElements.push(
                <tr>
                    <td colSpan="5" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            );
        }else{
            userElements.push(
                <tr>
                    <td colSpan="5" className="text-center">
                        <CardTitle tag="h4">No records Found</CardTitle>
                    </td>
                </tr>
            );
        }
    }else{
        let i=1;
        users.forEach(function(usersDetails){
            // console.log("userdata:",usersDetails.user_id);
            // console.log(permissionGroupArray)
            // let permissionGroups = await usersService.getUserPermissionGroupList(usersDetails.user_id);
            // console.log("pg list: ",permissionGroups.data)
            let className = '';
            if(!usersDetails.status){
                className = 'table-warning';
            }
            if(i==1){
                firstRecord = (activePage - 1)* records+i;
            }
            userElements.push(
                <tr className={className}>
                    <td className="text-left">
                        {(records*(activePage-1))+i}
                    </td>
                    <td className="text-left">
                        {usersDetails.user_email} 
                    </td>
                    <td className="text-left">
                        {usersDetails.user_name}
                    </td>
                    <td className="text-left"  style={{ flexDirection: "row", justifyContent: "center" }}>
                        {/* {console.log(usersDetails)} */}
                        {usersDetails.user_permission_group.map((data)=>{
                            return(
                                <ul type="disc">
                                    <li style={{width:"100px"}}>{data}</li>
                                </ul>
                            )
                        })}
                    </td>
                    <td className="text-left">
                        {usersDetails.status===1?
                        <span>
                            <a title="Edit" onClick={(event)=> redirectUrl(event, usersDetails.user_id)} href="javascript:void(0)" className="nav-link">
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </a>
                            <a title="Remove" onClick={(event)=> suspendUserPopUp(usersDetails.user_id)} href="javascript:void(0)" className="nav-link">
                                <FontAwesomeIcon icon={faTrash}/>
                            </a>
                        </span>
                        :
                        <a title="Restore" onClick={(event)=>  restoreUserPopUp(usersDetails.user_id) } href="javascript:void(0)" class="nav-link">
                            <FontAwesomeIcon icon={faTrashRestoreAlt} />
                        </a>
                        // <td className="text-left">
                        //     Removed
                        // </td>    
                    }
                    </td>
                </tr>
            )
            if(i== users.length){
                lastRecord = (activePage-1) * records + i;
            }
            i++;
            if(i == users.length){
                lastRecord = (activePage - 1)* records +i;
            }
        })
    }

    const handleSubmit = function(e) { searchUsers();e.preventDefault() }

    const changeEmail = (event) => {
        // console.log(event.target.value)
        setFormDetails({
            ...formdetails,
            userEmail: event.target.value,
        });
        setUserEmail(event);
        // console.log(formdetails)
    }
    const changePermissionGroup = (event) => {
        setFormDetails({
            ...formdetails,
            permission_Group: event.target.value,
        });
        setPermissionGroup(event)
    }

    const searchUsers = () =>{
        setActivePage(1);

        setSearchDetails(formdetails);
    }


    const resetForm = () => {
        setPermissionGroup('');
        setFormDetails({
            ...formdetails,
            permission_Group: '',
            userEmail: '',
            status:'Active'
        });
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
    }
    // handler function code ends
    
    return (
        <>
            <div className="content">
                {showPopup.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <Row>
                                        <Col>
                                        <div style={{'float':'right'}}>
                                        <Button
                                        className="btn-round"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={(event) => { history.push("/users/create-user")}}
                                    >
                                        Create User
                                    </Button>
                                        </div>
                                        </Col>
                                    </Row>
                                    <CardTitle tag="h4">
                                        Users Dashboard
                                    </CardTitle>
                                    <Form action="" method="POST" className="userForm" onSubmit={handleSubmit} />
                                    <Row>
                                        {/* for email */}
                                        <Col md="4">
                                            <Input type="email" name="email" id="email" value={formdetails.userEmail} onChange={changeEmail} placeholder="Enter your email.." style={{ 'height': '40px' }} />
                                        </Col>
                                        {/* for roles options */}
                                        {/* <Col md="4">
                                            <Select
                                                multiple='true'
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Role.."
                                                name="role_name"
                                                id="role_name"
                                                options={permissionGroupArray}
                                                value={permissionGroup} 
                                                onChange={changePermissionGroup} 
                                            />
                                        </Col> */}

                                        {/* add user button */}
                                        <Col md="4">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder ="Select Status.."
                                                name="status"
                                                id="status"
                                                options={statusArray}
                                                value={formdetails.status !== ""?statusArray.filter(option=>option.value == formdetails.status):""}
                                                onChange={changeStatus}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <div style={{'float':'left'}}>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="submit"
                                                onClick={searchUsers}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="warning"
                                                type="button"
                                                onClick={resetForm}
                                            >
                                                Reset
                                            </Button>
                                            </div>
                                        </Col>
                                        </Row>
                                        <hr />
                                        {totalUsers > 0 ? 
                                        <Row>
                                        <Col md="6">
                                            <div style={{float:'right',margin:'5px'}}>
                                            <b>{firstRecord} - {lastRecord} of {totalUsers}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{float:'left'}}>
                                                <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={records}
                                                totalItemsCount={totalUsers}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange.bind(this)}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                        : null}
                                </CardHeader>
                                {/* card body starts which will list the added users */}
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th className="text-left">#</th>
                                                <th className="text-left">Email</th>
                                                <th className="text-left">Username</th>
                                                <th className="text-left">Roles</th>
                                                <th className="text-left">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{userElements}</tbody>
                                    </Table>
                                </CardBody>

                                <CardFooter>

                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default UserDetails;