import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import cacheCleanJobsService from "../../services/api/clean-cache-job-service";
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
const CacheClean = (props)=> {
  const history = new useHistory();
  const context = useContext(UserContext);
  const [clearCacheJobs, setClearCacheJobs] = useState(false);
  const [totalClearCacheJobs, setTotalClearCacheJobs] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  
  const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:''});
  const [search, setSearchDetails] = useState({publisher_id:'',project_id:''});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const records = 10;

  useEffect( () => {
    (async () =>{
        let getTotalJobs = await cacheCleanJobsService.getTotalClearCacheJobs(formdetails);
            if(getTotalJobs.status){
                setTotalClearCacheJobs(getTotalJobs.data.count);
            }
        })();
    }, [reloadComponent,search]);

  useEffect( () => {
    (async () =>{
        let jobsData = await cacheCleanJobsService.getClearCacheJobs(formdetails,activePage,records);

        if(jobsData.status){
                setClearCacheJobs(jobsData.data.jobsList);
            }
          
            setRenderComponent(true);
        })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

  const getCommands = (jobData)=>{
    let data =  JSON.parse(jobData);
    return <>
          {
          data.map((details)=>{
            return details.command+"\n"
        })}
      </>
  }
  let clearCacheJobsElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!clearCacheJobs || !clearCacheJobs.length){
    if(!renderComponent){
        clearCacheJobsElements.push(
        <tr>
          <td colSpan="7" className="text-center">
              <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
              </Spinner>
          </td>
        </tr>
      );
    }else{
        clearCacheJobsElements.push(
          <tr>
            <td colSpan="7" className="text-center">
                <CardTitle tag="h4">No Records Found</CardTitle>
            </td>
          </tr>
    );
    }
  }else{
     let i = 1;
      clearCacheJobs.forEach(function(jobsDetails) {
          let className = '';
          if(!jobsDetails.status){
            className = 'table-warning';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          clearCacheJobsElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
                
               
               <td className="text-left">
                   {jobsDetails.project_id?jobsDetails.project_id.publishers_id.name:''}
               </td>
               <td className="text-left">
                   {jobsDetails.project_id?jobsDetails.project_id.name:''}
               </td>
               
               <td className="text-left">
                   {jobsDetails.event_type} <br/>
               </td>
               <td className="text-left">
                   {jobsDetails.job_data ? getCommands(jobsDetails.job_data) 
                   
                   :"" }
               </td>
               {/* <td className="text-left">
                   {contractDetails.plan_id?contractDetails.plan_id.plan_group_id.name:""} - {contractDetails.plan_id?contractDetails.plan_id.type:""}
               </td> */}
               {/* <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{contractDetails.start_date}</Moment>
               </td> */}
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{jobsDetails.scheduled_time}</Moment>
               </td>
               <td className="text-left">
                   {jobsDetails.created_by}
               </td>
            </tr>
        )
        if(i==clearCacheJobs.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==clearCacheJobs.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchJobs();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const searchJobs = () => {
    setActivePage(1);
    console.log("formdetails:",formdetails);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherId('');
    setPublisher('');
    setProjectId('');
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      project_id:'',
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }



    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <Row>
                    <Col>
                    <div style={{'float':'right'}}>
                    <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/cache/clean") }
                  >Create Cache Clean Job
                </Button>
                    </div>
                    </Col>
                  </Row>
                <CardTitle tag="h4">
                   Cache Clean  Dashboard
                </CardTitle>
              <Form id="cacheCleanJobFilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>
                  <Col md="4">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>
                  {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Group.."
                    name = "plan_group_id"
                    id="plan_group_id"
                    options={planGroupOptions}
                    value={groupName}
                    onChange={changeGroupName}
                  />

                  </Col> */}
                  {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Type .."
                    name = "type"
                    id="type"
                    options={planTypeArray}
                    value={planType}
                    onChange={changePlanType}
                  />
                  </Col> */}
                  {/* </Row>
                  <Row> */}

                  {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={[{value:"1",label:"Active"},{value:"0",label:"Grace"},{value:"-2",label:"Expired"},{value:"-1",label:"Suspended"}]}
                    value={status}
                    onChange={changePlanStatus}
                  />
                  </Col> */}
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>               
                <hr></hr> 
                  {totalClearCacheJobs > 0 ?
                      <Row>
                        <Col md="6">
                          <div style={{float:'right',margin:'5px'}}>
                            <b>{firstRecord} - {lastRecord} of {totalClearCacheJobs}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{float:'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalClearCacheJobs}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                      </Row>               
                  : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th> 
                      <th className="text-left">Event Type</th> 
                      <th className="text-left">JobData</th> 
                      <th className="text-left">Triggered At</th> 
                      {/* <th className="text-left">Status</th>   */}
                      <th className="text-left">Created By</th>
                      </tr>
                    </thead>
                    <tbody>{clearCacheJobsElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default CacheClean;
