import React, { useEffect, useState,useContext } from "react";
import appBuildService from "../../../services/api/app-build-job-service";
import formValidations from "../../../variables/form-validations";
import '../settings.css';
import UserContext from "../../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import appService from "../../../services/api/app-service";

const AddUser = (props)=> {

  const projectId = props.projectId;
  const context = useContext(UserContext);
  
  const [formdetails, setFormDetails] = useState({projectId:projectId,email:'', firstName:'', lastName : ''});
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});

  const [fieldStates ,setFieldStates] = useState([]);

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.name]: ( event.target.value ).trim(),
    });
    fieldStates['error_'+event.target.name] = "has-success";
    fieldStates['error_message_'+event.target.name] = "";
    setFieldStates({
        ...fieldStates,
        fieldStates
      });
  }

  const handleBlur = (event) => {
    
  }

  const validateFields = () => {
    let validations = true;
    if(formdetails.email===''){
      fieldStates["error_email"] = "has-danger";
      fieldStates["error_message_email"] = "email is required";
      validations = false;
    }else{
        if(!formValidations.verifyEmail(formdetails.email)){
          fieldStates["error_email"] = "has-danger";
          fieldStates["error_message_email"] = "Please enter a valid email.";
          validations = false;
      }
    }
    if(formdetails.firstName.trim()===''){
      fieldStates["error_firstName"] = "has-danger";
      fieldStates["error_message_firstName"] = "First Name is required";
      validations = false;
    }
    if(formdetails.lastName.trim()===''){
      fieldStates["error_lastName"] = "has-danger";
      fieldStates["error_message_lastName"] = "Last Name is required";
      validations = false;
    }
    setFieldStates({
        ...fieldStates,
        fieldStates
    });
    return validations;
}
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 
  const validateForm = async () => {
    let isValid = validateFields();
    console.log("InvitedUser",formdetails);
    if(isValid){
      setShowLoader(true);
      setShowSaveButton(false);
      let addUser = await appBuildService.addUserToAppleAccount(formdetails);
      if(!addUser.status && addUser.error.error_code === 403){
          context.setIsAuthorized(context);
      }
      else if(addUser.status){
        if(addUser.data.invited){
          setTimeout(()=>{
            setShowLoader(false)
            setAlert({show:true,type:'success',message:'Invitation was sent Successfully'});
          },1000);
          
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
  
          setTimeout(()=>{
            props.setShowAddUser(false);
          },3000);
        }
        if(addUser.data.exists){
          setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Invitation already sent or email is already associated .'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },8000);
        }
        
      }else{
        if(addUser.error)
        {
          setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:addUser.error.message});
        },1000); 
      }else
      {
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Invitation could not be sent, Please try again.'});
        },1000);
      }
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },8000);
      }
    }
  }
  
    return (
      <div className="content mt-30">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  {/* <CardTitle tag="h4" className="heding_tab_h4">Invite User to {props.projectName}</CardTitle> */}
                  
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="inviteUser" method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                    <div>
                      <Label className="lblNme">First Name</Label>
                      <div>
                      <Input
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="First Name"
                        name = "firstName"
                        id="firstName"
                        onChange={handleChange }
                      />
                      <InputGroup className={fieldStates["error_firstName"]}>

                      {fieldStates["error_firstName"] === "has-danger" ? (
                            <label className="error">
                              {fieldStates["error_message_firstName"]}
                            </label>
                          ) : null} 
                    </InputGroup>
                      </div>
                  </div>
                    </Col>
                    <Col md="6">
                    <div>
                      <Label className="lblNme">Last Name</Label>
                      <div>
                      <Input
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Last Name"
                        name = "lastName"
                        id="lastName"
                        onChange={handleChange }
                      />
                      <InputGroup className={fieldStates["error_lastName"]}>

                      {fieldStates["error_lastName"] === "has-danger" ? (
                            <label className="error">
                              {fieldStates["error_message_lastName"]}
                            </label>
                          ) : null}  
                    </InputGroup>
                      </div>
                  </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                    <div>
                      <Label className="lblNme">Email</Label>
                      <div>
                      <InputGroup className={fieldStates["error_email"]}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText  className="emailIcon">
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email" name="email" placeholder="Email..." type="text"  onChange={handleChange} onBlur={handleBlur}/>
                      {fieldStates["error_email"] === "has-danger" ? (
                            <label className="error">
                              {fieldStates["error_message_email"]}
                            </label>
                          ) : null}   
                    </InputGroup>
                      </div>
                  </div>
                    </Col>
                  </Row>
                  <div className="dflLft">
                    {showSaveButton?
                    <>
                    <Button
                      className="btn-smb btnBlue"
                      type="button"
                      onClick={validateForm}
                    >
                      Invite User
                    </Button>
                  <Button 
                    className="btn-smb btnBlk"
                    type="button"
                    onClick={(event)=>  props.onCancel() }
                  >
                    cancel
                  </Button></>
                    :''}
                  </div>
                  {/* {showSaveButton?<Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={(event)=>  props.setShowAddUser(false) }
                  >
                    Back
                  </Button>:''} */}
                  <div className="dflLft">
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                  </div>
                  </Form>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default AddUser;
