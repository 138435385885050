import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert, Container, Card } from 'reactstrap';
import axios from 'axios';
import Switch from "react-bootstrap-switch";

const WebhookConfigForm = ({ projectId }) => {
  const [enabled, setEnabled] = useState(false); // Default value set to false
  const [domain, setDomain] = useState('');
  const [secret, setSecret] = useState('');
  const [webhookURL, setWebhookURL] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchWebhookDetails = async () => {
      try {
        const response = await axios.get(`/api/qtwebhook/get-quintype-webhook?projectId=${projectId}`);
        if(response.status == 200) {
          let result = response.data;
          console.log("Result:",result);
          if(result.status && result.data) {
            const { status, domain, secret, webhookURL } = result.data;

            setEnabled(status); 
            setDomain(domain || '');
            setSecret(secret || '');
            setWebhookURL(webhookURL || '');
          }else {
            setEnabled(false); 
            setDomain('');
            setSecret('');
            setWebhookURL('');
          }
        }
      } catch (err) {
        setError('Error fetching webhook details. Please try again.');
      }
    };

    fetchWebhookDetails();
  }, [projectId]);

  const validateDomain = (domain) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(domain);
  };

  const handleEnableToggle = () => {
    setEnabled(!enabled);
  };

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const handleGenerateSecret = async () => {
    if (!validateDomain(domain)) {
      setError('Please enter a valid domain URL.');
      return;
    }

    try {
      const response = await axios.post('/api/qtwebhook/update-webhook', { project_id : projectId ,website_domain : domain, status : enabled, secret : secret });
      if(response.status == 200) {
        let result = response.data;
        if(result.status && result.data) {
          const { status, domain, secret, webhookURL } = result.data;
          setSecret(secret);
          setWebhookURL(webhookURL);
          setError('');
        }
      }

    } catch (err) {
      setError('Error generating secret. Please try again.');
    }
  };

  return (
    <div className="content py-5">
      <Container>
        <Card className="p-4 shadow">
          <h3 className='text-center'>Quintype Webhook</h3>
          <Form>
            <FormGroup className="mb-4">
              <Label for="enabled">Enable Webhook</Label>
              <div className="mt-2">
                <Switch
                  checked={enabled}
                  onChange={handleEnableToggle}
                  id="enabled"
                  // disabled={!enabled} // Disable if the webhook is not enabled
                />
              </div>
            </FormGroup>
            <FormGroup className="mb-4">
              <Label for="domain">Website Domain</Label>
              <Input
                type="text"
                name="domain"
                id="domain"
                placeholder="Enter your website domain"
                value={domain}
                onChange={handleDomainChange}
                invalid={!!error}
                style={{ width: '300px' }}
                disabled={enabled} // Disable input if the webhook is enabled
              />
            </FormGroup>

            {!enabled && (
              <Button onClick={handleGenerateSecret} color="primary" className="mb-3">Save</Button>
            )}

            {error && <Alert color="danger">{error}</Alert>}
            {secret && (
              <div>
                <h5>Generated Secret: {secret}</h5>
                <h5>Webhook URL: {webhookURL}</h5>
              </div>
            )}
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default WebhookConfigForm;
