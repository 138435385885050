import React, { useEffect, useState, useContext } from "react";
import ConfigVerticalMenu from "./ConfigVerticalMenu";
import customizationsService from "../../services/api/customizations-service";
import contentService from "../../services/api/content-service";
import ConfigForm from "./ConfigForm";
import {useHistory} from "react-router-dom";
import formValidations from "../../variables/form-validations";
import Projects from "../projects/Projects";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Spinner,
  Alert,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import UserContext from "../../UserContext";



const Customizations = (props)=> {
    const projectId = new URLSearchParams(props.location.search).get('projectid');
    const context = useContext(UserContext);
    const [horizontalTab, setHorizontalTab] = useState('');
    const [configSchema, setConfigSchema]   = useState(false);
    const [currentTabConfig, setCurrentTabConfig]   = useState(false);
    const [savedConfig, setSavedConfig]   = useState(false);
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [categories, setCategories] = useState([]);
    const [fieldStates,setFieldStates] = useState(false);
    const [allFieldStates,setAllFieldStates] = useState(false);
    const [formFields,setFormFields] = useState(false);
    const [currentFormFields,setCurrentFormFields] = useState(false);
    const horizontalTabContainSubCat = ['category','home'];
    const history = new useHistory();


    const urlParams = new URLSearchParams(window.location.search);
    const initialTab = urlParams.get('tab');


    useEffect(()=>{
      if(initialTab){
        setHorizontalTab(initialTab)
      }else{
        setHorizontalTab('general_settings')
      }
    },[])


    useEffect( () => {
      (async () =>{
        
        if(projectId && horizontalTab){
        let schema = await customizationsService.getConfigSchema(projectId);
        setConfigSchema(schema.data);
        if (!(schema.data.config).hasOwnProperty(horizontalTab)) {
          setHorizontalTab('general_settings')
        };

        let config = await customizationsService.getSavedConfig(projectId);
        // console.log("Saved Config:",config);
        setSavedConfig(config);
        let currentConfig = JSON.parse(config.data[horizontalTab]);
        setCurrentTabConfig(currentConfig);
        let categoryData = await contentService.getCategories(projectId);
        if(categoryData.status){
            let allCategories = categoryData.data;
            let categoryArray = [];
            if(allCategories.length){
              allCategories.forEach(function(categoryDetails) {
                categoryArray.push({value:categoryDetails.cat_guid.toString(),label:categoryDetails.name});
                if(horizontalTabContainSubCat.includes(horizontalTab) && categoryDetails.sub_categories && categoryDetails.sub_categories.length){
                  let subCategories = categoryDetails.sub_categories;
                  subCategories.forEach(function(subCategoryDetails) {
                    categoryArray.push({value:subCategoryDetails.cat_guid.toString(),label:subCategoryDetails.name});
                  })
                }
              })
              setCategories(categoryArray);
            }
        }
      }

      })();
    }, [horizontalTab]);


    //for setting form fields validations
    useEffect(()=>{
      // console.log("horizontol");
      // console.log("currentTabConfig",currentTabConfig);
      
      if(configSchema){
            let fieldStatesData = {};let formFieldsData = {};
            Object.keys(configSchema).forEach(function(key) {
              Object.keys(configSchema[key]).forEach(function(horizontolmenukey) {
                  let menuStateData = {};
                  let allFormFields = {};
                  var submenu = configSchema[key][horizontolmenukey].items;
                  Object.keys(submenu).forEach(function(submenukey) {
                    if(submenu[submenukey].items){
                      let submenufields = submenu[submenukey].items;
                      Object.keys(submenufields).forEach(function(submenufieldkey) {
                          if(submenufields[submenufieldkey].items){
                            let subheads = submenufields[submenufieldkey].items;
                            Object.keys(subheads).forEach(function(subheadkey) {
                              let elementErrorClass = "error_"+submenukey+":"+submenufieldkey+":"+subheadkey;
                              let elementErrorMessageClass =  "error_message_"+submenukey+":"+submenufieldkey+":"+subheadkey;
                              menuStateData[elementErrorClass] = 'has-success';
                              menuStateData[elementErrorMessageClass] = '';
                              allFormFields[submenukey+":"+submenufieldkey+":"+subheadkey] = subheads[subheadkey];
                              let fieldDesign = subheads[subheadkey];
                              if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                                  if(currentTabConfig[submenukey] && currentTabConfig[submenukey][submenufieldkey] && currentTabConfig[submenukey][submenufieldkey][subheadkey]){
                                    let multiInputFieldValue = currentTabConfig[submenukey][submenufieldkey][subheadkey];
                                    Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                      Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                        let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                        let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                        let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                        if(!fieldStates[multiElementErrorClass]){
                                          menuStateData[multiElementErrorClass] = 'has-success';
                                          menuStateData[multiElementErrorMessageClass] = '';
                                        }else{
                                          menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                          menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                        }
                                        allFormFields[submenukey+":"+submenufieldkey+":"+subheadkey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                      })
                                    })
                                  }
                              }
                            })

                          }else{
                            let elementErrorClass = "error_"+submenukey+":"+submenufieldkey;
                            let elementErrorMessageClass =  "error_message_"+submenukey+":"+submenufieldkey;
                            menuStateData[elementErrorClass] = 'has-success';
                            menuStateData[elementErrorMessageClass] = '';
                            allFormFields[submenukey+":"+submenufieldkey] = submenufields[submenufieldkey];
                            let fieldDesign = submenufields[submenufieldkey];
                            if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                                if(currentTabConfig[submenukey] && currentTabConfig[submenukey][submenufieldkey]){
                                  let multiInputFieldValue = currentTabConfig[submenukey][submenufieldkey];
                                  Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                    Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                      let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                      let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                      let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                      
                                      if(!fieldStates[multiElementErrorClass]){
                                        menuStateData[multiElementErrorClass] = 'has-success';
                                        menuStateData[multiElementErrorMessageClass] = '';
                                      }else{
                                        menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                        menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                      }
                                      
                                      allFormFields[submenukey+":"+submenufieldkey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                    })
                                  })
                                }
                            }
                          }
                      })
                    }else{
                        let elementErrorClass = "error_"+submenukey;
                        let elementErrorMessageClass =  "error_message_"+submenukey;
                        menuStateData[elementErrorClass] = 'has-success';
                        menuStateData[elementErrorMessageClass] = '';
                        allFormFields[submenukey] = submenu[submenukey];
                        let fieldDesign = submenu[submenukey];
                        if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                            if(currentTabConfig[submenukey]){
                              let multiInputFieldValue = currentTabConfig[submenukey];
                              Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                  let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                  let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                  let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                  if(!fieldStates[multiElementErrorClass]){
                                    menuStateData[multiElementErrorClass] = 'has-success';
                                    menuStateData[multiElementErrorMessageClass] = '';
                                  }else{
                                    menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                    menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                  }
                                  allFormFields[submenukey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                })
                              })
                            }
                        }
                    }
                  });
                  fieldStatesData[horizontolmenukey] = menuStateData;
                  formFieldsData[horizontolmenukey] = allFormFields;
              })
            })
            setFieldStates(fieldStatesData[horizontalTab]);
            setAllFieldStates(fieldStatesData);
            setFormFields(formFieldsData);
            setCurrentFormFields(formFieldsData[horizontalTab]);
      }
    },[currentTabConfig])


    // console.log(currentFormFields);
    const storeConfig = async () => {
      if(validateConfig()){
        setShowLoader(true);
        setShowSaveButton(false);
        // console.log(currentTabConfig);
        let saveConfig = await customizationsService.storeConfig(projectId,horizontalTab, currentTabConfig);
        if(!saveConfig.status && saveConfig.error.errorCode === 403){
          context.setIsAuthorized(context);
        }
        else if(saveConfig.status){
          setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'success',message:'Saved Successfully'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
        }
      }
    }

    const validateConfig = () => {
      let validated = true;
      let firstInvalidFieldId = '';
      Object.keys(currentFormFields).forEach(function(fieldId) {
        let fieldIdArray = fieldId.split(":");
        let fieldValue = '';
        if(fieldIdArray.length==5){
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]][fieldIdArray[4]];
        }else if(fieldIdArray.length==4){
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]];
        }else if(fieldIdArray.length==3){
          if(!currentTabConfig[fieldIdArray[0]]){
              currentTabConfig[fieldIdArray[0]] = {}
          }
          if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]]){
              currentTabConfig[fieldIdArray[0]][fieldIdArray[1]] ={}
          }
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]];
        }else if(fieldIdArray.length==2){
          if(!currentTabConfig[fieldIdArray[0]]) {
            currentTabConfig[fieldIdArray[0]] = {};
          }
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]];
        }else{
          fieldValue = currentTabConfig[fieldId];
        }
        if(!validateConfigField(fieldId, fieldValue, currentFormFields[fieldId],'form')){
          if(firstInvalidFieldId==''){
            firstInvalidFieldId = fieldId;
          }
          validated = false;
        }
      });
      if(!validated){
        setFieldStates({
                ...fieldStates,
                fieldStates
        });

        // console.log("firstInvalidFieldId",firstInvalidFieldId)

        // console.log(document.getElementById(firstInvalidFieldId))
        if(document.getElementById(firstInvalidFieldId)){
          document.getElementById(firstInvalidFieldId).focus();
        }
      }
      return validated;
    }
    
    const validateConfigField = (fieldId, fieldValue, fieldDesign, validationType) =>{
        let isValid = true;
        if((fieldDesign.type=='string' || fieldDesign.type=='integer' || fieldDesign.type=='url'|| fieldDesign.type=='html' || fieldDesign.type=='enum' || fieldDesign.type=='file_upload') && fieldDesign.required){
            if(fieldValue==''){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'This field is required.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'This field is required.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
        }

        if(isValid &&  fieldDesign.type=='url1'){
            if(!fieldValue){
              fieldValue = ''
            }
            if(fieldValue!='' && !formValidations.verifyUrl(fieldValue)){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'Please enter a valid url.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'Please enter a valid url.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
        }

        if(isValid && fieldDesign.type=='integer'){
          if(fieldValue!='' && !formValidations.verifyNumber(fieldValue)){
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-danger';
              fieldStates['error_message_'+fieldId] = 'Please enter a valid number.';
            }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-danger',
                  ['error_message_'+fieldId]: 'Please enter a valid number.',
                });
            }
            isValid = false;
          }else{
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-success';
              fieldStates['error_message_'+fieldId] = '';
            }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-success',
                ['error_message_'+fieldId]: '',
              });
            }
          }
        }

      if(isValid  && fieldDesign.type=='enum'){
        // console.log(fieldValue);
        // console.log(fieldDesign.type_items);
        if(!fieldValue){
            fieldValue = ''
        }
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }

      if(isValid && fieldDesign.required && fieldDesign.source=='category_list'){
        // console.log(fieldValue);
        // console.log(fieldDesign.type_items);
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }
      if(isValid && fieldDesign.type=='html' && fieldDesign.validate==="css"){
          if(fieldValue!='' && !formValidations.validateCSS(fieldValue)){
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-danger';
              fieldStates['error_message_'+fieldId] = 'Please enter a valid CSS.';
            }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-danger',
                  ['error_message_'+fieldId]: 'Please enter a valid CSS.',
                });
            }
            isValid = false;
          }else{
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-success';
              fieldStates['error_message_'+fieldId] = '';
            }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-success',
                ['error_message_'+fieldId]: '',
              });
            }
          }
        }

        return isValid;
    }

    var navItems = [];
    var tabPanes = [];
    
    Object.keys(configSchema).forEach(function(key) {
        Object.keys(configSchema[key]).forEach(function(configkey) {
        //console.log(configSchema[key][configkey]);
        navItems.push(<NavItem>
            <NavLink
              aria-expanded={
                horizontalTab === configkey
              }
              data-toggle="tab"
              href="#"
              role="tab"
              className={
                horizontalTab === configkey
                  ? "active"
                  : ""
              }
              onClick={() => {
                history.push({
                  search: `?projectid=${projectId}&tab=${configkey}`
                });
                setHorizontalTab(configkey)
                let currentConfig = JSON.parse(savedConfig.data[configkey]);
                setCurrentTabConfig(currentConfig)
                setFieldStates(allFieldStates[configkey])
                setCurrentFormFields(formFields[configkey]);
                }
              }
            >
              {configSchema[key][configkey].label}
            </NavLink>
          </NavItem>);
          var showVerticalMenu = false;
          var submenu = configSchema[key][configkey].items;
          Object.keys(submenu).forEach(function(submenukey) {
            if(submenu[submenukey].items){
              showVerticalMenu = true;
            }
            //console.log(submenu[submenukey]);
          });
//console.log(configSchema[key][configkey]);
          tabPanes.push(<TabPane tabId={configkey} role="tabpanel">
              <Row>
                  <Col md="12">
                  <div className="dflLft">
                  <Form action="" className="form-horizontal" method="POST">
                    
                  {horizontalTab === configkey ? showVerticalMenu?<ConfigVerticalMenu projectId={projectId} fieldStates={fieldStates} setFieldStates={setFieldStates} validateConfigField={validateConfigField} categories={categories} setCurrentTabConfig={setCurrentTabConfig} currentTabConfig={currentTabConfig} data={configSchema[key][configkey]} menutype={configkey} />
                  :<ConfigForm projectId={projectId} fieldStates={fieldStates} setFieldStates={setFieldStates} validateConfigField={validateConfigField} categories={categories} setCurrentTabConfig={setCurrentTabConfig} currentTabConfig={currentTabConfig} data={configSchema[key][configkey]} parent={configkey} subparent='' />:''}
                  
                  {showSaveButton?<Button
                    className="btnBlue btn-round"
                    type="button"
                    onClick={storeConfig}
                  >
                    Save {configSchema[key][configkey].label}
                  </Button>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}

                  {alert.show?<Alert variant={alert.type}>
                    {alert.message}
                  </Alert>:''}
                  

                  </Form>
                  </div>
                  </Col>
              </Row>
              

          </TabPane>);
        });
    });

    if(!projectId){
      return (
        <Projects navigate="config/customizations"></Projects>
        );
    }else{
  
    return (
      <div className="content mt-30">
          <Row>
          <Col className="col-md-12">
          <Card>
                <CardHeader>
                <CardTitle tag="h4"  className="heding_tab_h4">Customizations</CardTitle>
                <div align="right">
                  <a href ={"/config/versions?projectid="+projectId} className="btn btn-primary btn-smb " align="right">View Version</a>
                </div>
                </CardHeader>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                      {navItems}
                        
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={horizontalTab}
                  >
                    {tabPanes}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
}

export default Customizations;
