import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import moment from 'moment';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardFooter,
    CardTitle,
    CardText,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import Select from "react-select";
import './ProjectFeatures.js'
import "bootstrap/dist/css/bootstrap.min.css";
import "./Tooltip.css";
import projectsService from "../../services/api/projects-service.js";
const ProjectFeatures = (props) => {
    const [formdetails, setFormDetails] = useState({ project_id: '', features: [] });

    const [projects, setProjects] = useState(false);
    const [totalProjects, setTotalProjects] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [renderComponent, setRenderComponent] = useState(true);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const [search, setSearchDetails] = useState({ project_id: '', title: '', features: [] });
    const [reloadComponent, setReloadComponent] = useState(0);
    const records = 10;
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const statusArray = [{ "value": "all", label: "All" }, { "value": 1, label: "Active" }, { "value": 0, label: "Deleted" }];
    const [filters, setFilters] = useState([{ feature_code: 'abc' }, { feature_code: 'def' }, { feature_code: 'jkl' }]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [features, setFeatures] = useState([]);
    const [allFeatures, setAllFeatures] = useState({});
    const operators = [{ "value": true, label: "Exist" }, { "value": false, label: "Not Exist" }]
    const [filterData, setFilterData] = useState({ feature_code: '', status: '' });
    const [active, setActive] = useState(false);
    const [projectId, setProjectId] = useState('');
    const [projectNameArray, setProjectNames] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await projectsService.getFeaturesList();
            if (response.status && response.data) {
                console.log('features', response.data);
                let features = response.data;
                let tempOptions = [];
                Object.keys(features).forEach((key) => {
                    let feature = features[key];
                    tempOptions.push({
                        value: key,
                        label: feature.feature_name,
                    });
                });
                setAllFeatures(features);
                setFeatures(tempOptions);
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            const response = await projectsService.getProjectsFeaturesDetails(formdetails, activePage, records);
            if (response.status && response.data) {
                console.log('response', response.data)
                let projects = response.data.projects;
                setProjects(projects);
                if (projects && projects.length) {
                    setFirstRecord((activePage - 1) * records + 1);
                    setLastRecord((activePage - 1) * records + projects.length);
                } else {
                    setFirstRecord((activePage - 1) * records);
                    setLastRecord((activePage - 1) * records + projects.length);
                }
                setTotalProjects(response.data.count);
            }
        })()
    }, [reloadComponent]);
    useEffect(() => {
        (async () => {
            let projects = await projectsService.getPublisherProjects();
            let publisherProjects = [];
            if (projects.status) {
                let projectData = projects.data;
                if (projectData.length) {
                    projectData.forEach(function (projectDetails) {
                        let projectValue = { value: projectDetails.id, label: projectDetails.name };
                        publisherProjects.push(projectValue)
                    })
                }
                setProjectNames(publisherProjects);
            }
            // }
        })();
    }, []);

    const handleSubmit = function (e) { searchProjects(); e.preventDefault() }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const searchProjects = () => {
        setActivePage(1);
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
    }
    const resetForm = () => {
        setActivePage(1);
        setProjectId('');
        setFormDetails({
            ...formdetails,
            project_id: '',
        });
        setSearchDetails({

        });
        setReloadComponent(Math.random());
    }

    const handleChange = (event) => {
        console.log("Event:", event);
        setFormDetails({
            ...formdetails,
            [event.id]: event.value,
        });
    }
    const applyFilter = () => {
        if (filterData.feature_code && filterData.status != undefined) {
            let features = formdetails.features;
            features.push({
                [filterData.feature_code]: filterData.status
            });
            setFormDetails({
                ...formdetails,
                features: features
            });
            hideTip();
        }
    }
    const handleEnumChange = (id, value) => {
        console.log(id, value);
        console.log(filterData);
        setFilterData({
            ...filterData,
            [id]: value
        });
    }
    const handleTitleChange = (event) => {
        setFormDetails({
            ...formdetails,
            title: event.target.value,
        });
    };
    const handleStatusChange = (event) => {
        setFormDetails({
            ...formdetails,
            feature_code: event.value,
        });
    }
    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
        setFilterData({});
        setReloadComponent(Math.random());
    };
    const removeOneFilter = (index) => {
        let features = formdetails.features;
        features.splice(index, 1);
        setFormDetails({
            ...formdetails,
            features: features
        });
        setReloadComponent(Math.random());
    }

    const getCurrentFeature = (details) => {
        let currentFeature = [];
        Object.keys(allFeatures).forEach(featureCode => {
            if (details[featureCode]) {
                currentFeature.push(allFeatures[featureCode].feature_name);
            }
        });
        // console.log("currentFeature",currentFeature);
        return currentFeature;
    }
    const getNotUsedFeature = (details) => {
        let notUsedFeature = [];
        Object.keys(allFeatures).forEach(featureCode => {
            if (!details[featureCode]) {
                notUsedFeature.push(allFeatures[featureCode].feature_name);
            }
        });
        // console.log("notUsedFeature",notUsedFeature);
        return notUsedFeature;
    }
    const changeProjectId = (event) => {
        setFormDetails({
            ...formdetails,
            project_id: event.value,
        });
        setProjectId(event)
    }
    return <div className="content">
        <div className="content mt-30">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4" className="text-center ">
                                    Projects Features
                                </CardTitle>
                                {!renderComponent ?
                                    <div className="text-center">
                                        <Spinner className="text-center" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                    : null}
                                {
                                    formdetails.features && formdetails.features.length ? <>{(formdetails.features.map((details, index) =>
                                        <Label className="labelBorder">{Object.keys(details)}{formdetails.features[index][Object.keys(details)] === true ? " : Exist " : " : Not Exist "}<i onClick={() => removeOneFilter(index)} className="fa fa-remove" ></i></Label>
                                    ))}
                                        <div><Button className="btn btn-primary btn-smb" onClick={() => {
                                            setFormDetails({
                                                ...formdetails,
                                                features: []
                                            });
                                            setReloadComponent(Math.random());
                                        }}> Clear All</Button></div>
                                    </>
                                        : ''
                                }

                                <div style={{'width':'100%'}}>
                                        <Row>
                                        <Col>
                                        <div style={{'float':'left'}}>
                                        <Button className="btn btn-primary" onClick={showTip}>+ Add filter
                                        </Button>
                                        </div>
                                        </Col>
                                        </Row>
                                        {active && (
                                                <Form
                                                    id="projectsFilters"
                                                    action=""
                                                    className="form"
                                                    method="POST"
                                                    onSubmit={handleSubmit}
                                                >
                                                    <Row>
                                                        <Col md="4">
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Feature .."
                                                            name="feature_code"
                                                            id="feature_code"
                                                            // options = {features}
                                                            options={formdetails.features && formdetails.features.length ? features.filter(f => !formdetails.features.find(item => Object.keys(item)[0] === f.value)) : features}
                                                            // options={features.filter(f => formdetails.features.some(item => item.value !== f.value))}
                                                            value={features && (filterData.feature_code !== "") ? features.filter(option => option.value === filterData.feature_code) : ""}
                                                            onChange={(event) => { handleEnumChange("feature_code", event.value) }}
                                                        />
                                                        </Col>
                                                        <Col md="4">
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Operator .."
                                                            name="status"
                                                            id="status"
                                                            options={operators}
                                                            value={operators && (formdetails.status !== "") ? operators.filter(option => option.value === filterData.status) : ""}
                                                            onChange={(event) => { handleEnumChange("status", event.value) }}
                                                        />
                                                        </Col>
                                                        <Col md="4">
                                                        <div style={{'float':'left'}}>
                                                            <Button className="btn-smb" onClick={applyFilter} type="submit">
                                                                Add
                                                            </Button>
                                                            <Button
                                                                className="btn-smb btnRst"
                                                                type="button"
                                                                onClick={hideTip}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            </div>
                                                            </Col>
                                                    </Row>
                                                </Form>
                                        )}

                                    <Form id="contractfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="4">
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select Project .."
                                                    name="project_id"
                                                    id="project_id"
                                                    options={projectNameArray}
                                                    value={projectId}
                                                    onChange={changeProjectId}
                                                />
                                            </Col>
                                            <Col md="8">
                                                <div style={{'float':'left'}}>
                                                <Button
                                                    //style={{float:'left'}}
                                                    className="btn-round"
                                                    color="info"
                                                    type="submit"
                                                >
                                                    Search
                                                </Button>
                                                <Button
                                                    //style={{float:'left'}}
                                                    className="btn-round"
                                                    color="warning"
                                                    type="button"
                                                    onClick={resetForm}
                                                >
                                                    Reset
                                                </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <hr />
                                {totalProjects > 0 ?
                                    <Row>
                                        <Col md="6">
                                            <div style={{ float: 'right', margin: '5px' }}>
                                                <b>{firstRecord} - {lastRecord} of {totalProjects}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{ float: 'left' }}>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={records}
                                                    totalItemsCount={totalProjects}
                                                    pageRangeDisplayed={3}
                                                    onChange={handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                
                                : null}
                            </CardHeader>
                            <CardBody>
                                <Table class="fixed" responsive>

                                    <thead className="text-primary">
                                        <th className="text-left" >#</th>
                                        <th className="text-left">Project Name</th>
                                        <th className="text-left"  >Features Used</th>
                                        <th className="text-left" width="400px">Feature Not used</th>
                                    </thead>
                                    <tbody>
                                        {!renderComponent ?
                                            <td colSpan={6}>
                                                <Spinner className="text-center" animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </td>
                                            : null}
                                        {projects && projects.length ? projects.map((details, index) => (
                                            <tr >
                                                <td className="text-left">{(activePage - 1) * records + index + 1}</td>
                                                <td className="text-left">
                                                    {details.projects?.name}
                                                </td>
                                                <td className="text-left">
                                                    {getCurrentFeature(details).map((feature, i) => (

                                                        feature + " ,"
                                                    ))}
                                                </td>

                                                <td className="text-left">
                                                    {getNotUsedFeature(details).map((feature, i) => (

                                                        feature + " ,"
                                                    ))}
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan={6}><h4>No Record Found</h4></td></tr>

                                        }

                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    </div>

}

export default ProjectFeatures;