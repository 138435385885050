import axios from "axios";

const getGcpResources = async(formdetails, activePage, records) => {
    const response = await axios.post("/api/gcp/get-gcp-resources",{formdetails,activePage,records});
    return response.data;
}

const getTotalGcpResources = async(formdetails) => {
    const response = await axios.post("/api/gcp/get-total-gcp-resources",{formdetails});
    return response.data;
}

export default {
    getGcpResources,
    getTotalGcpResources,
}