import React, { useEffect, useState } from "react";
import ConfigForm from "./ConfigForm";
import Scrollspy from 'react-scrollspy'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";



const ConfigVerticalMenu = (props)=> {

    var activeTab = false;
    var i=0;
    Object.keys(props.data.items).forEach(function(key) {
      if(i==0){
        activeTab = key;
      }
      i++;
    });

    const [verticalTab, setVerticalTab] = useState(activeTab);

    var navItems = [];
    var tabPanes = [];
    var navItemsArray = [];
    var configSchema = props.data.items;
    var i = 0;
   
    Object.keys(configSchema).forEach(function(key) {
      navItemsArray.push(key);
        navItems.push(<NavItem>
          <NavLink
          data-toggle="tab"
          href={'#'+key}
          role="tab"
          className={
              (verticalTab === key)
              ? "active"
              : ""
          }
          onClick={() =>
              setVerticalTab(key)
          }
          >
         {configSchema[key].label}
          </NavLink>
      </NavItem>);

    tabPanes.push(<TabPane id={key} tabId={verticalTab} role="tabpanel">
      <CardTitle tag="h3">{configSchema[key].label}</CardTitle>
      <hr></hr>
      <ConfigForm templateId={props.templateId} fieldStates={props.fieldStates} setFieldStates={props.setFieldStates} validateConfigField={props.validateConfigField} categories={props.categories} setCurrentTabConfig={props.setCurrentTabConfig} currentTabConfig={props.currentTabConfig} data={configSchema[key]} subparent={props.menutype} parent={key} />
      <hr></hr>
    </TabPane>);
    i++;
  });

  const scrollUpdated = (event) =>{
    if(event){
      setVerticalTab(event.id);
    }
    
}


    return (
        <Row>
        <Col lg="2" md="2" sm="2" xs="3">
          <div className="nav-tabs-navigation verical-navs p-0">
            <div className="nav-tabs-wrapper">
              {/* <Nav
                className="flex-column nav-stacked"
                role="tablist"
                tabs
              >
                {navItems}
              </Nav> */}
              <Scrollspy className="flex-column nav-stacked scroll nav nav-tabs" items={navItemsArray} currentClassName="bg-light"  onUpdate={(event) =>scrollUpdated(event)} rootEl="#vertical-tab-content">
                  {navItems}
              </Scrollspy>
            </div>
           </div>
        </Col>

        <Col lg="10" md="10" sm="10" xs="9">
            <TabContent id="vertical-tab-content" style={{'height':'400px','overflow-y':'scroll'}} activeTab={verticalTab}>
              {tabPanes}
            </TabContent>
        </Col>
        </Row>
    );
  
}

export default ConfigVerticalMenu;
