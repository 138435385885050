import axios from "axios";

const updateCategory = async (formdata) => {
    const response = await axios.post('/api/content/update-category', formdata);
    return response.data;
}


const getCategories = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/content/get-categories', formdata);

    // console.log("input data categorie s", response.data)
    return response.data;
}

const getCategoriesList = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/content/get-categories-list', formdata);
    return response.data;
}

const reorderCategories = async (formdata) => {
    const response = await axios.post('/api/content/reorder-categories', formdata);
    return response.data;
}
const updateCategoryIcon = async (formdata) => {
    const response = await axios.post('/api/content/update-category-icon', formdata);
    return response.data;
}
const getArticles = async (projectId, formdetails, page, records) => {
    const formdata = {
        projectId: projectId,
        filters: formdetails,
        page: page,
        records: records
    }
    console.log("filter",formdata);
    const response = await axios.post('/api/content/get-articles', formdata);
    return response.data;
}

const getTotalArticles = async (projectId, formdetails) => {
    const formdata = {
        projectId: projectId,
        filters: formdetails
    }
    const response = await axios.post('/api/content/get-total-articles', formdata);
    return response.data;
}

const getDailyInsertedArticles = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    console.log("ProjectId",formdata);
    const response = await axios.post('/api/content/get-daily-inserted-articles', formdata);
    return response.data;
}

const getArticlesTypeCount = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    console.log("ProjectId",formdata);
    const response = await axios.post('/api/content/get-articles-type-count', formdata);
    return response.data;
}
const getArticlesMedia = async (formdetails, page, records) => {
    const formdata = {
        filters: formdetails,
        page: page,
        records: records
    }
    const response = await axios.post('/api/content/get-articles-media', formdata);
    return response.data;
}
const getTotalArticlesMedia = async (formdetails) => {
    const formdata = {
        filters: formdetails,
    }
    const response = await axios.post('/api/content/get-total-articles-media', formdata);
    return response.data;
}
const processArticleMedia = async (formdata) => {
    console.log(formdata);
    const response = await axios.post('/api/content/process-article-media', formdata);
    return response.data;
}

const getArticleById = async (articleId) => {
    const formdata = {
        article_id: articleId,
    }
    const response = await axios.post('/api/content/get-article-by-id', formdata);
    return response.data;
}
const getCdnUrl = async () => {
    const response = await axios.get('/api/content/get-cdn-url');
    return response.data;
}

const getwebstoryData = async(storyId) =>{
    const response = await axios.post('/api/content/get-webstory-by-id', {storyId});
    return response.data;
}
const getWPWebstories =  async(filters,page,records) =>{
    const formdata = {
        filters,
        page,
        records
    }
    const response = await axios.post('/api/content/get-wp-webstories',formdata);
    return response.data;
}
const deleteWPWebstory = async(webstoryId, projectId) =>{
    const formdata = {
        webstoryId,
        projectId
    }
    const response = await axios.post('/api/content/delete-wpwebstory',formdata);
    return response.data;
}

const restoreWPWebstory = async(webstoryId, projectId) =>{
    const formdata = {
        webstoryId,
        projectId
    }
    const response = await axios.post('/api/content/restore-wpwebstory',formdata);
    return response.data;
}
const deleteArticle = async(articleId,projectId) =>{
    const formdata = {
        articleId : articleId,
        projectId : projectId
    }
    const response = await axios.post('/api/content/delete-article',formdata);
    return response.data;
}
const  resyncArticles= async (articles) => {
    let formdata = {
        articles
    }
    const response = await axios.post('/api/content/resync-articles', formdata);
    return response.data;
}
const createCategory = async (formdata) => {
    const response = await axios.post('/api/content/create-category',formdata);
    return  response.data;
}
const unsyncArticles = async (formdata) => {
    const response = await axios.post('/api/content/unsync-articles',formdata);
    return response.data;
}

const getArticlesVideoMedia = async (formdetails, page, records) => {
    const formdata = {
        filters: formdetails,
        page: page,
        records: records
    }
    const response = await axios.post('/api/content/get-articles-video-media', formdata);
    return response.data;
}
const getTotalArticlesVideoMedia = async (formdetails) => {
    const formdata = {
        filters: formdetails,
    }
    const response = await axios.post('/api/content/get-total-articles-video-media', formdata);
    return response.data;
}

const getArticlesAudioMedia = async (formdetails, page, records) => {
    const formdata = {
        filters: formdetails,
        page: page,
        records: records
    }
    const response = await axios.post('/api/content/get-articles-audio-media', formdata);
    return response.data;
}
const getTotalArticlesAudioMedia = async (formdetails) => {
    const formdata = {
        filters: formdetails,
    }
    const response = await axios.post('/api/content/get-total-articles-audio-media', formdata);
    return response.data;
}

const getCategoriesHierarchy = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/content/get-categories-hierarchy', formdata);
    return response.data;
}

export default {
    getCategories,
    updateCategory,
    reorderCategories,
    updateCategoryIcon,
    getArticles,
    getTotalArticles,
    getDailyInsertedArticles,
    getArticlesTypeCount,
    getArticlesMedia,
    getTotalArticlesMedia,
    processArticleMedia,
    getArticleById,
    getCdnUrl,
    getwebstoryData,
    getWPWebstories,
    deleteWPWebstory,
    restoreWPWebstory,
    deleteArticle,
    resyncArticles,
    createCategory,
    unsyncArticles,
    getArticlesVideoMedia,
    getTotalArticlesVideoMedia,
    getArticlesAudioMedia,
    getTotalArticlesAudioMedia,
    getCategoriesList,
    getCategoriesHierarchy,
}