import axios from "axios";

const createProject = async(formdata) =>{
    const response = await axios.post('/api/projects/create-project',formdata);
    return response.data;
}

const saveProjectSettings = async(formdata) =>{
    const response = await axios.post('/api/projects/save-project-settings',formdata);
    return response.data;
}

const changeProjectTemplates = async(formdata) => {
    const response = await axios.post('/api/projects/change-project-template',formdata);
    return response.data;
}

const changeProjectAPPTemplates = async(formdata) => {
    const response = await axios.post('/api/projects/change-project-app-templates',formdata);
    return response.data;
}
const saveProjectJwplayer = async(formdata)=>{

    
    const response = await axios.post('/api/projects/save-project-jwplayer',formdata)

    return response.data;
}

const getAllProjects = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-all-projects',formdata);
    return response.data;
}

const getPublisherProjects = async(publisherId) =>{
    const formdata = {
        publisherId : publisherId
    }
    const response = await axios.post('/api/projects/get-publisher-projects',formdata);
    return response.data;
}

const getPublisherMultiProjects = async(projectId)=>{
    const formdata ={
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-publisher-multi-projects',formdata);
    return response.data;
}
const getTotalProjects = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/projects/get-total-projects',formdata);
    return response.data;
}
const getTemplates = async() =>{
    const response = await axios.post('/api/projects/get-templates');
    return response.data;
}

const getProjectDetails = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/get-project-details',formdata);
    return response.data;
}

const getJwplayerDetails = async(projectId)=>{
    try {
        console.log("getJwplayerDeatils project-service",projectId)
    const formdata = {
        projectId : projectId
    }

    const response = await axios.post('/api/projects/get-jwplayer-details',formdata)
    console.log("response is : : :",response)
    return response.data;
    } catch (error) {
        console.log("error inside function",error);
    }
    
}

const getProjectDetailsBySlug = async(projectSlug) =>{
    const formdata = {
        projectSlug : projectSlug,
    }
    const response = await axios.post('/api/projects/get-project-details-byslug',formdata);
    return response.data;
}
const getProjectBySlug = async(projectSlug) =>{
    const formdata = {
        projectSlug : projectSlug,
    }
    const response = await axios.post('/api/projects/get-project-byslug',formdata);
    return response.data;
}

const getAllPublishers = async() =>{
    const response = await axios.post('/api/projects/get-all-publishers');
    return response.data;
}

const getProjectDomains = async(projectId) => {
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/get-project-domains',formdata);
    return response.data;
}

const createProjectDomain = async(formdata) => {
    const response = await axios.post('/api/projects/create-project-domain',formdata);
    return response.data;
}
const editProjectDomain = async(formdata) => {
    const response = await axios.post('/api/projects/edit-project-domain',formdata);
    return response.data;
}

const generateSsl = async(formdata) => {
    const response = await axios.post('/api/projects/generate-ssl',formdata);
    return response.data;
}

const validateSsl = async(domainId,projectId) => {
    const formdata = {
        domainId : domainId,
        projectId
    }
    const response = await axios.post('/api/projects/validate-ssl',formdata);
    return response.data;
}

const deployCdn = async(projectId,domainId) => {
    const formdata = {
        projectId: projectId,
        domainId : domainId
    }
    const response = await axios.post('/api/projects/deploy-cdn',formdata);
    return response.data;
}

const getCdnDetails = async(distributionId) => {
    const formdata = {
        distributionId : distributionId
    }
    const response = await axios.post('/api/projects/get-cdn-details',formdata);
    return response.data;
}

const getAcmDetails = async(domainId) => {
    const formdata = {
        domainId : domainId
    }
    const response = await axios.post('/api/projects/get-acm-details',formdata);
    return response.data;
}

const getPublishers = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-publishers',formdata);
    return response.data;
}

const getTotalPublishers = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/projects/get-total-publishers',formdata);
    return response.data;
}

const getAwsResources = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-aws-resources',formdata);
    return response.data;
}

const getTotalAwsResources = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/projects/get-total-aws-resources',formdata);
    return response.data;
}

const getProjectUsers = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/get-project-users',formdata);
    return response.data;
}

const getProjectInvitedUsers = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/get-project-invited-users',formdata);
    return response.data;
}

const suspendUserFromProject = async(projectId,userId) =>{
    const formdata = {
        projectId : projectId,
        userId : userId
    }
    const response = await axios.post('/api/projects/suspend-project-user',formdata);
    return response.data;
}

const restoreUserToProject = async(projectId,userId) =>{
    const formdata = {
        projectId : projectId,
        userId : userId
    }
    const response = await axios.post('/api/projects/restore-project-user',formdata);
    return response.data;
}

const resendInviteToProject = async(inviteId) =>{
    const formdata = {
        inviteId : inviteId
    }
    const response = await axios.post('/api/projects/resend-invite',formdata);
    return response.data;
}
const updateProjectOwner = async(projectId, userInfo) =>{
    const formdata = {
        projectId : projectId,
        userInfo : userInfo
    }
    const response = await axios.post('/api/projects/update-project-owner',formdata);
    return response.data;
}
const suspendProject = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/suspend-project',formdata);
    return response.data;
}

const restoreProject = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/restore-project',formdata);
    return response.data;
}
const getProjectStorageMedia = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/projects/get-project-storage-media',formdata);
    return response.data;
}



const getPublishersProjectByEmail = async(publisheremail) =>{
    const formdata={
        publisheremail:publisheremail
    }
    const result=await axios.post("/api/projects/get-publishers-project-byemail",formdata);
    return result.data;
}
const getProjectTemplates =async (projectId) =>{
    const formdata={
        projectId:projectId
    }
    const response=await axios.post('/api/projects/get-project-templates',formdata)
    return response.data;
}


const getLoggedInUser = async() =>{
    const response = await axios.post('/api/projects/get-user-details');
    return response.data;
}
const getDailyStats =async (formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-daily-stats',formdata);
    return response.data;
}

const getTotalDailyStats = async (formdata) =>{
    const response = await axios.post('/api/projects/get-total-daily-stats',formdata);
    return response.data;
}
const getMonthlyStats =async (formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-monthly-stats',formdata);
    return response.data;
}

const getTotalMonthlyStats = async (formdata) =>{
    const response = await axios.post('/api/projects/get-total-monthly-stats',formdata);
    return response.data;
}
const getProjectFeatureUsage = async (projectId) =>{
    const formdata={
        projectId:projectId
    }
    const response=await axios.post('/api/projects/get-project-feature-usage',formdata)
    return response.data;
}

const getProjectAppsGeneralSettings = async (projectId) =>{
    const formdata={
        projectId:projectId
    }
    const response=await axios.post('/api/projects/get-project-apps-general-settings',formdata)
    return response.data;
}

const saveProjectAppsGeneralSettings = async(formdata) =>{
    const response = await axios.post('/api/projects/save-project-apps-general-settings',formdata);
    return response.data;
}
const getAndroidDistributions = async(formdata) =>{
    const response = await axios.get('/api/projects/get-android-distributions',formdata);
    return response.data;
}
const savePublishersPhoneDetails = async(formdata) =>{
    const response = await axios.post('/api/projects/save-publishers-phone-details',formdata);
    return response.data;
}

const uploadFile = async (projectId,fieldId,imageData ,contentType) => {
    let data = {projectId:projectId,fieldId:fieldId,imageData:imageData , contentType}
    const response = await axios.post('/api/projects/upload-file',data);
    return response.data;
}
const getProjectsFeaturesDetails = async (formdetails, page, records) => {
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-projects-feature-details',formdata);
    return response.data;
}
const getFeaturesList = async () => {
    const response = await axios.get('/api/projects/get-features-list');
    return response.data;
}

const getConfigFeaturesList = async(formdata)=>{
    const response = await axios.post("/api/projects/get-config-feature-list",formdata);
    return response.data;
}

const getConfigFeaturesOtpionsArray = async()=>{
    const response = await axios.post("/api/projects/get-config-feature-options-array");
    return response.data;
}

const getJwplayerStatus = async(jwKey,siteId)=>{
    try {
        const response = await axios.post("/api/projects/jwplayer-key-status",{jwKey:jwKey,siteId:siteId});
        return response;
    } catch (error) {
        throw error;
    }
}

const getJwplayerKeys = async(jwKey,siteId)=>{
    try {
        const response = await axios.post("/api/projects/all-jwplayer-key-data",{jwKey:jwKey,siteId:siteId});
        return response;
    } catch (error) {
        throw error
    }
}

const getAllActiveJwplayerProjects = async(Ids)=>{
    try {
        const response = await axios.post('/api/projects/all-active-jwplayer-projects',{ids:Ids});
        return response;
    } catch (error) {
        throw error;
    }
}
const getTotalProjectsForJwplayer = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/projects/get-total-projects-for-jwplayer',formdata);
    return response.data;
}

const updateCloudProvider = async(formdetails) => {
    const response = await axios.post('/api/projects/update-cloud-provider',formdetails);
    return response.data;
}

export  default {
    createProject,
    saveProjectSettings,
    changeProjectTemplates,
    getAllProjects,
    getPublisherProjects,
    getTotalProjects,
    getTemplates,
    getProjectDetails,
    getProjectDetailsBySlug,
    getProjectBySlug,
    getAllPublishers,
    getProjectDomains,
    createProjectDomain,
    editProjectDomain,
    generateSsl,
    validateSsl,
    deployCdn,
    getCdnDetails,
    getAcmDetails,
    getPublishers,
    getTotalPublishers,
    savePublishersPhoneDetails,
    getAwsResources,
    getTotalAwsResources,
    getProjectUsers,
    suspendUserFromProject,
    restoreUserToProject,
    getProjectInvitedUsers,
    resendInviteToProject,
    updateProjectOwner,
    suspendProject,
    restoreProject,
    getProjectStorageMedia,
    getPublishersProjectByEmail,
    getProjectTemplates,
    getLoggedInUser,
    getDailyStats,
    getTotalDailyStats,
    getMonthlyStats,
    getTotalMonthlyStats,
    getProjectFeatureUsage,
    getProjectAppsGeneralSettings,
    saveProjectAppsGeneralSettings,
    getAndroidDistributions,
    uploadFile,
    getProjectsFeaturesDetails,
    getFeaturesList,
    getConfigFeaturesList,
    getConfigFeaturesOtpionsArray,
    changeProjectAPPTemplates,
    saveProjectJwplayer,
    getJwplayerDetails,
    getJwplayerStatus,
    getJwplayerKeys,
    getAllActiveJwplayerProjects,
    getTotalProjectsForJwplayer, 
    getPublisherMultiProjects,
    updateCloudProvider,
}