import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import templateService from "../../../services/api/template-service";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UserContext from "../../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const PlanGroupTemplates = (props) => {

    const plan_group_id=new URLSearchParams(props.location.search).get("plan_group_id");
    if(plan_group_id=="")
    {
        history.push("/saas/plan-groups");
    }
    const [templates, setTemplates] = useState([]);
    const [componentRendered, setComponentRendered] = useState(false);
    const context = useContext(UserContext);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const history = new useHistory();
    const [templateLabel, setTemplateLabel] = useState("");
    const [templateOptions, setTemplateOptions] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState({
        plan_group_id: plan_group_id,
        template_id: "",
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [modified, setModified] = useState(false);
    const [planGroupTemplates, setPlanGroupTemplates] = useState([]);
    const [planGroupDetail,setPlanGroupDetail]=useState({plan_group_id,name:""});
    const [templateState,setTemplateState]=useState("");
    const [templateError,setTemplateError]=useState("");
    useEffect(() => {

        (async () => {
            const response = await templateService.getPlanGroupTemplates(plan_group_id);
            // console.log(response.data);
            if (response.status && response.data) {

                let tempResData = [];

                console.log("Response", response.data);
               // setPlanGroupDetail({...planGroupDetail,name:"Basic"});
                 response.data.forEach((element) => {
                    tempResData.push({
                        id: element.template_id._id,
                        name: element.template_id.name,
                    });
                });
                
                setPlanGroupTemplates(tempResData);
    
                const templates = await templateService.getTemplates();
                let tempTemplateOptions = [];
                let formattedTemplate = {};
                templates.data.forEach((template) => {
                    formattedTemplate = {};
                    if (template.status === true) {
                        formattedTemplate["label"] = template.name;
                        formattedTemplate["value"] = template._id;
                        if (tempResData.find((element) =>element.name.toLowerCase() ===template.name.toLowerCase())) {
                            //console.log(feature.name);
                        } else {
                            // console.log(feature.name);
                            tempTemplateOptions.push(formattedTemplate);
                        }
                    }
                });
                setTemplateOptions(tempTemplateOptions);





                setTemplates(tempResData);
               
            }
             setComponentRendered(true);
        })();
    }, [reloadComponent]);
    
    useEffect(() => {

        (async () => {
            const response = await saasService.getPlanGroupDetails(plan_group_id);
            if(response.status && response.data)
            {
            setPlanGroupDetail({...planGroupDetail,name:response.data.name});
                 
            }
        })();
    }, []);






    const handleRemoveTemplateSwitch = async (plan_group_id,template_id) => {
            const response = await templateService.removePlanGroupTemplate(plan_group_id,template_id);
            if(!response.status && response.error.errorCode === 403) {
                setShowPopup({alert:null});
                context.setIsAuthorized(context);
             }    
            else if (response.status && response.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Template Removed!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Template Removed successfully.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);


                setReloadComponent(Math.random());
            }else
            {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Template could not Publish!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Template could not remove.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);
                setReloadComponent(Math.random());
            }
        
    };
    const confirmationPopup = (plan_group_id,template_id) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to remove this template ?"
                    onConfirm={() => {
                        handleRemoveTemplateSwitch(planGroupDetail.plan_group_id,template_id);
                        hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Remove it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to Remove Template!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
    

    const getTemplateTable = () => {

        let templateElements = [];
        if (!templates || !templates.length) {
            if (!componentRendered) {
                templateElements.push(
                    <tr>
                        <td colSpan="3" className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </td>
                    </tr>
                );
            } else {
                templateElements.push(
                    <tr>
                        <td colSpan="3" className="text-center">
                            <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                    </tr>
                );
            }
        } else {
            let i = 1;
            templates.forEach(function (template) {
                let className = "";

                // if (!template) {
                //     className = "table-danger";
                // }
                templateElements.push(
                    <tr className={className}>
                        <td className="text-left">{i}</td>
                        <td className="text-left">{template.name?template.name:""}</td>

                         <td className="text-left">
                            
                            <Button
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={()=>confirmationPopup(plan_group_id,template.id)
                                }>
                                Remove
                            </Button>
                            
                            
                        </td>
                    </tr>
                );

                i++;

            });
        }
        return templateElements;
    };
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleTemplateChange = (event) => {
        setTemplateLabel(event.name);
        
        setCurrentTemplate({
            ...currentTemplate,
            template_id: event.value,
        });
        setTemplateState("");
    };
    const validateTemplate=()=>{
        if(currentTemplate.template_id==="")
        {
            setTemplateState("has-danger");
            setTemplateError(<div className="text-danger">Template is required</div>);
            return false;
        }
        return true;
    }
    const addPlanGroupTemplate = async () => {
        const isTemplateSelected=validateTemplate();
        if(isTemplateSelected)
        {
        const response = await templateService.addPlanGroupTemplate(currentTemplate);
        if(!response.status && response.error.errorCode === 403) {
           
            context.setIsAuthorized(context);
         }
        else if(response.status && response.data)
        {
            setReloadComponent(Math.random());
            setTemplateLabel("");
            
            setCurrentTemplate({
                ...currentTemplate,
                template_id: "",
            });
        }

    }
    }


    return (
        <div className="content">
            {showPopup.alert}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Add Template to {planGroupDetail.name?planGroupDetail.name:""}  Plan Group </CardTitle>
                                
                                
                            </CardHeader>
                            <CardBody>

                            <Form
                                            className="form-horizontol"
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Label sm="2">Template</Label>
                                                <Col sm="7">
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Template"
                                                        name="template"
                                                        id="template"
                                                        value={templateLabel}
                                                        options={templateOptions}
                                                        onChange={(value) =>
                                                            handleTemplateChange(value)
                                                        }
                                                    />
                                                    {templateState === "has-danger" ? (
                                                    <label className="error">
                                                        {templateError}
                                                    </label>
                                                ) : null}
                                                </Col>
                                            </Row>
                                           

                                            
                                            {showSaveButton ? (
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    onClick={addPlanGroupTemplate}
                                                >
                                                    Add
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                            {alert.show ? (
                                                <Alert color={alert.type}>
                                                    {alert.message}
                                                </Alert>
                                            ) : (
                                                ""
                                            )}

                                            {showLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                ""
                                            )}
                                        </Form><hr/>


                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">
                                                Template Name
                                            </th>
                                            {/* <th className="text-left">
                                                Created At
                                            </th>
                                            <th className="text-left">
                                                Updated At
                                            </th>
                                            <th className="text-left">
                                                Last Modified By
                                            </th> */}
                                                                                 
                                            <th className="text-left">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!templates || !templates.length ? (
                                            !componentRendered ? (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="text-center">
                                                        <Spinner
                                                            animation="border"
                                                            role="status">
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="text-center">
                                                        <CardTitle tag="h4">
                                                            No Template Found
                                                        </CardTitle>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            getTemplateTable()
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PlanGroupTemplates;
