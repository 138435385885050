import React, { useEffect, useState, useContext } from "react";
import appBuildService from "../../services/api/app-build-job-service";
import {useHistory} from "react-router-dom";
import AddUser from './component/AddUser';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext";
// import './settings.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const Users = (props)=> {
  const history = useHistory();
  const context = useContext(UserContext);
  let projectId = new URLSearchParams(props.location.search).get('projectid');
  if(projectId==''){
    history.push("/project/allprojects");
  }
  const [users, setUsers] = useState(false);
  const [addUser, setShowAddUser] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  let projectDetails = {
      name:"Test Name"
  }
  useEffect( () => {
    (async () =>{
        let usersData = await appBuildService.getAppleAccountUsers(projectId);
          // if(!usersData.status && usersData.error.errorCode === 403){
          //   context.setIsAuthorized(context);
          // }
          if(usersData.status && usersData.data){
                setUsers(usersData.data);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    const addUserAsIOSAppTesterPopup = (email) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => addUserAsIOSAppTester(email)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Invite it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to invite this user!
          </ReactBSAlert>
        ),
      });
    }

    const restoreUserPopup = (userId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreUser(userId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to provide access to this user again!
          </ReactBSAlert>
        ),
      });
    }

    const addUserAsIOSAppTester = async (email) => {
      let susUser = await appBuildService.addUserAsIOSAppTester({projectId,email});
      if(!susUser.status && susUser.error.errorCode === 403){
        setShowPopup({alert:null});
        context.setIsAuthorized(context);
      }
      else if(susUser.status && susUser.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Suspended/Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User was invited successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreUser = async (userId) => {
      let resUser = await appBuildService.restoreUserToProject(projectId,userId);
      if(!resUser.status && resUser.error.errorCode === 403){
        setShowPopup({alert:null});
        context.setIsAuthorized(context);
      }
      else if(resUser.status && resUser.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User was restored to the project successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              User could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  
 

  let userElements = [];
  if(!users || !users.length){
    if(!renderComponent){
        userElements.push(
        <td colSpan="5" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        userElements.push(
        <td colSpan="5" className="text-center">
            <CardTitle tag="h4">No user found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      users.forEach(function(userDetails) {
        let className = '';
        if(userDetails.status==0){
            className = 'table-danger';
        }
        let displayRoles = [];
        
        let actions = true;
        userElements.push(
            <tr className={className}>
               <td className="text-left">{i}</td>
               <td className="text-left">
                   {userDetails.attributes.firstName+" "+userDetails.attributes.lastName}
               </td>
               <td className="text-left">
                   {userDetails.attributes.username?userDetails.attributes.username:userDetails.attributes.email}
               </td>
               {/* <td className="text-left">
               {displayRoles}
               </td> */}
               <td className="text-left">
                   {userDetails.attributes.expirationDate?'Not Accepted':'-'}
               </td>
               {!userDetails.attributes.expirationDate?
               <td className="text-center">
               <a title="Add user as Tester" onClick={(event)=>  addUserAsIOSAppTesterPopup(userDetails.attributes.username || userDetails.attributes.email) } href="javascript:void(0)" class="dlIcn">
               <i class="fa fa-plus" aria-hidden="true"></i>
             </a>
             
               </td>
               :
               <td className="text-left"></td>
              }
               
            </tr>
        )
        i++;
    })
  }

  if(addUser){
    return (
      <AddUser projectId={projectId} projectName={projectDetails.name} setShowAddUser={setShowAddUser}/>
    );
  }else{
    return (
      <div className="content mt-30">
         {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                <CardTitle tag="h4" className="heding_tab_h4">
                    Users associated with {projectDetails.name}
                </CardTitle>

                 <Button
                      style={{float: 'right'}}
                      className="btn-smb adUsr"
                      type="button"
                      onClick={(event)=>  setShowAddUser(true) }
                  >Invite user</Button>
                  {/* <Button
                      style={{float: 'right'}}
                      className="btn-smb inUsr"
                      type="button"
                      onClick={(event)=>  history.push("/project/invitedusers") }
                  >View Invited Users</Button> */}
                </CardHeader>
                <CardBody>
                <Table>
                    <thead className="text-primary">
                     
                      <th className="text-left">#</th>   
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        {/* <th className="text-left">Roles</th> */}
                        <th className="text-left">Status</th>
                        <th className="text-left">Action</th>
                      
                    </thead>
                    <tbody></tbody>
                  
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
}

export default Users;
