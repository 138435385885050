import axios from "axios";

const getContracts = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/contract/get-contracts',formdata);
    return response.data;
}

const getTotalContracts = async(formdata) =>{
    const response = await axios.post('/api/contract/get-total-contracts',formdata);
    return response.data;
}

const suspendContract = async(contractId) =>{
    const formdata = {
        contractId : contractId
    }
    const response = await axios.post('/api/contract/suspend-contract',formdata);
    return response.data;
}

const restoreContract = async(contractId) =>{
    const formdata = {
        contractId : contractId
    }
    const response = await axios.post('/api/contract/restore-contract',formdata);
    return response.data;
}

const createComplementaryContract = async(formdata) =>{
    const response = await axios.post('/api/contract/create-complementary-contract',formdata);
    return response.data;
}

const inviteForEnterprisePlan= async(formdata) =>{
    const response=await axios.post('/api/contract/invite-for-enterprise-plan',formdata);
    return response.data;
}

const getPlanInvitations = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/contract/get-plan-invitations',formdata);
    return response.data;
}

const getTotalPlanInvitations = async(formdata) =>{
    const response = await axios.post('/api/contract/get-total-plan-invitations',formdata);
    return response.data;
}

const getUnmappedTransactionList = async() => {
    const response = await axios.post('/api/contract/get-unmapped-transaction-list');
    return response.data;
}
export  default {
    getContracts,
    getTotalContracts,
    restoreContract,
    suspendContract,
    createComplementaryContract,
    inviteForEnterprisePlan,
    getPlanInvitations,
    getTotalPlanInvitations,
    getUnmappedTransactionList
}