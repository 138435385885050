import React, { useEffect, useState,useContext } from "react";
import projectService from "../../services/api/projects-service";
import manageProjectService from "../../services/api/manage-project-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import Moment from "react-moment";
import Select from 'react-select';
import UserContext from "../../UserContext"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Spinner,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const Projects = (props)=> {
  const history = new useHistory(); 

  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({status:""});
  const [projects, setProjects] = useState(false);
  const [totalProjects,setTotalProjects] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({publisherids:[],publishername:'',publisheremail:'',projectname:''});
  const [allPublishers, setAllPublishers] = useState([]);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [projectsInQueue, setProjectsInQueue] = useState([]);

  const records = 10;
  const statusFilters = [
    {
      value: 0,
      label: "In Queue"
    },{
      value: 1,
      label: "Deleted"
    },{
      value: "",
      label: "All"
    }
  ]

  useEffect( () => {
    (async () =>{
      let publishers = await projectService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")"};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
          }
        })();
    }, []);


  useEffect( () => {
    (async () =>{

          console.log("searched")

            let projectsData = await manageProjectService.getProjectsDeleteQueue(formdetails,activePage,records);
            console.log(projectsData, "res data")
            // if(!projectsData.status && projectsData.error.errorCode === 403){
            // context.setIsAuthorized(context);
            // }
            if(projectsData.status && projectsData.data){
                // console.log("ProjectData",projectsData);
                setProjects(projectsData.data.projectQueue);
                setTotalProjects(projectsData.data.count);

            }
              setRenderComponent(true);
        })();
    }, [activePage,search,reloadComponent]);

    const deleteProject = async(queueElementId)=>{

      setShowPopup({
        alert: (
          <ReactBSAlert
            info
            style={{ display: "block", marginTop: "-100px" }}
            title="Please Wait!"
            confirmBtnBsStyle="info"
            btnSize="0"
            showConfirm={false}
            closeOnClickOutside={false}
          >
            while deleting the project.
          </ReactBSAlert>
        )})
      const response = await manageProjectService.deleteQueuedProject(queueElementId);

      console.log("response here", response);
      setReloadComponent(Math.random());
      if(!response.status && response.error.errorCode === 403){
        setShowPopup({alert:null});  
        context.setIsAuthorized(context);
      }    

      else if(response.status == false){
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Failed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {response.error.message}.
            </ReactBSAlert>
          )})
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Success!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project Deleted.
            </ReactBSAlert>
          )})
      }
    }

    const handleDeleteProject = async(queueElementId)=>{
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deleteProject(queueElementId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Delete this!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to Delete this project!
          </ReactBSAlert>
        ),
      });
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    }; 

  const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
  }

  const handleSubmit = function(e){  searchProjects();e.preventDefault() }

  const searchProjects = () => {
      setActivePage(1);
      setSearchDetails(formdetails);
  }

  const resetElements = () => {
    document.getElementById('searchproject').value = '';
    document.getElementById('publishername').value = '';
    document.getElementById('publisheremail').value = '';
    setFormDetails({publisherids:[],publishername:'',publisheremail:'',projectname:''});
    setSearchDetails({publisherids:[],publishername:'',publisheremail:'',projectname:''});
  }

  const addProjectToDeleteQueue = (projectId)=>{

  }

  const handleStatusFilterChange = (option) =>{
    setFormDetails({
      ...formdetails,
      status: option.value
    }) 
    // console.log(option)
  }

  const redirectProjectUrl = (e,navigate,redirectPath,projectId) =>{
    e.preventDefault()
    history.push("/"+navigate+"/"+redirectPath+"?projectid="+projectId)
  }
  
  
  let projectElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!projects || !projects.length){
    if(!renderComponent){
      projectElements.push(
        <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    projectElements.push(
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4">No Projects found.</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      projects.forEach(function(projectDetails) {
          // console.log(projectDetails)
        let className = '';
        if(i==1){
          firstRecord = (activePage-1)*records+i;
        }

          if(projectDetails.status==-1){
            className = 'table-warning';
          }
        
        projectElements.push(
            <tr id={"row_"+projectDetails._id} className={className}>
               <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left">
                   {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.project_id.name}
                 </a>
                   :
                   <a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.project_id.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.project_id.name}
                 </a>}
               </td>
               <td className="text-left">
                 
                  {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.project_id.slug}
                 </a>
                   :<a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.project_id.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.project_id.slug}
                 </a>} 
               </td>
              
               <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {projectDetails.createdAt}
                            </Moment>
               </td>
               <td className="text-left">
                  {projectDetails.added_by}
               </td>
               
               <td className="text-left">
                  {
                  projectDetails.status == 0?
                  <Button
                    style={{float: 'right','width':'145px'}}
                    className="btn-round"
                    color="danger"
                    size="sm"
                    type="button"
                    onClick= {()=>{handleDeleteProject(projectDetails._id)}}
                    >
                        Delete
                  </Button>:  projectDetails.status == 1?"Deleted": "Removed from Queue"
                
                }
                    
               </td>
            </tr>
        )
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  
    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    Manage Projects 
                </CardTitle>
                <Row>
                  <Col md="3">
                    <Select
                        className="react-select primary"
                        id="status-filter status"
                        classNamePrefix="react-select"
                        placeholder="Select Status"
                        name = "status"
                        options={statusFilters}
                        onChange={(option)=>{
                          handleStatusFilterChange(option)
                        }}
                        // value={formdetails.status}
                    />
                  </Col>
                  <Col md="3">
                    <div style={{'float':'left'}}>
                    <Button
                        className="btn-round"
                        color="info"
                        type="submit"
                        onClick={searchProjects}
                      >
                        Search
                      </Button>
                      </div>
                    </Col>
                </Row>
                <hr />
                {totalProjects > 0 ? 
                  <Row>
                    <Col md="6">
                    <div style={{float:'right',margin:'5px'}}>
                    <b>{firstRecord} - {lastRecord} of {totalProjects}</b>
                    </div>
                    </Col>
                    <Col md="6">
                    <div style={{float:'left'}}>
                        <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalProjects}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        />
                    </div>
                    </Col>
                  </Row>
                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th>   
                        <th className="text-left">Project Name</th>
                        <th className="text-left">Project Slug</th>
                        <th className="text-left">Added At</th>
                        <th className="text-left">Added by</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{projectElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>
                
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default Projects;
