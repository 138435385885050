import React,{useState,useEffect,useContext} from 'react';
import pluginService from "../../services/api/plugin-service";
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
import Moment from "moment";
import{
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    CardSubtitle,
    CardImg,
    CardGroup,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import moment from 'moment';
const ProjectsPluginInfo = (props) => {
    const [pluginsInfo , setPluginsInfo] = useState([]);
    const context = useContext(UserContext);
    const [totalPluginInfo , setTotalPluginsInfo] = useState(0);
    const [reloadComponent,setReloadComponent] = useState(0);
    const [formdetails, setFormDetails] = useState({publisher_id : '',project_id : '',plugin_version : ''});
    const records = 10;
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [renderComponent,setRenderComponent] = useState(false);
    const [activePage,setActivePage] = useState(1);
    const [publisherNameArray,setPublisherNames] = useState([]);
    const [projectId,setProjectId] = useState('');
    const [projectNameArray,setProjectNames] = useState([]);
    const [publisher, setPublisher] = useState('');
    const [publisherId,setPublisherId] = useState('');
    const [pluginsList, setPluginsList] = useState([]);
    useEffect(()=>{
        (async ()=>{
            const response = await pluginService.getPluginsList();
            if(response.status && response.data) {
                let pluginsArray = [];
                let plugins = response.data;
                plugins.forEach((name)=>{
                    pluginsArray.push({
                        value : name,
                        label : name
                    });
                });

                setPluginsList(pluginsArray);
            }
        })();
    },[]);
    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
        })();
      }, [publisher]);
    useEffect(()=>{
        (async ()=>{
            const response = await pluginService.getProjectsPluginInfo(formdetails,activePage,records);
            if(response.status && response.data) {
                let projectsPluginInfo = response.data.projects;
                let reverseResult = [];
                projectsPluginInfo.forEach((project) => {
                    if(project.plugins && project.plugins.length>0) {
                        project.plugins.reverse();
                    }
                    reverseResult.push(project);
                });
                setPluginsInfo(projectsPluginInfo);
                setTotalPluginsInfo(response.data.count);
                if(projectsPluginInfo && projectsPluginInfo.length) {
                    setFirstRecord((activePage-1)*records+1);
                    setLastRecord((activePage-1)*records+ projectsPluginInfo.length);
                }else{
                    setFirstRecord((activePage-1)*records);
                    setLastRecord((activePage-1)*records+ projectsPluginInfo.length);
                }
            }
            setRenderComponent(true);
        })();
    },[reloadComponent]);

        const handleSubmit = function(e){  searchProjectPlugin();e.preventDefault() }

    const searchProjectPlugin = () => {
            setActivePage(1);
            setReloadComponent(Math.random());
    }
    const resetForm = () => {
        setActivePage(1);
        setFormDetails({
        ...formdetails,
        publisher_id : '',
        plugin_version : '',
        project_id : ''
        });
        setPublisherId('');
        setPublisher('');
        setProjectId('');
        setReloadComponent(Math.random());
    }

    const handleChange = (event)=>{
        console.log(event.target);
        setFormDetails({
        ...formdetails,
        [event.target.id]: event.target.value,
        });
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const changePublisherId = (event) => {
        setFormDetails({
        ...formdetails,
        publisher_id: event.value,
        project_id:''
        });
        setProjectId('');
        setPublisherId(event)
        setPublisher(event.value);
    }
    const changeProjectId = (event) => {
        setFormDetails({
        ...formdetails,
        project_id: event.value,
        });
        setProjectId(event)
  }
  const handleEnumChange = (inputId, inputValue) => {
    setFormDetails({
        ...formdetails,
        [inputId]: ( inputValue.value ),
      });  
  }

    return (
        <div className="content">
         <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <Row>
                  <Col sm="12" align="center"><CardTitle tag="h4">Projects Plugin details</CardTitle></Col>
                </Row>
                </CardHeader>
                <CardBody>    
                    <Form
                        id="pluginsFilters"
                        action=""
                        className="form-horizontal"
                        method="POST"
                        onSubmit={handleSubmit}
                        >
                        <Row>
                            <Col md="3">
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Select Publisher .."
                                    name = "publisher_id"
                                    id="publisher_id"
                                    options={publisherNameArray}
                                    value={publisherId}
                                    onChange={changePublisherId}
                                />
                            </Col>
                            <Col md="3">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Project .."
                                name = "project_id"
                                id="project_id"
                                options={projectNameArray}
                                value={projectId}
                                onChange={changeProjectId}
                            />
                            </Col>
                            <Col md="3">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Plugin Version .."
                                name = "plugin_version"
                                id="plugin_version"
                                // style= {{"background-color":"black !important"}}
                                options={pluginsList}
                                value={formdetails.plugin_version? pluginsList.filter((versionDetail)=> formdetails.plugin_version===versionDetail.value):""}
                                onChange={(event)=> handleEnumChange('plugin_version',event)}
                            />
                            </Col>
                            {/* <Col md="3">
                                <Input
                                type="text"
                                className="col md"
                                value = {formdetails.plugin_version}
                                name = "plugin_version"
                                id="plugin_version"
                                placeholder="Plugin Version"
                                onChange ={handleChange}
                                />
                            </Col> */}
                            
                            <Col md='3'>
                                <div style={{'float':'left'}}>
                                <Button className="btn-smb" type="submit">
                                    Search
                                </Button>
                                <Button
                                    className="btn-smb btnRst"
                                    type="button"
                                    onClick={resetForm}
                                    >
                                Reset 
                                </Button>
                                </div>
                            </Col>
                            </Row>
                    </Form>
                    <hr/>
                    {totalPluginInfo > 0 ? 
                        <Row>
                            <Col md="6">
                                <div style={{'float':'right'}}>
                                {firstRecord} - {lastRecord} of {totalPluginInfo}
                                </div>
                            </Col>
                            <Col md="6">
                                <div style={{'float':'left'}}>
                                <Pagination
                                activePage={activePage}
                                itemsCountPerPage={records}
                                totalItemsCount={totalPluginInfo}
                                pageRangeDisplayed={3}
                                onChange={handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                />
                                </div>
                            </Col>
                        </Row>
                    : null}
                    <Table responsive>   
                        <thead className="text-primary">
                            <th className="text-left" width ="50px">#</th>
                            <th className="text-left" width ="150px">Publisher Name</th>   
                            <th className="text-left" width ="150px">Project Name</th>
                            <th className="text-left" width ="250px">Versions</th>
                        </thead>
                        <tbody>
                            {!renderComponent?
                                    <td colSpan={4}>
                                    <Spinner className="text-center" animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>  
                                    </td>
                            :null}
                            {pluginsInfo && pluginsInfo.length?pluginsInfo.map( (details ,index) => (
                                <tr >
                                    <td className="text-left">{(activePage-1)*records+index+1}</td>
                                    <td className="text-left">
                                        {details.publishers_id.name}
                                    </td>
                                    <td className="text-left">
                                        {details.name}
                                    </td>
                                    <td className="text-center">
                                        <Table bordered>
                                            <tbody>
                                            {details.plugins && details.plugins.length?details.plugins.map((plugins , i) => (
                                         
                                                <tr>
                                                    <td width ="100px">{plugins._id.plugin_version?plugins._id.plugin_version:plugins.plugin_version}</td>
                                                    <td>{moment(plugins._id.createdAt?plugins._id.createdAt: plugins.createdAt ).format('DD-MM-yyyy hh:mm:ss a')} </td>
                                                </tr>
                                         
                                        ))
                                        : "-"
                                        }
                                        </tbody>
                                        </Table>
                                        
                                    </td>
                            </tr>
                            )):<tr><td colSpan={4}><h4>No Record Found</h4></td></tr>
                        
                        }
                           
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Col>
        </Row>
    </div>
    );
}
export default ProjectsPluginInfo;