import React, { useEffect, useState,useContext } from "react";
import formValidations from "../../variables/form-validations";
import saasService from "../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import TagInput from "./plan/TagInput";
import UserContext from "../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from "react-select";
import { isNumber } from "lodash";
//import TagInput from "./tags-input";

const CreateFeature = (props) => {
    const history = new useHistory();
    const context = useContext(UserContext);
    const [groups, setGroups] = useState([]);
    const [features, setFeatures] = useState(false);
    const [featureCodes, setFeatureCodes] = useState([]);

    const [tagsArray, setTagsArray] = useState([]);

    const [formdetails, setFormDetails] = useState({
        name: "",
        description:"",
        code: "",
        type: "label",
        group_id: "",
        limit_type: "",
        limit_max: "",
        limit_min: "",
        limit_enum_array: [],
        quota_duration: "",
        visibility : 2
    });
    const [groupNameState,setGroupNameState]=useState("");
    const [groupNameError,setGroupNameError]=useState("");

    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [codestate, setCodeState] = useState("");
    const [codeerror, setCodeError] = useState("");
    const [featureLimitTypeState, setFeatureLimitTypeState] = useState("");
    const [featureLimitTypeError, setFeatureLimitTypeError] = useState("");
    const [minState, setMinState] = useState("");
    const [minError, setMinError] = useState("");
    const [maxState, setMaxState] = useState("");
    const [maxError, setMaxError] = useState("");

    const [enumState, setEnumState] = useState("");
    const [enumError, setEnumError] = useState("");
    const [quotaDurationState,setQuotaDurationState]=useState("");
    const [quotaDurationError,setQuotaDurationError]=useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [limit, setLimit] = useState({
        limitElements: [],
    });

    const typeOptions = [
        { value: "label", label: "Label" },
        { value: "quota", label: "Quota" },
    ];
    const quotaDurationOptions = [
        { value: "monthly", label: "Monthly" },
        { value: "active_usage", label: "Active usage" },
    ];
    const visibilityOptions = [
        { value: 0, label: "Private" },
        { value: 1, label: "Protected" },
        { value: 2, label: "Public" },
    ];

    useEffect(() => {
        (async () => {
            let groupsData = await saasService.getFeatureGroups();
            if (groupsData.status && groupsData.data) {
                let groupNames = groupsData.data;
                let groupValues = [];
                let i = 1;
                Object.keys(groupNames).forEach(function (key) {
                    groupValues.push({
                        value: groupNames[key].id,
                        label: groupNames[key].name,
                    });
                    // if (i == 1) {
                    //     setFormDetails({
                    //         ...formdetails,
                    //         group_id: groupNames[key].id,
                    //     });
                    // }
                    i++;
                });
                setGroups(groupValues);
            }

            let featureCodesData = await saasService.getFeatureCodes();
            if (featureCodesData.status && featureCodesData.data) {
                let allFeatureCodes = featureCodesData.data;
                let featureCodeValues = [];
                let i = 1;
                Object.keys(allFeatureCodes).forEach(function (key) {
                    featureCodeValues.push({
                        value: allFeatureCodes[key],
                        label: allFeatureCodes[key],
                    });
                    if (i == 1) {
                        setFormDetails({
                            ...formdetails,
                            code: allFeatureCodes[key],
                        });
                    }
                    i++;
                });
                setFeatureCodes(featureCodeValues);
            }

            let featuresData = await saasService.getFeatures();
            if (featuresData.status && featuresData.data) {
                setFeatures(featuresData.data);
            }
        })();
    }, []);

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value.trim(),
        });
        setNameState("");
        setNameError("");
    };

    const handleEnumChange = (inputId, inputValue) => {
        setFormDetails({
            ...formdetails,
            [inputId]: ( inputValue ),
        });  
    }

    const validateGroupName=()=>{
        if (formdetails.group_id === "") {
            setGroupNameState("has-danger");
            setGroupNameError(<div className="text-danger">Group Name is required</div>);
            return false;
        }
        return true;
    };
    const validateName = () => {
        if (formdetails.name === "") {
            setNameState("has-danger");
            setNameError("Feature Name is required");
            return false;
        }

        if (!formValidations.verifyAlphaNumeric(formdetails.name)) {
            setNameState("has-danger");
            setNameError("Please enter alphanumeric value.");
            return false;
        }

        if (features && features.length > 0) {
            let duplicateName = false;
            features.forEach(function (details) {
                if (formdetails.name.toLowerCase() === details.name.toLowerCase()) {
                    duplicateName = true;
                }
            });
            if (duplicateName) {
                setNameState("has-danger");
                setNameError(
                    "You have already created the feature with this name."
                );
                return false;
            }
        }

        setNameState("has-success");
        return true;
    };

    const validateCode = () => {
        if (formdetails.code === "") {
            setCodeState("has-danger");
            setCodeError("Feature Code is required");
            return false;
        }
        setCodeState("has-success");
        return true;
    };
    const validateFeatureLimitType = () => {
        let isQuotaDurationValid=validateQuotaDuration();
        if (formdetails.limit_type === "") {
            setFeatureLimitTypeState("has-danger");
            setFeatureLimitTypeError(
                <div className="text-danger">
                    Feature limit type is required
                </div>
            );
            return false;
        }


        if (formdetails.limit_type === "range") {
            let validateMin = validateMinRange(formdetails.limit_min);
            let validateMax = validateMaxRange(formdetails.limit_max);
            if (!validateMin || !validateMax) {
                return false;
            }
        } else {
            if (validateEnumArray() == false) {
                return false;
            }
        }
        if(!isQuotaDurationValid) return false;
        setFeatureLimitTypeState("has-success");
        return true;
    };
    const validateMinRange = (min) => {
        if (min.length == 0) {
            setMinState("has-danger");
            setMinError("Min Range  is required");
            return false;
        }
        if (!(min === String(Number(min)))) {
            // it's a "perfectly formatted" numbe
            setMinState("has-danger");
            setMinError("Special character not allowed");
            return false;
        }
        if (Number(min) < 1) {
            setMinState("has-danger");
            setMinError("Min Range Should be positive number");
            return false;
        }
        if(formdetails.limit_max)
        {
        if(Number(formdetails.limit_max)<Number(min))
        {
            setMinState("has-danger");
            setMinError("Min Range Should be less than Max Range");
            setMaxState("has-success");
            setMaxError("");
            return false;
        }

        }   
        setMinState("has-success");
        setMinError("");
        return true;
    };
    const validateMaxRange = (max) => {
        if (max === "") {
            setMaxState("has-danger");
            setMaxError("Max Range  is required");
            return false;
        }
        if (isNaN(max)) {
            setMaxState("has-danger");
            setMaxError("Special character not allowed");
            return false;
        }
        if (Number(max) < 1) {
            setMaxState("has-danger");
            setMaxError("Max Range Should be positive number");
            return false;
        }
        if(formdetails.limit_min)
        {

        if(Number(formdetails.limit_min)>Number(max))
        {
            setMaxState("has-danger");
            setMaxError("Max Range Should be greater than Min Range");
            setMinState("has-success");
            setMinError("");
            return false;
        }

    }

        setMaxState("has-success");
        setMaxError("");
        return true;
    };

    const validateEnumArray = () => {
        if (tagsArray.length === 0) {
            setEnumState("has-danger");
            setEnumError(
                <div className="text-danger">
                    Enum should have atleast one value
                </div>
            );
            return false;
        }
    };

    const validateEnumValue = (value) => {
        if (value === "") {
            setEnumState("has-success");
            setEnumError("");
            return true;
        }
        if (isNaN(value)) {
            setEnumState("has-danger");
            setEnumError(
                <div className="text-danger">
                    Enum should have only numeric value
                </div>
            );
            return false;
        }
        if (Number(value) < 1) {
            setEnumState("has-danger");
            setEnumError(
                <div className="text-danger">
                    Enum should have positive number
                </div>
            );
            return false;
        }
        setEnumState("has-success");
        setEnumError("");
        return true;
    };
    const validateQuotaDuration=()=>{
        if (formdetails.quota_duration === "") {
            setQuotaDurationState("has-danger");
            setQuotaDurationError(<div className="text-danger">Quota Duration is required</div>);
            return false;
        }
        return true;
    };
    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        setFormDetails({ ...formdetails, limit_enum_array: tagsArray });
        let groupNameValidation=validateGroupName();
        let namevalidation = validateName();
        let codevalidation = validateCode();
        let featureLimitTypeValidation =
            formdetails.type === "quota" ? validateFeatureLimitType() : true;

        if (namevalidation && groupNameValidation&& codevalidation && featureLimitTypeValidation) {
            setShowLoader(true);
            setShowSaveButton(false);

            let createFeature = await saasService.createFeature({
                ...formdetails,
                limit_enum_array: tagsArray,
            });
            if(!createFeature.status && createFeature.error.errorCode === 403){
                context.setIsAuthorized(context);
            }
            else if (createFeature.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Feature was created Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    history.push("/saas/features");
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "Feature could not be created, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    const changeType = (event) => {
        setFormDetails({
            ...formdetails,
            type: event.value,
            limit_type: "",
        });
    };
    const changeGroup = (event) => {
        setFormDetails({
            ...formdetails,
            group_id: event.value,
        });
        setGroupNameState("has-sucess");
        setGroupNameError("");
    };

    const changeCode = (event) => {
        setFormDetails({
            ...formdetails,
            code: event.value,
        });
    };
    const changeQuotaDuration = (event) => {
        setFormDetails({
            ...formdetails,
            quota_duration: event.value,
        });
        setQuotaDurationState("has-sucess");
        setQuotaDurationError("");
    };

    const handleTagsInputChange = (value) => {
        if (!isNaN(value) && Number(value) > 0) {
            return true;
        }
        if (validateEnumValue(value)) return true;
        return false;
    };

    const getTypeValue = () => {
        let typeValue = typeOptions[0];
        if (formdetails.type) {
            typeOptions.forEach(function (ldetails) {
                if (ldetails.value == formdetails.type) {
                    typeValue = ldetails;
                }
            });
        }
        return typeValue;
    };

    const getGroupValue = () => {
         let groupValue="";
        if (formdetails.group_id) {
            groupValue = groups.length ? groups[0] : "";
      
            groups.forEach(function (ldetails) {
                if (ldetails.value == formdetails.group_id) {
                    groupValue = ldetails;
                }
            });
        }
        return groupValue;
    };

    const getCodeValue = () => {
        let codeValue = featureCodes.length ? featureCodes[0] : "";
        if (formdetails.code) {
            featureCodes.forEach(function (codedetails) {
                if (codedetails.value == formdetails.code) {
                    codeValue = codedetails;
                }
            });
        }
        return codeValue;
    };

    const handleMaxChange = async (event) => {
        setFormDetails({
            ...formdetails,
            limit_max: Number(event.target.value),
        });
    };

    const handleMinChange = async (event) => {
        setFormDetails({
            ...formdetails,
            limit_min: Number(event.target.value),
        });
    };

    const handleInputChange = async (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === "limit_min") {
            validateMinRange(event.target.value);
            if (event.target.value == "") {
                setMinState("has-success");
                setMinError("");
            }
        }
        if (event.target.name === "limit_max") {
            validateMaxRange(event.target.value);
            if (event.target.value == "") {
                setMaxState("has-success");
                setMaxError("");
            }
        }
    };
    const handleLimitTypeChange = async (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.name]: event.target.value,
        });

        setFeatureLimitTypeState("has-success");
        setFeatureLimitTypeError("");
        setEnumState("has-success");
        setEnumError("");
        setMaxState("has-success");
        setMaxError("");
        setMinState("has-success");
        setMinError("");
        setQuotaDurationState("has-success");
        setQuotaDurationError("");
    };
    const getQuotaDurationValue = () => {
        let quotaDurationValue = "";
        if (formdetails.quota_duration) {
            quotaDurationOptions.forEach(function (ldetails) {
                if (ldetails.value === formdetails.quota_duration) {
                    quotaDurationValue = ldetails;
                }
            });
        }
        return quotaDurationValue;
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Create New Feature
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol"
                                    action=""
                                    id=""
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Label sm="2">Group Name <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="type"
                                                    value={getGroupValue()}
                                                    onChange={changeGroup}
                                                    options={groups}
                                                />
                                            {groupNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {groupNameError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Feature Code <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={codestate}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="type"
                                                    value={getCodeValue()}
                                                    onChange={changeCode}
                                                    options={featureCodes}
                                                />
                                                {codestate === "has-danger" ? (
                                                    <label className="error">
                                                        {codeerror}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Feature Name <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={namestate}>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                />
                                                {namestate === "has-danger" ? (
                                                    <label className="error">
                                                        {nameerror}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Description</Label>
                                        <Col sm="7">
                                            <FormGroup >
                                                <Input
                                                    name="description"
                                                    id="description"
                                                    type="textarea"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>          

                                    <Row>
                                        <Label sm="2">Type <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="type"
                                                    value={getTypeValue()}
                                                    onChange={changeType}
                                                    options={typeOptions}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formdetails.type === "quota" ? (
                                        <>
                                        <Row>
                                            <Label sm="2">
                                                Feature Limit Type <span class="text-danger"> * </span>
                                            </Label>
                                            <Col>
                                                <Row>
                                                    <Col sm="3">
                                                        {featureLimitTypeState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {
                                                                    featureLimitTypeError
                                                                }
                                                            </label>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <span className="form-check-radio">
                                                                <Label check>
                                                                    <Input
                                                                        value="enum"
                                                                        id="limitTypeEnumId"
                                                                        name="limit_type"
                                                                        type="radio"
                                                                        onChange={
                                                                            handleLimitTypeChange
                                                                        }
                                                                    />
                                                                    {"Enum"}{" "}
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <span className="form-check-radio">
                                                                <Label check>
                                                                    <Input
                                                                        value="range"
                                                                        id="limitTypeRangeId"
                                                                        name="limit_type"
                                                                        type="radio"
                                                                        onChange={
                                                                            handleLimitTypeChange
                                                                        }
                                                                    />
                                                                    {"Range"}{" "}
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>

                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {formdetails.limit_type === "range" ? (
                                        <Row className="content">
                                            <Row>
                                                <Label sm="2">Min <span class="text-danger"> * </span></Label>
                                                <Col sm="7">
                                                    <FormGroup
                                                        className={minState}
                                                    >
                                                        <Input
                                                            name="limit_min"
                                                            id="min"
                                                            type="Number"
                                                            value={
                                                                formdetails.limit_min
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                        {minState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {minError}
                                                            </label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Label sm="2">Max <span class="text-danger"> * </span></Label>
                                                <Col sm="7">
                                                    <FormGroup
                                                        className={maxState}
                                                    >
                                                        <Input
                                                            name="limit_max"
                                                            id="max"
                                                            type="Number"
                                                            value={
                                                                formdetails.limit_max
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                        {maxState ===
                                                        "has-danger" ? (
                                                            <label className="error">
                                                                {maxError}
                                                            </label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Row>
                                    ) : (
                                        ""
                                    )}

                                    {formdetails.limit_type === "enum" ? (
                                        <Row>
                                        <Col sm="2"></Col>
                                        <Col sm="7">
                                            <TagInput
                                                tagsArray={tagsArray}
                                                setTagsArray={setTagsArray}
                                                onChange={handleTagsInputChange}
                                                isValidValue={validateEnumValue}
                                            />
                                            {enumState === "has-danger" ? (
                                                <label className="error">
                                                    {enumError}
                                                </label>
                                            ) : null}
                                            <br/>
                                        </Col>
                                        </Row>
                                    ) : (
                                        ""
                                    )}
                                      {formdetails.type === "quota" ? (
                                        <Row>
                                            <Label sm="2">Quota Duration <span class="text-danger"> * </span></Label>
                                            <Col sm="7">
                                                <FormGroup className={quotaDurationState}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        value={getQuotaDurationValue()}
                                                        onChange={changeQuotaDuration}
                                                        options={quotaDurationOptions}
                                                    />
                                                 {quotaDurationState === "has-danger" ? (
                                                    <label className="error">
                                                        {quotaDurationError}
                                                    </label>
                                                ) : null}
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        )
                                        :""}
                                    <Row>
                                        <Label sm="2">Feature Visibility</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="visibility"
                                                    id="visibility"
                                                    value={visibilityOptions && (formdetails.visibility!=="")?visibilityOptions.filter(option=> option.value === formdetails.visibility):""}
                                                    onChange={(event)=>handleEnumChange("visibility",event.value)}
                                                    options={visibilityOptions}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {showSaveButton ? (
                                        <div>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="button"
                                                onClick={validateForm}
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="danger"
                                                type="button"
                                                onClick={(event) => {
                                                    history.push(
                                                        `/saas/features`
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default CreateFeature;
