import axios from "axios";

const getAppBuildJobs = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/appbuild/get-app-build-jobs',formdata);
    return response.data;
}


const createAppBuildJob = async (formdata) =>{
    console.log(formdata)
    const response = await axios.post('/api/appbuild/create-app-build-job',formdata);

    return response.data;
}
const retryAppBuildJob = async(data)=>{
    const response = await axios.post('/api/appbuild/retry-app-build-job', data);
    return response.data;
}
const getJobData = async(jobId) => {
    const response = await axios.get(`/api/appbuild/job-data/${jobId}`);
    return response.data;
}

const getNewVersionName = async (formdata) =>{
    const response = await axios.post('/api/appbuild/get-new-version-name',formdata);
    return response.data;
} 

const createAppKeystore = async (formdata) =>{
    const response = await axios.post('/api/appbuild/create-android-app-keystore',formdata);
    return response.data;
}
const getAppKeystore = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/appbuild/get-app-keystore',formdata);
    return response.data;
} 
const getAppleAccountUsers = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/appbuild/get-apple-account-user',formdata);
    return response.data;
} 
const addUserToAppleAccount = async (formdata) =>{
    const response = await axios.post('/api/appbuild/add-user-to-apple-account',formdata);
    return response.data;
} 
const addUserAsIOSAppTester = async (formdata) =>{
    const response = await axios.post('/api/appbuild/add-user-as-ios-app-tester',formdata);
    return response.data;
} 
const getIOSAppTesters = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/appbuild/get-ios-app-testers',formdata);
    return response.data;
}

const uploadNewAppKeystore = async (formdata) =>{
    const response = await axios.post('/api/appbuild/upload-new-android-app-keystore',formdata);
    return response.data;
}

const getAppKeystores = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/appbuild/get-app-keystore-list',formdata);
    return response.data;
}

export default {
    getAppBuildJobs,
    createAppBuildJob,
    retryAppBuildJob,
    getJobData,
    getNewVersionName,
    createAppKeystore,
    getAppKeystore,
    getAppleAccountUsers,
    addUserToAppleAccount,
    addUserAsIOSAppTester,
    getIOSAppTesters,
    uploadNewAppKeystore,
    getAppKeystores,
}