import React, { useContext, useEffect, useState } from 'react'
import ctvAppService from '../../services/api/ctv-app-service';
import { Container, Card, CardBody, CardHeader, CardTitle, Spinner, Form, Row, Col, Table, Button } from 'reactstrap';
import moment from "moment";
import Select from 'react-select'
import customStyles from '../../components/CustomStyles';
import Pagination from "react-js-pagination";
import projectsService from '../../services/api/projects-service';
import generalHelper from '../../variables/general-helper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImporterLogs = (props) => {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const projectId = urlSearchParams.get("project_id") || "";
    const createdByParam = urlSearchParams.get("created_by");
    const createdBy = createdByParam ? decodeURIComponent(createdByParam) : "";
    const importerId = urlSearchParams.get("importer_id") || "";
    const status = urlSearchParams.get("status") || "";


    const [formdetails, setFormDetails] = useState({ project_id: projectId, importer_id: importerId, created_by: createdBy, status: status })
    const [activePage, setActivePage] = useState(parseInt(urlSearchParams.get("page")) || 1)
    const records = 10;
    const [renderComponent, setRenderComponent] = useState(0)
    const [logsData, setLogsData] = useState([])
    const [totalLogs, setTotalLogs] = useState(0);
    const [projectNames, setProjectNames] = useState([])
    const [publisherNames, setPublisherNames] = useState([])
    const [importerNames, setImporterNames] = useState([])
    const [publisherId, setPublisherId] = useState("")

    const statusOptions = [
        { value: "", label: "All" },
        { value: "2", label: "Success" },
        { value: '-1', label: "Fail" },
    ]
    const statusLookUp = {
        0: "start",
        1: "processing",
        2: "success",
        [-1]: "fail"
    }

    useEffect(() => {
        (async () => {
            let publishers = await projectsService.getAllPublishers();
            if (publishers.status) {
                let getPublishers = [];
                let publisherData = publishers.data;
                if (publisherData.length) {
                    publisherData.forEach(function (publisherDetails) {
                        let publisherValue = { value: publisherDetails.id, label: publisherDetails.name + "(" + publisherDetails.email + ")", email: publisherDetails.email };
                        getPublishers.push(publisherValue)
                    })
                }
                setPublisherNames(getPublishers);
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            let projects = await projectsService.getPublisherProjects(publisherId);
            let publisherProjects = [];
            if (projects.status) {
                let projectData = projects.data;
                if (projectData.length) {
                    projectData.forEach(function (projectDetails) {
                        let projectValue = { value: projectDetails.id, label: projectDetails.name };
                        publisherProjects.push(projectValue)
                    })
                }
                setProjectNames(publisherProjects);
            }
        })()
    }, [publisherId])

    useEffect(() => {
        (async () => {
            let importers = await ctvAppService.getProjectImporters(formdetails.project_id)
            let projectImporter = [];
            if (importers.status) {
                let importerData = importers.data.videoList;
                if (importerData.length) {
                    importerData.forEach(function (importerDetails) {
                        let importerValue = { value: importerDetails._id, label: importerDetails.name };
                        projectImporter.push(importerValue)
                    })
                }
                setImporterNames(projectImporter)
            }
        })()
    }, [formdetails.project_id])

    useEffect(() => {
        (async () => {
            setRenderComponent(false)
            const logsList = await ctvAppService.getImporterLogsList({
                ...formdetails,
                activePage,
                records
            })
            console.log("logList : ", logsList);
            if (logsList.status && logsList.data) {
                console.log("in here");
                setLogsData(logsList.data.logsList)
                setTotalLogs(logsList.data.count)
            }
            setRenderComponent(true)
        })();
    }, [activePage, formdetails])

    const resetElements = () => {
        const baseUrl = window.location.origin + window.location.pathname;
        const newUrl = baseUrl + '?page=1';
        window.history.pushState({}, '', newUrl);
        setFormDetails({ project_id: "", importer_id: "", created_by: "", status: "" });
        setActivePage(1)
        setPublisherId("")
    }
    const handlePageChange = (pageNumber) => {
        generalHelper.addKeyToQueryString("page", pageNumber);
        setActivePage(pageNumber);
    }
    const changePublisher = async (e) => {
        setFormDetails({ ...formdetails, created_by: e.email });
        setPublisherId(e.value)
        generalHelper.addKeyToQueryString("created_by", e.email);
    }

    const handleChange = async (selectedOption, id) => {
        console.log("event", selectedOption, id);
        if (selectedOption) {
            setFormDetails({
                ...formdetails,
                [id]: selectedOption.value
            })
            generalHelper.addKeyToQueryString(`${id}`, selectedOption.value);
        }
    }
    let logElements = []
    let firstRecord = 0;
    let lastRecord = 0;
    if (!logsData || !logsData.length) {
        if (!renderComponent) {
            logElements.push(
                <tr>
                    <td colSpan="12" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            )
        }
        else {
            logElements.push(
                <td colSpan="12" className="text-center">
                    <CardTitle tag="h4" className="boRslt">No Logs Found</CardTitle>
                </td>
            )
        }
    }
    else {
        let i = 1;
        logsData.forEach(function (logDetails) {
            const createdAtTime = moment(logDetails.createdAt)
            let className = "";
            if (logDetails.status === -1) {
                className = "table-danger"
            }

            if (i == 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            logElements.push(
                <tr className={className}>
                    <td className="text-left">{firstRecord - 1 + i}</td>
                    <td className='text-left'>{logDetails.project_id.name}</td>
                    <td className='text-left'>{logDetails.importer_id.name}</td>

                    <td className='text-left'>{logDetails.created_by}</td>
                    {/* <td className='text-left'>
                        {logDetails.categories.map(data => (
                            <Row>{data.name}</Row>
                        ))}
                    </td> */}
                    <td className='text-left'>{logDetails.total_videos}</td>
                    <td className='text-left'>{logDetails.inserted_videos}</td>
                    <td className='text-left'>{logDetails.updated_videos}</td>
                    <td className='text-left'>
                            {createdAtTime.format('h:mm:ss a')}<br/>
                            {createdAtTime.format('Do MMM YYYY')}
                    </td>
                    <td className='text-left'>{statusLookUp[logDetails.status]}</td>
                </tr>
            )
            if (i === logsData.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i === logsData.length) {
                lastRecord = (activePage - 1) * records + i;
            }

        })
    }
    return (
        <div className='content'>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card_signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Importer Logs
                                </CardTitle>
                                <Form className="form" methods="POST" onSubmit={(e) => e.preventDefault}>
                                    <Row>
                                        {/* publisher */}
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                name="created_by"
                                                id="created_by"
                                                value={publisherNames.filter(option => option.email == formdetails.created_by)}
                                                onChange={(e) => changePublisher(e)}
                                                placeholder="Select Publisher..."
                                                options={publisherNames}
                                                styles={customStyles}
                                            />
                                        </Col>
                                        {/* projects  */}
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                name="project_id"
                                                id="project_id"
                                                value={projectNames.filter(option => option.value == formdetails.project_id)}
                                                onChange={(selectedOption) => handleChange(selectedOption, "project_id")}
                                                placeholder="Select Project..."
                                                options={projectNames}
                                                styles={customStyles}
                                            />
                                        </Col>
                                        {/* importer name  */}
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                name="importer_id"
                                                id="importer_id"
                                                value={importerNames.filter(option => option.value == formdetails.importer_id)}
                                                onChange={(selectedOption) => handleChange(selectedOption, "importer_id")}
                                                placeholder="Select Importer..."
                                                options={importerNames}
                                                styles={customStyles}
                                            />
                                        </Col>

                                        {/* //status */}
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                name="status"
                                                id="status"
                                                value={statusOptions.filter(option => option.value == formdetails.status)}
                                                onChange={(selectedOption) => handleChange(selectedOption, "status")}
                                                placeholder="Select Status"
                                                options={statusOptions}
                                                styles={customStyles}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div style={{ 'float': 'right' }}>
                                                {/* <Button
                                                    className="btn-round"
                                                    //style={{'margin-top':'-5px'}}
                                                    color="info"
                                                    type="button"
                                                    onClick={searchButton}
                                                >
                                                    Search
                                                </Button> */}
                                                <Button
                                                    className="btn-round"
                                                    //style={{'margin-top':'-5px'}}
                                                    color="warning"
                                                    type="button"
                                                    onClick={resetElements}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <hr />
                            </CardHeader>
                            <CardBody>

                                {totalLogs > 0 ? 
                                    <Row>
                                        <Col>
                                            <div className='pagNo'>
                                                <b>
                                                    {firstRecord} - {lastRecord} of {totalLogs}
                                                </b>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="pagBox">
                                                <div className="pagDt">
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={records}
                                                        totalItemsCount={totalLogs}
                                                        pageRangeDisplayed={3}
                                                        onChange={handlePageChange.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                : null}

                                <Table responsive hover>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>#</th>
                                            <th>Project</th>
                                            <th>Importer Name</th>
                                            <th>Created By</th>
                                            <th>Total Videos</th>
                                            <th>New Videos</th>
                                            <th>Updated Videos</th>
                                            <th>Created On</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logElements}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>

    )
}

export default ImporterLogs