import React, { useEffect, useState, useContext } from "react";
import ctvAppService from "../../services/api/ctv-app-service.js";
import { useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Alert,
    Spinner,
    Container,
    Row,
    Col,
    Input
} from "reactstrap";
import formValidations from "../../variables/form-validations.js";

const CTVGeneralSettings = (props) => {
    const projectId = new URLSearchParams(props.location.search).get('projectid');

    const history = new useHistory();
    if (!projectId) {
        history.push("/project/projects");
    }
    const [formdetails, setFormDetails] = useState({ project_id: projectId, ctv_android_app_enabled: false,ctv_android_autoplay:false,ctv_android_app_name:"", ctv_android_package_name:"",ctv_ios_app_enabled: false,ctv_ios_autoplay:false,ctv_ios_app_name:"", ctv_ios_package_name:"" });
    const [showAndroidLoader, setShowAndroidLoader] = useState(false);
    const [showAndroidSaveButton, setShowAndroidSaveButton] = useState(true);
    const [androidAlert, setAndroidAlert] = useState({ show: false, message: '', type: '' });

    const [showIOSLoader, setShowIOSLoader] = useState(false);
    const [showIOSSaveButton, setShowIOSSaveButton] = useState(true);
    const [iosAlert, setIOSAlert] = useState({ show: false, message: '', type: '' });

    const [androidPackageName, setAndroidPackageName] = useState("");
    const [iosPackageName, setIosPackageName] = useState("");
    const [fieldStates,setFieldStates] = useState({});
    const [projectDists, setProjectDists] = useState([]);

    useEffect(()=>{
        (async()=>{
            const projectDistribution = await ctvAppService.getProjectDistribution();
            if(projectDistribution.status && projectDistribution.data){
                setProjectDists(projectDistribution.data);
            }
        })();
    },[]);
    
    useEffect(() => {
        (async () => {
            const generalSettings = await ctvAppService.getCTVAppGeneralSettings({ project_id: projectId });
            if (generalSettings.status && generalSettings.data) {
                setAndroidPackageName(generalSettings.data.ctv_android_package_name);
                setIosPackageName(generalSettings.data.ctv_ios_package_name);
                setFormDetails({
                    ...formdetails,
                    ctv_android_app_enabled: generalSettings.data.ctv_android_app_enabled || false,
                    ctv_android_autoplay: generalSettings.data.ctv_android_autoplay || false,
                    ctv_android_app_name: generalSettings.data.ctv_android_app_name || '',
                    ctv_android_package_name: generalSettings.data.ctv_android_package_name || '',
                    ctv_ios_app_enabled: generalSettings.data.ctv_ios_app_enabled || false,
                    ctv_ios_autoplay: generalSettings.data.ctv_ios_autoplay || false,
                    ctv_ios_app_name: generalSettings.data.ctv_ios_app_name || "",
                    ctv_ios_package_name: generalSettings.data.ctv_ios_package_name || "",
                })
            }
        })();
    }, [])

    const handleSwitchChange = (fieldname, fieldvalue) => {
        setFormDetails({
            ...formdetails,
            [fieldname]: fieldvalue,
        });
    }

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id] : event.target.value,
        })
        fieldStates["error_"+event.target.id] = 'has-success';
        fieldStates["error_msg_"+event.target.id] = '';
        setFieldStates({
            ...fieldStates,
            fieldStates
        });
    }
    
    const validateDuplicatePackageNameExists = (platform)=>{
        let duplicateName = false;
        projectDists.filter(function(packageName) {
            console.log("in validateDuplicatePackageNameExists: ",platform);
            console.log("in androidPackageName: ",androidPackageName);

            console.log("in formdetails: ",formdetails);

            console.log("in packageName: ",packageName);

            if(platform === "android" &&(androidPackageName !== formdetails.ctv_android_package_name) && formdetails.ctv_android_package_name === packageName){
                duplicateName = true;
            }
            if(platform === "ios" &&(iosPackageName !== formdetails.ctv_ios_package_name) && formdetails.ctv_ios_package_name === packageName){
                duplicateName = true;
            }
        });
        return duplicateName;
    }

    const handleSubmit = function (e) { validateForm(); e.preventDefault() }

    const validateForm = () => {
        console.log("Validation Start:", formdetails);
        let validations = true;
        if(formdetails.ctv_android_app_enabled){
            if(formdetails.ctv_android_app_name.trim() === ''|| formValidations.verifySpecialCharacters(formdetails.ctv_android_app_name)){
                fieldStates['error_ctv_android_app_name'] = 'has-danger';
                fieldStates['error_msg_ctv_android_app_name'] = 'This field is required';
                validations = false;
            }else{
                fieldStates['error_ctv_android_app_name'] = 'has-success';
                fieldStates['error_msg_ctv_android_app_name'] = '';   
            }
            if(formdetails.ctv_android_package_name.trim() === ''){
                fieldStates['error_ctv_android_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_android_package_name'] = 'This field is required';
                validations = false;
            }else if(!formValidations.validateAppPackage(formdetails.ctv_android_package_name) || formValidations.verifySpecialCharacters(formdetails.ctv_android_package_name)){
                fieldStates['error_ctv_android_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_android_package_name'] = 'Invalid app package name.';
                validations = false;
            }else if(projectDists && validateDuplicatePackageNameExists('android')){
                fieldStates['error_ctv_android_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_android_package_name'] = 'Package name already exists .Please try with different package name';
                validations = false;
            }
            else{
                fieldStates['error_ctv_android_package_name'] = 'has-success';
                fieldStates['error_msg_ctv_android_package_name'] = '';   
            }
        }

        if(formdetails.ctv_ios_app_enabled){
            if(formdetails.ctv_ios_app_name.trim() === ''|| formValidations.verifySpecialCharacters(formdetails.ctv_ios_app_name)){
                fieldStates['error_ctv_ios_app_name'] = 'has-danger';
                fieldStates['error_msg_ctv_ios_app_name'] = 'This field is required';
                validations = false;
            }else{
                fieldStates['error_ctv_ios_app_name'] = 'has-success';
                fieldStates['error_msg_ctv_ios_app_name'] = '';   
            }

            if(formdetails.ctv_ios_package_name.trim() === ''){
                fieldStates['error_ctv_ios_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_ios_package_name'] = 'This field is required';
                validations = false;
            }else if(!formValidations.validateAppPackage(formdetails.ctv_ios_package_name) || formValidations.verifySpecialCharacters(formdetails.ctv_android_package_name)){
                fieldStates['error_ctv_ios_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_ios_package_name'] = 'Invalid app package name.';
                validations = false;
            }else if(projectDists && validateDuplicatePackageNameExists('ios')){
                fieldStates['error_ctv_ios_package_name'] = 'has-danger';
                fieldStates['error_msg_ctv_ios_package_name'] = 'Package name already exists .Please try with different package name';
                validations = false;
            }
            else{
                fieldStates['error_ctv_ios_package_name'] = 'has-success';
                fieldStates['error_msg_ctv_ios_package_name'] = '';   
            }
        }
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
        return validations;
    }

    const saveAndroidGeneralSettings = async()=>{

        if(validateForm()){
            setShowAndroidSaveButton(false);
            setShowAndroidLoader(true);
            const settingsSaved = await ctvAppService.saveCTVAppGeneralSettings(formdetails);
            if(settingsSaved.status && settingsSaved.data){
                setTimeout(()=>{
                    setShowAndroidLoader(false);
                    setShowAndroidSaveButton(true);
                    setAndroidAlert({show:true,type:'success',message:"CTV App General Settings saved succesfully"})
                },1000);
            }else{
                setTimeout(()=>{
                    setShowAndroidLoader(true);
                    setShowAndroidSaveButton(false);
                    setAndroidAlert({show:true,type:'danger',message:"CTV App General Settings could not be saved"})
                },1000);
            }
            setTimeout(()=>{
                setAndroidAlert({show:false,type:'',message:''});
            },3000);
        }
    }


    const saveIOSGeneralSettings = async()=>{

        if(validateForm()){
            setShowIOSSaveButton(false);
            setShowIOSLoader(true);
            const settingsSaved = await ctvAppService.saveCTVAppGeneralSettings(formdetails);
            if(settingsSaved.status && settingsSaved.data){
                setTimeout(()=>{
                    setShowIOSLoader(false);
                    setShowIOSSaveButton(true);
                    setIOSAlert({show:true,type:'success',message:"CTV App General Settings saved succesfully"})
                },1000);
            }else{
                setTimeout(()=>{
                    setShowIOSLoader(true);
                    setShowIOSSaveButton(false);
                    setIOSAlert({show:true,type:'danger',message:"CTV App General Settings could not be saved"})
                },1000);
            }
            setTimeout(()=>{
                setIOSAlert({show:false,type:'',message:''});
            },3000);
        }
    }


    console.log("formdetails", formdetails)

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Row>
                                        <Col>
                                            CTV Android App General Settings
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="ctvAppGenSettings" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Enable CTV</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Switch name="ctv_android_app_enabled"
                                                    offColor="success"
                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                    onColor="success"
                                                    onText={<i className="nc-icon nc-check-2" />}
                                                    value={formdetails.ctv_android_app_enabled}
                                                    onChange={(value, enabled) => handleSwitchChange('ctv_android_app_enabled', enabled)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formdetails.ctv_android_app_enabled ? 
                                        <>
                                            <Row>
                                                <Label sm="2">Autoplay Live Video</Label>
                                                <Col sm="7">
                                                    <FormGroup>
                                                        <Switch name="ctv_android_autoplay"
                                                            offColor="success"
                                                            offText={<i className="nc-icon nc-simple-remove" />}
                                                            onColor="success"
                                                            onText={<i className="nc-icon nc-check-2" />}
                                                            value={formdetails.ctv_android_autoplay}
                                                            onChange={(value, enabled) => handleSwitchChange('ctv_android_autoplay', enabled)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2"> App Name</Label>
                                                <Col sm="7">
                                                    <FormGroup className={fieldStates['error_ctv_android_app_name']}>
                                                        <Input
                                                            type="text"
                                                            id="ctv_android_app_name"
                                                            name="ctv_android_app_name"
                                                            value={formdetails.ctv_android_app_name}
                                                            onChange={handleChange}
                                                        />
                                                        {fieldStates['error_ctv_android_app_name'] === 'has-danger' ?
                                                            <label className="error">
                                                                {fieldStates['error_msg_ctv_android_app_name']}
                                                            </label>
                                                        :
                                                            null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2"> Package Name</Label>
                                                <Col sm="7">
                                                    <FormGroup className={fieldStates['error_ctv_android_package_name']}>
                                                        <Input
                                                            type="text"
                                                            id="ctv_android_package_name"
                                                            name="ctv_android_package_name"
                                                            value={formdetails.ctv_android_package_name}
                                                            onChange={handleChange}
                                                        />
                                                        {fieldStates['error_ctv_android_package_name'] === 'has-danger' ?
                                                            <label className="error">
                                                                {fieldStates['error_msg_ctv_android_package_name']}
                                                            </label>
                                                        :
                                                            null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        null
                                    }
                                    
                                    {
                                        showAndroidSaveButton ?
                                            <Button
                                                className='btn-round'
                                                color='info'
                                                type='button'
                                                onClick={saveAndroidGeneralSettings}
                                            >
                                                Save
                                            </Button>
                                            : ""
                                    }
                                    {
                                        androidAlert.show ?
                                            <Alert color={androidAlert.type}>
                                                {androidAlert.message}
                                            </Alert>
                                            :
                                            ''
                                    }
                                    {
                                        showAndroidLoader ?
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            ''
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Row>
                                        <Col>
                                            CTV IOS App General Settings
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="ctvAppGenSettings" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Enable CTV</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Switch name="ctv_ios_app_enabled"
                                                    offColor="success"
                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                    onColor="success"
                                                    onText={<i className="nc-icon nc-check-2" />}
                                                    value={formdetails.ctv_ios_app_enabled}
                                                    onChange={(value, enabled) => handleSwitchChange('ctv_ios_app_enabled', enabled)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formdetails.ctv_ios_app_enabled ? 
                                        <>
                                            <Row>
                                                <Label sm="2">Autoplay Live Video</Label>
                                                <Col sm="7">
                                                    <FormGroup>
                                                        <Switch name="ctv_ios_autoplay"
                                                            offColor="success"
                                                            offText={<i className="nc-icon nc-simple-remove" />}
                                                            onColor="success"
                                                            onText={<i className="nc-icon nc-check-2" />}
                                                            value={formdetails.ctv_ios_autoplay}
                                                            onChange={(value, enabled) => handleSwitchChange('ctv_ios_autoplay', enabled)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2"> App Name</Label>
                                                <Col sm="7">
                                                    <FormGroup className={fieldStates['error_ctv_ios_app_name']}>
                                                        <Input
                                                            type="text"
                                                            id="ctv_ios_app_name"
                                                            name="ctv_ios_app_name"
                                                            value={formdetails.ctv_ios_app_name}
                                                            onChange={handleChange}
                                                        />
                                                        {fieldStates['error_ctv_ios_app_name'] === 'has-danger' ?
                                                            <label className="error">
                                                                {fieldStates['error_msg_ctv_ios_app_name']}
                                                            </label>
                                                        :
                                                            null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2"> Package Name</Label>
                                                <Col sm="7">
                                                    <FormGroup className={fieldStates['error_ctv_ios_package_name']}>
                                                        <Input
                                                            type="text"
                                                            id="ctv_ios_package_name"
                                                            name="ctv_ios_package_name"
                                                            value={formdetails.ctv_ios_package_name}
                                                            onChange={handleChange}
                                                        />
                                                        {fieldStates['error_ctv_ios_package_name'] === 'has-danger' ?
                                                            <label className="error">
                                                                {fieldStates['error_msg_ctv_ios_package_name']}
                                                            </label>
                                                        :
                                                            null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        null
                                    }
                                    
                                    {
                                        showIOSSaveButton ?
                                            <Button
                                                className='btn-round'
                                                color='info'
                                                type='button'
                                                onClick={saveIOSGeneralSettings}
                                            >
                                                Save
                                            </Button>
                                            : ""
                                    }
                                    {
                                        iosAlert.show ?
                                            <Alert color={iosAlert.type}>
                                                {iosAlert.message}
                                            </Alert>
                                            :
                                            ''
                                    }
                                    {
                                        showIOSLoader ?
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            ''
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default CTVGeneralSettings;
