import React, { useEffect, useState ,useContext, createFactory} from "react";
import formValidations from "../../variables/form-validations";
import faqService from "../../services/api/faq-service";
import { useHistory } from "react-router-dom";
import UserContext from "../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

const CreateFAQGroup = (props) => {
    const history = new useHistory();
    const context = useContext(UserContext);
    const [groups, setGroups] = useState(false);
    const [formdetails, setFormDetails] = useState({ name: "" });
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });

    useEffect(() => {
        (async () => {
            let groupsData = await faqService.getFAQGroups();
            // if(!groupsData.status && groupsData.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            // }
            if (groupsData.status && groupsData.data) {
                setGroups(groupsData.data);
            }
        })();
    }, []);

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value.trim(),
        });
    };

    const validateName = () => {
        if (formdetails.name.trim() === "") {
            setNameState("has-danger");
            setNameError("This field is required");
            return false;
        }

        if (groups && groups.length > 0) {
            let duplicateName = false;
            groups.forEach(function (details) {
                if (formdetails.name === details.name) {
                    duplicateName = true;
                }
            });
            if (duplicateName) {
                setNameState("has-danger");
                setNameError(
                    "You have already created the faq group with this name."
                );
                return false;
            }
        }

        setNameState("has-success");
        return true;
    };

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        let namevalidation = validateName();
        if (namevalidation) {
            setShowLoader(true);
            setShowSaveButton(false);
            let createFAQGroup = await faqService.createFAQGroup(
                formdetails
            );
            if(!createFAQGroup.status && createFAQGroup.error.errorCode === 403){
                context.setIsAuthorized(context);
                setShowLoader(false);
                setShowSaveButton(true);
            }
            else if (createFAQGroup.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "FAQ Group was created Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    history.push("/faq/faqgroups");
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "FAQ Group could not be created, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Create New FAQ Group
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol"
                                    action=""
                                    id="createFAQGroup"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Label sm="2">FAQ Group Name <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={namestate}>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                />
                                                {namestate === "has-danger" ? (
                                                    <label className="error">
                                                        {nameerror}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {showSaveButton ? (
                                        <div>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="button"
                                                onClick={validateForm}
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="danger"
                                                type="button"
                                                onClick={(event) => {
                                                    history.push(
                                                        `/faq/faqgroups`
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default CreateFAQGroup;
