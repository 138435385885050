

const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
const verifyPhoneNumber = (value) => {
    var phoneNumberRex = /^\d{10}$/;
    if (phoneNumberRex.test(value)) {
      return true;
    }
    return false;
  };
  
  const verifyPhone = (value) => {
    var phoneNumberRex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{0,6}$/;
    if (phoneNumberRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyExactLength = (value, length) => {
    
    if (value.length === length) {
      console.log(value.length);
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
const compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  const verifyUrl = (value) => {
    // try {
    //   new URL(value);
    //   return true;
    // } catch (_) {
    //   return false;
    // }
    // value = value.replace(/^http:\/\//i, '');
    // value = value.replace(/^https:\/\//i, '');
    var urlRex = /^https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (urlRex.test(value)) {
      return true;
    }
    return false;
  };
  
const verifyDomain = (value) => {
  const invalidInitilals = ["-"];
  value = value.replace(/^http:\/\//i, '');
  value = value.replace(/^https:\/\//i, '');

  if(invalidInitilals.includes(value[0])){
      return false;
  }

  var urlRex = /^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?\.[a-z]{2,}$/;
  if (urlRex.test(value)) {
    return true;
  }
  return false;
};

  const verifyWebsiteUrl = (value) => {
    // try {
    //   new URL(value);
    //   return true;
    // } catch (_) {
    //   return false;
    // }
    value = value.replace(/^http:\/\//i, '');
    value = value.replace(/^https:\/\//i, '');
    console.log(value)
    // var urlRex = /^[a-zA-Z0-9]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9]{2,})+$/;
    // var urlRex = /^[a-zA-Z0-9]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9_]*)*$/;
    var urlRex = /^[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9-_](?:\.[a-zA-Z0-9-_]{2,})+(\/[a-zA-Z0-9-_]*)*$/;

    if (urlRex.test(value)) {
      return true;
    }
    return false;
  };


  const verifyAlphaNumeric = (value) => {
    var numberRex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const checkValueInArray = (value, valueArray, fieldType) => {

    let validated = false;
    if(valueArray.length){
        if(fieldType=='array' || fieldType=='cat_array'){
            if(value && value.length){
              let optionsvalidated = 0;
              value.forEach(function(optionvalue){
                valueArray.forEach(function(option){
                  if(option.value == optionvalue){
                    optionsvalidated++;
                  }
                })
              })
              if(optionsvalidated==value.length){
                validated = true;
              }
              
            }else{
              validated = true;
            }
        }else{
          valueArray.forEach(function(option){
            if(option.value == value){
                validated = true;
              }
          })
        }
        
    }
    return validated;
  }

  const validateCSS = (value) => {
    return true;
  };
  const validateVersion =(value)=>{
    var versionRex = /^\d+(\.\d+){0,2}$/;
    if (versionRex.test(value)) {
      return true;
    }
    return false;
  }
  const validateAppPackage = (value) => {
    var packageRex = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/;
    if (packageRex.test(value)) {
      return true;
    }
    return false;
  }
  const verifySpecialCharacters = (value) => {
    var specialCharacterRex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    if (specialCharacterRex.test(value)) {
      return true;
    }
    return false;
  }
export default {
      verifyEmail,
      verifyLength,
      compare,
      verifyNumber,
      verifyUrl,
      verifyDomain,
      verifyWebsiteUrl,
      verifyExactLength,
      verifyAlphaNumeric,
      checkValueInArray,
      validateCSS,
      validateVersion,
      verifyPhoneNumber,
      verifyPhone,
      validateAppPackage,
      verifySpecialCharacters
  }