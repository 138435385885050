import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
// import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const MediaStorageCountWidget = (props)=> {

  const [totalStorage,setTotalStorage] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let storageData = await projectService.getProjectStorageMedia(props.projectId);
            if(storageData.status && storageData.data.count){
                let mediaStorage = storageData.data.count;
                if(mediaStorage > 1000){
                    mediaStorage = (Math.round(mediaStorage/1000 * 100) / 100).toFixed(0)+'MB';
                }else{
                    mediaStorage = (Math.round(mediaStorage * 100) / 100).toFixed(0)+'KB';
                }
                setTotalStorage(mediaStorage)
            }
            setRenderComponent(true);
        })();
    }, []);  
 

    return (
        <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
              <i className="fa fa-hdd-o text-success" />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">Media Storage</p>
                <CardTitle tag="p">
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalStorage}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar-o" />
            Till Date
          </div>
        </CardFooter>
      </Card>
    );
  
  
}

export default MediaStorageCountWidget;
