import React, { useState } from "react";
import "./TagInput.scss";
const TagInput = (props) => {
    // const [tags, setTags] = useState([]);
    const [currentValue, setCurrentValue] = useState("")

    const removeTag = (index) => {
        const tempTags = [...props.tagsArray];

        tempTags.splice(index, 1);
        props.setTagsArray(tempTags);
    };

    const handleKeyPress = (event) => {
        const val = event.target.value;
        
        if(props.isValidValue(val)===false) return;
        if (event.key === "Enter" && val) {
            setCurrentValue("")
            if (props.tagsArray.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
                return;
            }
            props.setTagsArray([...props.tagsArray, val]);
            // tagInput.value = null;
        } else if (event.key === "Backspace" && !val) {
            removeTag(props.tagsArray.length - 1);
        }
    };

    const handleChange = (event) =>{
        if(props.onChange(event.target.value))
        {
      }
      setCurrentValue(event.target.value);
      
    }

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {props.tagsArray.map((tag, i) => (
                    <li key={tag}>
                        {tag}
                        <button
                            type="button"

                            onClick={() => {
                                removeTag(i);
                            }}>
                            +
                        </button>
                    </li>
                ))}
                <li className="input-tag__tags__input">
                    <input type="text" onKeyDown={handleKeyPress} value={currentValue}  onChange={handleChange}/>
                </li>
            </ul>
        </div>
    );
};

export default TagInput;
