import React, { useEffect, useState, useContext  } from "react";
import contentService from "../../services/api/content-service";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import projectsService from "../../services/api/projects-service";
import Select from "react-select";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";
import generalHelper from '../../variables/general-helper.js';
import moment from 'moment';
import UserContext from "../../UserContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

const Media = (props) => {
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({
    project_id: "",
    title: "",
    guid: "",
    media_type: "image",
    status: "",
    is_processed: "",
    is_failed: "",
    from_date:"",
    to_date:""
  });
  const [articlesMedia, setArticlesMedia] = useState(false);
  const [totalArticlesMedia, setTotalArticlesMedia] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search, setSearchDetails] = useState({
    project_id: "",
    title: "",
    guid: "",
    media_type: "image",
    status: "",
    is_processed: "",
    is_failed: "",
    from_date:"",
    to_date:""
  });
  const [records,setRecords]= useState(10);
  const [publisherId, setPublisherId] = useState("");
  const [publisherNameArray, setPublisherNames] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectNameArray, setProjectNames] = useState([]);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [publisher, setPublisher] = useState("");
  // const [status, setStatus] = useState(false);
  // const [processed, setProcessed] = useState(false);
  // const [failed, setFailed] = useState(false);
  // const [mediaType, setMediaType] = useState(false);
  // const [toDate,setToDate] = useState("");
  // const [fromDate,setFromDate] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [showReprocessButton, setShowReprocessButton] = useState(true);
  const pathname = window.location.href;
  useEffect(()=>{
    let filtersJSONString= generalHelper.getValueFromQueryString("filters");
    if(filtersJSONString){
      try{
        let filters = JSON.parse(filtersJSONString);
        setFormDetails({...formdetails,...filters});
      }catch(error){
        setFormDetails({
        publisher_id: "",
        project_id: "",
        title: "",
        guid: "",
        media_type: "image",
        status: "",
        is_processed: "",
        is_failed: "",
        from_date:"",
        to_date:""
      });
      }
    }else{
      setFormDetails({
        publisher_id: "",
        project_id: "",
        title: "",
        guid: "",
        media_type: "image",
        status: "",
        is_processed: "",
        is_failed: "",
        from_date:"",
        to_date:""
      });
    }
    let pageNumber = new URLSearchParams(window.location.search).get("page");
    if(pageNumber){
      setActivePage(pageNumber);
    }
    else{
      pageNumber=1;
      updateQueryParams("page",1);
    }
    setActivePage(pageNumber);
    setSearchDetails({});
},[pathname]);

  let statusArray = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];
  let processedStatusArray = [
    {
      value: true,
      label: "Processed",
    },
    {
      value: false,
      label: "Not Processed",
    },
  ];
  let failedStatusArray = [
    {
      value: true,
      label: "Fail",
    },
    {
      value: false,
      label: "Pass",
    },
  ];
  let mediaTypeArray = [
    {
      value: "image",
      label: "Image",
    },
    {
      value: "video",
      label: "Video",
    },
    {
      value: "audio",
      label: "Audio",
    },
  ];

  useEffect(() => {
    (async () => {
      let publishers = await projectsService.getAllPublishers();
      if (publishers.status) {
        let getPublishers = [];
        let publisherData = publishers.data;
        if (publisherData.length) {
          publisherData.forEach(function (publisherDetails) {
            let publisherValue = {
              value: publisherDetails.id,
              label: publisherDetails.name + "(" + publisherDetails.email + ")",
              email: publisherDetails.email,
            };
            getPublishers.push(publisherValue);
          });
        }
        setPublisherNames(getPublishers);
      }
    })();
  }, [reloadComponent]);

  useEffect(() => {
    (async () => {
      // if (publisher) {
        let projects = await projectsService.getPublisherProjects(publisher);
        let publisherProjects = [];
        if (projects.status) {
          let projectData = projects.data;
          if (projectData.length) {
            projectData.forEach(function (projectDetails) {
              let projectValue = {
                value: projectDetails.id,
                label: projectDetails.name,
              };
              publisherProjects.push(projectValue);
            });
          }
          setProjectNames(publisherProjects);
        }
      // }
    })();
  }, [publisher,reloadComponent]);

  useEffect(() => {
    (async () => {
      let response = await contentService.getArticlesMedia(
        formdetails,
        activePage,
        records
      );
      if(!response.status && response.error.errorCode === 403){
        let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
        window.history.replaceState({},'',newurl)
      }
      if (response.status && response.data) {
        setArticlesMedia(response.data);
      }
      response = await contentService.getTotalArticlesMedia(formdetails);
      // if(!response.status && response.error.errorCode === 403){
      //   context.setIsAuthorized(context);
      // }
      if (response.status && response.data) {
        setTotalArticlesMedia(response.data.count);
      }
      setRenderComponent(Math.random());
    })();
  }, [activePage,records, search]);
 

  const updateQueryParams = (key,value)=>{
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      window.history.pushState({}, '', newurl);
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    updateQueryParams("page",pageNumber);

  };
  const changeRecord = (record) => {
    setRecords(record);
  }

  const onImageError = (event) => {
    // event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png";
    event.target.src =
      "https://mcmscache.epapr.in/mcms/461/9e326070049c91c0a19855f334fe114b53f733e0.svg";
  };

  let articlesMediaElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!articlesMedia || !articlesMedia.length) {
    if (!renderComponent) {
      articlesMediaElements.push(
        <td colSpan="10" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only"> Loading... </span>
          </Spinner>
        </td>
      );
    } else {
      articlesMediaElements.push(
        <td colSpan="10" className="text-center">
          <CardTitle tag="h5"> No Articles Media Found </CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    articlesMedia.forEach(function (articleDetails) {
      let className = "";
      if (!articleDetails.is_processed) {
        className = "table-warning";
      }
      if (!articleDetails.status) {
        className = "table-danger";
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      articlesMediaElements.push(
        <tr id={"row_" + articleDetails.id} className={className}>
          <td className="text-left"> {(activePage - 1) * 10 + i} </td>
          <td className="text-left">
            {articleDetails.project_id && articleDetails.project_id.publishers_id ? articleDetails.project_id.publishers_id.name : ""}
          </td>
          <td className="text-left">
            {articleDetails.project_id ? articleDetails.project_id.name : ""}
          </td>
          <td className="text-left">
            {articleDetails.article_id ? articleDetails.article_id.guid : ""}
          </td>
          <td className="text-left">
            {articleDetails.article_id ? (
              <Link
                to={
                  "/contentsettings/article_view?articleId=" +
                  articleDetails.article_id._id
                }
              >
                {articleDetails.article_id.title}
              </Link>
            ) : (
              ""
            )}
          </td>
          <td className="text-left"> {articleDetails.filesize} </td>
          <td className="text-left"> {articleDetails.media_type} </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.createdAt}
            </Moment>
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.updatedAt}
            </Moment>
          </td>
          <td className="text-left">
            {articleDetails.is_failed ? articleDetails.reason : "-"}
          </td>
        </tr>
      );
      if (i == articlesMedia.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == articlesMedia.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    });
  }

  const handleTitleChange = (event) => {
    setFormDetails({
      ...formdetails,
      title: event.target.value,
    });
  };

  const handleGUIDChange = (event) => {
    setFormDetails({
      ...formdetails,
      guid: event.target.value.trim(),
    });
  };

  const handleSubmit = function (e) {
    searchArticles();
    e.preventDefault();
  };

  const searchArticles = () => {
    generalHelper.addKeyToQueryString("filters",JSON.stringify(formdetails));
    setSearchDetails(formdetails);
  };
  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id: "",
    });
    setProjectId("");
    setPublisherId(event);
    setPublisher(event.value);
  };

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event);
  };
  const changeMediaType = (event) => {
    setFormDetails({
      ...formdetails,
      media_type: event.value,
    });
    // setMediaType(event);
  };
  const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    // setStatus(event);
  };
  const changeProcessed = (event) => {
    setFormDetails({
      ...formdetails,
      is_processed: event.value,
    });
    // setProcessed(event);
  };
  const changeFailed = (event) => {
    setFormDetails({
      ...formdetails,
      is_failed: event.value,
    });
  };
  const handleFromDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      from_date: event._d,
    });
  }

  const handleToDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      to_date: event._d,
    });
  }
  const processMedia = async () => {
      let unique = [];
        articlesMedia.forEach(element => {
          let isPresent = false;
            unique.forEach((data)=> {
              if(data.article_id == element.article_id._id) {
                isPresent = true;
              }
            }) 
          if(!isPresent){
                unique.push({
                  article_id : element.article_id._id,
                  project_id : element.project_id._id
                });
            }
        });
        if(unique.length) {
          setShowLoader(true);
          setShowReprocessButton(false);
          const response  = await contentService.resyncArticles(unique);
          if(!response.staus && response.error.errorCode === 403){
            context.setIsAuthorized(context);
          }
          else if(response.status && response.data) {
            console.log("Response",response.data);
            setShowReprocessButton(true)
            setTimeout(() => {
              setShowLoader(false);
              setAlert({
                show: true,
                type: "success",
                message: "Media Reprocess job assigned to worker",
              });
            }, 2000);
            setTimeout(()=>{
              setAlert({
                show: false,
              });
              setReloadComponent(Math.random());
            },5000);
          }
          setShowReprocessButton(true)
        }
      
  }
  const resetForm = () => {
    setPublisherId("");
    setPublisher("");
    setProjectNames("");
    setProjectId("");
    let defaultFormDetails ={
      publisher_id: "",
      project_id: "",
      status: "",
      media_type: "image",
      title: "",
      guid: "",
      is_failed: "",
      is_processed: "",
      from_date:"",
      to_date:""
    }

    setFormDetails({
      ...formdetails,
      ...defaultFormDetails
    });
    document.getElementById("title").value = "";
    document.getElementById("guid").value = "";
    
    let jsonString = JSON.stringify({
      ...formdetails,
      ...defaultFormDetails
    });
    generalHelper.addKeyToQueryString("filters",jsonString);
    generalHelper.addKeyToQueryString("page",1);
    setActivePage(1);
    setSearchDetails({
      ...formdetails,
      ...defaultFormDetails
    });

    setReloadComponent(Math.random());
  };
  return (
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Media Dashboard ({formdetails.media_type})</CardTitle>
                <Form
                  id="mediafilters"
                  action=""
                  className="form"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Publisher .."
                        name="publisher_id"
                        id="publisher_id"
                        options={publisherNameArray}
                        // value={publisherId}
                        value={publisherNameArray && formdetails.publisher_id?publisherNameArray.filter(option=> option.value == formdetails.publisher_id):""}
                        
                        onChange={changePublisherId}
                      />
                    </Col>
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Project .."
                        name="project_id"
                        id="project_id"
                        options={projectNameArray}
                        value={projectNameArray && formdetails.project_id?projectNameArray.filter(option=> option.value == formdetails.project_id):""}
                        
                        // value={projectId}
                        onChange={changeProjectId}
                      />
                    </Col>
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Media Type .."
                        name="mediaType"
                        id="mediaType"
                        options={mediaTypeArray}
                        value={formdetails.media_type?mediaTypeArray.filter(option=> option.value == formdetails.media_type):""}
                        // value = {formdetails.media_type}
                        onChange={changeMediaType}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>  
                    <Col md="4">
                      {formdetails.status}
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Status .."
                        name="status"
                        id="status"
                        options={statusArray}
                        // value={status}
                        value={formdetails.status!==""?statusArray.filter(option=> option.value == formdetails.status):""}
                        // value ={formdetails.status}
                        onChange={changeStatus}
                      />
                    </Col>
                    <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select processed status .."
                        name="processed"
                        id="processed"
                        options={processedStatusArray}
                        // value={processed}
                        value={formdetails.is_processed!==""?processedStatusArray.filter(option=> option.value == formdetails.is_processed):""}
                        // value={formdetails.is_processed}
                        onChange={changeProcessed}
                      />
                    </Col>
                    <Col md="4">
                      {formdetails.is_failed}
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Failed Status .."
                        name="is_failed"
                        id="is_failed"
                        options={failedStatusArray}
                        // value ={formdetails.is_failed}
                        // value={failed}
                        value={formdetails.is_failed!==""?failedStatusArray.filter(option=> option.value == formdetails.is_failed):""}
                        onChange={changeFailed}
                      />
                    </Col>
                    </Row>
                    <br />
                    <Row>
                    <Col md="3">
                      <Input
                        value={formdetails.guid}
                        type="text"
                        name="guid"
                        id="guid"
                        onChange={handleGUIDChange}
                        placeholder="Search by Article guid"
                      />
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value ={formdetails.title}
                        onChange={handleTitleChange}
                        placeholder="Search in title"
                      />
                    </Col>
                    <Col md="3">
                  <ReactDatetime
                      value={formdetails.from_date? moment(formdetails.from_date).format("DD MMM YYYY"):""}
                      onChange={handleFromDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                        id:"fromDate",
                        autoComplete : "off"
                      }
                    }
                    closeOnSelect
                    />
                  </Col>
                  <Col md="3">
                  <ReactDatetime
                      value={formdetails.to_date? moment(formdetails.to_date).format("DD MMM YYYY"):""}
                      onChange={handleToDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                        id:"to_date",
                        name:"to_date",
                        autoComplete : "off",
                        
                      }}
                      closeOnSelect
                    />
                  </Col>
                  </Row>
                  <br />
                  <Row>
                   <Col md="4">
                    <div style={{'float':'left'}}>
                      <Button
                        className="btn-round"
                        color="info"
                        type="submit"
                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <hr />
                {totalArticlesMedia > 0 ? 
                  <Row>
                    <Col md="4">
                      <div
                        style={{
                          float: "right",
                          margin: "5px",
                        }}
                      >
                        <b>
                          {firstRecord} - {lastRecord} of {totalArticlesMedia}
                        </b>
                      </div>
                    </Col>
                    <Col md="5">
                      <div
                        style={{
                          float: "left",
                        }}
                      >
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalArticlesMedia}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                      </Col>
                      <Col sm="3">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          caret
                          color="secondary"
                          id="dropdownMenuButton"
                          type="button"
                        >
                          Number of record
                        </DropdownToggle>

                        <DropdownMenu aria-labelledby="dropdownMenuButton">
                          <DropdownItem href="#pablo" onClick={(e) =>{ e.preventDefault(); changeRecord(10)}}>
                            10
                          </DropdownItem>

                          <DropdownItem href="#pablo" onClick={(e) =>{ e.preventDefault(); changeRecord(25)}}>
                            25
                          </DropdownItem>

                          <DropdownItem href="#pablo" onClick={(e) =>{ e.preventDefault(); changeRecord(100)}}>
                            100
                          </DropdownItem>
                          <DropdownItem href="#pablo" onClick={(e) => {e.preventDefault(); changeRecord(500)}}>
                            500
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </Col>
                    {search.is_processed === false && showReprocessButton ? 
                    <Col>
                        <Button
                          style={{
                            float: "left",
                            "margin-top": "-5px",
                          }}
                          className="btn-round"
                          color="info"
                          onClick ={ processMedia}
                        >
                          Reprocess Unprocessed Media
                        </Button>
                    </Col>:""}
                    <Row>
                    
                    <Col>
                      
                        {alert.show ?
                          <Alert color={alert.type}>
                            {alert.message}
                          </Alert>
                      : 
                      ""}
                      
                      {showLoader ?
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      : ""
                      }
                    </Col>
                      </Row>
                  </Row>
                : null}
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left"> # </th>
                      <th className="text-left"> Publisher Name </th>
                      <th className="text-left"> Project Name </th>
                      <th className="text-left"> GUID </th>
                      <th className="text-left"> Title </th>
                      <th className="text-left"> File Size (kb) </th>
                      <th className="text-left"> Media Type </th>
                      <th className="text-left"> Created On </th>
                      <th className="text-left"> Modified On </th>
                      <th className="text-left"> Reason </th>
                    </tr>
                  </thead>
                  <tbody> {articlesMediaElements} </tbody>
                </Table>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );
};

// }

export default Media;
