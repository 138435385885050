import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import appBuildService from "../../services/api/app-build-job-service";
import projectsService from "../../services/api/projects-service";
import formValidations from "../../variables/form-validations";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './AppBuild.css';
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import QRCode from 'qrcode';
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import CreateAppKeyStore from "./CreateAppKeystore";
import UpdateAppKeyStore from "./UpdateKeystore";
import ManageKeyStore from './ManageKeystores';

const AndroidAppBuild = (props) => {
  // const project_id = new URLSearchParams(props.location.search).get('projectid');
  const project_id = props.projectId;
  const context = useContext(UserContext);
  const history = new useHistory();
  const [appBuildJobs, setAppBuildJobs] = useState(false);
  const [totalAppBuildJobs, setTotalAppBuildJobs] = useState(0);
  const [renderComponent, setRenderComponent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const [keyStore, setKeyStore] = useState(false);
  const [showKeystoreForm, setShowKeystoreForm] = useState(false);
  const [showManageKeystoreForm, setShowManageKeystoreForm] = useState(false);
  const [publisherId, setPublisherId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState("");
  const [versionNameState, setVersionNameState] = useState("");
  const [versionNameError, setVersionNameError] = useState("");
  const [versionName, setVersionName] = useState("");
  const [formdetails, setFormDetails] = useState({ projectId: project_id, versionName: '', platform: "android" });
  const [search, setSearchDetails] = useState({ projectId: project_id, versionName: '', platform: "android" });
  const [reloadVersion, setReloadVersion] = useState("");
  const [tooltipsState, setTooltipsState] = useState({});
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [fieldStates, setFieldStates] = useState({});
  const [lastVersion, setLastVersion] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [timeDifference, setTimeDifference] = useState(0);
  const [timeState, setTimeState] = useState(null);
  const records = 10;
  const generateQR = async text => {
    try {
      return await QRCode.toDataURL(text, { errorCorrectionLevel: 'H' });
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    (async () => {
      let getProjectDetails = await projectsService.getProjectDetails(project_id);
      // if(!getProjectDetails.status && getProjectDetails.error.errorCode === 403){
      //   context.setIsAuthorized(context);
      // }
      if (getProjectDetails.status) {
        setProjectName(getProjectDetails.data.name);
      }
    })()
  }, [])
  useEffect(() => {
    (async () => {
      let getAppKeystore = await appBuildService.getAppKeystore(project_id);
      // if(!getAppKeystore.status && getAppKeystore.error.errorCode === 403){
      //   context.setIsAuthorized(context);
      // }
      if (getAppKeystore.status) {
        setKeyStore(getAppKeystore.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let jobsData = await appBuildService.getAppBuildJobs({ project_id: project_id, platform: 'android' }, activePage, records);
      // if(!jobsData.status && jobsData.error.errorCode === 403){
      //   context.setIsAuthorized(context);
      // }
      if (jobsData.status && jobsData.data) {
        setAppBuildJobs(jobsData.data.appBuildJobs);
        setTotalAppBuildJobs(jobsData.data.count);
        if (jobsData.data && jobsData.data.appBuildJobs.length > 0) {
          let allJobs = jobsData.data.appBuildJobs;
          let timeDiff = (new Date().getTime() - new Date(allJobs[0].createdAt)) / (1000 * 60 * 60);
          setTimeDifference(timeDiff);
          // console.log(timeDiff)
          if (timeDiff < 0.5) {
            let timeOut = setTimeout(() => {
              setReloadComponent(Math.random());
            }, 10000);
            setTimeState(timeOut);
          }
        }
        for (const jobDetails of jobsData.data.appBuildJobs) {
          tooltipsState[jobDetails._id] = false;
          fieldStates[jobDetails._id + "_loader"] = false;
          fieldStates[jobDetails._id + "_button"] = true;
          fieldStates[jobDetails._id + "_alert"] = false;
          if (jobDetails.status === 6) {
            fieldStates[jobDetails._id + "_qrlink"] = await generateQR(jobDetails.s3_path);
          }

        }
        setTooltipsState({ ...tooltipsState, tooltipsState });
        setFieldStates({ ...fieldStates, fieldStates });
        if (jobsData.data.appBuildJobs.length) {
          setLastVersion(jobsData.data.appBuildJobs[0].version_name);
        }
      }

      setRenderComponent(true);
    })();
  }, [reloadComponent, search, activePage]);


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }
  const toggleTooltip = (id) => {
    tooltipsState[id] = !tooltipsState[id];
    setTooltipsState({
      ...tooltipsState,
      tooltipsState
    });
  }
  const reprocess = async (jobId) => {
    fieldStates[jobId + "_loader"] = true;
    fieldStates[jobId + "_button"] = false;
    setFieldStates({ ...fieldStates, fieldStates });
    let formdata = {
      jobId,
      platform: 'android'
    };
    const response = await appBuildService.retryAppBuildJob(formdata);
    if (!response.status && response.error.errorCode === 403) {
      context.setIsAuthorized(context);
    }
    else if (response.status) {
      setTimeout(() => {
        fieldStates[jobId + "_loader"] = false;
        fieldStates[jobId + "_alert"] = {
          show: true,
          type: "success",
          message: "Build Creation Retry Job Submitted Successfully",
        };
        setFieldStates({ ...fieldStates, fieldStates });

      }, 2000);
      setTimeout(() => {
        fieldStates[jobId + "_alert"] = {
          show: false,
          type: "",
          message: "",
        };
        setFieldStates({ ...fieldStates, fieldStates });
        window.location.reload();
      }, 5000);
    }
  };
  let appBuildJobsElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!appBuildJobs || !appBuildJobs.length) {
    if (!renderComponent) {
      appBuildJobsElements.push(
        <tr>
          <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </td>
        </tr>
      );
    } else {
      appBuildJobsElements.push(
        <tr>
          <td colSpan="6" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
          </td>
        </tr>
      );
    }
  } else {
    let i = 1;
    appBuildJobs.forEach(function (jobsDetails) {
      let className = '';
      if (jobsDetails.status != 6 && jobsDetails.error) {
        className = 'table-danger';
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      appBuildJobsElements.push(
        <tr className={className}>
          <td className="text-left">
            {(records * (activePage - 1)) + i}
          </td>
          <td className="text-left">
            {jobsDetails.version_name} <br />
          </td>
          <td className="text-left">
            <Moment format="DD MMM YYYY hh:mm:ss a">{jobsDetails.createdAt}</Moment>
          </td>
          <td className="text-left">

            <span style={{ cursor: "pointer" }} id={"tooltip_" + jobsDetails._id}>
              {jobsDetails.status != 6 ?
                (jobsDetails.error || (timeDifference > 0.5) ? <i class="fa fa-exclamation-triangle  fa-2x rediconcolor" data-toggle="tooltip" aria-hidden="true" />
                  :
                  <i className="fa fa-spinner fa-spin  fa-2x" data-toggle="tooltip" data-html="true" />)
                : ""}
              {jobsDetails.status == 6 ?
                <i class="fa fa-check fa-2x greeniconcolor" data-toggle="tooltip" data-html="true" /> : ""}
            </span>
            <Tooltip isOpen={tooltipsState[jobsDetails._id]}
              target={"tooltip_" + jobsDetails._id}
              placement="right"
              toggle={() => { toggleTooltip(jobsDetails._id) }}
            >
              <tr>
                <td align="left">
                  1 . Code Deployer
                </td>
                <td>
                  {jobsDetails.code_deployer === 1 ?
                    <i className="fa fa-check greeniconcolor" data-toggle="tooltip" data-html="true" />
                    :
                    (
                      jobsDetails.error ?
                        <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                        :
                        <i className="fa fa-spinner fa-spin" data-toggle="tooltip" data-html="true" />
                    )
                  }
                </td>
              </tr>
              <tr>
                <td align="left">
                  2 . Assets Builder
                </td>
                <td>
                  {jobsDetails.assets_builder === 1 ?
                    <i class="fa fa-check greeniconcolor" data-toggle="tooltip" data-html="true" />
                    :
                    (
                      jobsDetails.error ?
                        <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                        :
                        <i className="fa fa-spinner fa-spin" data-toggle="tooltip" data-html="true" />
                    )
                  }
                </td>
              </tr>
              <tr>
                <td align="left">
                  3 . Build Configurator
                </td>
                <td >
                  {jobsDetails.build_configurator === 1 ?
                    <i className="fa fa-check greeniconcolor" data-toggle="tooltip" data-html="true" />
                    :
                    (
                      jobsDetails.error ? <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                        :
                        <i className="fa fa-spinner fa-spin" data-toggle="tooltip" data-html="true" />
                    )
                  }
                </td>
              </tr>
              <tr>
                <td align="left">
                  4 . Build Dependency Reviewer
                </td>
                <td>
                  {
                    jobsDetails.build_dependency_reviewer === 1 ?
                      <i className="fa fa-check greeniconcolor" data-toggle="tooltip" title="completed" data-html="true" />
                      :
                      (jobsDetails.error ?
                        <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                        :
                        <i className="fa fa-spinner fa-spin" data-toggle="tooltip" data-html="true" />
                      )
                  }
                </td>
              </tr>
              <tr>
                <td align="left">
                  5 . Build Creator
                </td>
                <td>
                  {jobsDetails.build_creator === 1 ?
                    <i className="fa fa-check greeniconcolor" data-toggle="tooltip" data-html="true" />
                    :
                    (jobsDetails.error ? <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                      :
                      <i className="fa fa-spinner fa-spin" data-toggle="tooltip" data-html="true" />)
                  }
                </td>
              </tr>
              <tr>
                <td align="left">
                  6 . Build Exporter
                </td>
                <td>
                  {jobsDetails.build_exporter === 1 ?
                    <i className="fa fa-check greeniconcolor" data-toggle="tooltip" data-html="true" />
                    :
                    (jobsDetails.error ? <i class="fa fa-exclamation-triangle rediconcolor" aria-hidden="true" />
                      :
                      <i className="fa fa-spinner fa-spin  " data-toggle="tooltip" data-html="true" />)
                  }
                </td>
              </tr>

            </Tooltip>
          </td>
          <td className="text-left">
            {jobsDetails.status != 6 && jobsDetails.error ? jobsDetails.error : "-"}
          </td>
          <td className="text-left">
            {jobsDetails.status == 6 ?

              <div>
                <Row>
                  <Col sm="6">
                    {/* <div className="mx-2"> */}
                      <a target="_blank" href={jobsDetails.status == 6 ? jobsDetails.s3_path : ""}>
                        <Button
                          className="btn-round"
                          color="primary"
                          size="sm"
                          type="button"
                        >Download Apk
                        </Button>
                      </a>

                    {/* </div> */}
                  </Col>
                  <Col sm="6">
                    {/* <div> */}
                      <a target="_blank" href={jobsDetails.status == 6 ? jobsDetails.s3_bundle_path : ""}>
                        <Button
                          className="btn-round"
                          color="primary"
                          size="sm"
                          type="button"
                        >Download Bundle
                        </Button>
                      </a>
                    {/* </div> */}
                  
                  </Col>
                </Row>
                <Row>
                  <Col>

                      <img style={{ cursor: "pointer" }} title="Click Me" onClick={() => {
                        setShowQRPopup(

                          <ReactBSAlert
                            title="Scan & Download App"
                            onCancel={() => { setShowQRPopup(false) }}
                            openAnim={{ name: 'showSweetAlert', duration: 700 }}
                            showConfirm={false}
                            closeOnClickOutside
                          >
                            <img src={fieldStates[jobsDetails._id + "_qrlink"]} width="600" height="600">

                            </img>
                          </ReactBSAlert>

                        )
                      }} src={fieldStates[jobsDetails._id + "_qrlink"]} width="100" height="100">

                      </img>
                  </Col>

                </Row>



              </div>

              : ""}
            {jobsDetails.status != 6 && jobsDetails.error ?
              <>
                {fieldStates[jobsDetails._id + "_loader"] ?
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  : ""}
                {fieldStates[jobsDetails._id + "_button"] ?
                  <Button
                    className="btn-smb"
                    type="button"
                    onClick={() => { reprocess(jobsDetails._id) }}
                  >
                    Retry
                  </Button> : ""}
                {fieldStates[jobsDetails._id + "_alert"] ?
                  <Alert color={fieldStates[jobsDetails._id + "_alert"].type}>
                    {fieldStates[jobsDetails._id + "_alert"].message}
                  </Alert> : ""
                }
              </> : ""}
          </td>

          {/* <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{contractDetails.start_date}</Moment>
               </td> */}


        </tr>
      )
      if (i == appBuildJobs.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == appBuildJobs.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    })
  }

  const handleSubmit = function (e) { searchJobs(); e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id: ''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const searchJobs = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherId('');
    setProjectId('');
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      project_id: '',
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }
  const hideAlert = () => {
    setShowPopup(false);
    setVersionNameState("has-success");
    setVersionNameError("");
  }
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value
    });
    setVersionNameState("has-success");
    setVersionNameError("");
  }
  const handleBlur = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value,
    });
  }
  const validateVersionName = () => {
    if (formdetails.versionName === "") {
      setVersionNameState("has-danger");
      setVersionNameError("Version Name is required");
      return false;
    }
    if (!formValidations.validateVersion(formdetails.versionName)) {
      setVersionNameState("has-danger");
      setVersionNameError("Invalid Version name");
      return false;
    }
    if (lastVersion && versionCompare(formdetails.versionName, lastVersion) <= 0) {
      setVersionNameState("has-danger");
      setVersionNameError(" Version name should be greater than  previous one.");
      return false;
    }
    // if(versionCompare(formdetails.versionName,lastVersion)==0){
    //   setVersionNameState("has-danger");
    //   setVersionNameError(" Version name already exist.");
    //   return;
    // }
    setVersionNameState("has-success");
    setVersionNameError("");
    return true;
  }
  const processStart = async () => {
    if (validateVersionName()) {
      setShowLoader(true);
      setShowButton(false);
      clearTimeout(timeState);
      let createdJob = await appBuildService.createAppBuildJob(formdetails);
      if (!createdJob.status && createdJob.error.errorCode === 403) {
        context.setIsAuthorized(context);
      }
      else if (createdJob.status && createdJob.data) {
        setTimeout(() => {
          setShowLoader(false);

          setAlert({ show: true, type: 'success', message: 'Build Creation Job Submitted Successfully ' });
        }, 1000);

        setTimeout(() => {
          setAlert({ show: false, type: '', message: '' });
          setShowPopup(false);
          setShowButton(true);
          // setReloadComponent(Math.random());
        }, 4000);
        // const id = setInterval(()=>{ setReloadComponent(Math.random());},10000);
        // setIntervalId(id);
      }
      setReloadComponent(Math.random());
      // setReloadVersion(Math.random());
    } else {
      setTimeout(() => {
        setShowLoader(false);
        setAlert({ show: true, type: 'danger', message: 'Unable to create build job' });
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
        setShowPopup(false);
        setShowButton(true);
        // setReloadComponent(Math.random());
      }, 4000);
      // const id = setInterval(()=>{ setReloadComponent(Math.random());},10000);
      // setIntervalId(id);
    }
  }
  function versionCompare(a, b) {
    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);

    for (i = 0; i < l; i++) {
      diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
      if (diff) {
        return diff;
      }
    }
    return segmentsA.length - segmentsB.length;
  }

  const createNewVersion = async () => {
    if (keyStore) {
      let response = await appBuildService.getNewVersionName({ projectId: formdetails.projectId, platform: "android" });
      if (!response.status && response.error.errorCode === 403) {
        context.setIsAuthorized(context);
      }
      else if (response.status && response.data) {
        setVersionName(response.data);
        setFormDetails({ ...formdetails, versionName: response.data });
      }
      setShowPopup(true);
    } else {
      setShowKeystoreForm(true);
    }
  }

  const manageKeyStore = (event) => {
    setShowManageKeystoreForm(true)
  }

  if (showKeystoreForm) {
    props.setShowPlatforms(false);
    return <CreateAppKeyStore projectId={project_id} projectName={projectName} setShowKeystoreForm={setShowKeystoreForm} />
  }else if(showManageKeystoreForm){
    return <ManageKeyStore keyStore={keyStore} projectId={project_id} projectName={projectName} setShowManageKeystoreForm={setShowManageKeystoreForm}/>
  }
  else {
    props.setShowPlatforms(true);
    return (
      <div className="content">
        {showQRPopup}
        {showPopup ?
          <span>
            <ReactBSAlert
              title="Enter new Version name"
              closeOnClickOutside={false}
              showConfirm={false}
              showCancel={false}
              style={{ "position": "relative", "height": "300px", "width": "fit-content" }}
            >
              <div>
                {alert.show ?
                  <Alert color={alert.type}>
                    {alert.message}
                  </Alert>
                  : ''}

                {showLoader ? <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner> : ''}
                <FormGroup className={versionNameState}>
                  <Input
                    type="text"
                    name="versionName"
                    id="versionName"
                    value={formdetails.versionName}
                    onChange={handleChange}
                  // onBlur={handleBlur}
                  />
                  {versionNameState === "has-danger" ? (
                    <label className="error">
                      {versionNameError}
                    </label>
                  ) : null}
                </FormGroup>
                {showButton ?
                  <>
                    <Button
                      style={{ "backgroundColor": "blue" }}
                      className="btnB "
                      type="button"
                      onClick={(response) => processStart()}
                    >Create</Button>

                    <Button
                      className="btnBlk"
                      type="button"
                      onClick={() => hideAlert()}
                    >
                      Back
                    </Button></> : ""}
              </div>
            </ReactBSAlert>
          </span>
          : ""}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">
                    Android App Versions of your project {projectName}
                  </CardTitle>
                  <Col align="right">
                    <Button
                      className="btn-smb"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={() => createNewVersion()}
                    > {keyStore ? "Create New Version" : "Generate Keystore for your App"}
                    </Button>
                    {
                      keyStore ? 
                        <Button
                          className="btn-smb"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() => manageKeyStore()}
                        > Manage Keystore
                        </Button>

                      : null
                    }
                    <Button
                      className="btn btn-light"
                      style={{ "backgroundColor": "grey", "padding": "7px 5px" }}
                      size="sm"
                      type="button"
                      onClick={(event) => history.push("/project/appsettings?projectid=" + project_id)}
                    >
                      Go to App Setting
                    </Button>
                  </Col>
                  <Form id="appBuildJobFilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                    <Row>
                      <Col md="3">
                        {/* <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  /> */}
                      </Col>

                      <Col md="3">
                        {/* <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  /> */}
                      </Col>
                      {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Group.."
                    name = "plan_group_id"
                    id="plan_group_id"
                    options={planGroupOptions}
                    value={groupName}
                    onChange={changeGroupName}
                  />

                  </Col> */}
                      {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Plan Type .."
                    name = "type"
                    id="type"
                    options={planTypeArray}
                    value={planType}
                    onChange={changePlanType}
                  />
                  </Col> */}
                      {/* </Row>
                  <Row> */}

                      {/* <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={[{value:"1",label:"Active"},{value:"0",label:"Grace"},{value:"-2",label:"Expired"},{value:"-1",label:"Suspended"}]}
                    value={status}
                    onChange={changePlanStatus}
                  />
                  </Col> */}



                      {/* <Col md="6">
                    <Button
                      style={{float:'left','margin-top':'-5px'}}
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      style={{float:'left','margin-top':'-5px'}}
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                  </Col> */}
                    </Row>
                  </Form>
                  <hr></hr>
                  <Row>

                    <Col md="6">
                      <div style={{ float: 'right', margin: '5px' }}>
                        <b>{firstRecord} - {lastRecord} of {totalAppBuildJobs}</b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: 'left' }}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalAppBuildJobs}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th>

                        {/* <th className="text-left">Project Name</th> */}
                        <th className="text-left">Version</th>
                        {/* <th className="text-left">Apk Name</th>  */}
                        {/* <th className="text-left"></th>  */}

                        <th className="text-left">Created At</th>
                        {/* <th className="text-left">Created By</th>  */}
                        <th className="text-left">Status</th>
                        <th className="text-left">Reason</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{appBuildJobsElements}</tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }


}

export default AndroidAppBuild;
