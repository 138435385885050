import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import paymentLinkService from "../../services/api/payment-links-service.js";
import projectsService from "../../services/api/projects-service";
import Moment from "react-moment";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Form,
    Table,
    Row,
    Col,
    Container,
} from "reactstrap";

import Select from 'react-select';

const PaymentLinks = (props) => {
    const [formdetails, setFormDetails] = useState({ publisherId: "", projectId: "",status:"" });
    const history = new useHistory();
    const [search, setSearchDetails] = useState({ publisherId: "", projectId: "",status:"" });
    const [renderComponent, setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [totalPaymentLinks, setTotalPaymentLinks] = useState(0);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [publishersArray, setPublisherNames] = useState([]);
    const [publisherId, setPublisherId] = useState('');
    const [publisher, setPublisher] = useState('');
    const [status, setStatus] = useState("");
    const [projectsArray, setProjectNames] = useState();
    const [projectId, setProjectId] = useState('');
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);

    const records = 10;

    const statusArray = [
        {
            value : "created",
            label : "Created",
        },
        {
            value : "paid",
            label : "Paid",
        },
        {
            value : "failed",
            label : "failed",
        },
    ]

    useEffect(() => {
        (async () => {
            let publishers = await projectsService.getAllPublishers();
            if (publishers.status) {
                let getPublishers = [];
                let publisherDetails = publishers.data;
                if (publisherDetails.length) {
                    publisherDetails.forEach(function (publisherData) {
                        let publisherValue = { value: publisherData.id, label: publisherData.name + "(" + publisherData.email + ")", email: publisherData.email };
                        getPublishers.push(publisherValue);
                    })
                    setPublisherNames(getPublishers);
                }
            }
        })();
    }, [reloadComponent])

    useEffect(() => {
        (async () => {
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if (projects.status) {
                let projectDetails = projects.data;
                if (projectDetails.length) {
                    projectDetails.forEach(function (projectData) {
                        let projectValue = { value: projectData.id, label: projectData.name };
                        publisherProjects.push(projectValue);
                    })
                }
                setProjectNames(publisherProjects);
            }
        })();
    }, [publisher]);

    useEffect(() => {
        (
            async () => {
                let paymentLinks = await paymentLinkService.getPaymentLinks({ filters: formdetails, activePage, records });
                if (paymentLinks.status) {
                    setPaymentLinks(paymentLinks.data.paymentLinks);
                    setTotalPaymentLinks(paymentLinks.data.count);
                    if(paymentLinks.data.paymentLinks.length === 0){
                        setFirstRecord(0);
                        setLastRecord(0);
                    }
                    else{
                        setFirstRecord(((activePage-1)*records)+1);
                        setLastRecord((((activePage-1)*records)+1)+paymentLinks.data.paymentLinks.length-1)
                    }
                }
                setRenderComponent(true);
            }
        )();
    }, [search, reloadComponent, activePage]);

    const changeProject = (event) => {
        setFormDetails({
            ...formdetails,
            projectId: event.value,
        })
        setProjectId(event);
    }
    const changePublisher = (event) => {
        setFormDetails({
            ...formdetails,
            publisherId: event.value,
            projectId: ""
        });
        setProjectId('');
        setPublisherId(event);
        setPublisher(event.value);
    }

    const changeStatus = (event) => {
        setFormDetails({
            ...formdetails,
            status : event.value,
        });
        setStatus(event);
    }
    const handleSubmit = function (e) {
        searchPaymentLinks();
        e.preventDefault();
    }
    const searchPaymentLinks = () => {
        setActivePage(1);
        setSearchDetails(formdetails);
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const resetForm = () => {
        setPublisherId('');
        setProjectId('');
        setStatus("");
        setFormDetails({
            ...formdetails,
            publisherId: "",
            projectId: "",
            status : "",
        })
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
        setActivePage(1);
    }
    const viewPaymentLink = (e,paymentLinkId) => {
        e.preventDefault();
        history.push("/contract/view-payment-link?payment-link-id="+paymentLinkId);
    }
    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <Row>
                                    <Col>
                                    <Button
                                    style={{float:"right"}}
                                    className="btn-round"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event)=>{history.push("/contract/create-payment-links")}}
                                >
                                    Create Payment Link
                                </Button>
                                    </Col>
                                </Row>
                                <CardTitle tag="h4">
                                    Payment Links Dashboard
                                </CardTitle>
                                
                                <Form action="" method="POST" className="paymentLinks" onSubmit={handleSubmit} />
                                <Row>
                                    <Col md="3">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeHolder="Select Publisher"
                                            name="Publisher"
                                            id="publisher"
                                            style={{ 'heigth': '40px' }}
                                            options={publishersArray}
                                            value={publisherId}
                                            onChange={changePublisher}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeHolder="Select Project"
                                            name="Project"
                                            id="project"
                                            style={{ 'height': '40px' }}
                                            options={projectsArray}
                                            value={projectId}
                                            onChange={changeProject}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeHolder="Select Status"
                                            name="status"
                                            id="status"
                                            style={{'height':"40px"}}
                                            options={statusArray}
                                            value={status}
                                            onChange={changeStatus}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <div style={{'float':'left'}}>
                                        <Button
                                            className="btn-round"
                                            color="info"
                                            type="submit"
                                            onClick={searchPaymentLinks}
                                        >Search</Button>
                                        <Button
                                            className="btn-round"
                                            color="warning"
                                            type="submit"
                                            onClick={resetForm}
                                        >Reset</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                {totalPaymentLinks > 0 ? 
                                    <Row>
                                        <Col md="6">
                                            <div style={{ float: 'right'}}>
                                                <b>{firstRecord} - {lastRecord} of {totalPaymentLinks}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{ float: 'left' }}>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={records}
                                                    totalItemsCount={totalPaymentLinks}
                                                    pageRangeDisplayed={3}
                                                    onChange={handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                : null}
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">Project Name</th>
                                            <th className="text-left">Publisher name</th>
                                            <th className="text-left">Payment Link</th>
                                            <th className="text-left">Status</th>
                                            <th className="text-left">Updated At</th>
                                            <th className="text-left">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {totalPaymentLinks > 0 ?
                                        
                                        paymentLinks.map((data,i)=> (
                                            <tr key={i}>
                                                <td>{(i+((activePage-1)*records)+1)}</td>
                                                <td>{data.project_id.name}</td>
                                                <td>{data.publisher_name}</td>
                                                <td>{data.payment_link}</td>
                                                <td>{data.status}</td>
                                                <td><Moment format="DD-MM-YYYY hh:mm:ss a">
                                                        {data.updated_at}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Button 
                                                        title="View" 
                                                        onClick={(event)=>viewPaymentLink(event,data.id)} 
                                                        className="nav-link" 
                                                        id="viewBtn"
                                                        style={{backgroundColor: "#2F80ED",borderRadius: "20px",fontWeight: "normal"}}
                                                    >
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        )
                                    
                                        : <tr>
                                            <td colSpan="7" className="text-center">
                                                <CardTitle tag="h4">No Records Found</CardTitle>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaymentLinks;