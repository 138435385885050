import React, { useEffect, useState,useContext } from "react";
import projectsService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import Switch from "react-bootstrap-switch";
import UserContext from "../../UserContext";
import TimezoneSelect from 'react-timezone-select';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

var i18n = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Dawson': 'Pacific Time (US and Canada); Tijuana',
  'America/Boise': 'Mountain Time (US and Canada)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time (US and Canada)',
  'America/Indiana/Indianapolis': 'Indiana (East)',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Glace_Bay': 'Atlantic Time (Canada)',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Etc/GMT+2': 'Mid-Atlantic',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa"
};


const TemplateChanged = (props)=> {
  const projectId = props.projectId;
  const context = useContext(UserContext);
  const history = new useHistory();
  if(!projectId){
    history.push("/project/projects");
  }

  const [formdetails, setFormDetails] = useState({
    projectid : projectId,
    templateid:"",
    locale:"",
    timezone:"",
  });
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [templates, setTemplates] = useState(false);
  const [accesskey, setAccessKey] = useState(false);
  const [secretkey, setSecretKey] = useState(false);
  const [defaultTemplateId, setDefaultTemplateId] = useState(false);
  const [selectedTimezone,setSelectedTimezone] = useState('');

  useEffect( () => {
    (async () =>{
        let templateData = await projectsService.getProjectTemplates(projectId);
        if(templateData.status){
            let templateNames = templateData.data;
            let templateValues = [];
            Object.keys(templateNames).forEach(function(key) {
                templateValues.push({value:key,label:templateNames[key].name});
            });
          setTemplates(templateValues);
        }

        let getProjectDetails = await projectsService.getProjectDetails(projectId);
        if(getProjectDetails.status){
            setAccessKey(getProjectDetails.data.access_key);
            setSecretKey(getProjectDetails.data.secret_key);
            if(getProjectDetails.data.settings && getProjectDetails.data.settings.template_id){
                setDefaultTemplateId(getProjectDetails.data.settings.template_id);
                setFormDetails({
                  ...formdetails,
                  templateid: getProjectDetails.data.settings.template_id,
                  timezone: getProjectDetails.data.timezone,
                  locale: getProjectDetails.data.locale,
                });
                setSelectedTimezone({'value':getProjectDetails.data.timezone,'label':i18n[getProjectDetails.data.timezone]?i18n[getProjectDetails.data.timezone]:''})
            }else{
              setFormDetails({
                ...formdetails,
                timezone: getProjectDetails.data.timezone,
                locale: getProjectDetails.data.locale
              });
              setSelectedTimezone({'value':getProjectDetails.data.timezone,'label':i18n[getProjectDetails.data.timezone]?i18n[getProjectDetails.data.timezone]:''})
            }
        }else{
          history.push("/project/projects");
        }

    })();
  }, []);


  var languageOptions = [
      { value: "af", label: "Afrikaans" },
      { value: "sq", label: "Albanian" },
      { value: "am", label: "Amharic" },
      { value: "ar", label: "Arabic" },
      { value: "hy", label: "Armenian" },
      { value: "az", label: "Azerbaijani" },
      { value: "eu", label: "Basque" },
      { value: "be", label: "Belarusian" },
      { value: "bn", label: "Bengali" },
      { value: "bs", label: "Bosnian" },
      { value: "bg", label: "Bulgarian" },
      { value: "ca", label: "Catalan" },
      { value: "ceb", label: "Cebuano" },
      { value: "ny", label: "Chichewa" },
      { value: "zh", label: "Chinese (Simplified)" },
      { value: "zh-TW", label: "Chinese (Traditional)" },
      { value: "co", label: "Corsican" },
      { value: "hr", label: "Croatian" },
      { value: "cs", label: "Czech" },
      { value: "da", label: "Danish" },
      { value: "nl", label: "Dutch" },
      { value: "en", label: "English" },
      { value: "eo", label: "Esperanto" },
      { value: "et", label: "Estonian" },
      { value: "tl", label: "Filipino" },
      { value: "fi", label: "Finnish" },
      { value: "fr", label: "French" },
      { value: "fy", label: "Frisian" },
      { value: "gl", label: "Galician" },
      { value: "ka", label: "Georgian" },
      { value: "de", label: "German" },
      { value: "el", label: "Greek" },
      { value: "gu", label: "Gujarati" },
      { value: "ht", label: "Haitian Creole" },
      { value: "ha", label: "Hausa" },
      { value: "haw", label: "Hawaiian" },
      { value: "iw", label: "Hebrew" },
      { value: "hi", label: "Hindi" },
      { value: "hmn", label: "Hmong" },
      { value: "hu", label: "Hungarian" },
      { value: "is", label: "Icelandic" },
      { value: "ig", label: "Igbo" },
      { value: "id", label: "Indonesian" },
      { value: "ga", label: "Irish" },
      { value: "it", label: "Italian" },
      { value: "ja", label: "Japanese" },
      { value: "jw", label: "Javanese" },
      { value: "kn", label: "Kannada" },
      { value: "kk", label: "Kazakh" },
      { value: "km", label: "Khmer" },
      { value: "ko", label: "Korean" },
      { value: "ku", label: "Kurdish (Kurmanji)" },
      { value: "ky", label: "Kyrgyz" },
      { value: "lo", label: "Lao" },
      { value: "la", label: "Latin" },
      { value: "lv", label: "Latvian" },
      { value: "lt", label: "Lithuanian" },
      { value: "lb", label: "Luxembourgish" },
      { value: "mk", label: "Macedonian" },
      { value: "mg", label: "Malagasy" },
      { value: "ms", label: "Malay" },
      { value: "ml", label: "Malayalam" },
      { value: "mt", label: "Maltese" },
      { value: "mi", label: "Maori" },
      { value: "mr", label: "Marathi" },
      { value: "mn", label: "Mongolian" },
      { value: "my", label: "Myanmar (Burmese)" },
      { value: "ne", label: "Nepali" },
      { value: "no", label: "Norwegian" },
      { value: "ps", label: "Pashto" },
      { value: "fa", label: "Persian" },
      { value: "pl", label: "Polish" },
      { value: "pt", label: "Portuguese" },
      { value: "pa", label: "Punjabi" },
      { value: "ro", label: "Romanian" },
      { value: "ru", label: "Russian" },
      { value: "sm", label: "Samoan" },
      { value: "gd", label: "Scots Gaelic" },
      { value: "sr", label: "Serbian" },
      { value: "st", label: "Sesotho" },
      { value: "sn", label: "Shona" },
      { value: "sd", label: "Sindhi" },
      { value: "si", label: "Sinhala" },
      { value: "sk", label: "Slovak" },
      { value: "sl", label: "Slovenian" },
      { value: "so", label: "Somali" },
      { value: "es", label: "Spanish" },
      { value: "su", label: "Sundanese" },
      { value: "sw", label: "Swahili" },
      { value: "sv", label: "Swedish" },
      { value: "tg", label: "Tajik" },
      { value: "ta", label: "Tamil" },
      { value: "te", label: "Telugu" },
      { value: "th", label: "Thai" },
      { value: "tr", label: "Turkish" },
      { value: "uk", label: "Ukrainian" },
      { value: "ur", label: "Urdu" },
      { value: "uz", label: "Uzbek" },
      { value: "vi", label: "Vietnamese" },
      { value: "cy", label: "Welsh" },
      { value: "xh", label: "Xhosa" },
      { value: "yi", label: "Yiddish" },
      { value: "yo", label: "Yoruba" },
      { value: "zu", label: "Zulu" }
      ];

  const changeTimeZone = (event) => {
    setFormDetails({
      ...formdetails,
      timezone: event.value,
    });
    setSelectedTimezone(event)
  }

  const getLocaleValue = () => {
    let localeValue = languageOptions[0];
    if(formdetails.locale){
      languageOptions.forEach(function(ldetails) {
          if(ldetails.value==formdetails.locale){
            localeValue = ldetails;
          }
      });
    }
    return localeValue;
  }

  const handleEnumChange = (value) => {
    // setFormDetails({
    //   ...formdetails,
    //   locale: value,
    // });
  }

  const changeLocale = (event) => {
    setFormDetails({
      ...formdetails,
      locale: event.value,
    });
  }

  const changeTemplate = (event) => {
    setFormDetails({
      ...formdetails,
      templateid: event.target.value,
    });
  }

  const handleSwitchChange = (fieldname,fieldvalue) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: fieldvalue,
    });
  }
  const handleTemplateSubmit = function(e) {
     changeProjectTemplate();
     e.preventDefault()
  }
  const changeProjectTemplate = async ()=>{
    let validations = true;
    if(validations){
      setShowLoader(true);
      setShowSaveButton(false);

      let templateChanged = await projectsService.changeProjectTemplates(formdetails);
      if(!templateChanged.status && templateChanged.error.errorCode === 403){
          context.setIsAuthorized(context);
      }
      else if(templateChanged.status){
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true);
          setAlert({show:true,type:'success',message:'Project Template  were saved successfully'});
        },1000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Project Template could not be saved, Please try again.'});
        },1000);
      }

      setTimeout(()=>{
        setAlert({show:false,type:'',message:''});
      },3000);

      // setTimeout(()=>{
      //   history.push("/project/projects");
      // },2000);

      
    }
  }

  let templateElements = [];
  if(templates){
    let i = 0;
  templates.forEach(function(templateDetails) {
   
   i++;
   //(!defaultTemplateId && i==1) || 
    if(templateDetails.value == defaultTemplateId){
      templateElements.push(
        <div className="form-check-radio">
                <Label check>
                <Input
                    defaultChecked
                    value={templateDetails.value}
                    id="templateid"
                    name="templateid"
                    type="radio"
                    onChange={changeTemplate}
                />
                {templateDetails.label} <span className="form-check-sign" />
                </Label>
            </div>
      )
    }else{
      templateElements.push(
        <div className="form-check-radio">
                <Label check>
                <Input
                    value={templateDetails.value}
                    id="templateid"
                    name="templateid"
                    type="radio"
                    onChange={changeTemplate}
                />
                {templateDetails.label} <span className="form-check-sign" />
                </Label>
            </div>
      )
    }

    })
    
  }

  console.log(formdetails)

    return (
      <div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Project Template</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="projectTemplate" method="POST" onSubmit={handleTemplateSubmit}>
                    <Row>
                      <Label sm="2">Template</Label>
                      <Col sm="2">
                        <FormGroup>
                            {templateElements}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label sm="2">Locale</Label>
                      <Col sm="7">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="locale"
                            value={getLocaleValue()}
                            onChange={changeLocale}
                            options={languageOptions}
                          />
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Timezone</Label>
                      <Col sm="7">
                        <FormGroup >
                        <TimezoneSelect
                          name = "timezone"
                          value={selectedTimezone}
                          onChange={changeTimeZone}
                        />
                          
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Access Key</Label>
                      <Col sm="2">
                        <FormGroup>
                        <Label>{accesskey}</Label>
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label sm="2">Secret Key</Label>
                      <Col sm="2">
                        <FormGroup>
                        <Label>{secretkey}</Label>
                        </FormGroup>
                      </Col>
                  </Row>

                  
                  {showSaveButton?<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={handleTemplateSubmit}
                  >
                    Save
                  </Button>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default TemplateChanged;
