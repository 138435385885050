import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
// import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const SubuserCountWidget = (props)=> {

  const [totalSubusers,setTotalSubusers] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let usersData = await projectService.getProjectUsers(props.projectId);
            if(usersData.status && usersData.data){
                if(usersData.data.length>1){
                    setTotalSubusers(usersData.data.length-1);
                }else{
                    setTotalSubusers(0);
                }
                
            }
            setRenderComponent(true);
        })();
    }, []);  
 

    return (
        <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
              <i className="fa fa-user text-primary" />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">Subusers</p>
                <CardTitle tag="p">
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalSubusers}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar-o" />
            Till Date
          </div>
        </CardFooter>
      </Card>
    );
  
  
}

export default SubuserCountWidget;
