import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import notificationsService from "../../services/api/notifications-service";
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
const NotificationsDashboard = (props)=> {
  const [formdetails, setFormDetails] = useState({ topic: '', message:'',type:'',platform: '',send_medium:''});
  const [notifications, setNotifications] = useState(false);
  const [totalNotifications,setTotalNotifications] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({topic:'',message:'', type:'',platform: ''});
  const records = 10;
  const notificationTypeArray = [
        {
            value: "general",
            label: "General",
        },
        {
            value: "article_promotion",
            label: "Article Promotion",
        }
    ];
  const platformArray = [
    {
      value: "pwa",
      label: "PWA",
    },
    {
      value: "android",
      label: "Android",
    },
    {
      value: "ios",
      label: "IOS",
    }
  ]
  const sendMediumArray = [
    {
      value: 0,
      label: "Manual"
    },
    {
      value: 1,
      label: "Auto"
    }
  ]
  const context = useContext(UserContext);
  console.log(formdetails)

  // useEffect( () => {
  //   (async () =>{
      
  //       let getTotalNotifications = await notificationsService.getTotalNotifications(formdetails);
  //       if(getTotalNotifications.status){
  //           setTotalNotifications(getTotalNotifications.data.count);
  //       }
    
  //       })();
  //   }, [search]);  

  useEffect( () => {
    (async () =>{
      
        let notificationsData = await notificationsService.getNotifications(formdetails,activePage,records);
        console.log(notificationsData)
            if(notificationsData.status){
                setNotifications(notificationsData.data.notificationList);
                setTotalNotifications(notificationsData.data.count);
            }
            setRenderComponent(true);
          
        })();
    }, [activePage,search]);

    const handlePageChange = (pageNumber) => {
        console.log(pageNumber);
        setActivePage(pageNumber);
    }

  let notificationElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!notifications || !notifications.length){
    if(!renderComponent){
        notificationElements.push(
       <tr>
        <td colSpan="10" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        notificationElements.push(
          <tr>
        <td colSpan="10" className="text-center">
            <CardTitle tag="h4">No Notifications Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
      let i=1;
      notifications.forEach(function(notificationDetails) {
          let className = '';
          if(notificationDetails.type == 'general'){
            className = 'table-warning';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          notificationElements.push(
            <tr className={className}>
              <td className="text-left">{(activePage-1)*10+i}</td>
              <td className="text-left">
                   {notificationDetails.topic_arn?notificationDetails.topic_arn:''}
               </td>
               <td className="text-left">
                   {notificationDetails.message_type}
               </td>
               <td className="text-left">
                   {notificationDetails.message}
               </td>
               <td className="text-left">
               {notificationDetails.message_type}
               </td>
               <td className="text-left">
                   <Moment format="dddd DD MMM YYYY hh:mm:ss a">{notificationDetails.sent_on}</Moment>
               </td>
               <td className="text-left">
                {notificationDetails.platform}
              </td>
              <td className="text-left">
                {notificationDetails.message_service ? notificationDetails.message_service : 'firebase'}
                <div>
                  {
                    notificationDetails.message_service === "izooto"
                      ? `(${notificationDetails.segment_id?.segment_name || "All Subscribers"})`
                      : ""
                  }                </div>
              </td>
              <td className="text-left">
                {notificationDetails.send_medium == 1 ? "Auto" : "Manual"}
              </td>
              <td className="text-left">
                {notificationDetails.delivery_status ? notificationDetails.delivery_status : 'success'}
              </td>
              <td className="text-left">
                {notificationDetails.reason ? notificationDetails.reason : "-"}
              </td>
            </tr>
        )
        if(i==notifications.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==notifications.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleTopicChange = (event) => {
    setFormDetails({
      ...formdetails,
      topic: event.target.value,
    });
  }

  const handleMessageChange = (event) => {
    setFormDetails({
      ...formdetails,
      message: event.target.value,
    });
  }

  const changeType = (event) => {
        setFormDetails({
            ...formdetails,
            type: event.value,
        });
  };
  const changePlatform = (event) => {
        setFormDetails({
            ...formdetails,
            platform: event.value,
        });
  };
  const changeSendMedium = (event) => {
    setFormDetails({
      ...formdetails,
      send_medium: event.value
    })
  }
  const handleSubmit = function(e){  searchNotifications();e.preventDefault() }

  const searchNotifications = () => {
    console.log(formdetails)
      setActivePage(1);
      setSearchDetails(formdetails);
  }

  const resetElements = () => {
    document.getElementById('searchtopic').value = '';
    document.getElementById('searchmessage').value = '';
    setFormDetails({
      ...formdetails,
      topic: '',
      message:'',
      type : '',
      platform : '',
      send_medium:''
    });
    setSearchDetails({topic:'',message:'',type : '', platform : '',send_mediu:''});
 }
  

    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                   Notifications Dashboard
                </CardTitle>
                <Form id="notificationsfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                <Col md="3">
                  <Input type="text" name="searchtopic" style ={{height:"40px"}} id="searchtopic" onChange={handleTopicChange} placeholder="Search in topic"/>
                  </Col>  
                  
                  <Col md="3">
                  <Input type="text" name="searchmessage" style ={{height:"40px"}} id="searchmessage" onChange={handleMessageChange} placeholder="Search in message"/>
                  </Col>
                  <Col md="3">
                      <Select
                        className="react-select primary"
                        lassNamePrefix="react-select"
                        placeholder="Select Type .."
                        name="type"
                        id="type"
                        options={notificationTypeArray}
                        value={notificationTypeArray.filter(option => option.value===formdetails.type)}
                        onChange={changeType}
                      />
                  </Col>
                  <Col md="3">
                      <Select
                        className="react-select primary"
                        lassNamePrefix="react-select"
                        placeholder="Select Platform .."
                        name="platform"
                        id="platform"
                        options={platformArray}
                        value={platformArray.filter(option => option.value===formdetails.platform)}
                        onChange={changePlatform}
                      />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="3"style = {{marginTop:"10px"}}>
                      <Select
                        className="react-select primary"
                        lassNamePrefix="react-select"
                        placeholder="Select Send Medium .."
                        name="send_medium"
                        id="send_medium"
                        options={sendMediumArray}
                        value={sendMediumArray.filter(option => option.value===formdetails.send_medium)}
                        onChange={changeSendMedium}
                        
                      />
                  </Col>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetElements}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 
                </Row>
                </Form>
                <hr></hr>
                {totalNotifications > 0 ? 
                  <Row>
                    
                    <Col md="6">
                      <div style={{float:'right'}}>
                        <b>{firstRecord} - {lastRecord} of {totalNotifications}</b>
                      </div>
                    </Col>
                    <Col md="6">
                    <div style={{float:'left'}}>
                        <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalNotifications}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        />
                    </div>
                  </Col>
                  </Row>
                
                : null}
                
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th>
                        <th className="text-left">Topic</th>
                        <th className="text-left">Type</th>
                        <th className="text-left">Message</th>
                        <th className="text-left">Target Screen</th>
                        <th className="text-left">Sent On</th>
                        <th className="text-left">Platform</th>
                        <th className="text-left">Message Service</th>
                        <th className="text-left">Send Medium</th>
                        <th className="text-left">Message Delivery</th>
                        <th className="text-left">Fail Reason</th>
                      </tr>
                    </thead>
                    <tbody>{notificationElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default NotificationsDashboard;
