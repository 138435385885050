import axios from "axios";

const getCredits = async (formdetails, page, records) => {
    const formdata = {
        filters: formdetails,
        page: page,
        records: records
    }
    const response = await axios.post('/api/genai-credits/get-credits', formdata);
    return response.data;
}

const getTotalCredits = async (formdata) => {
    const response = await axios.post('/api/genai-credits/get-total-credits', formdata);
    return response.data;
}

const suspendCredits = async (genaiAPIQuotaId) => {
    const formdata = {
        genaiAPIQuotaId: genaiAPIQuotaId
    }
    const response = await axios.post('/api/genai-credits/suspend-credit', formdata);
    return response.data;
}

const restoreCredits = async (genaiAPIQuotaId) => {
    const formdata = {
        genaiAPIQuotaId: genaiAPIQuotaId
    }
    const response = await axios.post('/api/genai-credits/restore-credit', formdata);
    return response.data;
}

const assignCredits = async (formdata) => {
    const response = await axios.post('/api/genai-credits/assign-credits', formdata);
    return response.data;
}

export default {
    getCredits,
    getTotalCredits,
    restoreCredits,
    suspendCredits,
    assignCredits,
}