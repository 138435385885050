
import React, { useEffect, useState, useContext } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { Line, Bar, Pie } from "react-chartjs-2";


const Chart=(props)=>{
  const chartDetails = {
    data: {
      // labels: [
      //   // "Jan",
      //   // "Feb",
      //   // "Mar",
      //   // "Apr",
      //   // "May",
      //   // "Jun",
      //   // "Jul",
      //   // "Aug",
      //   // "Sep",
      //   // "Oct",
      // ],
      // datasets: [
      //   {
      //     label: "",
      //     borderColor: "#6bd098",
      //     pointRadius: 0,
      //     pointHoverRadius: 0,
      //     fill: false,
      //     borderWidth: 3,
      //     barPercentage: 1.6,
      //     //  data: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610],
      //   },
      // ],
    },
    options: {
      legend: {
        display: false,
      },
  
      // tooltips: {
      //   enabled: false,
      // },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
    //  hover: {
    //     mode: 'nearest',
    //     intersect: true
    //   },
  
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              beginAtZero: false,
              maxTicksLimit: 5,
              //padding: 20
              precision:0,
              beginAtZero: true
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)",
            },
          },
        ],
  
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f",
            },
          },
        ],
      },
    },
  };
  
    const [chartData,setChartData]=useState(false);
    const [heading,setHeading]=useState(false);
    const [chartOptions,setChartOptions]=useState(false);
    useEffect(()=>{
      if(props.data)
      {
         chartDetails.data.labels=props.data.labels;
        chartDetails.data.datasets=props.data.datasets;
        setChartData(chartDetails.data);
        setChartOptions(chartDetails.options);
        setHeading(props.heading);
      }
      

    },[]);

    return <Col lg="6" sm="8">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left"> {props.data?props.data.count:""}</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        {(props.data.diff>=0)?
                        <Badge color="success" pill>
                          +{props.data.diff} %
                        </Badge>
                        :
                        <Badge color="danger" pill>
                          {props.data.diff} %
                        </Badge>
                      }
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                  {heading?heading:""}
                  </h6>
                  <Line
                    data={chartData?chartData:""}
                    options={chartOptions?chartOptions:""}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  {/* <Row>
                    <Col sm="7">
                      <div className="footer-title">Financial Statistics</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          size="sm"
                        >
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Col>
                  </Row> */}
                </CardFooter>
              </Card>
            </Col>
}

export default Chart;