import React, { useEffect, useState ,useContext} from "react";
import "./projectAlert.css";
import Pagination from "react-js-pagination";
import projectAlertService from "../../services/api/project-alert-service";
import projectsService from "../../services/api/projects-service";
import {Row, Col, Button} from "reactstrap";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Moment from "react-moment";
import UserContext from "../../UserContext";
import {Table} from 'reactstrap';
const ProjectAlertDashboard = () => {
    const context = useContext(UserContext);
    const [alertProjects, setAlertProjects] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalProjects, setTotalProjects] = useState(0);
    const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:'', status:0});
    const [search, setSearchDetails] = useState({publisher_id:'',project_id:'', status:0});
    const [publisherNameArray,setPublisherNames] = useState([]);
    const [publisher, setPublisher] = useState('');
    const [projectNameArray,setProjectNames] = useState([]);
    const [publisherId,setPublisherId] = useState('');
    const [projectId,setProjectId] = useState('');
    const [reloadComponent,setReloadComponent] = useState(0);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);

    let itemsPerPage = 10;

    let statusArray = [
        {
          value: 0,
          label: "All",
        },
        {
          value: 1,
          label: "Pending",
        },
        {
          value: 2,
          label: "Completed",
        }
    ];

    const resetForm = () => {
        setPublisherId('');
        setProjectId('');

        setFormDetails({
            ...formdetails,
            publisher_id: '',
            project_id:'',
            status: 0
        });
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
        setActivePage(1);
    }

    const changePublisherId = (event) => {
        setFormDetails({
          ...formdetails,
          publisher_id: event.value,
          project_id:''
        });
        setProjectId('');
        setPublisherId(event)
        setPublisher(event.value);
    }

    const changeProjectId = (event) => {
        setFormDetails({
          ...formdetails,
          project_id: event.value,
        });
        setProjectId(event)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    } 

    const handleSubmit = function(e){  searchProjects();e.preventDefault() }

    const searchProjects = () => {
        setActivePage(1);
        setSearchDetails(formdetails);
    }

    useEffect( () => {
        (async () =>{
            let publishers = await projectsService.getAllPublishers();
            if(publishers.status){
                let getPublishers = [];
                let publisherData = publishers.data;
                if(publisherData.length){
                    publisherData.forEach(function(publisherDetails) {
                        let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                        getPublishers.push(publisherValue)
                    })
                }
                setPublisherNames(getPublishers);
            }
        })();
    }, [reloadComponent]);
  
    useEffect( () => {
        (async () =>{
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if(projects.status){
                let projectData = projects.data;
                if(projectData.length){
                    projectData.forEach(function(projectDetails) {
                        let projectValue = {value:projectDetails.id,label:projectDetails.name};
                        publisherProjects.push(projectValue)
                    })
                }
                setProjectNames(publisherProjects);
            }
        })();
    }, [publisher]);

    useEffect(() => {
        (async () => {
            try{
                let projectAlertData = await projectAlertService.getProjectAlert(formdetails, activePage, itemsPerPage);
                setAlertProjects(projectAlertData.data.projectAlerts);
                setTotalProjects(projectAlertData.data.count);
                console.log(projectAlertData.data.projectAlerts.length);
                if(projectAlertData.data.projectAlerts.length == 0){
                    setFirstRecord(0);
                    setLastRecord(0);
                }
                else{
                    setFirstRecord(((activePage - 1)*itemsPerPage) + 1);
                    setLastRecord((((activePage - 1)*itemsPerPage) + 1) + projectAlertData.data.projectAlerts.length - 1);
                }
                console.log(firstRecord + " " + lastRecord);
            }
            catch(err){
                console.log(err);
            }
        })();
    }, [reloadComponent, activePage, search]);

    const completeProjectPopup = (projectAlertId) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => completeProject(projectAlertId)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Complete it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to complete this project!
            </ReactBSAlert>
          ),
        });
      }

    const completeProject = async (projectAlertId) => {
        let projectAlertComplete = await projectAlertService.completeAlertProject(projectAlertId);
        if(!projectAlertComplete.status && projectAlertComplete.error.errorCode === 403){
            setShowPopup({alert:null});
            context.setIsAuthorized(context);
        }
        console.log(projectAlertComplete);
        updateState(projectAlertId, projectAlertComplete.data.updatedAt, projectAlertComplete.data.completedBy);
        setShowPopup({
            alert: null,
        });
    }

    const updateState = (projectAlertId, updatedAt, userEmail) => {
        let temp_state = [...alertProjects];
        for(let i=0; i<temp_state.length; i++){
            let temp_element = temp_state[i];
            if(temp_element._id === projectAlertId){
                temp_element.isCompleted = true;
                temp_element.completedBy = userEmail;
                temp_element.updatedAt = updatedAt;
            }
            temp_state[i] = temp_element;
        }
        setAlertProjects(temp_state);
    }

    const hideAlert = () => {
        setShowPopup({
          alert: null,
        });
    };

    const changeStatus = (event) => {
        setFormDetails({
          ...formdetails,
          status: event.value,
        });
    };

    const projectCompleted = (item) => {
        if(item.completedBy){
            return "Completed By " + item.completedBy;
        }
        else{
            return "Completed";
        }
    }

    return (
        <>
            <div className="content">
            {showPopup.alert}
                <div className="card">
                    <div className="card-header">
                        <h3>Project Alerts</h3>
                        <div className="inputs">
                            <Row>
                                <Col md="3">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Select Publisher .."
                                        name = "publisher_id"
                                        id="publisher_id"
                                        options={publisherNameArray}
                                        value={publisherId}
                                        onChange={changePublisherId}
                                    />
                                </Col>
                                <Col md="3">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Select Project .."
                                        name = "project_id"
                                        id="project_id"
                                        options={projectNameArray}
                                        value={projectId}
                                        onChange={changeProjectId}
                                    />
                                </Col>
                                <Col md="3">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Select Status .."
                                        name="status"
                                        id="status"
                                        options={statusArray}
                                        value={formdetails.status!==""?statusArray.filter(option=> option.value == formdetails.status):""}
                                        onChange={changeStatus}
                                    />
                                </Col>
                                <Col md="3">
                                    <div style={{'float':'left'}}>
                                    <Button onClick={handleSubmit}>Search</Button>
                                    <Button onClick={resetForm}>Reset</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <hr/>
                        {totalProjects > 0 ? 
                            <div className="row">
                                <div className="col-md-6">
                                    <b className="page-number-left" id="pageText">{firstRecord} - {lastRecord} of {totalProjects}</b>
                                </div>
                                <div className="col-md-6">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={totalProjects}
                                        pageRangeDisplayed={3}
                                        onChange={handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>
                        : null}
                    </div>
                    <div className="card-body">
                        <Table className="table" responsive> 
                            <thead className="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Publisher Name</th>
                                    <th>Project Name</th>
                                    <th>Project Slug</th>
                                    <th>Description</th>
                                    <th>Last Updated At</th>
                                    <th>Is Completed?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {alertProjects.map((item, i) => 
                                    <tr key={i}>   
                                        <td>{(i+(1+((activePage-1)*itemsPerPage)))}</td>
                                        <td>{item.project_id.publishers_id.name}</td>
                                        <td>{item.project_id.name}</td>
                                        <td>{item.project_id.slug}</td>
                                        <td>{item.description}</td>
                                        <td><Moment format="DD MMM YYYY hh:mm:ss a">{item.updatedAt}</Moment></td>
                                        <td className="complete">{item.isCompleted? 
                                        projectCompleted(item)
                                        : 
                                        <Button title="Complete" onClick={() => completeProjectPopup(item._id) } className="nav-link" id="completeBtn">Complete
                                        </Button>
                                        }</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectAlertDashboard;