import React, {useEffect, useState, useContext} from "react";
import {useHistroy} from "react-router-dom";
import permissionService from "../../services/api/permission-group-service.js";
import formValidations from "../../variables/form-validations.js";
import TagInput from "../saas/plan/TagInput";
import UserContext from "../../UserContext";
import Scrollspy from 'react-scrollspy'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    UncontrolledCollapse,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Select from "react-select";
import { useHistory } from "react-router-dom";


const EditPermissionGroup  = (props) =>{
    const history = new useHistory();
    const permissionGroupId = new URLSearchParams(props.location.search).get(
        "permissionGroupId"
    )
    if(!permissionGroupId){
        history.push("/permissions/permission-details");
    }
    const [disabled, setDisabled] = useState(false);
    const [permissionGroup, setPermissionGroup] = useState(false); // change this into false
    const [permissionGroupDescriptionState, setPermissionGroupDescriptionState] = useState("");
    const [permisisonGroupDescriptionError, setPermisisonGroupDescriptionError] = useState("");
    const [permissionGroupNameState, setPermissionGroupNameState] = useState("");
    const [permisisonGroupNameError, setPermisisonGroupNameError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [permissionsState, setPermissionState] = useState("");
    const [permissionsError, setPermissionsError] = useState("");
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [formdetails, setFormdetails] = useState({
        permissionGroupId:"",
        permissionGroupName: "",
        permissionGroupDescription:"",
        permissions:"",
    });
    var activeTab = false;
    const [verticalTab, setVerticalTab] = useState(activeTab);
    const [defaultCheckedPermission, setdefaultCheckedPermission] = useState([])
    const [checkedPermissions, setcheckedPermissions] = useState([]);
    // const [codeList, setCodeList] = useState([]);
    const context = useContext(UserContext);
    const [tabElement, setTabElement] = useState([]);

    const [permissionLookUp, setPermissionLookUp] = useState([]);
    // const [permissionGroupCode, setPermissionGroupCode] = useState("");
    useEffect(
        ()=>{
            (async () => {
                // api for permissions
                let response = await permissionService.getPermissions();
                if(response && response.data){
                    setPermissionLookUp(response.data);
                }
                // api for loading permission Group details
                // let permissionGroupData = await permissionService.getPermissionGroupDetails(permissionGroupId);
                // // console.log(permissionGroupData)
                // defaultChecked();
                // if(permissionGroupData.status && permissionGroupData.data){
                //     setPermissionGroup(permissionGroupData.data);
                //     setFormdetails({
                //         ...formdetails,
                //         permissionGroupId: permissionGroupData.data.permissionGroupId,
                //         permissionGroupName: permissionGroupData.data.name,
                //         permissions: permissionGroupData.data.permissions,
                //     });
                // }

            })();
        },[]);

    
        useEffect(()=>{
            (async ()=> {
                
                let permissionGroupData = await permissionService.getPermissionGroupDetails(permissionGroupId);
                // console.log(permissionGroupData)
                if(permissionGroupData.status && permissionGroupData.data){
                    setPermissionGroup(permissionGroupData.data);
                    
                    let list = [...defaultCheckedPermission];
                    const response = await permissionService.getSelectedPermissionList(permissionGroupId);
                    
                    if(response.status && response.data != ""){
                        setdefaultCheckedPermission(response.data);
                        setFormdetails({
                            ...formdetails,
                            permissionGroupId: permissionGroupData.data.permissionGroupId,
                            permissionGroupName: permissionGroupData.data.name,
                            permissionGroupDescription: permissionGroupData.data.description,
                            permissions: response.data
                        });
                        setcheckedPermissions(response.data);
                        console.log("checkedpermissions ",response.data)
                        setPermissionState("has-success");
                        setPermissionsError("");

                    }
                }
            })();
        },[])
    const validatePemrissionGroupName = ()=>{
        if(formdetails.permissionGroupName.trim() === ""){
            setPermissionGroupNameState("has-danger");
            setPermisisonGroupNameError("Permission Group Name is required!");
            return false;
        }
        setPermissionGroupNameState("has-success");
        return true;
    };

    const validatePermissionGroupDescription = ()=>{
        
        if(formdetails.permissionGroupDescription === ""){
            setPermissionGroupDescriptionState("has-danger");
            setPermisisonGroupDescriptionError("Permission Group Name is required!");
            return false;
        }
        setPermissionGroupDescriptionState("has-success");
        return true;
    };

    const handleSubmit = (e) => {
        validateForm();
        e.preventDefault();
    }

    const handlePermissionGroupNameChange = (event) => {
        // console.log("in name: ",event.target.value)
        setFormdetails({
            ...formdetails,
            permissionGroupName: event.target.value,
        })
        setPermissionGroupNameState("has-success");
        setPermisisonGroupNameError("");
    }

    const handlePermissionGroupDescriptionChange = (event) => {
        console.log("in name: ",event.target.value)
        setFormdetails({
            ...formdetails,
            permissionGroupDescription: event.target.value,
        })
        setPermissionGroupDescriptionState("has-success");
        setPermisisonGroupDescriptionError("");
    }
   
    const handlePermissionsChange = (event) => {
        console.log(event.target.value)
        let updateList = [...checkedPermissions];
        if(event.target.checked){
            updateList = [...checkedPermissions,event.target.value];
        }
        else{
            updateList.splice(checkedPermissions.indexOf(event.target.value),1);
        }
        console.log(updateList);
        setcheckedPermissions(updateList);
        setFormdetails({
            ...formdetails,
            permissions: updateList
        })
        setPermissionState("has-success");
        setPermissionsError("");
        // make new state for permisisons array and push every permission in permission array 
    }

    // const handlePermissionsCode = (code)=> {
    //     // update codes array using push function in formdetails
    //     let updateList = [...codeList];
    //     if(code != ""){
    //         updateList = [...codeList, code];
    //     }
    //     else{
    //         updateList = [...codeList]
    //     }
    //     console.log(updateList)
    //     setCodeList(updateList);
    // }

    const validatePermissions = () => {
        // console.log("permissions: ",formdetails.permissions.length);
        if(formdetails.permissions.length <= 0){
            setPermissionState("has-danger");
            setPermissionsError("Select At least one permission");
            return false;
        }
        // console.log("permissions", formdetails.permissions)
        setPermissionState("has-success");
        return true;
    }

    const validateForm = async () => {
        // setFormdetails({...formdetails});
        let permissionGroupNameValidation = validatePemrissionGroupName();
        let permissionsValidation = validatePermissions();
        // let permissionGroupDescriptionValidation = validatePermissionGroupDescription();
        // console.log("in form",formdetails)
        if(permissionGroupNameValidation && permissionsValidation  && !disabled){
            setShowLoader(true);
            setShowSaveButton(false);
            // console.log("formdetails: ",formdetails);
            let savePermissionGroup = await permissionService.savePermissionGroup({...formdetails});
            // console.log(savePermissionGroup);
            setDisabled(true);
            // if(!savePermissionGroup.status && savePermissionGroup.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            // }
            if(savePermissionGroup.status){
                setTimeout(()=>{
                    setShowLoader(false);
                    setAlert({
                        show:true,
                        type:"success",
                        message:"Role saved successfully!"
                    });
                },1000);

                setTimeout(()=>{
                    setAlert({
                        show:false,
                        type:"",
                        message:""
                    });
                },3000);

                setTimeout(()=>{
                    history.push("/permissions/permission-details");
                },2000);
            }
            else{
                setTimeout(()=>{
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show:true,
                        type:"danger",
                        message: "Role could not be saved, Please try again."
                    });
                },1000);
                setAlert(()=>{
                    setAlert({show:false, type:"", message:""});
                },3000);
            }
        }
    };

    var tabPanes = [];
    
    var navItems = [];
    var navItemsArray = [];
    var i = 0;
    Object.keys(permissionLookUp).forEach((key) => {
        navItemsArray.push(key);
 
        navItems.push(<NavItem>
            <NavLink
                data-toggle="tab"
                href={"#" + key}
                role="tab"
                className={
                    (verticalTab === key) ? "active" : ""
                }
                onClick={() => {
                    setVerticalTab(key);

                }}
                >
                {permissionLookUp[key].classifierName}
            </NavLink>
        </NavItem>);
        let permission = permissionLookUp[key].permissions;
        Object.keys(permission).forEach((data)=>{

            tabPanes.push(<TabPane id={key} tabId={verticalTab} role="tabpanel">
                <Label>{permission[data].label}</Label>{' '}
                <Input type="checkbox" name={permission[data].value} id={permission[data].value} checked={checkedPermissions.includes((permission[data].value)) } value={permission[data].value} onChange={handlePermissionsChange} style={{'vertical-align':'baseline'}}></Input>
            </TabPane>)
            });
            tabPanes.push(<hr></hr>)
            
        });
    const setTabPaneElement = (data) => {
        
            console.log(data);
            let permission = data.permissions;
            // permission.map(list => (
            //     tabPanes.push(

            //     <FormGroup className="text-left"> 
                                                                                
            //         <Label >{list.label}</Label>
            //         <Input type="checkbox" id={list.value} defaultChecked={defaultCheckedPermission.includes(String(list.value)) ? true : false} value={list.value} onChange={handlePermissionsChange}></Input>

            //     </FormGroup>
            //     )
            // ))
            setTabElement(permission)
        // let permission = data.permissions;
        // // console.log(data)
        // console.log(permission)
        // // // {defaultCheck;edPermission && defaultCheckedPermission.length ?
        // //     Object.keys(permission).forEach((data)=>{
        // //         // console.log("in loop",permission[data])
        // //         console.log(permission[data].label)
        // //         tabPanes.push(<FormGroup>
        // //             <Label>{permission[data].label}</Label>
        // //             <Input type="checkbox" id={permission[data].value} value={permission[data].value} defaultChecked={defaultCheckedPermission.includes(String(permission[data].value)) ? true : false} onChange={handlePermissionsChange}></Input>
        // //             </FormGroup>
        // //     )
        // //     // console.log(tabPanes)
        // // });
        // // // :null};
        // setTabPaneElement(permission);
    }

    const scrollUpdated = (event) => {
        if (event) {
            setVerticalTab(event.id);
        }
    };

        

    return(
        <>
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">Edit Role</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {permissionGroup ?(
                                        <Form
                                            className="form-horizontal form"
                                            action=""
                                            id=""
                                            method = "POST"
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Label sm="2">Role Name<span className="text-danger">*</span></Label>
                                                <Col sm="7">
                                                    <FormGroup
                                                        className={permissionGroupNameState}
                                                    >
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            value={formdetails.permissionGroupName}
                                                            onChange={handlePermissionGroupNameChange}
                                                        />
                                                        {permissionGroupNameState === "has-danger" ?
                                                            (
                                                                <label className="error">
                                                                    {permisisonGroupNameError}
                                                                </label>
                                                            ): null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label sm="2">Role Description</Label>
                                                <Col sm="7">
                                                    <FormGroup
                                                    >
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            value={formdetails.permissionGroupDescription}
                                                            onChange={handlePermissionGroupDescriptionChange}
                                                        />
                                                      
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <h5> Permissions</h5>
                                            </Row>


                                            <Card>
                                                <CardBody>

                                                    <Row className={permissionsState}>
                                                        <Col md="3">
                                                            <div className="nav-tabs-navigation verical-navs p-0">
                                                                <div className="nav-tabs-wrapper">
                                                            
                                                                    <Scrollspy className="flex-column nav-stacked scroll nav nav-tabs" items={navItemsArray} currentClassName="bg-light" onUpdate={(event) =>scrollUpdated(event)} rootEl="#vertical-tab-content">
                                                                        {navItems}
                                                                    </Scrollspy>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md="9">
                                        
                                                            <TabContent id="vertical-tab-content" style={{ 'height': '300px', 'overflow-y': 'scroll','fontSize':"20px" }} activeTab={verticalTab}>
                                                                {/* {console.log("tabPane: ",tabElement)} */}
                                                                {tabPanes}     
                                                            </TabContent>
                                                            
                                                        </Col>
                                                    </Row>
                                                    {permissionsState === "has-danger" ? (<label className="error" style={{ color: "red" }}>
                                                        {permissionsError}
                                                    </label>
                                                    ) : null}
                                                </CardBody>
                                            </Card>
                                            {/* <Row className={permissionsState}>
                                                <div>
                                                    {permissionLookUp.map(data=><>
                                                        
                                                        <Button 
                                                            color="primary" 
                                                            id="toggler1" 
                                                            style={{ marginBottom: '1rem' }} 
                                                            // onClick={getPermissions}
                                                        >
                                                            {data.classifierName}
                                                            
                                                        </Button>

                                                        <UncontrolledCollapse toggler="#toggler1">
                                                        <Card>
                                                            <CardBody>
                                                                <Form>
                                                                    
                                                                    {defaultCheckedPermission && defaultCheckedPermission.length ? data.permissions.map(permission=>(
                                                                        // <Input type="checkbox" label={data[0].permissions.label} value={data[0].permissions.value}>{data[0].permissions.label}</Input>
                                                                        <FormGroup >
                                                                                    {/* {console.log(key2)}
                                                                                    {console.log(data2.label)} */}
                                                                                    {/* <Label >{permission.label}</Label>
                                                                                    {console.log("permission List: ",defaultCheckedPermission)}
                                                                                    {console.log("value: ",permission.value)}
                                                                                    {console.log(defaultCheckedPermission.includes(String(permission.value)) ? true : false)}
                                                                                    <Input type="checkbox" id={permission.value} defaultChecked={defaultCheckedPermission.includes(String(permission.value)) ? true : false} value={permission.value} onChange={handlePermissionsChange}></Input>

                                                                        </FormGroup>

                                                                    )
                                                                    
                                                                    ): null}
                                                                </Form>
                                                            </CardBody>
                                                        </Card>
                                                    </UncontrolledCollapse>
                                                    </>)}
                                                    
                                                </div>
                                                    {permissionsState === "has-danger" ?(<label className="error">
                                                                    {permissionsError}
                                                                </label>
                                                        ): null}
                                            </Row> */}
                                                
                                                {/* <Row className={permissionsState}>
                                                <Card>
                                                    <CardBody>
                                                        <Accordion allowZeroExpanded={true}>
                                                        {permissionLookUp.map(data => <>
                                                            <AccordionItem key={data.code}>
                                                                <AccordionItemHeading className="square border">
                                                                    
                                                                    <AccordionItemButton 
                                                                         color="primary"
                                                                         id="toggler1"
                                                                         style={{ marginBottom: '1rem',textAlign:"left" }}
                                                                    >
                                                                            {data.classifierName}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <Form >

                                                                        { defaultCheckedPermission && defaultCheckedPermission.length ? data.permissions.map(permission => ( 
                                                                            
                                                                            <FormGroup className="text-left">  */}
                                                                                {/* {console.log(key2)}
                                                                                    {console.log(data2.label)} */}
                                                                                {/* <Label >{permission.label}</Label>
                                                                                <Input type="checkbox" id={permission.value} defaultChecked={defaultCheckedPermission.includes(String(permission.value)) ? true : false} value={permission.value} onChange={handlePermissionsChange}></Input>

                                                                            </FormGroup>

                                                                        )

                                                                        ):null}
                                                                    </Form>

                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </>)}   
                                                        </Accordion>

                                                    </CardBody>
                                                </Card>
                                                {permissionsState === "has-danger" ? (<label className="error" style={{color:"red"}}>
                                                    {permissionsError}
                                                </label>
                                                ) : null}
                                            </Row> */}


                                            <Row>
                                                {showSaveButton?(
                                                    <div>
                                                        <Button 
                                                            className="btn-round"
                                                            color="info"
                                                            type="button"
                                                            onClick={validateForm}
                                                            disabled={disabled}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button 
                                                            className="btn-round"
                                                            color="danger"
                                                            type="button"
                                                            onClick={(event) => {
                                                                history.push(`/permissions/permission-details`)
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                ):("")}
                                                {
                                                    alert.show?(
                                                        <Alert color={alert.type}>
                                                            {alert.message}
                                                        </Alert>
                                                    ):("")
                                                }
                                                {showLoader?(
                                                    <Spinner 
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="sr-only"></span>
                                                    </Spinner>
                                                ):("")}
                                            </Row>

                                        </Form>
                                    ):(
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </Spinner>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default EditPermissionGroup;