import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
// import configVersionService from "../../services/api/configVersion-service";
import customizationsService from "../../services/api/customizations-service";
import projectsService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
const AppConfigVersion = (props) => {
    const projectId = new URLSearchParams(props.location.search).get('projectid');
    const context = useContext(UserContext);
    const [configVersions, setConfigVersions] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [componentRendered, setComponentRendered] = useState(false);

    const [reloadComponent, setReloadComponent] = useState(0);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const [formdetails, setFormDetails] = useState({version:'',name: '',projectId : projectId});
    const history = new useHistory();
    const [showLoader, setShowLoader]   = useState(false);
    const [showCreateConfig, setShowCreateConfig] = useState(false);
    const [versionOptions, setVersionOptions] = useState([]);
    const [hideButton, setHideButton] = useState(false);
    const [createLoader , setCreateLoader] = useState(false);
    const [versionState, setVersionState] = useState("");
    const [versionError , setVersionError] = useState("");
    const [isDraftConfigPresent, setIsDraftConfigPresent] = useState(false);
    const [configCount, setConfigCount] =  useState(0);
    const [existingConfigs, setExistingConfigs] = useState(false);
    const [nameState, setNameState] = useState("");
    const [nameError, setNameError] = useState("");
    useEffect(() => {
        (async () => {
            let getProjectDetails = await projectsService.getProjectDetails(projectId);
            if(getProjectDetails.status && getProjectDetails.data){
                setProjectName(getProjectDetails.data.name);
            }
            const response = await customizationsService.getAppConfigVersions(projectId);
            if (response.status && response.data) {
                setConfigVersions(response.data.versions);
                let allVersion = response.data.versions;
                let versionArray = [];
                let configs = [];
                let publishedConfigCount=0;
                if(allVersion.length){

                      allVersion.forEach(function(versionDetails) {
                        configs.push(versionDetails.name);
                        if(versionDetails.status && versionDetails.is_published){
                            versionArray.push({value:versionDetails.version,label: versionDetails.name});
                            publishedConfigCount++;
                        }
                      });
                      setExistingConfigs(configs);
                      setConfigCount(publishedConfigCount);
                      setVersionOptions(versionArray);
                }
                setIsDraftConfigPresent(response.data.isDraftConfigPresent);
            }
             setComponentRendered(true);
        })();
    }, [reloadComponent]);
   
    const deactivateConfig =async (projectId , version)=>{
        const response = await customizationsService.deactivateAppConfig(projectId , version);
        if(!response.status && response.error.errorCode === 403){
            setShowPopup({alert:null});
            context.setIsAuthorized(context);
        }
        else if (response.status) {

            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Deactivate!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    showConfirm
                    btnSize=""
                  >
                    Config was deactivated successfully.
                  </ReactBSAlert>
                ),
              });
               
            }else{
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Please try again!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    Config could not be deactivate. Please try again.
                  </ReactBSAlert>
                ),
              });
            }
              setReloadComponent(Math.random());
              setTimeout(hideAlert,3000);
        }

    const deactivateConfigPopup = (projectId, version) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => deactivateConfig(projectId, version)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Deactivate it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to deactivate this config version!
            </ReactBSAlert>
          ),
        });
      }

    const activateConfig =async (projectId , version)=>{
        const response = await customizationsService.deactivateAppConfig(projectId , version);
        if(!response.status && response.error.errorCode === 403){
            setShowPopup({
                alert:null
            });
            context.setIsAuthorized(context);
        }
        else if (response.status) {

            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Deactivate!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    showConfirm
                    btnSize=""
                  >
                    Config was deactivated successfully.
                  </ReactBSAlert>
                ),
              });
               
            }else{
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Please try again!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    Config could not be deactivate. Please try again.
                  </ReactBSAlert>
                ),
              });
            }
              setReloadComponent(Math.random());
              setTimeout(hideAlert,3000);
        }

    const activateConfigPopup = (projectId, version) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => activateConfig(projectId, version)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Activate it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to activate this config version!
            </ReactBSAlert>
          ),
        });
      }
  



    const handleChange = (event) => {
        setFormDetails({
        ...formdetails,
        [event.target.id]: ( event.target.value ),
        });
    }
  


    const handlePublishConfigSwitch = async (projectId, version, enabled) => {

        if (enabled) {
            const response = await customizationsService.publishAppConfig(projectId, version);
            if(!response.status && response.error.errorCode === 403){
                setShowPopup({
                    alert:null
                });
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Config Published!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Config Published successfully.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);


                setReloadComponent(Math.random());
            }else
            {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Config could not Publish!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Config could not Publish.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                }, 3000);
                setReloadComponent(Math.random());
            }
        }else{
            setReloadComponent(Math.random());

        }
    };
    const confirmationPopup = (projectId, version, enabled) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to publish this Config version ?"
                    onConfirm={() => {
                        handlePublishConfigSwitch(projectId, version, enabled);
                        hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Publish it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to Publish Config!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
    

    const getConfigTable = () => {

        let configVersionElements = [];
        if (!configVersions || !configVersions.length) {
            if (!componentRendered) {
                configVersionElements.push(
                    <tr>
                        <td colSpan="7" className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </td>
                    </tr>
                );
            } else {
                configVersionElements.push(
                    <tr>
                        <td colSpan="7" className="text-center">
                            <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                    </tr>
                );
            }
        } else {
            let i = 1;
            configVersions.forEach(function (configVersion) {
                let className = "";

                if (!configVersion.status) {
                    className = "table-danger";
                }
                configVersionElements.push(
                    <tr className={className}>
                        <td className="text-left">{i}</td>
                        <td className="text-left">{configVersion.name?configVersion.name:""}</td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {configVersion.createdAt?configVersion.createdAt:""}
                            </Moment>
                        </td>
                        <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {configVersion.updatedAt?configVersion.updatedAt:""}
                            </Moment>
                        </td>
                        
                        <td className="text-left">{configVersion.modified_by?configVersion.modified_by:"System"}</td>                    
                        <td className="text-left">
                            <Switch
                                name="is_default"
                                offColor="success"
                                offText={
                                    <i className="nc-icon nc-simple-remove" />
                                }
                                onColor="success"
                                disabled={configVersion.is_published?configVersion.is_published:0}
                                onText={<i className="nc-icon nc-check-2" />}
                                value={configVersion.is_published?configVersion.is_published:0}
                                onChange={(value, enabled) =>
                                    confirmationPopup(projectId,configVersion.version, enabled)
                                }
                            />
                        </td>



                       
                        <td className="text-left">
                                <Link 
                                    to={{
                                    pathname: '/config/appcustomizations',
                                    search : `?projectid=${projectId}&version=${configVersion.version}`,
                                    }}
                                >
                                    <Button
                                        className="btn-round btn-primary"
                                        color="daprimaryger"
                                        size="sm"
                                        type="button"
                                        >
                                        Edit
                                    </Button>   
                            </Link>
                            {/* {!configVersion.is_published?
                            
                            
                            :
                            <>
                            {/* {
                                !configVersion.status ?
                            <Button
                            className="btn-round"
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={()=>{
                                activateConfigPopup(projectId, configVersion.version);
                            }}
                            >
                            Activate
                        </Button>:""
                            
                            } */}
                            {/* {
                                configVersion.status && (configCount>1)?
                            <Button
                            className="btn-round"
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={()=>{
                                deactivateConfigPopup(projectId, configVersion.version);
                            }}
                            >
                            Deactivate
                        </Button>:""
                            
                            } */}
                            {/* </>
                            }  */}
                        </td>
                    </tr>
                );

                i++;

            });
        }
        return configVersionElements;
    };
    const onConfirm =async ()=>{
        let isValid = true;
        if(formdetails.name === ""){
            setNameState('has-danger');
            setNameError('version name is required.');
            isValid = false;
        }else{
            if(existingConfigs){
                let duplicateName = false;
                existingConfigs.filter(function(existingConfigName) {
                    if(formdetails.name.trim()===existingConfigName.trim()){
                    duplicateName = true;
                    }
                })
                if(duplicateName){
                    setNameState("has-danger");
                    setNameError("You have already created config with this name .");
                    isValid = false;
                }
            }
        }
        if(formdetails.version=="") {
            setVersionError('This field is required.');
            setVersionState('has-danger');
            isValid = false;
        }
        if(!isValid) {
            return ;
        }
        setHideButton(true);
        setCreateLoader(true);
        const response = await customizationsService.createAppConfig(formdetails);
        setShowCreateConfig(false);
        setCreateLoader(false);
        setHideButton(false);
        if(!response.status && response.error.errorCode === 403){
            setShowPopup({alert:null});
            context.setIsAuthorized(context);
        }
        else if (response.status && response.data) {

            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Created!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    showConfirm
                    btnSize=""
                  >
                    Config has been created successfully.
                  </ReactBSAlert>
                ),
              });
               
        }else{
              setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Please try again!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    Config could not be created. Please try again.
                  </ReactBSAlert>
                ),
              });
        }
              setTimeout(()=>{
                hideAlert();
                setReloadComponent(Math.random())
              },3000);
    }
    const onCancel = ()=>{
        setShowCreateConfig(false);
    }
    const handleVersionChange = (event) => {
        setFormDetails({
            ...formdetails,
            version: event.value,
        });
        setVersionError('');
        setVersionState('has-success');
    };
    
    return (
        <div className="content">
            {showPopup.alert}
            {showCreateConfig?
                    <ReactBSAlert
                        title={"Create New Version of Config"}
                        type={'controlled'}
                        style ={{"position":"relative","height" : "450px","width": "50%"}}
                        closeOnClickOutside= {false}
                        showConfirm ={false}
                        showCancel ={false}
                        reverseButtons={true}

                        >
                        <Card  >
                            <CardHeader>
                    
                                {/* <CardTitle tag="h6">Create New Version of Config for {projectName}</CardTitle> */}
                        </CardHeader>
                        <CardBody>
                            <FormGroup className={nameState}>
                                <Input type="text" onChange={handleChange} name="name" id="name" placeholder="Enter version name" />
                                {nameState === "has-danger" ? (
                                    <label className="error">
                                    {nameError}
                                    </label>
                                ) : null}
                            </FormGroup>
                            <FormGroup className={versionState}>
                                <Select
                                    className={"react-select"}
                                    classNamePrefix="react-select"
                                    placeholder="Select Version .."
                                    name = "version"
                                    id="version"
                                    options={versionOptions}
                                    value={formdetails.version? versionOptions.filter((versionDetail)=> formdetails.version===versionDetail.value):""}
                                    onChange={handleVersionChange}
                                    />
                                {versionState === "has-danger" ? (
                                    <label className="error">
                                        {
                                            versionError
                                        }
                                    </label>
                                ) : null}
                            </FormGroup>
                            <br />
                            {createLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                            {!hideButton? 
                                <>        
                                        <Button
                                            style={{"backgroundColor": "blue"}}
                                            className="btnB "
                                            type="button"
                                            onClick={onConfirm}
                                        >Create</Button>

                                        <Button
                                            className="btnBlk"
                                            type="button"
                                            onClick={ onCancel }
                                        >
                                            Cancel
                                        </Button>
                                </>:""}
                        </CardBody>
                    </Card>

                        
                    </ReactBSAlert>
                            :""}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                    
                                <CardTitle tag="h4">Config Version of App - {projectName}</CardTitle>
                                {!isDraftConfigPresent?<Button
                                    style={{
                                        float: "right",
                                        "margin-top": "-50px",
                                    }}
                                    className="btn-round"
                                    color="btn-ble"
                                    size="sm"
                                    type="button"
                                    onClick={(event) =>
                                        setShowCreateConfig(true)
                                        // history.push("/configVersion/createappconfigVersion")
                                    }>
                                    Create New Config
                                </Button>:""}
                                
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">
                                                Name
                                            </th>
                                            <th className="text-left">
                                                Created At
                                            </th>
                                            <th className="text-left">
                                                Updated At
                                            </th>
                                            <th className="text-left">
                                                Modified By
                                            </th>
                                            <th className="text-left">
                                                is_published
                                            </th>                                          
                                            <th className="text-left">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!configVersions || !configVersions.length ? (
                                            !componentRendered ? (
                                                <tr>
                                                    <td
                                                        colSpan="7"
                                                        className="text-center">
                                                        <Spinner
                                                            animation="border"
                                                            role="status">
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan="7"
                                                        className="text-center">
                                                        <CardTitle tag="h4">
                                                            No Records Found
                                                        </CardTitle>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            getConfigTable()
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AppConfigVersion;
