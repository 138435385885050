import React, { useEffect, useState ,useContext} from "react";
import projectsService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import Switch from "react-bootstrap-switch";
import formValidations from "../../variables/form-validations";
import defaultImage from "../../assets/img/image_placeholder.jpg";
import { Prompt } from 'react-router'
import './AppSettings.css';

import UserContext from "../../UserContext"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import ImageUpload from "./UploadImage.js";
import FileUpload from './FileUpload.js'
import ReactBSAlert from "react-bootstrap-sweetalert";
const AppSettings = (props)=> {
  const projectId = new URLSearchParams(props.location.search).get('projectid');
 
  const history = new useHistory();
  if(!projectId){
    history.push("/project/projects");
  }
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({project_id:projectId,android_app_enabled:false,android_app_name:"",android_app_package_name:"",android_google_services_json:"", fcm_api_server_key : "", fcm_service_account_json : "",ios_app_enabled:false,ios_app_name:"",ios_app_package_name:"",ios_google_services_json:"",ios_username:"",ios_teamname:"",ios_team_id:'',app_store_api_key_id: '',app_store_api_key_issuer_id: '',app_store_api_auth_key_url: '',app_store_api_auth_key_file_name:'',launcher_icon:"",app_logo:"",launcher_background:"#eadcdc"});
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [projectName, setProjectName] = useState("");
  const [fieldStates,setFieldStates] = useState({});
  const [isSaved,setIsSaved] = useState(false);
  const [projectAppsGeneralSettings , setProjectAppsGeneralSettings] = useState({});
  const [existingApps, setExistingApps] = useState([]);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [reloadComponent ,setReloadComponent] = useState(null);
  const googleServiceValidationFormat = {
                    "project_info": {
                        "project_number": "12345",
                        "project_id": "12345",
                        "storage_bucket": "12345"
                    },
                    "client": [
                        {
                        "client_info": {
                            "mobilesdk_app_id": "12345",
                            "android_client_info": {
                            "package_name": ""
                            }
                        },
                        "oauth_client": [],
                        "api_key": [
                            {
                            "current_key": "12345"
                            }
                        ],
                        "services": {
                            "appinvite_service": {
                            "other_platform_oauth_client": []
                            }
                        }
                        }
                    ],
                    "configuration_version": "1"
    };
  let error = "";
  useEffect( () => {
    (async () =>{
        
        let getProjectDetails = await projectsService.getProjectDetails(projectId);
        if(getProjectDetails.status){
            setProjectName(getProjectDetails.data.name);
        }
        let getAndroidDistributions =await projectsService.getAndroidDistributions();
        if(getAndroidDistributions.status && getAndroidDistributions.data) {
            setExistingApps(getAndroidDistributions.data);
        }
        let getAppsGeneralSettingsDetails = await projectsService.getProjectAppsGeneralSettings(projectId);
        console.log("getAppsGeneralSettingsDetails",getAppsGeneralSettingsDetails);
        if(getAppsGeneralSettingsDetails.status){
            if(getAppsGeneralSettingsDetails.data){
                setFormDetails({
                  ...formdetails,
                  android_app_enabled: getAppsGeneralSettingsDetails.data.android_app_enabled,
                  android_app_name: getAppsGeneralSettingsDetails.data.android_app_name,
                  android_app_package_name: getAppsGeneralSettingsDetails.data.android_app_package_name,
                  android_google_services_json: getAppsGeneralSettingsDetails.data.android_google_services_json?JSON.stringify(JSON.parse(getAppsGeneralSettingsDetails.data.android_google_services_json),undefined,4):"",
                //   android_google_services_json: JSON.stringify(jsonAndroid,undefined,4),
                  fcm_api_server_key: getAppsGeneralSettingsDetails.data.fcm_api_server_key,
                  fcm_service_account_json : getAppsGeneralSettingsDetails.data.fcm_service_account_json,
                  ios_app_enabled: getAppsGeneralSettingsDetails.data.ios_app_enabled,
                  ios_app_name: getAppsGeneralSettingsDetails.data.ios_app_name,
                  ios_app_package_name: getAppsGeneralSettingsDetails.data.ios_app_package_name,
                  ios_google_services_json: getAppsGeneralSettingsDetails.data.ios_google_services_json,
                  ios_username: getAppsGeneralSettingsDetails.data.ios_username,
                  ios_teamname: getAppsGeneralSettingsDetails.data.ios_teamname,
                  ios_team_id : getAppsGeneralSettingsDetails.data.ios_team_id,
                  launcher_icon: getAppsGeneralSettingsDetails.data.launcher_icon,
                  app_logo: getAppsGeneralSettingsDetails.data.app_logo,
                  launcher_background: getAppsGeneralSettingsDetails.data.launcher_background,
                  s3_key_store_url: getAppsGeneralSettingsDetails.data.s3_key_store_url?getAppsGeneralSettingsDetails.data.s3_key_store_url:"",
                  app_store_api_key_id: getAppsGeneralSettingsDetails.data.app_store_api_key_id ,
                  app_store_api_key_issuer_id: getAppsGeneralSettingsDetails.data.app_store_api_key_issuer_id,
                  app_store_api_auth_key_url: getAppsGeneralSettingsDetails.data.app_store_api_auth_key_url,
                  app_store_api_auth_key_file_name : getAppsGeneralSettingsDetails.data.app_store_api_auth_key_file_name,
                });
                setProjectAppsGeneralSettings({
                  ...formdetails,
                  android_app_enabled: getAppsGeneralSettingsDetails.data.android_app_enabled,
                  android_app_name: getAppsGeneralSettingsDetails.data.android_app_name,
                  android_app_package_name: getAppsGeneralSettingsDetails.data.android_app_package_name,
                  android_google_services_json: getAppsGeneralSettingsDetails.data.android_google_services_json?JSON.stringify(JSON.parse(getAppsGeneralSettingsDetails.data.android_google_services_json),undefined,4):"",
                //   android_google_services_json: JSON.stringify(jsonAndroid,undefined,4),
                  fcm_api_server_key: getAppsGeneralSettingsDetails.data.fcm_api_server_key,
                  fcm_service_account_json : getAppsGeneralSettingsDetails.data.fcm_service_account_json,
                  ios_app_enabled: getAppsGeneralSettingsDetails.data.ios_app_enabled,
                  ios_app_name: getAppsGeneralSettingsDetails.data.ios_app_name,
                  ios_app_package_name: getAppsGeneralSettingsDetails.data.ios_app_package_name,
                  ios_google_services_json: getAppsGeneralSettingsDetails.data.ios_google_services_json,
                  ios_username: getAppsGeneralSettingsDetails.data.ios_username,
                  ios_teamname: getAppsGeneralSettingsDetails.data.ios_teamname,
                  ios_team_id : getAppsGeneralSettingsDetails.data.ios_team_id,
                  launcher_icon: getAppsGeneralSettingsDetails.data.launcher_icon,
                  app_logo: getAppsGeneralSettingsDetails.data.app_logo,
                  s3_key_store_url: getAppsGeneralSettingsDetails.data.s3_key_store_url?getAppsGeneralSettingsDetails.data.s3_key_store_url:"",
                  launcher_background: getAppsGeneralSettingsDetails.data.launcher_background,
                  app_store_api_key_id: getAppsGeneralSettingsDetails.data.app_store_api_key_id,
                  app_store_api_key_issuer_id: getAppsGeneralSettingsDetails.data.app_store_api_key_issuer_id,
                  app_store_api_auth_key_url: getAppsGeneralSettingsDetails.data.app_store_api_auth_key_url,
                  app_store_api_auth_key_file_name: getAppsGeneralSettingsDetails.data.app_store_api_auth_key_file_name,
                })
                
                
                setIsSaved(true);
            }
        }else{
          history.push("/project/projects");
        }

    })();
  }, [reloadComponent]);

  const handleEnumChange = (inputId, inputValue) => {
    setFormDetails({
        ...formdetails,
        [inputId]: ( inputValue ),
      });  
  }

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });
  }
  
    const handleJSONChange = (event) => {
    setFormDetails({
      ...formdetails,
      android_google_services_json: event.target.value ,
    });
  }
    const handleBlurJSON = (event) => {
    setFormDetails({
      ...formdetails,
      android_google_services_json: event.target.value,
    });
  }

  const handleBlur = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });

  }
  const handleSwitchChange = (fieldname,fieldvalue) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: fieldvalue,
    });
  }
  const setFileName = (fileName)=> {
      console.log("setFileName invoked ",fileName);
      setFormDetails({
         ...formdetails,
      app_store_api_auth_key_file_name : fileName,
    })
  }
  const setFileData = (data) => {
    console.log("setFileData-----------",data);
    setFormDetails({
        ...formdetails,
        ...data,
    })
  }
  const cancelPage = function(e){  history.push('projects'); }
 
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  const generateGoogleServiceJSONString = ()=>{
      let googleServiceJSON = {
                "project_info": {
                    "project_number": "12345",
                    "project_id": "12345",
                    "storage_bucket": "12345"
                },
                "client": [
                    {
                    "client_info": {
                        "mobilesdk_app_id": "12345",
                        "android_client_info": {
                        "package_name": formdetails.android_app_package_name
                        }
                    },
                    "oauth_client": [
                        {
                        "client_id": "12345",
                        "client_type": 3
                        }
                    ],
                    "api_key": [
                        {
                        "current_key": "12345"
                        }
                    ],
                    "services": {
                        "appinvite_service": {
                        "other_platform_oauth_client": [
                            {
                            "client_id": "12345",
                            "client_type": 3
                            },
                            {
                            "client_id": "12345",
                            "client_type": 2,
                            "ios_info": {
                                "bundle_id": formdetails.android_app_package_name
                            }
                            }
                        ]
                        }
                    }
                    }
                ],
                "configuration_version": "1"
            };
    return JSON.stringify(googleServiceJSON);
  }
  const isJSONValid = (jsonString)=>{
      try{
        JSON.parse(jsonString);
        return true;
      }catch(error){
        return false;
      }
  }

function validate(jsonObject,originalJSON) {
  for (var key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
        var val = jsonObject[key];
        if(originalJSON[key]===undefined) {
            error+=key+ " key is missing in Service JSON\n";
            continue;
        }
      if(typeof val === "object") {
          validate(val,originalJSON[key]);
      }
    
    }
  }
} 

  const validateGoogleServiceJSON = ()=>{
    try{  
        validate(googleServiceValidationFormat,JSON.parse(formdetails.android_google_services_json));
        if(error) {
            return false;
        }
        return true;
    }catch(error) {
        console.log(error);
        return false;
    }
  }
  const validateDuplicatePackageNameExists = ()=>{
        let duplicateName = false;
        existingApps.filter(function(app) {
            let existingPackageName = app.project_handle;
            if(formdetails.project_id!= app.project_id && formdetails.android_app_package_name===existingPackageName){
              duplicateName = true;
            }
        });
        return duplicateName;
  }
  const confirmationPopup = () => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to save app general settings ?"
                    onConfirm={() => {
                        saveAppGeneralSettings();
                        // hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Save it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                    openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
                >
                    You want to save App general settings!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
  const validateForm = () => {
    console.log("Validation Start:",formdetails);
    let validations = true;

    if(formdetails.android_app_enabled){
        if(formdetails.android_app_name.trim()===''){
            fieldStates['error_android_app_name'] = 'has-danger';
            fieldStates['error_message_android_app_name'] = 'This field is required.';
            validations = false;
        }else{
            if(formValidations.verifySpecialCharacters(formdetails.android_app_name.trim())) {
                fieldStates['error_android_app_name'] = 'has-danger';
                fieldStates['error_message_android_app_name'] = 'Invalid app name.';
                validations = false;
            }else{
                fieldStates['error_android_app_name'] = 'has-success';
                fieldStates['error_message_android_app_name'] = '';
            }
            
        }
        if(formdetails.android_app_package_name.trim()===''){
            fieldStates['error_android_app_package_name'] = 'has-danger';
            fieldStates['error_message_android_app_package_name'] = 'This field is required.';
            validations = false;
        }else{
            if(existingApps && validateDuplicatePackageNameExists()){
                fieldStates['error_android_app_package_name'] = 'has-danger';
                fieldStates['error_message_android_app_package_name'] = 'Package name already exists .Please try with different package name'; 
                validations = false;
            }else{
                if(formValidations.validateAppPackage(formdetails.android_app_package_name.trim())===false && formValidations.verifySpecialCharacters(formdetails.android_app_package_name.trim())) {
                    fieldStates['error_android_app_package_name'] = 'has-danger';
                    fieldStates['error_message_android_app_package_name'] = 'Invalid app package name.';
                    validations = false;
                }else{
                    fieldStates['error_android_app_package_name'] = 'has-success';
                    fieldStates['error_message_android_app_package_name'] = '';
                }
            }
        }
        if(formdetails.android_google_services_json.trim()===''){
            // setFormDetails({
            //     ...formdetails,
            //     android_google_services_json: JSON.stringify(googleServiceJSON)
            // });
        }else {
            if(!isJSONValid(JSON.stringify(formdetails.android_google_services_json))){
                fieldStates['error_android_google_services_json'] = 'has-danger';
                fieldStates['error_message_android_google_services_json'] = 'Invalid JSON';
                validations = false;
            }else{
                if(!validateGoogleServiceJSON()) {
                    fieldStates['error_android_google_services_json'] = 'has-danger';
                    fieldStates['error_message_android_google_services_json'] = <pre>Fields are missing in  JSON<br/>{error}</pre>;
                    error="";
                    validations = false;
                }else{
                    if(validations && formdetails.android_app_package_name!==JSON.parse(formdetails.android_google_services_json).client[0].client_info.android_client_info.package_name){
                        fieldStates['error_android_app_package_name'] = 'has-danger';
                        fieldStates['error_message_android_app_package_name'] = 'Package name should be same as client_info.android_client_info.package_name of service JSON.';
                        validations = false;
                    }
                    fieldStates['error_android_google_services_json'] = 'has-success';
                    fieldStates['error_message_android_google_services_json'] = '';
                }
            }
            
        }
    }
    if(formdetails.ios_app_enabled){
        // if(formdetails.ios_app_name.trim()===''){
        //     fieldStates['error_ios_app_name'] = 'has-danger';
        //     fieldStates['error_message_ios_app_name'] = 'This field is required.';
        //     validations = false;
        // }else{
        //     if(formValidations.verifySpecialCharacters(formdetails.ios_app_name.trim())) {
        //         fieldStates['error_ios_app_name'] = 'has-danger';
        //         fieldStates['error_message_ios_app_name'] = 'Invalid app name.';
        //         validations = false;
        //     }else{
        //         fieldStates['error_ios_app_name'] = 'has-success';
        //         fieldStates['error_message_ios_app_name'] = '';
        //     }
        // }
        if(formdetails.ios_app_package_name.trim()===''){
            fieldStates['error_ios_app_package_name'] = 'has-danger';
            fieldStates['error_message_ios_app_package_name'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_ios_app_package_name'] = 'has-success';
            fieldStates['error_message_ios_app_package_name'] = '';
        }
        // if(formdetails.ios_google_services_json.trim()===''){
        //     fieldStates['error_ios_google_services_json'] = 'has-danger';
        //     fieldStates['error_message_ios_google_services_json'] = 'This field is required.';
        //     validations = false;
        // }else{
        //     if(!isJSONValid(formdetails.ios_google_services_json.trim())){
        //         fieldStates['error_ios_google_services_json'] = 'has-danger';
        //         fieldStates['error_message_ios_google_services_json'] = 'Invalid JSON.';
        //         validations = false;
        //     }else{
        //         fieldStates['error_ios_google_services_json'] = 'has-success';
        //         fieldStates['error_message_ios_google_services_json'] = '';
        //     }
        // }
        // if(formdetails.ios_username.trim()===''){
        //     fieldStates['error_ios_username'] = 'has-danger';
        //     fieldStates['error_message_ios_username'] = 'This field is required.';
        //     validations = false;
        // }else{
        //     fieldStates['error_ios_ios_username'] = 'has-success';
        //     fieldStates['error_message_ios_username'] = '';
        // }
        // if(formdetails.ios_teamname.trim()===''){
        //     fieldStates['error_ios_teamname'] = 'has-danger';
        //     fieldStates['error_message_ios_teamname'] = 'This field is required.';
        //     validations = false;
        // }else{
        //     fieldStates['error_ios_teamname'] = 'has-success';
        //     fieldStates['error_message_ios_teamname'] = '';
        // }
        if(formdetails.ios_team_id===''){
            fieldStates['error_ios_team_id'] = 'has-danger';
            fieldStates['error_message_ios_team_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_ios_team_id'] = 'has-success';
            fieldStates['error_message_ios_team_id'] = '';
        }
        if(formdetails.app_store_api_key_id===undefined || formdetails.app_store_api_key_id.trim()===''){
            fieldStates['error_app_store_api_key_id'] = 'has-danger';
            fieldStates['error_message_app_store_api_key_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_key_id'] = 'has-success';
            fieldStates['error_message_app_store_api_key_id'] = '';
        }
        if(formdetails.app_store_api_key_issuer_id===undefined || formdetails.app_store_api_key_issuer_id.trim()==='' ){
            fieldStates['error_app_store_api_key_issuer_id'] = 'has-danger';
            fieldStates['error_message_app_store_api_key_issuer_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_key_issuer_id'] = 'has-success';
            fieldStates['error_message_app_store_api_key_issuer_id'] = '';
        }
        if(formdetails.app_store_api_auth_key_url===undefined || formdetails.app_store_api_auth_key_url.trim()===''){
            fieldStates['error_app_store_api_auth_key_url'] = 'has-danger';
            fieldStates['error_message_app_store_api_auth_key_url'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_auth_key_url'] = 'has-success';
            fieldStates['error_message_app_store_api_auth_key_url'] = '';
        }


    }

    if(formdetails.launcher_icon.trim()===''){
        fieldStates['error_launcher_icon'] = 'has-danger';
        fieldStates['error_message_launcher_icon'] = 'This field is required.';
        validations = false;
    }else{
        fieldStates['error_launcher_icon'] = 'has-success';
        fieldStates['error_message_launcher_icon'] = '';
    }

    if(formdetails.app_logo.trim()===''){
        fieldStates['error_app_logo'] = 'has-danger';
        fieldStates['error_message_app_logo'] = 'This field is required.';
        validations = false;
    }else{
        fieldStates['error_app_logo'] = 'has-success';
        fieldStates['error_message_app_logo'] = '';
    }

    // if(formdetails.launcher_background.trim()===''){
    //     fieldStates['error_launcher_background'] = 'has-danger';
    //     fieldStates['error_message_launcher_background'] = 'This field is required.';
    //     validations = false;
    // }else{
    //     fieldStates['error_launcher_background'] = 'has-success';
    //     fieldStates['error_message_launcher_background'] = '';
    // }

    
    setFieldStates({
        ...fieldStates,
        fieldStates
    });
    return validations;
  }
  const saveAppGeneralSettings = async ()=>{
    //   setShowLoader(true);
    //   setShowSaveButton(false);
      let formdata = formdetails;
      if(formdetails.android_app_enabled && !formdetails.android_google_services_json) {
            formdata = {
                ...formdetails,
                android_google_services_json : generateGoogleServiceJSONString()
            }
        }
      let saveProjectSettings = await projectsService.saveProjectAppsGeneralSettings(formdata);
      console.log("saveProjectSettings",saveProjectSettings);
      if(!saveProjectSettings.status && saveProjectSettings.error.errorCode === 403){
        setShowPopup({alert:null});
        context.setIsAuthorized(context);
      }  
      else if (saveProjectSettings.status && saveProjectSettings.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="App Settings Saved !"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                            openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
                        >
                            App settings saved successfully.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                    
                }, 3000);

                setReloadComponent(Math.random());
                
            }else {
                setTimeout(() => {
                    hideAlert();
                    // window.location.reload();
                }, 1000);
                if(saveProjectSettings.error && saveProjectSettings.error.errorCode=== 1015) {
                    fieldStates['error_ios_app_package_name'] = 'has-danger';
                    fieldStates['error_message_ios_app_package_name'] = saveProjectSettings.error.message;
                    setFieldStates({
                            ...fieldStates,
                            fieldStates
                        });
                }
                if(saveProjectSettings.error && saveProjectSettings.error.errorCode===1016) {
                    fieldStates['error_app_store_api_key_id'] = 'has-danger';
                    fieldStates['error_message_app_store_api_key_id'] = 'Invalid Api key';
                    fieldStates['error_app_store_api_key_issuer_id'] = 'has-danger';
                    fieldStates['error_message_app_store_api_key_issuer_id'] = 'Invalid Issuer Id.';
                    fieldStates['error_app_store_api_auth_key_url'] = 'has-danger';
                    fieldStates['error_message_app_store_api_auth_key_url'] = 'Invalid Auth key file.';
                    setFieldStates({
                            ...fieldStates,
                            fieldStates
                        });
                }
                
                // setShowPopup({
                //     alert: (
                //         <ReactBSAlert
                //             danger
                //             style={{ display: "block", marginTop: "-100px" }}
                //             title="App general settings could not save!"
                //             onConfirm={() => hideAlert()}
                //             onCancel={() => hideAlert()}
                //             confirmBtnBsStyle="info"
                //             btnSize=""
                //             openAnim = {{ name: 'hideSweetAlert', duration: 3000 }}
                //         >
                //             App settings could not save.
                //         </ReactBSAlert>
                //     ),
                // });
    
                // setTimeout(() => {
                //     hideAlert();
                // }, 3000);
                // setReloadComponent(Math.random());
            }
    //   if(saveProjectSettings.status){
    //     setTimeout(()=>{
    //       setShowLoader(false)
    //       setShowSaveButton(true);
    //       setAlert({show:true,type:'success',message:'Project Apps settings were saved successfully'});
    //     },1000);
        
    //   }else{
    //     setTimeout(()=>{
    //       setShowLoader(false)
    //       setShowSaveButton(true)
    //       setAlert({show:true,type:'danger',message:'Project Apps settings could not be saved, Please try again.'});
    //     },1000);
    //   }

    //   setTimeout(()=>{
    //     setAlert({show:false,type:'',message:''});
    //   },3000);

    //   setTimeout(()=>{
    //     window.location.reload();
    //   },2000);
  }

  const onSave = ()=> {
      if(validateForm()==true) {
          confirmationPopup();
      }
  }

  console.log("formdetails",formdetails)

    return (
      <div className="content">
          {showPopup.alert}
        <Container>
            <Prompt message="Are you sure you want to leave?"/>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">{projectName} - Apps General Settings</CardTitle>
                  {isSaved ?<Row>
                      <Col md="6"></Col>
                    <Col md="6" align="right"> 
                    {projectAppsGeneralSettings?.s3_key_store_url?                  
                    <a target="_blank" href={projectAppsGeneralSettings?.s3_key_store_url}>
                      <Button
                        className="btn-round"
                        color="primary"
                        size="sm"
                        type="button"
                      >Download Key Store File
                      </Button>
                    </a>
                    :""}
                       
                        <Button
                            // style={{float: 'left' }}
                            className="btn-round"
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={(event)=>  history.push("/appbuild/dashboard?projectid="+projectId) }
                        >
                             App Version History
                        </Button>
                        <Button
                            className="btn-round"
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={(event)=>  history.push("/config/appconfigversions?projectid="+projectId) }
                        >
                            Customize App 
                        </Button>
                        <a title="App Template" onClick={(event)=>  history.push("/project/apptemplates?projectid="+projectId) }  class="btn-rotate">
                            <i class="nc-icon nc-settings-gear-65"></i>
                        </a>
                       
                    </Col>
                </Row>:""}
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="appSetting" method="POST" onSubmit={handleSubmit}>
                    <hr/>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Get Android App
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enable switch for Android App</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup>
                                <Switch name="android_app_enabled"
                                    offColor="success"
                                    offText={<i className="nc-icon nc-simple-remove" />}
                                    onColor="success"
                                    onText={<i className="nc-icon nc-check-2" />}
                                    value={formdetails.android_app_enabled}
                                    onChange={(value,enabled)=>  handleSwitchChange('android_app_enabled',enabled) }
                                />
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>
                    

                {formdetails.android_app_enabled?
                        <p>
                            <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Android App Name<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter name for your Android app</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_android_app_name"]}>
                        <Input
                            type="text"
                            name="android_app_name"
                            id="android_app_name"
                            value={formdetails.android_app_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_android_app_name"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_android_app_name"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Android Package Name<span className="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter app package name (com.yourappname.app)</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_android_app_package_name"]}>
                                <Input
                                    type="text"
                                    name="android_app_package_name"
                                    id="android_app_package_name"
                                    value={formdetails.android_app_package_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {fieldStates["error_android_app_package_name"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_android_app_package_name"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Android Google Services JSON
                            </small>
                        </div>
                        <Label className="smLabl-txt">Paste google service json</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_android_google_services_json"]}>
                                <Input
                                    type="textarea"
                                    
                                    name="android_google_services_json"
                                    id="android_google_services_json"
                                    value={formdetails.android_google_services_json}
                                    // value ={googleServiceJSON?JSON.stringify(googleServiceJSON):""}
                                    onChange={handleJSONChange}
                                    onBlur={handleBlurJSON}
                                    style={{ "min-height": "300px","padding":"15px" }}
                                />
                                {fieldStates["error_android_google_services_json"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_android_google_services_json"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                FCM API Server Key
                            </small>
                        </div>
                        <Label className="smLabl-txt">Deprecated and will be removed on June 21, 2024.</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_fcm_api_server_key"]}>
                                <Input
                                    type="textarea"
                                    name="fcm_api_server_key"
                                    id="fcm_api_server_key"
                                    value={formdetails.fcm_api_server_key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Firebase Service Account JSON
                            </small>
                        </div>
                        <Label className="smLabl-txt">Add service account json for sending Notification</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_fcm_service_account_json"]}>
                                <Input
                                    type="textarea"
                                    name="fcm_service_account_json"
                                    id="fcm_service_account_json"
                                    value={formdetails.fcm_service_account_json}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ "min-height": "200px","padding":"15px" }}
                                />
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>
                </p>:''}
                <hr/>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Get IOS App
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enable switch for IOS App</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup>
                                <Switch name="ios_app_enabled"
                                    offColor="success"
                                    offText={<i className="nc-icon nc-simple-remove" />}
                                    onColor="success"
                                    onText={<i className="nc-icon nc-check-2" />}
                                    value={formdetails.ios_app_enabled}
                                    onChange={(value,enabled)=>  handleSwitchChange('ios_app_enabled',enabled) }
                                />
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>

                  {formdetails.ios_app_enabled?
                        <p>
                    {formdetails.ios_app_name ?
                   <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                               IOS App Name 
                            </small>
                        </div>
                        {/*  */}
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_app_name"]}>
                                <Label >{formdetails.ios_app_name}</Label>
                        {/* <Input
                            type="text"
                            name="ios_app_name"
                            id="ios_app_name"
                            value={formdetails.ios_app_name}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            readonly
                        /> */}
                        {/* {fieldStates["error_ios_app_name"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_ios_app_name"
                                        ]
                                    }
                                </label>
                            ) : null} */}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row> :""}
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                IOS Package Name<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter app package name (com.yourappname.app)</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_app_package_name"]}>
                        <Input
                            type="text"
                            name="ios_app_package_name"
                            id="ios_app_package_name"
                            value={formdetails.ios_app_package_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_ios_app_package_name"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_ios_app_package_name"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                IOS Google Services JSON
                            </small>
                        </div>
                        <Label className="smLabl-txt">Paste google service json</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_google_services_json"]}>
                        <Input
                            type="textarea"
                            name="ios_google_services_json"
                            id="ios_google_services_json"
                            value={formdetails.ios_google_services_json}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_ios_google_services_json"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_ios_google_services_json"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row> */}
                  
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                 API key Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api key id</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_key_id"]}>
                        <Input
                            type="textarea"
                            name="app_store_api_key_id"
                            id="app_store_api_key_id"
                            value={formdetails.app_store_api_key_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_app_store_api_key_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_key_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                 API key issuer Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api key issuer id</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_key_issuer_id"]}>
                        <Input
                            type="textarea"
                            name="app_store_api_key_issuer_id"
                            id="app_store_api_key_issuer_id"
                            value={formdetails.app_store_api_key_issuer_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_app_store_api_key_issuer_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_key_issuer_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                 API Auth File <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api auth key</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_auth_key_url"]}>
                            <FileUpload
                                    projectId={projectId}
                                    id="app_store_api_auth_key_url"
                                    setFileName = {setFileName}
                                    value= {formdetails.app_store_api_auth_key_file_name}
                                    file_type="application/pkcs8,"
                                    handleEnumChange={handleEnumChange}
                                    setFileData={setFileData}
                                />
                        
                        {fieldStates["error_app_store_api_auth_key_url"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_auth_key_url"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                               IOS Username <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter username</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_username"]}>
                        <Input
                            type="text"
                            name="ios_username"
                            id="ios_username"
                            value={formdetails.ios_username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_ios_username"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_ios_username"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                IOS Team Name<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter Team Name</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_teamname"]}>
                        <Input
                            type="text"
                            name="ios_teamname"
                            id="ios_teamname"
                            value={formdetails.ios_teamname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_ios_teamname"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_ios_teamname"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row> */}
                  <Row>
                      <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                IOS Team Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">IOS team ID</Label>
                    </Col>
                      <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_team_id"]}>
                            <Input
                                type="text"
                                name="ios_team_id"
                                id="ios_team_id"
                                value={formdetails.ios_team_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {fieldStates["error_ios_team_id"] ===
                                "has-danger" ? (
                                    <label className="error">
                                        {
                                            fieldStates[
                                                "error_message_ios_team_id"
                                            ]
                                        }
                                    </label>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  
                  
                </p>:''}
                
                <hr/>
                <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                                Launcher Icon(1024x1024)<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload launcher icon</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_launcher_icon"]}>
                        <ImageUpload
                                projectId={projectId}
                                size="1024x1024"
                                id="launcher_icon"
                                value={formdetails.launcher_icon ||defaultImage}
                                file_type="image/jpg,image/png,image/jpeg"
                                handleEnumChange={handleEnumChange}
                            />
                            {fieldStates["error_launcher_icon"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_launcher_icon"
                                        ]
                                    }
                                </label>
                            ) : null}
                        
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                        <div className="designLabel">
                            <small className="text-default">
                               App Logo (960x1600) <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload App Logo</Label>
                    </Col>
                    <Col sm="4">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_logo"]}>
                        <ImageUpload
                                projectId={projectId}
                                size="960x1600"
                                id="app_logo"
                                value={formdetails.app_logo || defaultImage}
                                file_type="image/jpg,image/png,image/jpeg,image/gif"
                                handleEnumChange={handleEnumChange}
                            />
                         {fieldStates["error_app_logo"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_logo"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                

                  {/* <Row>
                      <Label sm="3">Launcher Background <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={fieldStates["error_launcher_background"]}>
                       

                        <div className="inptFomColr">
                            <div className="clrChng" style={{display:"inline-block", border:"none", }}>
                            <input
                                style={{ background: formdetails.launcher_background}}
                                type="color"
                                name="launcher_background"
                                id="launcher_background"
                                value={formdetails.launcher_background}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />    
                            </div>
                            <div className="clrTxt" style={{display:"inline-block"}}>
                            <input 
                            //   style={{border:"none" }}
                              type="text"
                              name="launcher_background"
                                id="launcher_background"
                                value={formdetails.launcher_background}
                              onChange={handleChange}
                              />
                              {fieldStates["error_launcher_background"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_launcher_background"
                                        ]
                                    }
                                </label>
                            ) : null}
                              </div>
                              
                        </div>

                        </FormGroup>
                      </Col>
                  </Row>   */}



                  


                  {showSaveButton?<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={onSave}
                  >
                    Save
                  </Button>:''}
                  <Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={cancelPage}
                  >
                    Cancel
                  </Button>
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default AppSettings;
