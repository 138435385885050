import axios from 'axios';


const sendMessage = async (formdata)=> {
    const response = await axios.post('/api/whatsapp/send-message',formdata);
    return response.data;
}
const getMessages = async (formdata) => {
    const response = await axios.post('/api/whatsapp/messages',formdata);
    return response.data;
}

const getAlertsList = async(formdata) => {
    const response = await axios.post('/api/whatsapp/get-alerts',formdata)
    return response.data;
}

export default {
    sendMessage,
    getMessages,
    getAlertsList
}