import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import appBuildService from "../../services/api/app-build-job-service";
import projectsService from "../../services/api/projects-service";

import './AppBuild.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import QRCode from 'qrcode';
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";

const AppBuilds = (props)=> {

  const [appBuildJobs, setAppBuildJobs] = useState(false);
  const [totalAppBuildJobs, setTotalAppBuildJobs] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');

  const [publisherId,setPublisherId] = useState('');
  const [projectId,setProjectId] = useState('');

  const [formdetails, setFormDetails] = useState({project_id :'' , versionName : '' , platform:"",status : 6});
  const [search, setSearchDetails] = useState({project_id :'' , versionName : '',platform:"",status : 6});

  const [fieldStates,setFieldStates] = useState({});

  const records = 10;
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectNameArray,setProjectNames] = useState([]);
  const platformArray = [{value:"",label:"All"},{value:"android",label:"Android"},{value:"ios",label:"iOS"}];




  const platformChange = (event) => {
    setFormDetails({
      ...formdetails,
      platform: event.value,
    });
  }

  const generateQR = async text => {
      try {
        return await QRCode.toDataURL(text,{ errorCorrectionLevel: 'H'});
      } catch (err) {
        console.error(err)
      }
    }

  useEffect( () => {
      (async () =>{
        let publishers = await projectsService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                    getPublishers.push(publisherValue)
                })
            }
            setPublisherNames(getPublishers);
        }
      })();
    }, [reloadComponent]);

  useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
  }, [publisher]);

  useEffect( () => {
    (async () =>{
        let jobsData = await appBuildService.getAppBuildJobs(formdetails,activePage,records);
        if(jobsData.status && jobsData.data){
                setAppBuildJobs(jobsData.data.appBuildJobs);
                setTotalAppBuildJobs(jobsData.data.count);
                for(const jobDetails of jobsData.data.appBuildJobs) {
                  if(jobDetails.status===6 && jobDetails.platform === 'android') {
                    fieldStates[jobDetails._id+"_qrlink"] = await generateQR(jobDetails.s3_path);
                  }
                  
                }
                setFieldStates({...fieldStates,fieldStates});
        }  
        setRenderComponent(true);
      })();
    }, [reloadComponent,search,activePage]);


  const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
  }
  let appBuildJobsElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!appBuildJobs || !appBuildJobs.length){
    if(!renderComponent){
        appBuildJobsElements.push(
        <tr>
          <td colSpan="7" className="text-center">
              <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </td>
        </tr>
      );
    }else{
        appBuildJobsElements.push(
          <tr>
            <td colSpan="7" className="text-center">
                <CardTitle tag="h4">No Records Found</CardTitle>
            </td>
          </tr>
    );
    }
  }else{
     let i = 1;
      appBuildJobs.forEach(function(jobsDetails) {
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          appBuildJobsElements.push(
            <tr id={jobsDetails._id}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
                </td>
                <td className="text-left">
                      {jobsDetails.project_id && jobsDetails.project_id.publishers_id? jobsDetails.project_id.publishers_id.name : ''}
                </td>
                <td className="text-left">
                      {jobsDetails.project_id ? jobsDetails.project_id.name : ''}
                </td>
                <td className="text-left">
                    {jobsDetails.version_name} <br/>
                </td>
                <td className="text-left">
                  {jobsDetails.platform}
                </td>
                <td className="text-left">
                    <Moment format="DD MMM YYYY hh:mm:ss a">{jobsDetails.createdAt}</Moment>
                </td>
                <td className="text-left">
                    {jobsDetails.platform == 'android' ?  
                      <>
                      <Row>
                          <Col>
                            <a target="_blank" href={jobsDetails.status==6 ?jobsDetails.s3_path:""}>    
                                <Button                            
                                        className="btn-round"
                                        color="primary"
                                        size="sm"
                                        type="button"
                                    >Download Apk
                                </Button>
                            </a>
                          </Col>
                          <Col>
                            <a target="_blank" href={jobsDetails.status==6 ?jobsDetails.s3_bundle_path:""}>    
                                <Button                            
                                        className="btn-round"
                                        color="primary"
                                        size="sm"
                                        type="button"
                                    >Download Bundle
                                </Button>
                            </a>
                          </Col>
                          
                      </Row>
                      <Row>
                        <Col>
                          <img  style= {{cursor:"pointer"}} title="Click Me" onClick = {()=>{setShowQRPopup(
                          
                              <ReactBSAlert
                                  title="Scan & Download App"
                                  onCancel={()=>{setShowQRPopup(false)}}
                                  openAnim = {{ name: 'showSweetAlert', duration: 700 }}
                                  showConfirm={false}
                                  closeOnClickOutside
                              >
                                  <img src={fieldStates[jobsDetails._id+"_qrlink"]} width="450" height="450">
                                  
                                  </img>
                              </ReactBSAlert>
                          
                          )}} src={fieldStates[jobsDetails._id+"_qrlink"]} width="100" height="100"></img>
                        </Col>
                      </Row>
                      </>
                      :""
                    }
                </td>
            </tr>
        )
        if(i==appBuildJobs.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==appBuildJobs.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchJobs();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const searchJobs = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherId('');
    setProjectId('');
    setPublisher('')
    setFormDetails({
      ...formdetails,
      publisher_id: '',
      project_id:'',
      platform : ''
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className="content">
        {showQRPopup}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    App Versions
                </CardTitle>
                <Col align="right">
                </Col>
              <Form id="appBuildsfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Project .."
                    name = "project_id"
                    id="project_id"
                    options={projectNameArray}
                    value={projectId}
                    onChange={changeProjectId}
                  />
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Platform .."
                    name = "platform"
                    id="platform"
                    options={platformArray}
                    onChange={platformChange}
                    value = {platformArray.filter(details => details.value === formdetails.platform)}
                  />
                  </Col>
                  <Col md="3">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>

                </Form>
             
                <hr></hr>
                {totalAppBuildJobs > 0 ? 
                  <Row>
                        <Col md="6">
                          <div style={{float:'right'}}>
                            <b>{firstRecord} - {lastRecord} of {totalAppBuildJobs}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{'float':'left'}}>
                              <Pagination
                              activePage={activePage}
                              itemsCountPerPage={records}
                              totalItemsCount={totalAppBuildJobs}
                              pageRangeDisplayed={3}
                              onChange={handlePageChange.bind(this)}
                              itemClass="page-item"
                              linkClass="page-link"
                              />
                          </div>
                        </Col>
                      </Row>             
                : null}   
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th> 
                        <th className="text-left">Publisher Name</th> 
                        <th className="text-left">Project Name</th>
                        <th className="text-left">Version</th>
                        <th className="text-left">Platform</th> 
                        <th className="text-left">Created At</th>
                        <th className="text-left">Action</th> 
                      </tr>
                    </thead>
                    <tbody>{appBuildJobsElements}</tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default AppBuilds;
