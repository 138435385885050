import React, { useEffect, useState, useRef, useContext } from "react";
import { Button,Spinner , Label } from "reactstrap";
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
const UploadFile = (props)=> {
    const projectId = props.projectId;
    let defaultFile = '';
    if(props.value){
      defaultFile = props.value;
    }
    const [file, setFile] = useState(null);
    const context = useContext(UserContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader]   = useState(false);
    const fileInputRef = useRef(null);
    const [fileNameLabel, setFileNameLabel] = useState(false);

    if(props.value && fileNameLabel != props.value){
      setFileNameLabel(props.value);
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileValidated = true;
    if(file.size > 1024000){ //1024000
      fileValidated = false;
      setError(true);
      setErrorMessage('File Size is greater than 1MB');
    }

    let fileTypeArray = props.file_type.split(',');
    console.log("file.type",file.type);
    console.log("fileTypeArray",fileTypeArray);
    console.log("File :",file);
    if(!fileTypeArray.includes(file.type)){
      fileValidated = false;
      setError(true);
      setErrorMessage('Invalid Auth file. Only .p8 supported');
      return false;
    }

    if(fileValidated){  
      reader.onload = async (event) => {
        let fileData = event.target.result;
        fileData = fileData.split(',')[1];
        setShowLoader(true);
            // props.setFileName(file.name);
            let uploadFileToServer = await projectsService.uploadFile(projectId, props.id, fileData , file.type);
            console.log("uploadFileToServer",props.id,uploadFileToServer.data);   
            if(!uploadFileToServer.status && uploadFileToServer){
                context.setIsAuthorized(context);
            }
            else if(uploadFileToServer.status){
                setError(false);
                setFileNameLabel(file.name);
                let image = {
                    value : uploadFileToServer.data
                }
                let data = {
                  'app_store_api_auth_key_url': uploadFileToServer.data,
                  'app_store_api_auth_key_file_name': file.name
                }
                props.setFileData(data)
                // props.handleEnumChange(props.id,uploadFileToServer.data);
                setShowLoader(false);
            }else{
                setError(false);
                setErrorMessage("There was an error. Please try again.");
                setShowLoader(false);
            }
          };       
      reader.readAsDataURL(file);
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  }
  const handleRemove = () => {
    setFile(null);
    setFileNameLabel('');
    fileInputRef.current.value = null;
    let image ={
        value : ""
    }
    let data = {
      'app_store_api_auth_key_url': "",
      'app_store_api_auth_key_file_name': ""
    }
    props.setFileData(data)
    // props.handleEnumChange(props.id,'');
    // props.setFileName('');
  }

  
    return (
      <div className="fileinput text-center">
        <input type="file"  onChange={handleImageChange} ref={fileInputRef} />
        
        <div>
            <Label >
          {fileNameLabel? fileNameLabel : "No file choosen"}
        </Label>
          {(!fileNameLabel) ? (
            <Button className="btn-smb" onClick={() => handleClick()}>
              {"select file"}
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => handleClick()}>
                Change
              </Button>
             
              <Button
                color="danger"
                className="btn-round"
                onClick={() => handleRemove()}
              >
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )}
        </div>

        {showLoader?
        <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
        </Spinner>:''}

        {error?<label className="error" style={{color:'red'}}>
                {errorMessage}  
        </label>:''}
      </div>
    );

}


export default UploadFile;
