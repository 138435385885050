import React, { useEffect, useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import contractService from "../../services/api/contract-service";
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Spinner,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const Invitations = (props)=> {
  const history = new useHistory();
  const [invitations, setInvitations] = useState(false);
  const [totalInvitations, setTotalInvitations] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [formdetails, setFormDetails] = useState({publisher_id:''});
  const [search, setSearchDetails] = useState({publisher_id:''});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const records = 10;
  const context = useContext(UserContext);
  useEffect( () => {
    (async () =>{
        let getTotalInvitations = await contractService.getTotalPlanInvitations(formdetails);
            if(getTotalInvitations.status){
                setTotalInvitations(getTotalInvitations.data.count);
            }
        })();
    }, [reloadComponent,search]);

  useEffect( () => {
    (async () =>{
        let invitationsData = await contractService.getPlanInvitations(formdetails,activePage,records);

        if(invitationsData.status){
                setInvitations(invitationsData.data);
            }
          
            setRenderComponent(true);
        })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let invitationElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!invitations || !invitations.length){
    if(!renderComponent){
      invitationElements.push(
       <tr>
        <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
      invitationElements.push(
          <tr>
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      invitations.forEach(function(invitationDetails) {
          if(i===1){
            firstRecord = (activePage-1)*records+i;
          }
          invitationElements.push(
            <tr>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
                   {invitationDetails.publisher_id?
                   <a onClick={(event)=>  window.location.href = "/report/publisher?publisher_email="+invitationDetails.publisher_id.email } href="javascript:void(0)" class="btn-rotate nav-link">
                   {invitationDetails.publisher_id.name}
                 </a>:''}
               </td>
               <td className="text-left">
                   {invitationDetails.project_id?
                   <a onClick={(event)=>  window.location.href = "/report/project?project="+invitationDetails.project_id.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {invitationDetails.project_id.name}
                 </a>:''}
               </td>
               <td className="text-left">
                   {invitationDetails.plan_id?invitationDetails.plan_id.name:""}
               </td>
          
               <td className="text-left">
                   {invitationDetails.invitedBy}
               </td>

               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{invitationDetails.createdAt}</Moment>
               </td>
            </tr>
        )
        if(i===invitations.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i===invitations.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchInvitations();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value
    });
    setPublisherId(event)
  }

  const searchInvitations = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherId('');
    setFormDetails({
      ...formdetails,
      publisher_id: ''
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Enterprise Plan Invitations Dashboard
                </CardTitle>
                <Row>
                  <Col>
                <Button
                       style={{float: 'right'}}
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/contract/invite-enterprise-plan") }
                  >Invite for Enterprise Plan
                </Button>
                </Col>
                </Row>
              <Form id="contractfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>            
                <hr></hr>
                <Row>
                      <Col md="6">
                        <div style={{float:'right'}}>
                          <b>{firstRecord} - {lastRecord} of {totalInvitations}</b>
                        </div>
                      </Col>
                      <Col md="6">
                      <div style={{float:'left'}}>
                          <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalInvitations}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          />
                      </div>
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th> 
                      <th className="text-left">Plan</th> 
                      <th className="text-left">Invited By</th>
                      <th className="text-left">Invited At</th>
                      </tr>
                    </thead>
                    <tbody>{invitationElements}</tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Invitations;
