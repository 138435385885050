/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import userService from '../../services/api/user-service';
import projectsService from "../../services/api/projects-service";
import {Redirect} from 'react-router-dom';
import googleSigninImage from '../../assets/img/google-signupimage.png'
// reactstrap components
import {
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
      errorMessage : false,
      renderMessage : false,
      user:false
    };
  }
  async componentDidMount() {
    document.body.classList.toggle("login-page");

    let flashMessage = await userService.getFlashMessage();
    if(flashMessage.status && flashMessage.data){
      if(Object.keys(flashMessage.data).length){
            this.setState({errorMessage:flashMessage.data});
            this.setState({renderMessage:true});
      }              
    }
    let user=await projectsService.getLoggedInUser();
    if(user.status && user.data){
      this.setState({user:user.data});
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  render() {
    const showMessage = this.state.renderMessage;
    let alertMessage;
    const searchParams = new URLSearchParams(window.location.search);
    const isAuthenticated = searchParams.get("authenticated")
    if (showMessage) {
      alertMessage = <UncontrolledAlert color="danger">
                  <span>
                    <b>{this.state.errorMessage}</b>
                  </span>
                </UncontrolledAlert>;
    }else if(isAuthenticated === "false"){
      alertMessage = <UncontrolledAlert color="danger" style={{'.aler button close':{
        "background-color": "black",
        "border": "black",
        "border-radius": "12px",
      }
    }}>
                  <span>
                    <b>Not authorized</b>
                  </span>
                </UncontrolledAlert>;
    } else {
      alertMessage = '';
    }
    if(this.state.user){
    return <Redirect to='/dashboard/home' />
    }
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ms-auto me-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                {alertMessage}
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody align="center">
                  <a href="/auth/google"><img src={googleSigninImage}  title="Sign In with Google"></img></a>
                    {/* <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="First Name..." type="text" />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                      />
                    </InputGroup>
                    <br />
                    <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Subscribe to newsletter
                        </Label>
                      </FormGroup>
                    </FormGroup> */}
                  </CardBody>
                  <CardFooter>
                    {/* <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Get Started
                    </Button> */}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/fabio-mangione.jpg")})`,
          }}
        />
      </div>
    );
  }
}

export default Login;
