import React, { useEffect, useState, useContext } from "react";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Switch from "react-bootstrap-switch";
import UserContext from "../../../UserContext"
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const AddRazorpayPlan = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [basePriceState, setBasePriceState] = useState("");
    const [basePriceError, setBasePriceError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const history = new useHistory();
    const [componentRendered, setComponentRendered] = useState(true);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState({
        plan_id: props.planId,
        razorpay_plan_id: "",
        price: "",
    });
    const context = useContext(UserContext);
    const [modified, setModified] = useState(false);
    const [showForm, setShowFrom] = useState(false);
    const [showPopup,setShowPopup] = useState({alert:null});

    
    useEffect(() => {
        (async () => {
            const response = await saasService.getRazorpayPlans(props.planId);
            if(response.status && response.data) {
                setRazorpayPlans(response.data);
            }
        })();
    }, [modified]);

    // const validateName = () =>{

    //     if(formdetails.name===''){
    //         setNameState("has-danger");
    //         setNameError("This field is required");
    //         return false;
    //       }

    //       if(planGroups && planGroups.length > 0){
    //         let duplicateName = false;
    //         planGroups.forEach(function(details) {
    //             if(formdetails.name===details.name){
    //                 duplicateName = true;
    //             }
    //         })
    //         if(duplicateName){
    //             setNameState("has-danger");
    //             setNameError("You have already created the plan group with this name.");
    //             return false;
    //         }
    //       }
    //       setNameState("has-success");
    //       return true;

    // }




        const validateBasePrice = (base_price) => {
        if (!base_price) {
            setBasePriceState("has-danger");
            setBasePriceError("Amount is required");
            return false;
        }

        if (Number(base_price) < 0) {
            setBasePriceState("has-danger");
            setBasePriceError("Amount will never be negative");
            return false;
        }
        setBasePriceState("has-success");
        return true;
    };
    const validateRazorpayId = (razorpayPlanId) => {
        if (!razorpayPlanId) {
            setNameState("has-danger");
            setNameError("Razorpay plan id is required");
            return false;
        }
        setNameState("has-success");
        return true;
    };
    const validateForm = async () => {
        let isValidRazpayId  = validateRazorpayId(currentPlan.razorpay_plan_id);
       let isAmountValid = validateBasePrice(currentPlan.price);
        if (isValidRazpayId && isAmountValid) {
            setShowLoader(true);
            const response = await saasService.addRazorpayPlan(currentPlan);
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if(response.status && response.data) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Razorpay Plan was added Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    if (modified) {
                        props.setModified(!props.state);
                        setModified(false);
                    } else {
                        props.setModified(!props.state);
                        setModified(true);
                    }
                }, 2000);
                
            }else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                            show: true,
                            type: "danger",
                            message: "Razorpay plan could not be added, Please try again.",
                        });
                    }, 1000);

                    setTimeout(() => {
                        setAlert({ show: false, type: "", message: "" });
                    }, 3000);
            }
         setCurrentPlan({
            plan_id: props.planId,
            razorpay_plan_id: "",
            price: "",
        });

        }
    };

    const deleteRazorpayPlan = async (rzplanId) => {
        const response = await saasService.deleteRazorpayPlan(rzplanId);
        if(!response.status && response.error.errorCode === 403) {
            context.setIsAuthorized(context);
        }
        hideAlert();
        if (modified) {
            setModified(false);
            props.setModified(false);
        } else {
            setModified(true);
            props.setModified(true);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        setCurrentPlan({
            ...currentPlan,
            [event.target.name]: event.target.value,
        });
    };
    const toggleForm = (event) => {
        if (showForm) {
            setShowFrom(false);
        } else {
            setShowFrom(true);
        }
    };


    const handleRzpPlanId = (event) =>{
        setCurrentPlan({
            ...currentPlan,
            razorpay_plan_id : event.target.value
        })
    }

    const confirmationPopup = (planId) => {
       
        setShowPopup({
          alert: (
            <ReactBSAlert
              info
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure want to Delete ?"
              onConfirm={() =>{ deleteRazorpayPlan(planId)}}
              onCancel={() =>{ hideAlert()}}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="primary"
              confirmBtnText="Yes, Delete it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to delete this plan!
            </ReactBSAlert>
          ),
        });
      }
      const hideAlert = () => {
        setShowPopup({
          alert: null,
        });
      };  
  
    const handleIsPrimarySwitch = async (planId, enabled) => {
        if (enabled) {
            const response = await saasService.changeRazorpayPlanPrimaryStatus(
                planId,
                enabled
            );
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                if (modified) {
                    setModified(false);
                    props.setModified(false);
                } else {
                    setModified(true);
                    props.setModified(true);
                }
            }
        }else{
            // setReloadComponent(Math.random());
        }
    };


    const getTableData = () => {
        const table = [];
        let i = 1;

        razorpayPlans.forEach((razorpayPlan) => {
            table.push(
                <tr>
                    <td className="text-left">{i}</td>
                    <td className="text-left">{razorpayPlan.razorpay_plan_id}</td>
                    <td className="text-left">{razorpayPlan.price}</td>
                    <td className="text-left">{razorpayPlan.is_primary}
                    <td className="text-left">
                            <Switch
                                name="is_default"
                                offColor="success"
                                offText={
                                    <i className="nc-icon nc-simple-remove" />
                                }
                                onColor="success"
                                disabled={razorpayPlan.is_primary}
                                onText={<i className="nc-icon nc-check-2" />}
                                value={razorpayPlan.is_primary}
                                onChange={(value, enabled) =>
                                    handleIsPrimarySwitch(razorpayPlan.id, enabled)
                                }
                            />
                        </td>
                    </td>
                    <td className="text-left">{razorpayPlan.created_by}</td>
                    <td className="text-left">{razorpayPlan.createdat}</td>
                    <td className="text-left">{razorpayPlan.is_primary}</td>
                    <td className="text-left">
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event) => {confirmationPopup(razorpayPlan.id)}}
                        >
                            Delete
                        </Button>
                    </td>
                </tr>
            );
            i++;
        });
        return table;
    };

    return (
        <div >
            {showPopup.alert}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle
                                    tag="h4"
                                    onClick={toggleForm}
                                    style={{ cursor: "pointer" }}
                                >
                                    Add Razorpay Plan 
                                    <b className="caret"></b>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {showForm ? (
                                    <>
                                        <Form
                                            className="form-horizontol"
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Label sm="3">Razorpay Plan Id <span class="text-danger"> * </span></Label>
                                                <Col sm="5">
                                                    <FormGroup className={namestate}>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            onChange={handleRzpPlanId}
                                                            value={currentPlan.razorpay_plan_id}
                                                        />
                                                        {namestate === "has-danger" ? (
                                                            <label className="error">
                                                                {nameerror}
                                                            </label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                
                                            </Row>
                                            <Row>
                                        <Label sm="3">Plan Amount (INR) <span class="text-danger"> * </span></Label>
                                        <Col sm="5">
                                            <FormGroup
                                                className={basePriceState}
                                            >
                                                <Input
                                                    name="price"
                                                    id="price"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={currentPlan.price}
                                                />
                                                {basePriceState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {basePriceError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row><Col>{showSaveButton ? (
                                                <Button
                                                    
                                                    className="btn-smb"
                                                    color="info"
                                                    type="button"
                                                    onClick={validateForm}
                                                >
                                                    Add
                                                </Button>
                                            ) : (
                                                ""
                                            )}</Col>
                                            </Row>
                                            {alert.show ? (
                                                <Alert color={alert.type}>
                                                    {alert.message}
                                                </Alert>
                                            ) : (
                                                ""
                                            )}

                                            {showLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                ""
                                            )}
                                        </Form>
                                        <CardTitle
                                    tag="h4"
                                >
                                </CardTitle>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th className="text-left">
                                                        #
                                                    </th>
                                                    <th className="text-left">
                                                        Razorpay plan id
                                                    </th>
                                                    <th className="text-left">
                                                        Amount (INR)
                                                    </th>
                                                    <th className="text-left">
                                                        Is primary
                                                    </th>
                                                    <th className="text-left">
                                                        Created by
                                                    </th>
                                                    <th className="text-left">
                                                        Created At
                                                    </th>
                                                    <th className="text-left">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!razorpayPlans ||
                                                !razorpayPlans.length ? (
                                                    !componentRendered ? (
                                                        <tr>
                                                            <td
                                                                colSpan="7"
                                                                className="text-center"
                                                            >
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                >
                                                                    <span className="sr-only">
                                                                        Loading...
                                                                    </span>
                                                                </Spinner>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td
                                                                colSpan="7"
                                                                className="text-center"
                                                            >
                                                                <CardTitle tag="h4">
                                                                    No Records
                                                                    Found
                                                                </CardTitle>
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : (
                                                    getTableData()
                                                )}
                                            </tbody>
                                        </Table>

                                    </>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddRazorpayPlan;
