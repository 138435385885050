import React, {useEffect, useState, useContext} from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import {useHistory} from "react-router-dom";
import UserContext from "../../../UserContext";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
const PlanGroup = (props) => {

    const [planGroups,setPlanGroups] = useState([]);
    const [componentRendered,setComponentRendered] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [showPopup,setShowPopup] = useState({alert:null});
    const context = useContext(UserContext);
    const history = new useHistory();
    const [reloadComponent,setReloadComponent] = useState(0);


    useEffect(() => {
        (async() => {
            console.log("getting plan list");
            const response = await saasService.getPlanGroups();
            console.log("got the plan list");
            console.log(response.data);
            if (response.status) {
                setPlanGroups(response.data);
            }
            setComponentRendered(true);
        })()
    },[changeStatus,reloadComponent])

   
    const deactivatePlanGroupPopup = (groupId) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => deactivatePlanGroup(groupId)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Deactivate it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to deactivate this plan group!
            </ReactBSAlert>
          ),
        });
      }
  
      const activatePlanGroupPopup = (groupId) => {
        setShowPopup({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => activatePlanGroup(groupId)}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, activate it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to activate this plan group!
            </ReactBSAlert>
          ),
        });
      }
  
      const deactivatePlanGroup = async (planGroupId) => {
     setChangeStatus(false)
            const response = await saasService.deactivatePlanGroup(planGroupId);
            if(!response.status && response.error.errorCode === 403) {
              setShowPopup({
                alert:null
              })
              context.setIsAuthorized(context);
            }
            else if(response.status){
              
        
        
            setShowPopup({
            alert: (
              <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deactivate!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                showConfirm
                btnSize=""
              >
                Plan Group was deactivated successfully.
              </ReactBSAlert>
            ),
          });
           
        }else{
          setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Please try again!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                Group could not be deactivate. Please try again.
              </ReactBSAlert>
            ),
          });
        }
          setReloadComponent(Math.random());
          setChangeStatus(true);
          setTimeout(hideAlert,3000);
      }
  
      const activatePlanGroup = async (planGroupId) => {
         setChangeStatus(false)
            const response = await saasService.activatePlanGroup(planGroupId);
            if(!response.status && response.error.errorCode === 403) {
              setShowPopup({
                alert:null
              });
              context.setIsAuthorized(context);
           }
            else if(response.status){
          setShowPopup({
            alert: (
              <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Activated!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                Plan Group was Activated successfully.
              </ReactBSAlert>
            ),
          });
        }else{
          setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Please try again!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                Plan Group could not be activate. Please try again.
              </ReactBSAlert>
            ),
          });
        }

        setReloadComponent(Math.random());
        setChangeStatus(true);
        setTimeout(hideAlert,3000);
      }
  
      const hideAlert = () => {
        setShowPopup({
          alert: null,
        });
      };  
  
  


      const redirectUrl = (e,plan_group_id) =>{
        e.preventDefault()
        history.push("/saas/template?plan_group_id="+plan_group_id)
      }

    const getPlanGroupsTable = () =>{

        let groupElements = [];
        if(!planGroups || !planGroups.length){
          if(!componentRendered){
              groupElements.push(
             <tr>
              <td colSpan="5" className="text-center">
                  <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
              </td>
              </tr>
            );
          }else{
              groupElements.push(
                <tr>
              <td colSpan="5" className="text-center">
                  <CardTitle tag="h4">No Records Found</CardTitle>
              </td>
              </tr>
          );
          }
        }else{
           let i = 1;
           planGroups.forEach(function(groupDetails) {
                let className = '';
                if(!groupDetails.status){
                  className = 'table-warning';
                }
                groupElements.push(
                  <tr className={className}>
                      <td className="text-left">
                         {i}
                     </td>
                     <td className="text-left">
                         {groupDetails.name}
                     </td>
                     <td className="text-left">
                         {groupDetails.status?'Active':'Inactive'}
                     </td>
                     <td className="text-left">
                        <a title="See Templates" onClick={(event)=>  redirectUrl(event,groupDetails.id) } href="javascript:void(0)" class="nav-link">
                           See templates
                        </a>
                     </td>
                     
                     <td className="text-left">
                        {groupDetails.status? <Button
                                // style={{float: 'right'}}
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={(event) =>deactivatePlanGroupPopup(groupDetails.id)}>
                                Deactivate
                            </Button>:<Button
                                // style={{float: 'right'}}
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={(event) => activatePlanGroupPopup(groupDetails.id)}>
                                Activate
                            </Button>}
                     </td>
                  </tr>
              )
              i++;
          })
        }
        return groupElements;
    }


    return ( 
    <div className="content">
        {showPopup.alert}
        <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-signup text-center">
                        <CardHeader>
                          <Row>
                            <Col>
                            <div style={{'float':'right'}}>
                            <Button
                                className="btn-round"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={(event) => history.push("/saas/create-plan-group")}>
                                Create Plan Group
                            </Button>
                            </div>
                            </Col>
                          </Row>
                            <CardTitle tag="h4">
                                Plan Group Dashboard
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th className="text-left">#</th>
                                        <th className="text-left">Name</th>
                                        <th className="text-left">Status</th>
                                        <th className="text-left">Templates</th>
                                        <th className="text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    !planGroups || !planGroups.length ? !componentRendered? 
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <CardTitle tag="h4">No Records Found</CardTitle>
                                        </td>
                                    </tr>
                                :getPlanGroupsTable()}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div> 
    )
}

export default PlanGroup;