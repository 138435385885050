
import projectsService from '../../services/api/projects-service';
import whatsappService from '../../services/api/whatsapp-service'
import React,{ useContext, useEffect, useState } from 'react';
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from '../../UserContext';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  FormGroup,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
const WhatsAppSendMessage  = (props) => {
    const context = useContext(UserContext);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [reloadComponent,setReloadComponent] = useState(0);
    const [publisher, setPublisher] = useState('');
    const [publisherId,setPublisherId] = useState('');
    const [publisherNameArray,setPublisherNames] = useState([]);
    const [projectNameArray,setProjectNames] = useState([]);
    const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:'',message:'',phone_number:'',type:"individual"});
    const [projectId,setProjectId] = useState('');
    const [messageState, setMessageState] = useState(false);
    const [messageError,setMessageError] = useState("");
    useEffect( () => {
        (async () =>{
                    let publishers = await projectsService.getAllPublishers();
                    if(publishers.status){
                        let getPublishers = [];
                        let publisherData = publishers.data;
                        if(publisherData.length){
                            publisherData.forEach(function(publisherDetails) {
                                let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email,
                                phone_number: publisherDetails.country_code && publisherDetails.phone_number ? publisherDetails.country_code+publisherDetails.phone_number :""
                            };
                                getPublishers.push(publisherValue)
                            })
                        }
                        setPublisherNames(getPublishers);
                    }
            })();
        }, [reloadComponent]);

    useEffect( () => {
        (async () =>{
            if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            }
        })();
    }, [publisher]);
const handleSubmit = function(e){  e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const handleChange = (event)=> {
    setFormDetails({
        ...formdetails,
        [event.target.id]:event.target.value
    })
    setMessageState("has-success");
    setMessageError("");
  }
  const successAlert = ()=> {
    toast.success('Successfully sent message', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  const failAlert = ()=> {
    toast.error('Message could not send', {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
      progress: undefined,
      });
  }
  const validate = ()=> {
    let isValid =true;
    if(formdetails.message.trim()=== "") {
      isValid= false;
      setMessageState("has-danger");
      setMessageError("Message is required");
    }
    return isValid;
  }
  const sendMessage = async (type="individual")=> {
    if(!validate()){
      return;
    }
    const response = await whatsappService.sendMessage({...formdetails,phone_number: publisherId.phone_number,type});
    if(!response.status && response.error.errorCode === 403) {
       context.setIsAuthorized(context);
    }
    else if(response.status && response.data) {
      successAlert();
    }else{
      failAlert();
    }
  }
return (
    <div className="content">
        {showPopup.alert}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Send WhatsApp Message
                </CardTitle>
              
              
                </CardHeader>
                <CardBody>
                  <Form id="whatsapp" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="5">
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Publisher .."
                        name = "publisher_id"
                        id="publisher_id"
                        options={publisherNameArray}
                        value={publisherId}
                        onChange={changePublisherId}
                    />
                  </Col>
                  <Col md="5">
                      {publisherId?
                    <>
                        {publisherId.phone_number?  
                        "Message will be sent to "+publisherId.phone_number:<span class="text-danger"> Please Add Phone Number for sending message </span>}
                    </>
                    
                    :""}
                  </Col>
                  {/* <Col md="3">
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Project .."
                        name = "project_id"
                        id="project_id"
                        options={projectNameArray}
                        value={projectId}
                        onChange={changeProjectId}
                    />
                  </Col> */}
                </Row>
                <br/>
                <Row>
                    
                  <Col md="5">
                    <FormGroup className={messageState}>
                          <Input 
                          type="textarea"
                          id="message"
                          name="message"
                          placeholder="Type message here"
                          onChange = {handleChange}

                          />  
                          {messageState === "has-danger" ? (
                            <label className="error">
                              {messageError}
                            </label>
                          ) : null}
                    </FormGroup>
                    

                  </Col>
                  <Col md="3">
                   <Button className="btn-smb"
                   disabled = {publisherId && publisherId.phone_number? false:true}
                   onClick = {(e)=>{sendMessage()}}
                   >
                       Send <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </Button> or
                    <Button className="btn-smb"
                  //  disabled = {publisherId && publisherId.phone_number? false:true}
                   onClick = {(e)=>{sendMessage("all")}}
                   >
                       Send to All<i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </Button> 
                  </Col>
                </Row>
                </Form>
                </CardBody>
                <CardFooter>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>



);
}

export default WhatsAppSendMessage;