import React, { useEffect, useState, useContext } from "react";
import Moment from "react-moment";
import contentService from "../../services/api/content-service";
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    CardSubtitle,
    CardImg,
    CardGroup,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';
import UserContext from "../../UserContext";
const WebstoryView = (props) => {
    const article_id = new URLSearchParams(props.location.search).get("articleId");
    const [showLoader, setShowLoader] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [webstoryData, setWebstoryData] = useState({})
    const context = useContext(UserContext);

    useEffect(() => {
        (async () => {
            let response = await contentService.getwebstoryData(article_id);
            // if(!response.status && response.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            // }
            console.log("response", response);
            setWebstoryData(response.data);
        })();
    }, [])

    return <div className="content">
        <Row>
            <Col className="ml-auto mr-auto" md="12">
                <Card className="card-signup text-center">
                    <CardHeader>
                        <Row>
                            <Col sm="12" align="center"><CardTitle tag="h4">Webstory</CardTitle></Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="4">Project : {webstoryData.project_name}</Col>
                            <Col md="4">Categories : {webstoryData.categories ? webstoryData.categories.map(category => (
                                <Badge color="primary" pill>{category.name}</Badge>
                            )) : ""}</Col>
                            <Col md="4">Title : {webstoryData.title}</Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md="4">Guid : {webstoryData.guid}</Col>
                            <Col md="4">Author : {webstoryData.author}</Col>
                            <Col md="4">Type : Webstory</Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md="4">Status : {webstoryData.status ?
                                <Badge color="success" pill>
                                    Active
                                </Badge>
                                :
                                <Badge color="danger" pill>
                                    inactive
                                </Badge>
                            }</Col>
                            <Col md="4">Slug : {webstoryData.slug}</Col>
                            <Col md="4">Link : {webstoryData.share_url}</Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md="4">Created At : <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                                {webstoryData.createdAt}
                            </Moment></Col>
                            <Col md="4">Updated At : <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                                {webstoryData.updatedAt}
                            </Moment></Col>
                            <Col md="4">Published On : {webstoryData.article_published_on ?
                                <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                                    {webstoryData.article_published_on}
                                </Moment> : ""
                            }</Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md="4">Modified On : {webstoryData.article_modified_on ?
                                <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                                    {webstoryData.article_modified_on}
                                </Moment> : ""
                            }</Col>
                        </Row>
                        <br />
                        <hr />
                        <Row>

                            <Col sm="12"><h5>Webstory Pages</h5></Col>



                            {webstoryData.webstoryPages && webstoryData.webstoryPages.length ?

                                <>
                                    <Row>
                                        <Col sm="4"><b>Component Name</b></Col>
                                        <Col sm="8"><b>Component value</b></Col>
                                    </Row>

                                    {webstoryData.webstoryPages.map((page, index) => (
                                        <>
                                            <h6>Story Page {index + 1}</h6>
                                            {page.page_meta ?
                                                page.page_meta.map(meta => (
                                                    <Row>
                                                        <Col sm="4">{meta.component_name} <br /></Col>
                                                        <Col sm="8">{meta.component_value} <br /></Col>
                                                    </Row>
                                                )) : ""
                                            }
                                            <hr />
                                        </>
                                    ))}
                                </>
                                : ""}



                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>

}

export default WebstoryView;