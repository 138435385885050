import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import transactionsService from "../../services/api/transaction-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from "../../services/api/saas-service";
import pluginService from "../../services/api/plugin-service";
import moment from "moment";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import ReactDatetime from "react-datetime";
const PluginUsers = (props)=> {

  const [pluginUsers,setPluginUsers]= useState(false);
  const [totalUsers,setTotalUsers]=useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_email:'',wordpress_domain:'',from_date:'',to_date:''});
  const [search, setSearchDetails] = useState({publisher_email:'',wordpress_domain:'',from_date:'',to_date:''});

  const records = 10;

  const [publisherEmail,setPublisherEmail] = useState('');
  const [wordpressDomain,setWordpressDomain]=useState('');
  const [toDate,setToDate] = useState("");
  const [fromDate,setFromDate] = useState("");






    useEffect( () => {
        (async () =>{
            let getTotalPluginUsersCount = await pluginService.getTotalPluginUsersCount(formdetails);
                if(getTotalPluginUsersCount.status){
                    setTotalUsers(getTotalPluginUsersCount.data.count);
                }
            })();
        }, [reloadComponent,search]);


    useEffect( () => {
        (async () =>{
            let pluginUsersData = await pluginService.getPluginUsers(formdetails,activePage,records);
                if(pluginUsersData.status){
                        setPluginUsers(pluginUsersData.data);
                    }
                
                    setRenderComponent(true);
                })();
            }, [reloadComponent,search,activePage]);
    

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let pluginDetailsElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!pluginUsers || !pluginUsers.length){
    if(!renderComponent){
        pluginDetailsElements.push(
       <tr>
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        pluginDetailsElements.push(
          <tr>
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
     pluginUsers.forEach(function(usersDetails) {
          let className = '';
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          pluginDetailsElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
                   {usersDetails.publisher_email?usersDetails.publisher_email:''} <br/>
               </td>
               <td className="text-left">
                   {usersDetails.wordpress_domain?usersDetails.wordpress_domain:''}
               </td>
               <td className="text-left">
                   {usersDetails.posts_count?usersDetails.posts_count:''} <br/>
               </td>
               <td className="text-left">
                   {usersDetails.category_count?usersDetails.category_count:''}
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{usersDetails.createdat}</Moment>
               </td>
               <td className="text-center">
                  
                    {usersDetails.deactivate_on?
                        <>
                          <Badge  color="danger" pill>
                            Deactive
                          </Badge>
                          <div>
                              Deactivate On:<td><Moment format="DD MMM YYYY hh:mm a">{usersDetails.deactivate_on}</Moment></td> 
                          </div>
                        </>
                       : <Badge  color="success" pill>
                            Active
                          </Badge>
                                
                      }
                  
               </td>
               
            </tr>
        )
        if(i==pluginUsers.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==pluginUsers.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchPluginUser();e.preventDefault() }

  const handleFromDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      from_date: event._d,
    });
    setFromDate(event._d);
  }

  const handleToDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      to_date: event._d,
    });
    setToDate(event._d);
  }

  const searchPluginUser = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setPublisherEmail('');
    setWordpressDomain('');
    setToDate("");
    setFromDate("");
    document.getElementById('publisher_email').value = '';
    document.getElementById('wordpress_domain').value='';

    setFormDetails({
      ...formdetails,
      publisher_email: '',
      wordpress_domain: '',
      from_date:'',
      to_date:''
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
    
  }

  const handleChange = (event) => {
    setFormDetails({
        ...formdetails,
        [event.target.id]: event.target.value.trim(),
    });
};


    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Plugin Users
                </CardTitle>
                
              <Form id="pluginsfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                   <Input type="text" name="publisher_email" id="publisher_email" onChange={handleChange} placeholder="Publisher Email"/>
                  </Col>
                  
                  <Col md="3">
                   <Input type="text" name="wordpress_domain" id="wordpress_domain" onChange={handleChange} placeholder="Wordpress Domain"/>
                  </Col>
                  <Col md="3">
                    <ReactDatetime
                        value={fromDate}
                        onChange={handleFromDateChange}
                        inputProps={{
                          className: "form-control",
                          placeholder: "From Date",
                          id:"fromDate",
                        }
                      }
                      />
                  </Col>
                  <Col md="3">
                    <ReactDatetime
                        value={toDate}
                        onChange={handleToDateChange}
                        inputProps={{
                          className: "form-control",
                          placeholder: "To Date",
                          id:"toDate",
                        }}
                      />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>
                </Form>
                <hr></hr>
                {totalUsers > 0 ? 
                  <Row>
                      <Col md="6">
                          <div style={{float:'right',margin:'5px'}}>
                            <b>{firstRecord} - {lastRecord} of {totalUsers}</b>
                          </div>
                      </Col>
                      <Col md="6">
                        <div style={{float:'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalUsers}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                  </Row>
                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Email</th>
                      <th className="text-left">Wordpress Domain</th>
                       
                      <th className="text-left">Posts count</th> 
                      <th className="text-left">Category count</th> 
                      <th className="text-left">Activated on</th>
                      <th className ="text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>{pluginDetailsElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    {/* <hr></hr> */}
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default PluginUsers;
