

    const addKeyToQueryString = (key,value)=>{
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      window.history.pushState({}, '', newurl);
    }

    const getValueFromQueryString = (key) =>{
      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.has(key)){
        let value = searchParams.get(key);
        return value;
      }
      return false;
    }
    const getFiltersFromParams = (key) =>{
      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.has(key)){
        let jsonString=searchParams.get(key);
        let formdetails = JSON.parse(jsonString);
        return formdetails;
      }
      return false;
    }
    module.exports ={
        addKeyToQueryString,
        getValueFromQueryString

    }

