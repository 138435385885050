import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import saasService from "../../services/api/saas-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const Features = (props)=> {
  let group_id = '';
  if(props.location.state && props.location.state.group_id){
      group_id = props.location.state.group_id;
  }
  const context = useContext(UserContext);
    const history = new useHistory();
  const [features, setFeatures] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [groupName,setGroupName] = useState('');
  const [status,setStatus]=useState('');
  const [type,setType]=useState('');
  const [formdetails, setFormDetails] = useState({group_name:group_id,status:"",type:"",name:''});
  const [search, setSearchDetails] = useState({group_name:group_id,status:"",type:"",name:''});
  const [groupNameArray,setGroups] = useState([]);

  useEffect( () => {
    (async () =>{
      
        let featuresData = await saasService.getFeatures(formdetails);
            if(featuresData.status){
                setFeatures(featuresData.data);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent,search]);

    useEffect( () => {
      (async () =>{
        
            let groupsData = await saasService.getFeatureGroups();
            if(groupsData.status){
                setGroups(groupsData.data);
                let allGroups = groupsData.data;
                let groupArray = [];
                let defaultGroup = '';
                if(allGroups.length){
                  allGroups.forEach(function(groupDetails) {
                    //groupArray.push(groupDetails.name)
                    groupArray.push({value:groupDetails.id,label:groupDetails.name});
                    if(group_id != '' && group_id==groupDetails.id){
                      defaultGroup = {value:groupDetails.id,label:groupDetails.name};
                    }
                  })
                }
                setGroups(groupArray)
                setGroupName(defaultGroup);
            }
            
          })();
      }, [reloadComponent]);

  const deactivateFeaturePopup = (featureId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deactivateFeature(featureId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Remove it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to remove this feature!
          </ReactBSAlert>
        ),
      });
    }

    const restoreFeaturePopup = (featureId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreFeature(featureId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this feature again!
          </ReactBSAlert>
        ),
      });
    }

    const deactivateFeature = async (featureId) => {
      let susFeature = await saasService.removeFeature(featureId);
      if(!susFeature.status && susFeature.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      }
      else if(susFeature.status && susFeature.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Feature was removed successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Feature could not be removed. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreFeature = async (featureId) => {
      let resFeature = await saasService.restoreFeature(featureId);
      if(!resFeature.status && resFeature.error.errorCode === 403){
        setShowPopup({
          alert:null
        });
        context.setIsAuthorized(context);
      } 
      else if(resFeature.status && resFeature.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Feature was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Feature could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  

    const redirectUrl = (e,featureId) =>{
      e.preventDefault()
      history.push("/saas/editfeature?featureid="+featureId)
    }
   

  let featureElements = [];
  if(!features || !features.length){
    if(!renderComponent){
        featureElements.push(
       <tr>
        <td colSpan="8" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        featureElements.push(
          <tr>
        <td colSpan="8" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
      features.forEach(function(featureDetails) {
          let className = '';
          if(!featureDetails.status){
            className = 'table-warning';
          }
          featureElements.push(
            <tr className={className}>
                <td className="text-left">
                   {i}
               </td>
               <td className="text-left">
                   {featureDetails.name} <br/>
                   ( {featureDetails.group_id.name} )
               </td>
               <td className="text-left">
                   {featureDetails.description?featureDetails.description:""} <br/>
               </td>
               <td className="text-left">
                   {featureDetails.code}
               </td>
               <td className="text-left">
                   {featureDetails.type}
               </td>
               <td className="text-left">
                   {featureDetails.status?'Active':'Inactive'}
               </td>
               <td className="text-left">
                   {featureDetails.created_by}
               </td>
               <td className="text-left">
               {featureDetails.status==1?
               <span>
               <a title="Edit" onClick={(event)=>  redirectUrl(event,featureDetails.id) } href="javascript:void(0)" class="nav-link">
               {/* <i class="nc-icon nc-simple-remove"></i> */}
               <FontAwesomeIcon icon={faPencilAlt} />
             </a>
               <a title="Remove" onClick={(event)=>  deactivateFeaturePopup(featureDetails.id) } href="javascript:void(0)" class="nav-link">
               {/* <i class="nc-icon nc-simple-remove"></i> */}
               <FontAwesomeIcon icon={faTrash} />
             </a>
             </span>
             :
             <a title="Restore" onClick={(event)=>  restoreFeaturePopup(featureDetails.id) } href="javascript:void(0)" class="nav-link">
                 {/* <i class="nc-icon nc-refresh-69"></i> */}
                 <FontAwesomeIcon icon={faTrashRestoreAlt} />
               </a> 
               }
               </td>
            </tr>
        )
        i++;
    })
  }

  const handleSubmit = function(e){  searchFeatures();e.preventDefault() }

  const changeGroupName = (event) => {
    setFormDetails({
      ...formdetails,
      group_name: event.value,
    });
    setGroupName(event)
  }
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id] : event.target.value
    })
  }
  const changeType = (event) => {
    setFormDetails({
      ...formdetails,
      type: event.value,
    });
    setType(event)
  }
  const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    setStatus(event)
  }

  const searchFeatures = () => {
        setSearchDetails(formdetails);
  }

  const resetForm = () => {
    setGroupName('');
    setStatus('');
    setType('');
    setFormDetails({
      ...formdetails,
      group_name: '',
      status:'',
      type:'',
      name: ''
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <Row>
                    <Col>
                    <div style={{'float':'right'}}>
                    <Button
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/saas/createfeature") }
                  >Create Feature</Button>
                  </div>
                    </Col>
                  </Row>
                <CardTitle tag="h4">
                   Features Dashboard
                </CardTitle>
              <Form id="featurefilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Group .."
                    name = "group_name"
                    id="group_name"
                    options={groupNameArray}
                    value={groupName}
                    onChange={changeGroupName}
                  />
                  </Col>
                  <Col md="3">
                   <Input style={{height:'40px'}} type="text" name="name" id="name" value={formdetails.name} onChange ={handleChange} placeholder="Enter Feature Name"/>
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Type .."
                    name = "type"
                    id="type"
                    options={[{value:"quota",label:"Quota"},{value:"label",label:"Label"}]}
                    value={type}
                    onChange={changeType}
                  />
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    options={[{value:"1",label:"Active"},{value:"0",label:"Inactive"}]}
                    value={status}
                    onChange={changeStatus}
                  />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                </Row>
                </Form>
                <hr></hr> 
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Name</th>
                      <th className="text-left">Description</th>
                      <th className="text-left">Code</th> 
                      <th className="text-left">Type</th> 
                      <th className="text-left">Status</th>  
                      <th className="text-left">Created By</th>  
                      <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>{featureElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Features;
