import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {Link} from 'react-router-dom';
import serviceStackService from '../../services/api/service-stack-service';
import UserContext from "../../UserContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const ServiceStackDashboard = (props) => {  

    const statusOptions = [
        { value: "", label: "All" },
        { value: "inactive", label: "Inactive" },
        { value: "available", label: "Available" },
        { value: "locked", label: "Locked" },
    ];
    const context = useContext(UserContext);
    const [filter, setFilter] = useState({
        name: "",
        slug: "",
        classification: null,
        status: null
    });
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const records = 10;
    const [activePage, setActivePage] = useState(1);
    const [searchDetails, setSearchDetails] = useState({})
    const [serviceStackRecords, setServiceStackRecords] = useState([])
    const [reloadComponent,setReloadComponent] = useState(0);
    const [fieldStates, setFieldStates] = useState({});
 
    useEffect(()=>{
        (async()=>{
            const response = await serviceStackService.getServiceStackList(filter,activePage, records);

            console.log(response)

            if(response.status && response.data){
                setServiceStackRecords(response.data.serviceStacks); 
                console.log(response.data.serviceStacks)
                let showBtnStateArray = {};
                for(let i=0; i<response.data.serviceStacks.length; i++){
                    let data = response.data.serviceStacks[i];
                    showBtnStateArray[`show_updatebutton_${data.slug}`] = true;
                    showBtnStateArray[`show_loader_${data.slug}`] = false;
                }
                setFieldStates({
                    ...fieldStates,
                    ...showBtnStateArray,
                })
                setFirstRecord((activePage-1)*records + 1);
                let last = activePage*records;
                if(last>response.data.totalCount){
                    last = response.data.totalCount;
                }
                setLastRecord(last);
                setTotalRecords(response.data.totalCount)
            }
        })()
    },[activePage,searchDetails,reloadComponent]);

    const classificationOptions = [
        { value: 0, label: "Basic" },
        { value: 1, label: "Professional" },
        { value: 2, label: "Business" },
        { value: 3, label: "Enterprise" },
    ];

    const classificationDisplayName = ["Basic", "Professional", "Business", "Enterprise"];

    const handleOptionChange = (key, value) =>{
        setFilter({
            ...filter,
            [key]: value
        })
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSearchDetails({
            ...filter
        })
    }
    
    const handleChange = (event) =>{
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        })
    }

    const handlePageChange = (pageNumber) =>{
        setActivePage(pageNumber);
    }


    const updateRouteCacheRedis = async(data) => {
        setFieldStates({
            ...fieldStates,
            [`show_loader_${data.slug}`] : true,
            [`show_updatebutton_${data.slug}`] : false,
        })
        const response = await serviceStackService.updateRouteCacheRedis({data});
        setTimeout(()=>{
            setReloadComponent(Math.random());
            setFieldStates({
                ...fieldStates,
                [`show_loader_${data.slug}`] : false,
                [`show_updatebutton_${data.slug}`] : true,
            })
        },1000);
    }

    const resetElements = () =>{
        setFilter({
            name: "",
            slug: ""
        })
    }

    return (
        <>
            <div className="content">
            <Container>
                <Link
                    to={{
                        pathname: "/service-stack/create",
                    }}
                    className="btn btn-primary"
                >Create New Service Stack</Link>
                
                <Row>
                    <Col className="ml-auto mr-auto"  md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Service Stacks
                                </CardTitle>
                                <Form  action="" className="form" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Status"
                                                name = "status"
                                                id="status"
                                                options={statusOptions}
                                                onChange={(event)=>{handleOptionChange("status", event.value)}}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Classification"
                                                name = "classification"
                                                id="classification"
                                                options={classificationOptions}
                                                onChange={(event)=>{handleOptionChange("classification", event.value)}}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Input
                                                name="name"
                                                onChange={handleChange}
                                                value={filter.name}
                                                placeholder="Service Stack Name"
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Input
                                                name="slug"
                                                onChange={handleChange}
                                                value={filter.slug}
                                                placeholder="Service Stack Slug"
                                            />
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md="4">
                                            <div style={{'float':'left'}}>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="submit"
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="warning"
                                                type="button"
                                                onClick={resetElements}
                                            >
                                                Reset
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <hr/>
                                {totalRecords > 0 ? 
                                    <Row>
                                        <Col md="6">
                                            <div style={{float:'right',margin:'5px'}}>
                                            <b>{firstRecord} - {lastRecord} of {totalRecords}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{float:'left'}}>
                                                <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={records}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange.bind(this)}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row> 
                                : null}
                            </CardHeader>

                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th> 
                                            <th className="text-left">Stack Name</th>   
                                            <th className="text-left">Stack Slug</th>
                                            <th className="text-left">Capacity</th>
                                            <th className="text-left">Classification</th>
                                            <th className="text-left">Status</th>
                                            <th className="text-left">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceStackRecords.map((element, index)=>(
                                            <tr>
                                                    <td>{index+1}</td>
                                                    <td>{element.name}</td>
                                                    <td>{element.slug}</td>
                                                    <td>{element.capacity}</td>
                                                    <td>{classificationDisplayName[element.classification]}</td>
                                                    <td>{element.status}</td>
                                                    <td>
                                                        <Row>
                                                            <Col md="6">
                                                                {
                                                                    fieldStates[`show_updatebutton_${element.slug}`] && !element.route_cache_redis ?
                                                                        <Button 
                                                                            className="btnBlue btn-smb"
                                                                            type="button"
                                                                            style={{marginBottom:"10px"}}
                                                                            onClick={(event) => updateRouteCacheRedis(element)}
                                                                        >
                                                                            Route Cache
                                                                        </Button>
                                                                    : null
                                                                }
                                                                {
                                                                    fieldStates[`show_loader_${element.slug}`] ?
                                                                        <Spinner animation="border" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </Spinner>
                                                                    : null
                                                                }   
                                                            </Col>
                                                            <Col md="6">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/service-stack/details/${element._id}`,
                                                                    }}

                                                                    className="btn btn-primary"
                                                                >Details</Link>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>  
                                </Table>
                            </CardBody>
                        </Card>
                        </Col>
                </Row>
            </Container>
          </div>
        </>
  );
};

export default ServiceStackDashboard;
