
import React, {useEffect, useState, useContext} from "react";
import saasService from "../../../services/api/saas-service";
import {useHistory} from "react-router-dom";


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

const PlanFeatureDetails = (props) => {

    const [planFeatures, setPlanFeatures] = useState([]);

    const [componentRendered, setComponentRendered] = useState(false);

    useEffect(() => {
        console.log("PlanFeatures" , props.planFeatures)

        let tempFeatures = [];

        if(props.planFeatures && props.planFeatures.length){
            props.planFeatures.forEach( feature=> {
                tempFeatures.push({
                    name: feature.feature_id.name,
                    quota: feature.feature_quota || "NA"
                })                
            });
            setPlanFeatures(tempFeatures);
        }
    },[])

    const getFeaturesTable = ()=>{
        let i=1;
        // setComponentRendered(true)

        const table = [];
        planFeatures.forEach(feature=>{
           table.push( 
            <tr>
                <td className="text-left">
                    {i++}
                </td>
                <td className="text-left">
                    {feature.name}
                </td>
                <td className="text-left">
                    {feature.quota}
                </td>
            </tr>)
        })
        return table;
    }

    return ( 
    <div className="content">
        <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-signup text-center">
                        <CardHeader>
                            <CardTitle tag="h4">
                                Plan Features
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th className="text-left">#</th>
                                        <th className="text-left">Feature Name</th>
                                        <th className="text-left">Quota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    !planFeatures || !planFeatures.length ? !componentRendered? 
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <CardTitle tag="h4">No Records Found</CardTitle>
                                        </td>
                                    </tr>
                                :getFeaturesTable()}
                                {/* {getFeaturesTable()} */}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div> 
    )
}

export default PlanFeatureDetails;