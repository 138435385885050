import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import appService from '../../services/api/app-service';
import generalHelper from '../../variables/general-helper.js';
import formValidations from '../../variables/form-validations';
import {
  Link
} from 'react-router-dom'
import Select from 'react-select'
import './Publishers.css'
import { Country, State, City } from 'country-state-city';
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const Publishers = (props) => {
  const history = new useHistory();
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({ publishername: '', publisheremail: '' });
  const [publishers, setPublishers] = useState(false);
  const [totalPublishers, setTotalPublishers] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search, setSearchDetails] = useState({ publishername: '', publisheremail: '' });
  const records = 10;
  const [consoleUrl, setConsoleUrl] = useState("");
  const [editFormdetails, setEditFormdetails] = useState({ publishers_id: '', phone_number: '', country_code: '91' })
  const [countriesCode, setCountriesCode] = useState([]);
  const [reloadComponent, setReloadComponent] = useState(false);
  const pathname = window.location.href;
  useEffect(() => {
    let filtersJSONString = generalHelper.getValueFromQueryString("filters");
    // console.log("filtersJSONString: ", filtersJSONString);
    if (filtersJSONString) {
      try {
        let filters = JSON.parse(filtersJSONString);
        setFormDetails({ ...formdetails, ...filters });
      } catch (error) {
        setFormDetails({ publishername: '', publisheremail: '' });
      }
    } else {
      setFormDetails({ publishername: '', publisheremail: '' });
    }
    let pageNumber = new URLSearchParams(window.location.search).get("page");
    console.log("pageNumber: ", pageNumber);
    if (pageNumber) {
      setActivePage(pageNumber);
    }
    else {
      // console.log("publishers: ",publishers);
      pageNumber = 1;
      generalHelper.addKeyToQueryString("page", 1);
    }
    setActivePage(pageNumber);
    setSearchDetails({});
  }, [pathname]);

  useEffect(() => {
    (async () => {
      let publishersData = await projectService.getPublishers(formdetails, activePage, records);
      if (!publishersData.status && publishersData.error.errorCode === 403) {
        let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
        window.history.replaceState({}, '', newurl)
      }
      if (publishersData.status && publishersData.data) {
        setPublishers(publishersData.data);
        console.log("in second")
        for (const publisherData of publishersData.data) {
          editFormdetails[publisherData.id + "_loader"] = false;
          editFormdetails[publisherData.id + "_button"] = false;
          editFormdetails[publisherData.id + "_alert"] = false;
          editFormdetails[publisherData.id + "_edit"] = true;
          editFormdetails[publisherData.id + "_country_code"] = publisherData.country_code || "91";
          editFormdetails[publisherData.id + "_phone_number"] = publisherData.phone_number;
          editFormdetails[publisherData.id + "_error"] = 'has-success';
          editFormdetails[publisherData.id + "_error_message"] = '';
        }
        setEditFormdetails({ ...editFormdetails, editFormdetails });
      }
      let getTotalPublishers = await projectService.getTotalPublishers(formdetails);
      if (getTotalPublishers.status) {
        setTotalPublishers(getTotalPublishers.data.count);
      }
      setRenderComponent(true);
    })();
  }, [activePage, search, reloadComponent]);


  useEffect(() => {
    (async () => {
      const response = await appService.getConsoleUrl();
      setConsoleUrl(response.url);
    })()
  })

  useEffect(() => {
    (async () => {
      let countriesList = Country.getAllCountries();
      let countryArray = [];
      countriesList.forEach((country) => {
        if (country.phonecode) {
          countryArray.push({
            value: country.phonecode,
            label: "(" + country.phonecode + ")   " + country.name
          });
        }
      });
      setCountriesCode(countryArray);
    })();
  }, []);

  const handlePageChange = (pageNumber) => {
    generalHelper.addKeyToQueryString("page", pageNumber);
    setActivePage(pageNumber);
  }


  const handlePublisherNameChange = (event) => {
    setFormDetails({
      ...formdetails,
      publishername: event.target.value,
    });
  }

  const handlePublisherEmailChange = (event) => {
    setFormDetails({
      ...formdetails,
      publisheremail: event.target.value,
    });
  }
  const handlePhoneNumberChange = (value, id) => {
    editFormdetails[id + "_phone_number"] = value;
    editFormdetails[id + "_error"] = "has-success";
    editFormdetails[id + "_error_message"] = "";
    setEditFormdetails({ ...editFormdetails, editFormdetails });
  }

  const handleCountryCodeChange = (event, id) => {
    editFormdetails[id + "_country_code"] = event.value;
    setEditFormdetails({ ...editFormdetails, editFormdetails });
  };

  const onEdit = (id) => {
    editFormdetails[id + "_edit"] = false;
    editFormdetails[id + "_button"] = true;
    setEditFormdetails({ ...editFormdetails, editFormdetails });
  }
  const validatePhoneNumber = (id) => {
    if (editFormdetails[id + "_phone_number"] === undefined || editFormdetails[id + "_phone_number"] === "") {
      editFormdetails[id + "_error"] = 'has-danger';
      editFormdetails[id + "_error_message"] = 'Enter phone number';
      setEditFormdetails({ ...editFormdetails, editFormdetails });
      return false;
    } else {
      // if(formValidations.verifyPhoneNumber(editFormdetails[id+"_phone_number"].trim()) ===false ) {
      //     editFormdetails[id+'_error'] = 'has-danger';
      //     editFormdetails[id+'_error_message'] = 'Invalid phone number.';
      //     setEditFormdetails({...editFormdetails,editFormdetails});
      //     return false;
      // }else{
      let phoneNumber = editFormdetails[id + "_phone_number"].trim();
      if (phoneNumber.length < 6 || phoneNumber.length > 15) {
        editFormdetails[id + '_error'] = 'has-danger';
        editFormdetails[id + '_error_message'] = 'Phone number length should be in between 6 to 15';
        setEditFormdetails({ ...editFormdetails, editFormdetails });
        return false;
      } else {
        if (formValidations.verifyPhone(editFormdetails[id + "_phone_number"].trim()) === false) {
          editFormdetails[id + '_error'] = 'has-danger';
          editFormdetails[id + '_error_message'] = 'Invalid phone number.';
          setEditFormdetails({ ...editFormdetails, editFormdetails });
          return false;
        } else {
          editFormdetails[id + '_error'] = 'has-success';
          editFormdetails[id + '_error_message'] = '';
          setEditFormdetails({ ...editFormdetails, editFormdetails });
          return true;
        }
      }
      // }

    }
    return true;
  }
  const onSave = async (id) => {
    if (!validatePhoneNumber(id)) {
      return false;
    }
    editFormdetails[id + "_edit"] = false;
    editFormdetails[id + "_button"] = false;
    editFormdetails[id + "_loader"] = true;
    let formdata = {
      publishers_id: id,
      phone_number: editFormdetails[id + "_phone_number"],
      country_code: editFormdetails[id + "_country_code"]
    }
    setEditFormdetails({ ...editFormdetails, editFormdetails });
    const response = await projectService.savePublishersPhoneDetails(formdata);
    if (!response.status && response.error.errorCode === 403) {
      context.setIsAuthorized(context)
    }
    if (response.status && response.data) {
      editFormdetails[id + "_alert"] = false;
      editFormdetails[id + "_button"] = false;
      editFormdetails[id + "_edit"] = false;
      editFormdetails[id + "_loader"] = false;
      setTimeout(() => {
        editFormdetails[id + "_loader"] = false;
        editFormdetails[id + "_alert"] = {
          show: true,
          type: "success",
          message: "Phone Number saved Successfully",
        };
        editFormdetails[id + "_edit"] = false;
        setEditFormdetails({ ...editFormdetails, editFormdetails });

      }, 1000);
      setTimeout(() => {

        editFormdetails[id + "_alert"] = {
          show: false,
          type: "",
          message: "",
        };
        editFormdetails[id + "_edit"] = true;
        setEditFormdetails({ ...editFormdetails, editFormdetails });
        setReloadComponent(Math.random());
      }, 5000);
    } else {
      editFormdetails[id + "_alert"] = false;
      editFormdetails[id + "_button"] = false;
      editFormdetails[id + "_edit"] = false;
      editFormdetails[id + "_loader"] = false;
      setTimeout(() => {
        editFormdetails[id + "_loader"] = false;
        editFormdetails[id + "_alert"] = {
          show: true,
          type: "danger",
          message: "Phone Number could not save",
        };
        editFormdetails[id + "_edit"] = false;
        setEditFormdetails({ ...editFormdetails, editFormdetails });

      }, 1000);
      setTimeout(() => {

        editFormdetails[id + "_alert"] = {
          show: false,
          type: "",
          message: "",
        };
        editFormdetails[id + "_edit"] = true;
        setEditFormdetails({ ...editFormdetails, editFormdetails });
        setReloadComponent(Math.random());
      }, 5000);
    }
  }
  const onCancel = (id) => {
    editFormdetails[id + "_edit"] = true;
    editFormdetails[id + "_button"] = false;
    setEditFormdetails({ ...editFormdetails, editFormdetails });
    setReloadComponent(Math.random());
  }

  const handleSubmit = function (e) { searchPublishers(); e.preventDefault() }

  const searchPublishers = () => {
    generalHelper.addKeyToQueryString("filters", JSON.stringify(formdetails));
    setActivePage(1);
    setSearchDetails(formdetails);
  }

  const resetElements = () => {
    document.getElementById('publishername').value = '';
    document.getElementById('publisheremail').value = '';
    setFormDetails({ publishername: '', publisheremail: '' });
    let defaultFormDetails = { publishername: '', publisheremail: '' };
    let jsonString = JSON.stringify({
      ...formdetails,
      ...defaultFormDetails
    });
    generalHelper.addKeyToQueryString("filters", jsonString);
    generalHelper.addKeyToQueryString("page", 1);
    setSearchDetails({ publishername: '', publisheremail: '' });
  }

  let publisherElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!publishers || !publishers.length) {
    if (!renderComponent) {
      publisherElements.push(
        <td colSpan="6" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      publisherElements.push(
        <td colSpan="6" className="text-center">
          <CardTitle tag="h4">No Publisher found.</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    publishers.forEach(function (publisherDetails) {
      let className = '';
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      let encodedAuthToken = encodeURIComponent(publisherDetails.auth_token)
      //let publisherLoginUrl = "https://console.sortd.dev/api/auth/direct-login?email="+publisherDetails.email+"&auth_token="+publisherDetails.auth_token;
      let publisherLoginUrl = consoleUrl + "api/auth/direct-login?email=" + publisherDetails.email + "&auth_token=" + encodedAuthToken;
      publisherElements.push(
        <tr className={className}>
          <td className="text-left">{(activePage - 1) * records + i}</td>
          <td className="text-left">

            <a onClick={(event) => window.location.href = "/report/publisher?publisher_email=" + publisherDetails.email} href="javascript:void(0)" class="btn-rotate nav-link">
              {publisherDetails.name}
            </a>



          </td>
          <td className="text-left">
            {publisherDetails.email}
          </td>
          <td>
            <div className="mainDiv">
              <div  >
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="country_code"
                  isDisabled={editFormdetails[publisherDetails.id + "_edit"] ? true : false}
                  value={countriesCode ? countriesCode.filter(option => option.value == editFormdetails[publisherDetails.id + "_country_code"]) : ""}
                  onChange={(event) => handleCountryCodeChange(event, publisherDetails.id)}
                  options={countriesCode}
                />
              </div>
              <div >
                <Input
                  type="tel"
                  id="phone"
                  name="phone"
                  disabled={editFormdetails[publisherDetails.id + "_edit"] ? true : false}
                  value={editFormdetails[publisherDetails.id + "_phone_number"]}
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  onChange={(event) => handlePhoneNumberChange(event.target.value, publisherDetails.id)}
                />
              </div>
              <div className="compDiv">
                {/* <span className="compDiv"> */}
                {editFormdetails[publisherDetails.id + "_edit"] ?
                  <Button
                    className="btn-smb"
                    type="button"
                    onClick={() => { onEdit(publisherDetails.id) }}
                  >
                    Edit
                  </Button>
                  : ""}
                {/* </span>
                  <span > */}
                {editFormdetails[publisherDetails.id + "_loader"] ?
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  : ""}
                {editFormdetails[publisherDetails.id + "_button"] ?
                  <>
                    <Button
                      className="btn-smb"
                      type="button"
                      onClick={() => { onSave(publisherDetails.id) }}
                    >
                      &nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                    <Button
                      className="btn-smb cancelBtn"
                      type="button"
                      onClick={() => { onCancel(publisherDetails.id) }}
                    >
                      cancel
                    </Button>
                  </>
                  : ""}

                {editFormdetails[publisherDetails.id + "_alert"] ?
                  <Alert color={editFormdetails[publisherDetails.id + "_alert"].type}>
                    {editFormdetails[publisherDetails.id + "_alert"].message}
                  </Alert> : ""
                }

                {editFormdetails[publisherDetails.id + "_error"] ===
                  "has-danger" ? (
                  <label className="error errLabel">
                    {
                      editFormdetails[publisherDetails.id +
                      "_error_message"
                      ]
                    }
                  </label>
                ) : null}
                {/* </span> */}
              </div>
            </div>

          </td>
          <td className="text-left">
            <a title="Login To Publisher" href={publisherLoginUrl} target="_blank" class="btn-rotate nav-link">
              Login to Publisher
            </a>
          </td>
        </tr>
      )
      if (i == publishers.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == publishers.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    })
  }


  return (
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">
                  Publishers
                </CardTitle>
                <Form id="pubfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>

                  <Row>
                    <Row>
                    <Col md="6">
                      <Input
                        className="react-select primary"
                        classNamePrefix="react-select"
                        type="text"
                        value={formdetails.publishername}
                        name="publishername"
                        id="publishername"
                        onChange={handlePublisherNameChange}
                        placeholder="Publisher Name .." />
                    </Col>
                    <Col md="6">
                      <Input
                        className="react-select primary"
                        classNamePrefix="react-select"
                        type="text"
                        value={formdetails.publisheremail}
                        name="publisheremail" id="publisheremail"
                        onChange={handlePublisherEmailChange}
                        placeholder="Publisher Email .." />
                    </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                      <div style={{'float':'left'}}>
                      <Button
                        className="btn-round"
                        //style={{ 'margin-top': '-5px' }}
                        color="info"
                        type="submit"
                      //onClick={searchPublishers}
                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        //style={{ 'margin-top': '-5px' }}
                        color="warning"
                        type="button"
                        onClick={resetElements}
                      >
                        Reset
                      </Button>
                      </div>
                    </Col>
                    </Row>
                  </Row>
                </Form>
                <hr></hr>
                {totalPublishers > 0 ?
                  <Row>
                    <Col md="6">
                      <div style={{ float: 'right' }}>
                        <b>{firstRecord} - {lastRecord} of {totalPublishers}</b>
                      </div>
                    </Col>
                    <Col md="6" >
                      <div style={{ float: 'left' }}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalPublishers}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                        <a title="Generate Access Token for Zoho API" href="/auth/zoho" target="_blank" class="btn-rotate nav-link getBtn">
                          generate Zoho Token
                        </a>
                      </div>
                    </Col>
                  </Row>
                : null}


              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Publisher Email</th>
                      <th className="text-left">Contact Number</th>
                      <th className="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>{publisherElements}</tbody>

                </Table>
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );


}

export default Publishers;
