import React, { useEffect, useState,useContext } from "react";
import projectService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  Table,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const CdnDetails = (props)=> {

  const cdnId = props.cdnId;
  const context = useContext(UserContext);
  const [cdnDetails,setCdnDetails] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});

  useEffect( () => {
    (async () =>{
            let cdnDetails = await projectService.getCdnDetails(cdnId);
            if(cdnDetails.status && cdnDetails.data){
                setCdnDetails(cdnDetails.data);
            }
            setRenderComponent(true);
        })();
    }, []);

    let cacheBehaviours = [];
    if(cdnDetails){
        let total = cdnDetails.DistributionConfig.CacheBehaviors.Quantity;
        if(total>0){
          cdnDetails.DistributionConfig.CacheBehaviors.Items.forEach(function(behaviourDetails) {
            console.log(behaviourDetails);
            cacheBehaviours.push(
              <tr>
                <td>
                    {behaviourDetails.PathPattern}
                </td>
                <td>
                    {behaviourDetails.Compress?'TRUE':'FALSE'}
                </td>
                <td>
                  {behaviourDetails.DefaultTTL}
                </td>
                <td>
                  {behaviourDetails.MaxTTL}
                </td>
                <td>
                    {behaviourDetails.MinTTL}
                </td>
                <td>
                  {behaviourDetails.TargetOriginId}
                </td>
              </tr>
            );
          })
        }
    }
    

    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">CDN Details</CardTitle>
                  <Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={(event)=>  props.setShowCdnDetails(false) }
                  >
                    Back
                  </Button>
                </CardHeader>
                <CardBody>
                    {renderComponent?
                    <div>
                    <Row>
                        <Label sm="3"><b>Distribution ID : </b></Label>
                        <Label sm="3">{props.domainDetails.cdn_dist_id}</Label>
                    </Row>
                    <Row>
                        <Label sm="3"><b>Public Domain : </b></Label>
                        <Label sm="3">{props.domainDetails.public_host}</Label>
                    </Row>
                    <Row>
                        <Label sm="3"><b>Origin Domain : </b></Label>
                        <Label sm="3">{props.domainDetails.origin_host}</Label>
                    </Row>
                    <Row>
                        <Label sm="3"><b>ACM Certificate Reference : </b></Label>
                        <Label sm="6">{cdnDetails.DistributionConfig.ViewerCertificate.Certificate}</Label>
                    </Row>
                    <Row>
                        <Label sm="3"><b>Caller Reference : </b></Label>
                        <Label sm="3">{cdnDetails.DistributionConfig.CallerReference}</Label>
                    </Row>
                    <Row>
                        <Label sm="3"><b>Cache Behaviours : </b></Label>
                        <Col md="12">
                        <Table>
                          <thead className="text-primary">
                            <tr>
                              <th className="text-left">Path Pattern</th>
                              <th className="text-left">Compress</th>
                              <th className="text-left">Default TTL</th>
                              <th className="text-left">Max TTL</th>
                              <th className="text-left">Min TTL</th>
                              <th className="text-left">Target Origin ID</th>
                            </tr>
                            {cacheBehaviours}
                          </thead>
                          </Table>
                        </Col>
                    </Row>
                    </div>
                    :
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                    }
                  
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default CdnDetails;
