import React, {useState, useEffect, useContext} from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import whatsappService from "../../services/api/whatsapp-service";
import Moment from "react-moment"
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from 'react-select';

const WhatsappAlerts = (props) => {
    const history =  new useHistory();
    
    const [alerts,setAlerts] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [formdetails,setFormDetails] = useState({publisherName:"",projectName:"",status:1,type:""});
    const [search, setSearchDetails] = useState({publisherName:"",projectName:"",status:1,type:""});
    const [activePage,setActivePage] = useState(1);
    const [totalAlerts, setTotalAlerts] = useState(0);
    const records = 10;
    let statusArray = [
        {
            value: 1,
            label: "Sent",
        },
        {
            value:0,
            label:"Error Occured",
        },
        {
            value:-1,
            label:"Not Sent",
        },
    ];
    useEffect(()=>{
        (
            async()=>{
                let alertsData = await whatsappService.getAlertsList({filters:formdetails,activePage,records});
                if(alertsData.status){
                    setAlerts(alertsData.data.alerts);
                    setTotalAlerts(alertsData.data.count);
                }
                setRenderComponent(true);
            }
        )();
    },[reloadComponent,search,activePage]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleSubmit = function(e){
        searchAlerts();
        e.preventDefault();
    };

    const changePublisherName = (event) => {
        console.log(event.target.value);
        setFormDetails({
            ...formdetails,
            publisherName:event.target.value,
        });   
    };

    const changeProjectName = (event) => {
        setFormDetails({
            ...formdetails,
            projectName:event.target.value,
        });   
    };

    const searchAlerts = ()=>{
        setActivePage(1);
        setSearchDetails(formdetails);
    };

    const changeStatus = (event)=>{
        console.log(event.value)
        setFormDetails({
            ...formdetails,
            status: event.value,
        });
    };

    const changeType = (event) => {
        setFormDetails({
            ...formdetails,
            type: event.target.value,
        })
    }

    const resetForm = ()=>{
        setFormDetails({
            ...formdetails,
            projectName:"",
            publisherName:"",
            status:1,
            type:""
        });
        // setFormDetails(formdetails);
        setReloadComponent(Math.random());
    };
    console.log("alerts:",alerts)
    let alertElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if(!alerts || !alerts.length){
        if(!renderComponent){
            alertElements.push(
                <tr>
                    <td colSpan="8" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            )
        }
        else{
            alertElements.push(
                <tr>
                    <td colSpan="8" className="text-center">
                        <CardTitle tag="h4">No records Found</CardTitle>
                    </td>
                </tr>
            )
        }
    }
    else{
        let i = 1;
        alerts.forEach(function(alertDetails){
            let className ='';
            if(!alertDetails.status){
                className = "table-warning";
            }
            if(i===1){
                firstRecord = (activePage - 1) * records + i;
            }
            alertElements.push(
                <tr className={className}>
                    <td className="text-left">
                        {(records*(activePage-1))+i}
                    </td>
                    <td className="text-left">
                        {alertDetails.user_phone_number}
                    </td>
                    <td style={{width:"10%"}}>
                        {JSON.parse(alertDetails.params).map(data=>(
                            data+",\n"
                        ))}
                        {console.log("type of: ",alertDetails.params.split(","))}
                    </td>
                    <td className="text-left">
                        {alertDetails.type}
                    </td>
                    <td className="text-left">
                        <Moment format="DD-MM-YYYY hh:mm:ss a">
                            {alertDetails.sentOn}
                        </Moment>
                    </td>
                    <td className="text-left">
                        {alertDetails.publisher_name}
                    </td>
                    <td className="text-left">
                        {alertDetails.project_name}
                    </td>
                    <td className="text-left">
                        {alertDetails.status === -1 ?
                        <p>not sent</p> : alertDetails.status === 0 ? <p>error occured</p>: <p>sent</p>    
                    }
                    </td>
                </tr>
            )
            if(i== alerts.length){
                lastRecord = (activePage-1) * records + i;
            }
            i++;
            if(i == alerts.length){
                lastRecord = (activePage - 1)* records +i;
            }
        })
    }

    return(
        <>
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Whatsapp Alerts
                                </CardTitle>
                                <Form action="" method="POST" className="whatsappAlerts" onSubmit={handleSubmit}/>
                                <Row>
                                    <Col md="3">
                                        <Input type="text" name="publisher name" id="publisher name" value={formdetails.publisherName} onChange={changePublisherName} placeHolder="Enter Publisher Name.." style={{'heigth':'40px'}} />
                                    </Col>

                                    <Col md="3">
                                        <Input type="text" name="project name" id="project name" value={formdetails.projectName} onChange={changeProjectName} placeHolder="Enter Project Name.." style={{'heigth':'40px'}} />
                                    </Col>
                                    <Col md="3">
                                        <Input type="text" name="type" id="type" value={formdetails.type} onChange={changeType} placeHolder="Enter Type" style={{'heigth':'40px'}} />
                                    </Col>
                                    <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder ="Select Status.."
                                                name="status"
                                                style={{'heigth':'40px'}}
                                                id="status"
                                                options={statusArray}
                                                value={formdetails.status !== ""?statusArray.filter(option=>option.value == formdetails.status):""}
                                                onChange={changeStatus}
                                            />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="3">
                                        <div style={{'float':'left'}}>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="submit"
                                                onClick={searchAlerts}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="warning"
                                                type="button"
                                                onClick={resetForm}
                                            >
                                                Reset
                                            </Button>
                                            </div>
                                    </Col>
                                </Row>
                                <hr />
                                {totalAlerts > 0 ? 
                                <Row>
                                        <Col md="6">
                                            <div style={{float:'right',margin:'5px'}}>
                                                {console.log("total Alerts:",totalAlerts)}
                                            <b>{firstRecord} - {lastRecord} of {totalAlerts}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{float:'left'}}>
                                                <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={records}
                                                totalItemsCount={totalAlerts}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange.bind(this)}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                />
                                            </div>
                                        </Col>

                                </Row>
                                
                                : null}
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">Contact Num</th>
                                            <th className="text-left">Details Of alert</th>
                                            <th className="text-left">Type Of alert</th>
                                            <th className="text-left">Sent On</th>
                                            <th className="text-left">Publisher Name</th>
                                            <th className="text-left">Project Name</th>
                                            <th className="text-left">Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>{alertElements}</tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default WhatsappAlerts;