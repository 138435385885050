import axios from "axios";

const getFeatureGroups = async() =>{
    const response = await axios.get('/api/saas/get-feature-groups');
    return response.data;
}

const createFeatureGroup = async(formdata) =>{
    const response = await axios.post('/api/saas/create-feature-group',formdata);
    return response.data;
}

const removeFeatureGroup = async(groupId) =>{
    const formdata = {
        groupId : groupId
    }
    const response = await axios.post('/api/saas/remove-feature-group',formdata);
    return response.data;
}

const restoreFeatureGroup = async(groupId) =>{
    const formdata = {
        groupId : groupId
    }
    const response = await axios.post('/api/saas/restore-feature-group',formdata);
    return response.data;
}

const getFeatures = async(formdata) =>{
    const response = await axios.post('/api/saas/get-features',formdata);
    return response.data;
}

const createFeature = async(formdata) =>{
    const response = await axios.post('/api/saas/create-feature',formdata);
    return response.data;
}


const getPlanGroups = async(formdata)=>{
    const response = await axios.get('/api/saas/get-plans-groups',formdata);
    return response.data;
}

const getPlanGroupDetails = async(plan_group_id)=>{
    const response = await axios.post('/api/saas/get-plans-group-details',{
        plan_group_id
    });
    return response.data;
}


const createPlanGroup = async(formdata)=>{
    const response = await axios.post('/api/saas/create-plan-group',formdata);
    return response.data;
}

const activatePlanGroup = async(planGroupId)=>{
    const response = await axios.post('/api/saas/activate-plan-group',{
        planGroupId
    });
    return response.data;
}


const deactivatePlanGroup = async(planGroupId)=>{
    const response = await axios.post('/api/saas/deactivate-plan-group',{
        planGroupId
    });
    return response.data;
}

const getPlans = async(formdata)=>{
    const response = await axios.post('/api/saas/get-plans',formdata);
    return response.data;
}
const getEnterprisePlans = async(formdata)=>{
    const response = await axios.post('/api/saas/get-enterprise-plans',formdata);
    console.log("Response:",response.data);
    return response.data;
}

const createPlan = async(planData)=>{
    const response = await axios.post('/api/saas/create-plan',{
        planData
    });
    return response.data;
}
const editPlan = async(planData)=>{
    const response = await axios.post('/api/saas/edit-plan',{
        planData
    });
    return response.data;
}


const getPlanDetails = async(planId)=>{
    const response = await axios.post('/api/saas/get-plan-details',{
        planId
    }); 
    console.log(response)
    return response.data;
}

const createPlanPrice = async(priceData)=>{
    const response = await axios.post('/api/saas/create-plan-price', {
        priceData
    })
    return response.data;
}
const editPlanPrice = async(priceData)=>{
    const response = await axios.post('/api/saas/edit-plan-price', {
        priceData
    })
    return response.data;
}


const saveFeature = async(formdata) =>{
    const response = await axios.post('/api/saas/save-feature',formdata);
    return response.data;
}

const getFeatureDetail = async(featureId) =>{
    const formdata = {
        featureId : featureId
    }
    const response = await axios.post('/api/saas/get-feature-detail',formdata);
    return response.data;
}

const removeFeature = async(featureId) =>{
    const formdata = {
        featureId : featureId
    }
    const response = await axios.post('/api/saas/remove-feature',formdata);
    return response.data;
}

const restoreFeature = async(featureId) =>{
    const formdata = {
        featureId : featureId
    }
    const response = await axios.post('/api/saas/restore-feature',formdata);
    return response.data;
}

const addPlanFeature = async(planFeatureData) =>{
    const response = await axios.post('/api/saas/add-plan-feature',planFeatureData);
    return response.data;
}


const getPlanFeatures = async(planId) =>{
    
    const response = await axios.post('/api/saas/get-plan-features',{planId});
    return response.data;
}



const deletePlanFeature = async(featureId) =>{
    const response = await axios.post('/api/saas/remove-plan-features',{featureId});
    return response.data;
}

const publishPlan = async(planId) =>{
    const response = await axios.post('/api/saas/publish-plan',{
        planId
    })
    return response.data;
}

const  getFeatureCodes = async(planId) =>{
    const response = await axios.post('/api/saas/get-feature-codes',{
    })
    return response.data;
}

const changePlanPrimaryStatus = async(planId, status)=>{
    const response = await axios.post('/api/saas/change-primary-status', {
        planId,
        status
    })
    return response.data;
}

const addRazorpayPlan = async(razorpayPlanData) =>{
    const response = await axios.post('/api/saas/add-razorpay-plan',razorpayPlanData);
    return response.data;
}


const getRazorpayPlans = async(planId) =>{
    
    const response = await axios.post('/api/saas/get-razorpay-plans',{planId});
    return response.data;
}



const deleteRazorpayPlan = async(razorpayPlanId) =>{
    const response = await axios.post('/api/saas/remove-razorpay-plan',{razorpayPlanId});
    return response.data;
}
const changeRazorpayPlanPrimaryStatus = async(planId, status)=>{
    const response = await axios.post('/api/saas/change-razorpayplan-primary-status', {
        planId,
        status
    })
    return response.data;
}
export  default {
    getFeatureGroups,
    createFeatureGroup,
    getFeatures,
    createFeature,
    getPlanGroups,
    createPlanGroup,
    activatePlanGroup,
    deactivatePlanGroup,
    getPlans,
    getEnterprisePlans,
    createPlan,
    editPlan,
    getPlanDetails,
    createPlanPrice,
    editPlanPrice,
    restoreFeatureGroup,
    removeFeatureGroup,
    restoreFeature,
    removeFeature,
    saveFeature,
    getFeatureDetail,
    addPlanFeature,
    getPlanFeatures,
    deletePlanFeature,
    publishPlan,
    getFeatureCodes,
    changePlanPrimaryStatus,
    getPlanGroupDetails,
    addRazorpayPlan,
    getRazorpayPlans,
    deleteRazorpayPlan,
    changeRazorpayPlanPrimaryStatus
}