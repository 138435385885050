import axios  from "axios";

const getAllJwplayerProjectsData = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/projects/get-all-jwplayer-projects-data',formdata);
    return response.data;
}

const saveProjectJwplayer = async(formdata)=>{

    
    const response = await axios.post('/api/projects/save-project-jwplayer',formdata)

    return response.data;
}

const getJwplayerDetails = async(projectId)=>{
    try {
       
    const formdata = {
        projectId : projectId
    }

    const response = await axios.post('/api/projects/get-jwplayer-details',formdata)
    console.log("response is : : :",response)
    return response.data;
    } catch (error) {
        console.log("error inside function",error);
    }
    
}

const getJwplayerStatus = async(jwKey,siteId)=>{
    try {
        const response = await axios.post("/api/projects/jwplayer-key-status",{jwKey:jwKey,siteId:siteId});
        return response;
    } catch (error) {
        throw error;
    }
}

const getJwplayerKeys = async(jwKey,siteId)=>{
    try {
        const response = await axios.post("/api/projects/all-jwplayer-key-data",{jwKey:jwKey,siteId:siteId});
        return response;
    } catch (error) {
        throw error
    }
}

const getAllActiveJwplayerProjects = async(Ids)=>{
    try {
        const response = await axios.post('/api/projects/all-active-jwplayer-projects',{ids:Ids});
        return response;
    } catch (error) {
        throw error;
    }
}
const getTotalProjectsForJwplayer = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/projects/get-total-projects-for-jwplayer',formdata);
    return response.data;
}

export default{
    getAllJwplayerProjectsData,
    saveProjectJwplayer,
    getJwplayerDetails,
    getJwplayerStatus,
    getJwplayerKeys,
    getAllActiveJwplayerProjects,
    getTotalProjectsForJwplayer
}