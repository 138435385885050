/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {UserProvider} from "../UserContext";
import userService from '../services/api/user-service';
// import frontendPermissionLookUp from "../../../../core_libs/models/mongodb/frontend-permission-lookup.js";
import routes from "../routes.js";
import RBAPopup from "./RBAPopup.js";
var ps;

class Admin extends React.Component {
  state ={
    alert:null,
    userPermissionList:[],
    frontendPermissionLookUp:{}
  }
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      user : {name:'',email:'',picture:'',isAuthorized:true},
      renderComponent : false
    };
  }

  goToHome = () => {
    const {history} = this.props;
    history.push("/dashboard/home");
    this.setState({alert:null});
  }
  setIsAuthorized = ()=>{
    const {history} = this.props;
    let tempAuthorize = this.state.user;
    let path_array = ["/dashboard/publishers","/project/projects","/contentsettings/categories","/contentsettings/articles","/contentsettings/wpwebstories","/contentsettings/media"]
    // console.log("before toggle tempauthorize: ",tempAuthorize.isAuthorized)
    this.setState({...this.state,user:{...this.state.user,isAuthorized : !tempAuthorize.isAuthorized}})
    if(!tempAuthorize.isAuthorized){
      this.setState({
        alert:null
      })
      if(history.length === 2){
        console.log("history: ",history.length);
        history.push("/dashboard/home");
      }
      else{
        console.log("in else")
        history.go(-1);
      }

    }
    if(tempAuthorize.isAuthorized){
      this.setState({
        alert:<ReactBSAlert
          warning
          style={{display:"block",marginTop:"-100px"}}
          title="You are not authorized!"
          onConfirm={this.state.user.setIsAuthorized}
          onCancel={this.goToHome}
          confirmBtnBsStyle="info"
          confirmBtnText="Go Back"
          cancelBtnBsStyle="danger"
          cancelBtnText="Go To Home"
          showCancel
          btnSize=""
        >
          You are not authorized to perform this action!!
        </ReactBSAlert>
      })
    }
    // console.log("in admin after toggle: ",!tempAuthorize.isAuthorized)
    // console.log("2nd state",{...this.state.user,isAuthorized : !tempAuthorize.isAuthorized})
  }
  async componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      //ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    let currentUser = await userService.getLoggedInUser();
    
    if(currentUser.status && currentUser.data){
      if(Object.keys(currentUser.data).length){
        this.setState({ user: {name:currentUser.data._json.name, email:currentUser.data._json.email, picture:currentUser.data._json.picture,isAuthorized:true,setIsAuthorized:this.setIsAuthorized} });
        this.setState({renderComponent:true});
      }              
    }

    // let userPermissionList = await userService.getUserPermissionList(currentUser.data._json.email);
    // this.setState({userPermissionList:userPermissionList.data});

    // let frontendPermissionLookUp = await userService.getUserFrontendPermissionLookup();
    // this.setState({frontendPermissionLookUp:frontendPermissionLookUp.data})

    

  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {

    //   if(ps) ps.destroy();
       document.documentElement.className += " perfect-scrollbar-off";
       document.documentElement.classList.remove("perfect-scrollbar-on");
     }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.getPermission();
      //this.refs.mainPanel.scrollTop = 0;
      // <FlashAlert user={this.state} setState={this.setIsAuthorized}/>
      // console.log(window.location.pathname)
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout != "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getPermission = (url) => {
      // let url = window.location.pathname;
      
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    // if(this.state && !this.state.user.isAuthorized){
    //   console.log("state: ",this.state.user)
    //   return (
        // <ReactBSAlert
        //   warning
        //   style={{display:"block",marginTop:"-100px"}}
        //   title="You are not authorized!"
        //   onConfirm={this.setIsAuthorized}
        //   confirmBtnBsStyle="info"
        //   confirmBtnText="Ok"
        //   btnSize=""
        // >
        //   You are not authorized to perform this action!!
        // </ReactBSAlert>
    //   )
    // }
     if(!this.state.renderComponent){
      return (
        <>
        </>
      )
    }else{
      return (
        <div className="wrapper">
          {this.state.alert}
          {/* {console.log("in admin: ",window.location.pathname)} */}
          <RBAPopup user={this.state} setState={this.setIsAuthorized} onChange={window.location.pathname}/>
          {/* {this.getPermission(window.location.pathname)} */}
          {/* {console.log("in div",window.location.pathname)}   */}
          
          <UserProvider value={this.state.user}>
          <Sidebar
            {...this.props}
            routes={routes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref="mainPanel">
            <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <Switch>{this.getRoutes(routes)}</Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : (
                <Footer fluid />
              )
            }
          </div>
          {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
            sidebarMini={this.state.sidebarMini}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
            handleMiniClick={this.handleMiniClick}
          /> */}
          </UserProvider>
        </div>
      );
    }
  }
}

export default Admin;
