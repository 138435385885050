import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
// import Select from 'react-select';
// import ReactDatetime from 'react-datetime';
// import moment from 'moment';
import UserContext from "../../../UserContext";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const EditPlanPrice = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [currencyState, setCurrencyState] = useState("");
    const [currencyError, setCurrencyError] = useState("");
    const context = useContext(UserContext);
    const [basePriceState, setBasePriceState] = useState("");
    const [basePriceError, setBasePriceError] = useState("");
    const [discountState, setDiscountState] = useState("");
    const [discountError, setDiscountError] = useState("");

    const [durationState, setDurationState] = useState("");
    const [durationError, setDurationError] = useState("");

    const [durationUnitState, setDurationUnitState] = useState("");
    const [durationUnitError, setDurationUnitError] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const history = new useHistory();
    const [durationUnitData, setDurationUnitData] = useState({
        value: "",
        label: "",
    });
    const [planGroupData, setPlanGroupData] = useState({
        value: "",
        label: "",
    });
    const [modified, setModified] = useState(false);

    const [showForm, setShowFrom] = useState(false);

    const boolOptions = [
        {
            value: true,
            label: "True",
        },
        {
            value: false,
            label: "False",
        },
    ];

    const durationUnitOptions = [
        {
            value: "days",
            label: "Days",
        },
        {
            value: "months",
            label: "Months",
        },
    ];

    const nameOptions = [
        {
            value: "trial",
            label: "Trial",
        },
        {
            value: "monthly",
            label: "Monthly",
        },
        {
            value: "quarterly",
            label: "Quarterly",
        },
        {
            value: "yearly",
            label: "Yearly",
        },
    ];
    const currencyOptions = [
        {
            value: "inr",
            label: "INR",
        },
        {
            value: "usd",
            label: "USD",
        },
    ];

    const [planPrice, setPlanPrice] = useState({
        plan_id: props.planId,
        // name: "",
        base_price: "",
        discount: "",
        duration: "",
        duration_unit: "",
        // currency: "",
    });

    useEffect(() => {
        (async () => {
            setPlanPrice({
                plan_id: props.planId,
                // name: "",
                base_price: "",
                discount: "",
                duration: "",
                duration_unit: "",
                // currency: "",
            });

            const response = await saasService.getPlanDetails(props.planId);
            console.log(response);
            setPlanPrice({
                ...planPrice,
                plan_group_id: response.data.planPrice.id,
                currency: response.data.planPrice.currency,
                base_price: response.data.planPrice.base_price,
                discount: response.data.planPrice.discount,
                duration_unit: response.data.planPrice.duration_unit,
                duration: response.data.planPrice.duration,
                name: response.data.planPrice.name,
                plan_price_id: response.data.planPrice.id,
            });
        })();
    }, [modified]);

    const validateForm = async () => {
        // let planTypeValidation = validatePlanType();

        let basePriceValidation = validateBasePrice();
        let discountValidation = validateDiscount();

        let durationValidation = validateDuration();
        let durationUnitValidation = validateDurationUnit();

        if (
            // planTypeValidation &&
            basePriceValidation &&
            discountValidation &&
            durationValidation &&
            durationUnitValidation
        ) {
            setShowLoader(true);

            setShowSaveButton(true);
            const response = await saasService.editPlanPrice(planPrice);

            console.log(response);
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Plan was updated Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    //history.push(`/saas/plan-details/${props.planId}`)
                    props.setModified(true);
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message: "Plan could not be update, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    const validatePlanType = () => {
        if (planPrice.name === "") {
            setNameState("has-danger");
            setNameError(
                <div className="text-danger">Plan type is required</div>
            );
            return false;
        }

        setNameState("has-success");
        return true;
    };
    const validateBasePrice = () => {
        if (planPrice.base_price === "") {
            setBasePriceState("has-danger");
            setBasePriceError("Base Price is required");
            return false;
        }

        if (Number(planPrice.base_price) < 0) {
            setBasePriceState("has-danger");
            setBasePriceError("Base Price will never be negative");
            return false;
        }
        if (planPrice.discount) {
            if (Number(planPrice.base_price) < Number(planPrice.discount)) {
                setBasePriceState("has-danger");
                setBasePriceError("Base Price should be greater than Discount");
                return false;
            }
        }
        setBasePriceState("has-success");
        return true;
    };
    const validateDiscount = () => {
        if (planPrice.discount === "") {
            setDiscountState("has-danger");
            setDiscountError("Discount is required");
            return false;
        }
        if (planPrice.base_price) {
            if (Number(planPrice.base_price) < Number(planPrice.discount)) {
                setDiscountState("has-danger");
                setDiscountError(
                    "Discount will never be greater than Base Price"
                );
                return false;
            }
        }
        if (Number(planPrice.discount) < 0) {
            setDiscountState("has-danger");
            setDiscountError("Discount will never be negative");
            return false;
        }
        setDiscountState("has-success");
        return true;
    };
    const validateDuration = () => {
        if (planPrice.duration === "") {
            setDurationState("has-danger");
            setDurationError("Duration is required");
            return false;
        }
        if (Number(planPrice.duration) < 1) {
            setDurationState("has-danger");
            setDurationError("Duration Should be positive number");
            return false;
        }

        setDurationState("has-success");
        return true;
    };
    const validateDurationUnit = () => {
        if (planPrice.duration_unit === "") {
            setDurationUnitState("has-danger");
            setDurationUnitError(
                <div className="text-danger">Duration unit is required</div>
            );
            return false;
        }
        setDurationUnitState("has-success");
        return true;
    };

    const handleNameChange = (value) => {
        setPlanPrice({
            ...planPrice,
            name: value,
        });
        setNameError("");
        setNameState("");
    };
    const handleCurrencyChange = (value) => {
        setPlanPrice({
            ...planPrice,
            currency: value,
        });
        setCurrencyError("");
        setCurrencyState("");
    };

    const handleDurationUnitChange = (value) => {
        setPlanPrice({
            ...planPrice,
            duration_unit: value,
        });
        setDurationUnitError("");
        setDurationUnitState("");
    };

    const handleChange = (event) => {
        setPlanPrice({
            ...planPrice,
            [event.target.name]: event.target.value,
        });
        if (event.target.name === "discount") {
            setDiscountState("");
            setDiscountError("");
        }
        if (event.target.name === "base_price") {
            setBasePriceError("");
            setBasePriceState("");
        }
        if (event.target.name === "duration") {
            setDurationState("");
            setDurationError("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const toggleForm = (event) => {
        if (showForm) {
            setShowFrom(false);
        } else {
            setShowFrom(true);
        }
    };

    let planTypeElements = [];
    if (nameOptions) {
        let i = 0;
        nameOptions.forEach(function (planTypeDetails) {
            if (planTypeDetails.value == planPrice.name) {
                planTypeElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultChecked
                                value={planTypeDetails.value}
                                //  id="planTypeId"
                                name="planTypeId"
                                type="radio"
                                onChange={() => {
                                    handleNameChange(planTypeDetails.value);
                                }}
                            />
                            {planTypeDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            } else {
                planTypeElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                value={planTypeDetails.value}
                                // id="planTypeId"
                                name="planTypeId"
                                type="radio"
                                onChange={() => {
                                    handleNameChange(planTypeDetails.value);
                                }}
                            />
                            {planTypeDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }
        });
    }

    let currencyElements = [];
    if (currencyOptions) {
        let i = 0;
        currencyOptions.forEach(function (currencyDetails) {
            //console.log(planTypeDetails.value);
            i++;

            if (currencyDetails.value == planPrice.currency) {
                currencyElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultChecked
                                value={currencyDetails.value}
                                name="currencyId"
                                type="radio"
                                onChange={(value) => {
                                    handleCurrencyChange(currencyDetails.value);
                                }}
                            />
                            {currencyDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            } else {
                currencyElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                value={currencyDetails.value}
                                name="currencyId"
                                type="radio"
                                onChange={(value) => {
                                    handleCurrencyChange(currencyDetails.value);
                                }}
                            />
                            {currencyDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }
        });
    }

    let durationUnitElements = [];
    if (durationUnitOptions) {
        let i = 0;
        durationUnitOptions.forEach(function (durationUnitDetails) {
            i++;
            if (durationUnitDetails.value == planPrice.duration_unit) {
                durationUnitElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultChecked
                                value={durationUnitDetails.value}
                                // id="durationUnitId"
                                name="durationUnitId"
                                type="radio"
                                onChange={() => {
                                    handleDurationUnitChange(
                                        durationUnitDetails.value
                                    );
                                }}
                            />
                            {durationUnitDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            } else {
                durationUnitElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                value={durationUnitDetails.value}
                                // id="durationUnitId"
                                name="durationUnitId"
                                type="radio"
                                onChange={() => {
                                    handleDurationUnitChange(
                                        durationUnitDetails.value
                                    );
                                }}
                            />
                            {durationUnitDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }
        });
    }

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle
                                    tag="h4"
                                    onClick={toggleForm}
                                    style={{ cursor: "pointer" }}
                                >
                                    Edit Plan Price
                                    <b className="caret"></b>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>

                                {showForm?<Form
                                    className="form-horizontol"
                                    onSubmit={handleSubmit}>
                                    
                                    <br />
                                    <Row>
                                        <Label sm="2">Base Price (INR) <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={basePriceState}
                                            >
                                                <Input
                                                    value={planPrice.base_price}
                                                    name="base_price"
                                                    id="base_price"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {basePriceState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {basePriceError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Label sm="2">Discount (INR) <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={discountState}
                                            >
                                                <Input
                                                    value={planPrice.discount}
                                                    name="discount"
                                                    id="discount"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {discountState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {discountError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Label sm="2">Duration Unit <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            {durationUnitElements}
                                            {durationUnitState ===
                                            "has-danger" ? (
                                                <label className="error">
                                                    {durationUnitError}
                                                </label>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Duration <span class="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup
                                                className={durationState}
                                            >
                                                <Input
                                                    value={planPrice.duration}
                                                    name="duration"
                                                    id="duration"
                                                    type="number"
                                                    onChange={handleChange}
                                                />
                                                {durationState ===
                                                "has-danger" ? (
                                                    <label className="error">
                                                        {durationError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {showSaveButton ? (
                                        <Button
                                            className="btn-round"
                                            color="info"
                                            type="button"
                                            onClick={validateForm}
                                        >
                                            Update
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                </Form>:""}
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditPlanPrice;
