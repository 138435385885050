import React, { useEffect, useState, useContext } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    FormGroup,
    Form,
    FormText,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";
import './CreateAppKeyStore.css';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';
import appBuildService from "../../services/api/app-build-job-service";
import { useHistory } from 'react-router-dom';
import formValidations from '../../variables/form-validations'
import UserContext from "../../UserContext";
import UploadKey from "./UploadKey";

const UpdateAppKeyStore = (props) => {
    const history = new useHistory();
    const context = useContext(UserContext);
    const keyStoreDetails = props.keyStore[0];
    const [showPassword, setShowPassword] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [isStateChanged, setIsStateChanged] = useState(false)
    const [formdetails, setFormDetails] = useState({
        project_id: props.projectId,
        name: keyStoreDetails.name,
        alias_name: keyStoreDetails.alias_name,
        first_name: keyStoreDetails.first_name,
        last_name: keyStoreDetails.last_name,
        password: keyStoreDetails.password,
        organization: keyStoreDetails.organization,
        organization_unit: keyStoreDetails.organization_unit,
        country: "",
        state: "",
        city: keyStoreDetails.city,
        key: keyStoreDetails.key,
        id: keyStoreDetails._id
    });
    const [reloadComponent, setReloadComponent] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [fieldStates, setFieldStates] = useState({});
    const imageDetails = {
        label: "Thumbnail",
        file_type: ""
    }
    console.log("props: ", props);


    useEffect(() => {
        (async () => {
            let countriesList = Country.getAllCountries();
            let countryArray = [];
            countriesList.forEach((country) => {
                if(country.phonecode === keyStoreDetails.country_code){
                    setFormDetails({
                        ...formdetails,
                        country:{
                            name: country.name, countryCode: country.isoCode, phoneCode: country.phonecode 
                        }
                    })
                }
                countryArray.push({
                    value: { name: country.name, countryCode: country.isoCode, phoneCode: country.phonecode },
                    label: country.name
                });
            });
            setCountries(countryArray);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let statesList = State.getStatesOfCountry(formdetails.country.countryCode);
            let stateArray = [];
            statesList.forEach((state) => {
                if(state.name === keyStoreDetails.state){
                    setFormDetails({
                        ...formdetails,
                        state:{
                            name: state.name, isoCode: state.isoCode
                        }
                    })
                    setIsStateChanged(!isStateChanged)
                }
                stateArray.push({
                    value: { name: state.name, isoCode: state.isoCode },
                    label: state.name
                });
            });
            setStates(stateArray);
        })();
    }, [reloadComponent,formdetails.country]);
    useEffect(() => {
        (async () => {
            let citiesList = City.getCitiesOfState(formdetails.country.countryCode, formdetails.state.isoCode);
            let citiesArray = [];
            citiesList.forEach((city) => {
                citiesArray.push({
                    value: city.name,
                    label: city.name
                });
            });
            setCities(citiesArray);
        })();
    }, [isStateChanged]);
    // const cancelPage = function(e){  history.push('/project/appsettings?projectid='+props.projectId); }
    const setFileName = (fileName)=> {
        console.log("setFileName invoked ",fileName);
        setFormDetails({
           ...formdetails,
        name : fileName,
      })
    }

    const setFileData = (data) => {
        console.log("setFileData-----------",data);
        setFormDetails({
            ...formdetails,
            ...data,
        })
    }

    const cancelPage = function (e) { props.setShowManageKeystoreForm(false) }
    const validateForm = () => {
        let validations = true;
        if (formdetails.name.trim() === '') {
            fieldStates['error_name'] = 'has-danger';
            fieldStates['error_message_name'] = 'This field is required.';
            validations = false;
        } else {
            if (formdetails.name.trim().indexOf(' ') >= 0 || formValidations.verifySpecialCharacters(formdetails.name.trim())) {
                fieldStates['error_name'] = 'has-danger';
                fieldStates['error_message_name'] = 'Invalid filename. special characters are not allowed'
                validations = false;
            } else {
                fieldStates['error_name'] = 'has-success';
                fieldStates['error_message_name'] = '';
            }
        }
        if (formdetails.alias_name.trim() === '') {
            fieldStates['error_alias_name'] = 'has-danger';
            fieldStates['error_message_alias_name'] = 'This field is required.';
            validations = false;
        } else {
            if (formValidations.verifySpecialCharacters(formdetails.alias_name.trim())) {
                fieldStates['error_alias_name'] = 'has-danger';
                fieldStates['error_message_alias_name'] = 'Invalid alias name. special characters are not allowed'
                validations = false;
            } else {
                fieldStates['error_alias_name'] = 'has-success';
                fieldStates['error_message_alias_name'] = '';
            }
        }
        if (formdetails.first_name.trim() === '') {
            fieldStates['error_first_name'] = 'has-danger';
            fieldStates['error_message_first_name'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_first_name'] = 'has-success';
            fieldStates['error_message_first_name'] = '';
        }
        if (formdetails.last_name.trim() === '') {
            fieldStates['error_last_name'] = 'has-danger';
            fieldStates['error_message_last_name'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_last_name'] = 'has-success';
            fieldStates['error_message_last_name'] = '';
        }
        if (formdetails.password.trim() === '') {
            fieldStates['error_password'] = 'has-danger';
            fieldStates['error_message_password'] = 'This field is required.';
            validations = false;
        } else {
            if (formdetails.password.length < 6) {
                fieldStates['error_password'] = 'has-danger';
                fieldStates['error_message_password'] = 'Password length should be greater than 5 character.';
                validations = false;
            } else {
                fieldStates['error_password'] = 'has-success';
                fieldStates['error_message_password'] = '';
            }
        }
        if (formdetails.organization.trim() === '') {
            fieldStates['error_organization'] = 'has-danger';
            fieldStates['error_message_organization'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_organization'] = 'has-success';
            fieldStates['error_message_organization'] = '';
        }
        if (formdetails.organization_unit.trim() === '') {
            fieldStates['error_organization_unit'] = 'has-danger';
            fieldStates['error_message_organization_unit'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_organization_unit'] = 'has-success';
            fieldStates['error_message_organization_unit'] = '';
        }
        if (formdetails.country === '') {
            fieldStates['error_country'] = 'has-danger';
            fieldStates['error_message_country'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_country'] = 'has-success';
            fieldStates['error_message_country'] = '';
        }
        if (formdetails.state === '') {
            fieldStates['error_state'] = 'has-danger';
            fieldStates['error_message_state'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_state'] = 'has-success';
            fieldStates['error_message_state'] = '';
        }
        if (formdetails.city === '') {
            fieldStates['error_city'] = 'has-danger';
            fieldStates['error_message_city'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_city'] = 'has-success';
            fieldStates['error_message_city'] = '';
        }

        if(formdetails.key === ''){
            fieldStates['error_key'] = 'has-danger';
            fieldStates['error_message_key'] = 'This field is required.';
            validations = false;
        } else {
            fieldStates['error_key'] = 'has-success';
            fieldStates['error_message_key'] = '';
        }

        setFieldStates({
            ...fieldStates,
            fieldStates
        });
        return validations;
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        let validations = validateForm();
        if (validations) {
            setShowLoader(true);
            setShowSaveButton(false);
            console.log("formdetails: ", formdetails);
            let appKeystore = await appBuildService.uploadNewAppKeystore(formdetails);
            if (!appKeystore.status && appKeystore.error.errorCode === 403) {
                setTimeout(() => {
                    setShowLoader(false)
                    setShowSaveButton(true);
                }, 1000);
                context.setIsAuthorized(context);
            }
            else if (appKeystore.status) {
                setTimeout(() => {
                    setShowLoader(false)
                    setShowSaveButton(true);
                    setAlert({ show: true, type: 'success', message: 'App Keystore  saved successfully' });
                }, 1000);

            } else {
                setTimeout(() => {
                    setShowLoader(false)
                    setShowSaveButton(true)
                    setAlert({ show: true, type: 'danger', message: 'App Keystore could not be saved, Please try again.' });
                }, 1000);
            }

            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
                window.location.href = `/appbuild/dashboard?projectid=${props.projectId}`
            }, 3000);

            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
        }

    }
    // const handleSubmit = (event)=>{
    //     validateForm();
    //     event.preventDefault();
    // }

    const handleFileChange = (id,value) => {
        setFormDetails({
            ...formdetails,
            [id]: value,
        })
    }

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value
        });
    }
    const handleEnumChange = (event, id) => {
        console.log("id: ", id);
        console.log("event: ", event);
        setFormDetails({
            ...formdetails,
            [id]: event.value
        });

        if (id === "country") {
            setReloadComponent(Math.random());
        }
        if (id === "state") {
            let citiesList = City.getCitiesOfState(formdetails.country.countryCode, event.value);
            setIsStateChanged(!isStateChanged);
        }
    }
    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">{props.projectName} Android App Keystore</CardTitle>
                            </CardHeader>
                            <CardBody align="left">
                                <Form className="form-horizontol" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="6">
                                            <div className="designLabel">
                                                <small className="text-default">
                                                    Keystore file name<span class="text-danger"> * </span>
                                                </small>
                                            </div>
                                            <Label for="name">Enter name of keystore file</Label>

                                            <FormGroup className={fieldStates["error_name"]}>
                                                <Input type="text" onChange={handleChange} value={formdetails.name} name="name" id="name" placeholder="Enter keystore filename" />
                                                {fieldStates["error_name"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_name"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <div className="designLabel">
                                                <small className="text-default">
                                                    Alias Name<span class="text-danger"> * </span>
                                                </small>
                                            </div>
                                            <Label for="alias_name">Enter alias name for keystore</Label>
                                            <FormGroup className={fieldStates["error_alias_name"]}>
                                                <Input type="text" onChange={handleChange} value={formdetails.alias_name} name="alias_name" id="alias_name" placeholder="Enter Alias name" />
                                                {fieldStates["error_alias_name"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_alias_name"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="first_name">First Name<span class="text-danger"> * </span></Label>
                                            <FormGroup className={fieldStates["error_first_name"]}>
                                                <Input type="text" onChange={handleChange} value={formdetails.first_name} name="first_name" id="first_name" placeholder="Enter first name" />
                                                {fieldStates["error_first_name"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_first_name"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>

                                        </Col>
                                        <Col md="6">
                                            <Label for="last_name">Last Name<span class="text-danger"> * </span></Label>
                                            <FormGroup className={fieldStates["error_last_name"]}>
                                                <Input type="text" onChange={handleChange} value={formdetails.last_name} name="last_name" id="last_name" placeholder="Enter last name" />
                                                {fieldStates["error_last_name"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_last_name"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label for="password">Password<span class="text-danger"> * </span></Label>
                                                <div className="pwd-container">
                                                    <FormGroup className={fieldStates["error_password"]}>
                                                        <Input type={showPassword ? "text" : "password"} value={formdetails.password} onChange={handleChange} name="password" id="password" placeholder="Password" />
                                                        {fieldStates["error_password"] ===
                                                            "has-danger" ? (
                                                            <label className="error">
                                                                {
                                                                    fieldStates[
                                                                    "error_message_password"
                                                                    ]
                                                                }
                                                            </label>
                                                        ) : null}
                                                        {showPassword ?
                                                            <i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true"></i>
                                                            :
                                                            <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true"></i>}
                                                    </FormGroup>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="organization">Organization <span class="text-danger"> * </span></Label>
                                            <FormGroup className={fieldStates["error_organization"]}>
                                                <Input type="textarea" onChange={handleChange} value={formdetails.organization} name="organization" id="organization" />
                                                {fieldStates["error_organization"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_organization"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <Label for="organization_unit">Organization Unit<span class="text-danger"> * </span></Label>
                                            <FormGroup className={fieldStates["error_organization_unit"]}>
                                                <Input type="textarea" onChange={handleChange} value={formdetails.organization_unit} name="organization_unit" id="organization_unit" />
                                                {fieldStates["error_organization_unit"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_organization_unit"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup className={fieldStates["error_country"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select Country "
                                                    name="country"
                                                    id="country"
                                                    value={formdetails.country && countries ? countries.filter((country) => country.label === formdetails.country.name) : ""}
                                                    options={countries}
                                                    onChange={(event) => handleEnumChange(event, "country")}
                                                />
                                                {fieldStates["error_country"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_country"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup className={fieldStates["error_state"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select State "
                                                    name="state"
                                                    id="state"
                                                    value={formdetails.state && states ? states.filter((state) => state.label == formdetails.state.name) : ""}
                                                    options={states}
                                                    onChange={(event) => handleEnumChange(event, "state")}
                                                />
                                                {fieldStates["error_state"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_state"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup className={fieldStates["error_city"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select City "
                                                    name="city"
                                                    id="city"
                                                    value={formdetails.city && cities ? cities.filter((city) => city.label == formdetails.city) : ""}
                                                    options={cities}
                                                    onChange={(event) => handleEnumChange(event, "city")}
                                                />
                                                {fieldStates["error_city"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_city"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={fieldStates["error_key"]}>
                                                <UploadKey
                                                    projectId={props.projectId}
                                                    id={'key'}
                                                    setFileName={setFileName}
                                                    value={formdetails.name}
                                                    file_type="application/x-java-keystore,"
                                                    handleEnumChange={handleFileChange}
                                                    setFileData={setFileData}
                                                />
                                                {fieldStates["error_key"] ===
                                                    "has-danger" ? (
                                                    <label className="error">
                                                        {
                                                            fieldStates[
                                                            "error_message_key"
                                                            ]
                                                        }
                                                    </label>
                                                ) : null}
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    {showSaveButton ?
                                        <>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="button"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                className="btn-round"
                                                color="danger"
                                                type="button"
                                                onClick={cancelPage}
                                            >
                                                Cancel
                                            </Button>
                                        </> : ''}
                                    {alert.show ?
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert> : ''}
                                    {showLoader ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner> : ''}
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <hr></hr>

                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );

}

export default UpdateAppKeyStore;