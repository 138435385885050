import { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import UserContext from "../../UserContext";
import gcpServices from "../../services/api/gcp-resources-service.js";
import projectsService from "../../services/api/projects-service";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import Select from "react-select";

const GcpResourcesDashboard = (props) => {

    const history = new useHistory();
    const [formdetails, setFormDetails] = useState({ project_id: '' });
    const [resources, setResources] = useState(false);
    const [totalResources, setTotalResources] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [renderComponent, setRenderComponent] = useState(false);
    const [search, setSearchDetails] = useState({ project_id: '', resource_type: '' });
    const [publisherId, setPublisherId] = useState('');
    const [publisherNameArray, setPublisherNames] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [projectNameArray, setProjectNames] = useState([]);
    const [publisher, setPublisher] = useState('');
    const [reloadComponent, setReloadComponent] = useState(0);

    const records = 10;
    const resourceTypeArray = [
        {
            value: "gcs",
            label: "GCS",
        },
        {
            value: "certificateMapEntry",
            label: "Certificate Map Entry",
        },
        {
            value: "dnsAuthorization",
            label: "DNS Authorization",
        },
    ];
    const context = useContext(UserContext);

    useEffect(() => {
        (async () => {
            let resourcesData = await gcpServices.getGcpResources(formdetails, activePage, records);
            if (resourcesData.status) {
                setResources(resourcesData.data);
            }

            let getTotalResources = await gcpServices.getTotalGcpResources(formdetails);
            if (getTotalResources.status) {
                setTotalResources(getTotalResources.data.count);
            }
            setRenderComponent(true);
        })();
    }, [activePage, search]);

    useEffect(() => {
        (async () => {
            let publishers = await projectsService.getAllPublishers();
            if (publishers.status) {
                let getPublishers = [];
                let publisherData = publishers.data;
                if (publisherData.length) {
                    publisherData.forEach(function (publisherDetails) {
                        let publisherValue = { value: publisherDetails.id, label: publisherDetails.name + "(" + publisherDetails.email + ")", email: publisherDetails.email };
                        getPublishers.push(publisherValue)
                    })
                }
                setPublisherNames(getPublishers);
            }
        })();
    }, [reloadComponent]);

    useEffect(() => {
        (async () => {
            // if(publisher){
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if (projects.status) {
                let projectData = projects.data;
                if (projectData.length) {
                    projectData.forEach(function (projectDetails) {
                        let projectValue = { value: projectDetails.id, label: projectDetails.name };
                        publisherProjects.push(projectValue)
                    })
                }
                setProjectNames(publisherProjects);
            }
            // }
        })();
    }, [publisher]);

    const resetElements = () => {
        setFormDetails({ project_id: '', resource_type: "" });
        setActivePage(1);
        setSearchDetails({ project_id: '', resource_type: "" });
        setPublisherId('');
        setProjectId('');
        setPublisher('');
        setReloadComponent(Math.random())
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const handleResourceTypeChange = (event) => {
        setFormDetails({
            ...formdetails,
            resource_type: event.value,
        });
    }

    const changePublisherId = (event) => {
        setFormDetails({
            ...formdetails,
            publisher_id: event.value,
            project_id: ''
        });
        setProjectId('');
        setPublisherId(event)
        setPublisher(event.value);
    }

    const changeProjectId = (event) => {
        setFormDetails({
            ...formdetails,
            project_id: event.value,
        });
        setProjectId(event)
    }
    const handleSubmit = function (e) { searchResources(); e.preventDefault() }

    const searchResources = () => {
        setActivePage(1);
        setSearchDetails(formdetails);
    }


    let resourcesElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!resources || !resources.length) {
        if (!renderComponent) {
            resourcesElements.push(
                <td colSpan="6" className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </td>
            );
        } else {
            resourcesElements.push(
                <td colSpan="6" className="text-center">
                    <CardTitle tag="h4">No Resources found.</CardTitle>
                </td>
            );
        }
    } else {
        let i = 1;
        resources.forEach(function (resourceDetails) {
            if (i == 1) {
                firstRecord = (activePage - 1) * records + i;
            }

            resourcesElements.push(
                <tr>
                    <td className="text-left">{(activePage - 1) * 10 + i}</td>
                    <td className="text-left">
                        {resourceDetails.project_id ? resourceDetails.project_id.name : ''}
                    </td>
                    <td className="text-left">
                        {resourceDetails.project_id ? resourceDetails.project_id.slug : ''}
                    </td>
                    <td className="text-left">
                        {resourceDetails.resource_type}
                    </td>
                    <td className="text-left">
                        {resourceDetails.resource_name}
                    </td>
                    <td className="text-left">
                        {resourceDetails.resource_meta}
                    </td>
                </tr>
            )
            if (i == resources.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i == resources.length) {
                lastRecord = (activePage - 1) * records + i;
            }
        })
    }

    return (
        <div className='content'>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    GCP Resources
                                </CardTitle>
                                <Form id="gcpFilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                                    <Row className="align-items-center" style={{ marginBottom: '10px' }}>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Publisher .."
                                                name="publisher_id"
                                                id="publisher_id"
                                                options={publisherNameArray}
                                                value={publisherId}
                                                onChange={changePublisherId}
                                                styles={{ container: (provided) => ({ ...provided, marginTop: '5px' }) }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Project .."
                                                name="project_id"
                                                id="project_id"
                                                options={projectNameArray}
                                                value={projectId}
                                                onChange={changeProjectId}
                                                styles={{ container: (provided) => ({ ...provided, marginTop: '5px' }) }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeHolder="Select Resource..."
                                                name="resource_type"
                                                id="resource_type"
                                                options={resourceTypeArray}
                                                value={resourceTypeArray.filter(option => option.value === formdetails.resource_type)}
                                                onChange={handleResourceTypeChange}
                                                styles={{ container: (provided) => ({ ...provided, marginTop: '5px' }) }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="submit"
                                                >
                                                    Search
                                                </Button>
                                                <Button
                                                    className="btn-round"
                                                    color="warning"
                                                    type="button"
                                                    onClick={resetElements}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>

                                </Form>
                                <hr></hr>
                                {totalResources > 0 ?
                                    <Row>

                                        <Col md="6">
                                            <div style={{ float: 'right' }}>
                                                <b>{firstRecord} - {lastRecord} of {totalResources}</b>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div style={{ float: 'left' }}>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={records}
                                                    totalItemsCount={totalResources}
                                                    pageRangeDisplayed={3}
                                                    onChange={handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : null}

                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th className="text-left">Project Name</th>
                                            <th className="text-left">Project Slug</th>
                                            <th className="text-left">Resource Type</th>
                                            <th className="text-left">Resource Name</th>
                                            <th className="text-left">Resource Meta</th>
                                        </tr>
                                    </thead>
                                    <tbody>{resourcesElements}</tbody>

                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default GcpResourcesDashboard;