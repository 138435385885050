import axios from "axios";

const getClearCacheJobs = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/cacheclean/get-cache-clean-jobs',formdata);
    return response.data;
}

const cancelJob = async(jobId)=>{

    const response = await axios.post('/api/cacheclean/cancel-job', {
        jobId
    })
    return response.data;
}

const rescheduleJob = async(data)=>{
    const response = await axios.post('/api/cacheclean/reschedule-job', data);
    return response.data;
}


const scheduleJob = async(data)=>{
    let jobData = {
        article_id : data.article_id,
        article_guid : data.article_guid,
        category_id : data.category_id,
        page_slug : data.page_slug,
    }
    let formdata = {
                publisher_id : data.publisher_id,
                project_id : data.project_id,
                event_type : data.event_type,
                job_data : jobData,
            }
    console.log(formdata)
    const response = await axios.post('/api/cacheclean/schedule-cache-clean-job',formdata);

    return response.data;
}

const editJob = async(data)=>{

    const response = await axios.post('/api/cacheclean/edit-job-data', data);

    return response.data;
}


const getJobData = async(jobId)=>{
    const response = await axios.get(`/api/cacheclean/job-data/${jobId}`);
    
    return response.data;
}

const getTotalClearCacheJobs = async(formdetails) =>{
    const formdata = {
        filters : formdetails
    }
    const response = await axios.post('/api/cacheclean/get-total-cache-clean-jobs',formdata);
    return response.data;
}


export  default {
    getClearCacheJobs,
    getTotalClearCacheJobs,
    scheduleJob
    // cancelJob,
    // scheduleJob,
    // rescheduleJob,
    // editJob,
    // getJobData
}