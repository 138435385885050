
import React, {useEffect, useState, useContext} from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import {useHistory} from "react-router-dom";
import CreatePlanPrice from './CreatePlanPrice';
import AddPlanFeature from './AddPlanFeatures';
import EditPlan from "./EditPlan";
import PlanData from "./PlanData";
import PlanPriceDetails from "./PlanPriceDetail";
import PlanFeatureDetails from "./PlanFeatureDetails";
import UserContext from "../../../UserContext"
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";
import EditPlanPrice from "./EditPlanPrice";
import PreviewPlan from "./PreviewPlan";

const PlanGroup = (props) => {

    const [planDetails,setPlanDetails] = useState("");
    const [editPlan,setEditPlan] = useState("");
    const context = useContext(UserContext);
    const [componentRendered,setComponentRendered] = useState(false);
    const [groupDetails, setGroupDetails] = useState("")
    const history = new useHistory();
    const [priceDetails,setPriceDetails] = useState();
    const [planFeatures, setPlanFeatures] = useState([]);
    const [isPlanPublished, setIsPlanPublished] = useState(false);
    const [modified,setModified]=useState(false);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [preview,setPreview]=useState(false);

    useEffect(() => {
        (async() => {

            const response = await saasService.getPlanDetails(props.match.params["planId"]);
            if(response.status && response.data) {
                  setPlanDetails(response.data.details);
                  setEditPlan(response.data.details);
                  setGroupDetails(response.data.planGroup);
                  setPriceDetails(response.data.planPrice);
                  setPlanFeatures(response.data.planFeatures)

                
                  // if(response.data.details.is_published ===1){
                  //     setIsPlanPublished(true);
                  // }
          }
        })()
    },[modified],[isPlanPublished])

    // const activatePlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.activatePlanGroup(planGroupId);
    //     if(response.status){
    //         setChangeStatus(true);
    //     }
        // const tempPlanGroups = [];

        // planGroups.forEach(planGroup=>{
        //     if(planGroup.id == planGroupId){
        //         planGroup.status = 1;

        //         tempPlanGroups.push(planGroup)
        //     }else{
        //         tempPlanGroups.push(planGroup)
        //     }
        // })

        
    //     console.log(response)
    // }

    // const deactivatePlanPlanGroup = async(planGroupId) =>{
    //     setChangeStatus(false)
    //     const response = await saasService.deactivatePlanGroup(planGroupId);

    //     if(response.status){
    //         setChangeStatus(true)
    //     }
    //     console.log(response)

    // }

    // const getPlanGroupsTable = () =>{

    //     let groupElements = [];
    //     if(!planGroups || !planGroups.length){
    //       if(!componentRendered){
    //           groupElements.push(
    //          <tr>
    //           <td colSpan="4" className="text-center">
    //               <Spinner animation="border" role="status">
    //                       <span className="sr-only">Loading...</span>
    //                     </Spinner>
    //           </td>
    //           </tr>
    //         );
    //       }else{
    //           groupElements.push(
    //             <tr>
    //           <td colSpan="4" className="text-center">
    //               <CardTitle tag="h4">No Records Found</CardTitle>
    //           </td>
    //           </tr>
    //       );
    //       }
    //     }else{
    //        let i = 1;
    //        planGroups.forEach(function(groupDetails) {
    //             let className = '';
    //             if(!groupDetails.status){
    //               className = 'table-warning';
    //             }
    //             groupElements.push(
    //               <tr className={className}>
    //                   <td className="text-left">
    //                      {i}
    //                  </td>
    //                  <td className="text-left">
    //                      {groupDetails.name}
    //                  </td>
    //                  <td className="text-left">
    //                      {groupDetails.status?'Active':'Inactive'}
    //                  </td>
    //                  <td className="text-left">
    //                     {groupDetails.status? <Button
    //                             style={{float: 'right'}}
    //                             className="btn-round"
    //                             color="danger"
    //                             size="sm"
    //                             type="button"
    //                             onClick={(event) =>deactivatePlanPlanGroup(groupDetails.id)}>
    //                             Deactivate
    //                         </Button>:<Button
    //                             style={{float: 'right'}}
    //                             className="btn-round"
    //                             color="danger"
    //                             size="sm"
    //                             type="button"
    //                             onClick={(event) => activatePlanGroup(groupDetails.id)}>
    //                             Activate
    //                         </Button>}
    //                  </td>
    //               </tr>
    //           )
    //           i++;
    //       })
    //     }
    //     return groupElements;
    // }

    const publishPlan = async()=>{

        const response = await saasService.publishPlan(props.match.params.planId);
        if(!response.status && response.error.errorCode === 403) {
          setShowPopup({alert:null});
          context.setIsAuthorized(context);
        }
        else if(response.data === true){


            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Plan Published!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                  >
                    Plan Published successfully.
                  </ReactBSAlert>
                ),
              });
            setTimeout(hideAlert,3000);
        }
    }

    const confirmationPopup = () => {
       
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure want to publish  ?"
              onConfirm={() =>{ publishPlan()}}
              onCancel={() =>{ hideAlert()}}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, Publish it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You want to Publish Plan!
            </ReactBSAlert>
          ),
        });
      }
      const hideAlert = () => {
        setShowPopup({
          alert: null,
        });
      };  
  

    return ( 
    <div className="content">
        {showPopup.alert}
        <Container>
            {isPlanPublished ? 
                <>
                    <PlanData  planData = {planDetails} planGroup = {groupDetails}/>
                    <PlanPriceDetails planPriceData={priceDetails}/>
                    <PlanFeatureDetails planFeatures={planFeatures}/>
                </>
             :<>
            {preview?
              <>
                <PreviewPlan  setPlanPublished={(value)=>{setIsPlanPublished(value)}} setModified={(value)=>{setModified(value)}} planId={props.match.params["planId"]} planData={planDetails} planGroup={groupDetails}  planPriceData={priceDetails} planFeatures={planFeatures}/>
              </>
              :<>
                    <Row>
                        <EditPlan planId={props.match.params["planId"]} setModified={(value)=>{setModified(value)}} />
                    </Row>
                    <Row>
                        {!priceDetails ? <CreatePlanPrice setModified={(value)=>{setModified(value)}} planId={props.match.params["planId"]}/>:<EditPlanPrice planId={props.match.params["planId"]} setModified={(value)=>{setModified(value)}}/>}
                    </Row>
                    <Row>
                        <AddPlanFeature state={modified} planId={props.match.params["planId"]} setModified={(value)=>{setModified(value)}} />
                    </Row>
                    <Row>
                      {
                      (priceDetails && planFeatures.length)?
                          <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            //onClick={publishPlan}
                            onClick={(event)=>{setPreview(true);setModified(!modified)}}
                            >
                            Preview 
                        </Button>
                        :''}
                    </Row>
              </>
            }
            </>}
        </Container>
    </div> 
    )
}

export default PlanGroup;