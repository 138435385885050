import axios from 'axios';


const createTicket = async (formdetails)=> {
    const response = await axios.post('/api/zohodesk/create-ticket',formdetails);
    return response.data;
}

const getTicketCount = async ()=>{
    const response = await axios.post('/api/zohodesk/ticketCount');
    return response.data;   
}

const getTickets = async (page,records)=> {
    const formdata = {
        page,
        records
    }
    const response = await axios.post('/api/zohodesk/tickets',formdata);
    return response.data;
}

const getTicket = async (ticket_id)=>{
    const formdata={
        ticket_id:ticket_id
    }
    const response = await axios.post('/api/zohodesk/ticket',formdata);
    return response.data;   
}

const createProduct = async (productName, departmentid)=>{
    const formdata={
        productName:productName, 
        departmentid:departmentid
    }
    const response = await axios.post('/api/zohodesk/createProduct',formdata);
    return response.data;   
}

const getTicketCountByFilters = async ()=>{
    const response = await axios.post('/api/zohodesk/ticketCountByFilter');
    return response.data;   
}

const editTicket = async (formdetails)=> {
    // return true;
    const response = await axios.post('/api/zohodesk/edit-ticket',formdetails);
    return response.data;
}

const createComment = async (formdetails)=> {
    const response = await axios.post('/api/zohodesk/create-comment',formdetails);
    return response.data;
}

const searchTicket = async (formdetails,page,records)=> {
    const formdata = {
        formdetails,
        page,
        records
    }
    const response = await axios.post('/api/zohodesk/searchticket',formdata);
    return response.data;
}

const getConversations = async (ticket_id)=>{
    const formdata={
        ticket_id:ticket_id
    }
    const response = await axios.post('/api/zohodesk/conversations',formdata);
    return response.data;   
}

const getThread = async (ticketId,threadId)=>{
    const formdata={
        ticketId:ticketId,
        threadId:threadId
    }
    const response = await axios.post('/api/zohodesk/thread',formdata);
    return response.data;   
}

const getDepartments = async ()=> {
    const response = await axios.post('/api/zohodesk/departments');
    return response.data;
}

const getProducts = async (department)=> {
    const formdata = {
        department:department
    }
    const response = await axios.post('/api/zohodesk/products',formdata);
    return response.data;
}

const getAgents = async ()=> {
    const response = await axios.post('/api/zohodesk/agents');
    return response.data;
}

const getContacts = async ()=> {
    const response = await axios.post('/api/zohodesk/contacts');
    return response.data;
}

const getAllPublishers = async() =>{
    const response = await axios.post('/api/projects/get-all-publishers');
    return response.data;
}

const updateStatus = async(formdetails)=>{
    const response = await axios.post('/api/zohodesk/update-status',formdetails)
    return response.data;
}



export default {
    createTicket,
    createComment,
    getTickets,
    getTicket,
    searchTicket,
    getTicketCount,
    getTicketCountByFilters,
    editTicket,
    getConversations,
    getThread,
    getDepartments,
    getAgents,
    getContacts,
    getAllPublishers,
    getProducts,
    createProduct,
    updateStatus
}