import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import saasService from "../../../services/api/saas-service";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Switch from "react-bootstrap-switch";
import AddRazorpayPlan from './AddRazorpayPlan';
import UserContext from "../../../UserContext";
import './plan.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

const EditPlan = (props) => {
    // const [formdetails, setFormDetails] = useState({name:''});
    const [planGroups, setPlanGroups] = useState([]);
    const [namestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [plans,setPlans]=useState([]);
    const [planGroupNameState, setPlanGroupNameState] = useState("");
    const [planGroupNameError, setPlanGroupNameError] = useState("");
    const [planNameState,setPlanNameState]=useState("");
    const [planNameError,setPlanNameError]=useState("");
    const context = useContext(UserContext);
    const [planName,setPlanName]=useState("");
    const [groupDetails, setGroupDetails] = useState("");

    const [isDefaultState, setIsDefaultState] = useState("");
    const [isDefaultError, setIsDefaultError] = useState("");

    const [startTimeState, setStartTimeState] = useState("");
    const [startTimeError, setStartTimeError] = useState("");

    const [endTimeState, setEndTimeState] = useState("");
    const [endTimeError, setEndTimeError] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });

    const [isPrimary, setIsPrimary] = useState({ value: false, label: "" });
    const [planGroupData, setPlanGroupData] = useState({
        value: "",
        label: "",
    });
    const [planGroupOptions, setPlanGroupOption] = useState();
    const [planDuration, setPlanDuration] = useState();
    const [modified, setModified] = useState(false);
    const [showForm, setShowFrom] = useState(false);

    const history = new useHistory();

    const boolOptions = [
        {
            value: true,
            label: "True",
        },
        {
            value: false,
            label: "False",
        },
    ];

    const [planData, setPlanData] = useState({
        plan_group_id: "",
        start_date: "",
        end_date: "",
        type: "",
        is_primary: "",
        recurring_enabled: ""
    });


    const planTypeOptions = [
        {
            value: "trial",
            label: "Trial",
        },
        {
            value: "monthly",
            label: "Monthly"
        },
        {
            value: "yearly",
            label: "Yearly"
        }
    ];


    useEffect(() => {
        (async () => {
            const response = await saasService.getPlanDetails(props.planId);

            //setEditPlan(response.data.details);
            // console.log("response.data.details",response.data.details)
            setGroupDetails(response.data.planGroup);


            setPlanData({
                plan_group_id: response.data.planGroup.id,
                start_date: moment(response.data.details.start_date).utc().toDate(),
                end_date: moment(response.data.details.end_date).utc().toDate(),
                name:response.data.details.name,
                type: response.data.planGroup.name,
                is_primary: response.data.details.is_primary,
                recurring_enabled: response.data.details.recurring_enabled,
                rzpay_plan_id: response.data.details.rzpay_plan_id,
                type: response.data.details.type,
                plan_id: props.planId,
            });
            setPlanName(response.data.details.name);
            var duration = moment.duration(
                moment(response.data.details.end_date).diff(
                    moment(response.data.details.start_date)
                )
            );
            setDuration(duration);

            setIsPrimary({
                value: response.data.details.is_primary,
                label: response.data.details.is_primary,
            });
            setPlanGroupData({
                value: response.data.planGroup.id,
                label: response.data.planGroup.name,
            });
            let groupsData = await saasService.getPlanGroups();
            if (groupsData.status && groupsData.data) {
                //    setPlanGroups(groupsData.data);
                let tempOptions = [];

                groupsData.data.forEach((data) => {
                    tempOptions.push({
                        value: data.id,
                        label: data.name,
                    });
                });
                setPlanGroupOption(tempOptions);
                let plansData = await saasService.getPlans();
           
                if (plansData.status && plansData.data) {
                    setPlans(plansData.data.planList);
                }
            }
        })();
    }, [modified]);

    const handlePlanTypeChange = (event)=>{
        setPlanData({
            ...planData,
            type:event.value
        })   
    }

    let planTypeElements = [];
    if(planTypeOptions){
        planTypeOptions.forEach(planType=> {
            if(planData.type === planType.value){
                planTypeElements.push(
                    <div>
                        <Label check>
                            <Input
                                defaultChecked
                                value={planType.value}
                                name="currencyId"
                                type="radio"
                                onChange={(value) => {
                                    handlePlanTypeChange(planType);
                                }}
                            />
                            {planType.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }else{
                planTypeElements.push(
                    <div>
                        <Label check>
                            <Input
                                value={planType.value}
                                //   id="planGroupId"
                                name="currencyId"
                                type="radio"
                                onChange={(value) => {
                                    handlePlanTypeChange(planType);
                                }}
                            />
                            {planType.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }
            
        });
    }

    const validatePlanName = () => {
        if (planData.name === "") {
            setPlanNameState("has-danger");
            setPlanNameError("Plan name is required");
            return false;
        }
        if (plans) {
            let duplicateName = false;
            
            plans.forEach(function (details) {
                if (planData.name!=planName &&planData.name === details.name) {
                    duplicateName = true;
                }
            });
            if (duplicateName) {
                setPlanNameState("has-danger");
                setPlanNameError(
                    "You have already created the plan  with this name."
                );
                return false;
            }
        }
        setPlanNameState("has-success");
        return true;
    };

    const validatePlanGroupName = () => {
        if (planData.plan_group_id === "") {
            setNameState("has-danger");
            setNameError(
                <div className="text-danger">Plan Group Name is required</div>
            );
            return false;
        }

        setNameState("has-success");
        return true;
    };
    // const validateIsDefault = () =>{

    //     if(planData.is_default===''){
    //         setIsDefaultState("has-danger");
    //         setIsDefaultError(<div className='text-danger'>This field is required</div>);
    //         return false;
    //       }
    //       setIsDefaultState("has-success");
    //       return true;

    // }
    const validateStartTime = () => {
        if (planData.start_date === "") {
            setStartTimeState("has-danger");
            setStartTimeError("Start Date-Time is required");
            return false;
        }
        setStartTimeState("has-success");
        return true;
    };
    const validateEndTime = () => {
        if (planData.end_date === "") {
            setEndTimeState("has-danger");
            setEndTimeError("End Date-Time is required");
            return false;
        }
        setEndTimeState("has-success");

        return true;
    };

    const validateForm = async () => {

        let planGroupNameValidation = validatePlanGroupName();
        let planNameValidation =validatePlanName();

        let startTimeValidation = validateStartTime();

        let endTimeValidation = validateEndTime();

        if (
            planGroupNameValidation &&planNameValidation &&
            startTimeValidation &&
            endTimeValidation
        ) {
            setShowLoader(true);
            const response = await saasService.editPlan(planData);
            if(!response.status && response.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (response.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Plan was updated Successfully",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);

                setTimeout(() => {
                    //    history.push("/saas/plan-groups");
                    // history.push(`/saas/plan-details/${props.planId}`)
                    props.setModified(true);
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message: "Plan could not be update, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }
        }
    };

    const handleSubmit = async (event) => {
        // validateForm();
        console.log(planData);
        event.preventDefault();
    };

    const handlePlanGroupChange = async (event) => {
        setPlanData({
            ...planData,
            plan_group_id: event.target.value,
        });
        setNameState("");
        setNameError("");
    };

    const handleChange = (event) => {
        setPlanData({
            ...planData,
            [event.target.id]: event.target.value.trim(),
        });
    };

    const disablePastDates = (current) => {
        const previousDate = moment().subtract(1, "day");
        return current.isAfter(previousDate);
    };

    const handleStartTimeChange = (event) => {
        setPlanData({
            ...planData,
            start_date: moment(event._d).utc().toDate(),
        });
        if (planData.end_date != "") {
            var duration = moment.duration(
                moment(planData.end_date).diff(moment(event._d))
            );
            setDuration(duration);
        } else {
            setPlanDuration("");
        }
        setStartTimeError("");
        setStartTimeState("");
    };
    const handlePlanNameChange = async (event) => {
        setPlanData({
            ...planData,
            name: event.target.value,
        });
        setPlanNameState("");
        setPlanNameError("");
    };
    const handleSwitchChange = (fieldname, fieldvalue) => {
        setPlanData({
            ...planData,
            [fieldname]: fieldvalue,
        });

        setIsDefaultError("");
        setIsDefaultState("");
    };

    const handleIsDefault = (event) => {
        setPlanData({
            ...planData,
            is_default: event.value,
        });
    };

    const toggleForm = (event) => {
        if (showForm) {
            setShowFrom(false);
        } else {
            setShowFrom(true);
        }
    };

    const handleEndTimeChange = (event) => {
        setPlanData({
            ...planData,
            end_date: moment(event._d).utc().toDate(),
        });

        if (planData.start_date != "") {
            var duration = moment.duration(
                moment(event._d).diff(moment(planData.start_date))
            );
            setDuration(duration);
        } else {
            setPlanDuration("");
        }
        setEndTimeError("");
        setEndTimeState("");
    };

    function setDuration(duration) {
        let data=duration._data;
        var seconds = data.seconds;
        var minutes = data.minutes;
        var hours = data.hours;
        var days = data.days;
        var months = data.months;
        var years = data.years;
        var s = "";
        if (years > 0) {
            s += years + (years === 1 ? "  Year  " : "  Years  ");
        }
        if (months > 0) {
            s += months + (months === 1 ? "  Month  " : "  Months  ");
        }
        if (days > 0) {
            s += days + (days === 1 ? "  Day  " : "  Days  ");
        }

        if (hours > 0) {
            s += hours + (hours === 1 ? "  Hour  " : "  Hours  ");
        }

        if (minutes > 0) {
            s += minutes + (minutes === 1 ? "  Minute  " : "  Minutes  ");
        }
        setPlanDuration(s);
    }

    let planGroupElements = [];
    if (planGroupOptions) {
        let i = 0;
        planGroupOptions.forEach(function (planGroupDetails) {
            i++;
            if (
                (!planData.plan_group_id && i == 1) ||
                planGroupDetails.value == planData.plan_group_id
            ) {
                planGroupElements.push(
                    <div>
                        <Label check>
                            <Input
                                defaultChecked
                                value={planGroupDetails.value}
                                //   id="planGroupId"
                                name="planGroupId"
                                type="radio"
                                onChange={handlePlanGroupChange}
                            />
                            {planGroupDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            } else {
                planGroupElements.push(
                    <div>
                        <Label check>
                            <Input
                                value={planGroupDetails.value}
                                //id="planGroupId"
                                name="planGroupId"
                                type="radio"
                                onChange={handlePlanGroupChange}
                            />
                            {planGroupDetails.label}{" "}
                            <span className="form-check-sign" />
                        </Label>
                    </div>
                );
            }
        });
    }

    const handleRzpPlanId = (event) =>{
        setPlanData({
            ...planData,
            rzpay_plan_id: event.target.value
        })
    }

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4" onClick={toggleForm} style={{cursor:"pointer"}}>
                                    Edit Plan
                                    <b className="caret"></b>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {showForm ? (
                                    <Form
                                        className="form-horizontol"
                                        onSubmit={handleSubmit}
                                    >
                                    <Row>
                                        <Label sm="3">Plan Name <span className="text-danger"> * </span></Label>
                                        <Col sm="5">
                                            <FormGroup className={planNameState}>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    onChange={handlePlanNameChange}
                                                    value={planData.name}
                                                />
                                                {planNameState === "has-danger" ? (
                                                    <label className="error">
                                                        {planNameError}
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>

                                        <Row>
                                            <Label sm="3">
                                                Plan Group Name <span className="text-danger"> * </span>
                                            </Label>

                                            <Col sm="3">
                                                <Row>
                                                    {namestate ===
                                                    "has-danger" ? (
                                                        <label className="error">
                                                            {nameerror}
                                                        </label>
                                                    ) : null}
                                                    <div className="planGP">
                                                        {planGroupElements}
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Label sm="3">
                                                Plan Type <span className="text-danger"> * </span>
                                            </Label>

                                            <Col sm="2">
                                                <Row>
                                                    {namestate ===
                                                    "has-danger" ? (
                                                        <label className="error">
                                                            {nameerror}
                                                        </label>
                                                    ) : null}
                                                    <div className="planGP">
                                                        {planTypeElements}
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Label sm="3">
                                                Is Primary Plan
                                            </Label>
                                            <Col sm="1">
                                                <FormGroup>
                                                    <Switch
                                                        name="is_default"
                                                        offColor="success"
                                                        offText={
                                                            <i className="nc-icon nc-simple-remove" />
                                                        }
                                                        onColor="success"
                                                        onText={
                                                            <i className="nc-icon nc-check-2" />
                                                        }
                                                        value={
                                                            planData.is_primary
                                                        }
                                                        onChange={(
                                                            value,
                                                            enabled
                                                        ) =>
                                                            handleSwitchChange(
                                                                "is_primary",
                                                                enabled
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="8"></Col>
                                        </Row>
                                       <br />
                                        <Row>
                                        <Label sm="3">
                                                Start Date-Time <span className="text-danger"> * </span>
                                            </Label>
                                            <Col>
                                            <FormGroup
                                                        className={
                                                            startTimeState
                                                        }
                                                    >
                                                        <Col></Col>

                                                        <Col sm="7">
                                                            <ReactDatetime
                                                                value={
                                                                    planData.start_date
                                                                }
                                                                name="scheduleTime"
                                                                // id="scheduleTime"
                                                                isValidDate={
                                                                    disablePastDates
                                                                }
                                                                onChange={
                                                                    handleStartTimeChange
                                                                }
                                                            />
                                                            {startTimeState ===
                                                            "has-danger" ? (
                                                                <label className="error">
                                                                    {
                                                                        startTimeError
                                                                    }
                                                                </label>
                                                            ) : null}
                                                        </Col>
                                                    </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                        <Label sm="3">
                                                        End Date-Time <span className="text-danger"> * </span>
                                                    </Label>
                                                    <Col>
                                                    <FormGroup
                                                        className={endTimeState}
                                                    >
                                                        <Col sm="7">
                                                            <ReactDatetime
                                                                value={
                                                                    planData.end_date
                                                                }
                                                                name="scheduleTime"
                                                                // id="scheduleTime"
                                                                isValidDate={
                                                                    disablePastDates
                                                                }
                                                                onChange={
                                                                    handleEndTimeChange
                                                                }
                                                            />
                                                            {endTimeState ===
                                                            "has-danger" ? (
                                                                <label className="error">
                                                                    {
                                                                        endTimeError
                                                                    }
                                                                </label>
                                                            ) : null}
                                                        </Col>
                                                    </FormGroup>
                                                    </Col>
                                        </Row> 
                                        <br />
                                        <Row>
                                            <Label sm="3">Duration</Label>
                                            <Col sm="1">
                                                <div>{planDuration}</div>
                                            </Col>
                                        </Row>
                                        <br />
                                         <Row>
                                        <Label sm="3">Enable Recurring</Label>
                                        <Col sm="1">
                                            <FormGroup>
                                                <Switch
                                                    name="is_default"
                                                    offColor="success"
                                                    offText={
                                                        <i className="nc-icon nc-simple-remove" />
                                                    }
                                                    onColor="success"
                                                    onText={
                                                        <i className="nc-icon nc-check-2" />
                                                    }
                                                    value={planData.recurring_enabled}
                                                    onChange={(
                                                        value,
                                                        enabled
                                                    ) =>
                                                        handleSwitchChange(
                                                            "recurring_enabled",
                                                            enabled
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="8"></Col>
                                        </Row>
                                        
                                        {
                                        planData.recurring_enabled?  
                                            
                                            <AddRazorpayPlan state={modified} planId={props.planId} setModified={(value)=>{setModified(value)}} />
                                            : null
                                        }
                                        {showSaveButton ? (
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="button"
                                                onClick={validateForm}
                                            >
                                                Update
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {alert.show ? (
                                            <Alert color={alert.type}>
                                                {alert.message}
                                            </Alert>
                                        ) : (
                                            ""
                                        )}

                                        {showLoader ? (
                                            <Spinner
                                                animation="border"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </Spinner>
                                        ) : (
                                            ""
                                        )}
                                    </Form>
                                ) : (
                                    ""
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditPlan;
