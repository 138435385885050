
import React, {useEffect, useState, useContext} from "react";
import saasService from "../../../services/api/saas-service";
import {useHistory} from "react-router-dom";
import UserContext from "../../../UserContext";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

const PlanPriceDetails = (props) => {

    const [planPrice, setPlanPrice] = useState({})
    const context = useContext(UserContext);
    useEffect(() => {
        setPlanPrice(props.planPriceData)
    },[])

    return ( 
    <div className="content">
        <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-signup text-center">
                        <CardHeader>
                            <CardTitle tag="h4">
                                Plan Price Details
                            </CardTitle>
                        </CardHeader>
                        <CardBody>

                            <Row>
                                <Col sm="6">
                                    Base Price
                                </Col>
                                <Col sm="6">
                                <i className="fa fa-inr" /> {planPrice.base_price}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="6">
                                    Discount
                                </Col>
                                <Col sm="6">
                                <i className="fa fa-inr" /> {planPrice.discount}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    Effective Price
                                </Col>
                                <Col sm="6">
                                <i className="fa fa-inr" /> {planPrice.base_price-planPrice.discount}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    Duration
                                </Col>
                                <Col sm="6">
                                    {planPrice.duration} {planPrice.duration_unit}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div> 
    )
}

export default PlanPriceDetails;