import axios from 'axios';


const getSubscriptions = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/subscription/get-subscriptions',formdata);
    return response.data;
}
export default {
    getSubscriptions
}