import React, { useEffect, useState ,useContext} from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext";
import Moment from "react-moment";
import generalHelper from '../../variables/general-helper.js';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import './Projects.css'
const Projects = (props)=> {
  const history = new useHistory(); 
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisherids:[],publishername:'',publisheremail:'',projectname:'',status:1});
  const [projects, setProjects] = useState(false);
  const [totalProjects,setTotalProjects] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({publisherids:[],publishername:'',publisheremail:'',projectname:'',status:1});
  const [allPublishers, setAllPublishers] = useState([]);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const records = 10;
  let statusArray = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: -1,
      label: "Suspended",
    },
    {
      value: -2,
      label: "Deleted",
    },
    {
      value: "all",
      label: "All",
    },
  ];
  const pathname = window.location.href;

  useEffect(()=>{
    let filtersJSONString= generalHelper.getValueFromQueryString("filters");
    if(filtersJSONString){
      try{
        let filters = JSON.parse(filtersJSONString);
        setFormDetails({...formdetails,...filters});
      }catch(error){
        setFormDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'',status:1});
      }
    }else{
      setFormDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'',status:1});
    }
    let pageNumber = new URLSearchParams(window.location.search).get("page");
    if(pageNumber){
      setActivePage(pageNumber);
    }
    else{
      pageNumber=1;
      generalHelper.addKeyToQueryString("page",1);
    }
    setActivePage(pageNumber);
    setSearchDetails({});
},[pathname]);
  useEffect( () => {
    (async () =>{
      let publishers = await projectService.getAllPublishers();
     
      if(!publishers.status && publishers.error.errorCode === 403){
        let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
        window.history.replaceState({},'',newurl)
      }
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")"};
                    getPublishers.push(publisherValue)
                  })
                }
                setAllPublishers(getPublishers);
              }
              
              
              
            })();
          }, []);
          
          
          useEffect( () => {
    (async () =>{
        
            let projectsData = await projectService.getAllProjects(formdetails,activePage,records);
           
            if(!projectsData.status && projectsData.error.errorCode === 403){
              let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
              window.history.replaceState({},'',newurl)
            }
            if(projectsData.status && projectsData.data){
                // console.log("ProjectData",projectsData);
                setProjects(projectsData.data);
            }
            
            let getTotalProjects = await projectService.getTotalProjects(formdetails);
           
            // setTotalProjects(151);
            if(getTotalProjects.status){
              setTotalProjects(getTotalProjects.data.count);
            }

            
              setRenderComponent(true);
            

        })();
    }, [activePage,search,reloadComponent]);

    const suspendProjectPopup = (projectId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => suspendProject(projectId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Suspend this!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to suspend this project!
          </ReactBSAlert>
        ),
      });
    }

    const restoreProjectPopup = (projectId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreProject(projectId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this project again!
          </ReactBSAlert>
        ),
      });
    }

    const suspendProject = async (projectId) => {
      let susProject = await projectService.suspendProject(projectId);
      if(!susProject.status && susProject.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(susProject.status && susProject.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Removed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project was suspended successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project could not be suspended. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreProject = async (projectId) => {
      let resProject = await projectService.restoreProject(projectId);
      if(!resProject.status && resProject.error.errorCode === 403){
          setShowPopup({
            alert:null
          });
          context.setIsAuthorized(context);
      }
      else if(resProject.status && resProject.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    }; 

  const handlePageChange = (pageNumber) => {
      generalHelper.addKeyToQueryString("page",pageNumber);
      setActivePage(pageNumber);
  }

  const handlePublisherChange = (value) => {
    let publisherIds = [];
    if(value && value.length > 0){
      value.forEach(function(publisherIdDetails){
        publisherIds.push(publisherIdDetails.value);
      })
    }
    setFormDetails({
      ...formdetails,
      publisherids: publisherIds,
    });
  }

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id] : event.target.value
    });
  }

  const handlePublisherNameChange = (event) => {
    setFormDetails({
      ...formdetails,
      publishername: event.target.value,
    });
  }

  const handlePublisherEmailChange = (event) => {
    setFormDetails({
      ...formdetails,
      publisheremail: event.target.value,
    });
  }

  const handleSubmit = function(e){  searchProjects();e.preventDefault() }

  const searchProjects = () => {
      generalHelper.addKeyToQueryString("filters",JSON.stringify(formdetails));
      setActivePage(1);
      generalHelper.addKeyToQueryString("page",1);
      setSearchDetails(formdetails);
  }
  const changeStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
  };
  const resetElements = () => {
    document.getElementById('projectname').value = '';
    document.getElementById('projectslug').value = '';
    document.getElementById('publishername').value = '';
    document.getElementById('publisheremail').value = '';
    let defaultFormDetails = {publisherids:[],publishername:'',publisheremail:'',projectname:'',projectslug:'',status:1};
    let jsonString = JSON.stringify({
      ...formdetails,
      ...defaultFormDetails
    });
    generalHelper.addKeyToQueryString("filters",jsonString);
    generalHelper.addKeyToQueryString("page",1);
    setFormDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'',projectslug:'',status:1});
    setSearchDetails({publisherids:[],publishername:'',publisheremail:'',projectname:'',projectslug : '',status:1});

  }

  const redirectProjectUrl = (e,navigate,redirectPath,projectId) =>{
    e.preventDefault()
    history.push("/"+navigate+"/"+redirectPath+"?projectid="+projectId)
  }
  
  let projectElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!projects || !projects.length){
    if(!renderComponent){
      projectElements.push(
        <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    projectElements.push(
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4">No Projects found.</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      projects.forEach(function(projectDetails) {
        let className = '';
        if(i==1){
          firstRecord = (activePage-1)*records+i;
        }

          if(projectDetails.status==-1){
            className = 'table-warning';
          }
        
        projectElements.push(
          <>
            <tr id={"row_"+projectDetails._id} className={className}>
              
               <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left">

                   {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.name}
                 </a>
                   :
                   <a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.name}
                 </a>}
               </td>
               <td className="text-left">
                 
                  {props.navigate?
                   <a onClick={(event)=>  window.location.href = "/"+props.navigate+"?projectid="+projectDetails.id } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.slug}
                 </a>
                   :<a onClick={(event)=>  window.location.href = "/report/project?project="+projectDetails.slug } href="javascript:void(0)" class="btn-rotate nav-link">
                   {projectDetails.slug}
                 </a>} 
               </td>
               <td className="text-left">
                   {projectDetails.publisherName}
               </td>
               <td  className="text-left">
                   {projectDetails.publisherEmail}
               </td>
               <td className="text-left">
                            <Moment format="DD MMM YYYY hh:mm:ss a">
                                {projectDetails.created_at}
                            </Moment>
               </td>
              
               {/* <td className="text-left">
                <a onClick={(event)=>  window.location.href = "/project/stats?projectid="+projectDetails.id} href="javascript:void(0)" class="btn-rotate nav-link">
                   View
                 </a>
               </td> */}
                </tr>
               <tr  className="tricon">
                 
                 {/* <td colSpan="1"></td> */}
                 <td colSpan="4" >
                   <a title="Website settings" onClick={(event)=>  redirectProjectUrl(event,'config','customizations',projectDetails.id) }  class="btn-rotate ">
                     <Button  className="btn-smb btnicon"> <i class="nc-icon nc-controller-modern"></i> Website settings  </Button>
                  </a>
                  &nbsp;&nbsp;
                  <a title="App Settings" onClick={(event)=>  redirectProjectUrl(event,'project','appsettings',projectDetails.id) }  class="btn-rotate ">
                  <Button  className="btn-smb btnicon"><FontAwesomeIcon icon={faTablet} /> App settings </Button>
                  </a>
                  </td>
                  {/* <td colSpan={"1"}>
                  </td> */}
                  <td colSpan={"2"} className="actionItem">
                  <a title="Project Domains" onClick={(event)=>  redirectProjectUrl(event,'project','domains',projectDetails.id) }  class="btn-rotate ">
                     <Button  className="btn-smb btnsmicon"><i class="nc-icon nc-planet"></i></Button>
                  </a>
                  
                  <a title="Project Users" onClick={(event)=>  redirectProjectUrl(event,'project','users',projectDetails.id) }  class="btn-rotate ">
                    <Button  className="btn-smb btnsmicon"><i class="nc-icon nc-single-02"></i></Button> 
                  </a>
                  <a title="Project Settings" onClick={(event)=> {redirectProjectUrl(event,'project','settings',projectDetails.id) }}  class="btn-rotate">
                  <Button  className="btn-smb btnsmicon"><i class="nc-icon nc-settings-gear-65"></i></Button> 
                  </a>
                  
                  {projectDetails.status==1?
                    <a title="Suspend" onClick={(event)=>  suspendProjectPopup(projectDetails.id) } href="javascript:void(0)" class="nav-link">
                    {/* <i class="nc-icon nc-simple-remove"></i> */}
                      <Button  className="btnsmiconred"><FontAwesomeIcon icon={faTrash} /></Button> 
                  </a>
                  :''}
                  {projectDetails.status==-1?
                  <a title="Restore" onClick={(event)=>  restoreProjectPopup(projectDetails.id) } href="javascript:void(0)" class="nav-link">
                      {/* <i class="nc-icon nc-refresh-69"></i> */}
                      <Button  className="btn-smb btnsmicon"><FontAwesomeIcon icon={faTrashRestoreAlt} /></Button>  
                    </a> 
                    :''}
                    </td>
                    <td></td>
               </tr>
               
            
            
            
               
               </>

        )
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==projects.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  
    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <Row>
                  <Col md="12">
                  {props.navigate?'':<Button
                      style={{float: 'right'}}
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/project/create") }
                  >Create Project</Button>}
                  </Col>
                </Row>
                <CardTitle tag="h4">
                    Projects 
                </CardTitle>
                <Form id="projectfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                  {/* <Col md="4">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="select publishers"
                    name = "publisherids"
                    id="publisherids"
                    isMulti = "true"
                    options={allPublishers}
                    onChange={(value)=>  handlePublisherChange(value) }
                  />
                  </Col> */}
                  <Row>
                  <Col sm="3">
                  <Input type="text" name="publishername" id="publishername" value={formdetails.publishername} onChange={handlePublisherNameChange} placeholder="Publisher Name .."/>
                  </Col>
                  <Col sm="2">
                  <Input type="text" name="publisheremail" id="publisheremail" value={formdetails.publisheremail} onChange={handlePublisherEmailChange} placeholder="Publisher Email .."/>
                  </Col>
                  <Col sm="2">
                  <Input type="text" name="projectname" id="projectname" value={formdetails.projectname} onChange={handleChange}  placeholder="Project Name .."/>
                  </Col>
                  <Col sm="2">
                  <Input type="text" name="projectslug" id="projectslug" value={formdetails.projectslug} onChange={handleChange}  placeholder="Project Slug .."/>
                  </Col>
                  <Col sm="3">
                  <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Status .."
                        name="status"
                        id="status"
                        options={statusArray}
                        value={formdetails.status!==""?statusArray.filter(option=> option.value == formdetails.status):""}
                        onChange={changeStatus}
                      />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      //style={{'margin-top':'-5px'}}
                      color="info"
                      type="submit"
                      //onClick={searchProjects}
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      //style={{'margin-top':'-5px'}}
                      color="warning"
                      type="button"
                      onClick={resetElements}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                </Row>
                </Form>
                <hr></hr>
                {totalProjects > 0 ? 
                  <Row>
                    
                    <Col md="6">
                      <div style={{float:'right',margin:'5px'}}>
                        <b>{firstRecord} - {lastRecord} of {totalProjects}</b>
                      </div>
                    </Col>
                    <Col md="6">
                    <div style={{float:'left'}}>
                        <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalProjects}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        />
                    </div>
                  </Col>
                  </Row>
                : null}
                 
                </CardHeader>
                <CardBody>
                <Table class="fixed" responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th>   
                        <th className="text-left">Project Name</th>
                        <th className="text-left">Project Slug</th>
                        <th className="text-left" >Publisher Name</th>
                        <th className="text-left" >Publisher Email</th>
                        <th className="text-left">Created At</th>
                        {/* <th className="text-left">Stats</th> */}
                        {/* <th className="text-left">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>{projectElements}</tbody>
                  
                  </Table>
                </CardBody>
                <CardFooter>
                
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default Projects;
