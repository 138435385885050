import React, { useEffect, useState,useContext } from "react";
import projectService from "../../services/api/projects-service";
import formValidations from "../../variables/form-validations";
import {useHistory} from "react-router-dom";
import GenerateSsl from './GenerateSsl';
import CdnDetails from './CdnDetails';
import UserContext from "../../UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

const Domains = (props)=> {
    const projectId = new URLSearchParams(props.location.search).get('projectid');
    const context = useContext(UserContext);
    const history = new useHistory();
    if(!projectId){
      history.push("/project/projects");
    }
 
    const [formdetails, setFormDetails] = useState({project_id: projectId, domain:''});
    const [domains, setDomains] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [renderComponent, setRenderComponent] = useState(false);
    const [showLoader, setShowLoader]   = useState(false);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});

    const [domainSSLId, setDomainId] = useState('');
    const [showSSLVerify, setShowSSLVerify] = useState(false);

    const [cdnId, setCdnId] = useState('');
    const [showCdnDetails, setShowCdnDetails] = useState(false);
    const [validateSslStatus, setValidateSslStatus] = useState('pending');
    const [domainState, setDomainState] = useState("");
    const [domainError, setDomainError] = useState("");
    // const [showLoader, setShowLoader]   = useState(false);
    const [showButton, setShowButton]   = useState(false);
    // const [alert, setAlert]   = useState({show:false,message:'',type:''});

   useEffect( () => {
    (async () =>{
            let projectDetails = await projectService.getProjectDetails(projectId);
            if(projectDetails.status && projectDetails.data){
                setProjectName(projectDetails.data.name);
            }

            let domainsData = await projectService.getProjectDomains(projectId);
            console.log(domainsData)
            if(domainsData.status && domainsData.data){
                setDomains(domainsData.data);
                setFormDetails({
                    ...formdetails,
                    domain : domainsData.data.public_host?domainsData.data.public_host:""}
                  );
            }
            setRenderComponent(true);
        })();
    }, [validateSslStatus]);

  const openEditForm = (event,fieldname) => {
    setShowButton(true);
    document.getElementById(fieldname+'_form').style.display = '';
    document.getElementById(fieldname+'_label').style.display = 'none';
    document.getElementById(fieldname).focus();
  }

  const hideForm = (event,fieldname) => {
    document.getElementById(fieldname+'_form').style.display = 'none';
    document.getElementById(fieldname+'_label').style.display = '';
    setShowButton(false);
  }
  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });
    if(event.target.id=="domain")
    {
    setDomainState("has-success");
    setDomainError("");
    }
  }

  const validateProjectDomain = () => {
      if(formdetails.domain===''){
        setDomainState("has-danger");
        setDomainError("This field is required");
        return false;
      }

      setFormDetails({
        ...formdetails,
        domain: formdetails.domain.replace('https://','').replace('http://', '')
      })

      if(!formValidations.verifyDomain(formdetails.domain)){
        setDomainState("has-danger");
        setDomainError("Invalid Domain name, try with www.example.com or m.example.com");
        return false;
      }
      
      if(domains && domains.length > 0){
        let duplicateDomain = false;
        domains.forEach(function(domainDetails) {
            if(formdetails.domain===domainDetails.public_host){
                duplicateDomain = true;
            }
        })
        if(duplicateDomain){
            setDomainState("has-danger");
            setDomainError("You have already created this domain.");
            return false;
        }
      }

      setDomainState("has-success");
      return true;
  }
  
    const handleSubmit = function(e){  validateForm();e.preventDefault() }
 

  const validateForm = async () => {
    let domainvalidation = validateProjectDomain();
    if(domainvalidation){
      setShowLoader(true);
      setShowButton(false);
      console.log(formdetails)
      let editProjectDomain = await projectService.editProjectDomain(formdetails);
      if(!editProjectDomain.status && editProjectDomain.error.errorCode === 403){
        setAlert({show:false});  
        context.setIsAuthorized(context);
      }
      else if(editProjectDomain.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Domain was updated Successfully'});
        },2000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          window.location.reload();
          // history.push("/project/domains?projectid="+projectId);
        },3000);
        hideForm(null,'domain')
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowButton(true)
          setAlert({show:true,type:'danger',message:'Domain could not be update, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          
        },3000);
      }
    }
  }
  

  const generateSSLCertificate =  async(domainId,projectId) => {
    setDomainId(domainId);
    let generateSsl = await projectService.generateSsl({domainId,projectId});
    if(!generateSsl.status && generateSsl.error.errorCode === 403){
        setAlert({show:false})
        context.setIsAuthorized(context);
    }
    else if(generateSsl.status){
      setTimeout(()=>{
        setShowLoader(false)
        setAlert({show:true,type:'success',message:'SSL generation request processed Successfully'});
      },1000);
      setTimeout(()=>{
        setAlert({show:false,type:'',message:''});
        window.location.reload();
      },3000);
    }else{
      setTimeout(()=>{
        setShowLoader(false)
        setAlert({show:true,type:'danger',message:'SSL generation could not be processed, Please try again.'});
      },1000);

      setTimeout(()=>{
        setAlert({show:false,type:'',message:''});
        window.location.reload();
      },3000);
    }
    
    setShowSSLVerify(true);
  }

  const showCdnDetailsPage =  (cdnId) => {
    setCdnId(cdnId);
    setShowCdnDetails(true);
  }

  const validateSSLCertificate = async (domainId,projectId) => {

      setShowLoader(true);
      let validateSsl = await projectService.validateSsl(domainId,projectId);
      if(!validateSsl.status && validateSsl.error.errorCode === 403){
          context.setIsAuthorized(context);
      }
      else if(validateSsl.status){

        let alertType = 'danger';
        if(validateSsl.data=='PENDING_VALIDATION'){
          alertType = 'info';
        }else if(validateSsl.data=='ISSUED'){
          alertType = 'success';
        }

        

        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:alertType,message:'Validation Status is : '+validateSsl.data});
        },3000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          if(validateSsl.data=='ISSUED'){
            setValidateSslStatus('success');
            window.location.reload();
          }
        },10000);

        

      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'danger',message:'Could not be validated, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          window.location.reload();
        },3000);
      }
      
  }

  const deployCDN = async (projectId,domainId) => {

      setShowLoader(true);
      let deployCDN = await projectService.deployCdn(projectId,domainId);
      if(!deployCDN.status && deployCDN.error.errorCode === 403){
        setAlert({show:false});  
        context.setIsAuthorized(context);
      }
      else if(deployCDN.status && deployCDN.data){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Deployed Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          setValidateSslStatus('deployed');
          window.location.reload();
        },3000);


      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'danger',message:'Could not be deployed, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          window.location.reload();
        },3000);
      }
  }
  
  let domainElements = [];
  
  if(!domains.public_host ){
    if(!renderComponent){
        domainElements.push(
        <td colSpan="7" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        domainElements.push(
        <td colSpan="7" className="text-center">
            <CardTitle tag="h4">No Domain found.</CardTitle>
            <Button
                      //style={{'margin-top':'-50px'}}
                      className="btn-round"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={(event)=>  history.push("/project/createdomain?projectid="+projectId) }
                  >Create Domain</Button>
        </td>
    );
    }
   }

  
  // if(showSSLVerify){
  //   return ( <GenerateSsl domainId={domainSSLId} setShowSSLVerify={setShowSSLVerify}/>);
  // }else
   if(showCdnDetails){
    return ( <CdnDetails domainDetails={domains} cdnId={cdnId} setShowCdnDetails={setShowCdnDetails}/>);
  }else{
    return (
      <div className="content">
          
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                    Domain Details of {projectName}
                </CardTitle>

                {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}

                  
                <Row>
                 
                </Row>
                </CardHeader>
                <CardBody>
                <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left"></th>
                      </tr>
                    </thead>
                    {domains.origin_host && domains.public_host?
                    <tbody>
                          <tr>
                            <td className="text-left">
                              <b>Domain URL :</b>
                            </td>
                            <td className="text-left">
                              {/* {domains.public_host} */}
                              {/* {domains.status==0? */}
                              <>
                              <span id="domain_label">
                                {formdetails.domain}
                                <a style={{display: 'inline'}} title="Edit" onClick={(event)=>  openEditForm(event,'domain') } href="javascript:void(0)" class="nav-link">
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                </a>
                              </span>
                              <span>
                              <span style={{display: 'none'}} className="display-none" id="domain_form">
                                <td>
                                <Input
                                  name="domain"
                                  id="domain"
                                  type="text"
                                  onChange={handleChange}
                                  //onBlur={handleBlur}
                                  // onBlur={(event)=>  hideForm(event,'domain') }
                                  value={formdetails.domain}
                                />
                                {domainState === "has-danger" ? (
                                  <label style={{color: "red"}} className="error">
                                    {domainError}
                                  </label>
                                ) : null}
                                </td>
                                </span>
                                <div>
                                {showButton?
                                  <>
                                    <Button
                                        className="btn-round"
                                        color="primary"
                                        size="sm"
                                        type="button"
                                        // onClick = {handleSubmit}
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        className="btn-round"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick = {(event)=>  {    setFormDetails({...formdetails,domain:domains.public_host});hideForm(event,'domain')} }
                                    >
                                        Cancel
                                    </Button>
                                    </>
                                :""}
                                </div>
                                </span>
                                </>
                                {/* :
                                domains.public_host
                                } */}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                            <b>Demo HOST :</b>
                            </td>
                            <td className="text-left">
                              {domains.demo_host}
                            </td>
                            </tr>
                          <tr>
                            <td className="text-left">
                            <b>Origin HOST :</b>
                            </td>
                            <td className="text-left">
                              {domains.origin_host}
                            </td>
                            </tr>
                            <tr>
                            <td className="text-left">
                            <b>PublicHostCName :</b>
                            </td>
                            <td className="text-left">
                              {domains.public_host_cname}
                            </td>
                            </tr>
                          <tr>
                            <td className="text-left">
                            <b>Status :</b>
                            </td>
                            <td className="text-left">
                              {domains.status==0?'SSL Pending'
                                :domains.status==1?'SSL Not Verified'
                                :domains.status==2?'Deployment Pending'
                              :'Deployment Complete'
                              }
                            </td>
                            </tr>
                            {domains.status==1?
                            <tr>
                            <td className="text-left">
                            <b>Verification Method :</b>
                            </td>
                            <td className="text-left">
                              {domains.acm_certificate ? domains.acm_certificate.domain_validation_method : ""}
                            </td>
                            </tr>
                            :''}

                        
                            {domains.status==1 && domains.acm_certificate && domains.acm_certificate.domain_validation_method=='DNS'?
                            <tr>
                            <td className="text-left">
                            <b>CNAME Details :</b>
                              </td>
                              <td className="text-left">
                                <b>Name :</b> {domains.acm_validation_data[0].ResourceRecord?domains.acm_validation_data[0].ResourceRecord.Name:""} 
                                <br/>
                                  <b>Value :</b> {domains.acm_validation_data[0].ResourceRecord?domains.acm_validation_data[0].ResourceRecord.Value:""}
                              </td>
                              </tr>
                            :''}
                            


                          <tr>
                            <td className="text-left">
                            <b>CDN Distribution ID :</b>
                            </td>
                            <td className="text-left">
                                {domains.cdn_dist_id?
                                  <a onClick={(event)=>  showCdnDetailsPage(domains.cdn_dist_id) } href="javascript:void(0)" class="btn-rotate nav-link">
                                  {domains.cdn_dist_id}
                                </a>
                                :''}
                            </td>
                            </tr>
                          <tr>
                            <td className="text-left">
                            <b>Action :</b>
                            </td>
                            <td className="text-left">
                            {domains.status==0?<Button
                                    className="btn"
                                    disabled={!showButton?false:true}
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event)=>  generateSSLCertificate(domains.id,domains.project_id) }
                                >Generate SSL Certificate</Button>
                                    :domains.status==1?<Button
                                    className="btn"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event)=>  validateSSLCertificate(domains.id,domains.project_id) }
                                >Validate SSL Certificate</Button>
                                    :domains.status==2?<Button
                                    className="btn"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={(event)=>  deployCDN(domains.project_id,domains.id) }
                                >Deploy On CDN</Button>
                              :''
                              }
                            </td>
                          </tr>
                          </tbody>
                          :''}
                      
                  {domainElements}
                  </Table>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
  
}

export default Domains;
