import React, { useEffect, useState,useContext } from "react";
import formValidations from "../../variables/form-validations";
import projectsService from "../../services/api/projects-service";
import contractService from "../../services/api/contract-service";
import saasService from "../../services/api/saas-service";
import {useHistory} from "react-router-dom";
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import UserContext from "../../UserContext";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const Create = (props)=> {
  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:'',plan_id:"",transaction_id:"",start_date:"",expire_date:""});
  const [projectstate, setProjectState] = useState("");
  const [projecterror, setProjectError] = useState("");
  const [publisherstate, setPublisherState] = useState("");
  const [publishererror, setPublisherError] = useState("");
  const [startDateState, setStartDateState] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateState, setEndDateState] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [planstate, setPlanState] = useState("");
  const [planerror, setPlanError] = useState("");
  const [publisher, setPublisher] = useState('');
  const [publisherFieldValue, setPublisherFieldValue] = useState('');
  const [projectFieldValue, setProjectFieldValue] = useState('');
  const [planFieldValue, setPlanFieldValue] = useState('');
  const [allProjects, setAllProjects] = useState([]);
  const [allPublishers, setAllPublishers] = useState([]);
  const [allTransactions, setAllTransactiion] = useState([]);
  const [transactionIdValue, setTransactionId] = useState("");
  const [transactionState, setTransactionState] = useState("");
  const [transactionError, setTransactionError] = useState("");
  const [allPlans, setAllPlans] = useState([]);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const context = useContext(UserContext);
  useEffect( () => {
    (async () =>{
        if(publisher){
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if(projects.status){
                let projectData = projects.data;
                if(projectData.length){
                    projectData.forEach(function(projectDetails) {
                        let projectValue = {value:projectDetails.id,label:projectDetails.name};
                        publisherProjects.push(projectValue)
                    })
                }
                setAllProjects(publisherProjects);
            }
        }
    })();
  }, [publisher]);

  useEffect(()=>{
    (async()=>{
      let transactionList = await contractService.getUnmappedTransactionList();
      if(transactionList.status){
        let getTransactions = [];
        let transactionData = transactionList.data;
        if(transactionData.length){
          transactionData.forEach(function(transactionDetails){
            let transactionValue = {value:transactionDetails.pg_txn_id,label:(transactionDetails.amount)/100+"("+transactionDetails.pg_txn_id+")"};
            getTransactions.push(transactionValue);
          })
          setAllTransactiion(getTransactions);
        }
      }
    })();
  },[])

  useEffect( () => {
    (async () =>{
        let publishers = await projectsService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
        }

            const response = await saasService.getPlans({is_published:1,status:1});
            console.log("got the plan list");
            console.log(response.data);
            // if(!response.status && response.error.errorCode === 403){
            //   context.setIsAuthorized(context);
            // }
            if (response.status) {
                let tempPlan =[];
                response.data.planList.forEach(planDetails=>{
                    // if(planDetails.is_primary){
                        let planValue = {value:planDetails.id,label:planDetails.name?planDetails.name:""+"-"+planDetails.plan_group_id.name+" - "+planDetails.type};
                        tempPlan.push(planValue)
                    // }
                })
                setAllPlans(tempPlan);
            }
    })();
  }, []);

  const changePublisher = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectFieldValue('');
    setPublisher(event.value);
    setPublisherFieldValue(event);
    setPublisherState("");
    setPublisherError("");
  }

  const changeProject = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectFieldValue(event);
    setProjectState("");
    setProjectError("");
  }

  const changePlan = (event) => {
    setFormDetails({
      ...formdetails,
      plan_id: event.value,
    });
    setPlanFieldValue(event);
    setPlanState("");
    setPlanError("");
  }

  const changeTransactionId = (event) => {
    setFormDetails({
      ...formdetails,
      transaction_id: event.value,
    });
    setTransactionId(event);
    setTransactionState("");
    setTransactionError("");
  }

  const validateFields = () => {
        let validations = true;
        if(formdetails.publisher_id===''){
            setPublisherState("has-danger");
            setPublisherError("This field is required");
            validations = false;
        }

        if(formdetails.project_id===''){
            setProjectState("has-danger");
            setProjectError("This field is required");
            validations = false;
        }

        if(formdetails.plan_id===''){
            setPlanState("has-danger");
            setPlanError("This field is required");
            validations = false;
        }

        if(formdetails.start_date===''){
            setStartDateState("has-danger");
            setStartDateError("This field is required");
            validations = false;
        }

        if(formdetails.expire_date===''){
            setEndDateState("has-danger");
            setEndDateError("This field is required");
            validations = false;
        }
        if(formdetails.start_date && formdetails.expire_date && moment(formdetails.expire_date).isBefore(formdetails.start_date)){
            setStartDateState("has-danger");
            setStartDateError("Start date should be less than expire date");
            validations = false;
        }

        return validations;
  }


  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 
  const validateForm = async () => {
    let validated = validateFields();
    if(validated){
      setShowLoader(true);
      setShowSaveButton(false);
      let createContract = await contractService.createComplementaryContract(formdetails);
      if(!createContract.status && createContract.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(createContract.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Complementary contract was created Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/contract/contracts");
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Contract could not be created, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }

  const redirectUrl = (event) =>{
      event.preventDefault();
    history.push("/contract/contracts")
  }

  const disablePastDates = (current) =>{
    const previousDate =  moment().subtract(1, 'day');
    return current.isAfter(previousDate);
  }
  const disablePastDatesForEndDate = (current) => {
    const previousDate = moment(formdetails.start_date).subtract(1, "day");
    return current.isAfter(previousDate);
};
     const disablePastAndPreviousDatesForStartDate = (current) => {
    const previousDate = moment(formdetails.expire_date)
    return current.isBetween(moment(),previousDate);

    };




    const handleStartDateChange = (event) =>{
        setFormDetails({
            ...formdetails,
            start_date: moment(event._d)
        });
        setStartDateError("");
        setStartDateState("");
    }

    const handleEndDateChange = (event) =>{
        setFormDetails({
            ...formdetails,
            expire_date: moment(event._d)
        });
        setEndDateError("");
        setEndDateState("");
    }
  
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Create Complementary Contract</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createContract" method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label className="bold" sm="2">Select Publisher <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={publisherstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="publisherid"
                            value={publisherFieldValue}
                            onChange={changePublisher}
                            options={allPublishers}
                          />
                          
                          {publisherstate === "has-danger" ? (
                            <label className="error">
                              {publishererror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label className="bold" sm="2">Select Project <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={projectstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="publisherid"
                            value={projectFieldValue}
                            onChange={changeProject}
                            options={allProjects}
                          />
                          
                          {projectstate === "has-danger" ? (
                            <label className="error">
                              {projecterror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label className="bold" sm="2">Select Plan <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={planstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="planid"
                            value={planFieldValue}
                            onChange={changePlan}
                            options={allPlans}
                          />
                          
                          {planstate === "has-danger" ? (
                            <label className="error">
                              {planerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label className="bold" sm="2">Select Transaction</Label>
                      <Col sm="7">
                        <FormGroup className={transactionState}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="transactionid"
                            value={transactionIdValue}
                            onChange={changeTransactionId}
                            options={allTransactions}
                          />
                          
                          {transactionState === "has-danger" ? (
                            <label className="error">
                              {transactionError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                    <Row>
                        <Label className="bold" sm="2">Start Date <span class="text-danger"> * </span></Label>
                        <Col sm="7">
                            <FormGroup className={startDateState}>
                                
                                    <ReactDatetime
                                        value={formdetails.start_date}
                                        name="start_date"
                                        // id="scheduleTime"
                                        isValidDate={ formdetails.expire_date?disablePastAndPreviousDatesForStartDate:disablePastDates}
                                        onChange={handleStartDateChange}
                                        dateFormat ={"DD/MM/YYYY"}
                                        closeOnSelect
                                    />
                                    {startDateState === "has-danger" ? (
                            <label className="error">
                            {startDateError}
                            </label>
                        ) : null}
                            
                            </FormGroup>
                            </Col>
                    </Row>

                    <Row>
                        <Label className="bold" sm="2">End Date <span class="text-danger"> * </span></Label>
                        <Col sm="7">
                            <FormGroup className={endDateState}>
                                <ReactDatetime
                                    value={formdetails.expire_date}
                                    name="end_date"
                                    // id="scheduleTime"
                                    isValidDate={ formdetails.start_date?disablePastDatesForEndDate:disablePastDates}
                                    onChange={handleEndDateChange}
                                    dateFormat ={"DD/MM/YYYY"}
                                    closeOnSelect
                                />
                                {endDateState === "has-danger" ? (
                                  <label className="error">
                                  {endDateError}
                                  </label>
                                ) : null}
                            
                            </FormGroup>
                        </Col>
                    </Row>



                  {showSaveButton?<span>
                      <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Create
                  </Button>

                  <Button
                    className="btn-round"
                    color="warning"
                    type="button"
                    onClick={redirectUrl}
                  >
                    Cancel
                  </Button>
                  
                  </span>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default Create;
