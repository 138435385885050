import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {useHistory} from "react-router-dom";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const ReorderCategories = (props)=> {

    const projectId = props.projectId;
    const context = useContext(UserContext);
    const [categories, setCategories] = useState(props.categories);
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [reloadComp, setReloadComp] = useState(0);
    const [showPopup, setShowPopup] = useState({alert:null})
    const [treeData, setTreeData] = useState(props.categories);

    useEffect(() => {
      const generateTreeData = (categories) => {
        const updatedData = categories.map((element) => {
          const title = (
            <Row className="catTitle">
              <Col>{element.name}</Col>
            </Row>
          );
  
          const updatedElement = { ...element, title };
  
          if (element.children) {
            updatedElement.children = generateTreeData(element.children);
          }
  
          return updatedElement;
        });
  
        return updatedData;
      };
  
      const updatedData = generateTreeData(categories);
      setTreeData(updatedData);
    }, [categories]);

  


  const updateTreeData = (treeData) => {
        console.log(treeData);
        updateParentId(treeData,null);

        setTreeData(treeData);
  }

  const updateParentId = async(treeData,parent_id) => {
    for(let i = 0; i<treeData.length; i++){

        if(treeData[i].parent_id !== parent_id){
          treeData[i].parent_id = parent_id;
          treeData[i].parent_updated = true;
        }

        if(treeData[i].children && treeData[i].children.length > 0){
            await updateParentId(treeData[i].children,treeData[i]._id)
        }
    }
}
  const saveCategoryOrder = async () => {
    setShowLoader(true)
    setShowSaveButton(false)
      let formdata = {'categoryOrders':treeData,projectId:projectId};
      let saveCategoryOrders = await contentService.reorderCategories(formdata);
      if(!saveCategoryOrders.status && saveCategoryOrders.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(saveCategoryOrders.status){
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'success',message:'Saved Successfully'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }else{
        setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'success',message:'Could Not Save. Please try again.'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
      }
  }

    return (
      <div style={{'marginTop':'75px'}}>
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
          {alert.show?<Alert variant={alert.type}>
                    {alert.message}
                  </Alert>:''}
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Reorder Categories</CardTitle>
                </CardHeader>
                <CardBody>

                {showSaveButton?<Button
                    className="btn-round"
                    color="primary"
                    type="button"
                    onClick={(event)=>  saveCategoryOrder(event) }
                >
                    Save
                </Button> :''} 

                <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={(event)=>  props.setReorderCategories(false) }
                >
                    Back
                </Button>

                {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}

                <div style={{'height':'fit-content'}}>
                <SortableTree
                    isVirtualized={false}
                    maxDepth="6"
                    treeData={treeData}
                    onChange={treeData => updateTreeData(treeData) }
                    />
                </div>
               
                
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default ReorderCategories;
