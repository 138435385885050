import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import appBuildService from "../../services/api/app-build-job-service";
import projectsService from "../../services/api/projects-service";

import './AppBuild.css';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";
import QRCode from 'qrcode';
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UpdateAppKeyStore from "./UpdateKeystore.js";

const ManageKeystores = (props) => {

    const [appKeystores, setAppKeystores] = useState(false);
    const [totalAppKeystores, setTotalAppKeystores] = useState(0);
    const [renderComponent, setRenderComponent] = useState(false);
    const [showQRPopup, setShowQRPopup] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [publisher, setPublisher] = useState('');
    const [showUpdateKeystoreForm, setShowUpdateKeystoreForm] = useState(false);
    const [publisherId, setPublisherId] = useState('');
    const [projectId, setProjectId] = useState('');

    const [formdetails, setFormDetails] = useState({ project_id: props.projectId });
    const [search, setSearchDetails] = useState({ project_id: props.projectId });

    const [fieldStates, setFieldStates] = useState({});

    const records = 10;
    const [publisherNameArray, setPublisherNames] = useState([]);
    const [projectNameArray, setProjectNames] = useState([]);
    const platformArray = [{ value: "", label: "All" }, { value: "android", label: "Android" }, { value: "ios", label: "iOS" }];




    const platformChange = (event) => {
        setFormDetails({
            ...formdetails,
            platform: event.value,
        });
    }

    const generateQR = async text => {
        try {
            return await QRCode.toDataURL(text, { errorCorrectionLevel: 'H' });
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        (async () => {
            let publishers = await projectsService.getAllPublishers();
            if (publishers.status) {
                let getPublishers = [];
                let publisherData = publishers.data;
                if (publisherData.length) {
                    publisherData.forEach(function (publisherDetails) {
                        let publisherValue = { value: publisherDetails.id, label: publisherDetails.name + "(" + publisherDetails.email + ")", email: publisherDetails.email };
                        getPublishers.push(publisherValue)
                    })
                }
                setPublisherNames(getPublishers);
            }
        })();
    }, [reloadComponent]);

    useEffect(() => {
        (async () => {
            // if(publisher){
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if (projects.status) {
                let projectData = projects.data;
                if (projectData.length) {
                    projectData.forEach(function (projectDetails) {
                        let projectValue = { value: projectDetails.id, label: projectDetails.name };
                        publisherProjects.push(projectValue)
                    })
                }
                setProjectNames(publisherProjects);
            }
            // }
        })();
    }, [publisher]);

    useEffect(() => {
        (async () => {
            let jobsData = await appBuildService.getAppKeystores(formdetails, activePage, records);
            if (jobsData.status && jobsData.data) {
                setAppKeystores(jobsData.data.appKeystores);
                setTotalAppKeystores(jobsData.data.count);
                for (const jobDetails of jobsData.data.appKeystores) {
                    if (jobDetails.status === 6 && jobDetails.platform === 'android') {
                        fieldStates[jobDetails._id + "_qrlink"] = await generateQR(jobDetails.s3_path);
                    }

                }
                setFieldStates({ ...fieldStates, fieldStates });
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent, search, activePage]);

    const handleDownload = (base64String, fileName) => {
        const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

        const blob = new Blob([bytes], { type: 'application/octet-stream' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;

        const updatedFileName = updateFileName(fileName);
        link.setAttribute('download', updatedFileName);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const updateFileName = (fileName) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).replace(/[\/:,\s]/g, '');
      
        const parts = fileName.split('.');
        const name = parts.slice(0, -1).join('.');
        const extension = parts[parts.length - 1];
      
        const newFileName = `${name}_${formattedDate}.${extension}`;
      
        return newFileName;
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }
    let appKeystoresElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!appKeystores || !appKeystores.length) {
        if (!renderComponent) {
            appKeystoresElements.push(
                <tr>
                    <td colSpan="7" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            );
        } else {
            appKeystoresElements.push(
                <tr>
                    <td colSpan="7" className="text-center">
                        <CardTitle tag="h4">No Records Found</CardTitle>
                    </td>
                </tr>
            );
        }
    } else {
        let i = 1;
        appKeystores.forEach(function (keystoreDetails) {
            if (i == 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            appKeystoresElements.push(
                <tr id={keystoreDetails._id}>
                    <td className="text-left">
                        {(records * (activePage - 1)) + i}
                    </td>
                    <td className="text-left">
                        {keystoreDetails.project_id && keystoreDetails.project_id.publishers_id ? keystoreDetails.project_id.publishers_id.name : ''}
                    </td>
                    <td className="text-left">
                        {keystoreDetails.project_id ? keystoreDetails.project_id.name : ''}
                    </td>
                    <td className="text-left">
                        {keystoreDetails.name} <br />
                    </td>
                    <td className="text-left">
                        {keystoreDetails.created_by}
                    </td>
                    <td className="text-left">
                        <Moment format="DD MMM YYYY hh:mm:ss a">{keystoreDetails.createdAt}</Moment>
                    </td>
                    <td className="text-left">
                        <Row>
                            <Col>
                                <Button
                                    className="btn-round btnBlue"
                                    size="sm"
                                    type="button"
                                    onClick={() => handleDownload(keystoreDetails.key, keystoreDetails.name)}
                                >Download KeyStore
                                </Button>
                            </Col>
                        </Row>
                    </td>
                </tr>
            )
            if (i == appKeystores.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i == appKeystores.length) {
                lastRecord = (activePage - 1) * records + i;
            }
        })
    }

    const handleSubmit = function (e) { searchJobs(); e.preventDefault() }

    const changePublisherId = (event) => {
        setFormDetails({
            ...formdetails,
            publisher_id: event.value,
            project_id: ''
        });
        setProjectId('');
        setPublisherId(event)
        setPublisher(event.value);
    }

    const changeProjectId = (event) => {
        setFormDetails({
            ...formdetails,
            project_id: event.value,
        });
        setProjectId(event)
    }
    const searchJobs = () => {
        setActivePage(1);
        setSearchDetails(formdetails);
    }

    const resetForm = () => {
        setPublisherId('');
        setProjectId('');
        setPublisher('')
        setFormDetails({
            ...formdetails,
            publisher_id: '',
            project_id: '',
            platform: ''
        });
        setSearchDetails(formdetails);
        setReloadComponent(Math.random());
    }

    const manageUploadKeyStore = (event) => {
        setShowUpdateKeystoreForm(true)
    }

    if (showUpdateKeystoreForm) {
        return (<UpdateAppKeyStore keyStore={props.keyStore} projectId={props.projectId} projectName={props.projectName} setShowManageKeystoreForm={props.setShowManageKeystoreForm} />);
    } else {
        return (
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        App Keystores Backup
                                        <Col align="right">
                                            <Button
                                                className="btn-smb"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={(event) => manageUploadKeyStore(event)}
                                            > Upload Keystore
                                            </Button>
                                        </Col>
                                        <Col align="right">
                                            <Button
                                                className="btn-smb"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={(event) => handleDownload(props.keyStore[0].key,props.keyStore[0].name)}
                                            > Download Current Keystore
                                            </Button>
                                        </Col>
                                    </CardTitle>

                                    <Col align="right">
                                        <Row>
                                            <Col md="6">
                                                <div style={{ float: 'right' }}>
                                                    <b>{firstRecord} - {lastRecord} of {totalAppKeystores}</b>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div style={{ 'float': 'left' }}>
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={records}
                                                        totalItemsCount={totalAppKeystores}
                                                        pageRangeDisplayed={3}
                                                        onChange={handlePageChange.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th className="text-left">#</th>
                                                <th className="text-left">Publisher Name</th>
                                                <th className="text-left">Project Name</th>
                                                <th className="text-left">File Name</th>
                                                <th className="text-left">Modified By</th>
                                                <th className="text-left">Created At</th>
                                                <th className="text-left">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{appKeystoresElements}</tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                    }}
                />
            </div>
        );
    }


}

export default ManageKeystores;
