import axios from "axios";

const getJobs = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/jobs/get-jobs',formdata);
    console.log(response)
    return response.data;
}

const cancelJob = async(jobId)=>{

    const response = await axios.post('/api/jobs/cancel-job', {
        jobId
    })
    return response.data;
}

const rescheduleJob = async(data)=>{
    const response = await axios.post('/api/jobs/reschedule-job', data);
    return response.data;
}


const scheduleJob = async(data)=>{

    console.log(data)
    const response = await axios.post('/api/jobs/schedule-job',data);

    return response.data;
}

const editJob = async(data)=>{

    const response = await axios.post('/api/jobs/edit-job-data', data);

    return response.data;
}


const getJobData = async(jobId)=>{
    const response = await axios.get(`/api/jobs/job-data/${jobId}`);
    
    return response.data;
}



export  default {
    getJobs,
    cancelJob,
    scheduleJob,
    rescheduleJob,
    editJob,
    getJobData
}