import axios from "axios";


const createNewServiceStack = async (data)=>{
    console.log("data", data)
    const response = await axios.post('/api/service-stack/create', data);
    return response.data;
}


const getServiceStackList = async (filters, activePage,records)=>{
    const response = await axios.post('/api/service-stack/list', {
        filters,
        activePage,
        records
    });
    return response.data;
}

const getServiceStackDetails = async(serviceStackId)=>{
    const response = await axios.post('/api/service-stack/details', {serviceStackId});
    return response.data;
}

const updateServiceStackStatus = async(data) =>{
    const response = await axios.post('/api/service-stack/update-status', data);
    return response.data;
}

const updateRouteCacheRedis = async(data) => {
    const response = await axios.post('/api/service-stack/update-route-redis-cache', data);
    return response.data;
}

export default {
    createNewServiceStack,
    getServiceStackList,
    getServiceStackDetails,
    updateServiceStackStatus,
    updateRouteCacheRedis,
}