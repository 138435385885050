/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useContext } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import systemServices from '../services/api/system-service';
import Chart from './charts/Charts'

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

// class Dashboard extends React.Component {
const Dashboard = () => {
  // componentDidMount()
  // {
  //   this.loadProjectStats().then(res => {
  //     const details = res.data;
  //     console.log("ProjectStats",details);
  //     this.setState({ projects_stats:details });
  //   })
  //   this.loadPublisherStats().then(res => {
  //     const details = res.data;
  //     console.log("PublisherStats",details);
  //     this.setState({ publishers_stats:details });
  //   })
  //   this.loadTransactionStats().then(res => {
  //     const details = res.data;
  //     console.log("Transaction Stats",details);
  //     this.setState({ transactions_stats:details });
  //   })
  // }

  // constructor(props)
  // {
  // super(props);
  // this.state={"days":30};
  // this.loadData().then(res => {
  //   const details = res.data;
  //   console.log("Details:",details);
  //   this.setState({ ...details });
  // })
  // }
  // async loadData(){
  //   let response=await systemServices.getSystemDetails();
  //   return response;
  // }
  // async loadProjectStats(){
  //   let response=await systemServices.getProjectStats(30);
  //   return response;
  // }
  // async loadPublisherStats(){
  //   let response=await systemServices.getPublisherStats(30);
  //   return response;
  // }
  // async loadTransactionStats(){
  //   let response=await systemServices.getTransactionStats(30);
  //   return response;
  // }
  const [details, setDetails] = useState(false);
  const [projectsStats, setProjectsStats] = useState(false);
  const [publishersStats, setPublishersStats] = useState(false);
  const [transactionsStats, setTransactionsStats] = useState(false);
  const [contractsStats, setContractsStats] = useState(false);
  const [chartUnit, setChartUnit] = useState({ msg: '30 days', duration_unit: "month" });

  useEffect(() => {
    (async () => {

      let systemDetails = await systemServices.getSystemDetails();
      if (systemDetails.status && systemDetails.data) {
        setDetails(systemDetails.data);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setProjectsStats(false);
      setPublishersStats(false);
      setTransactionsStats(false);
      setContractsStats(false);


      let projectsStatsDetails = await systemServices.getProjectStats(chartUnit.duration_unit);
      if (projectsStatsDetails.status && projectsStatsDetails.data) {
        let projectDatasets = [
          {
            label: "Projects Created",
            borderColor: (projectsStatsDetails.data.diff >= 0) ? "#6bd098" : "#ef8156",
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            data: projectsStatsDetails.data.data[0],
          }];
        setProjectsStats({ ...projectsStatsDetails.data, datasets: projectDatasets });
      }

      let publishersStatsDetails = await systemServices.getPublisherStats(chartUnit.duration_unit);
      if (publishersStatsDetails.status && publishersStatsDetails.data) {
        //setPublishersStats(publishersStatsDetails.data);
        let publisherDatasets = [
          {
            label: "Publishers Onboarded",
            borderColor: (publishersStatsDetails.data.diff >= 0) ? "#6bd098" : "#ef8156",
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            data: publishersStatsDetails.data.data[0],
          }];
        setPublishersStats({ ...publishersStatsDetails.data, datasets: publisherDatasets });
      }
      let contractsStatsDetails = await systemServices.getContractStats(chartUnit.duration_unit);
      if (contractsStatsDetails.status && contractsStatsDetails.data) {
        let contractDatasets = [
          {
            label: "Contracts created",
            borderColor: (contractsStatsDetails.data.diff >= 0) ? "#6bd098" : "#ef8156",
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            data: contractsStatsDetails.data.data[0],
          }];
        setContractsStats({ ...contractsStatsDetails.data, datasets: contractDatasets });
      }


      let transactionStatsDetails = await systemServices.getTransactionStats(chartUnit.duration_unit);
      if (transactionStatsDetails.status && transactionStatsDetails.data) {
        let transactionDatasets = [
          {
            label: "Successful Transactions",
            borderColor: "#6bd098",
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            data: transactionStatsDetails.data.data[0],
          },
          {
            label: "Failed Transactions",
            borderColor: "#ef8156",
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            data: transactionStatsDetails.data.data[1],
          },
        ];

        setTransactionsStats({ ...transactionStatsDetails.data, datasets: transactionDatasets });
      }

    })();
  }, [chartUnit]);



  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02 text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category"><Link to={"/dashboard/publishers?page=1"}>Publishers</Link></p>
                      <CardTitle tag="p">{details ? details.publishersCount : ""}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {/* <hr />
                  <div className="stats">
                    <i className="fa fa-refresh" />
                    Update Now
                  </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category"><Link to={"/project/projects?page=1"}>Projects</Link></p>
                      <CardTitle tag="p">{details ? details.projectsCount : ""}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {/* <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    Last day
                  </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-file-image-o text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category"><Link to={"/contentsettings/articles?page=1"}>Articles</Link></p>
                      <CardTitle tag="p">{details ? details.articlesCount : ""}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {/* <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                    In the last hour
                  </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category"><Link to={"/contract/contracts"}>Contracts</Link></p>
                      <CardTitle tag="p">{details ? details.contractsCount : ""}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {/* <hr />
                  <div className="stats">
                    <i className="fa fa-refresh" />
                    Update now
                  </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>

          </Col>
          <div style={{ 'width': '100%' }}>
            <Row>
              <div style={{ 'display': 'flex', 'width': '100%', 'justifyContent': 'center', 'marginBottom': '15px' }}>
                <Col sm="2"><Button style={{ 'padding': '15px', 'width': '105px' }} onClick={() => { setChartUnit({ msg: "24 Hours", duration_unit: "day" }); }}>24 Hours</Button></Col>
                <Col sm="2"><Button style={{ 'padding': '15px', 'width': '105px' }} onClick={() => { setChartUnit({ msg: "30 Days", duration_unit: "month" }); }}>30 Days</Button></Col>
                <Col sm="2"><Button style={{ 'padding': '15px', 'width': '105px' }} onClick={() => { setChartUnit({ msg: "12 Months", duration_unit: "year" }); }}>12 Months</Button></Col>
              </div>
            </Row>
          </div>
        </Row>
        <Row>

          {projectsStats ?

            <Chart data={projectsStats} heading={"Total Projects created in last  " + chartUnit.msg} />
            : ""}

          {publishersStats ?
            <Chart data={publishersStats} heading={"Total Publishers onboarded in last " + chartUnit.msg} />

            : ""}
          {transactionsStats ?
            <Chart data={transactionsStats} heading={"Total Transactions  in last " + chartUnit.msg} />

            : ""}
          {contractsStats ?
            <Chart data={contractsStats} heading={"Total Contracts  in last " + chartUnit.msg} />

            : ""}

        </Row>
        {/* <Row>
            <Col lg="4" sm="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">$34,657</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="success" pill>
                          +18%
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    total earnings in last ten quarters
                  </h6>
                  <Line
                    data={chartExample1.data}
                    options={chartExample1.options}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">Financial Statistics</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          size="sm"
                        >
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            
          </Row> */}



      </div>
    </>
  );
}


export default Dashboard;
