import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import UserContext from "../../UserContext";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import faqService from "../../services/api/faq-service"; 
const EditFAQ = (props)=> {
    const history = new useHistory();
    const context = useContext(UserContext);

    const faqId = new URLSearchParams(props.location.search).get(
        "faqId"
    );
    if (!faqId) {
        history.push("/faq/list");
    }

    const [formdetails, setFormDetails] = useState({faq_id:faqId,group_id:'',question:'',answer:''});
    const [groupNameState,setGroupNameState]=useState("");
    const [groupNameError,setGroupNameError]=useState("");
    const [questionState, setQuestionState] = useState("");
    const [questionError, setQuestionError] = useState("");
    const [answerState, setAnswerState] = useState("");
    const [answerError, setAnswerError] = useState("");
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [groups, setGroups] = useState([]);
  useEffect( () => {
    (async () =>{

            let groupsData = await faqService.getFAQGroups();
            // if(!groupsData.status && groupsData.error.errorCode){
            //   context.setIsAuthorized(context);
            // }
            if (groupsData.status && groupsData.data) {
                let groupNames = groupsData.data;
                let groupValues = [];
                let i = 1;
                Object.keys(groupNames).forEach(function (key) {
                    groupValues.push({
                        value: groupNames[key].id,
                        label: groupNames[key].name,
                    });
                    i++;
                });
                setGroups(groupValues);
            }
            let faqData = await faqService.getFAQ(faqId);
            console.log("faqData:",faqData);
            // if(!faqData.status && faqData.error.errorCode === 403){
            //   context.setIsAuthorized(context);
            // }
            if (faqData.status && faqData.data) {
                setFormDetails({
                    ...formdetails,
                    group_id : faqData.data.group_id,
                    question : faqData.data.question,
                    answer : faqData.data.answer
                });
            }



    })();
  }, []);

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: event.target.value,
    });
    if(event.target.id==="question"){
        setQuestionState("");
        setQuestionError("");
    }
    if(event.target.id==="answer"){
        setAnswerState("");
        setAnswerError("");
    }
  }
  const changeGroup = (event) => {
    setFormDetails({
        ...formdetails,
        group_id: event.value,
    });
    setGroupNameState("has-success");
    setGroupNameError("");
};

const validateGroupName=()=>{
  if (formdetails.group_id === "") {
      setGroupNameState("has-danger");
      setGroupNameError(<div className="text-danger">Group Name is required</div>);
      return false;
  }
  return true;
};
  const validateQuestion = () => { 
    if (formdetails.question.trim() === '') {
      setQuestionState("has-danger");
      setQuestionError("Question is required");
      return false;
    }
    setQuestionState("has-success");
    return true;
  }
  const validateAnswer = () => { 
    if (formdetails.answer.trim() === '') {
      setAnswerState("has-danger");
      setAnswerError("Answer is required");
      return false;
    }
    setAnswerState("has-success");
    return true;
  }
  
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  

  const validateForm = async () => {
    let groupNameValidation = validateGroupName();
    let questionValidation = validateQuestion();
    let answerValidation =  validateAnswer();
    if(groupNameValidation && questionValidation && answerValidation){
      setShowLoader(true);
      setShowSaveButton(false);
      let response = await faqService.editFAQ(formdetails);
      if(!response.status && response.error.errorCode === 403){
        context.setIsAuthorized(context);
        setShowLoader(false)
        setShowSaveButton(true)
      }
      else if(response.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'FAQ  updated Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/faq/list");
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'FAQ could not be update, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }
  const getGroupValue = () => {
    let groupValue="";
   if (formdetails.group_id) {
       groupValue = groups.length ? groups[0] : "";
 
       groups.forEach(function (ldetails) {
           if (ldetails.value === formdetails.group_id) {
               groupValue = ldetails;
           }
       });
   }
   return groupValue;
};

  
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Edit FAQ</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="editFAQ"  method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label sm="2">Group Name <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                          <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="type"
                                value={getGroupValue()}
                                onChange={changeGroup}
                                options={groups}
                              />
                              {groupNameState === "has-danger" ? (
                                  <label className="error">
                                        {groupNameError}
                                  </label>
                              ) : null}
                          </FormGroup>
                      </Col>
                    </Row>
                
                  <Row>
                      <Label sm="2">Question: <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={questionState}>
                          <Input
                            name="question"
                            id="question"
                            type="textarea"
                            value={formdetails.question}
                            onChange={handleChange}
                          />
                          {questionState === "has-danger" ? (
                            <label className="error">
                              {questionError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>                  
                  <Row>
                      <Label sm="2">Answer: <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={answerState}>
                          <Input
                            name="answer"
                            id="answer"
                            type="textarea"
                            value={formdetails.answer}
                            onChange={handleChange}
                          />
                          {answerState === "has-danger" ? (
                            <label className="error">
                              {answerError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>          

                {showSaveButton?
                    <>
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                        >
                            Update
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event) => {history.push(`/faq/list`)}}
                        >
                                Cancel
                        </Button>  
                    </>               
                  :''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default EditFAQ;
