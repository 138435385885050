import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ticketsService from "../../services/api/zoho-desk-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import Select from "react-select";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";


const ZohoDeskEditTicket = (props)=> {

    const history = new useHistory();
    const ticketId = new URLSearchParams(props.location.search).get(
       "ticketId"
     );
    if (!ticketId) {
        history.push("/zohodesk/dashboard");
    }

    console.log("TICKET-ID",ticketId);

    const [formdetails, setFormDetails] = useState({ticket_id:ticketId,departmentId:'',priority:'',status:'',assigneeId:''});
    const [subjectState, setSubjectState] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [departmentState, setDepartmentState] = useState("");
    const [departmentError, setDepartmentError] = useState("");
    const [publisherState, setPublisherState] = useState("");
    const [publisherError, setPublisherError] = useState("");
    // const [firstNameState, setFirstNameState] = useState("");
    // const [firstNameError, setFirstNameError] = useState("");
    // const [lastNameState, setLastNameState] = useState("");
    // const [lastNameError, setLastNameError] = useState("");
    // const [emailState, setEmailState] = useState("");
    // const [emailError, setEmailError] = useState("");
    const [priorityState, setPriorityState] = useState("");
    const [priorityError, setPriorityError] = useState("");
    const [agentState, setAgentState] = useState("");
    const [agentError, setAgentError] = useState("");
    const [descriptionState, setDescriptionState] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [statusState, setStatusState] = useState("");
    const [statusError, setStatusError] = useState("");
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [departments, setDepartments] = useState([]);
    const [publishers, setPublishers] = useState([]);
    const [agents, setAgents] = useState([]);
    const [renderComponent, setRenderComponent] = useState(false);

    useEffect( () => {
      (async () =>{
        let departmentsData = await ticketsService.getDepartments();
        if (departmentsData.status && departmentsData.data) {
            let departmentNames = departmentsData.data;
            let departmentValues = [];
            let i = 1;
            Object.keys(departmentNames).forEach(function (key) {
                departmentValues.push({
                    value: departmentNames[key].id,
                    label: departmentNames[key].name,
                });
                i++;
            });
            setDepartments(departmentValues);
        }

        let ticketData = await ticketsService.getTicket(ticketId);
        console.log("TicketData:",ticketData);
        if (ticketData.status && ticketData.data) {
            setFormDetails({
                ...formdetails,
                ticket_id : ticketData.data.id,
                departmentId : ticketData.data.departmentId,
                priority : ticketData.data.priority,
                status : ticketData.data.status,
                assigneeId : ticketData.data.assigneeId

            });
        }
      

  
      })();
    }, []);

    useEffect( () => {
      (async () =>{
        let agentsData = await ticketsService.getAgents();
        if (agentsData.status && agentsData.data) {
            let agentNames = agentsData.data;
            let agentValues = [];
            let i = 1;
            Object.keys(agentNames).forEach(function (key) {
                agentValues.push({
                    value: agentNames[key].id,
                    label: agentNames[key].name,
                });
                i++;
            });
            setAgents(agentValues);
        }
  
      })();
    }, []);

    const priorityArray = [
      {
          value: "High",
          label: "High",
      },
      {
          value: "Medium",
          label: "Medium",
      },
      {
        value: "Low",
        label: "Low",
      }
  ];
    const statusArray = [
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Closed",
      label: "Closed",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Escalated",
      label: "Escalated",
    }
    ]

console.log("formdetails",formdetails);

const handleChange = (event) => {
  setFormDetails({
    ...formdetails,
    [event.target.id]: event.target.value,
  });
  console.log(event.target.value);
  if(event.target.id==="departmentId"){
      setDepartmentState("");
      setDepartmentError("");
  }
  
    if(event.target.id==="publisher"){
      setPublisherState("");
      setPublisherError("");
  }
if(event.target.id==="priority"){
  setPriorityState("");
  setPriorityError("");
}
  if(event.target.id==="status"){
    setStatusState("");
     setStatusError("");
 }
 if(event.target.id==="assigneeId"){
  setAgentState("");
  setAgentError("");
}

}


const changeDepartment = (event) => {
  setFormDetails({
      ...formdetails,
      departmentId: event.value,
  });
  setDepartmentState("has-sucess");
  setDepartmentError("");
};

const changeAgent = (event) => {
  setFormDetails({
      ...formdetails,
      assigneeId: event.value,
  });
  setAgentState("has-sucess");
  setAgentError("");
};


const changePriority = (event) => {
setFormDetails({
    ...formdetails,
    priority: event.value,
});
setPriorityState("has-sucess");
setPriorityError("");
};

  const changeStatus = (event) => {
    setFormDetails({
        ...formdetails,
        status: event.value,
    });
    setStatusState("has-sucess");
    setStatusError("");
};

const validateDepartment = () => {
    if (formdetails.departmentId === '') {
        setDepartmentState("has-danger");
        setDepartmentError("This field is required");
        return false;
    }
        setDepartmentState("has-success");
        return true;
}

const validateStatus = () => {
    if (formdetails.status.trim() === '') {
        setStatusState("has-danger");
        setStatusError("This field is required");
        return false;
    }
        setStatusState("has-success");
        return true;
}
    const handleSubmit = function(e){  validateForm();e.preventDefault() }
  

    const validateForm = async () => {

        let departmentValidation = validateDepartment();
        let statusValidation = validateStatus();

        if(departmentValidation && statusValidation){
            setShowLoader(true);
            setShowSaveButton(false);
            let response = await ticketsService.editTicket(formdetails);
            if(response.status && response.data && response.data.code != 'ERR_BAD_REQUEST'){
              setTimeout(()=>{
                setShowLoader(false)
                setAlert({show:true,type:'success',message:'Ticket was updated Successfully'});
              },1000);
              
              setTimeout(()=>{
                setAlert({show:false,type:'',message:''});
              },3000);
      
              setTimeout(()=>{
                history.push("/zohodesk/dashboard");
              },4000);
              
            }else{
              setTimeout(()=>{
                setShowLoader(false)
                setShowSaveButton(true)
                setAlert({show:true,type:'danger',message:'Ticket could not be updated, Please try again.'});
              },1000);
      
              setTimeout(()=>{
                setAlert({show:false,type:'',message:''});
              },3000);
            }
          }

        }

        const getDepartmentValue = () => {
          let departmentValue="";
         if (formdetails.departmentId) {
             departmentValue = departments.length ? departments[0] : "";
       
             departments.forEach(function (deptdetails) {
                 if (deptdetails.value == formdetails.departmentId) {
                     departmentValue = deptdetails;
                 }
             });
         }
         return departmentValue;
      };

      const getAgentValue = () => {
        let agentValue="";
       if (formdetails.assigneeId) {
           agentValue = agents.length ? agents[0] : "";
     
           agents.forEach(function (agentdetails) {
               if (agentdetails.value == formdetails.assigneeId) {
                   agentValue = agentdetails;
               }
           });
       }
       return agentValue;
    };

      const getPriorityValue = () => {
        let priorityValue="";
       if (formdetails.priority) {
           priorityValue = priorityArray.length ? priorityArray[0] : "";
     
           priorityArray.forEach(function (prioritydetails) {
               if (prioritydetails.value == formdetails.priority) {
                   priorityValue = prioritydetails;
               }
           });
       }
       return priorityValue;
    };

    const getStatusValue = () => {
      let statusValue="";
     if (formdetails.status) {
         statusValue = statusArray.length ? statusArray[0] : "";
   
         statusArray.forEach(function (statusdetails) {
             if (statusdetails.value == formdetails.status) {
                 statusValue = statusdetails;
             }
         });
     }
     return statusValue;
  };


  console.log("FORM-DETAILS",formdetails)

    
    return <>
    <div className="content">

    <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Edit Ticket</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="editTicket" method="POST" onSubmit={handleSubmit}>
                  <Row>
                      <Label sm="2">Department Name: <span class="text-danger"></span></Label>
                      <Col sm="7">
                          <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder = "Select Department"
                                name="type"
                                value={getDepartmentValue()}
                                onChange={changeDepartment}
                                options={departments}
                              />
                              {departmentState === "has-danger" ? (
                                  <label className="error">
                                        {departmentError}
                                  </label>
                              ) : null}
                          </FormGroup>
                      </Col>
                    </Row>                  
                  <Row>
                      <Label sm="2">Priority: <span class="text-danger"></span></Label>
                      <Col sm="7">
                      <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder = "Select Priority"
                                name="type"
                                value={getPriorityValue()}
                                onChange={changePriority}
                                options={priorityArray}
                              />
                              {priorityState === "has-danger" ? (
                                  <label className="error">
                                        {priorityError}
                                  </label>
                              ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label sm="2">Status: <span class="text-danger"></span></Label>
                      <Col sm="7">
                      <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder = "Select Status"
                                name="type"
                                value={getStatusValue()}
                                onChange={changeStatus}
                                options={statusArray}
                              />
                              {statusState === "has-danger" ? (
                                  <label className="error">
                                        {statusState}
                                  </label>
                              ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Label sm="2">Agent: <span class="text-danger"></span></Label>
                      <Col sm="7">
                      <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder = "Select Agent"
                                name="type"
                                value={getAgentValue()}
                                onChange={changeAgent}
                                options={agents}
                              />
                              {agentState === "has-danger" ? (
                                  <label className="error">
                                        {agentError}
                                  </label>
                              ) : null}
                          </FormGroup>
                      </Col>
                  </Row>
                  

                  {showSaveButton?
                    <>
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                        >
                            Update
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event) => {history.push(`/zohodesk/viewTicket`)}}
                        >
                                Cancel
                        </Button>  
                    </>
                  :''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                                   
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
    </>


}
export default ZohoDeskEditTicket;
