import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import serviceStackService from "../../services/api/service-stack-service";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { useHistory } from "react-router";
import UserContext from "../../UserContext";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from "reactstrap";

const CreateServiceStack = () => {
  const history = new useHistory();
  const statusOptions = [
    { value: "inactive", label: "Inactive" },
    { value: "available", label: "Available" },
    { value: "locked", label: "Locked" },
  ];
  const classificationOptions = [
    { value: 0, label: "Basic" },
    { value: 1, label: "Professional" },
    { value: 2, label: "Business" },
    { value: 3, label: "Enterprise" },
  ];
  const context = useContext(UserContext);
  const [showPopup, setShowPopup] = useState({
    alert: null
  })

  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    region: "",
    capacity: "",
    classification: "",
  });

  const [serviceStackData, setServiceStackData] = useState({
    name: "",
    region: "",
    capacity: "",
    classification: "",
  });

  const regionOptions = [{ value: "", label: "All" }];

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    setServiceStackData({
      ...serviceStackData,
      [event.target.name]: event.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [event.target.name]: "",
    });
  };

  const handleOptionChange = (key, value) => {
    setServiceStackData({
      ...serviceStackData,
      [key]: value,
    });
    setFieldErrors({
      ...fieldErrors,
      [key]: "",
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(serviceStackData);
    const isValid = validateForm();

    if(isValid){
      setShowPopup({
        alert: (
          <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure to Create Service stack!"
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                onConfirm={() => createServiceStack()}
                onCancel={() => hideAlert()}
                confirmBtnText="Yes, Create Service stack!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
          >
            Article was deleted successfully.
          </ReactBSAlert>
        )
      })
    }
  };

  const createServiceStack = async() =>{
    const isValid = validateForm();
    if(isValid){
      console.log(serviceStackData);
      const response = await serviceStackService.createNewServiceStack(serviceStackData);
      if(!response.status && response.error.errorCode === 403){
        setShowPopup({alert:null});  
        context.setIsAuthorized(context);
      }
      else if(response.status){
        setShowPopup({
          alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Service Stack Created"
                confirmBtnBsStyle="info"
                onConfirm={() =>serviceStackCreationSuccess(response.data.serviceStackId)}
                confirmBtnText="Ok"
            >
              Article was deleted successfully.
            </ReactBSAlert>
          )
        })
        setTimeout(()=>{
          history.push(`/service-stack/details/${response.data.serviceStackId}`);
        },3000)
        
      }
    }
  }

  const serviceStackCreationSuccess = (serviceStackId)=>{
    history.push(`/service-stack/details/${serviceStackId}`)
  }

  const validateForm = () => {
    let isValid = true;
    console.log(serviceStackData);
    const updatedFieldErrors = {
      ...fieldErrors,
    };
    console.log(isValid);
    if (!serviceStackData["name"]) {
      isValid = false;
      updatedFieldErrors["name"] = "Service stack name is required";
    } else {
      updatedFieldErrors["name"] = "";
    }
    console.log(isValid);

    if (!serviceStackData["capacity"]) {
      isValid = false;
      updatedFieldErrors["capacity"] = "Capacity is required";
    } else {
      updatedFieldErrors["capacity"] = "";
    }
    console.log(isValid);

    if (!serviceStackData["classification"] && serviceStackData["classification"] !== 0) {
      isValid = false;
      updatedFieldErrors["classification"] = "classification is required";
    } else {
      updatedFieldErrors["classification"] = "";
    }
    console.log(isValid);

    setFieldErrors({
      ...updatedFieldErrors,
    });
    console.log(updatedFieldErrors);
    return isValid;
  };

  const hideAlert = () =>{
    setShowPopup({
      alert: null
    })
  }

  return (
    <>
      <div className="content">
        {showPopup.alert}
        <Container>
          <Card className="card-signup text-center">
            <CardHeader>
              <CardTitle tag="h4">Create Service Stack</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Label sm="2">
                    Name<span className="text-danger"> * </span>
                  </Label>
                  <Col sm="7">
                    <FormGroup className={fieldErrors["name"] !== "" ? "has-danger" : ""}>
                      
                        <Input name="name" onChange={handleChange} />
                      {fieldErrors["name"] ? (
                        <label className="error">{fieldErrors["name"]}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label sm="2">Region</Label>
                  <Col sm="7">

                  <FormGroup
                    className={fieldErrors["region"] !== "" ? "has-danger" : ""}
                  >
                      <Select
                        className="react-select primary"
                        placeholder="Select region"
                        name="region"
                        id="region"
                        options={regionOptions}
                        onChange={(event) => {
                          handleOptionChange("region", event.value);
                        }}
                      />
                      {fieldErrors["region"] ? (
                        <label className="error">{fieldErrors["region"]}</label>
                      ) : null}
                  </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Label sm="2">
                    Classification<span className="text-danger"> * </span>
                  </Label>
                  <Col sm="7">

                  <FormGroup
                    className={
                      fieldErrors["classification"] !== "" ? "has-danger" : ""
                    }>
                      <Select
                        className="react-select primary"
                        placeholder="Select class"
                        name="classification"
                        id="classification"
                        options={classificationOptions}
                        onChange={(event) => {
                          handleOptionChange("classification", event.value);
                        }}
                      />
                      {fieldErrors["classification"] ? (
                        <label className="error">
                          {fieldErrors["classification"]}
                        </label>
                      ) : null}
                  </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Label sm="2">
                    Capacity <span className="text-danger"> * </span>
                  </Label>
                  <Col sm="7">

                  <FormGroup
                    className={
                      fieldErrors["capacity"] !== "" ? "has-danger" : ""
                    }
                  >
                      <Input
                        name="capacity"
                        onChange={handleChange}
                        type="number"
                      />
                    {fieldErrors["capacity"] ? (
                      <label className="error">{fieldErrors["capacity"]}</label>
                    ) : null}
                  </FormGroup>
                  </Col>
                </Row>
                <Button className="btn btn-primary" type="submit">
                  Create Service Stack
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default CreateServiceStack;
