import axios from 'axios';

const getAllProjects = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/manage-project/get-project-list',formdata);
    return response.data;
}

const addProjectToDeleteQueue = async(project_id) =>{
    const formdata = {
        project_id
    }
    const response = await axios.post('/api/manage-project/add-to-delete-queue',formdata);
    console.log(response.data)
    return response.data;
}

const removeElementFromQueue = async(elementId) =>{
    const formdata = {
        element_id: elementId
    }
    const response = await axios.post('/api/manage-project/remove-project-from-queue', formdata)
    return response.data;
}

const getProjectsDeleteQueue = async(formdetails, page, records)=>{
    const formdata = {
        page: page,
        records: records,
        filters: formdetails
    }
    const response = await axios.post('/api/manage-project/project-deletion-list', formdata);
    return response.data;
}

const deleteQueuedProject = async(queuedProjectId) =>{
    const formdata = {
        element_id:queuedProjectId
    }
    console.log(formdata, "formdata")
    const response = await axios.post('/api/manage-project/delete-queued-project', formdata);
    return response.data;
}

export default{
    getAllProjects,
    addProjectToDeleteQueue,
    removeElementFromQueue,
    getProjectsDeleteQueue,
    deleteQueuedProject
}