import React, { useEffect, useState, useContext } from "react";
import { useHistory,Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import usersService from '../../services/api/user-service.js';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import UserContext from "../../UserContext.js";
import projectsService from "../../services/api/projects-service.js";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Formfeature,
    Form,
    Input,
    Alert,
    Spinner,
    InputfeatureAddon,
    InputfeatureText,
    Inputfeature,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from 'react-select';


const ConfigFeatures = (props) => {

    const [formDetails, setFormDetails] = useState({project_id:"",feature:"",status:1});
    const [featuresArray, setFeaturesArray] = useState([]);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [renderComponent, setRenderComponent] = useState(false);
    const [search, setSearchDetails] = useState({project_id:"",feature:"",status:1});
    const [activePage, setActivePage] = useState(1);
    const [projects, setProjects] = useState([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [projectNameArray,setProjectNames] = useState([]);
    const [projectId,setProjectId] = useState('');
    const [features,setFeatures] = useState([]);
    const [allFeatures,setAllFeatures] = useState({});


    const records = 10;
    let statusArray = [
        {
            value:1,
            label:"Enable"
        },
        {
            value:0,
            label:"Disable"
        }
    ]

    useEffect( () => {
        (async () =>{
                let projects = await projectsService.getPublisherProjects();
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, []);

    useEffect(()=>{
        (
            async()=>{
                const response = await projectsService.getConfigFeaturesOtpionsArray();
                if(response.status && response.data){
                    let features = response.data;
                    let tempOptions = [];
                    Object.keys(features).forEach(function(key){
                        let feature = features[key];
                        tempOptions.push({
                            value:key,
                            label:feature.feature_name,
                        });
                    });
                    setAllFeatures(features);
                    setFeatures(tempOptions)
                }
            }
        )();
    },[]);
    useEffect(()=>{
        (async()=>{
            let projectsDetails = await projectsService.getConfigFeaturesList({filters:formDetails,activePage,records});
            if(projectsDetails.status){
                setProjects(projectsDetails.data.featureDetails);
                setTotalProjects(projectsDetails.data.counts || 0);
            }
            setRenderComponent(true);
        
        })();
    },[reloadComponent,search,activePage]);



    const handleProjectNameChange = (event)=>{
        setFormDetails({
            ...formDetails,
            project_id: event.value,
        });
        setProjectId(event);
    }

    const handleFeatureChange = (event)=>{
        setFormDetails({
            ...formDetails,
            feature: event.value
        });
    }
    const handleStatusChange = (event) => {
        setFormDetails({
            ...formDetails,
            status: event.value,
        });
    };

    const handleSubmit = function(e){
        searchProjects();
        e.preventDefault();
    }
    const handlePageChange = (pageNumber)=>{
        setActivePage(pageNumber);
    }
    const searchProjects = async()=>{
        setActivePage(1);
        setSearchDetails(formDetails);
    }
    const resetForm = ()=>{
        setProjectId("");
        setFormDetails({
            ...formDetails,
            project_id:"",
            feature:"",
            status:1
        })
        // console.log("formdetails: ",formDetails)
        setSearchDetails(formDetails);
        setReloadComponent(Math.random());

    }

    let projectFeatureElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if(!projects || !projects.length){
        if(!renderComponent){
            projectFeatureElements.push(
                <tr>
                    <td colSpan="4" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            );
        }
        else{
            projectFeatureElements.push(
                <tr>
                    <td colSpan="4" className="text-center">
                        <CardTitle tag="h4">No records Found</CardTitle>
                    </td>
                </tr>
            )
        }
    }else{
        let i=1;
        projects.forEach(function(projectDetails){
            // console.log("data: ",projectDetails)
            let classname = '';
            if(!projectDetails.status){
                classname = "table-warning";
            }
            if(i==1){
                firstRecord = (activePage - 1) * records + i;
            }
            projectFeatureElements.push(
                <tr className={classname}>
                    <td className="text-left">
                        {(records*(activePage - 1))+i}
                    </td>
                    <td className="text-left">
                        {projectDetails.project_id.name}
                    </td>
                    <td className="text-left">
                        {projectDetails.enableFeatures.map(data=>(
                            data + " ,"
                        ))}
                    </td>
                    <td className="text-left">
                        {projectDetails.disableFeatures.map(data=>(
                            data + " ,"
                        ))}
                    </td>
                </tr>
            )
            if(i== projects.length){
                lastRecord = (activePage-1) * records + i;
            }
            i++;
            if(i==projects.length){
                lastRecord = (activePage-1) * records + i;
            }
        })
    }

    return (
        <>
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Project Config Features
                                    </CardTitle>
                                    <Form action="" method="POST" className="feautreForm" onSubmit={handleSubmit} />
                                    <Row>
                                        <Col md="3">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeholder="Select Project .."
                                            name = "project_id"
                                            id="project_id"
                                            options={projectNameArray}
                                            value={projectId}
                                            onChange={handleProjectNameChange}
                                        />
                                        </Col>
                                        <Col md="3">
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix ="react-select"
                                                placeholder = "Select Feature"
                                                name="feature"
                                                id="feature"
                                                options={features}
                                                value={formDetails.feature !== "" ? features.filter(option=>option.value == formDetails.feature):""}
                                                onChange={handleFeatureChange}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Select 
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Select Status"
                                                name="staus"
                                                id="status"
                                                options={statusArray}
                                                value={formDetails.status !== "" ? statusArray.filter(option=>option.value == formDetails.status):""}
                                                onChange={handleStatusChange}
                                            />
                                        </Col>
                                        <Col md="3">
                                        <div style={{'float':'left'}}>
                                            <Button
                                                className="btn-round"
                                                color="info"
                                                type="submit"
                                                onClick={searchProjects}
                                            >
                                                Search
                                            </Button>
                                        
                                            <Button
                                                className="btn-round"
                                                color="warning"
                                                type="button"
                                                onClick={resetForm}
                                            >
                                                Reset
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {totalProjects > 0 ?
                                        <Row>
                                            <Col md="6">
                                                <div style={{'float':'right'}}>
                                                    <b>{firstRecord} - {lastRecord} of {totalProjects}</b>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div style={{'float':'left'}}>
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={records}
                                                        totalItemsCount={totalProjects}
                                                        pageRangeDisplayed={3}
                                                        onChange={handlePageChange.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : null}
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th className="text-left">#</th>
                                                <th className="text-left">Project Name</th>
                                                <th className="text-left">Features Enable</th>
                                                <th className="text-left">Feature Disable</th>
                                            </tr>
                                        </thead>
                                        <tbody>{projectFeatureElements}</tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>

                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ConfigFeatures;