
import React, { useEffect, useState, useContext } from "react";
import transactionService from "../../services/api/transaction-service";
import UserContext from "../../UserContext";
import Moment from "react-moment";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";
import { useHistory } from "react-router";

const TransactionDetails = (props) => {
    const contract_id = new URLSearchParams(props.location.search).get('contract_id');
    const history = useHistory();
    const [transactionDetails, setTransactionDetails] = useState(false);
    const context = useContext(UserContext);
    useEffect(() => {
        (async () => {
            const transactionDetails = await transactionService.getTransactionDetailsByContractId(contract_id);
            if (!transactionDetails.status && transactionDetails.error.errorCode === 403) {
                // context.setIsAuthorized(context);
            }
            else if (transactionDetails.status && transactionDetails.data) {
                setTransactionDetails(transactionDetails.data);
            }
        })();
    }, [])

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        {transactionDetails ? <>
                            <Card className="card-signup text-center">

                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Publisher Details
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            Project Name : {transactionDetails.project_id
                                                ? transactionDetails.project_id.name
                                                : ""}
                                        </Col>
                                    </Row><hr />
                                    <Row>
                                        <Col>
                                            Publisher Name :  {transactionDetails
                                                ? transactionDetails.publisher_id.name
                                                : ""}
                                        </Col>
                                    </Row><hr />
                                    <Row>
                                        <Col>
                                            Publisher Email : {transactionDetails
                                                ? transactionDetails.publisher_id.email
                                                : ""}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="card-signup text-center">

                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Transaction Details
                                    </CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            Transaction Id:
                                        </Col>
                                        <Col sm="6">
                                            {transactionDetails._id}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            Status :
                                        </Col>
                                        <Col sm="6">

                                            {transactionDetails.status == 2 ? 'Successfull' :
                                                transactionDetails.status == 1 ? 'Initiated' :
                                                    transactionDetails.status == -2 ? 'Failed' :
                                                        ''}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            Amount :
                                        </Col>
                                        <Col sm="6">
                                            <i className="fa fa-inr" style={{ "font-size": "12px" }}></i>&nbsp;{transactionDetails.amount ? transactionDetails.amount / 100 : ""}
                                        </Col>
                                    </Row>
                                    {transactionDetails.coupon_id ?
                                        <>
                                            <Row>
                                                <Col sm="6">
                                                    Coupon Code
                                                </Col>
                                                <Col sm="6">
                                                    {transactionDetails.coupon_id ? transactionDetails.coupon_id.coupon_code : ""}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    Coupon Discount %
                                                </Col>
                                                <Col sm="6">
                                                    {transactionDetails.coupon_id ? transactionDetails.coupon_id.discount_percentage + " %" : ""}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    Effective Amount
                                                </Col>
                                                <Col sm="6">
                                                    <i className="fa fa-inr" style={{ "font-size": "12px" }}></i>&nbsp;{transactionDetails.coupon_id && transactionDetails.coupon_discount ? (transactionDetails.amount - transactionDetails.coupon_discount) / 100 : (transactionDetails.amount) / 100}
                                                </Col>
                                            </Row>
                                        </>

                                        : ""}
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            Date :
                                        </Col>
                                        <Col sm="6">
                                            {transactionDetails.createdAt ?
                                                <Moment format="DD MMM YYYY hh:mm:ss a">
                                                    {transactionDetails.createdAt}
                                                </Moment> : ""}

                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            Plan Name :
                                        </Col>
                                        <Col sm="6">
                                            {transactionDetails.plan_id ? transactionDetails.plan_id.plan_group_id.name : ""}
                                        </Col>
                                    </Row>
                                </CardBody>


                                <CardFooter>
                                    <Button
                                        className="btn-round"
                                        color="danger"
                                        type="button"
                                        onClick={(event) => {
                                            history.goBack();
                                        }}
                                    >
                                        Go Back
                                    </Button>
                                </CardFooter>

                            </Card>
                        </>
                            :
                            <CardHeader>
                                No Transaction details found !
                                <Button
                                    className="btn-round"
                                    color="danger"
                                    type="button"
                                    onClick={(event) => {
                                        history.goBack();
                                    }}
                                >
                                    Go Back
                                </Button>
                            </CardHeader>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TransactionDetails;