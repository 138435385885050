import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import planService from "../../services/api/plan-service";
import { useHistory } from "react-router-dom";
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import ArticlesWidget from "./ArticlesWidget";
import ArticlesCountWidget from "./ArticlesCountWidget";
import CategoryCountWidget from "./CategoryCountWidget";
import SubuserCountWidget from "./SubuserCountWidget";
import MediaStorageCountWidget from "./MediaStorageCountWidget";
import ArticlesFrequencyChart from "./ArticlesFrequencyChart";
import ArticlesPieChart from "./ArticlesPieChart";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import Moment from 'react-moment';
import {
    chartExample1,
    chartExample2,
    chartExample3,
    chartExample4,
    chartExample5,
    chartExample6,
    chartExample7,
    chartExample8,
} from "../../variables/charts.js";

const ProjectReport = (props) => {
    let projectSlug = new URLSearchParams(props.location.search).get("project");
    const history = new useHistory();
    if (projectSlug == '') {
        history.push("/project/projects");
    }
    // const projectId = projectDetails.id;

    const [domain, setProjectDomain] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [projectDetails, setProjectDetails] = useState(false);
    const [planDetails, setPlanDetails] = useState(false);
    const [featureUsage, setFeatureUsage] = useState(false);
    const [contractFeatures, setContractFeatures] = useState(false);
    const [componentRendered, setComponentRendered] = useState(false);
    useEffect(() => {
        (async () => {
            const result = await projectService.getProjectBySlug(projectSlug);
            console.log("Result:", result);
            if (result.status && result.data) {
                setProjectDetails(result.data);
                let domainData = await projectService.getProjectDomains(result.data.id);
                if (domainData.status && domainData.data) {
                    if (domainData.data.status == "4") {
                        setProjectDomain("https://" + domainData.data.public_host.replace('https://', ""));
                    } else {
                        setProjectDomain("https://" + domainData.data.demo_host.replace('https://', ""));
                    }
                    //setRenderComponent(true);
                }

                let planDetails = await planService.getPlanDetails(result.data.id);
                if (planDetails.status && planDetails.data) {
                    setPlanDetails(planDetails.data);
                }

                let features = await projectService.getProjectFeatureUsage(result.data.id);
                if (features.status && features.data) {
                    setContractFeatures(features.data.contract_features);
                    setFeatureUsage(features.data.feature_usage);

                }
                setComponentRendered(true);
            }
        })();
    }, []);


    return (
        <div className="content">
            <Container>
                <Row>
                    <Col md="3">
                        {projectDetails ? <ArticlesCountWidget projectId={projectDetails.id} /> : ""}
                    </Col>
                    <Col md="3">
                        {projectDetails ? <CategoryCountWidget projectId={projectDetails.id} /> : ""}
                    </Col>
                    <Col md="3">
                        {projectDetails ? <SubuserCountWidget projectId={projectDetails.id} /> : ""}
                    </Col>
                    <Col md="3">
                        {projectDetails ? <MediaStorageCountWidget projectId={projectDetails.id} /> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6">
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Card className="card-stats">
                                    <CardBody>
                                    <Row>
                                            <Col md="12">
                                                <div style={{'float':'right'}}>
                                                    <a href={"/config/customizations?projectid=" + projectDetails.id} >
                                                        <i className="fa fa-pencil" />
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <div className="icon-big text-left icon-warning">
                                                    <i className="nc-icon nc-globe text-warning" />
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="stats text-left">
                                                    <CardTitle tag="h3">
                                                        {projectDetails.name}
                                                    </CardTitle>
                                                    <p className="text-left">
                                                        {domain ? (
                                                            <a
                                                                target="_blank"
                                                                href={domain}
                                                            >
                                                                {domain}
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>

                                                    <p />
                                                </div>
                                            </Col>
                                            </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        {/* <Row> */}
                                        {/* <Col md="4" xs="5">
                                                <div className="stats">
                                                    <i className="fa fa-level-up" />
                                                    Upgrade Plan
                                                </div>
                                            </Col>
                                            <Col md="4" xs="5"></Col>
                                            <Col md="4" xs="5">
                                                <div className="stats text-end">
                                                    <a href={"/project/users?projectid="+projectDetails.id} >
                                                        <i className="fa fa-user" />
                                                        Manage Users
                                                    </a>
                                                </div>
                                            </Col> */}



                                        {/* 
                                        </Row>
                                        <hr/>  */}
                                        <Row>
                                            <Col>
                                                Plan :
                                                
                                                {planDetails ? planDetails.plan_name
                                                    : ""}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Plan Type :

                                                {planDetails ? planDetails.plan_type
                                                    : ""}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Start Date :

                                                {planDetails ? <Moment format="dddd DD MMM YYYY hh:mm:ss a" utc>{planDetails.plan_start_date}</Moment>
                                                    : ""}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Expire Date :

                                                {planDetails ? <Moment format="dddd DD MMM YYYY hh:mm:ss a" utc>{planDetails.plan_expire_date}</Moment>

                                                    : ""}
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle tag="h4">Feature Usage</CardTitle>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th className="text-left">#</th>
                                                    <th className="text-left">Metric Name</th>
                                                    <th className="text-left">Total Quota</th>
                                                    <th className="text-left">Quota Used</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {!featureUsage || !featureUsage.length ?
                                                    (!componentRendered) ?
                                                        <tr>
                                                            <td colSpan="4" className="text-center">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </Spinner>
                                                            </td>
                                                        </tr>

                                                        :
                                                        <tr>
                                                            <td colSpan="4" className="text-center">
                                                                <CardTitle tag="h4">No Records Found</CardTitle>
                                                            </td>
                                                        </tr>


                                                    :
                                                    featureUsage.map((item, i) =>
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{item._id}</td>
                                                            <td>{contractFeatures[item._id] ? contractFeatures[item._id].feature_quota : 0}</td>
                                                            <td>{item.count}</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </Table>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                        <Row>
                            <Col md="12">
                                {projectDetails.id ? <ArticlesFrequencyChart projectId={projectDetails.id} /> : ""}
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                {projectDetails.id ? <ArticlesPieChart projectId={projectDetails.id} /> : ""}
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col md="4">
                <Row>
                <Col lg="12" md="12" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="10">
                              <div className="stats text-left">
                              <CardTitle tag="h3">Analytics</CardTitle>
                              <p className="text-left">Last 7 Days</p>
                            </div>
                          </Col>
                          <Col md="2">
                          <div className="stats text-end">
                          <i className="fa fa-cog" />
                        </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                      

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">1,30,704</CardTitle>
                              <p className="card-category">Page Views</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-file-image-o text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                        <hr />
                        <Row>
                        

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">3,704</CardTitle>
                              <p className="card-category">Users</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-user text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                        <hr />
                        <Row>
                        

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">30,704</CardTitle>
                              <p className="card-category">Events</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-hand-pointer-o text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              
                </Col>
               */}
                    <Col md="6">
                        {projectDetails.id ? <ArticlesWidget projectId={projectDetails.id} /> : ""}
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default ProjectReport;
