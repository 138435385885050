import axios from "axios";

const getPlanDetails = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/plan/get-plan-details',formdata);
    return response.data;
}

export  default {
    getPlanDetails
}