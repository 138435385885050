import React, { useEffect, useState, useContext } from "react";
import formValidations from "../../variables/form-validations";
import projectsService from "../../services/api/projects-service";
import contractService from "../../services/api/contract-service";
import saasService from "../../services/api/saas-service";
import {useHistory} from "react-router-dom";
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import UserContext from "../../UserContext";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const InviteEnterprisePlan = (props)=> {
  const history = new useHistory();
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',project_id:'',plan_id:""});
  const [projectstate, setProjectState] = useState("");
  const [projecterror, setProjectError] = useState("");
  const [publisherstate, setPublisherState] = useState("");
  const [publishererror, setPublisherError] = useState("");
  const [planstate, setPlanState] = useState("");
  const [planerror, setPlanError] = useState("");
  const [publisher, setPublisher] = useState('');
  const [publisherFieldValue, setPublisherFieldValue] = useState('');
  const [projectFieldValue, setProjectFieldValue] = useState('');
  const [planFieldValue, setPlanFieldValue] = useState('');
  const [allProjects, setAllProjects] = useState([]);
  const [allPublishers, setAllPublishers] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [planGroupId,setPlanGroupId] =useState(false);
  useEffect( () => {
    (async () =>{
        if(publisher){
            let projects = await projectsService.getPublisherProjects(publisher);
            let publisherProjects = [];
            if(projects.status){
                let projectData = projects.data;
                if(projectData.length){
                    projectData.forEach(function(projectDetails) {
                        let projectValue = {value:projectDetails.id,label:projectDetails.name};
                        publisherProjects.push(projectValue)
                    })
                }
                setAllProjects(publisherProjects);
            }
        }
    })();
  }, [publisher]);

  useEffect(()=>{
      (async ()=>{
       let response=await saasService.getPlanGroups();
       let plan_group_id="";
       if(response.status && response.data)
       {
        response.data.forEach((planGroup)=>{
            if(planGroup.name==="Enterprise")
            {
                plan_group_id=planGroup.id;
                setPlanGroupId(planGroup.id);
            }
        });
       }
        response = await saasService.getEnterprisePlans({plan_group_id:plan_group_id});
        // console.log(response.data);
        if (response.status) {
            let planPrices=response.data.planPrice;
            let tempPlan =[];
            response.data.planList.forEach(planDetails=>{
          
                    let planName=planDetails.name ?planDetails.name:"Enterprise";
                    let designLabel=<Label>
                      
                      <h6>
                      {planPrices[planDetails.id].base_price}
                      
                      {planName}</h6>
                     
                      </Label>
                    let label=planPrices[planDetails.id].base_price+" "+planName+"-"+planDetails.type;
                    let planValue = {
                                value:planDetails.id,
                                //label:planDetails.name?planDetails.plan_group_id.name+" - "+planDetails.type+" "+planDetails.name:planDetails.plan_group_id.name+" - "+planDetails.type+" "};
                                label:label};
                    tempPlan.push(planValue)
                // }
            })
            setAllPlans(tempPlan);
        }

      })();
  },[]);
  useEffect( () => {
    (async () =>{
        let publishers = await projectsService.getAllPublishers();
        if(publishers.status){
            let getPublishers = [];
            let publisherData = publishers.data;
            if(publisherData.length){
                publisherData.forEach(function(publisherDetails) {
                    let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                    getPublishers.push(publisherValue)
                })
            }
            setAllPublishers(getPublishers);
        }
 
    })();
  }, []);

  const changePublisher = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectFieldValue('');
    setPublisher(event.value);
    setPublisherFieldValue(event);
    setPublisherState("");
    setPublisherError("");
  }

  const changeProject = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectFieldValue(event);
    setProjectState("");
    setProjectError("");
  }

  const changePlan = (event) => {
    setFormDetails({
      ...formdetails,
      plan_id: event.value,
    });
    setPlanFieldValue(event);
    setPlanState("");
    setPlanError("");
  }


  const validateFields = () => {
        let validations = true;
        if(formdetails.publisher_id===''){
            setPublisherState("has-danger");
            setPublisherError("Publisher is required");
            validations = false;
        }

        if(formdetails.project_id===''){
            setProjectState("has-danger");
            setProjectError("Project is required");
            validations = false;
        }

        if(formdetails.plan_id===''){
            setPlanState("has-danger");
            setPlanError("Plan is required");
            validations = false;
        }

        return validations;
  }


  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 
  const validateForm = async () => {
    let validated = validateFields();
    if(validated){
      setShowLoader(true);
      setShowSaveButton(false);
      let invited = await contractService.inviteForEnterprisePlan(formdetails);
      if(!invited.status && invited.error.errorCode === 403){
        context.setIsAuthorized(context);
      }
      else if(invited.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Invitation for Enterprise plan  was sent Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/contract/invitations");
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Invitation for Enterprise plan could not be created, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }

  const redirectUrl = (event) =>{
      event.preventDefault();
    history.push("/contract/invitations")
  }






    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Invite for Enterprise Plan</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createContract"  method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label className="bold" sm="2">Select Publisher <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={publisherstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="publisherid"
                            value={publisherFieldValue}
                            onChange={changePublisher}
                            options={allPublishers}
                          />
                          
                          {publisherstate === "has-danger" ? (
                            <label className="error">
                              {publishererror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label className="bold" sm="2">Select Project <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={projectstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="publisherid"
                            value={projectFieldValue}
                            onChange={changeProject}
                            options={allProjects}
                          />
                          
                          {projectstate === "has-danger" ? (
                            <label className="error">
                              {projecterror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  <Row>
                      <Label className="bold" sm="2">Select Enterprise Plan <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={planstate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="planid"
                            value={planFieldValue}
                            onChange={changePlan}
                            options={allPlans}
                          />
                          
                          {planstate === "has-danger" ? (
                            <label className="error">
                              {planerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>


                  {showSaveButton?<span>
                      <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Invite
                  </Button>

                  <Button
                    className="btn-round"
                    color="warning"
                    type="button"
                    onClick={redirectUrl}
                  >
                    Cancel
                  </Button>
                  
                  </span>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default InviteEnterprisePlan;
