import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
// import ProjectContext from '../../ProjectContext';
import UserContext from "../../UserContext";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const ArticlesCountWidget = (props)=> {
  const [formdetails, setFormDetails] = useState({categoryids:[],title:''});
  const [totalArticles,setTotalArticles] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [reloadComponent,setReloadComponent] = useState(0);
  const context = useContext(UserContext);
  useEffect( () => {
    (async () =>{
      let getTotalArticles = await contentService.getTotalArticles(props.projectId,formdetails);
      if(getTotalArticles.status){
          setTotalArticles(getTotalArticles.data.count);
      }
      setRenderComponent(true);
        })();
    }, [reloadComponent]);  
 

    return (
        <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
              <i className="fa fa-file-image-o text-warning" />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category"><a href={"/contentsettings/articles?projectid="+props.projectId}>Articles</a></p>
                <CardTitle tag="p">
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalArticles}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar-o" />
            Till Date
          </div>
        </CardFooter>
      </Card>
    );
  
  
}

export default ArticlesCountWidget;
