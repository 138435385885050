import axios from "axios";


const getConsoleUrl = async() =>{
    const response = await axios.get('/api/console-url');
    return response.data;
}

export default {
    getConsoleUrl
}