import React, { useEffect, useState, useContext } from "react";
import projectsService from "../../services/api/projects-service";
import templatService from "../../services/api/template-service";
import { useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";
import UserContext from "../../UserContext";
import TimezoneSelect from 'react-timezone-select';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

const AppTemplate = (props) => {
    const projectId = new URLSearchParams(props.location.search).get('projectid');;
    const context = useContext(UserContext);
    const history = new useHistory();
    if (!projectId) {
        history.push("/project/projects");
    }
    const [formdetails, setFormDetails] = useState({
        projectid: projectId,
        templateid: "",
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [templates, setTemplates] = useState(false);
    const [defaultTemplateId, setDefaultTemplateId] = useState(false);
    useEffect(() => {
        (async () => {
            let templateData = await templatService.getSortdAppTemplates();
            if (templateData.status && templateData.data) {
                let templateNames = templateData.data;
                let templateValues = [];
                Object.keys(templateNames).forEach(function (key) {
                    templateValues.push({ value: key, label: templateNames[key].name });
                });
                setTemplates(templateValues);
            }
        })();
    }, [])
    useEffect(()=>{
        (async()=>{
            let getAppsGeneralSettingsDetails = await projectsService.getProjectAppsGeneralSettings(projectId);
            console.log("getAppsGeneralSettingsDetails",getAppsGeneralSettingsDetails);
            if(getAppsGeneralSettingsDetails.status && getAppsGeneralSettingsDetails.data){
                console.log(getAppsGeneralSettingsDetails.data);
                setDefaultTemplateId(getAppsGeneralSettingsDetails.data.template_id);
                setFormDetails({
                    ...formdetails,
                    templateid: getAppsGeneralSettingsDetails.data.template_id,
                })
            }
        })();
    },[])

    const changeTemplate = (event) => {
        setFormDetails({
            ...formdetails,
            templateid: event.target.value,
        })
    }
    const handleTemplateSubmit = function (e) {
        changeProjectTemplate();
        e.preventDefault();
    }
    const changeProjectTemplate = async () => {
        let validations = true;
        if (validations) {
            setShowLoader(true);
            setShowSaveButton(false);

            // let templateChanged = {status:false,data:[]}
            let templateChanged = await projectsService.changeProjectAPPTemplates(formdetails);

            if (!templateChanged.status && templateChanged.error && templateChanged.error.errorCode === 403) {
                context.setIsAuthorized(context);
            }
            else if (templateChanged.status) {
                setTimeout(() => {
                    setShowLoader(false)
                    setShowSaveButton(true);
                    setAlert({ show: true, type: 'success', message: 'Project Template  were saved successfully' });
                }, 1000);

            } else {
                console.log("in else")
                setTimeout(() => {
                    setShowLoader(false)
                    setShowSaveButton(true)
                    setAlert({ show: true, type: 'danger', message: 'Project Template could not be saved, Please try again.' });
                }, 1000);
            }

            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);

            // setTimeout(()=>{
            //   history.push("/project/projects");
            // },2000);


        }
    }

    let templateElements = [];
    if (templates) {
        let i = 0;
        templates.forEach(function (templateDetails) {
            i++;
            console.log(templateDetails);
            console.log(defaultTemplateId)
            if (templateDetails.value === defaultTemplateId) {
                console.log("in If")
                templateElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultChecked
                                value={templateDetails.value}
                                id="templateid"
                                name="templateid"
                                type="radio"
                                onChange={changeTemplate}
                            />
                            {templateDetails.label} <span className="form-check-sign" />
                        </Label>
                    </div>
                )
            } else {
                templateElements.push(
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                value={templateDetails.value}
                                id="templateid"
                                name="templateid"
                                type="radio"
                                onChange={changeTemplate}
                            />
                            {templateDetails.label} <span className="form-check-sign" />
                        </Label>
                    </div>
                )
            }
        })
    }
    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Project Template</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="projectTemplate" method="POST" onSubmit={handleTemplateSubmit}>
                                    <Row>
                                        <Label sm="2">Template</Label>
                                        <Col sm="2">
                                            <FormGroup>
                                                {templateElements}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {showSaveButton ? <Button
                                        className="btn-round"
                                        color="info"
                                        type="button"
                                        onClick={handleTemplateSubmit}
                                    >
                                        Save
                                    </Button> : ''}
                                    {alert.show ? <Alert color={alert.type}>
                                        {alert.message}
                                    </Alert> : ''}

                                    {showLoader ? <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner> : ''}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppTemplate;