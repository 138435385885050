import React, { useEffect, useState, useRef, useContext } from "react";
import { Button,Spinner } from "reactstrap";
import defaultImage from "../../assets/img/image_placeholder.jpg";
import customizationsService from "../../services/api/customizations-service";
import {useHistory} from "react-router-dom";
import axios from "axios";
import UserContext from "../../UserContext";
const UploadImage = (props)=> {
    const projectId = props.projectId;
    let defaultImage = '';
    if(props.value){
      defaultImage = props.value;
    }
    console.log(props)
    const context = useContext(UserContext);
    const [file, setFile] = useState(null);
    
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader]   = useState(false);
    const fileInputRef = useRef(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);

    if(props.value && imagePreviewUrl != props.value){
      setImagePreviewUrl(props.value);
  }
console.log(imagePreviewUrl)
  

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileValidated = true;
    if(file.size > 5120000){ //1024000
      fileValidated = false;
      setError(true);
      setErrorMessage('File Size is greater than 5MB');
    }

    let fileTypeArray = props.file_type.split(',');
    
    if(!fileTypeArray.includes(file.type)){
      fileValidated = false;
      setError(true);
      setErrorMessage('Invalid image file. Only '+props.file_type+' supported');
      return false;
    }
      
    if(fileValidated){  
      let uploadImage = false;
      reader.onload = async (event) => {
          var image = new Image();
          image.src = reader.result;
          image.onload = async function () {
              var height = this.height;
              var width = this.width;
              if(props.size){
                let imageWidth = props.size.split('x')[0];
                let imageHeight = props.size.split('x')[1];
                if (height == imageHeight && width == imageWidth) {
                  uploadImage = true;
                }else{
                    fileValidated = false;
                    setError(true);
                    setErrorMessage('Please upload image of '+props.size);
                    return false;
                }
              }else{
                uploadImage = true;
              }

              if(uploadImage){
                let fileData = event.target.result;
                fileData = fileData.split(',')[1];
                setShowLoader(true);
                let uploadImageToServer = await customizationsService.uploadImage(projectId, props.id, fileData);
                if(!uploadImageToServer.status && uploadImageToServer.error.errorCode === 403){
                    context.setIsAuthorized(context);
                }
                else if(uploadImageToServer.status){
                        setError(false);
                        setImagePreviewUrl(uploadImageToServer.data);
                        let image ={
                            value : uploadImageToServer.data
                        }
                        props.handleEnumChange(props.id,uploadImageToServer.data);
                        setShowLoader(false);
                    }else{
                      setError(false);
                      setErrorMessage("There was an error. Please try again.");
                      setShowLoader(false);
                    }
              }
          };
          image.onerror = function() {
            
            fileValidated = false;
            setError(true);
            setErrorMessage('Invalid Image');
            return false;
          };
        
        
       };

      reader.readAsDataURL(file);
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  }
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl('');
    fileInputRef.current.value = null;
    let image ={
        value : ""
    }
    props.handleEnumChange(props.id,'');
  }

  
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={handleImageChange} ref={fileInputRef} />
        <div className={"thumbnail"}>
          <img src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {(imagePreviewUrl === '') ? (
            <Button className="btn-round" onClick={() => handleClick()}>
              {"Select image"}
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => handleClick()}>
                Change
              </Button>
             
              <Button
                color="danger"
                className="btn-round"
                onClick={() => handleRemove()}
              >
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )}
        </div>

        {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}

        {error?<label className="error" style={{color:'red'}}>
                {errorMessage}  
        </label>:''}
      </div>
    );

}


export default UploadImage;
