import React, {useEffect, useState, useContext} from "react";
import {useHistroy} from "react-router-dom";
import usersService from "../../services/api/user-service.js";
import formValidations from "../../variables/form-validations.js";
import TagInput from "../saas/plan/TagInput";
import UserContext from "../../UserContext.js";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    UncontrolledCollapse,
} from "reactstrap";

import Select from "react-select";
import { useHistory } from "react-router-dom";
import permissionService from "../../services/api/permission-group-service.js";
import userService from "../../services/api/user-service.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const EditUserDetails = (props) =>{
    const history = new useHistory();
    const userId = new URLSearchParams(props.location.search).get(
        "userId"
    )
    if(!userId){
        history.push("/users/user-details")
    }
    const [user, setUser] = useState(true);
    const context = useContext(UserContext);
    const [disabled, setDisabled] = useState(false);
    const [usernamestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [useremailstate, setEmailState] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [permissionGroupState, setPermissionGroupState] = useState("");
    const [permissionGroupError, setPermissionGroupError] = useState("");
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [formdetails, setFormDetails] = useState({
        userId: "",
        userName: "",
        userEmail: "",
        permission_Group: "",
    });
    const [temp_list, setTempList] = useState([]);
    const [checkedPermissionGroup, setcheckedPermissionGroup] = useState([]);
    const [defaultCheckedPermissionGroup, setdefaultCheckedPermissionGroup] = useState([]);
    const [permissionGroup, setPermissionGroup] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        (async () => {

            //api for permission Group
            let permissionGroupdata = await usersService.getPermissionGroup();
            // if(!permissionGroupdata.status && permissionGroupdata.error.errorCode === 403){
            //     history.push("unauth/unauthorized");
            // }
            if (permissionGroupdata.status && permissionGroupdata.data) {
                let permissionGroup = permissionGroupdata.data;
                let permissionGroupValues = [];
                let i = 1;
                Object.keys(permissionGroup).forEach(function (key) {
                    if(permissionGroup[key].status != 0 ){
                        permissionGroupValues.push({
                            value: permissionGroup[key]._id,
                            label: permissionGroup[key].name,
                        });
                    }
                    i++;
                });
                console.log(permissionGroupValues)
                setPermissionGroup(permissionGroupValues);
            }

            //api for user details
            // console.log("userId: ",userId);
            // let userData = await usersService.getUsersDetails(userId);
            // console.log("userdata: ",userData);
            // if(!userData.status && userData.error.errorCode === 403){
            //     history.push("unauth/unauthorized")
            // }
            // else if(userData.status && userData.data){
            //     setUser(userData.data);
                
            //     setFormDetails({
            //         ...formdetails,
            //         userId: userData.data.user_id,
            //         userName: userData.data.user_name,
            //         userEmail: userData.data.user_email,
            //     });
            //     console.log("formdata",formdetails)
            //     defaultChecked();
            // }
            
        })();
    }, []);


    useEffect(()=>{
        (async () => {

            let userData = await usersService.getUsersDetails(userId);
            // console.log("userdata: ",userData);
            // if(!userData.status && userData.error.errorCode === 403){
            //     history.push("unauth/unauthorized")
            // }
            if(userData.status && userData.data){
                setUser(userData.data);
                
                // console.log("formdata",formdetails)
                
                // console.log("forndata inside default checked: ",formdetails)
                let list = [...defaultCheckedPermissionGroup];
                const response = await userService.getUserPermissionGroupList(userId);
                if(response.status && response.data != ""){
                    // console.log("response: ",response.data);
                    setdefaultCheckedPermissionGroup(response.data);//
                    // console.log("formdetails: ",user)
                    let permissionList = await permissionService.getSelectedPermission(response.data);
                    let permList = permissionList.data;
                    let list = [];
                    for(var index in permList){
                        let permissionGroup = permList[index];
                        for(var perm in permissionGroup){
                            if(!list.includes(permissionGroup[perm].permission)){
                                list.push(permissionGroup[perm].permission)
                            }
                        }
                    }
                    // console.log("list",response.data);
                    setcheckedPermissionGroup(response.data)
                    setFormDetails({
                        ...formdetails,
                        userId: userData.data.user_id,
                        userName: userData.data.user_name,
                        userEmail: userData.data.user_email,
                        permission_Group: response.data
                    });
                    // setFormDetails({
                    //     ...formdetails,
                    // })
                    setTempList(list);
                    setPermissionGroupState("has-success");
                    setPermissionGroupError("");
                }
            }
        
        })();
    },[])
    
    const validateName = () => {
        if (formdetails.userName.trim() === "") {
            setNameState("has-danger");
            setNameError("User Name is required");
            return false;
        }
        setNameState("has-success");
        return true;
    };

    const validateEmail = () => {
        if (formdetails.userEmail.trim() == "") {
            setEmailState("has-danger");
            setEmailError("User Email is required");
            return false;
        }

        if (!formValidations.verifyEmail(formdetails.userEmail)) {
            setEmailState("has-danger");
            setEmailError("Please enter valid email address");
            return false;
        }
        setEmailState("has-success");
        return true;
    };

    const validatePermissionGroup = () => {
        if (formdetails.permission_Group.length <= 0) {
            setPermissionGroupState("has-danger");
            setPermissionGroupError("Please choose atleast one role");
            return false;
        }
        setPermissionGroupState("has-success");
        return true;
    };

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        setFormDetails({ ...formdetails });
        let userNameValidation = validateName();
        let userEmailValidation = validateEmail();
        let permissionGroupValidation = validatePermissionGroup();

        if (userNameValidation && userEmailValidation && permissionGroupValidation && !disabled) {
            setShowLoader(true);
            setShowSaveButton(false);
            let userDetails = await usersService.saveUser({
                ...formdetails
            });
            setDisabled(true);
            // console.log("user details: ",userDetails)
            
            // if(!userDetails.status && userDetails.error.errorCode === 403){
            //     context.setIsAuthorized(context);
            // }
            if (userDetails.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "User was Saved Successfully",
                    });
                }, 1000);
                setTimeout(() => {
                    setAlert({
                        show: false, type: "", message: ""
                    });
                }, 3000);
                setTimeout(() => {
                    history.push("/users/user-details");
                }, 2000);
            }
            else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "User could not be saved, Please try again.",
                    });
                }, 1000);

                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000);
            }

        }
    };

    const handleNameChange = (event) => {
        // console.log(event.target);
        setFormDetails({
            ...formdetails,
            userName: event.target.value,
        });
        setNameState("has-success");
        setNameError("");
    }

    const handleEmailChange = (event) => {
        setFormDetails({
            ...formdetails,
            userEmail: event.target.value.trim(),
        });
        setEmailState("has-success");
        setEmailError("");
    }

    
    const handlePermissionGroupChange = async(event) => {
        // console.log(event.target)
        let updateList = [...checkedPermissionGroup];
        let permissionList;
        if(event.target.checked){
            updateList = [...checkedPermissionGroup, event.target.value];
            permissionList = await permissionService.getSelectedPermission(updateList);
        }
        else{
            updateList.splice(checkedPermissionGroup.indexOf(event.target.value),1);
            permissionList = await permissionService.getSelectedPermission(updateList);
            
        }
        // console.log("permission list: ",permissionList.data);
        // console.log(permissionArray)
        let permList = permissionList.data;
        let list = [];
        for(var index in permList){
            let permissionGroup = permList[index];
            for(var perm in permissionGroup){
                // console.log(perm)
                // console.log(permissionGroup[perm].permission)
                if(!list.includes(permissionGroup[perm].permission)){
                    list.push(permissionGroup[perm].permission)
                }
            }
        }
        setTempList(list);
        // console.log("in list: ",temp_list)
        // console.log(updateList);
        setcheckedPermissionGroup(updateList);
        setFormDetails({
            ...formdetails,
            permission_Group: updateList
        })
        setPermissionGroupState("has-success");
        setPermissionGroupError("");
    }

    // const getPermissionGroupValue = () => {
    //     let permissionGroupvalue = permissionGroupOptions[0];
    //     if (formdetails.permission_Group) {
    //         permissionGroupOptions.forEach(function (ldetails) {
    //             if (ldetails.value === formdetails.permission_Group) {
    //                 permissionGroupvalue = ldetails;
    //             }
    //         });
    //     }
    //     return permissionGroupvalue;
    // }

    // let userData = [];
    const toggleDropDown = ()=>{
        setIsOpen(!isOpen)
    }
    return (
        <>
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Edit User Details
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {user ? (

                                    <Form
                                        className="form-horizontol form"
                                        action=""
                                        id=""
                                        method="POST"
                                        onSubmit={handleSubmit}
                                    >

                                        <Row>
                                            <Label sm="2">User Name<span className="text-danger"> * </span> </Label>
                                            <Col sm="7">
                                                <FormGroup className={usernamestate}>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        value={formdetails.userName}
                                                        onChange={handleNameChange}
                                                    />
                                                    {usernamestate === "has-danger" ? (
                                                        <label className="error">
                                                            {nameerror}
                                                        </label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">User Email<span className="text-danger"> * </span> </Label>
                                            <Col sm="7">
                                                <FormGroup className={useremailstate}>
                                                    <Input
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        value={formdetails.userEmail}
                                                        // onChange={handleEmailChange}
                                                    />
                                                    {useremailstate === "has-danger" ? (
                                                        <label className="error">
                                                            {emailerror}
                                                        </label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {/* <Label sm="2">Select Permission Group<span className="text-danger"> * </span> </Label> */}
                                            <Col sm="2"></Col>
                                            <Col sm="8">
                                                <FormGroup className={permissionGroupState}>
                                                    {/* <Select
                                                        multiple='true'
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        value={getPermissionGroupValue()}//getRoleValue function called
                                                        onChange={handlePermissionGroupChange}//changeRole function called 
                                                        options={permissionGroup}// role state                            
                                                    /> */}

                                                    <Row>
                                                        <h5>Role</h5>
                                                    </Row>
                                                    <Row>   
                                                        <Button 
                                                            color="primary" 
                                                            id="toggler1" 
                                                            style={{ marginBottom: '1rem' }} 
                                                            onClick={toggleDropDown}
                                                        >
                                                            Select At Least One Role 
                                                            {' '}
                                                            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown}/>            
                                                        </Button>
                                                        <UncontrolledCollapse toggler="#toggler1">
                                                            <Card>
                                                                <CardBody>
                                                                    <FormGroup>      
                                                                        {defaultCheckedPermissionGroup && defaultCheckedPermissionGroup.length ? permissionGroup.map(data=><>
                                                                            {/* {console.log(data.value)} */}
                                                                            <Label 
                                                                                style={{float:"row",marginTop:"5px",marginBottom:"5px",marginLeft:"5px", textSize:"10px"}}   
                                                                            >{data.label}</Label>
                                                                            <Input type="checkbox" id={data.value} defaultChecked={defaultCheckedPermissionGroup.includes(String(data.value)) ? true : false} value={data.value} onChange={handlePermissionGroupChange}
                                                                                style={{float:"row",marginTop:"5px",marginBottom:"5px",marginLeft:"5px", textSize:"10px"}}   
                                                                            ></Input>
                                                                        </>): null}
                                                                    </FormGroup>
                                                                </CardBody>
                                                            </Card>
                                                        </UncontrolledCollapse>
                                                        <Row>
                                                            <Card>
                                                                <CardBody>
                                                                    {temp_list.map((data)=>{
                                                                        // console.log("in item: ",temp_list)
                                                                            return (
                                                                                <ul type="disc">
                                                                                    <li style={{color:"blue"}}>
                                                                                        {data}
                                                                                    </li>
                                                                                </ul>
                                                                            )
                                                                    })}
                                                                        
                                                                </CardBody>
                                                            </Card>
                                                        </Row>
                                                                {/* <UncontrolledCollapse toggler="#toggler1">
                                                                <Card>
                                                                    <CardBody>
                                                                        <Form>
                                                                        {permissionGroup.map(data=><> */}         
                                                                            {/* // <Input type="checkbox" label={data[0].permissions.label} value={data[0].permissions.value}>{data[0].permissions.label}</Input> */}
                                                                            {/* {console.log(key2)}
                                                                            {console.log(data2.label)} */}
                                                                            {/* <Label >{data.label}</Label>
                                                                            <Input type="checkbox" value={data.value} onChange={handlePermissionGroupChange}></Input>
                                                                            </>)}
                                                                        </Form>
                                                                    </CardBody>
                                                                </Card>
                                                                </UncontrolledCollapse> */}
                                                                {/* {
                                                                    data.permissions.map(permission=><>{permission.label}</>)
                                                                } */}
                                                    </Row>
                                                    {
                                                        permissionGroupState === "has-danger" ? (
                                                            <label className="error">
                                                                {permissionGroupError}
                                                            </label>
                                                        ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* button for creating user */}
                                        <Row>
                                            {showSaveButton ? (
                                                <div>
                                                    <Button
                                                        className="btn-round"
                                                        color="info"
                                                        type="button"
                                                        onClick={validateForm}
                                                        disabled={disabled}
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        className="btn-round"
                                                        color="danger"
                                                        type="button"
                                                        onClick={
                                                            (event) => {
                                                                history.push(
                                                                    `/users/user-details`
                                                                );
                                                            }
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {
                                                alert.show ? (
                                                    <Alert color={alert.type}>
                                                        {alert.message}
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )
                                            }
                                            {showLoader ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                ""
                                            )}
                                        </Row>
                                    </Form>
                                    ):(
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </Spinner>
                                    )
                                    }
                                </CardBody>
                                <CardFooter></CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default EditUserDetails;