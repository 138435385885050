import React, { useEffect, useState,useContext } from "react";
import formValidations from "../../variables/form-validations";
import projectsService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const CreateDomain = (props)=> {

  const projectId = new URLSearchParams(props.location.search).get('projectid');
  const history = new useHistory();
  if(!projectId){
    history.push("/project/domains");
  }
  const context = useContext(UserContext);
  const [domains, setDomains] = useState(false);
  const [formdetails, setFormDetails] = useState({projectId:projectId,domain:'',origin_host:'',demo_host:''});
  const [projectdomainstate, setProjectDomainState] = useState("");
  const [projectdomainerror, setProjectDomainError] = useState("");
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [projectName, setProjectName] = useState('');

  
  useEffect( () => {
    (async () =>{
        let projectDetails = await projectsService.getProjectDetails(projectId);
        if(projectDetails.status && projectDetails.data){
            setProjectName(projectDetails.data.name);
            setFormDetails({
                ...formdetails,
                origin_host: 'https://'+projectDetails.data.slug+'.originturbo.sortd.mobi',
                demo_host: 'https://'+projectDetails.data.slug +'.demo.sortd.mobi',
                public_host_cname: 'https://'+projectDetails.data.slug+'.pwa.sortd.mobi'
              });
        }

        let domainsData = await projectsService.getProjectDomains(projectId);
        if(domainsData.status && domainsData.data){
            if(domainsData.data.public_host) {
              history.push("/project/domains?projectid="+projectId);
            }
            setDomains(domainsData.data);
        }
    })();
  }, []);
  
 
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  const handleBlur = (event) => {
    // setFormDetails({
    //   ...formdetails,
    //   [event.target.id]: event.target.value,
    // });

    // if(event.target.id=='projectname'){
    //   validateProjectName();
    // }

    // if(event.target.id=='projecturl'){
    //   validateProjectUrl();
    // }

  }

  const validateProjectDomain = () => {
      if(formdetails.domain===''){
        setProjectDomainState("has-danger");
        setProjectDomainError("This field is required");
        return false;
      }

      setFormDetails({
        ...formdetails,
        domain: formdetails.domain.replace('https://','').replace('http://', '')
      })

      if(!formValidations.verifyDomain(formdetails.domain)){
        setProjectDomainState("has-danger");
        setProjectDomainError("Invalid Domain name, try with www.example.com or m.example.com");
        return false;
      }
      
      if(domains && domains.length > 0){
        let duplicateDomain = false;
        domains.forEach(function(domainDetails) {
            if(formdetails.domain===domainDetails.public_host){
                duplicateDomain = true;
            }
        })
        if(duplicateDomain){
            setProjectDomainState("has-danger");
            setProjectDomainError("You have already created this domain.");
            return false;
        }
      }

      setProjectDomainState("has-success");
      return true;
  }
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 

  const validateForm = async () => {
    let domainvalidation = validateProjectDomain();
    if(domainvalidation){
      setShowLoader(true);
      setShowSaveButton(false);
      console.log(formdetails)
      let createProjectHost = await projectsService.createProjectDomain(formdetails);
      if(!createProjectHost.status && createProjectHost.error.errorCode === 403){
        setAlert({show:false});
        context.setIsAuthorized(context);
      }
      else if(createProjectHost.status){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Domain was created Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);

        setTimeout(()=>{
          history.push("/project/domains?projectid="+projectId);
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Domain could not be created, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    }
  }
  
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Create New Domain for {projectName}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createProjectDomain"  method="POST" onSubmit={handleSubmit}>


                  <Row>
                      <Label sm="2">Domain URL<span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={projectdomainstate}>
                          <Input
                            name="domain"
                            id="domain"
                            type="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {projectdomainstate === "has-danger" ? (
                            <label className="error">
                              {projectdomainerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  {showSaveButton?<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Create
                  </Button>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default CreateDomain;
