import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import transactionsService from "../../services/api/transaction-service";
import projectsService from "../../services/api/projects-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import saasService from "../../services/api/saas-service";
import moment from "moment";
import UserContext from "../../UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import {Link} from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import ReactDatetime from "react-datetime";
const Transactions = (props)=> {
    const history = new useHistory();
  const [transactions, setTransactions] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  // const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [publisher, setPublisher] = useState('');
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({publisher_id:'',transaction_id:'',from_date:'',to_date:'',project_id:'',status:"",verified_by :'', type:""});
  const [search, setSearchDetails] = useState({publisher_id:'',transaction_id:'',from_date:'',to_date:'',project_id:'',status:"",verified_by :'',  type:""});
  const [publisherId,setPublisherId] = useState('');
  const [publisherNameArray,setPublisherNames] = useState([]);
  const [projectId,setProjectId] = useState('');
  const [projectNameArray,setProjectNames] = useState([]);
  const [planType,setPlanType] = useState('');
  const records = 10;
  const [planGroupOptions, setPlanGroupOption] = useState();

  const [groupName,setGroupName] = useState('');
  const [status,setStatus]=useState('');
  const [toDate,setToDate] = useState("");
  const [fromDate,setFromDate] = useState("");
  const [transactionId, setTransactionId] = useState(false);

  const [showPopup, setShowPopup] = useState({
    alert: null
  })

  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };

  const reconcileTransaction = async(transactionId) =>{

    hideAlert();
    console.log({
      transactionId
    })

    const response = await transactionsService.reconcileTransaction(transactionId);
    if(!response.status && response.error.errorCode === 403){
      setShowPopup({
        alert:null
      })
      context.setIsAuthorized(context);
    }
    else if(response.data){
      setShowPopup({
        alert: <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Completed"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        Reconcilation Completed
    </ReactBSAlert>,
      });
    }else{
      setShowPopup({
        alert: <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Failed"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        Failed to reconcile, Try again.
    </ReactBSAlert>
      });
    }

    setTimeout(()=>{
      hideAlert();
      setReloadComponent(Math.random());
    },2000)
    console.log({response})
  }


  const handleReconcile = (transactionId) => {
    setShowPopup({
      alert:(
        <ReactBSAlert
          warning
          // style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => reconcileTransaction(transactionId)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, reconcile it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You want to reconcile this transaction!
        </ReactBSAlert>
      )
    })
  }

  const verifiedByArray = [
        {
            value: "Webhook",
            label: "Webhook",
        },
        {
            value: "frontend",
            label: "Frontend",
        }
    ];

  const typeOptions = [
    {
      value: "1",
      label: "One time",
    },
    {
      value: "2",
      label: "Subscription",
    }
  ]
    
const transactionStatusValue=[
{
    value: 1,
    label: "Order Created",
},
{
    value: -1,
    label: "Order creation failed",
},
{
  value: 2,
  label: "Success",
},
{
  value: -2,
  label: "Failed",
}
];

  useEffect(() => {
    (async() => {
        let tempOptions=[];
        let groupsData = await saasService.getPlanGroups();
        if (groupsData.status && groupsData.data) {
            groupsData.data.forEach((data) => {
                tempOptions.push({
                    value: data.id,
                    label: data.name,
                });
            });
        }
        setPlanGroupOption(tempOptions);
    })()
},[]);



  useEffect( () => {
    (async () =>{
        let getTotalTransactions = await transactionsService.getTotalTransactions(formdetails);
            if(getTotalTransactions.status){
                setTotalTransactions(getTotalTransactions.data.count);
            }
        })();
    }, [reloadComponent,search]);

  useEffect( () => {
    (async () =>{
        let transactionsData = await transactionsService.getTransactions(formdetails,activePage,records);
        if(transactionsData.status && transactionsData.data){
                setTransactions(transactionsData.data);
        }  
        setRenderComponent(true);
      })();
    }, [reloadComponent,search,activePage]);

    useEffect( () => {
      (async () =>{
                let publishers = await projectsService.getAllPublishers();
                if(publishers.status){
                    let getPublishers = [];
                    let publisherData = publishers.data;
                    if(publisherData.length){
                        publisherData.forEach(function(publisherDetails) {
                            let publisherValue = {value:publisherDetails.id,label:publisherDetails.name+"("+publisherDetails.email+")",email:publisherDetails.email};
                            getPublishers.push(publisherValue)
                        })
                    }
                    setPublisherNames(getPublishers);
                }
          })();
      }, [reloadComponent]);

      useEffect( () => {
        (async () =>{
            // if(publisher){
                let projects = await projectsService.getPublisherProjects(publisher);
                let publisherProjects = [];
                if(projects.status){
                    let projectData = projects.data;
                    if(projectData.length){
                        projectData.forEach(function(projectDetails) {
                            let projectValue = {value:projectDetails.id,label:projectDetails.name};
                            publisherProjects.push(projectValue)
                        })
                    }
                    setProjectNames(publisherProjects);
                }
            // }
        })();
      }, [publisher]);

 
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }


  let transactionElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!transactions || !transactions.length){
    if(!renderComponent){
        transactionElements.push(
       <tr>
        <td colSpan="11" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
        </tr>
      );
    }else{
        transactionElements.push(
          <tr>
        <td colSpan="11" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
        </td>
        </tr>
    );
    }
  }else{
     let i = 1;
     transactions.forEach(function(transactionDetails) {
          let className = '';
          if(transactionDetails.status==-2){
            className = 'table-danger';
          }
          if(transactionDetails.status==2){
            className = 'table-success';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
          transactionElements.push(
            <tr className={className}>
                <td className="text-left">
                   {(records*(activePage-1))+i}
               </td>
               <td className="text-left">
                   {transactionDetails.publisher_id?transactionDetails.publisher_id.name:''} <br/>
               </td>
               <td className="text-left">
                   {transactionDetails.project_id?transactionDetails.project_id.name:''}
               </td>
               {/* <td className="text-left">
                   {contractDetails.plan_id?contractDetails.plan_id.plan_group_id.name:""} - {contractDetails.plan_id?contractDetails.plan_id.type:""}
               </td> */}
               <td className="text-left">
                   {transactionDetails.amount?transactionDetails.amount/100:''}
               </td>
               <td className="text-left">
                   {transactionDetails.coupon_discount?transactionDetails.coupon_discount/100 :''}
               </td>
               <td className="text-left">
                   {transactionDetails.coupon_discount?(transactionDetails.amount-transactionDetails.coupon_discount)/100 : transactionDetails.amount/100}
               </td>
                <td className="text-left">
                   {transactionDetails.pg_txn_id?transactionDetails.pg_txn_id:''}
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{transactionDetails.createdat}</Moment>
               </td>
               <td className="text-left">{transactionDetails.type ==1? "One time": "Recurring"}</td>

               <td className="text-left">
                   {transactionDetails.status==0?'Initiated':
                   transactionDetails.status==1?<>Order Created <Button onClick={()=>{handleReconcile(transactionDetails.id)}}>Reconcile</Button></>:
                   transactionDetails.status==-1?'Order could not created':
                   transactionDetails.status==2?'Success':
                   transactionDetails.status==-2?'Failed':
                   ''}
               </td>
               <td>
                   {transactionDetails.verified_by?transactionDetails.verified_by:""}
               </td>
            </tr>
        )
        if(i==transactions.length){
            lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==transactions.length){
        lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleSubmit = function(e){  searchTransactions();e.preventDefault() }

  const changePublisherId = (event) => {
    setFormDetails({
      ...formdetails,
      publisher_id: event.value,
      project_id:''
    });
    setProjectId('');
    setPublisherId(event)
    setPublisher(event.value);
  }

  const changeProjectId = (event) => {
    setFormDetails({
      ...formdetails,
      project_id: event.value,
    });
    setProjectId(event)
  }
  const handleTransactionIdChange = (event) => {
    setFormDetails({
      ...formdetails,
      transaction_id: event.target.value,
    });
    setTransactionId(event.target.value);
  }
  
  const changePlanStatus = (event) => {
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
    setStatus(event)
  }
  const handleVerifiedByChange = (event) => {
    setFormDetails({
      ...formdetails,
      verified_by: event.value,
    });
  }
  

  const handleFromDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      from_date: event._d,
    });
    setFromDate(event._d);
  }

  const handleToDateChange = (event) => {
    setFormDetails({
      ...formdetails,
      to_date: event._d,
    });
    setToDate(event._d);
  }

  const searchTransactions = () => {
    setActivePage(1);
    console.log(formdetails)
    setSearchDetails(formdetails);
  }

  const handleTypeChange = (event) =>{
    setFormDetails({
      ...formdetails,
      type: event.value
    });
    console.log(event.value)
  }

  const resetForm = () => {
    setPublisherId('');
    setProjectId('');
    setGroupName('');
    setStatus('');
    setPlanType('');
    setToDate("");
    setFromDate("");
    setTransactionId("");
    setPublisher("");
    document.getElementById('transaction_id').value = '';

    setFormDetails({
      ...formdetails,
      publisher_id: '',
      plan_id:'',
      project_id:'',
      plan_group_id: '',
      status:'',
      plan_type:'',
      transaction_id:'',
      from_date:'',
      to_date:'',
      verified_by : '',
      type: ""
    });
    setSearchDetails(formdetails);
    setReloadComponent(Math.random());
    
  }

    return (
      <div className="content">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                  Transactions Dashboard
                </CardTitle>
              <Form id="transactionfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Publisher .."
                    name = "publisher_id"
                    id="publisher_id"
                    options={publisherNameArray}
                    value={publisherId}
                    onChange={changePublisherId}
                  />
                  </Col>

                  <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Project .."
                      name = "project_id"
                      id="project_id"
                      options={projectNameArray}
                      value={projectId}
                      onChange={changeProjectId}
                    />
                  </Col>

                  <Col md="3">
                  <ReactDatetime
                      value={fromDate}
                      onChange={handleFromDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                        id:"fromDate",
                      }
                    }
                    />
                  </Col>
                  <Col md="3">
                  <ReactDatetime
                      value={toDate}
                      onChange={handleToDateChange}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                        id:"toDate",
                      }}
                    />
                  </Col>
                  </Row>
                  <br/>
                  <Row>
                  <Col md="3">
                   <Input type="text" name="transaction_id" id="transaction_id" onChange={handleTransactionIdChange} placeholder="Transaction Id"/>
                  </Col>
                  <Col md="3">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Select Status .."
                    name = "status"
                    id="status"
                    // options={[{value:"2",label:"Success"},{value:"1",label:"Initiated"},{value:"-2",label:"Failed"},{value:"-1",label:""}]}
                      options={transactionStatusValue}
                    value={status}
                    onChange={changePlanStatus}
                  />
                  </Col>
                  <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Verified by .."
                      name = "verified_by"
                      id="verified_by"
                      options={verifiedByArray}
                      value={verifiedByArray.filter(option => option.value==formdetails.verified_by)}
                      onChange={handleVerifiedByChange}
                    />
                  </Col>
                  
                  <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Transactions Type"
                      name = "type"
                      id="type"
                      options={typeOptions}
                      value={typeOptions.filter(option => option.value==formdetails.type)}
                      onChange={handleTypeChange}
                    />
                  </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div style={{'float':'left'}}>
                    <Button
                      className="btn-round"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>                   
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                    </div>
                  </Col>
                 </Row>

                </Form>

                
                <hr></hr>
                {totalTransactions > 0 ? 
                  <Row>
                        
                        <Col md="6">
                          <div style={{float:'right',margin:'5px'}}>
                            <b>{firstRecord} - {lastRecord} of {totalTransactions}</b>
                          </div>
                        </Col>
                        <Col md="6">
                        <div style={{float:'left'}}>
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalTransactions}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                        </div>
                      </Col>
                      </Row>
                : null}
                
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                      <th className="text-left">#</th> 
                      <th className="text-left">Publisher Name</th>
                      <th className="text-left">Project Name</th>
                      <th className="text-left">Transaction Amount(INR)</th> 
                      <th className="text-left">Coupon discount(INR)</th> 
                      <th className="text-left">Effective Amount(inr)</th> 
                      <th className="text-left">Transaction Id</th> 
                      <th className="text-left">Transaction Date</th> 
                      <th className="text-left">Type</th>
                      <th className="text-left">Status</th>
                      <th className="text-left">Verified By</th>
                      </tr>
                    </thead>
                    <tbody>{transactionElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                    <hr></hr>
                    
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default Transactions;
